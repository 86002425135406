import { ApiCaller, PagedResponse } from '../../api';
import { Service, SERVICE_DIRECT_ROUTING, SERVICE_FAX2MAIL, SERVICE_HOSTED_PBX, SERVICE_SIP_TRUNKING, SERVICE_WEBEX } from '../../services';

export const TYPE_ENDCLIENT = 'EndClient';
export const TYPE_RESELLER = 'Reseller';
export const TYPE_ADMINISTRATOR = 'Administrator';

export type OrganizationType =
    | typeof TYPE_ENDCLIENT
    | typeof TYPE_RESELLER
    | typeof TYPE_ADMINISTRATOR;

/*
 * GET /api/organizations
 * Retrieves a paginated list of visible organizations
 */

interface FetchOrganizationsResponseItem {
    id: number;
    sapId: string | null;
    name: string;
    type: OrganizationType;
    services: Service[];
}

export const fetchOrganizations = async (api: ApiCaller, search?: string, page: number = 1, limit: number | undefined = undefined): Promise<PagedResponse<FetchOrganizationsResponseItem>> => {
    return await api<PagedResponse<FetchOrganizationsResponseItem>>('organizations', 'GET', {
        search,
        page,
        limit
    });
};

/*
 * GET /api/organizations/?type=Reseller
 * Retrieves a list of all visible resellers
 */
export const fetchAllResellers = async (api: ApiCaller) => {
    let results: Array<FetchOrganizationsResponseItem> = [];
    var totalPages = 100;

    for (let page = 1; page <= totalPages; page++) {
        let response = await api<PagedResponse<FetchOrganizationsResponseItem>>('organizations', 'GET', {
            type: TYPE_RESELLER,
            page,
            limit: 100
        });

        totalPages = response.totalPages;

        results = results.concat(response.items);
    }

    return results;
};

/*
 * GET /api/organizations/:id
 * Retrieves details for a single organization
 */

export interface OrganizationDetails {
    id: number;
    sapId: string | null;
    name: string;
    address: string|null;
    address2: string|null;
    city: string|null;
    state: string|null;
    zip: string|null;
    type: OrganizationType;
    reseller: {
        id: number;
        name: string;
    }|null,
    services: Array<Service>
}

export const fetchOrganizationDetails = async (api: ApiCaller, organizationId: number): Promise<OrganizationDetails> => {
    return await api<OrganizationDetails>(`organizations/${organizationId}`, 'GET');
};

interface OrganizationForm {
    sapId: string | null;
    name: string;
    address: string;
    address2: string;
    resellerId: number | null;
    city: string;
    state: string;
    zip: string;
    type: OrganizationType;
}

/*
 * POST /api/organizations
 * Creates a new organization
 */

export const createOrganization = async (api: ApiCaller, form: OrganizationForm): Promise<OrganizationDetails> => {
    return await api<OrganizationDetails>('organizations', 'POST', {
        ...form
    });
};

/*
 * PUT /api/organizations/:id
 * Update an existing organization
 */

export const updateOrganization = async (api: ApiCaller, organizationId: number, form: OrganizationForm): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}`, 'PUT', {
        ...form
    });
};

/*
 * DELETE /api/organizations/:id
 * Delete an existing organization
 */

export const deleteOrganization = async (api: ApiCaller, organizationId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}`, 'DELETE');
};

/*
 * Adds the Hosted PBX service to an organization
 */
export const addService = async (api: ApiCaller, organizationId: number, service: Service, formValues: {[key: string]: string}): Promise<{}> => {
    let url: string = '';

    switch (service) {
        case SERVICE_DIRECT_ROUTING:
            url = `organizations/${organizationId}/services/direct-routing`;
            break;
        case SERVICE_HOSTED_PBX:
            url = `organizations/${organizationId}/services/hosted-pbx`;
            break;
        case SERVICE_FAX2MAIL:
            url = `organizations/${organizationId}/services/fax2mail`;
            break;
        case SERVICE_SIP_TRUNKING:
            url = `organizations/${organizationId}/services/sip-trunking`;
            break;
        case SERVICE_WEBEX:
            url = `organizations/${organizationId}/services/webex`;
            break;
        default:
            throw new Error(`Unhandled service: {service}`)

    }

    return await api<{}>(url, 'POST', formValues);
};

/*
 * GET /api/organizations/:id/services
 * Retrieves all services assigned to an organization
 */

export interface AssignedService {
    service: Service;
    id: number;
    resource: string;
    isActive: boolean;
}

export const fetchAssignedServices = async (api: ApiCaller, organizationId: number): Promise<AssignedService[]> => {
    return await api<AssignedService[]>(`organizations/${organizationId}/services`, 'GET');
};