import { useEffect, useState } from 'react';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { BulkUpload, createBulkUpload, fetchBulkUpload, fetchBulkUploadDownloadUrl, fetchBulkUploads } from './api';
import { WebexPolicies } from '../policies';


/**
 * Hook for fetching bulk uploads
 */

type OrderBy = 'id' | 'uploadedAt';
type Dir = 'asc' | 'desc';

interface UseFetchBulkUploads {
    fetch: (organizationId: number, assignmentId: number, orderBy: OrderBy, dir: Dir, pageNumber: number, limit?: number) => void;
    isFetching: boolean;
    uploads: BulkUpload[] | null;
    error: string | null;
}

interface Request {
    requestTs: number;
    organizationId: number;
    assignmentId: number;
    orderBy: OrderBy;
    dir: Dir;
    pageNumber: number;
    limit?: number;
}

export const useFetchBulkUploads = (): UseFetchBulkUploads => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [uploads, setUploads] = useState<BulkUpload[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);
    const session = useSession();

    const fetch = (organizationId: number, assignmentId: number, orderBy: OrderBy, dir: Dir, pageNumber: number, limit?: number) => {
        setRequest({
            requestTs: Date.now(),
            organizationId,
            assignmentId,
            orderBy,
            dir,
            pageNumber,
            limit
        })
    };

    useEffect(() => {
        if (request !== null && authorizedFor(WebexPolicies.CanUseBulkUpload, session.roles)) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchBulkUploads(api, request.organizationId, request.assignmentId, request.orderBy, request.dir, request.pageNumber, request.limit);

                    if (!didCancel) {
                        setUploads(response.items);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve uploads.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        fetch,
        isFetching,
        uploads,
        error
    };
};

/**
* Hook for fetching a bulk upload
*/

interface UseFetchBulkUpload {
    fetch: (organizationId: number, assignmentId: number, uploadId: number) => void;
    isFetching: boolean;
    upload: BulkUpload | null;
    error: string | null;
}

export const useFetchBulkUpload = (): UseFetchBulkUpload => {
    interface Request {
        requestTs: number;
        organizationId: number;
        assignmentId: number;
        uploadId: number;
    }

    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [upload, setUpload] = useState<BulkUpload | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);
    const session = useSession();

    const fetch = (organizationId: number, assignmentId: number, uploadId: number) => {
        setRequest({
            requestTs: Date.now(),
            uploadId,
            organizationId,
            assignmentId
        })
    };

    useEffect(() => {
        if (request !== null && authorizedFor(WebexPolicies.CanUseBulkUpload, session.roles)) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchBulkUpload(api, request.organizationId, request.assignmentId, request.uploadId);

                    if (!didCancel) {
                        setUpload(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve upload.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        fetch,
        isFetching,
        upload,
        error
    };
};

/*
 * Hook for creating bulk upload
 */

interface UseCreateBulkUpload {
    create: (organizationId: number, assignmentId: number, base64Contents: string) => void;
    isCreating: boolean;
    upload: BulkUpload | null;
    error: string | null;
    fieldErrors: FieldError[] | null;
}

export const useCreateBulkUpload = (): UseCreateBulkUpload => {
    interface Request {
        requestTs: number;
        organizationId: number;
        assignmentId: number;
        base64Contents: string;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [upload, setUpload] = useState<BulkUpload | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create = (organizationId: number, assignmentId: number, base64Contents: string) => {
        setRequest({
            organizationId,
            assignmentId,
            base64Contents,
            requestTs: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setUpload(null);

                try {
                    const createdUpload = await createBulkUpload(api, request.organizationId, request.assignmentId, request.base64Contents);

                    if (!didCancel) {
                        setIsCreating(false);
                        setUpload(createdUpload);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setUpload(null);
                            setError('Unable to create bulk upload.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        create,
        isCreating,
        upload,
        error,
        fieldErrors
    }
};

type UseFetchBulkUploadUserSheet = [
    (organizationId: number, assignmentId: number, uploadId: number) => void,
    boolean,
    string | null,
    string | null
];

export const useFetchBulkUploadUserSheet = (): UseFetchBulkUploadUserSheet => {
    interface Request {
        requestTs: number;
        organizationId: number;
        assignmentId: number;
        uploadId: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [url, setUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (organizationId: number, assignmentId: number, uploadId: number) => {
        setRequest({
            organizationId,
            assignmentId,
            uploadId,
            requestTs: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setUrl(null);

                try {
                    const sheetUrl = await fetchBulkUploadDownloadUrl(api, request.organizationId, request.assignmentId, request.uploadId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setUrl(sheetUrl);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            setError(e.message);
                            setIsFetching(false);
                        } else {
                            setIsFetching(false);
                            setUrl(null);
                            setError('Unable to retrieve user sheet.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        url,
        error
    ]
};