import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { Assignment, cancelSipTrunkingAssignment, CreateAssignment, createSipTrunkingAssignment, fetchSipTrunkingAssignment, UpdateAssignment, updateSipTrunkingAssignment } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/*
 * Hook for creating SIP Trunking service assignment
 */

type FetchAssignmentFunc = (organizationId: number, assignmentId: number) => void;

interface FetchAssignmentRequest {
    organizationId: number;
    assignmentId: number;
    ts: number;
}

export const useFetchSipTrunkingAssignment = (): TriggeredFetchHookResponse<FetchAssignmentFunc, Assignment> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchAssignmentRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [assignment, setAssignment] = useState<Assignment | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchAssignmentFunc = (organizationId: number, assignmentId: number) => {
        setRequest({
            organizationId,
            assignmentId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setAssignment(null);

                try {
                    const assignment = await fetchSipTrunkingAssignment(api, request.organizationId, request.assignmentId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setAssignment(assignment);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setAssignment(null);
                            setError('Unable to fetch SIP Trunking service assignment.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        assignment,
        error
    ];
};

/*
 * Hook for creating SIP Trunking service assignment
 */

type CreateAssignmentFunc = (organizationId: number, form: CreateAssignment) => void;

interface CreateAssignmentRequest {
    organizationId: number;
    form: CreateAssignment;
    ts: number;
}

export const useCreateSipTrunkingAssignment = (): TriggeredSubmitHookResponse<CreateAssignmentFunc, Assignment> => {
    const api = useApi();
    const [request, setRequest] = useState<CreateAssignmentRequest | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [assignment, setAssignment] = useState<Assignment | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: CreateAssignmentFunc = (organizationId: number, form: CreateAssignment) => {
        setRequest({
            organizationId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setAssignment(null);

                try {
                    const createdAssignment = await createSipTrunkingAssignment(api, request.organizationId, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setAssignment(createdAssignment);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setAssignment(null);
                            setError('Unable to create SIP Trunking service assignment.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        assignment,
        error,
        fieldErrors
    ];
};

/*
 * Hook for updating SIP Trunking service assignment
 */

type UpdateAssignmentFunc = (organizationId: number, assignmentId: number, form: UpdateAssignment) => void;

interface UpdateAssignmentRequest {
    organizationId: number;
    assignmentId: number;
    form: UpdateAssignment;
    ts: number;
}

export const useUpdateSipTrunkingAssignment = (): TriggeredSubmitHookResponse<UpdateAssignmentFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<UpdateAssignmentRequest | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: UpdateAssignmentFunc = (organizationId: number, assignmentId: number, form: UpdateAssignment) => {
        setRequest({
            organizationId,
            assignmentId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setIsComplete(false);

                try {
                    await updateSipTrunkingAssignment(api, request.organizationId, request.assignmentId, request.form);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setIsComplete(false);
                            setError('Unable to update SIP Trunking service assignment.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isUpdating,
        isComplete,
        error,
        fieldErrors
    ];
};

/*
 * Hook for cancelling a SIP Trunking service
 */

type CancelHook = {
    cancel: CancelAssignmentFunc,
    isCancelling: boolean,
    isCancelled: boolean,
    validationMessage: string | null,
    failureMessage: string | null
};

type CancelAssignmentFunc = (organizationId: number, assignmentId: number) => void;

interface CancelAssignmentRequest {
    organizationId: number;
    assignmentId: number;
    ts: number;
}

export const useCancelSipTrunkingAssignment = (): CancelHook => {
    const api = useApi();
    const [request, setRequest] = useState<CancelAssignmentRequest | null>(null);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    const cancel: CancelAssignmentFunc = (organizationId: number, assignmentId: number) => {
        setRequest({
            organizationId,
            assignmentId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCancelling(true);
                setIsCancelled(false);

                try {
                    await cancelSipTrunkingAssignment(api, request.organizationId, request.assignmentId);

                    if (!didCancel) {
                        setIsCancelling(false);
                        setIsCancelled(true);
                        setValidationMessage(null);
                        setFailureMessage(null);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, we'll either display error messages under the fields
                            // or display a generic error message if no field errors are listed
                            setValidationMessage(e.message);
                        } else if ((e instanceof ResponseError) && (e.code === 500)) {
                            setFailureMessage(e.message);
                            setValidationMessage(null);
                        } else {
                            setIsCancelling(false);
                            setIsCancelled(false);
                            setValidationMessage(null);
                            setFailureMessage('Unable to cancel SIP Trunking service assignment.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        cancel,
        isCancelling,
        isCancelled,
        validationMessage,
        failureMessage
    }
};