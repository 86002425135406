import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../../api";
import history from '../../../../history';
import { loginUrl } from "../../../auth/urls";
import { fetchBandwidthPortInOrder, fetchBandwidthPortInOrders, PortInOrder, PortInOrderSummary, triggerBandwidthPortInOrder } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/**
 * Hook for fetching Port In Orders
 * */

type FetchBandwidthPortInsFunc = (accountId: string, subAccountId?: string, number?: string) => void;

export const useFetchBandwidthPortInOrders = (): TriggeredFetchHookResponse<FetchBandwidthPortInsFunc, PortInOrderSummary[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthPortInOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [orders, setOrders] = useState<PortInOrderSummary[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthPortInOrdersRequest {
        accountId: string;
        subAccountId?: string;
        number?: string;
        ts: number;
    }

    const fetch: FetchBandwidthPortInsFunc = (accountId: string, subAccountId?: string, number?: string) => {
        setRequest({
            accountId,
            subAccountId,
            number,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrders(null);

                try {
                    var orders = await fetchBandwidthPortInOrders(api, request.accountId, request.subAccountId, request.number);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrders(orders);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrders(null);
                            setError('Unable to fetch Bandwidth Orders.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        orders,
        error
    ];
};

/**
 * Hook for fetching a Port In Order
 * */

type FetchBandwidthPortInOrderFunc = (accountId: string, orderId: string) => void;

export const useFetchBandwidthPortInOrder = (): TriggeredFetchHookResponse<FetchBandwidthPortInOrderFunc, PortInOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthPortInOrderRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [order, setOrder] = useState<PortInOrder | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthPortInOrderRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: FetchBandwidthPortInOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrder(null);

                try {
                    var order = await fetchBandwidthPortInOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrder(null);
                            setError('Unable to fetch Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        order,
        error
    ];
};

/**
 * Hook for triggering a Port In Order
 * */

type TriggerBandwidthPortInOrderFunc = (accountId: string, orderId: string) => void;

export const useTriggerBandwidthPortInOrder = (): TriggeredFetchHookResponse<TriggerBandwidthPortInOrderFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthPortInOrderRequest | null>(null);
    const [isTriggering, setisTriggering] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthPortInOrderRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: TriggerBandwidthPortInOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setisTriggering(true);
                setIsComplete(false);

                try {
                    await triggerBandwidthPortInOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setisTriggering(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if ((e instanceof ResponseError) && (e.code === 400)) {
                            setisTriggering(false);
                            setIsComplete(false);
                            setError(e.message);
                        } else {
                            setisTriggering(false);
                            setIsComplete(false);
                            setError('Unable to trigger Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isTriggering,
        isComplete,
        error
    ];
};