import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HostedPbxOrganization } from '../api';
import { hpbxAssignmentViewUrl } from '../urls';
import moment = require('moment');
import { bandwidthSubAccountViewUrl } from '../../bandwidth/urls';
import { dubberAccountViewUrl } from '../../dubber/urls';
import { platformDisplay } from '../helpers';

interface Props {
    items: Array<HostedPbxOrganization>
}

const OrganizationTable = (props: Props) => {
    const { items } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>BroadWorks Group</TableCell>
                    <TableCell>Bandwidth SubAccount</TableCell>
                    <TableCell>Dubber Account</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assign Date</TableCell>
                    <TableCell>Cancel Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(org => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={hpbxAssignmentViewUrl(org.id, org.assignmentId)} {...props} />);
                    const BandwidthLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(org.bandwidthAccount, org.bandwidthSubAccount)} {...props} />);
                    const DubberLinkComponent = org.dubberAccount !== null
                        ? React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(org.dubberGroup, org.dubberAccount || '')} {...props} />)
                        : null;

                    return (
                        <TableRow key={org.assignmentId}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{org.name}</Link>
                            </TableCell>
                            <TableCell>{platformDisplay(org.platform)}</TableCell>
                            <TableCell>{org.broadworksGroup}</TableCell>
                            <TableCell>
                                <Link component={BandwidthLinkComponent}>{org.bandwidthSubAccount}</Link>
                            </TableCell>
                            <TableCell>
                                {DubberLinkComponent ? <Link component={DubberLinkComponent}>{org.dubberAccount}</Link> : <>{org.dubberAccount}</>}
                            </TableCell>
                            <TableCell>{org.isActive ? "Active" : "Cancelled"}</TableCell>
                            <TableCell>{moment(org.startedAt).format('MM/DD/YYYY')}</TableCell>
                            <TableCell>{org.cancelledAt !== null ? moment(org.cancelledAt).format('MM/DD/YYYY') : ''}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default OrganizationTable;