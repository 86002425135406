export const HPBX_ORGANIZATIONS_INDEX_URL = '/hosted-pbx/organizations';
export const HPBX_STATEMENTS_INDEX_URL = '/hosted-pbx/statements';
export const HPBX_BULK_UPLOADS_INDEX_URL = '/hosted-pbx/bulk-uploads';

export const HPBX_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/hosted-pbx/:assignmentId';
export const HPBX_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/hosted-pbx/:assignmentId/update';

export const HPBX_ASSIGNMENT_STATEMENTS_URL = '/organizations/:organizationId/services/hosted-pbx/:assignmentId/statements';

export const HPBX_NUMBERS_INDEX_URL = '/hosted-pbx/numbers';

export const HPBX_ZERO_TOUCH_INDEX_URL = '/hosted-pbx/zero-touch';

export const HPBX_NINE_LINE_INDEX_URL = '/hosted-pbx/9line';

export const hpbxOrganizationsIndexUrl = () => HPBX_ORGANIZATIONS_INDEX_URL;

export const hpbxStatementsIndexUrl = () => HPBX_STATEMENTS_INDEX_URL;

export const hpbxAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    HPBX_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const hpbxAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    HPBX_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const hpbxAssignmentStatementsUrl = (organizationId: string | number, assignmentId: string | number) =>
    HPBX_ASSIGNMENT_STATEMENTS_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));


export const hpbxBulkUserUploadsIndexUrl = () => HPBX_BULK_UPLOADS_INDEX_URL;

export const hpbxNumbersIndexUrl = () => HPBX_NUMBERS_INDEX_URL;

export const hpbxZeroTuchIndexUrl = () => HPBX_ZERO_TOUCH_INDEX_URL;

export const hpbxNineLineIndexUrl = () => HPBX_NINE_LINE_INDEX_URL;