import { Checkbox, FormControl, FormControlLabel, Grid, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';
import { Account } from '../../accounts/api';
import { productLabel } from '../../helpers';
import { useFetchDubberUsers } from '../../hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    accountId: string;
    userId: string;
    product: string;
    retainUnidentifiedRecordings: boolean;
}

interface Props {
    groupId: string;
    productOptions: string[];
    accountOptions: Account[];
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean) => void;
    onLoading: (loading: boolean) => void;
    onEnter: () => void;
}

const UnidentifiedDubPointUpdateForm = (props: Props) => {
    const classes = useStyles();

    const [fetchUsers, isFetchingUsers, users, fetchUsersError] = useFetchDubberUsers();

    const { groupId, accountOptions, formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, onEnter, onLoading, productOptions } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onEnter();
        }
    };

    useEffect(() => {
        if (formValues.accountId) {
            fetchUsers(groupId, formValues.accountId);
        }
    }, [formValues.accountId])

    const sortedUsers = useMemo(() => {
        return (users || []).sort((a, b) => {
            return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
        });
    }, [users]);

    useEffect(() => {
        onLoading(isFetchingUsers);
    }, [isFetchingUsers]);

    const anyErrorMessage = errorMessage || fetchUsersError;

    return (
        <>
            {anyErrorMessage && <Typography variant="body1" color="error">{anyErrorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('accountId')}>
                        <InputLabel htmlFor="type">Account</InputLabel>
                        <Select
                            id="accountId"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.accountId}
                            input={<Input name="accountId" id="accountId" />}
                            onKeyDown={handleKeyDown}
                            onChange={evt => onUpdate('accountId', evt.target.value)}>
                            <option></option>
                            {accountOptions.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                        </Select>
                        <ValidationMessage field="accountId" />
                    </FormControl>
                </Grid>

                {formValues.accountId &&
                    <Grid item xs={12}>
                        {isFetchingUsers
                            ? <LinearProgress color="primary" />
                            : <FormControl className={classes.formControl} fullWidth required error={!isValid('userId')}>
                                <InputLabel htmlFor="type">User</InputLabel>

                                <Select
                                    id="userId"
                                    margin="none"
                                    native={true}
                                    disabled={isSubmitting}
                                    value={formValues.userId}
                                    input={<Input name="userId" id="userId" />}
                                    onKeyDown={handleKeyDown}
                                    onChange={evt => onUpdate('userId', evt.target.value)}>
                                    <option></option>
                                    {sortedUsers.map(u => <option key={u.id} value={u.id}>{u.firstName} {u.lastName}</option>)}
                                </Select>

                                <ValidationMessage field="userId" />
                            </FormControl>
                        }
                    </Grid>
                }

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('product')}>
                        <InputLabel htmlFor="type">Product</InputLabel>
                        <Select
                            id="product"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.product}
                            input={<Input name="product" id="product" />}
                            onKeyDown={handleKeyDown}
                            onChange={evt => onUpdate('product', evt.target.value)}>
                            <option></option>
                            {productOptions.map(prod => <option key={prod} value={prod}>{productLabel(prod)}</option>)}
                        </Select>
                        <ValidationMessage field="product" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="retainUnidentifiedRecordings"
                                checked={formValues.retainUnidentifiedRecordings}
                                disabled={isSubmitting}
                                onChange={evt => onUpdate('retainUnidentifiedRecordings', !formValues.retainUnidentifiedRecordings)}
                                value="1"
                                color="primary"
                            />
                        }
                        label="Retain Unidentified Recordings" />
                </Grid>
            </Grid>
        </>
    );
};

export default UnidentifiedDubPointUpdateForm;