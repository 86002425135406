import { BILLING_ASSOCIATE, PROJECT_COORDINATOR, PROVISIONING, SERVICE_MANAGER, SUPER_USER } from '../administrators/api';

export enum DirectRoutingPolicies {
    CanManageAssignments = 'CanManageDirectRoutingAssignments',
    CanCancelAssignments = 'CanCancelDirectRoutingAssignments'
}

export const DirectRoutingPolicyRoles = {
    [DirectRoutingPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [DirectRoutingPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING]
};