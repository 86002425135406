import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { isAllTeam } from '../helpers';
import { useDeleteDubberTeamMember, useFetchDubberAccount, useFetchDubberTeam, useFetchDubberTeamMember } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberTeamMemberUpdateUrl, dubberTeamViewUrl } from '../urls';
import DubPointTable from './DubPointTable';
import TeamMemberSummary from './TeamMemberSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
}));

interface Props extends RouteComponentProps<any> {
}

const TeamMemberViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const teamId = props.match.params['teamId'];
    const userId = props.match.params['userId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchTeam, isFetchingTeam, team, fetchTeamError] = useFetchDubberTeam();
    const [fetchTeamMember, isFetchingTeamMember, teamMember, fetchTeamMemberError] = useFetchDubberTeamMember();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteMember, isDeletingMember, memberDeleted, deleteMemberError] = useDeleteDubberTeamMember();

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingTeam || isFetchingTeamMember,
        fetchAccountError || fetchTeamError || fetchTeamMemberError || deleteMemberError
    );

    useEffect(() => {
        fetchTeam(groupId, accountId, teamId);
        fetchTeamMember(groupId, accountId, teamId, userId);
        fetchAccount(groupId, accountId);
    }, [accountId, teamId, userId]);

    const confirmDelete = () => {
        deleteMember(groupId, accountId, teamId, userId);
    };

    // Back to team view if deleted

    useEffect(() => {
        if (memberDeleted) {
            dispatch(appWindowAddNotification('Team Member deleted.', 'success'));
            history.push(dubberTeamViewUrl(groupId, accountId, teamId));
        }
    }, [memberDeleted]);

    return (
        <div className={classes.root}>
            {team !== null && account !== null && teamMember !== null
                && <>
                    <PageHeader text="Dubber Team Members" subtext={`${teamMember.firstName} ${teamMember.lastName}`}>
                        {authorizedFor(DubberPolicies.CanManageTeams, session.roles) && team &&
                            <>
                                <Button
                                    className={classes.headerButton}
                                    variant="text"
                                    color="primary"
                                    component={React.forwardRef((props, ref) => <Link to={dubberTeamMemberUpdateUrl(groupId, accountId, teamId, userId)} {...props as any} ref={ref} />)}>
                                    Update
                                </Button>

                                {!isAllTeam(team) &&
                                    <Button
                                        className={classes.headerButton}
                                        variant="text"
                                        onClick={() => setDeleteDialogOpen(true)}
                                        color="secondary">
                                        Unassign
                                    </Button>
                                }
                            </>
                        }
                    </PageHeader>


                    <TeamMemberSummary
                        groupId={groupId}
                        account={account}
                        team={team}
                        teamMember={teamMember}
                    />

                    <div className={classes.section}>
                        {teamMember.dubPoints.length === 0 && <Typography variant="body2">No Dub Points associated for this team.</Typography>}

                        {teamMember.dubPoints.length > 0 &&
                            <>
                                <Typography variant="body2">The following Dub Points are assigned to this user and visible to the team.</Typography>
                                <Paper className={classes.paper}>
                                    <DubPointTable
                                        accountId={accountId}
                                        groupId={groupId}
                                        dubPoints={teamMember.dubPoints} />
                                </Paper>
                            </>
                        }
                    </div>


                    <ConfirmationDialog
                        title={'Unassign User?'}
                        message={'Are you sure you want to unassigned this user from the team?'}
                        showWorking={isDeletingMember}
                        isOpen={deleteDialogOpen}
                        confirmText={'Unassign'}
                        cancelText={'Cancel'}
                        onCancel={() => setDeleteDialogOpen(false)}
                        onConfirm={() => confirmDelete()} />
                </>
            }
        </div>
    );
};

export default TeamMemberViewPage;