export const FAX2MAIL_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/fax2mail/:assignmentId';
export const FAX2MAIL_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/fax2mail/:assignmentId/update';
export const FAX2MAIL_ASSIGNMENT_STATEMENTS_URL = '/organizations/:organizationId/services/fax2mail/:assignmentId/statements';

export const FAX2MAIL_ORGANIZATION_INDEX_URL = '/fax2mail/organizations';

export const FAX2MAIL_SEAT_URL = '/fax2mail/seats';

export const FAX2MAIL_STATEMENT_INDEX_URL = '/fax2mail/statements';
export const FAX2MAIL_STATEMENT_CREATE_URL = '/fax2mail/statements/create';

export const fax2mailOrganizationIndexUrl = () => FAX2MAIL_ORGANIZATION_INDEX_URL;

export const fax2mailSeatUrl = () => FAX2MAIL_SEAT_URL;

export const fax2mailStatementIndexUrl = () => FAX2MAIL_STATEMENT_INDEX_URL;
export const fax2mailStatementCreateUrl = () => FAX2MAIL_STATEMENT_CREATE_URL;

export const fax2mailAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    FAX2MAIL_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const fax2mailAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    FAX2MAIL_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const fax2mailAssignmentStatementsUrl = (organizationId: string | number, assignmentId: string | number) =>
    FAX2MAIL_ASSIGNMENT_STATEMENTS_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));