import { Button, DialogActions, DialogContent, DialogContentText, FormControl, Input, InputLabel, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useValidationHelpers } from '../../form-helpers';
import history from '../../history';
import theme from '../../theme';
import { dashboardUrl } from '../dashboard/urls';
import { authMfaCompleteAction } from './actions';
import { useMfaAuthenticator } from './hooks';

const useStyles = makeStyles({
    formControl: {
        marginTop: theme.spacing(1),
        maginBottom: theme.spacing(1)
    }
});

interface AuthenticatorMethodProps {
    onCancel: () => void;
}

const AuthenticatorMethod = (props: AuthenticatorMethodProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [confirm, isConfirming, isConfirmed, confirmError, confirmErrorFields] = useMfaAuthenticator();
    const { isValid, ValidationMessage } = useValidationHelpers(confirmErrorFields);

    const { onCancel } = props;
    const [code, setCode] = useState('');

    const handleSubmit = () => {
        confirm(code);
    };

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (isConfirmed) {
            // Upon confirmation mark the session as confirmed and redirect to dashboard
            dispatch(authMfaCompleteAction());
            history.push(dashboardUrl());
        }
    }, [isConfirmed]);

    const textColor = confirmError !== null ? 'error' : 'inherit';
    const text = confirmError !== null ? confirmError : 'Enter the code from your authenticator app below.';

    return <>
        <DialogContent>
            <DialogContentText color={textColor}>{text}</DialogContentText>

            <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor="code">Code</InputLabel>
                <Input
                    autoFocus
                    disabled={false}
                    autoComplete="off"
                    onChange={evt => setCode(evt.target.value)}
                    onKeyDown={handleKeyDown}
                    error={!isValid('code')}
                    id="code"
                    type="code" />
                <ValidationMessage field="code" />
            </FormControl>
        </DialogContent>

        <DialogActions>
            <Button onClick={() => onCancel()} color="secondary" disabled={isConfirming}>
                Cancel
            </Button>

            <Button onClick={() => handleSubmit()} color="primary" disabled={isConfirming}>
                Submit
            </Button>
        </DialogActions>

        {isConfirming && <LinearProgress variant="indeterminate" color="primary" />}
    </>
};

export default AuthenticatorMethod;