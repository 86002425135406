import { useEffect, useState } from "react";
import { FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { fetchDubberGroups, Group } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type FetchGroupsFunc = () => void;

// Hook for fetching all groups

export const useFetchDubberGroups = (): TriggeredFetchHookResponse<FetchGroupsFunc, Group[]> => {
    interface Request {
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [groups, setGroups] = useState<Group[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchGroupsFunc = () => {
        setRequest({
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setGroups(null);

                try {
                    const acc = await fetchDubberGroups(api);

                    if (!didCancel) {
                        setIsFetching(false);
                        setGroups(acc);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setGroups(null);
                            setError('Unable to fetch dubber groups.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        groups,
        error
    ];
};