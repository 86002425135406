import { ApiCaller } from '../../../api';

export type Platform = 'Averistar' | 'Cisco';

/*
 * GET /api/organizations/:id/services/hosted-pbx:assignmentId
 * Get the Hosted PBX assignment details for an organization
 */

export interface HostedPbxAssignment {
    id: number;
    isActive: boolean;
    startedAt: string;
    cancelledAt: string | null;
    phoneConfigurationMethod: 'FTP' | 'DMS',
    platform: Platform;
    broadworksServiceProvider: string;
    broadworksGroup: string;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
    dubberGroup: string;
    dubberAccount: string | null;
}

export const fetchHostedPbxAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<HostedPbxAssignment> => {
    return await api<HostedPbxAssignment>(`organizations/${organizationId}/services/hosted-pbx/${assignmentId}`, 'GET');
};

/*
 * PUT /api/organizations/:id/services/hosted-pbx/:assignmentId
 * Updates the Hosted PBX assignment details for an organization
 */

export interface UpdateHostedPbxAssignment {
    platform: Platform;
    broadworksServiceProvider: string;
    broadworksGroup: string;
    bandwidthSubAccount: string;
    dubberAccount: string | null;
}

export const updateHostedPbxAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateHostedPbxAssignment): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/hosted-pbx/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * DELETE /api/organizations/:id/services/hosted-pbx/:assignmentId
 *
 * Marks the Hosted PBX assignment as cancelled 
 */
export const cancelHostedPbxAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/hosted-pbx/${assignmentId}`, 'DELETE');
};