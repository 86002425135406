import { ApiCaller } from '../../../api';
import { AddressBase, AddressType } from '../api';

export interface SubAccountIndex {
    id: string;
    name: string;
    description: string | null;
    locations: number;
    ucTrunkType: UcTrunkType | null;
}

type UcTrunkType = 'Cloud' | 'Premise' | 'Seats';

/**
 * GET /bandwidth/{accountId}/sub-accounts
 * 
 * Fetch all sub-accounts
 * 
 * @param api
 * @param accountId
 */
export const fetchBandwidthSubAccounts = async (api: ApiCaller, accountId: string): Promise<SubAccountIndex[]> => {
    return await api<SubAccountIndex[]>(`bandwidth/${accountId}/sub-accounts`, 'GET');
};

export interface SubAccountAddress extends AddressBase {
    addressType: AddressType;
    country: string;
}

export interface SubAccount {
    id: string;
    name: string;
    description: string;
    ucTrunkType: UcTrunkType | null;
    address: SubAccountAddress;
}

/**
 * GET /bandwidth/{accountId}/sub-accounts/{subAccountId}
 * 
 * Fetch details for a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 */
export const fetchBandwidthSubAccount = async (api: ApiCaller, accountId: string, subAccountId: string): Promise<SubAccount> => {
    return await api<SubAccount>(`bandwidth/${accountId}/sub-accounts/${subAccountId}`, 'GET');
};

export interface SubAccountNumbers {
    locations: {
        locationId: string;
        locationName: string;
        numbers: string[]
    }[];
}

/**
 * GET /bandwidth/{accountId}/sub-accounts/{subAccountId}/numbers
 * 
 * Fetch all numbers under a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 */
export const fetchBandwidthSubAccountNumbers = async (api: ApiCaller, accountId: string, subAccountId: string): Promise<SubAccountNumbers> => {
    return await api<SubAccountNumbers>(`bandwidth/${accountId}/sub-accounts/${subAccountId}/numbers`, 'GET');
};