import {
    AttachMoney as AttachMoneyIcon,
    BarChart,
    Business as BusinessIcon,
    Dashboard as DashboardIcon,
    DialerSip,
    ExpandLess,
    ExpandMore,
    Group as GroupIcon,
    Phone as PhoneIcon,
    PermPhoneMsg as PermPhoneMsgIcon,
    Mic as MicIcon,
    Print,
    Route as RouteIcon
} from '@mui/icons-material';
import { Box, Collapse, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DIRECT_ROUTING_LIVE, DRAWER_WIDTH, FAX2MAIL_LIVE, SIPTRUNKING_LIVE } from '../../constants';
import theme from '../../theme';
import { AdministratorPolicies } from '../administrators/policies';
import { administratorIndexUrl } from '../administrators/urls';
import { useSession } from '../auth/hooks';
import { authorizedFor } from '../auth/policies';
import { bandwidthLocationIndexUrl, bandwidthNumberIndexUrl, bandwidthOrderIndexUrl, bandwidthPortCheckUrl, bandwidthSubAccountIndexUrl } from '../bandwidth/urls';
import { Fax2MailPolicies } from '../fax2mail/policies';
import { fax2mailOrganizationIndexUrl, fax2mailSeatUrl, fax2mailStatementIndexUrl } from '../fax2mail/urls';
import { HostedPbxPolicies } from '../hosted-pbx/policies';
import { hpbxBulkUserUploadsIndexUrl, hpbxNineLineIndexUrl, hpbxNumbersIndexUrl, hpbxOrganizationsIndexUrl, hpbxStatementsIndexUrl, hpbxZeroTuchIndexUrl } from '../hosted-pbx/urls';
import { organizationIndexUrl } from '../organizations/urls';
import { ReportingPolicies } from '../reporting/policies';
import { reportingDidUsageUrl, reportingHpbxUsageUrl } from '../reporting/urls';
import { SipTrunkingPolicies } from '../sip-trunking/policies';
import { sipTrunkingOrganizationIndexUrl } from '../sip-trunking/urls';
import CellTowerIcon from '@mui/icons-material/CellTower';
import { dubberAccountIndexUrl, dubberUnidentifiedDubPointIndexUrl } from '../dubber/urls';
import { webexBulkUploadsIndexUrl, webexOrganizationIndexUrl, webexRedSkyIndexUrl } from '../webex/urls';
import { directRoutingOrganizationIndexUrl } from '../direct-routing/urls';
import { WebexPolicies } from '../webex/policies';

const useStyles = makeStyles(() => ({
    // Drawer is hidden on mobile devices and is toggled by a Menu button
    // in the header bar
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0
        }
    },
    // The area above the drawer navigation. Shows the All Covered logo
    aboveNav: {
        ...theme.mixins.toolbar,
        textAlign: 'center'
    },
    paper: {
        backgroundColor: '#E1E2E1',
        borderRightColor: blueGrey[200],
        width: DRAWER_WIDTH
    },
    navMenuText: {
        paddingLeft: 0
    },
    nestedNavMenu: {
        paddingLeft: theme.spacing(2)
    },
    nestedNavMenuText: {
        fontSize: '0.9rem',
        paddingLeft: 0
    }
}));

interface Props {
    mobileNavOpen: boolean;
    onDrawerToggle: () => void;
    selectedTopNav?: TopNavItem;
    selectedSubNav?: SubNavItem;
}

export type TopNavItem =
    'dashboard'
    | 'administrators'
    | 'bandwidth'
    | 'direct-routing'
    | 'dubber'
    | 'fax2mail'
    | 'reporting'
    | 'hosted-pbx'
    | 'oncall-rotation'
    | 'organizations'
    | 'sip-trunking'
    | 'voice-rates'
    | 'webex';

export type SubNavItem =
    '9line'
    | 'accounts'
    | 'bulk-uploads'
    | 'did-usage'
    | 'hpbx-usage'
    | 'locations'
    | 'numbers'
    | 'orders'
    | 'organizations'
    | 'port-check'
    | 'redsky'
    | 'seats'
    | 'statements'
    | 'sub-accounts'
    | 'unidentified-dub-points'
    | 'zero-touch';

/**
 * Drawer containing the app's navigation
 * Drawer will either be permanent on desktop or toggleable on mobile
 *
 * @param props
 */
export default function NavDrawer(props: Props) {
    const { mobileNavOpen, onDrawerToggle, selectedTopNav, selectedSubNav } = props;
    const classes = useStyles();

    return (
        <Box component="nav" className={classes.drawer}>
            {/* Drawer is toggleable on desktop */}
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileNavOpen}
                    onClose={onDrawerToggle}
                    classes={{
                        paper: classes.paper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}>
                    <DrawerContents selectedTopNav={selectedTopNav} selectedSubNav={selectedSubNav} />
                </Drawer>
            </Hidden>
            {/* Drawer is permanent on desktop */}
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.paper,
                    }}
                    variant="permanent"
                    open>
                    <DrawerContents selectedTopNav={selectedTopNav} selectedSubNav={selectedSubNav} />
                </Drawer>
            </Hidden>
        </Box>
    );
}

interface DrawerContentsProps {
    selectedTopNav?: TopNavItem;
    selectedSubNav?: SubNavItem;
}

/**
 * The contents of the Navigation Drawer
 *
 * @constructor
 */
function DrawerContents(props: DrawerContentsProps) {
    const classes = useStyles();
    const { selectedTopNav, selectedSubNav } = props;
    const [currentNav, setCurrentNav] = useState<TopNavItem | null>(selectedTopNav || null);

    const { roles } = useSession();

    useEffect(() => {
        if (selectedTopNav) {
            setCurrentNav(selectedTopNav);
        } else {
            setCurrentNav(null);
        }
    }, [selectedTopNav]);


    const toggleNav = (nav: TopNavItem) => {
        if (nav === currentNav) {
            setCurrentNav(null);
        } else {
            setCurrentNav(nav);
        }
    }

    return (
        <div>
            <div className={classes.aboveNav} />

            <List>
                <ListItem selected={selectedTopNav === 'dashboard'} button component={React.forwardRef((props, ref) => <Link to="/" {...props as any} ref={ref} />)}>
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Dashboard" />
                </ListItem>
            </List>

            <Divider />

            <List>
                {authorizedFor(AdministratorPolicies.CanViewAndManage, roles) &&
                    <ListItem selected={selectedTopNav === 'administrators'} button
                        component={React.forwardRef((props, ref) => <Link to={administratorIndexUrl()} {...props as any} ref={ref} />)}>
                        <ListItemIcon><GroupIcon /></ListItemIcon>
                        <ListItemText className={classes.navMenuText} inset primary="Administrators" />
                    </ListItem>
                }

                <ListItem button onClick={() => toggleNav('bandwidth')}>
                    <ListItemIcon><CellTowerIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Bandwidth" />
                    {currentNav === 'bandwidth' ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={currentNav === 'bandwidth'} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'bandwidth' && selectedSubNav === 'sub-accounts'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={bandwidthSubAccountIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Sub-Accounts" />
                        </ListItem>
                    </List>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'bandwidth' && selectedSubNav === 'locations'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={bandwidthLocationIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Locations" />
                        </ListItem>
                    </List>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'bandwidth' && selectedSubNav === 'numbers'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={bandwidthNumberIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Numbers" />
                        </ListItem>
                    </List>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'bandwidth' && selectedSubNav === 'orders'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={bandwidthOrderIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Orders" />
                        </ListItem>
                    </List>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'bandwidth' && selectedSubNav === 'port-check'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={bandwidthPortCheckUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Port Checker" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => toggleNav('webex')}>
                    <ListItemIcon><PermPhoneMsgIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Cloud PBX w/ Webex" />
                    {currentNav === 'webex' ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={currentNav === 'webex'} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'webex' && selectedSubNav === 'organizations'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={webexOrganizationIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Organizations" />
                        </ListItem>
                        {authorizedFor(WebexPolicies.CanUseBulkUpload, roles) &&
                            <ListItem
                                selected={selectedTopNav === 'webex' && selectedSubNav === 'bulk-uploads'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={webexBulkUploadsIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Bulk Upload Tool" />
                            </ListItem>
                        }
                        {authorizedFor(WebexPolicies.CanUseRedSkyTool, roles) &&
                            <ListItem
                                selected={selectedTopNav === 'webex' && selectedSubNav === 'redsky'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={webexRedSkyIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="RedSky E911 Tool" />
                            </ListItem>
                        }
                    </List>
                </Collapse>

                {DIRECT_ROUTING_LIVE && <>
                    <ListItem button onClick={() => toggleNav('direct-routing')}>
                        <ListItemIcon><RouteIcon /></ListItemIcon>
                        <ListItemText className={classes.navMenuText} inset primary="Direct Routing for Teams" />
                        {currentNav === 'direct-routing' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={currentNav === 'direct-routing'} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <ListItem
                                selected={selectedTopNav === 'direct-routing' && selectedSubNav === 'organizations'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={directRoutingOrganizationIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Organizations" />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
                }

                <ListItem button onClick={() => toggleNav('dubber')}>
                    <ListItemIcon><MicIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Dubber" />
                    {currentNav === 'dubber' ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={currentNav === 'dubber'} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'dubber' && selectedSubNav === 'accounts'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={dubberAccountIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Accounts" />
                        </ListItem>
                        <ListItem
                            selected={selectedTopNav === 'dubber' && selectedSubNav === 'unidentified-dub-points'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={dubberUnidentifiedDubPointIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Unidentified Dub Points" />
                        </ListItem>
                    </List>
                </Collapse>

                {FAX2MAIL_LIVE && <>
                    <ListItem button onClick={() => toggleNav('fax2mail')}>
                        <ListItemIcon><Print /></ListItemIcon>
                        <ListItemText className={classes.navMenuText} inset primary="Fax2Mail" />
                        {currentNav === 'fax2mail' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={currentNav === 'fax2mail'} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <ListItem
                                selected={selectedTopNav === 'fax2mail' && selectedSubNav === 'organizations'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={fax2mailOrganizationIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Organizations" />
                            </ListItem>

                            {authorizedFor(Fax2MailPolicies.CanManageSeats, roles) &&
                                <ListItem
                                    selected={selectedTopNav === 'fax2mail' && selectedSubNav === 'seats'}
                                    button
                                    className={classes.nestedNavMenu}
                                    component={React.forwardRef((props, ref) => <Link to={fax2mailSeatUrl()} {...props as any} ref={ref} />)}>
                                    <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Seats" />
                                </ListItem>
                            }

                            {authorizedFor(Fax2MailPolicies.CanViewStatements, roles) &&
                                <ListItem
                                    selected={selectedTopNav === 'fax2mail' && selectedSubNav === 'statements'}
                                    button
                                    className={classes.nestedNavMenu}
                                    component={React.forwardRef((props, ref) => <Link to={fax2mailStatementIndexUrl()} {...props as any} ref={ref} />)}>
                                    <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Statements" />
                                </ListItem>
                            }
                        </List>
                    </Collapse>
                </>}

                <ListItem button onClick={() => toggleNav('hosted-pbx')}>
                    <ListItemIcon><PhoneIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Hosted PBX" />
                    {currentNav === 'hosted-pbx' ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={currentNav === 'hosted-pbx'} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListItem
                            selected={selectedTopNav === 'hosted-pbx' && selectedSubNav === 'organizations'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={hpbxOrganizationsIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Organizations" />
                        </ListItem>

                        {authorizedFor(HostedPbxPolicies.CanUse9LineTool, roles) &&
                        <ListItem
                            selected={selectedTopNav === 'hosted-pbx' && selectedSubNav === '9line'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={hpbxNineLineIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="9Line E911 Tool" />
                        </ListItem>
                        }

                        {authorizedFor(HostedPbxPolicies.CanUseBulkUpload, roles) &&
                            <ListItem
                                selected={selectedTopNav === 'hosted-pbx' && selectedSubNav === 'bulk-uploads'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={hpbxBulkUserUploadsIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Bulk Upload Tool" />
                            </ListItem>
                        }

                        {authorizedFor(HostedPbxPolicies.CanManageNumbers, roles) &&
                            <ListItem
                                selected={selectedTopNav === 'hosted-pbx' && selectedSubNav === 'numbers'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={hpbxNumbersIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Numbers" />
                            </ListItem>
                        }


                        <ListItem
                            selected={selectedTopNav === 'hosted-pbx' && selectedSubNav === 'zero-touch'}
                            button
                            className={classes.nestedNavMenu}
                            component={React.forwardRef((props, ref) => <Link to={hpbxZeroTuchIndexUrl()} {...props as any} ref={ref} />)}>
                            <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Zero Touch Provisioning" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem selected={selectedTopNav === 'organizations'} button
                    component={React.forwardRef((props, ref) => <Link to={organizationIndexUrl()} {...props as any} ref={ref} />)}>
                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                    <ListItemText className={classes.navMenuText} inset primary="Organizations" />
                </ListItem>

                {(authorizedFor(ReportingPolicies.CanViewDidUsageReport, roles) || authorizedFor(ReportingPolicies.CanViewHpbxUsageReport, roles)) &&
                    <>
                        <ListItem button onClick={() => toggleNav('reporting')}>
                            <ListItemIcon><BarChart /></ListItemIcon>
                            <ListItemText className={classes.navMenuText} inset primary="Reports" />
                            {currentNav === 'reporting' ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={currentNav === 'reporting'} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {authorizedFor(ReportingPolicies.CanViewDidUsageReport, roles) &&
                                    <ListItem
                                        selected={selectedTopNav === 'reporting' && selectedSubNav === 'did-usage'}
                                        button
                                        className={classes.nestedNavMenu}
                                        component={React.forwardRef((props, ref) => <Link to={reportingDidUsageUrl()} {...props as any} ref={ref} />)}>
                                        <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="DID Usage" />
                                    </ListItem>
                                }

                                {authorizedFor(ReportingPolicies.CanViewHpbxUsageReport, roles) &&
                                    <ListItem
                                        selected={selectedTopNav === 'reporting' && selectedSubNav === 'hpbx-usage'}
                                        button
                                        className={classes.nestedNavMenu}
                                        component={React.forwardRef((props, ref) => <Link to={reportingHpbxUsageUrl()} {...props as any} ref={ref} />)}>
                                        <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Hosted PBX Usage" />
                                    </ListItem>
                                }
                            </List>
                        </Collapse>
                    </>
                }

                {SIPTRUNKING_LIVE && <>
                    <ListItem button onClick={() => toggleNav('sip-trunking')}>
                        <ListItemIcon><DialerSip /></ListItemIcon>
                        <ListItemText className={classes.navMenuText} inset primary="SIP Trunking" />
                        {currentNav === 'sip-trunking' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={currentNav === 'sip-trunking'} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <ListItem
                                selected={selectedTopNav === 'sip-trunking' && selectedSubNav === 'organizations'}
                                button
                                className={classes.nestedNavMenu}
                                component={React.forwardRef((props, ref) => <Link to={sipTrunkingOrganizationIndexUrl()} {...props as any} ref={ref} />)}>
                                <ListItemText classes={{ primary: classes.nestedNavMenuText }} inset primary="Organizations" />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
                }
            </List>
        </div>
    );
}