import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import AddressBlock from '../AddressBlock';
import { SubAccount } from '../api';
import { bandwidthSubAccountViewUrl } from '../urls';
import { Location } from './api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    accountId: string;
    subAccount: SubAccount;
    location: Location;
}

// Location Summary Box
const LocationSummary = (props: Props) => {
    const classes = useStyles();
    const { accountId, location, subAccount } = props;

    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(accountId, subAccount.id)} {...props} />);

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Sub-Account</Typography>
                    <Typography>
                        <Link component={LinkComponent}>{subAccount.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Default Location</Typography>
                    <Typography>
                        {location.isDefault ? 'Yes' : 'No'}
                    </Typography>
                </Grid>

                {location.description &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Description</Typography>
                        <Typography>
                            {location.description}
                        </Typography>
                    </Grid>
                }

                {location.address &&
                    <>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">Address</Typography>
                            <Typography>
                                <AddressBlock address={location.address} />
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Paper>
    );
}

export default LocationSummary;