export const DIRECT_ROUTING_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/direct-routing/:assignmentId';
export const DIRECT_ROUTING_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/direct-routing/:assignmentId/update';

export const DIRECT_ROUTING_ORGANIZATION_INDEX_URL = '/direct-routing/organizations';

export const directRoutingAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    DIRECT_ROUTING_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const directRoutingAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    DIRECT_ROUTING_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const directRoutingOrganizationIndexUrl = () => DIRECT_ROUTING_ORGANIZATION_INDEX_URL;

