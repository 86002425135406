import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import { Account, User } from '../api';
import { productLabel } from '../helpers';
import { dubberAccountViewUrl, dubberUserViewUrl } from '../urls';
import { DubPoint } from './api';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    groupId: string;
    account: Account;
    user: User | null;
    username: string;
    dubPoint: DubPoint;
}

const DubPointSummary = (props: Props) => {
    const classes = useStyles();
    const { groupId, account, dubPoint, user, username } = props;

    const AccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(groupId, account.id)} {...props} />);
    const UserLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberUserViewUrl(groupId, account.id, user?.id || "")} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>
                        <Link component={AccountLinkComponent}>{account.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">User</Typography>
                    <Typography>
                        {user ? <Link component={UserLinkComponent}>{username}</Link> : username}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">ID</Typography>
                    <Typography>{dubPoint.id}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                    <Typography>{dubPoint.type}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Label</Typography>
                    <Typography>{dubPoint.label}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>{dubPoint.status}</Typography>
                </Grid>

                {dubPoint.product &&
                    <>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">AI</Typography>
                            <Typography>{dubPoint.ai ? "Yes" : "No"}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">Product</Typography>
                            <Typography>{productLabel(dubPoint.product)}</Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Paper>);
};

export default DubPointSummary;