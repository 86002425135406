import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { Fax2MailPolicies } from '../policies';
import { Statement } from '../statements/api';
import { useFetchAllFax2MailStatements } from '../statements/hooks';
import StatementRow from './StatementRow';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    headingContainer: {
        display: 'flex'
    },
    h4: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(1)
    }
}));

interface Props {
    organizationId: number;
    assignmentId: number;
    onLoadStateChange: (loading: boolean) => void;
    showGenerateButton?: boolean;
}

const padMonth = (i: number) => i < 10 ? ('0' + i) : i;

const sortStatements = (statements: Statement[] | null) => {
    if (statements === null) {
        return null
    }

    return [...statements].sort((a, b) => {
        const aDate = `${a.billingYear}-${padMonth(a.billingMonth)}-01`;
        const bDate = `${b.billingYear}-${padMonth(b.billingMonth)}-01`;

        const aUnix = moment(aDate).utc().unix();
        const bUnix = moment(bDate).utc().unix();

        if (bUnix === aUnix) {
            return b.revision - a.revision;
        }

        return bUnix - aUnix;
    });
};

/**
 * Displays a list of all Fax2Mail statements
 *
 * @param props
 * @constructor
 */
const StatementsTabPane = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { onLoadStateChange, organizationId, assignmentId } = props;
    const showGenerateButton = props.showGenerateButton == undefined ? true : props.showGenerateButton;
    const session = useSession();

    const [fetch, isFetching, statements, fetchError] = useFetchAllFax2MailStatements();

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [organizationId, assignmentId])

    // Trigger progress bar on parent container when loading statements
    useEffect(() => {
        onLoadStateChange(isFetching);
    }, [isFetching]);

    // Display error notification if statement retrieval fails
    useEffect(() => {
        if (fetchError !== null) {
            dispatch(appWindowAddNotification(fetchError, 'error'));
        }
    }, [fetchError]);

    // Sort statements by billing period and revision
    const sortedStatements = useMemo(() => sortStatements(statements), [statements]);

    return (
        <>
            <div className={classes.headingContainer}>
                <Typography variant="h4" className={classes.h4}>All Statements</Typography>

                {authorizedFor(Fax2MailPolicies.CanManageStatements, session.roles) &&
                    showGenerateButton &&
                    <Button
                        className={classes.button}
                        variant="text"
                        onClick={() => false}
                        color="primary">
                        Add New
                    </Button>
                }
            </div>

            {!isFetching &&
                sortedStatements !== null &&
                sortedStatements.length === 0 &&
                <Typography>No statements have been generated for this organization.</Typography>
            }

            {sortedStatements !== null &&
                sortedStatements.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Billing Period</TableCell>
                            <TableCell>Revision</TableCell>
                            <TableCell>Generated</TableCell>
                            <TableCell>Total Pages In / Out</TableCell>
                            <TableCell>Total Charges</TableCell>
                            <TableCell>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedStatements.map(stmt =>
                            <StatementRow
                                organizationId={organizationId}
                                assignmentId={assignmentId}
                                statement={stmt}
                                key={stmt.id} />)}
                    </TableBody>
                </Table>
            }
        </>
    );
};

export default StatementsTabPane;