import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, InputLabel, Link, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FieldError } from '../../../api';
import { STATES } from '../../../constants';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { ServiceType } from '../api';
import { LocationAddressForm, LocationForm, LocationForm as LocationFormData } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    isWholesaleAccount: boolean;
    accountService: ServiceType,
    formValues: LocationFormData,
    isSubmitting: boolean;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    onFieldChange: (fieldName: keyof LocationFormData, value: boolean | string | string[] | number | null | LocationAddressForm) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

const LocationForm = (props: Props) => {
    const classes = useStyles();
    const { isWholesaleAccount, formValues, isSubmitting, onFieldChange, onFieldKeyDown, accountService, validationMessage, validationFieldMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(validationFieldMessages);

    const directionOptions = ['N', 'E', 'S', 'W', 'NE', 'SE', 'NW', 'SW'];

    // Handle a change to the host fields
    const onHostFieldChange = (field: 'terminationHosts' | 'originationHosts', idx: number, value: string) => {
        let hosts = formValues[field];
        hosts[idx] = value;

        onFieldChange(field, hosts);
    };

    // Handle when any address field is changed
    const onAddressFieldChange = (field: keyof LocationAddressForm, value: string | null) => {
        // Disregard if address is null like we're updating a Wholesale account
        if (formValues.address !== null) {
            onFieldChange('address', {
                ...formValues.address,
                [field]: value
            });
        }
    };

    return (
        <>
            {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('name')}>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            autoFocus
                            value={formValues.name || ''}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('name', evt.target.value)}
                            onKeyDown={onFieldKeyDown}
                            id="name"
                            type="text" />
                        <ValidationMessage field="name" />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('isDefault')}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formValues.isDefault}
                                    onChange={evt => onFieldChange('isDefault', evt.target.checked)}
                                />
                            }
                            label="Default Location"
                            labelPlacement="top"
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <FormControl className={classes.formControl} fullWidth error={!isValid('description')}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <Input
                    value={formValues.description || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('description', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="name"
                    type="text" />
                <ValidationMessage field="description" />
            </FormControl>

            <Divider className={classes.divider} />

            <Typography variant="h4">Origination Settings</Typography>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('callingNameDisplayed')} component="fieldset" variant="standard">
                        <FormLabel component="legend">Caller Name Displayed</FormLabel>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formValues.callingNameDisplayed}
                                        onChange={evt => onFieldChange('callingNameDisplayed', evt.target.checked)}
                                        disabled={isSubmitting} />
                                }
                                label="Yes"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formValues.callingNameEnforced}
                                        onChange={evt => onFieldChange('callingNameEnforced', evt.target.checked)}
                                        disabled={isSubmitting} />
                                }
                                label="Enforced for All Numbers"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('originationDistribution')}>
                        <InputLabel htmlFor="originationDistribution">Distribution</InputLabel>

                        <Select
                            id="originationDistribution"
                            native={true}
                            margin="none"
                            disabled={isSubmitting || accountService === 'BroadCloudPaas'}
                            value={formValues.originationDistribution || 'None'}
                            input={<Input name="originationDistribution" id="originationDistribution" />}
                            onChange={evt => onFieldChange('originationDistribution', evt.target.value)}
                            onKeyDown={onFieldKeyDown}>
                            <option value="None">None</option>
                            <option value="Random">Random</option>
                            <option value="Sequential">Sequential</option>
                        </Select>

                        <ValidationMessage field="originationDistribution" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="originationHosts" shrink={true}>Hosts</InputLabel>
                    {[...Array(4).keys()].map(i => {
                        return (
                            <div key={'originationHosts' + i}>
                                <Input
                                    value={formValues.originationHosts[i] || ''}
                                    readOnly={accountService === 'BroadCloudPaas'}
                                    disabled={isSubmitting}
                                    onChange={evt => onHostFieldChange('originationHosts', i, evt.target.value)}
                                    onKeyDown={onFieldKeyDown}
                                    id={`originationHosts[${i}]`}
                                    type="text" />
                                <ValidationMessage field={`originationHosts[${i}]`} />
                            </div>
                        );
                    })}

                    <ValidationMessage field="originationHosts" />
                </Grid>
            </Grid>


            <Divider className={classes.divider} />

            <Typography variant="h4">Termination Settings</Typography>

            {isWholesaleAccount && <Typography>Termination Hosts can only be set by Bandwidth. <Link href="https://support.bandwidth.com/hc/en-us/requests/new" target="_blank">Create ticket</Link></Typography>}

            {!isWholesaleAccount &&
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('trafficAllowed')}>
                                <InputLabel htmlFor="trafficAllowed">Allowed Traffic</InputLabel>

                                <Select
                                    id="trafficAllowed"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting || accountService === 'BroadCloudPaas'}
                                    value={formValues.trafficAllowed}
                                    input={<Input name="trafficAllowed" id="trafficAllowed" />}
                                    onChange={evt => onFieldChange('trafficAllowed', evt.target.value)}
                                    onKeyDown={onFieldKeyDown}>
                                    <option value="Domestic">Domestic</option>
                                    <option value="Lite">International Lite</option>
                                    {accountService === 'BroadCloudPaas' && <option value="Full">International</option>}
                                </Select>

                                <ValidationMessage field="trafficAllowed" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="terminationHosts" shrink={true}>Hosts</InputLabel>
                            {[...Array(4).keys()].map(i => {
                                return (
                                    <div key={'terminationHosts' + i}>
                                        <Input
                                            value={formValues.terminationHosts[i] || ''}
                                            readOnly={accountService === 'BroadCloudPaas'}
                                            disabled={isSubmitting}
                                            onChange={evt => onHostFieldChange('terminationHosts', i, evt.target.value)}
                                            onKeyDown={onFieldKeyDown}
                                            id={`terminationHosts[${i}]`}
                                            type="text" />
                                        <ValidationMessage field={`terminationHosts[${i}]`} />
                                    </div>);
                            })}

                            <ValidationMessage field="terminationHosts" />
                        </Grid>
                    </Grid>
                </>
            }


            {!isWholesaleAccount && formValues.address &&
                <>
                    <Divider className={classes.divider} />

                    <Typography variant="h4">Address Settings</Typography>

                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.housePrefix')}>
                                <InputLabel htmlFor="address.housePrefix">Number Prefix</InputLabel>

                                <Input
                                    value={formValues.address.housePrefix || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('housePrefix', evt.target.value)}
                                    id="address.housePrefix"
                                    type="text" />

                                <ValidationMessage field="address.housePrefix" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('address.houseNumber')}>
                                <InputLabel htmlFor="address.houseNumber">House Number</InputLabel>

                                <Input
                                    value={formValues.address.houseNumber || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('houseNumber', evt.target.value)}
                                    id="address.houseNumber"
                                    type="text" />

                                <ValidationMessage field="address.houseNumber" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.houseSuffix')}>
                                <InputLabel htmlFor="address.housePrefix">Number Suffix</InputLabel>

                                <Input
                                    value={formValues.address.houseSuffix || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('houseSuffix', evt.target.value)}
                                    id="address.houseSuffix"
                                    type="text" />

                                <ValidationMessage field="address.houseSuffix" />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.preDirectional')}>
                                <InputLabel htmlFor="address.preDirectional">Pre Dir</InputLabel>

                                <Select
                                    id="address.preDirectional"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.address.preDirectional || ''}
                                    input={<Input name="address.preDirectional" id="address.preDirectional" />}
                                    onChange={evt => onAddressFieldChange('preDirectional', evt.target.value)}>
                                    <option value=""></option>
                                    {directionOptions.map(o => <option key={'preDirectional' + o} value={o}>{o}</option>)}
                                </Select>

                                <ValidationMessage field="address.preDirectional" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('address.streetName')}>
                                <InputLabel htmlFor="address.preDirectional">Street Name</InputLabel>

                                <Input
                                    value={formValues.address.streetName || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('streetName', evt.target.value)}
                                    id="address.streetName"
                                    type="text" />

                                <ValidationMessage field="address.streetName" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.streetSuffix')}>
                                <InputLabel htmlFor="address.streetSuffix">Street Suffix</InputLabel>

                                <Input
                                    value={formValues.address.streetSuffix || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('streetSuffix', evt.target.value)}
                                    id="address.streetSuffix"
                                    type="text" />

                                <ValidationMessage field="address.streetSuffix" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.postDirectional')}>
                                <InputLabel htmlFor="address.postDirectional">Post Dir</InputLabel>

                                <Select
                                    id="address.postDirectional"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.address.postDirectional || ''}
                                    input={<Input name="address.postDirectional" id="address.postDirectional" />}
                                    onChange={evt => onAddressFieldChange('postDirectional', evt.target.value)}>
                                    <option value=""></option>
                                    {directionOptions.map(o => <option key={'postDirectional' + o} value={o}>{o}</option>)}
                                </Select>

                                <ValidationMessage field="address.postDirectional" />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.addressLine2')}>
                        <InputLabel htmlFor="address.addressLine2">Address Line 2</InputLabel>

                        <Input
                            value={formValues.address.addressLine2 || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('addressLine2', evt.target.value)}
                            id="address.addressLine2"
                            type="text" />

                        <ValidationMessage field="address.addressLine2" />
                    </FormControl>

                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('address.city')}>
                                <InputLabel htmlFor="address.city">City</InputLabel>

                                <Input
                                    value={formValues.address.city || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('city', evt.target.value)}
                                    id="address.city"
                                    type="text" />

                                <ValidationMessage field="address.city" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('address.stateCode')}>
                                <InputLabel htmlFor="address.stateCode">State</InputLabel>

                                <Select
                                    id="address.stateCode"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.address.stateCode || ''}
                                    input={<Input name="address.stateCode" id="address.stateCode" />}
                                    onChange={evt => onAddressFieldChange('stateCode', evt.target.value)}>
                                    {StateOptions()}
                                </Select>

                                <ValidationMessage field="address.stateCode" />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('address.zip')}>
                                <InputLabel htmlFor="address.zip">Zip Code</InputLabel>

                                <Input
                                    value={formValues.address.zip || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('zip', evt.target.value)}
                                    id="address.zip"
                                    type="text" />

                                <ValidationMessage field="address.zip" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('address.plusFour')}>
                                <InputLabel htmlFor="address.plusFour">Zip Plus 4</InputLabel>

                                <Input
                                    value={formValues.address.plusFour || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onAddressFieldChange('plusFour', evt.target.value)}
                                    id="address.plusFour"
                                    type="text" />

                                <ValidationMessage field="address.plusFour" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            }

            {formValues.premiseTrunks !== null &&
                <>
                    <Divider className={classes.divider} />

                    <Typography variant="h4">Trunk Settings</Typography>

                    <FormControl className={classes.formControl} fullWidth error={!isValid('premiseTrunks')}>
                        <InputLabel htmlFor="premiseTrunks" shrink={true}>Premise Trunks</InputLabel>
                        <Input
                            value={formValues.premiseTrunks}
                            readOnly={true}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('premiseTrunks', evt.target.value)}
                            id="premiseTrunks"
                            type="text" />

                        <ValidationMessage field="premiseTrunks" />
                    </FormControl>
                </>
            }
        </>
    );
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value="" />];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};

export default LocationForm;
