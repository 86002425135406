import { FormControl, Input, TextField, InputLabel, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { MobileDatePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import theme from '../../../theme';
import { Organization } from '../organizations/api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    isSubmitting: boolean;
    organizations: Organization[];
    onSelect: (billingYear: number, billingMonth: number, assignmentId: number | null) => void;
}

/**
 * Form for selecting the billing period and organization to create a statement form
 * @param props
 */
export const PeriodAndOrganizationSelection = (props: Props) => {
    const classes = useStyles();
    const { isSubmitting, onSelect, organizations } = props;

    const [billingPeriod, setBillingPeriod] = useState(moment().format('YYYY-MM-01'));
    const [assignmentId, setAssignmentId] = useState<number | null>(null);

    // Only allow user to select organizations with that had active f2m assignments during the selected billing period
    const filteredOrganizations = useMemo(() => {
        var pSelected = moment(billingPeriod).utc();

        return organizations.filter(o => {
            const pStart = moment(o.startedAt).utc().startOf('month');
            const pEnd = moment(!o.isActive ? o.cancelledAt : undefined).utc().endOf('month');

            return pSelected.isBetween(pStart, pEnd);
        });
    }, [billingPeriod]);

    useEffect(() => {
        const m = moment(billingPeriod).utc();
        const billingYear = parseInt(m.format('YYYY'));
        const billingMonth = parseInt(m.format('MM'));

        onSelect(billingYear, billingMonth, assignmentId);

    }, [billingPeriod, assignmentId]);

    return (
        <div className={classes.root}>
            <MobileDatePicker
                //fullWidth={true}
                disabled={isSubmitting}
                label="Billing Period"
                views={["year", "month"]}
                value={moment(billingPeriod).utc()}
                InputProps={{
                    disableUnderline: false
                }}
                onChange={evt => {
                    setBillingPeriod(evt ? evt.format('YYYY-MM-01') : '')
                }}
                renderInput={props => <TextField {...props} variant="standard" />}
            />

            <FormControl className={classes.formControl} fullWidth required error={false}>
                <InputLabel htmlFor="type">Organization</InputLabel>

                <Select
                    id="assignmentId"
                    native={true}
                    margin="none"
                    disabled={isSubmitting}
                    value={assignmentId || ''}
                    input={<Input name="type" id="type" />}
                    onChange={evt => {
                        setAssignmentId(evt.target.value ? parseInt(String(evt.target.value)) : null);
                    }}
                >
                    <option></option>
                    {filteredOrganizations.map(o => <option key={o.assignmentId} value={o.assignmentId}>{o.name}</option>)}
                </Select>
            </FormControl>

        </div>
    );
};


export default PeriodAndOrganizationSelection;