import { AdministratorRole } from '../administrators/api';
import { SessionStatus } from './api';

export const INITIAL_STATE: AuthState = {
    token: null,
    expiresAt: null,
    roles: [],
    administratorId: null,
    status: null
};

/**
 * Authentication State
 */
export interface AuthState {
    token: string | null;
    expiresAt: string | null;
    roles: AdministratorRole[];
    administratorId: number | null;
    status: SessionStatus | null;
}