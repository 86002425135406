import { FormControl, Input, InputLabel, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import theme from '../../../theme';
import { Account } from '../accounts/api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    disabled: boolean;
    accounts: Account[];
    onSelected: (accountId: string | null) => void;
}

export const SearchForm = (props: Props) => {
    const classes = useStyles();
    const { accounts, disabled, onSelected } = props;
    const [selected, setSelected] = useState<string | null>(null);

    // Alert parent when selected account changes
    useEffect(() => {
        onSelected(selected);
    }, [selected]);

    return (
        <>
            <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor="accountId">Account</InputLabel>
                <Select
                    id="accountId"
                    native
                    margin="none"
                    value={selected || ''}
                    disabled={disabled}
                    input={<Input name="accountId" id="accountId" />}
                    onChange={evt => {
                        setSelected(evt.target.value || null);
                    }}
                    onKeyDown={() => false}>
                    <option value="" />
                    {accounts.map(acc => <option key={acc.id} value={acc.id}>{acc.name} ({acc.id})</option>)}
                </Select>
            </FormControl>
        </>
    );
};

export default SearchForm;