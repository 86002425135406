import { FormControl, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect } from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { useFetchDirectRoutingIPilotCustomers } from '../hooks';
import { CreateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    assignmentId?: number;
    formValues: CreateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: CreateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: CreateAssignment): boolean => {
    return form.iPilotAccountId != null;
};

/**
 * Direct Routing assignment form
 * @param props
 * @constructor
 */
const CreateAssignmentForm = (props: Props) => {
    const { errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const [fetchCustomers, isFetchingCustomers, customers, fetchCustomersError] = useFetchDirectRoutingIPilotCustomers();


    useEffect(() => {
        fetchCustomers();
    }, [])

    const handleFieldChange = (fieldName: keyof CreateAssignment, value: number | null) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    const displayError = errorMessage || fetchCustomersError;

    return (
        <>
            {!isFetchingCustomers && customers !== null
                ?
                <>
                    {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

                    <FormControl className={classes.formControl} fullWidth required error={!isValid('iPilotAccountId')}>
                        <InputLabel htmlFor="iPilotAccountId">iPilot Account</InputLabel>
                        <Select
                            id="iPilotAccountId"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.iPilotAccountId || ''}
                            input={<Input name="bandwidthAccount" id="iPilotAccountId" />}
                            onChange={evt => handleFieldChange('iPilotAccountId', evt.target.value ? parseInt(String(evt.target.value)) : null)}>
                            <option key="" value="" />

                            {customers.map(c => {
                                return <option key={c.id} value={c.id}>{c.name}</option>;
                            })}
                        </Select>

                        <ValidationMessage field="iPilotAccountId" />
                    </FormControl>
                </>
                : <LinearProgress />
            }
        </>
    );
};

export default CreateAssignmentForm;