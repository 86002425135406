import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { PortabilityError } from './api';

const useStyles = makeStyles(() => ({
    gridItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(1)
    },
    numbers: {
        listStyle: 'none',
        padding: 0,
        margin: 0
    }
}));

interface Props {
    supportedNumbers: string[];
    unsupportedNumbers: string[];
    portabilityErrors: PortabilityError[];
}

const OverviewPane = (props: Props) => {
    const classes = useStyles();
    const { supportedNumbers, unsupportedNumbers, portabilityErrors } = props;

    return (
        <Grid container>
            <Grid xs={6} item className={classes.gridItem}>
                <Typography variant="h4" color="primary">Supported Numbers ({supportedNumbers.length})</Typography>

                {supportedNumbers.length > 0
                    ?
                    <Typography component="div" className={classes.content}>
                        <ul className={classes.numbers}>
                            {supportedNumbers.sort().map(n => {
                                return <li key={n}>{n}</li>
                            })}
                        </ul>
                    </Typography>
                    : <Typography className={classes.content}>None of the numbers are portable.</Typography>
                }
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

            <Grid xs={6} item className={classes.gridItem}>
                <Typography variant="h4" color="error">Unsupported Numbers ({unsupportedNumbers.length})</Typography>
                {(unsupportedNumbers.length > 0 || portabilityErrors.length > 0)
                    ? <Typography component="div" className={classes.content}>
                        <ul className={classes.numbers}>
                            {unsupportedNumbers.sort().map(n => {
                                return <li key={n}>{n}</li>
                            })}

                            {portabilityErrors.map(e => {
                                return <li key={e.number}>{e.number} - {e.error}</li>
                            })}
                        </ul>
                    </Typography>
                    : <Typography className={classes.content}>All of the numbers are portable.</Typography>
                }
            </Grid>
        </Grid>
    );
};

export default OverviewPane;