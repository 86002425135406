import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    addButton: {
        marginLeft: theme.spacing(1)
    },
}));

interface SubHeadingProps {
    groupId: string;
    selectedNumbers: string[];
    onAddNumbers: () => void;
    onRemoveNumbers: () => void;
}

// Sub-heading on number management page that's displayed after a group is selected
const SubHeading = (props: SubHeadingProps) => {
    const classes = useStyles();

    const { groupId, selectedNumbers, onAddNumbers, onRemoveNumbers } = props;

    return (
        <Grid container className={classes.root} justifyContent="space-between">
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Typography variant="h3">Numbers for {groupId}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.addButton}
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={onAddNumbers}>Add Numbers</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                {selectedNumbers.length > 0 &&
                    <Grid item>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={onRemoveNumbers}>Remove {selectedNumbers.length} Number{selectedNumbers.length > 1 ? "s" : ""}</Button>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default SubHeading;