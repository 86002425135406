import * as React from 'react';
import { AddressBase } from './api';

interface Props {
    address: AddressBase;
}

export default function AddressBlock(props: Props) {
    const addr = props.address;

    return (
        <>
            {addr.housePrefix} {addr.houseNumber} {addr.houseSuffix} {addr.preDirectional} {addr.streetName} {addr.streetSuffix} {addr.postDirectional}<br />
            {addr.addressLine2 && <>{addr.addressLine2}<br /></>}
            {addr.city} {addr.stateCode} {addr.zip}{addr.plusFour && '-' + addr.plusFour}
        </>
    );
}