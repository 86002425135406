import { PROJECT_COORDINATOR, PROVISIONING, SERVICE_MANAGER, SUPER_USER, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER } from '../administrators/api';

export enum BandwidthPolicies {
    CanManageLocations = 'BandwidthCanManageLocations',
    CanManageNumberE911 = 'BandwidthCanManageNumberE911',
    CanManageNumberLidb = 'BandwidthCanManageNumberLidb',
    CanManageNumberRouting = 'BandwidthCanManageNumberRouting',
    CanManageNumberTnOptions = 'BandwidthCanManageNumberTnOptions',
    CanViewAndManageNumberPin = 'BandwidthCanViewAndManageNumberPin',
    CanMoveNumbers = 'BandwidthCanMoveNumbers',
    CanTriggerNumberPort = 'BandwidthCanTriggerNumberPort'
}

export const BandwidthPolicyRoles = {
    [BandwidthPolicies.CanManageLocations]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [BandwidthPolicies.CanManageNumberE911]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [BandwidthPolicies.CanManageNumberLidb]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [BandwidthPolicies.CanManageNumberRouting]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [BandwidthPolicies.CanManageNumberTnOptions]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [BandwidthPolicies.CanViewAndManageNumberPin]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [BandwidthPolicies.CanMoveNumbers]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [BandwidthPolicies.CanTriggerNumberPort]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, PROJECT_COORDINATOR]
};