import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { BadRequestError, ResponseError, useApi } from '../../../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor, withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { deleteAdministrator, RoleLabels } from '../api';
import { useFetchAdministrator, useUnlockAdministrator } from '../hooks';
import { AdministratorPolicies } from '../policies';
import { administratorIndexUrl, administratorUpdateUrl } from '../urls';
import LockedNotice from './LockedNotice';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

type UseDeleteResponse = [
    () => void,
    boolean,
    string | null
];

const useDelete = (id: number, onSuccess: () => void): UseDeleteResponse => {
    const api = useApi();
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<string | null>(null);

    let didCancel = false;

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, []);

    const confirm = useCallback(async () => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            await deleteAdministrator(api, id);

            if (!didCancel) {
                setIsDeleting(false);
                onSuccess();
            }
        } catch (e) {
            if (!didCancel) {
                // Redirect to login page if 401
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    setDeleteError(e.message);
                } else {
                    setDeleteError('An error occurred deleting administrator');
                }

                setIsDeleting(false);
            }
        }

    }, [id]);

    return [
        confirm,
        isDeleting,
        deleteError
    ];
};

const AdministratorViewPage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const session = useSession();
    const administratorId = props.match.params['id'];

    // Allow for deleting the administrator
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);

    const [fetch, isFetching, administrator, fetchError] = useFetchAdministrator();
    const [unlock, isUnlocking, isUnlockComplete, unlockError] = useUnlockAdministrator();

    const [confirmDelete, isDeleting, deleteError] = useDelete(administratorId, () => {
        dispatch(appWindowAddNotification('Administrator deleted.', 'success'));
        history.push(administratorIndexUrl());
    });

    useEffect(() => {
        fetch(administratorId);
    }, [administratorId]);

    useProgressEffects(
        isFetching || isDeleting,
        fetchError || deleteError || unlockError
    );

    const confirmUnlock = () => {
        unlock(administratorId);
    };

    // Refresh after unlocked
    useEffect(() => {
        if (isUnlockComplete) {
            dispatch(appWindowAddNotification('Administrator unlocked.', 'success'));
            setUnlockDialogOpen(false);
            fetch(administratorId);
        }
    }, [isUnlockComplete]);

    return (
        <div className={classes.root}>
            {!isFetching &&
                administrator !== null &&
                <>
                    <PageHeader text={`${administrator.firstName} ${administrator.lastName}`}>
                        {authorizedFor(AdministratorPolicies.CanViewAndManage, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={administratorUpdateUrl(administratorId)} {...props as any} ref={ref} />)}>
                                Update
                            </Button>
                        }

                        {administrator.isLocked && authorizedFor(AdministratorPolicies.CanViewAndManage, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                onClick={() => setUnlockDialogOpen(true)}
                                color="primary">
                                Unlock
                            </Button>
                        }

                        {authorizedFor(AdministratorPolicies.CanViewAndManage, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                onClick={() => setDeleteDialogOpen(true)}
                                color="secondary">
                                Delete
                            </Button>
                        }
                    </PageHeader>

                    {administrator.isLocked && <LockedNotice />}

                    <Paper className={classes.paper}>
                        <Grid container spacing={4}>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">Email Address</Typography>
                                <Typography>{administrator.email}</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="caption" color="textSecondary">First Name</Typography>
                                <Typography>{administrator.firstName}</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="caption" color="textSecondary">Last Name</Typography>
                                <Typography>{administrator.lastName}</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="caption" color="textSecondary">Roles</Typography>
                                <Typography>{administrator.roles.map(role => RoleLabels[role]).join(', ')}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            }

            <ConfirmationDialog
                title={'Delete Administrator?'}
                message={'Are you sure you want to delete this administrator?'}
                showWorking={isDeleting}
                isOpen={deleteDialogOpen}
                confirmText={'Delete'}
                cancelText={'Cancel'}
                onCancel={() => setDeleteDialogOpen(false)}
                onConfirm={() => confirmDelete()} />

            <ConfirmationDialog
                title={'Unlock Administrator?'}
                message={'Are you sure you want to unlock this administrator?'}
                showWorking={isUnlocking}
                isOpen={unlockDialogOpen}
                confirmText={'Unlock'}
                cancelText={'Cancel'}
                onCancel={() => setUnlockDialogOpen(false)}
                onConfirm={() => confirmUnlock()} />
        </div>
    );
};

export default withPolicyRestriction(AdministratorViewPage, AdministratorPolicies.CanViewAndManage);