import { Card, CardActionArea, CardContent, LinearProgress, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { Phone as PhoneIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useApi } from '../../../../api';
import { Link } from 'react-router-dom';
import { fetchHostedPbxAssignment, HostedPbxAssignment, } from '../../../hosted-pbx/api';
import { hpbxAssignmentViewUrl } from '../../../hosted-pbx/urls';
import moment = require('moment');
import theme from '../../../../theme';
import { platformDisplay } from '../../../hosted-pbx/helpers';

const useStyles = makeStyles(() => ({
    root: {
        width: 275
    },
    serviceName: {
        ...theme.typography.h5,
        color: theme.palette.text.primary
    },
    details: {
        marginTop: theme.spacing(1)
    },
    serviceActions: {
        justifyContent: 'space-between'
    },
    removeButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText
        }
    }
}));

interface Props {
    organizationId: number;
    assignmentId: number;
}

/**
 * Hook for retrieving the hosted pbx assignment details
 * @param organizationId
 */
const useDetailsApi = (organizationId: number, assignmentId: number): [boolean, HostedPbxAssignment | null] => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [details, setDetails] = useState<HostedPbxAssignment | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchHostedPbxAssignment(api, organizationId, assignmentId);
                if (!didCancel) {
                    setDetails(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    setIsFetching(false);
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [organizationId]);

    return [
        isFetching,
        details
    ]
};

/**
 * A card in the assigned services grid when the service is Hosted PBX.
 *
 * @param props
 * @constructor
 */
const HostedPbxCard = (props: Props) => {
    const classes = useStyles();
    const { organizationId, assignmentId } = props;

    const [isFetching, details] = useDetailsApi(organizationId, assignmentId);

    const formatCancelDate = useCallback((ts: any) => moment(ts).utc().format('MM/DD/YYYY'), []);

    return (
        <Card className={classes.root}>
            <CardActionArea component={React.forwardRef((props, ref) => <Link to={hpbxAssignmentViewUrl(organizationId, assignmentId)} {...props} />)}>
                <CardContent>
                    <Typography className={classes.serviceName} component="h4">
                        <PhoneIcon fontSize="small"/> Hosted PBX
                    </Typography>

                    {!isFetching && details != null &&
                        <Typography className={classes.details} component="p">
                            Platform: {platformDisplay(details.platform)}<br/>
                            Group: {details.broadworksGroup}<br/>
                            Bandwidth Acct: {details.bandwidthSubAccount}
                            {details.dubberAccount && <><br />Dubber Acct: {details.dubberAccount}</>}

                            {!details.isActive && <><br />Cancelled: {formatCancelDate(details.cancelledAt)}</>
                        }
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>

            {isFetching && <LinearProgress variant="indeterminate" color="primary" />}
        </Card>
    );
};

export default HostedPbxCard;