import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import AdministratorForm, { FormValues } from '../AdministratorForm';
import { createAdministrator } from '../api';
import { AdministratorPolicies } from '../policies';
import { administratorIndexUrl } from '../urls';
import theme from '../../../theme';

/**
 * Administrator creation form
 */

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface FormHandler {
    isSubmitting: boolean;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    errorMessage: string | null;
    submit: (formValues: FormValues) => void;
}

const useFormHandler = (callback: () => void): FormHandler => {
    const api = useApi();
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validationFieldMessages, setValidationFieldMessages] = useState<FieldError[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Validates that all fields are filled out.
    // If they are, validate that password and confirm password are equal
    const validate = (formValues: FormValues) => {
        const requiredFields: Array<keyof FormValues> = [
            'firstName',
            'lastName',
            'email',
            'password',
            'confirmPassword'
        ];

        let fieldErrors: FieldError[] = [];

        requiredFields.forEach(field => {
            let value = formValues[field] !== undefined
                ? String(formValues[field])
                : '';

            if (value.length === 0) {
                fieldErrors.push({
                    field,
                    message: 'Field is required.'
                })
            }
        });

        if (fieldErrors.length === 0) {
            if (formValues.password !== formValues.confirmPassword) {
                fieldErrors.push({
                    field: 'password',
                    message: 'Passwords do not match.'
                });

                fieldErrors.push({
                    field: 'confirmPassword',
                    message: 'Passwords do not match.'
                });
            }
        }

        setValidationFieldMessages(fieldErrors);

        return fieldErrors.length === 0;
    };

    const submit = (formValues: FormValues) => {
        // Do some local validation before sending it to the server.
        // This is because we have a field (confirmPassword) which is not part of the API
        if (validate(formValues)) {
            (async () => {
                setIsSubmitting(true);

                try {
                    // Call API to create administrator
                    await createAdministrator(api, {
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        email: formValues.email,
                        roles: formValues.roles,
                        password: formValues.password || ''
                    });

                    callback();
                } catch (e) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else if (e instanceof BadRequestError) {
                        // For bad request errors, we'll either display error messages under the fields
                        // or display a generic error message if no field errors are listed
                        setValidationMessage(e.fields && (e.fields.length > 0) ? null : e.message);
                        setValidationFieldMessages(e.fields && (e.fields.length > 0) ? e.fields : []);
                    } else {
                        // For generic errors, display a generic error message
                        setErrorMessage('Unable to save administrator.');
                        setValidationMessage(null);
                        setValidationFieldMessages([]);
                    }

                    setIsSubmitting(false);
                }
            })();
        }
    };

    return {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        errorMessage,
        submit
    }
};

const AdministratorCreatePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState<FormValues>({
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
        setPassword: true,
        password: '',
        confirmPassword: ''
    });

    const {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        errorMessage,
        submit
    } = useFormHandler(() => {
        // Create a notification about the creation
        dispatch(appWindowAddNotification('Administrator created.', 'success'));

        // Forward back to list page
        history.push(administratorIndexUrl());
    });

    useNav('administrators');

    useProgressEffects(isSubmitting, errorMessage);

    // Adjust form state when a field is modified
    const handleFieldChange = (evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
        const target = evt.target;
        const fieldName = target.id || target.name;
        let value: string | string[] = evt.target.value;

        // Roles is stored in a string[]
        // If a Role checkbox is toggled, modify the array
        if (target.type === 'checkbox' && fieldName === 'roles') {
            const checked = (target as HTMLInputElement).checked;
            let roles = formValues.roles;

            if (checked) {
                value = [...roles, value];
            } else {
                value = roles.filter(r => r !== value);
            }
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Cancel goes back to admin index
    const handleCancel = () => {
        history.push(administratorIndexUrl());
    };

    const handleSubmit = () => {
        submit(formValues);
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Create Administrator"/>

            <Paper className={classes.paper}>
                <AdministratorForm
                    formValues={formValues}
                    requirePassword={true}
                    validationMessage={validationMessage}
                    fieldValidationMessages={validationFieldMessages}
                    isSubmitting={isSubmitting}
                    onFieldChange={handleFieldChange}
                    onFieldKeyDown={handleKeyDown}
                />
            </Paper>

            <Grid container justifyContent="flex-end">
                <Button
                    className={classes.button}
                    color="inherit"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleCancel}>Cancel</Button>

                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}>Save</Button>
            </Grid>
        </div>
    );
};

export default withPolicyRestriction(AdministratorCreatePage, AdministratorPolicies.CanViewAndManage);