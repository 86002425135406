import { Alert, Box, CircularProgress, Link, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { HostedPbxOrganizationForm } from '../components/HostedPbxOrganizationForm';
import { useFetchAllHpbxOrganizations } from '../hooks';
import { HostedPbxPolicies } from '../policies';
import DevicesTab from './DevicesTab';
import { useFetchNineLineE911Report } from './hooks';

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

type ToolTabs = 'devices';

interface Selection {
    organizationId: number;
    assignmentId: number;
    ts: number;
}

/**
 * 9Line Tool index page
 */
const NineLineIndexPage = (props: Props) => {
    const classes = useStyles();

    const [isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllHpbxOrganizations('active');
    const [fetchReport, isFetchingReport, report, fetchReportError, fetchReportValidationError] = useFetchNineLineE911Report();

    const [selection, setSelection] = useState<Selection | null>(null);

    const [selectedTab, setSelectedTab] = useState<ToolTabs>('devices');
    const [tabWorking, setTabWorking] = useState(false);
    const [tabError, setTabError] = useState<string | null>(null);

    useNav('hosted-pbx', '9line');

    useProgressEffects(
        isFetchingOrganizations,
        fetchOrganizationsError || fetchReportError || tabError
    );

    useEffect(() => {
        if (selection) {
            fetchReport(selection.assignmentId);

        }
    }, [selection]);

    const refresh = useCallback(() => {
        if (selection) {
            setSelection({
                ...selection,
                ts: Date.now()
            });
        }
    }, [selection]);

    const hasRecommendedLocations = useMemo(() => {
        return selection && report
            ? report.devices.filter(d => d.recommendedEmergencyLocation !== null).length > 0
            : false;
    }, [selection, report]);

    const requiresSync = useMemo(() => {
        return selection && report
            ? report.devices.filter(d => d.inSync === false).length > 0
            : false;
    }, [selection, report]);

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX" subtext="9Line E911 Tool" />

            {!isFetchingOrganizations && organizations !== null &&
                <Paper className={classes.paper}>
                    <HostedPbxOrganizationForm
                        organizations={organizations}
                        selectedOrganizationId={selection?.organizationId || null}
                        selectedAssignmentId={selection?.assignmentId || null}
                        onSelect={(orgId, asId) => {
                            setSelection(orgId && asId ? {
                                organizationId: orgId,
                                assignmentId: asId,
                                ts: Date.now()
                            } : null)
                        }}
                        disabled={isFetchingReport || tabWorking}
                    />
                </Paper>
            }

            {isFetchingReport && <>
                <Box>
                    <CircularProgress size="1em" /> Retrieving information
                </Box>
            </>}

            {fetchReportValidationError && <>
                <Alert severity="error">{fetchReportValidationError}</Alert>
            </>}

            {requiresSync
                ? <Alert severity="warning">Device information within 9Line appears out of sync with BroadWorks. Please force a sync within the 9Line portal and <Link component="button" onClick={() => refresh()}>try again</Link>.</Alert>
                : <>

                    {hasRecommendedLocations && <>
                        <Alert severity="info">Some devices do not have locations set, but are attached to users that do. Locations have automatically been selected for these devices. Press the <em>Apply Changes</em> button to save these selections.</Alert>
                    </>}

                    {report && selection && <>
                        {selectedTab == 'devices' &&
                            <DevicesTab
                                assignmentId={selection.assignmentId}
                                devices={report.devices || []}
                                locations={report.emergencyLocations}
                                departments={report.departments}
                                onWorking={working => setTabWorking(working)}
                                onError={errorMsg => setTabError(errorMsg)}
                                refresh={() => fetchReport(selection.assignmentId)}
                            />}
                    </>}
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(NineLineIndexPage, HostedPbxPolicies.CanUse9LineTool);