import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import CancellationNotice from '../../services/CancellationNotice';
import { WebexPolicies } from '../policies';
import { useFetchWebexProductCounts } from '../product-counts/hooks';
import ProductCountsTable from '../product-counts/ProductCountsTable';
import { webexAssignmentUpdateUrl } from '../urls';
import AssignmentSummary from './AssignmentSummary';
import CancelDialog from './CancelDialog';
import { useFetchWebexAssignment } from './hooks';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * Webex assignment view page
 * @param props
 * @constructor
 */
const AssignmentViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);
    const [fetch, isFetchingAssignment, assignment, fetchAssignmentError] = useFetchWebexAssignment();
    const [fetchProductCounts, isFetchingProductCounts, productCounts, fetchProductCountsError] = useFetchWebexProductCounts();

    const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    useNav('webex', 'organizations');

    useProgressEffects(
        isFetchingAssignment || isFetchingOrganization,
        fetchAssignmentError || fetchOrganizationError
    );

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [organizationId, assignmentId]);

    // Retrieve product counts if assignment is still active
    useEffect(() => {
        if (assignment && assignment.isActive) {
            fetchProductCounts(organizationId, assignmentId);
        }
    }, [assignment]);

    const isCancelled = useMemo(() => {
        return !assignment?.isActive;
    }, [assignment]);

    // When cancellation completes, reload the assignment details
    useEffect(() => {
        if (isCancelConfirmed) {
            setCancelDialogOpen(false);
            dispatch(appWindowAddNotification('Cloud PBX w/ Webex service cancelled', 'success'));
            fetch(organizationId, assignmentId);
        }
    }, [isCancelConfirmed]);

    return (
        <div className={classes.root}>
            <PageHeader text="Cloud PBX w/ Webex"
                subtext={organization !== null ? organization.name : undefined}>

                {organization !== null
                    && !isCancelled
                    && <>
                        {authorizedFor(WebexPolicies.CanManageAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={webexAssignmentUpdateUrl(organizationId, assignmentId)} {...props} />)}>
                                Update
                            </Button>
                        }

                        {authorizedFor(WebexPolicies.CanCancelAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                onClick={() => setCancelDialogOpen(true)}
                                variant="text"
                                color="secondary">
                                Cancel Service
                            </Button>
                        }
                    </>
                }
            </PageHeader>

            {organization !== null
                && assignment !== null
                && <>
                    <div className={classes.marginBottom}>
                        {isCancelled &&
                            <CancellationNotice
                                cancelDate={moment(assignment.cancelledAt).format('MM/DD/YYYY')} />
                        }
                    </div>

                    <div className={classes.marginBottom}>
                        <AssignmentSummary assignment={assignment} />
                    </div>

                    {assignment.isActive &&
                        <div className={classes.marginBottom}>
                            <Typography variant="h3">Product Counts</Typography>
                            {isFetchingProductCounts && <Typography><CircularProgress size="1em" /> Retrieving product counts...</Typography>}
                            {fetchProductCountsError && <Typography color="error">{fetchProductCountsError}</Typography>}

                            {productCounts && <Paper>
                                <ProductCountsTable counts={productCounts} />
                            </Paper>}
                        </div>
                    }

                    <CancelDialog
                        isOpen={cancelDialogOpen}
                        assignmentId={assignmentId}
                        organizationId={organizationId}
                        organizationName={organization.name}
                        onCancel={() => setCancelDialogOpen(false)}
                        onComplete={() => setIsCancelConfirmed(true)}
                    />
                </>
            }
        </div>
    );
};

export default AssignmentViewPage;