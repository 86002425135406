import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../../api";
import history from '../../../../history';
import { loginUrl } from "../../../auth/urls";
import { fetchBandwidthNewNumberOrder, fetchBandwidthNewNumberOrders, NewNumberOrder, NewNumberOrderSummary } from './api';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/**
 * Hook for fetching New Number Orders
 * */

type FetchBandwidthNewNumbersFunc = (accountId: string, subAccountId?: string) => void;

export const useFetchBandwidthNewNumberOrders = (): TriggeredFetchHookResponse<FetchBandwidthNewNumbersFunc, NewNumberOrderSummary[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthNewNumberOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [orders, setOrders] = useState<NewNumberOrderSummary[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthNewNumberOrdersRequest {
        accountId: string;
        subAccountId?: string;
        ts: number;
    }

    const fetch: FetchBandwidthNewNumbersFunc = (accountId: string, subAccountId?: string) => {
        setRequest({
            accountId,
            subAccountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrders(null);

                try {
                    var orders = await fetchBandwidthNewNumberOrders(api, request.accountId, request.subAccountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrders(orders);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrders(null);
                            setError('Unable to fetch Bandwidth Orders.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        orders,
        error
    ];
};

/**
 * Hook for fetching a New Number Order
 * */

type FetchBandwidthNewNumberOrderFunc = (accountId: string, orderId: string) => void;

export const useFetchBandwidthNewNumberOrder = (): TriggeredFetchHookResponse<FetchBandwidthNewNumberOrderFunc, NewNumberOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthNewNumberOrderRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [order, setOrder] = useState<NewNumberOrder | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthNewNumberOrderRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: FetchBandwidthNewNumberOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrder(null);

                try {
                    var order = await fetchBandwidthNewNumberOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrder(null);
                            setError('Unable to fetch Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        order,
        error
    ];
};