import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    typography: {
        // H1 lives in the header and is rather small
        h1: {
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            letterSpacing: '0.0075em'
        },
        h2: {
            fontWeight: 400,
            fontSize: '2.125rem',
            lineHeight: 1.17,
            letterSpacing: '0.00735em'
        },
        h3: {
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.33,
            letterSpacing: '0em'
        },
        h4: {
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            letterSpacing: '0.0075em'
        }
    },
    palette: {
        primary: {
            dark: '#00578a',
            main: '#007DC6',
            light: '#3397d1'
        },
        secondary: {
            dark: '#8a3300',
            main: '#C64900',
            light: '#d16d33'
        },
        background: {
            default: '#FEFEFE'
        }
    },
    components: {
        MuiInputLabel: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiLink: {
            defaultProps: {
                // Only show an underline on links when hovering
                // Maintains previous behavior
                underline: 'hover'
            }
        },
        MuiTableHead: {
            defaultProps: {
                color: 'rgba(0, 0, 0, 0.54)'
            }
        }
    }
});

export default theme;