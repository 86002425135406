import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { Account } from '../api';
import { roleLabel } from '../helpers';
import { useFetchDubberUsers } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberUserCreateUrl, dubberUserViewUrl } from '../urls';
import history from '../../../history';
import SearchBar, { UserRoleOptions } from './SearchBar';

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    headingContainer: {
        display: 'flex',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    h4: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
}));

interface Props {
    groupId: string;
    account: Account;
    onLoadStateChange: (loading: boolean) => void;
    //page?: number;
}

const UserIndexTabContents = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { onLoadStateChange, account, groupId } = props;
    const session = useSession();
    const queryString = parse(location.search);
    const page = queryString['page'] === undefined ? 1 : parseInt(String(queryString['page']));
    const pageSize = queryString['limit'] === undefined ? DEFAULT_PAGE_SIZE : parseInt(String(queryString['limit']));
    const search = queryString['search'] === undefined ? '' : String(queryString['search']);
    const role = queryString['role'] === undefined ? 'all' : String(queryString['role']) as UserRoleOptions;

    const [fetch, isFetching, users, fetchError] = useFetchDubberUsers();

    useEffect(() => {
        fetch(groupId, account.id);
    }, [account])

    // Trigger progress bar on parent container when loading
    useEffect(() => {
        onLoadStateChange(isFetching);
    }, [isFetching]);

    // Display error notification if fetch fails
    useEffect(() => {
        if (fetchError !== null) {
            dispatch(appWindowAddNotification(fetchError, 'error'));
        }
    }, [fetchError]);

    const handleSubmitSearch = (search: string, role: UserRoleOptions, limit: number) => {
        var query: Record<string, string> = {
            limit: String(limit),
            role: String(role),
            search,
            page: "1"
        };

        const params = new URLSearchParams(query);

        history.push('?' + params.toString());
    };

    const filteredUsers = (users || []).filter(u => {
        const searchLowered = search.toLowerCase().trim();

        if (role !== 'all' && u.role !== role) {
            return false;
        }

        const userName = `${u.firstName} ${u.lastName}`.toLowerCase();
        const email = u.username?.toLowerCase() || '';

        return userName.indexOf(searchLowered) > -1
            || email.indexOf(searchLowered) > -1
    });

    const userPage = useMemo(() => {
        if (!filteredUsers) {
            return null;
        }

        return filteredUsers.slice((page - 1) * pageSize, page * pageSize);
    }, [page, filteredUsers, pageSize]);

    return (
        <>
            {users !== null &&
                <>
                    <SearchBar
                        search={search}
                        limit={pageSize}
                        role={role}
                        onSubmitSearch={handleSubmitSearch}
                        showAddButton={authorizedFor(DubberPolicies.CanCreateAndDeleteUsers, session.roles)}
                        onAddNew={() => history.push(dubberUserCreateUrl(groupId, account.id))}
                    />

                    {users.length === 0 &&
                        <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">
                                No users found.
                            </Typography>
                        </div>}

                    {users.length > 0 &&
                        <>
                            {filteredUsers.length === 0
                                ?
                                <div className={classes.noResultsContainer}>
                                    <Typography color="textSecondary" align="center">
                                        No users match search.
                                    </Typography>
                                </div>
                                :
                                <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Role</TableCell>
                                                <TableCell>Username</TableCell>
                                                <TableCell>Confirmed</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(userPage || []).map(user => {
                                                const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberUserViewUrl(groupId, account.id, user.id)} {...props} />);

                                                return <TableRow key={user.id}>
                                                    <TableCell component="th" scope="row">
                                                        <Link component={LinkComponent}>{user.firstName} {user.lastName}</Link>
                                                    </TableCell>
                                                    <TableCell>{roleLabel(user.role)}</TableCell>
                                                    <TableCell>{user.username}</TableCell>
                                                    <TableCell>{user.confirmed ? 'Yes' : 'No'}</TableCell>
                                                </TableRow>;
                                            }
                                            )}
                                        </TableBody>
                                    </Table>

                                    <Pagination
                                        totalItems={filteredUsers.length}
                                        pageSize={pageSize}
                                        page={page}
                                        disabled={isFetching}
                                        additionalParams={{
                                            search,
                                            role,
                                            limit: pageSize
                                        }}
                                        onChange={() => null} />
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default UserIndexTabContents;