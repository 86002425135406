import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { Location, Traffic } from './api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    location: Location;
}

const trafficDisplay = (traffic: Traffic) => {
    switch (traffic) {
        case 'Lite':
            return 'International Lite';
        case 'Full':
            return 'International';
        default:
            return traffic;
    }
}

// Location Termination Summary Box
const TerminationSummary = (props: Props) => {
    const classes = useStyles();
    const { location } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                {location.trafficAllowed !== null &&
                    <Grid item xs={3}>
                        <Typography variant="caption" color="textSecondary">Allowed Traffic</Typography>
                        <Typography>
                            {trafficDisplay(location.trafficAllowed)}
                        </Typography>
                    </Grid>
                }

                {location.terminationHosts.length > 0 &&
                    <Grid item xs={3}>
                        <Typography variant="caption" color="textSecondary">Allowed Hosts</Typography>
                        {location.terminationHosts.map(h => <div key={h}>{h}</div>)}
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}

export default TerminationSummary;