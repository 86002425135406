import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor, withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { Administrator, fetchAdministrators } from '../api';
import { AdministratorPolicies } from '../policies';
import { administratorCreateUrl } from '../urls';
import AdministratorTable from './AdministratorTable';
import SearchBar from './SearchBar';

/**
 * Administrators list page
 */

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    block: {
        display: 'block',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface Props {
}

interface SearchForm {
    page?: number;
    limit?: number;
}

interface Page {
    current: number;
    size: number;
    total: number;
}

interface UseFetchResult {
    isFetching: boolean;
    administrators : Administrator[] | null;
    page: Page | null;
    errorMessage: string | null;
}

const useFetch = (pageNumber: number = 1, limit: number | undefined): UseFetchResult => {
    const api = useApi();

    // Indicates that we're currently fetching items from the server
    const [isFetching, setIsFetching] = useState(false);

    // The list of administrators retrieved
    const [administrators, setAdministrators] = useState<Administrator[] | null>(null);

    // Details about the page returned by the server
    const [page, setPage] = useState<Page | null>(null);

    // Error message returned by the server
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchAdministrators(api, pageNumber, limit);
                if (!didCancel) {
                    setAdministrators(response.items);

                    setPage({
                        current: response.page,
                        size: response.pageSize,
                        total: response.totalItems,
                    });

                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setErrorMessage('Unable to retrieve administrators');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [pageNumber, limit]);

    return {
        isFetching,
        administrators,
        page,
        errorMessage
    }
};

const AdministratorIndexPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();

    const params = parse(location.search);

    const pageQuery = params['page'] !== undefined
        ? parseInt(String(params['page']))
        : undefined;

    const limitQuery = params['limit'] !== undefined
        ? parseInt(String(params['limit']))
        : undefined;

    const [form, setForm] = useState<SearchForm>({
        // Default query state to what's passed in the URL query string
        page: pageQuery,
        limit: limitQuery
    });

    // A change in params causes a change in state
    if ((pageQuery !== form.page) || (limitQuery !== form.limit)) {
        setForm({
            page: pageQuery,
            limit: limitQuery
        });
    }

    useNav('administrators');

    const { isFetching, administrators, page, errorMessage } = useFetch(form.page, form.limit);

    useProgressEffects(
        isFetching,
        errorMessage
    );

    // Submitting the search will update the query string and force a fetch
    function handleSubmitSearch(limit: number) {
        history.push(`?limit=${limit}&page=1`);
    }

    return (
        <>
            <div className={classes.root}>
                <PageHeader text="All Administrators">
                    {authorizedFor(AdministratorPolicies.CanViewAndManage, session.roles) &&
                        <Button
                            className={classes.headerButton}
                            variant="text"
                            color="primary"
                            component={React.forwardRef((props, ref) => <Link to={administratorCreateUrl()} {...props as any} ref={ref}/>)}>
                            Add New
                        </Button>
                    }
                </PageHeader>

                <Paper>
                    <SearchBar
                        limit={form.limit}
                        onSubmitSearch={handleSubmitSearch}/>

                    {administrators !== null &&
                    administrators.length > 0 &&
                        <>
                        <AdministratorTable administrators={administrators} />

                            {page !== null &&
                                <Pagination
                                    totalItems={page.total}
                                    pageSize={page.size}
                                    page={page.current}
                                    disabled={isFetching}
                                    additionalParams={{
                                        limit: form.limit
                                    }}
                                    onChange={() => null}/>
                            }
                        </>
                    }

                    {administrators !== null &&
                    administrators.length === 0 &&
                        <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">
                                No administrators found.
                            </Typography>
                        </div>
                    }

                </Paper>
            </div>
        </>
    );
};

export default withPolicyRestriction(AdministratorIndexPage, AdministratorPolicies.CanViewAndManage);