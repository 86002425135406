// All administrator roles in the system
import { ApiCaller, PagedResponse } from '../../api';

export const SUPER_USER = 'SuperUser';
export const BILLING_ASSOCIATE = 'BillingAssociate';
export const PROJECT_COORDINATOR = 'ProjectCoordinator';
export const SUPPORT_TECHNICIAN = 'SupportTechnician';
export const SYSTEMS_ENGINEER = 'SystemsEngineer';
export const SERVICE_MANAGER = 'ServiceManager';
export const PROVISIONING = 'Provisioning';

export const ADMINISTRATOR_ROLES = [
    SUPER_USER,
    BILLING_ASSOCIATE,
    PROJECT_COORDINATOR,
    SUPPORT_TECHNICIAN,
    SYSTEMS_ENGINEER,
    SERVICE_MANAGER,
    PROVISIONING
];

export type AdministratorRole =
    typeof SUPER_USER
    | typeof BILLING_ASSOCIATE
    | typeof PROJECT_COORDINATOR
    | typeof SUPPORT_TECHNICIAN
    | typeof SYSTEMS_ENGINEER
    | typeof SERVICE_MANAGER
    | typeof PROVISIONING;

export const RoleLabels = {
    [SUPER_USER]: 'Super User',
    [BILLING_ASSOCIATE]: 'Billing Associate',
    [PROJECT_COORDINATOR]: 'Project Coordinator',
    [SUPPORT_TECHNICIAN]: 'Service Desk Engineer',
    [SYSTEMS_ENGINEER]: 'Systems Engineer',
    [SERVICE_MANAGER]: 'Service Manager',
    [PROVISIONING]: 'Provisioning'
};

export interface Administrator {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    roles: AdministratorRole[];
    isLocked: boolean;
}

/*
 * GET /api/administrators
 *
 * Gets all administrators in the system
 */
export const fetchAdministrators = async (api: ApiCaller, page: number, limit: number | undefined = undefined): Promise<PagedResponse<Administrator>> => {
    return await api<PagedResponse<Administrator>>('administrators', 'GET', {
        page,
        limit
    });
};

/*
 * POST /api/administrators
 *
 * Create new administrator
 */

interface CreateAdministrator {
    firstName: string;
    lastName: string;
    email: string;
    roles: AdministratorRole[];
    password: string;
}

export const createAdministrator = async (api: ApiCaller, form: CreateAdministrator): Promise<Administrator> => {
    return await api<Administrator>(`administrators`, 'POST', {
        ...form
    });
};

/*
 * GET /api/administrators/:id
 *
 * Get administrator details
 */
export const fetchAdministrator = async (api: ApiCaller, id: number): Promise<Administrator> => {
    return await api<Administrator>(`administrators/${id}`, 'GET');
};

/*
 * DELETE /api/administrator/:id
 *
 * Get administrator details
 */
export const deleteAdministrator = async (api: ApiCaller, id: number): Promise<{}> => {
    return await api<{}>(`administrators/${id}`, 'DELETE');
};

/*
 * PUT /api/administrator/:id
 *
 * Update an administrator
 */

interface UpdateUser {
    firstName: string;
    lastName: string;
    email: string;
    roles: AdministratorRole[];
    // Password can be omitted so it isn't changed
    password?: string;
}

export const updateAdministrator = async (api: ApiCaller, id: number, form: UpdateUser): Promise<{}> => {
    return await api<{}>(`administrators/${id}`, 'PUT', {
        ...form
    });
};

// Unlock an administrator
export const unlockAdministrator = async (api: ApiCaller, id: number): Promise<{}> => {
    return await api<{}>(`administrators/${id}/unlock`, 'POST');
};