import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../../theme';
import { UnidentifiedDubPoint } from '../api';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    groupId: string;
    dubPoint: UnidentifiedDubPoint;
}

const UnidentifiedDubPointSummary = (props: Props) => {
    const classes = useStyles();
    const { groupId, dubPoint } = props;

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">ID</Typography>
                    <Typography>{dubPoint.id}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                    <Typography>{dubPoint.type}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Label</Typography>
                    <Typography>{dubPoint.label}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>{dubPoint.status}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Recording Count</Typography>
                    <Typography>{dubPoint.recordingCount}</Typography>
                </Grid>
            </Grid>
        </Paper>);
};

export default UnidentifiedDubPointSummary;