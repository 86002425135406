import { ApiCaller, PagedResponse } from '../../../api';

export interface Group {
    id: string;
    name: string;
}

/*
 * GET /api/dubber
 *
 * Get all groups within dubber.
 */

export const fetchDubberGroups = async (api: ApiCaller): Promise<Group[]> => {
    return await api<Group[]>(`dubber`, 'GET');
};