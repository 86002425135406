import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchBandwidthAccount, useFetchBandwidthSubAccount } from '../hooks';
import { BandwidthPolicies } from '../policies';
import { bandwidthLocationUpdateUrl, bandwidthSubAccountViewUrl } from '../urls';
import { useDeleteBandwidthLocation, useFetchBandwidthLocation, useFetchBandwidthLocationNumbers } from './hooks';
import LocationNumbers from './LocationNumbers';
import LocationSummary from './LocationSummary';
import OriginationSummary from './OriginationSummary';
import TerminationSummary from './TerminationSummary';
import TrunksSummary from './TrunksSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noPaddingPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for view a location's details
const LocationViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();

    const [fetch, isFetching, location, fetchErrorMessage, fetchErrorCode] = useFetchBandwidthLocation();
    const [fetchAccount, isFetchingAccount, account, fetchAccountErrorMessage] = useFetchBandwidthAccount();
    const [fetchSubAccount, isFetchingSubAccount, subAccount, fetchSubAccountErrorMessage] = useFetchBandwidthSubAccount();
    const [fetchNumbers, isFetchingNumbers, numbers, fetchNumbersErrorMessage] = useFetchBandwidthLocationNumbers();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [del, isDeleting, deleteSuccess, deleteErrorMessage] = useDeleteBandwidthLocation();

    const accountId = props.match.params['accountId'];
    const subAccountId = props.match.params['subAccountId'];
    const locationId = props.match.params['locationId'];

    const fetchErrorToast = fetchErrorCode !== 404 ? fetchErrorMessage : null;

    // Update navigation
    useNav('bandwidth', 'locations');

    // Display progress bar and errors
    useProgressEffects(
        isFetching || isFetchingAccount || isFetchingSubAccount || isFetchingNumbers,
        fetchErrorToast || fetchAccountErrorMessage || fetchSubAccountErrorMessage || fetchNumbersErrorMessage || deleteErrorMessage
    );

    // Fetch location, sub-account, and numbers on load
    useEffect(() => {
        fetch(accountId, subAccountId, locationId);
        fetchAccount(accountId);
        fetchSubAccount(accountId, subAccountId);
        fetchNumbers(accountId, subAccountId, locationId);
    }, [accountId, subAccountId, locationId]);

    const confirmDelete = () => {
        del(accountId, subAccountId, locationId);
    };

    // Redirect back to sub-account view on delete
    useEffect(() => {
        if (deleteSuccess) {
            dispatch(appWindowAddNotification('Location deleted.', 'success'));
            setDeleteDialogOpen(false);
            history.push(bandwidthSubAccountViewUrl(accountId, subAccountId));

        }
    }, [deleteSuccess]);

    const canUpdateLocation = account?.service !== 'WebexWholesale';

    return (
        <div className={classes.root}>
            {!isFetching &&
                <PageHeader text={location?.name || locationId} subtext={subAccount?.name || subAccountId}>
                    {!isFetching && account !== null && subAccount !== null && location !== null && numbers &&
                        <>
                            {canUpdateLocation && authorizedFor(BandwidthPolicies.CanManageLocations, session.roles) &&
                                <Button
                                    className={classes.headerButton}
                                    variant="text"
                                    color="primary"
                                    component={React.forwardRef((props, ref) => <Link to={bandwidthLocationUpdateUrl(accountId, subAccountId, locationId)} {...props as any} ref={ref} />)}>
                                    Update
                                </Button>
                            }

                            {authorizedFor(BandwidthPolicies.CanManageLocations, session.roles) &&
                                <Button
                                    className={classes.headerButton}
                                    variant="text"
                                    onClick={() => setDeleteDialogOpen(true)}
                                    disabled={numbers.numbers.length > 0}
                                    color="secondary">
                                    Delete
                                </Button>
                            }
                        </>
                    }
                </PageHeader>
            }

            {fetchErrorCode === 404 && <Typography>Location no longer exists.</Typography>}

            {!isFetching && subAccount !== null && location !== null && numbers &&
                <>
                    <div className={classes.summary}>
                        <LocationSummary accountId={accountId} subAccount={subAccount} location={location} />
                    </div>

                    <Typography variant="h3">Origination</Typography>
                    <div className={classes.summary}>
                        <OriginationSummary location={location} />
                    </div>

                    {location.trafficAllowed !== null && location.terminationHosts.length > 0 &&
                        <>
                            <Typography variant="h3">Termination</Typography>
                            <div className={classes.summary}>
                                <TerminationSummary location={location} />
                            </div>
                        </>
                    }

                    {location.premiseTrunks !== null &&
                        <>
                            <Typography variant="h3">Trunks</Typography>
                            <div className={classes.summary}>
                                <TrunksSummary trunks={location.premiseTrunks} />
                            </div>
                        </>
                    }

                    <Typography variant="h3">Numbers</Typography>
                    <Paper className={classes.noPaddingPaper}>
                        {numbers.numbers.length === 0 &&
                            <div className={classes.noResultsContainer}>
                                <Typography color="textSecondary" align="center">
                                    No numbers found.
                                </Typography>
                            </div>
                        }

                        {numbers.numbers.length > 0 && <LocationNumbers numbers={numbers.numbers} />}
                    </Paper>
                </>
            }

            <ConfirmationDialog
                title={'Delete Location?'}
                message={'Are you sure you want to delete this location?'}
                showWorking={isDeleting}
                isOpen={deleteDialogOpen}
                confirmText={'Delete'}
                cancelText={'Cancel'}
                onCancel={() => setDeleteDialogOpen(false)}
                onConfirm={() => confirmDelete()} />
        </div>
    );
};

export default LocationViewPage;