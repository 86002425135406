import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    appWindowAddNotification,
    appWindowHideProgress,
    appWindowSetActiveNav,
    appWindowShowProgress
} from './actions';
import { SubNavItem, TopNavItem } from './NavDrawer';

// Hook for triggering the Application Window's loading bar and error display
export const useProgressEffects = (inProgress: boolean, errorMessage: string | null) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Hide the progress bar on unmount in case the user leaves the page
        // before a fetch completes
        return () => {
            dispatch(appWindowHideProgress());
        };
    }, []);

    useEffect(() => {
        dispatch(inProgress ? appWindowShowProgress() : appWindowHideProgress());
    }, [inProgress]);

    useEffect(() => {
        if (errorMessage !== null) {
            dispatch(appWindowAddNotification(errorMessage, 'error'));
        }
    }, [errorMessage]);
};

// Hook for setting the current active navigation item
export const useNav = (nav?: TopNavItem, subNav?: SubNavItem) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appWindowSetActiveNav(nav, subNav));
    }, []);
};