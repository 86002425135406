import { ApiCaller } from '../../../api';
import { AddressBase } from '../api';

export interface Location {
    id: string;
    name: string;
    description: string;
    isDefault: boolean;
    failoverUri: string;
    originationDistribution: Distribution;
    originationHosts: string[];
    terminationHosts: string[];
    trafficAllowed: Traffic | null;
    callingNameDisplayed: boolean;
    callingNameEnforced: boolean;
    // Only set on UCImplied accounts
    premiseTrunks: number | null;
    // Only set on UC/UCImplied accounts
    address: LocationAddress | null;
    stirShaken: StirShaken;
}

export type Distribution = 'None' | 'Random' | 'Sequential';
export type Traffic = 'Domestic' | 'Lite' | 'Full';

export interface LocationAddress extends AddressBase {
    country: string;
    latitude: string | null;
    longitude: string | null;
}

export interface StirShaken {
    inboundVerification: InboundVerification;
    transmitIdentityHeader: boolean;
}

export type InboundVerification = 'Off' | 'On' | 'Enhanced';

/**
 * GET /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations
 * 
 * Fetch all locations under a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 */
export const fetchBandwidthLocations = async (api: ApiCaller, accountId: string, subAccountId: string): Promise<Location[]> => {
    return await api<Location[]>(`bandwidth/${accountId}/sub-accounts/${subAccountId}/locations`, 'GET');
};

/**
 * GET /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations/{locationId}
 * 
 * Fetch details about a location under a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 * @param locationId
 */
export const fetchBandwidthLocation = async (api: ApiCaller, accountId: string, subAccountId: string, locationId: string): Promise<Location> => {
    return await api<Location>(`bandwidth/${accountId}/sub-accounts/${subAccountId}/locations/${locationId}`, 'GET');
};

export interface LocationForm {
    accountId: string;
    subAccountId: string;
    locationId?: string;
    name: string;
    description: string;
    isDefault: boolean;
    failoverUri: string;
    originationDistribution: Distribution;
    originationHosts: string[];
    terminationHosts: string[];
    trafficAllowed: Traffic | null;
    callingNameDisplayed: boolean;
    callingNameEnforced: boolean;
    // Only set on UCImplied accounts
    premiseTrunks: number | null;
    // Only set on UC/UCImplied accounts
    address: LocationAddressForm | null;
    stirShaken: StirShakenForm;
}

export type LocationAddressForm = LocationAddress;
export type StirShakenForm = StirShaken;

/**
 * POST /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations
 * 
 * Create a new location under a sub-account
 * 
 * @param api
 * @param form
 */
export const createBandwidthLocation = async (api: ApiCaller, form: LocationForm): Promise<Location> => {
    return await api<Location>(`bandwidth/${form.accountId}/sub-accounts/${form.subAccountId}/locations`, 'POST', {
        ...form
    });
};

/**
 * PUT /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations/{locationId}
 * 
 * Update a location under a sub-account
 * 
 * @param api
 * @param form
 */
export const updateBandwidthLocation = async (api: ApiCaller, form: LocationForm): Promise<void> => {
    return await api<void>(`bandwidth/${form.accountId}/sub-accounts/${form.subAccountId}/locations/${form.locationId}`, 'PUT', {
        ...form
    });
};

/**
 * DELETE /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations/{locationId}
 * 
 * Delete a location from under a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 * @param locationId
 */
export const deleteBandwidthLocation = async (api: ApiCaller, accountId: string, subAccountId: string, locationId: string): Promise<void> => {
    return await api<void>(`bandwidth/${accountId}/sub-accounts/${subAccountId}/locations/${locationId}`, 'DELETE');
};

export interface NumbersPage {
    next: string;
    numbers: string[];
}

/**
 * GET /bandwidth/{accountId}/sub-accounts/{subAccountId}/locations/{locationId}/numbers
 * 
 * Delete a location from under a sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 * @param locationId
 * @param page
 * @param limit
 */
export const fetchBandwidthLocationNumbers = async (api: ApiCaller, accountId: string, subAccountId: string, locationId: string, page?: string, limit?: string): Promise<NumbersPage> => {
    return await api<NumbersPage>(`bandwidth/${accountId}/sub-accounts/${subAccountId}/locations/${locationId}/numbers`, 'GET', {
        page,
        limit
    });
};