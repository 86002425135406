import MomentUtils from '@date-io/moment';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './features/app/App';
import history from './history';
import { Store, StorePersistor } from './store';
import theme from './theme';
import moment = require('moment');

// Override the MomentUtils used by material-ui-pickers so it treats all datetimes as UTC

class UTCUtils extends MomentUtils {
    formatByString = (date: moment.Moment, formatString: string) => {
        return moment(date)
            .utc()
            .format(formatString);
    }
}

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={Store}>
                <PersistGate loading={null} persistor={StorePersistor}>
                    <Router history={history}>
                        <LocalizationProvider dateAdapter={UTCUtils}>
                            <App />
                        </LocalizationProvider>
                    </Router>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>, document.querySelector('#app'));