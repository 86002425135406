import { OrderType } from "./api";

export const BANDWIDTH_SUBACCOUNT_INDEX_URL = '/bandwidth/sub-accounts';
export const BANDWIDTH_SUBACCOUNT_VIEW_URL = '/bandwidth/sub-accounts/:accountId/:subAccountId';

export const BANDWIDTH_LOCATION_INDEX_URL = '/bandwidth/locations';
export const BANDWIDTH_LOCATION_VIEW_URL = '/bandwidth/locations/:accountId/:subAccountId/:locationId';
export const BANDWIDTH_LOCATION_CREATE_URL = '/bandwidth/locations/:accountId/:subAccountId/create';
export const BANDWIDTH_LOCATION_UPDATE_URL = '/bandwidth/locations/:accountId/:subAccountId/:locationId/update';

export const BANDWIDTH_NUMBER_INDEX_URL = '/bandwidth/numbers';
export const BANDWIDTH_NUMBER_VIEW_URL = '/bandwidth/numbers/:number';
export const BANDWIDTH_NUMBER_UPDATE_GENERAL_URL = '/bandwidth/numbers/:number/general';
export const BANDWIDTH_NUMBER_UPDATE_E911_URL = '/bandwidth/numbers/:number/e911';
export const BANDWIDTH_NUMBER_UPDATE_LIDB_URL = '/bandwidth/numbers/:number/lidb';
export const BANDWIDTH_NUMBER_UPDATE_ROUTING_URL = '/bandwidth/numbers/:number/routing';
export const BANDWIDTH_NUMBER_UPDATE_PASSCODE_URL = '/bandwidth/numbers/:number/passcode';
export const BANDWIDTH_NUMBER_MOVE_URL = '/bandwidth/numbers/:number/move';

export const BANDWIDTH_ORDER_INDEX_URL = '/bandwidth/orders';
export const BANDWIDTH_ORDER_NEW_NUMBERS_INDEX_URL = '/bandwidth/orders/:accountId';
export const BANDWIDTH_ORDER_PORT_INS_INDEX_URL = '/bandwidth/orders/:accountId/port-ins';
export const BANDWIDTH_ORDER_E911S_INDEX_URL = '/bandwidth/orders/:accountId/e911s';
export const BANDWIDTH_ORDER_MOVES_INDEX_URL = '/bandwidth/orders/:accountId/moves';
export const BANDWIDTH_ORDER_LIDBS_INDEX_URL = '/bandwidth/orders/:accountId/lidbs';
export const BANDWIDTH_ORDER_TN_OPTIONS_INDEX_URL = '/bandwidth/orders/:accountId/tn-options';

export const BANDWIDTH_ORDER_NEW_NUMBER_VIEW_URL = '/bandwidth/orders/:accountId/new-numbers/:orderId';
export const BANDWIDTH_ORDER_PORT_IN_VIEW_URL = '/bandwidth/orders/:accountId/port-ins/:orderId';
export const BANDWIDTH_ORDER_E911_VIEW_URL = '/bandwidth/orders/:accountId/e911s/:orderId';
export const BANDWIDTH_ORDER_MOVE_NUMBER_VIEW_URL = '/bandwidth/orders/:accountId/move-numbers/:orderId';

export const BANDWIDTH_ORDER_LIDB_VIEW_URL = '/bandwidth/orders/:accountId/lidbs/:orderId';
export const BANDWIDTH_ORDER_TN_OPTION_VIEW_URL = '/bandwidth/orders/:accountId/tn-options/:orderId';

export const BANDWIDTH_PORT_CHECK_URL = '/bandwidth/port-check';

export const bandwidthSubAccountIndexUrl = () => BANDWIDTH_SUBACCOUNT_INDEX_URL;

export const bandwidthSubAccountViewUrl = (accountId: string, subAccountId: string) =>
    BANDWIDTH_SUBACCOUNT_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':subAccountId', String(subAccountId));

export const bandwidthLocationIndexUrl = () => BANDWIDTH_LOCATION_INDEX_URL;

export const bandwidthLocationViewUrl = (accountId: string, subAccountId: string, locationId: string) =>
    BANDWIDTH_LOCATION_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':subAccountId', String(subAccountId))
        .replace(':locationId', String(locationId));

export const bandwidthLocationCreateUrl = (accountId: string, subAccountId: string) =>
    BANDWIDTH_LOCATION_CREATE_URL.replace(':accountId', String(accountId))
        .replace(':subAccountId', String(subAccountId));

export const bandwidthLocationUpdateUrl = (accountId: string, subAccountId: string, locationId: string) =>
    BANDWIDTH_LOCATION_UPDATE_URL.replace(':accountId', String(accountId))
        .replace(':subAccountId', String(subAccountId))
        .replace(':locationId', String(locationId));

export const bandwidthNumberIndexUrl = () => BANDWIDTH_NUMBER_INDEX_URL;

export const bandwidthNumberViewUrl = (number: string) => BANDWIDTH_NUMBER_VIEW_URL.replace(':number', number);
export const bandwidthNumberUpdateGeneralUrl = (number: string) => BANDWIDTH_NUMBER_UPDATE_GENERAL_URL.replace(':number', number);
export const bandwidthNumberUpdateE911Url = (number: string) => BANDWIDTH_NUMBER_UPDATE_E911_URL.replace(':number', number);
export const bandwidthNumberUpdateLidbUrl = (number: string) => BANDWIDTH_NUMBER_UPDATE_LIDB_URL.replace(':number', number);
export const bandwidthNumberUpdateRoutingUrl = (number: string) => BANDWIDTH_NUMBER_UPDATE_ROUTING_URL.replace(':number', number);
export const bandwidthNumberUpdatePasscodeUrl = (number: string) => BANDWIDTH_NUMBER_UPDATE_PASSCODE_URL.replace(':number', number);
export const bandwidthNumberMoveUrl = (number: string) => BANDWIDTH_NUMBER_MOVE_URL.replace(':number', number);

export const bandwidthOrderIndexUrl = () => BANDWIDTH_ORDER_INDEX_URL;
export const bandwidthOrderNewNumbersIndexUrl = (accountId: string) => BANDWIDTH_ORDER_NEW_NUMBERS_INDEX_URL.replace(':accountId', accountId);
export const bandwidthOrderPortInsIndexUrl = (accountId: string) => BANDWIDTH_ORDER_PORT_INS_INDEX_URL.replace(':accountId', accountId);
export const bandwidthOrderE911sIndexUrl = (accountId: string) => BANDWIDTH_ORDER_E911S_INDEX_URL.replace(':accountId', accountId);
export const bandwidthOrderTnOptionsIndexUrl = (accountId: string) => BANDWIDTH_ORDER_TN_OPTIONS_INDEX_URL.replace(':accountId', accountId);
export const bandwidthOrderMovesIndexUrl = (accountId: string) => BANDWIDTH_ORDER_MOVES_INDEX_URL.replace(':accountId', accountId);
export const bandwidthOrderLidbsIndexUrl = (accountId: string) => BANDWIDTH_ORDER_LIDBS_INDEX_URL.replace(':accountId', accountId);

export const bandwidthOrderViewUrl = (type: OrderType, accountId: string, orderId: string) => {
    switch (type) {
        case 'E911':        return bandwidthOrderE911ViewUrl(accountId, orderId);
        case 'Lidb':        return bandwidthOrderLidbViewUrl(accountId, orderId);
        case 'MoveNumber':  return bandwidthOrderMoveNumberViewUrl(accountId, orderId);
        case 'NewNumber':   return bandwidthOrderNewNumberViewUrl(accountId, orderId);
        case 'TnOption':    return bandwidthOrderTnOptionViewUrl(accountId, orderId);
        case 'PortIn':      return bandwidthOrderPortInViewUrl(accountId, orderId);
    }
};

export const bandwidthOrderNewNumberViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_NEW_NUMBER_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthOrderPortInViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_PORT_IN_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthOrderE911ViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_E911_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthOrderMoveNumberViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_MOVE_NUMBER_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthOrderLidbViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_LIDB_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthOrderTnOptionViewUrl = (accountId: string, orderId: string) =>
    BANDWIDTH_ORDER_TN_OPTION_VIEW_URL.replace(':accountId', String(accountId))
        .replace(':orderId', String(orderId));

export const bandwidthPortCheckUrl = () => BANDWIDTH_PORT_CHECK_URL;