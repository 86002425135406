import { FormControl, FormLabel, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect } from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';
import { Account } from '../../accounts/api';
import { useFetchBandwidthLocations, useFetchBandwidthSubAccounts } from '../../hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    accountOptions: Account[];
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string | null) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    targetAccountId: string | null;
    subAccountId: string | null;
    locationId: string | null;
}

const GeneralForm = (props: Props) => {
    const classes = useStyles();
    const { accountOptions, formValues, isSubmitting, onFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const [fetchSubAccounts, isFetchingSubAccounts, subAccounts, fetchSubAccountsErrorMessage] = useFetchBandwidthSubAccounts();
    const [fetchLocations, isFetchingLocations, locations, fetchLocationsErrorMessage] = useFetchBandwidthLocations();

    // Refresh Sub-Account listing on account change
    useEffect(() => {
        if (formValues.targetAccountId) {
            fetchSubAccounts(formValues.targetAccountId);
        }
    }, [formValues.targetAccountId]);

    // Refresh location listing on sub-account change
    useEffect(() => {
        if (formValues.targetAccountId && formValues.subAccountId) {
            fetchLocations(formValues.targetAccountId, formValues.subAccountId);
        }
    }, [formValues.subAccountId]);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
            {fetchSubAccountsErrorMessage && <Typography variant="body1" color="error">{fetchSubAccountsErrorMessage}</Typography>}
            {fetchLocationsErrorMessage && <Typography variant="body1" color="error">{fetchLocationsErrorMessage}</Typography>}

            <FormControl className={classes.formControl} fullWidth error={!isValid('targetAccountId')}>
                <InputLabel htmlFor="targetAccountId">Target Account</InputLabel>
                <Select
                    id="targetAccountId"
                    native
                    margin="none"
                    value={formValues.targetAccountId || ''}
                    disabled={isSubmitting}
                    input={<Input name="targetAccountId" id="targetAccountId" />}
                    onChange={evt => onFieldChange('targetAccountId', evt.target.value)}
                    onKeyDown={() => false}>
                    <option value="" />
                    {accountOptions.map(acc => <option key={acc.id} value={acc.id}>{acc.name} ({acc.id})</option>)}
                </Select>

                <ValidationMessage field="targetAccountId" />
            </FormControl>

            {formValues.targetAccountId &&
                <>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('subAccountId')}>
                        {subAccounts &&
                            <>
                                <InputLabel htmlFor="subAccountId">Target Sub-Account</InputLabel>

                                <Select
                                    id="subAccountId"
                                    native
                                    margin="none"
                                    value={formValues.subAccountId || ''}
                                    disabled={isSubmitting}
                                    input={<Input name="subAccountId" id="subAccountId" />}
                                    onChange={evt => onFieldChange('subAccountId', evt.target.value)}
                                    onKeyDown={() => false}>
                                    <option value="" />
                                    {subAccounts.map(sa => <option key={sa.id} value={sa.id}>{sa.name} ({sa.id})</option>)}
                                </Select>
                            </>
                        }

                        <ValidationMessage field="subAccountId" />
                    </FormControl>

                    {formValues.subAccountId &&
                        <FormControl className={classes.formControl} fullWidth error={!isValid('locationId')}>
                            {locations &&
                                <>
                                    <InputLabel htmlFor="locationId">Target Location</InputLabel>

                                    <Select
                                        id="locationId"
                                        native
                                        margin="none"
                                        value={formValues.locationId || ''}
                                        disabled={isSubmitting}
                                        input={<Input name="locationId" id="locationId" />}
                                        onChange={evt => onFieldChange('locationId', evt.target.value)}
                                        onKeyDown={() => false}>
                                        <option value="" />
                                        {locations.map(l => <option key={l.id} value={l.id}>{l.name} ({l.id})</option>)}
                                    </Select>
                                </>
                            }

                            <ValidationMessage field="locationId" />
                        </FormControl>
                    }

                    {(isFetchingSubAccounts || isFetchingLocations) && <LinearProgress />}
                </>
            }
        </>
    );
};

export default GeneralForm;