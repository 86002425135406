import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../../api";
import history from '../../../../history';
import { loginUrl } from "../../../auth/urls";
import { createBandwidthMoveNumberOrder, CreateBandwidthMoveNumberOrderForm, fetchBandwidthMoveNumberOrder, fetchBandwidthMoveNumberOrders, MoveNumberOrder, MoveNumberOrderSummary } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type CreateBandwidthMoveOrderFunc = (form: CreateBandwidthMoveNumberOrderForm) => void;

/**
 * Hook for creating a new Bandwidth Move Order
 * */
export const useCreateBandwidthMoveNumberOrder = (): TriggeredSubmitHookResponse<CreateBandwidthMoveOrderFunc, MoveNumberOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<CreateBandwidthMoveOrderRequest | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [createdOrder, setIsCreatedOrder] = useState<MoveNumberOrder | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    interface CreateBandwidthMoveOrderRequest {
        form: CreateBandwidthMoveNumberOrderForm;
        ts: number;
    }

    const create: CreateBandwidthMoveOrderFunc = (form: CreateBandwidthMoveNumberOrderForm) => {
        setRequest({
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setIsCreatedOrder(null);

                try {
                    const order = await createBandwidthMoveNumberOrder(api, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setIsCreatedOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setIsCreatedOrder(null);
                            setError('Unable to create Bandwidth Move Number Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        createdOrder,
        error,
        fieldErrors
    ];
};

/**
 * Hook for fetching Move TN Orders
 * */

type FetchBandwidthFetchMoveNumberOrdersFunc = (accountId: string, number?: string) => void;

export const useFetchBandwidthMoveNumberOrders = (): TriggeredFetchHookResponse<FetchBandwidthFetchMoveNumberOrdersFunc, MoveNumberOrderSummary[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthMoveOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [orders, setOrders] = useState<MoveNumberOrderSummary[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthMoveOrdersRequest {
        accountId: string;
        number?: string;
        ts: number;
    }

    const fetch: FetchBandwidthFetchMoveNumberOrdersFunc = (accountId: string, number?: string) => {
        setRequest({
            accountId,
            number,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrders(null);

                try {
                    var orders = await fetchBandwidthMoveNumberOrders(api, request.accountId, request.number);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrders(orders);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrders(null);
                            setError('Unable to fetch Bandwidth Orders.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        orders,
        error
    ];
};

/**
 * Hook for fetching a Move Order
 * */

type FetchBandwidthFetchMoveNumberOrderFunc = (accountId: string, orderId: string) => void;

export const useFetchBandwidthMoveNumberOrder = (): TriggeredFetchHookResponse<FetchBandwidthFetchMoveNumberOrderFunc, MoveNumberOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthFetchMoveOrderRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [order, setOrder] = useState<MoveNumberOrder | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthFetchMoveOrderRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: FetchBandwidthFetchMoveNumberOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrder(null);

                try {
                    var order = await fetchBandwidthMoveNumberOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrder(null);
                            setError('Unable to fetch Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        order,
        error
    ];
};