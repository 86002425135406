import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import DubPointTable from '../dub-points/DubPointTable';
import { useFetchDubberDubPoints } from '../dub-points/hooks';
import { useDeleteDubberUser, useFetchDubberAccount, useFetchDubberUser } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberAccountViewUrl, dubberDubPointCreateUrl, dubberUserUpdateUrl } from '../urls';
import DeleteDialog from './DeleteDialog';
import UserSummary from './UserSummary';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
}));

interface Props extends RouteComponentProps<any> {
}

const UserViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const userId = props.match.params['userId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchUser, isFetchingUser, user, fetchUserError] = useFetchDubberUser();
    const [deleteUser, isDeletingUser, userDeleted, deleteUserError] = useDeleteDubberUser();
    const [fetchDubPoints, isFetchingDubPoints, accountDubPoints, fetchDubPointsError] = useFetchDubberDubPoints();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingUser || isFetchingDubPoints,
        fetchAccountError || fetchUserError || deleteUserError || fetchDubPointsError
    );

    useEffect(() => {
        fetchUser(groupId, accountId, userId);
        fetchAccount(groupId, accountId);
    }, [accountId, userId]);

    useEffect(() => {
        fetchDubPoints(groupId, accountId);
    }, []);

    const userDubPoints = useMemo(() => {
        if (!accountDubPoints) {
            return null;
        }

        return accountDubPoints.filter(d => d.user === userId);
    }, [accountDubPoints]);

    const confirmDelete = (deleteRecordings: boolean) => {
        deleteUser(groupId, accountId, userId, deleteRecordings);
    };

    // Back to user listing if deleted
    useEffect(() => {
        if (userDeleted) {
            dispatch(appWindowAddNotification('User deleted.', 'success'));
            history.push(dubberAccountViewUrl(groupId, accountId));
        }
    }, [userDeleted]);

    const addDubPointUrl = dubberDubPointCreateUrl(groupId, accountId) + "?userId=" + userId;

    return (
        <div className={classes.root}>
            {user !== null && account !== null && userDubPoints !== null
                && <>
                    <PageHeader text="Dubber Users" subtext={`${user.firstName} ${user.lastName}`}>
                        {authorizedFor(DubberPolicies.CanUpdateUsers, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={dubberUserUpdateUrl(groupId, accountId, userId)} {...props as any} ref={ref} />)}>
                                Update
                            </Button>
                        }

                        {authorizedFor(DubberPolicies.CanCreateAndDeleteUsers, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                onClick={() => setDeleteDialogOpen(true)}
                                color="secondary">
                                Delete
                            </Button>
                        }
                    </PageHeader>

                    <UserSummary groupId={groupId} account={account} user={user} />

                    <div className={classes.section}>
                        <div className={classes.subheading}>
                            <Typography variant="h3">Dub Points</Typography>
                            {authorizedFor(DubberPolicies.CanManageDubPoints, session.roles) &&
                                <Button
                                    className={classes.headerButton}
                                    variant="text"
                                    color="primary"
                                    component={React.forwardRef((props, ref) => <Link to={addDubPointUrl} {...props} />)}>
                                    Add New
                                </Button>
                            }
                        </div>

                        {userDubPoints.length === 0
                            ? <Typography variant="body2">No dub points are assigned to this user.</Typography>
                            :
                            <Paper className={classes.paper}>
                                <DubPointTable
                                    size="small"
                                    accountId={accountId}
                                    groupId={groupId}
                                    dubPoints={userDubPoints} />
                            </Paper>
                        }
                    </div>

                    <DeleteDialog
                        showWorking={isDeletingUser}
                        isOpen={deleteDialogOpen}
                        onCancel={() => setDeleteDialogOpen(false)}
                        onConfirm={(deleteRecordings: boolean) => confirmDelete(deleteRecordings)} />
                </>
            }
        </div>
    );
};

export default UserViewPage;