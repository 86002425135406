import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Number } from './api';

interface Props {
    items: Number[];
    selectedNumbers: string[];
    onToggle: (number: string) => void;
}

const NumberTable = (props: Props) => {
    const { items, selectedNumbers, onToggle } = props;

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        onToggle(e.target.value);
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Assigned</TableCell>
                    <TableCell>Activated</TableCell>
                    <TableCell>Remove</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(number => {
                    const isSelected = selectedNumbers.indexOf(number.number) > -1;

                    return (
                        <TableRow key={number.number}>
                            <TableCell component="th" scope="row">{number.number}</TableCell>
                            <TableCell>{number.isAssigned ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{number.isActivated ? 'Yes' : 'No'}</TableCell>
                            <TableCell>
                                <Checkbox
                                    disabled={number.isAssigned}
                                    value={number.number}
                                    checked={isSelected}
                                    onChange={handleCheckbox} />
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;