import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import { State } from '../../store';
import theme from '../../theme';
import { authLogoutAction } from '../auth/actions';
import { loginMfaUrl, loginUrl } from '../auth/urls';
import Header from './Header';
import NavDrawer, { SubNavItem, TopNavItem } from './NavDrawer';
import Notifier from './Notifier';
import moment = require('moment');

/**
 * When logged in, the AppWindow container displays the nav, heading, and current content components
 */

const useStyles = makeStyles(() => ({
    main: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    aboveContent: theme.mixins.toolbar
}));

interface SelectedState {
    // Whether or not the progess bar should be shown under the header
    headerShowProgress: boolean;

    // Set via Store to whether or not a session is active
    activeSession: boolean;

    // Whether the user has been fully authenticated via MFA
    authenticated: boolean;
    
    // Current selected navigation item
    nav?: TopNavItem;
    subNav?: SubNavItem;
}

interface Props {
    children: ReactNode;
}


const AppWindow = (props: Props) => {
    const { children } = props;

    const { headerShowProgress, activeSession, authenticated, nav, subNav } = useSelector<State, SelectedState>(state => ({
        headerShowProgress: state.appWindow.showProgressBar,
        activeSession:
            state.auth.token != null
            && state.auth.expiresAt != null
            && moment.utc(state.auth.expiresAt).isAfter(moment.utc()),
        authenticated: state.auth.status === 'Authenticated',
        nav: state.appWindow.activeNav,
        subNav: state.appWindow.activeSubNav
    }));

    const classes = useStyles();
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

    const dispatch = useDispatch();
    const logout = () => dispatch(authLogoutAction());

    // Check if user is currently logged in.
    // If not, redirect them to /login
    useEffect(() => {
        if (!activeSession) {
            history.push(loginUrl());
        } else {
            // If logged in but haven't passed MFA then push them to MFA login
            if (!authenticated) {
                history.push(loginMfaUrl());
            }
        }
    }, [activeSession]);

    // For mobile users, the Drawer is hidden and must be toggled to view
    const handleDrawerToggle = () => setMobileDrawerOpen(!mobileDrawerOpen);

    return (
        activeSession && authenticated ? (
            <SnackbarProvider>
                <Notifier/>
                <Header
                    showProgress={headerShowProgress}
                    onDrawerToggle={handleDrawerToggle}
                    onLogout={logout}/>

                <NavDrawer
                    mobileNavOpen={mobileDrawerOpen}
                    onDrawerToggle={handleDrawerToggle}
                    selectedTopNav={nav}
                    selectedSubNav={subNav}/>

                <main className={classes.main}>
                    <div className={classes.aboveContent}/>
                    { children }
                </main>
            </SnackbarProvider>
        ) : <></>
    );
};

export default AppWindow;