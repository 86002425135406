import { AppBar, Box, LinearProgress, Paper, Tab, Tabs as UiTabs } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import history from '../../../history';
import theme from '../../../theme';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { OrganizationDetails } from '../../organizations/api';
import { Fax2MailPolicies } from '../policies';
import { fax2mailAssignmentViewUrl } from '../urls';
import StatementsTabPane from './StatementsTabPane';

interface Props {
    organization: OrganizationDetails;
    assignmentId: number;
    assignmentActive: boolean;
    activeTab?: Tabs;
}

const useStyles = makeStyles(() => ({
    root: {
    },
    tabPaperContents: {
        padding: theme.spacing(2)
    }
}));

export type Tabs = 'statements';

/**
 * Tab display on Fax2Mail assignment view
 * @param props
 * @constructor
 */
const AssignmentViewTabs = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const { organization, assignmentId } = props;

    const [showLoadingBar, setShowLoadingBar] = useState(false);
    const activeTab = props.activeTab || 'statements';

    const handleTabChange = (_: React.ChangeEvent<{}>, value: Tabs) => {
        if (value === 'statements') {
            history.push(fax2mailAssignmentViewUrl(organization.id, assignmentId));
        }
    };

    const showStatements = authorizedFor(Fax2MailPolicies.CanViewStatements, session.roles);

    return (
        <Paper className={classes.root}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <UiTabs value={activeTab} onChange={handleTabChange}>
                    {showStatements && <Tab value="statements" label="Statements" />}
                </UiTabs>
            </Box>

            <div className={classes.tabPaperContents}>
                {activeTab === 'statements' && showStatements &&
                    <StatementsTabPane
                        organizationId={organization.id}
                        assignmentId={assignmentId}
                        onLoadStateChange={loading => setShowLoadingBar(loading)} />
                }
            </div>

            {showLoadingBar && <LinearProgress />}
        </Paper>
    );
};

export default AssignmentViewTabs;