import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    trunks: number;
}

// Location Premise Trunks Summary Box
const TrunksSummary = (props: Props) => {
    const classes = useStyles();
    const { trunks } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Premise Trunks</Typography>
                    <Typography>
                        {trunks}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default TrunksSummary;