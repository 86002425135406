export * from './e911s/api';
export * from './lidbs/api';
export * from './move-numbers/api';
export * from './new-numbers/api';
export * from './tn-options/api';

export type OrderStatus =
    'Received'
    | 'Processing'
    | 'Complete'
    | 'Partial'
    | 'Failed'
    | 'PartialComplete'
    | 'AdjustedComplete'
    | 'AdjustedPartial'
    | 'Exception'
    | 'Backordered'
    | 'Foc'
    | 'Submitted'
    | 'PendingDocuments'
    | 'RequestedSupp'
    | 'RequestedCancel'
    | 'Cancelled'
    | 'Draft';

export const ORDER_STATUSES: OrderStatus[] = [
    'Received'
    , 'Processing'
    , 'Complete'
    , 'Partial'
    , 'Failed'
    , 'PartialComplete'
    , 'AdjustedComplete'
    , 'AdjustedPartial'
    , 'Exception'
    , 'Backordered'
    , 'Foc'
    , 'Submitted'
    , 'PendingDocuments'
    , 'RequestedSupp'
    , 'RequestedCancel'
    , 'Cancelled'
    , 'Draft'
];

export type OrderType =
    'E911'
    | 'Lidb'
    | 'MoveNumber'
    | 'NewNumber'
    | 'TnOption'
    | 'PortIn';

// Core fields found in all order types
export interface OrderBase {
    id: string;
    type: OrderType;
    accountId: string;
    customerOrderId: string | null;
    createdByUser: string;
    createDate: string;
    status: OrderStatus;
}

export interface OrderNote {
    id: number;
    userId: string;
    description: string;
    date: string;
}

export interface OrderHistory {
    date: string;
    note: string;
    author: string;
    status: OrderStatus;
}