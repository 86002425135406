import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, IconButton, LinearProgress, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { DRAWER_WIDTH } from '../../constants';
import theme from '../../theme';
import { UserHeaderMenu } from '../auth/UserHeaderMenu';

const useStyles = makeStyles(() => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    branding: {
        ...theme.typography.h1,
        width: DRAWER_WIDTH,
        flexGrow: 1,
        color: '#FFFFFF'
    },
    subBranding: {
        //...theme.typography.h5,
        color: '#FFFFFF'
    }
}));

interface Props {
    showProgress: boolean;
    onDrawerToggle: () => void;
    onLogout: () => void;
}

/**
 * Displays the current section being viewed.
 * Also includes a menu button for Mobile users to open the navigation drawer.
 *
 * @param props
 */
export default function Header(props: Props) {
    const { onDrawerToggle, onLogout, showProgress } = props;
    const classes = useStyles();

    return (
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="Open Navigation"
                    onClick={() => onDrawerToggle()}
                    className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>

                <div className={classes.branding}>
                    Konica Minolta

                    <div className={classes.subBranding}>
                        Unified Communications
                    </div>
                </div>

                <UserHeaderMenu onLogout={onLogout}/>
            </Toolbar>
            {showProgress && <LinearProgress variant="indeterminate" color="secondary"/>}
        </AppBar>
    );
}