import { useEffect, useState } from 'react';
import { ResponseError, useApi } from '../../api';
import history from '../../history';
import { loginUrl } from '../auth/urls';
import {
    fetchHostedPbxBandwidthSubAccount, fetchHostedPbxBandwidthSubAccounts,
    fetchHostedPbxGroup, fetchHostedPbxGroups,
    Group,
    GroupItem,
    SubAccount, SubAccountItem,
    Seat,
    fetchHostedPbxSeats,
    fetchHostedPbxDubberAccounts,
    DubberAccount,
    DubberAccountDetails,
    fetchHostedPbxDubberAccount,
    Platform
} from './api';

export * from './assignment/hooks';
export * from './9line/hooks';

type FetchHookResponse<T> = [
    boolean,
    T | null,
    string | null
];

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];


/**
 * Hook for fetching all groups in BroadWorks
 */

type FetchGroupsFunc = (platform: Platform, sortBy?: 'id' | 'name', omitTemplate?: boolean) => void;

export const useFetchHostedPbxGroups = (): TriggeredFetchHookResponse<FetchGroupsFunc, GroupItem[]> => {
    interface Request {
        ts: number;
        platform: Platform;
        sortBy: 'id' | 'name';
        omitTemplate: boolean;
    }

    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [groups, setGroups] = useState<GroupItem[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);

    const fetch: FetchGroupsFunc = (platform: Platform, sortBy?: 'id' | 'name', omitTemplate?: boolean) => {
        sortBy = sortBy || 'id';
        omitTemplate = omitTemplate || false;

        setRequest({
            platform,
            sortBy,
            omitTemplate,
            ts: Date.now()
        });
    };

    const applySortAndFilter = (groups: GroupItem[], omitTemplate: boolean, sortBy: 'id' | 'name') => {
        return groups !== null
            ? groups
                .filter(grp => !omitTemplate || grp.id !== '000000')
                .sort((a, b) => {
                    if (sortBy == 'name') {
                        if (a.name > b.name) {
                            return 1;
                        }

                        if (a.name < b.name) {
                            return -1;
                        }
                    } else {
                        if (a.id > b.id) {
                            return 1;
                        }

                        if (a.id < b.id) {
                            return -1;
                        }
                    }

                    return 0;
                })
            : null;
    };

    useEffect(() => {
        if (request) {

            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchHostedPbxGroups(api, request.platform);
                    if (!didCancel) {
                        setGroups(applySortAndFilter(response, request.omitTemplate, request.sortBy));
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve groups.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        groups,
        error
    ];
};

/**
 * Hook for fetching all seats from Group
 */

type FetchSeatsFunc = (platform: Platform, groupId: string) => void;

export const useFetchHostedPbxSeats = (): TriggeredFetchHookResponse<FetchSeatsFunc, Seat[]> => {
    interface Request {
        platform: Platform;
        groupId: string;
        ts: number;
    }

    const api = useApi();
    //const [groupId, setGroupId] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);
    //const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [seats, setSeats] = useState<Seat[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchSeatsFunc = (platform: Platform, groupId: string) => {
        setRequest({
            platform,
            groupId,
            ts: Date.now()
        });
        //setGroupId(groupId);
        //setRequestTs(Date.now());
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setSeats(null);

                try {
                    const response = await fetchHostedPbxSeats(api, request.platform, request.groupId);
                    if (!didCancel) {
                        setSeats(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve seats.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        seats,
        error
    ];
};

/**
 * Hook for fetching group details from BroadWorks
 */
export const useFetchHostedPbxGroup = (groupId: string): FetchHookResponse<Group> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [group, setGroup] = useState<Group | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchHostedPbxGroup(api, 'Cisco', groupId);
                if (!didCancel) {
                    setGroup(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError('Unable to retrieve group.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, []);

    return [
        isFetching,
        group,
        error
    ];
};

/**
 * Hook for fetching all subaccounts in Bandwidth
 */

type FetchSubAccountsFunc = (platform: Platform) => void;

export const useFetchHostedPbxBandwidthSubAccounts = (): TriggeredFetchHookResponse<FetchSubAccountsFunc, SubAccountItem[]> => {
    interface Request {
        platform: Platform;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [accounts, setAccounts] = useState<SubAccountItem[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchSubAccountsFunc = (platform: Platform) => {
        setRequest({
            platform,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {

            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchHostedPbxBandwidthSubAccounts(api, request.platform);
                    if (!didCancel) {
                        setAccounts(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve sub-accounts.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        accounts,
        error
    ];
};

/**
 * Hook for fetching a subaccount from Bandwidth
 */
export const useFetchHostedPbxBandwidthSubAccount = (subaccountId: string): FetchHookResponse<SubAccount> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [account, setAccount] = useState<SubAccount | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchHostedPbxBandwidthSubAccount(api, subaccountId);
                if (!didCancel) {
                    setAccount(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError('Unable to retrieve sub-account.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, []);

    return [
        isFetching,
        account,
        error
    ];
};

/**
 * Hook for fetching all accounts in Dubber
 */
export const useFetchHostedPbxDubberAccounts = (): TriggeredFetchHookResponse<() => void, DubberAccount[]> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [accounts, setAccounts] = useState<DubberAccount[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);

    const fetch = () => {
        setRequestTs(Date.now());
    };

    useEffect(() => {
        if (requestTs !== null) {

            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchHostedPbxDubberAccounts(api);
                    if (!didCancel) {
                        setAccounts(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve Dubber accounts.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [requestTs]);

    return [
        fetch,
        isFetching,
        accounts,
        error
    ];
};

/**
 * Hook for fetching Dubber account details for HPBX
 */

type FetchAccountFunc = (accountId: string) => void;

export const useFetchHostedPbxDubberAccount = (): TriggeredFetchHookResponse<FetchAccountFunc, DubberAccountDetails> => {
    interface Request {
        accountId: string;
        ts: number;
    }

    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [account, setAccount] = useState<DubberAccountDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);

    const fetch: FetchAccountFunc = (accountId: string) => {
        setRequest({
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {

            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchHostedPbxDubberAccount(api, request.accountId);
                    if (!didCancel) {
                        setAccount(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve Dubber account.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        account,
        error
    ];
};
