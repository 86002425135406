import { AdministratorRole } from '../administrators/api';
import { SessionStatus } from './api';

// Fired when user attempts login
export const AUTH_LOGIN = 'AUTH_LOGIN_REQUEST';

// Fired when user complete MFA step
export const AUTH_MFA_COMPLETE = 'AUTH_MFA_COMPLETE';

// Fired when the user logs out
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export type AuthLoginAction = {
    type: typeof AUTH_LOGIN,
    payload: {
        token: string;
        roles: AdministratorRole[];
        expiresAt: string;
        administratorId: number | null;
        status: SessionStatus | null
    }
}

export type AuthMfaCompleteAction = {
    type: typeof AUTH_MFA_COMPLETE
}

export type AuthLogoutAction = {
    type: typeof AUTH_LOGOUT
}

export const authLoginAction = (token: string, expiresAt: string, status: SessionStatus, roles: AdministratorRole[], userId: number): AuthLoginAction => ({
    type: AUTH_LOGIN,
    payload: {
        token,
        expiresAt,
        roles,
        administratorId: userId,
        status
    }
});

export const authMfaCompleteAction = (): AuthMfaCompleteAction => ({
    type: AUTH_MFA_COMPLETE
});

export const authLogoutAction = (): AuthLogoutAction => ({
    type: AUTH_LOGOUT
});

export type Actions =
    | AuthLoginAction
    | AuthMfaCompleteAction
    | AuthLogoutAction;