import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { DubPoint, User } from '../api';
import { TeamMemberIndex } from './api';
import { useFetchDubberTeamMember } from './hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    userId: string;
    listener: boolean;
    dubPointIds: string[];
}

interface Props {
    groupId: string;
    accountId: string;
    teamId: string;
    userOptions: User[];
    accountDubPoints: DubPoint[];
    teamMembers: TeamMemberIndex[];
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean | string[]) => void;
}

const CreateTeamMemberForm = (props: Props) => {
    const classes = useStyles();
    const { groupId, accountId, teamId, userOptions, accountDubPoints, teamMembers, formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate } = props;
    const [fetchTeamMember, isFetchingTeamMember, teamMemberDetails, fetchTeamMemberError] = useFetchDubberTeamMember();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const userExistsInTeam = useMemo(() => {
        if (formValues.userId.length > 0) {
            return teamMembers.findIndex(m => m.user === formValues.userId) > -1;
        } else {
            return false;
        }
    }, [formValues.userId]);

    // Get list of dub points assigned to selected user
    const userDubPoints = useMemo(() => {
        if (formValues.userId) {
            return accountDubPoints.filter(dp => dp.user === formValues.userId);
        }

        return [];
    }, [formValues.userId]);

    // Update form values if user changes
    useEffect(() => {
        if (formValues.userId.length > 0) {
            const teamMember = teamMembers.find(m => m.user === formValues.userId);

            if (teamMember) {
                onUpdate('listener', teamMember.listener);
                fetchTeamMember(groupId, accountId, teamId, formValues.userId);
            }
        }
    }, [formValues.userId]);


    useEffect(() => {
        if (teamMemberDetails) {
            onUpdate('dubPointIds', teamMemberDetails.dubPoints.map(d => d.dubPoint));
        }
    }, [teamMemberDetails]);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage || fetchTeamMemberError}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('userId')}>
                        <InputLabel htmlFor="userId">User</InputLabel>
                        <Select
                            id="userId"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.userId}
                            input={<Input name="userId" id="userId" />}
                            onChange={evt => onUpdate('userId', evt.target.value)}>
                            <option></option>
                            {userOptions.map(user => <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>)}
                        </Select>
                        <ValidationMessage field="userId" />
                    </FormControl>
                </Grid>

                {isFetchingTeamMember && <Grid item xs={12}><LinearProgress /></Grid>}

                {formValues.userId && !isFetchingTeamMember &&
                    <>
                        {userExistsInTeam && <Grid item xs={12}><Typography>User exists in Team. You can update their membership below.</Typography></Grid>}

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="listener"
                                        checked={formValues.listener}
                                        disabled={isSubmitting}
                                        onChange={evt => onUpdate('listener', evt.target.checked)}
                                        value="1"
                                        color="primary"
                                    />
                                }
                                label="User can listen to team recordings" />

                            <ValidationMessage field="listener" />
                        </Grid>


                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} fullWidth error={!isValid('dubPointIds')}>
                                <FormLabel>Dub Points</FormLabel>
                                {userDubPoints.length === 0
                                    ? <Typography>User has no Dub Points assigned.</Typography>
                                    : <FormGroup>
                                        {userDubPoints.map(dp => {
                                            return (
                                                <FormControlLabel
                                                    key={dp.id}
                                                    control={
                                                        <Checkbox
                                                            name="roles"
                                                            color="primary"
                                                            checked={formValues.dubPointIds.indexOf(dp.id) > -1}
                                                            disabled={isSubmitting}
                                                            onChange={evt => {
                                                                if (evt.target.checked) {
                                                                    onUpdate('dubPointIds', [
                                                                        ...formValues.dubPointIds,
                                                                        dp.id
                                                                    ]);
                                                                } else {
                                                                    onUpdate('dubPointIds', formValues.dubPointIds.filter(v => v !== dp.id));
                                                                }
                                                            }}
                                                            value={dp.id} />
                                                    }
                                                    label={
                                                        <div>
                                                            <Typography variant="body1">{dp.id}</Typography>
                                                            <Typography variant="body2">{dp.externalIdentifier}</Typography>

                                                        </div>
                                                    } />
                                            );
                                        })}
                                    </FormGroup>
                                }
                                <ValidationMessage field="dubPointIds" />
                            </FormControl>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    );
};

export default CreateTeamMemberForm;