import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { TnOptions } from '../api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    tnOptions: TnOptions;
}

// TN Options Summary Box
const TnOptionsSummary = (props: Props) => {
    const classes = useStyles();
    const { tnOptions } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">CNAM Display</Typography>
                    <Typography>
                        {tnOptions.callingNameDisplayed === null ? 'Default' : (tnOptions.callingNameDisplayed ? 'Yes' : 'No')}
                    </Typography>
                </Grid>

                {tnOptions.nnid !== null &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">NNID Assignment</Typography>
                        <Typography>
                            {tnOptions.nnid}
                        </Typography>
                    </Grid>
                }

                {tnOptions.failoverUri !== null &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Failover URI</Typography>
                        <Typography>
                            {tnOptions.failoverUri}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>
    );
}

export default TnOptionsSummary;