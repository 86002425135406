import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Administrator } from '../api';
import { administratorViewUrl } from '../urls';

interface Props {
    administrators: Administrator[];
}

const AdministratorTable = (props: Props) => {
    const { administrators } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {administrators.map(admin => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={administratorViewUrl(admin.id)} {...props} />);

                    return (
                        <TableRow key={admin.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{admin.email}</Link>
                            </TableCell>
                            <TableCell>{admin.firstName}</TableCell>
                            <TableCell>{admin.lastName}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default AdministratorTable;