import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { amber, grey } from '@mui/material/colors';
import * as React from 'react';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: amber[800],
        borderColor: amber[900],
        borderWidth: 2,
        borderStyle: 'solid'
    },
    text: {
        color: grey[800]
    }
}));

/**
 * Displays a box alerting when there's no fax2mail seats saved and default values are being presented
 * @constructor
 */
const DefaultNotice = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            {/*<Typography variant="h4" className={classes.text}>Notice</Typography>*/}
            <Typography className={classes.text}>No seat information has yet been saved. Update the names below and press <strong>Update Seats</strong></Typography>
        </Paper>);
};

export default DefaultNotice;