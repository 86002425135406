import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { dubberUnidentifiedDubPointViewUrl } from '../../urls';
import { UnidentifiedDubPoint } from '../api';

interface Props {
    groupId: string;
    dubPoints: UnidentifiedDubPoint[];
    size?: 'medium' | 'small';
}

export default function UnidentifiedDubPointTable(props: Props) {
    const { groupId, dubPoints, size } = props;

    return (
        <Table size={size}>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Group ID</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Recordings</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(dubPoints || []).map(dp => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberUnidentifiedDubPointViewUrl(groupId, dp.id)} {...props} />);

                    return <TableRow key={dp.id}>
                        <TableCell component="th" scope="row">
                            <Link component={LinkComponent}>{dp.id}</Link>
                        </TableCell>
                        <TableCell>{dp.externalGroup}</TableCell>
                        <TableCell>{dp.externalIdentifier}</TableCell>
                        <TableCell>{dp.recordingCount}</TableCell>
                    </TableRow>;
                }
                )}
            </TableBody>
        </Table>
    );
}