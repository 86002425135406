import {
    Dialog, DialogTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import theme from '../../theme';
import { authLogoutAction } from './actions';
import { MfaMethods } from './api';
import EmailMethod from './EmailMethod';
import MethodSelection from './MethodSelection';
import AuthenticatorMethod from './AuthenticatorMethod';
import { loginUrl } from './urls';

const useStyles = makeStyles({
    formControl: {
        marginTop: theme.spacing(1),
        maginBottom: theme.spacing(1)
    }
});

const MfaPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selectedMethod, setSelectedMethod] = useState<MfaMethods | null>(null);

    // If Cancel is pressed, destroy session and go back to login screen
    const handleCancel = () => {
        dispatch(authLogoutAction());
        history.push(loginUrl());
    };

    return (
        <Dialog open={true} aria-labelledby="login">
            <DialogTitle id="login">Konica Minolta Unified Communications</DialogTitle>

            {selectedMethod === null && <MethodSelection onCancel={() => handleCancel()} onSelect={method => setSelectedMethod(method)} />}
            {selectedMethod === 'Email' && <EmailMethod onCancel={() => setSelectedMethod(null)}/> }
            {selectedMethod === 'Authenticator' && <AuthenticatorMethod onCancel={() => setSelectedMethod(null)}/> }
        </Dialog>
    );
};

export default MfaPage;