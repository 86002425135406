import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { PortInOrder } from './api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    order: PortInOrder;
}

// Port In Order End-User Information Box
const PortInEndUserInformation = (props: Props) => {
    const classes = useStyles();
    const { order } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">Account Type</Typography>
                    <Typography>
                        {order.subscriber.subscriberType}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">Customer/Subscriber Name</Typography>
                    <Typography>
                        {order.subscriber.businessName}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">Authorizing Person (Name on LOA)</Typography>
                    <Typography>
                        {order.loaAuthorizingPerson}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row">
                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">Account Number</Typography>
                    <Typography>
                        {order.wirelessAccountNumber || 'N/A'}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">Account PIN</Typography>
                    <Typography>
                        {order.wirelessPin || 'N/A'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PortInEndUserInformation;