import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchBandwidthAccount } from '../hooks';
import { BandwidthPolicies } from '../policies';
import { bandwidthLocationViewUrl } from '../urls';
import { LocationAddressForm, LocationForm as LocationFormData } from './api';
import { useFetchBandwidthLocation, useUpdateBandwidthLocation } from './hooks';
import LocationForm from './LocationForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    errorMessage: {
        backgroundColor: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const LocationUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [fetch, isFetching, location, fetchErrorMessage] = useFetchBandwidthLocation();
    const [fetchAccount, isFetchingAccount, account, fetchAccountErrorMessage] = useFetchBandwidthAccount();

    const [update, isUpdating, updateSuccess, updateErrorMessage, updateFieldErrorMessages] = useUpdateBandwidthLocation();

    const accountId = props.match.params['accountId'];
    const subAccountId = props.match.params['subAccountId'];
    const locationId = props.match.params['locationId'];

    const [formValues, setFormValues] = useState<LocationFormData>({
        accountId,
        subAccountId,
        locationId,
        name: '',
        description: '',
        isDefault: true,
        failoverUri: '',
        originationDistribution: 'None',
        originationHosts: [],
        terminationHosts: [],
        trafficAllowed: 'Domestic',
        callingNameDisplayed: true,
        callingNameEnforced: false,
        premiseTrunks: null,
        address: null,
        stirShaken: {
            inboundVerification: 'Off',
            transmitIdentityHeader: false
        }
    });

    // Update nav
    useNav('bandwidth', 'locations');

    // Display progress bar and errors
    useProgressEffects(
        isFetching || isFetchingAccount,
        fetchErrorMessage || fetchAccountErrorMessage
    );

    // Fetch account & location details on load
    useEffect(() => {
        fetchAccount(accountId);
        fetch(accountId, subAccountId, locationId);
    }, []);

    // Fill form once account & location details are loaded
    useEffect(() => {
        if (account !== null && location !== null) {
            setFormValues({
                accountId,
                subAccountId,
                locationId: location.id,
                name: location.name,
                description: location.description,
                isDefault: location.isDefault,
                failoverUri: location.failoverUri,
                originationDistribution: location.originationDistribution,
                originationHosts: location.originationHosts,
                terminationHosts: location.terminationHosts,
                trafficAllowed: location.trafficAllowed,
                callingNameDisplayed: location.callingNameDisplayed,
                callingNameEnforced: location.callingNameEnforced,
                address: location.address,
                premiseTrunks: location.premiseTrunks,
                stirShaken: location.stirShaken
            });
        }
    }, [account, location]);

    // On successful update, redirect back to view page
    useEffect(() => {
        if (updateSuccess === true) {
            dispatch(appWindowAddNotification('Location updated.', 'success'));

            history.push(bandwidthLocationViewUrl(accountId, subAccountId, locationId));
        }
    }, [updateSuccess]);

    // Handle submit button
    const handleSubmit = () => {
        update(formValues);
    };

    // Cancel redirects back to the view URL
    const handleCancel = () => {
        history.push(bandwidthLocationViewUrl(accountId, subAccountId, locationId));
    };

    // Adjust form state when a field is modified
    const handleFieldChange = (fieldName: keyof LocationFormData, value: boolean | string | string[] | number | null | LocationAddressForm) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Update Location"
                subtext={location !== null ? location.name : undefined} />

            {!isFetching && !isFetchingAccount && account && 
                <>
                    <Paper className={classes.paper}>
                    <LocationForm
                        accountService={account.service}
                        isWholesaleAccount={account.type == 'Wholesale'}
                        formValues={formValues}
                        isSubmitting={isUpdating}
                        validationMessage={updateErrorMessage}
                        validationFieldMessages={updateFieldErrorMessages}
                        onFieldChange={handleFieldChange}
                        onFieldKeyDown={handleKeyDown} />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(LocationUpdatePage, BandwidthPolicies.CanManageLocations);
