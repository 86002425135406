import { Box, Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useCheckBandwidthPortability } from './hooks';
import NumberForm from './NumberForm';
import OverviewPane from './OverviewPane';
import RateCenterPane from './RateCenterPane';
import LosingCarrierPane from './LosingCarrierPane';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    },
    tabPaper: {
        padding: 0
    },
    tabPaperContents: {
        padding: 0
    },
}));

interface Props extends RouteComponentProps<any> {
}

type TabValue = 'overview' | 'rate-centers' | 'losing-carriers';

// Page for checking number portability
const PortCheckPage = (props: Props) => {
    const classes = useStyles();
    const [check, isChecking, portabilityResults, errorMessage, validationMessages] = useCheckBandwidthPortability();
    const [formValue, setFormValue] = useState('');
    const [activeTab, setActiveTab] = useState<TabValue>('overview');

    // Update navigation
    useNav('bandwidth', 'port-check');

    // Display progress bar and error messages
    useProgressEffects(
        isChecking,
        errorMessage
    );

    const handleSubmit = () => {
        const val = formValue.replace(/,/g, "\n");
        setFormValue(val);
        check(val.split("\n").map(v => v.trim()).filter(v => v.length > 0).filter((v, i, a) => a.indexOf(v) == i));
    };

    // Reset tabs when data is loaded
    useEffect(() => {
        setActiveTab('overview');
    }, [portabilityResults]);

    const handleTabChange = (_: React.ChangeEvent<{}>, value: TabValue) => {
        setActiveTab(value);
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth" subtext="Number Port Checker" />

            <Paper className={classes.paper}>
                <NumberForm
                    value={formValue}
                    onUpdate={val => setFormValue(val)}
                    validationFieldMessages={validationMessages}
                />

                <Grid container justifyContent="flex-end">
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        disabled={isChecking}
                        onClick={handleSubmit}>Check Numbers</Button>
                </Grid>
            </Paper>

            {!isChecking && portabilityResults &&
                <>
                    <Typography variant="h3" className={classes.subHeading}>Results</Typography>

                    <Paper className={classes.tabPaper}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={activeTab} onChange={handleTabChange}>
                                <Tab value="overview" label="Overview" />
                                <Tab value="rate-centers" label="Rate Centers" />
                                <Tab value="losing-carriers" label="Losing Carriers" />
                            </Tabs>
                        </Box>

                        <div className={classes.tabPaperContents}>
                            <div style={{ display: activeTab !== 'overview' ? "none" : "block" }}>
                                <OverviewPane
                                    supportedNumbers={portabilityResults.portableNumbers}
                                    unsupportedNumbers={portabilityResults.unportableNumbers}
                                    portabilityErrors={portabilityResults.portabilityErrors}
                                />
                            </div>

                            <div style={{ display: activeTab !== 'rate-centers' ? "none" : "block" }}>
                                <RateCenterPane rateCenters={portabilityResults.rateCenters} />
                            </div>

                            <div style={{ display: activeTab !== 'losing-carriers' ? "none" : "block" }}>
                                <LosingCarrierPane losingCarriers={portabilityResults.losingCarriers} />
                            </div>
                        </div>
                    </Paper>
                </>
            }
        </div>
    );
};

export default PortCheckPage;