import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import { Account, User } from '../api';
import { languageLabel, roleLabel } from '../helpers';
import { dubberAccountViewUrl } from '../urls';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    groupId: string;
    account: Account;
    user: User;
}

const UserSummary = (props: Props) => {
    const classes = useStyles();
    const { groupId, account, user } = props;

    const AccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(groupId, account.id)} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>
                        <Link component={AccountLinkComponent}>{account.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Role</Typography>
                    <Typography>{roleLabel(user.role)}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">First Name</Typography>
                    <Typography>{user.firstName}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Last Name</Typography>
                    <Typography>{user.lastName}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Username</Typography>
                    <Typography>{user.username || 'None'}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Confirmed</Typography>
                    <Typography>{user.confirmed ? 'Yes' : 'No'}</Typography>
                </Grid>

                {user.mobileNumber &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Mobile Number</Typography>
                        <Typography>{user.mobileNumber}</Typography>
                    </Grid>
                }

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Language</Typography>
                    <Typography>{languageLabel(user.language)}</Typography>
                </Grid>
            </Grid>
        </Paper>);
};

export default UserSummary;