import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../theme';

interface Props {
    title?: string;
    message: string;
    secondary?: string;
    showWorking: boolean;
    isOpen: boolean;
    cancelColor?: PropTypes.Color;
    cancelText?: string;
    confirmColor?: PropTypes.Color;
    confirmText?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const useStyles = makeStyles(() => ({
    secondary: {
        marginTop: theme.spacing(1),
        fontSize: '0.9rem'
    },
}));

/**
 * Displays a simple confirmation dialog box
 *
 * @param props
 * @constructor
 */
const ConfirmationDialog = (props: Props) => {
    const {
        title,
        message,
        secondary,
        showWorking,
        isOpen,
        cancelText,
        confirmText,
        onCancel,
        onConfirm
    } = props;

    const cancelColor = props.cancelColor || 'primary';
    const confirmColor = props.confirmColor || 'secondary';

    const classes = useStyles();

    const handleCancel = () => {
        if (!showWorking) {
            onCancel();
        }
    };

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={handleCancel}
            aria-labelledby="confirmation-dialog-title">
            <DialogTitle id="confirmation-dialog-title">{title || 'Confirm'}</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    {message}
                </DialogContentText>

                {secondary &&
                    <DialogContentText color="textSecondary" className={classes.secondary}>
                        {secondary}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color={cancelColor} disabled={showWorking}>
                    {cancelText || 'Cancel'}
                </Button>
                <Button onClick={onConfirm} color={confirmColor} autoFocus disabled={showWorking}>
                    {confirmText || 'Confirm'}
                </Button>
            </DialogActions>
            {showWorking && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

export default ConfirmationDialog;