import { ApiCaller } from '../../../api';

export const SEAT_BASIC = 'Basic';
export const SEAT_PREMIUM = 'Premium';
export const SEAT_PREMIUMPLUS = 'PremiumPlus';
export const SEAT_ENCRYPTED = 'Encrypted';

export interface Seat {
    id: number;
    name: string;
    type: SeatType;
}

export type SeatType =
    typeof SEAT_BASIC
    | typeof SEAT_PREMIUM
    | typeof SEAT_PREMIUMPLUS
    | typeof SEAT_ENCRYPTED;

export const seatTypeName = (type: SeatType) => {
    switch (type) {
        case SEAT_BASIC:
            return 'Basic';
        case SEAT_PREMIUM:
            return 'Premium';
        case SEAT_PREMIUMPLUS:
            return 'Premium Plus';
        case SEAT_ENCRYPTED:
            return 'Encrypted';
        default: throw new Error('Unknown seat type');
    }
}

/*
 * GET /api/organizations/{organizationId}/services/fax2mail/{assignmentId}/seats
 *
 * Get seat configuration for fax2mail assignment
 */

export const fetchFax2MailSeats = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Seat[]> => {
    return await api<Seat[]>(`organizations/${organizationId}/services/fax2mail/${assignmentId}/seats`, 'GET');
};

export interface UpdateForm {
    seats: {
        id?: number;
        name: string;
        type: SeatType;
    }[];
}

/*
 * PUT /api/organizations/{organizationId}/services/fax2mail/{assignmentId}/seats
 *
 * Update seat configuration for fax2mail assignment
 */

export const updateFax2MailSeats = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateForm): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/fax2mail/${assignmentId}/seats`, 'PUT', {
        ...form
    });
};