import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { WebexProduct, WebexProductCounts } from './api';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMemo, useState } from 'react';
import theme from '../../../theme';

interface Props {
    counts: WebexProductCounts;
}

interface ProductRow {
    label: string;
    hasData: boolean;
    errorMessage?: string;
    webexVoice: number;
    webexCalling: number;
    webexMeetings: number;
    webexSuite: number;
    commonAreaCalling: number;
}

const incrementRow = (row: ProductRow, product: WebexProduct, quantity: number) => {
    switch (product) {
        case 'WebexCalling':
            row.webexCalling += quantity;
            break;

        case 'WebexVoice':
            row.webexVoice += quantity;
            break;

        case 'WebexMeetings':
            row.webexMeetings += quantity;
            break;

        case 'WebexSuite':
            row.webexSuite += quantity;
            break;

        case 'CommonAreaCalling':
            row.commonAreaCalling += quantity;
            break;
    }
};

const sortProductRows = (a: ProductRow, b: ProductRow) => {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
}

const ProductCountsTable = (props: Props) => {
    const { counts } = props;
    const [webexOpen, setWebexOpen] = useState(false);
    const [revioOpen, setRevioOpen] = useState(false);

    const [deployed, deployedChildren, contracted, contractedChildren] = useMemo(() => {
        let deployed: ProductRow = {
            label: 'Totals on Webex',
            hasData: true,
            webexVoice: 0,
            webexCalling: 0,
            webexMeetings: 0,
            webexSuite: 0,
            commonAreaCalling: 0
        }

        let contracted = { ...deployed };

        let deployedChildrenMap = new Map<string, ProductRow>();
        let contractedChildrenMap = new Map<string, ProductRow>();

        contracted.label = 'Totals on rev.io';

        if (!counts.hasContracted) {
            contracted.hasData = counts.hasContracted;
            contracted.errorMessage = 'No Webex services found in rev.io for this customer';
        }

        for (let i = 0; i < counts.totals.length; i++) {
            const total = counts.totals[i];

            incrementRow(deployed, total.product, total.deployed);
            incrementRow(contracted, total.product, total.contracted);
        }

        for (let i = 0; i < counts.deployed.length; i++) {
            const total = counts.deployed[i];

            if (!deployedChildrenMap.has(total.location)) {
                deployedChildrenMap.set(total.location, {
                    label: total.location,
                    hasData: true,
                    webexVoice: 0,
                    webexCalling: 0,
                    webexMeetings: 0,
                    webexSuite: 0,
                    commonAreaCalling: 0
                });
            }

            incrementRow(deployedChildrenMap.get(total.location)!, total.product, total.count);
        }

        for (let i = 0; i < counts.contracted.length; i++) {
            const total = counts.contracted[i];

            if (!contractedChildrenMap.has(total.location)) {
                contractedChildrenMap.set(total.location, {
                    label: total.location,
                    hasData: true,
                    webexVoice: 0,
                    webexCalling: 0,
                    webexMeetings: 0,
                    webexSuite: 0,
                    commonAreaCalling: 0
                });
            }

            incrementRow(contractedChildrenMap.get(total.location)!, total.product, total.count);
        }

        let deployedChildren = Array.from(deployedChildrenMap.values()).sort(sortProductRows);
        let contractedChildren = Array.from(contractedChildrenMap.values()).sort(sortProductRows);

        return [deployed, deployedChildren, contracted, contractedChildren];
    }, [counts]);

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>Calling</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Common Area</TableCell>
                    <TableCell>Suite</TableCell>
                    <TableCell>Voice</TableCell>
                    <TableCell>Meetings</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <RowGroup header={deployed} children={deployedChildren} isOpen={webexOpen} setIsOpen={setWebexOpen} />
                <RowGroup header={contracted} children={contractedChildren} isOpen={revioOpen} setIsOpen={setRevioOpen} />
            </TableBody>
        </Table>
    );
};

interface RowGroupProps {
    header: ProductRow;
    children: ProductRow[];
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}

const useStyles = makeStyles(() => ({
    heading: {
        backgroundColor: theme.palette.action.hover
    }
}));

const RowGroup = (props: RowGroupProps) => {
    const { header, children, isOpen, setIsOpen } = props;
    const classes = useStyles();

    return <>
        <TableRow className={classes.heading}>
            <TableCell>
                {children.length > 0 && <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>}
            </TableCell>
            <TableCell component="th" scope="row" width="100%">
                {header.label}
                {header.errorMessage && <Typography color="error" fontSize="small">{header.errorMessage}</Typography>}
            </TableCell>
            <TableCell align="right">{header.hasData ? header.webexCalling : 'N/A'}</TableCell>
            <TableCell align="right">{header.hasData ? header.commonAreaCalling : 'N/A'}</TableCell>
            <TableCell align="right">{header.hasData ? header.webexSuite : 'N/A'}</TableCell>
            <TableCell align="right">{header.hasData ? header.webexVoice : 'N/A'}</TableCell>
            <TableCell align="right">{header.hasData ? header.webexMeetings : 'N/A'}</TableCell>
        </TableRow>
        {isOpen && children.map(child =>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>{child.label}</TableCell>
                <TableCell align="right">{child.webexCalling}</TableCell>
                <TableCell align="right">{child.commonAreaCalling}</TableCell>
                <TableCell align="right">{child.webexSuite}</TableCell>
                <TableCell align="right">{child.webexVoice}</TableCell>
                <TableCell align="right">{child.webexMeetings}</TableCell>
            </TableRow>
        )}
    </>
}

export default ProductCountsTable;