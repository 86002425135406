import { ApiCaller, PagedResponse } from '../../../api';

export interface BulkUpload {
    id: number;
    userId: number;
    assignmentId: number;
    uploadedAt: string;
    status: 'Pending' | 'Working' | 'Success' | 'Error';
    pctComplete?: number;
    errors: string[];
    uploadedBy: {
        id: number;
        firstName: string;
        lastName: string;
    }
}

type OrderBy = 'id' | 'uploadedAt';
type Dir = 'asc' | 'desc';

/*
 * GET /api/organizations/{organizationId}/services/webex/{assignmentId}/bulk-uploads
 *
 * Get bulk uploads
 */

export const fetchBulkUploads = async (api: ApiCaller, organizationId: number, assignmentId: number, orderBy: OrderBy = 'id', dir: Dir = 'asc', page: number = 1, limit?: number): Promise<PagedResponse<BulkUpload>> => {
    return await api<PagedResponse<BulkUpload>>(`organizations/${organizationId}/services/webex/${assignmentId}/bulk-uploads`, 'GET', {
        orderBy,
        dir,
        page,
        limit
    });
};

/*
 * GET /api/organizations/{organizationId}/services/webex/{assignmentId}/bulk-uploads/{id}
 *
 * Get details for a bulk upload
 */

export const fetchBulkUpload = async (api: ApiCaller, organizationId: number, assignmentId: number, id: number): Promise<BulkUpload> => {
    return await api<BulkUpload>(`organizations/${organizationId}/services/webex/${assignmentId}/bulk-uploads/${id}`, 'GET');
};

/*
 * POST /api/organizations/{organizationId}/services/webex/{assignmentId}/bulk-uploads
 *
 * Create a new bulk upload
 */
export const createBulkUpload = async (api: ApiCaller, organizationId: number, assignmentId: number, base64Contents: string) => {
    return await api<BulkUpload>(`organizations/${organizationId}/services/webex/${assignmentId}/bulk-uploads`, 'POST', {
        fileContents: base64Contents
    });
};

/*
* GET /api/organizations/{organizationId}/services/webex/{assignmentId}/bulk-uploads/{id}/created-users
*
* Get URL to download created users sheet for an upload
*/
export const fetchBulkUploadDownloadUrl = async (api: ApiCaller, organizationId: number, assignmentId: number, uploadId: number): Promise<string> => {
    const response = await api<{ url: string }>(`organizations/${organizationId}/services/webex/${assignmentId}/bulk-uploads/${uploadId}/created-users`, 'GET');

    return response.url;
};