import { useEffect, useState } from "react";
import { PagedResponse, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { fetchFax2MailOrganizations, Organization, Status } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/*
 * Hook for fetching all organizations with fax2mail service
 */

type FetchOrganizationsFunc = (search?: string, status?: Status, pageNumber?: number, limit?: number) => void;

interface FetchOrganizationsRequest {
    search?: string;
    status?: Status;
    pageNumber?: number;
    limit?: number;
    ts: number;
}

export const useFetchFax2MailOrganizations = (): TriggeredFetchHookResponse<FetchOrganizationsFunc, PagedResponse<Organization>> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchOrganizationsRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [organizations, setOrganizations] = useState<PagedResponse<Organization> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchOrganizationsFunc = (search?: string, status?: Status, pageNumber?: number, limit?: number) => {
        setRequest({
            search,
            status,
            pageNumber,
            limit,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrganizations(null);

                try {
                    const organizations = await fetchFax2MailOrganizations(api, request.search, request.status, request.pageNumber, request.limit);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrganizations(organizations);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrganizations(null);
                            setError('Unable to fetch fax2mail organizations.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        organizations,
        error
    ];
};

/*
 * Hook for retrieving all Fax2Mail organizations without paging
 */

export const useFetchAllFax2MailOrganizations = (): TriggeredFetchHookResponse<() => void, Organization[]> => {
    const api = useApi();
    const [request, setRequest] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = () => {
        setRequest(Date.now());
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrganizations(null);

                try {
                    let allOrganizations: Organization[] = [];
                    let pageToFetch: number | null = 1;

                    while (pageToFetch != null && !didCancel) {
                        const response = await fetchFax2MailOrganizations(api, '', 'all', pageToFetch, 5000);

                        if (!didCancel) {
                            allOrganizations = allOrganizations.concat(response.items);

                            if (response.totalPages > pageToFetch) {
                                pageToFetch++;
                            } else {
                                pageToFetch = null;
                            }
                        }
                    }

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrganizations(allOrganizations);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrganizations(null);
                            setError('Unable to fetch fax2mail organizations.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        organizations,
        error
    ];
};