import { FormControl, InputLabel, Input, Typography, RadioGroup, FormControlLabel, Radio, Grid, FormLabel, FormHelperText, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../../api';
import { STATES } from '../../../../constants';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';
import { Direction, DIRECTIONS } from '../../api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string) => void;
    onAddressFieldChange: (fieldName: keyof FormAddressData, value: string) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    callerName: string;
    address: FormAddressData;
}

export interface FormAddressData {
    housePrefix: string;
    houseNumber: string;
    houseSuffix: string;
    preDirectional: Direction | ''
    streetName: string;
    streetSuffix: string;
    postDirectional: Direction | '';
    addressLine2: string;
    city: string;
    stateCode: string;
    zip: string;
    plusFour: string;
}

const E911Form = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, onFieldChange, onAddressFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <FormControl className={classes.formControl} fullWidth required error={!isValid('callerName')}>
                <InputLabel htmlFor="failoverUri">Caller Name</InputLabel>
                <Input
                    autoFocus
                    value={formValues.callerName || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('callerName', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="callerName"
                    type="text" />

                <ValidationMessage field="callerName" />
            </FormControl>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.housePrefix')}>
                        <InputLabel htmlFor="address.housePrefix">Number Prefix</InputLabel>

                        <Input
                            value={formValues.address.housePrefix || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('housePrefix', evt.target.value)}
                            id="address.housePrefix"
                            type="text" />

                        <ValidationMessage field="address.housePrefix" />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('address.houseNumber')}>
                        <InputLabel htmlFor="address.houseNumber">House Number</InputLabel>

                        <Input
                            value={formValues.address.houseNumber || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('houseNumber', evt.target.value)}
                            id="address.houseNumber"
                            type="text" />

                        <ValidationMessage field="address.houseNumber" />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.houseSuffix')}>
                        <InputLabel htmlFor="address.housePrefix">Number Suffix</InputLabel>

                        <Input
                            value={formValues.address.houseSuffix || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('houseSuffix', evt.target.value)}
                            id="address.houseSuffix"
                            type="text" />

                        <ValidationMessage field="address.houseSuffix" />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.preDirectional')}>
                        <InputLabel htmlFor="address.preDirectional">Pre Dir</InputLabel>

                        <Select
                            id="address.preDirectional"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.address.preDirectional || ''}
                            input={<Input name="address.preDirectional" id="address.preDirectional" />}
                            onChange={evt => onAddressFieldChange('preDirectional', evt.target.value)}>
                            <option value=""></option>
                            {DIRECTIONS.map(o => <option key={'preDirectional' + o} value={o}>{o}</option>)}
                        </Select>

                        <ValidationMessage field="address.preDirectional" />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('address.streetName')}>
                        <InputLabel htmlFor="address.preDirectional">Street Name</InputLabel>

                        <Input
                            value={formValues.address.streetName || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('streetName', evt.target.value)}
                            id="address.streetName"
                            type="text" />

                        <ValidationMessage field="address.streetName" />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.streetSuffix')}>
                        <InputLabel htmlFor="address.streetSuffix">Street Suffix</InputLabel>

                        <Input
                            value={formValues.address.streetSuffix || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('streetSuffix', evt.target.value)}
                            id="address.streetSuffix"
                            type="text" />

                        <ValidationMessage field="address.streetSuffix" />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.postDirectional')}>
                        <InputLabel htmlFor="address.postDirectional">Post Dir</InputLabel>

                        <Select
                            id="address.postDirectional"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.address.postDirectional || ''}
                            input={<Input name="address.postDirectional" id="address.postDirectional" />}
                            onChange={evt => onAddressFieldChange('postDirectional', evt.target.value)}>
                            <option value=""></option>
                            {DIRECTIONS.map(o => <option key={'postDirectional' + o} value={o}>{o}</option>)}
                        </Select>

                        <ValidationMessage field="address.postDirectional" />
                    </FormControl>
                </Grid>
            </Grid>

            <FormControl className={classes.formControl} fullWidth error={!isValid('address.addressLine2')}>
                <InputLabel htmlFor="address.addressLine2">Address Line 2</InputLabel>

                <Input
                    value={formValues.address.addressLine2 || ''}
                    disabled={isSubmitting}
                    onChange={evt => onAddressFieldChange('addressLine2', evt.target.value)}
                    id="address.addressLine2"
                    type="text" />

                <ValidationMessage field="address.addressLine2" />
            </FormControl>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('address.city')}>
                        <InputLabel htmlFor="address.city">City</InputLabel>

                        <Input
                            value={formValues.address.city || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('city', evt.target.value)}
                            id="address.city"
                            type="text" />

                        <ValidationMessage field="address.city" />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('address.stateCode')}>
                        <InputLabel htmlFor="address.stateCode">State</InputLabel>

                        <Select
                            id="address.stateCode"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.address.stateCode || ''}
                            input={<Input name="address.stateCode" id="address.stateCode" />}
                            onChange={evt => onAddressFieldChange('stateCode', evt.target.value)}>
                            {StateOptions()}
                        </Select>

                        <ValidationMessage field="address.stateCode" />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('address.zip')}>
                        <InputLabel htmlFor="address.zip">Zip Code</InputLabel>

                        <Input
                            value={formValues.address.zip || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('zip', evt.target.value)}
                            id="address.zip"
                            type="text" />

                        <ValidationMessage field="address.zip" />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('address.plusFour')}>
                        <InputLabel htmlFor="address.plusFour">Zip Plus 4</InputLabel>

                        <Input
                            value={formValues.address.plusFour || ''}
                            disabled={isSubmitting}
                            onChange={evt => onAddressFieldChange('plusFour', evt.target.value)}
                            id="address.plusFour"
                            type="text" />

                        <ValidationMessage field="address.plusFour" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value="" />];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};

export default E911Form;