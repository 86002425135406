import { ApiCaller } from '../../../api';

/*
 * GET /api/webex/customers
 *
 * Get all webex customers
 */

export interface WebexCustomer {
    id: string;
    orgId: string;
    name: string;
}

export const fetchWebexCustomers = async (api: ApiCaller): Promise<WebexCustomer[]> => {
    return await api<WebexCustomer[]>(`webex/customers`, 'GET');
};
