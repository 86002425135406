import { FormControl, FormHelperText, Grid, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../api';
import { STATES } from '../../constants';
import { useValidationHelpers } from '../../form-helpers';
import theme from '../../theme';
import { OrganizationType, TYPE_ADMINISTRATOR, TYPE_ENDCLIENT, TYPE_RESELLER } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    name: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    resellerId: number | null;
    type: OrganizationType;
    sapId: string;
}

interface Props {
    formValues: FormValues,
    isSubmitting: boolean;
    resellerOptions: any;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormValues, value: string | number | null) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

/**
 * Form for creating/updating an organization
 *
 * @param props
 * @constructor
 */
export const OrganizationForm = (props: Props) => {
    const { isSubmitting, onFieldChange, onFieldKeyDown, validationMessage, validationFieldMessages, formValues, resellerOptions } = props;
    const classes = useStyles();
    const { isValid, ValidationMessage } = useValidationHelpers(validationFieldMessages);

    return (
        <>
            {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('name')}>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            autoFocus
                            value={formValues.name}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('name', evt.target.value)}
                            onKeyDown={onFieldKeyDown}
                            id="name"
                            type="text" />
                        <ValidationMessage field="name" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('sapId')}>
                        <InputLabel htmlFor="sapId">SAP ID</InputLabel>
                        <Input
                            value={formValues.sapId}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('sapId', evt.target.value)}
                            onKeyDown={onFieldKeyDown}
                            id="sapId"
                            inputProps={{ maxLength: 10 }}
                            type="text" />

                        {isValid('sapId') && <FormHelperText>SAP ID must be 10 characters</FormHelperText>}

                        <ValidationMessage field="sapId" />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('resellerId')}>
                        <InputLabel htmlFor="resellerId">Reseller</InputLabel>
                        <Select
                            id="resellerId"
                            margin="none"
                            native={resellerOptions != null}
                            disabled={isSubmitting}
                            value={formValues.resellerId || ''}
                            input={<Input name="resellerId" id="resellerId" />}
                            onChange={evt => onFieldChange('resellerId', evt.target.value)}
                            onKeyDown={onFieldKeyDown}>
                            {resellerOptions != null
                                ? ResellerOptions(resellerOptions)
                                : <LinearProgress />
                            }
                        </Select>

                        <ValidationMessage field="resellerId" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('type')}>
                        <InputLabel htmlFor="type">Type</InputLabel>

                        {
                            // Don't allow changing the Type is Administrator
                            formValues.type === TYPE_ADMINISTRATOR
                                ? <Select
                                    id="type"
                                    native={true}
                                    margin="none"
                                    disabled={true}
                                    value={formValues.type}
                                    input={<Input name="type" id="type" />}>
                                    <option value={TYPE_ADMINISTRATOR}>Administrator</option>
                                </Select>
                                : <Select
                                    id="type"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.type}
                                    input={<Input name="type" id="type" />}
                                    onChange={evt => onFieldChange('type', evt.target.value)}
                                    onKeyDown={onFieldKeyDown}>
                                    <option value={TYPE_ENDCLIENT}>End Client</option>
                                    <option value={TYPE_RESELLER}>Reseller</option>
                                </Select>
                        }
                        <ValidationMessage field="type" />
                    </FormControl>
                </Grid>
            </Grid>

            <FormControl className={classes.formControl} fullWidth error={!isValid('address')}>
                <InputLabel htmlFor="address">Address</InputLabel>
                <Input
                    disabled={isSubmitting}
                    id="address"
                    value={formValues.address}
                    onChange={evt => onFieldChange('address', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    type="text" />
                <ValidationMessage field="address" />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth error={!isValid('address2')}>
                <InputLabel htmlFor="address2">Address 2</InputLabel>
                <Input
                    disabled={isSubmitting}
                    id="address2"
                    value={formValues.address2}
                    onChange={evt => onFieldChange('address2', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    type="text" />
                <ValidationMessage field="address2" />
            </FormControl>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('city')}>
                        <InputLabel htmlFor="city">City</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('city', evt.target.value)}
                            onKeyDown={onFieldKeyDown}
                            id="city"
                            value={formValues.city}
                            type="text" />
                        <ValidationMessage field="city" />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={4}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('state')}>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Select
                            id="state"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.state || ''}
                            input={<Input name="state" id="state" />}
                            onChange={evt => onFieldChange('state', evt.target.value)}
                            onKeyDown={onFieldKeyDown}>
                            {StateOptions()}
                        </Select>
                        <ValidationMessage field="state" />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('zip')}>
                        <InputLabel htmlFor="zip">Zip</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('zip', evt.target.value)}
                            onKeyDown={onFieldKeyDown}
                            id="zip"
                            value={formValues.zip}
                            type="text" />
                        <ValidationMessage field="zip" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

// Dropdown options for Reseller field
const ResellerOptions = (items: Array<{ id: number; name: string }>) => {
    let options = [<option key="" value=""></option>];

    for (let i = 0; i < items.length; i++) {
        let item = items[i];

        options.push(<option key={item.id} value={item.id}>{item.name}</option>)
    }

    return options;
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value=""></option>];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};