import { ApiCaller } from '../../../api';

export interface Team {
    id: string;
    name: string;
    description: string;
    dateCreated: string;
    dateUpdated: string;
}

/*
 * GET /api/dubber/:groupId/accounts/:accountId/teams
 *
 * Get team listing for dubber account
 */

export const fetchDubberTeams = async (api: ApiCaller, groupId: string, accountId: string): Promise<Team[]> => {
    return await api<Team[]>(`dubber/${groupId}/accounts/${accountId}/teams`, 'GET');
};

/*
 * GET /api/dubber/:groupId/accounts/:accountId/teams/:teamId
 *
 * Get team details
 */

export const fetchDubberTeam = async (api: ApiCaller, groupId: string, accountId: string, teamId: string): Promise<Team> => {
    return await api<Team>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}`, 'GET');
};

/*
 * POST /api/dubber/:groupId/accounts/:accountId/teams/
 *
 * Create team
 */

export interface TeamCreate {
    name: string;
    description: string;
}

export const createDubberTeam = async (api: ApiCaller, groupId: string, accountId: string, create: TeamCreate): Promise<Team> => {
    return await api<Team>(`dubber/${groupId}/accounts/${accountId}/teams`, 'POST', {
        ...create
    });
};

/*
 * PUT /api/dubber/:groupId/accounts/:accountId/teams/:teamId
 *
 * Update team
 */

export interface TeamUpdate {
    name: string;
    description: string;
}

export const updateDubberTeam = async (api: ApiCaller, groupId: string, accountId: string, teamId: string, update: TeamUpdate): Promise<Team> => {
    return await api<Team>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}`, 'PUT', {
        ...update
    });
};


/*
 * DELETE /api/dubber/:groupId/accounts/:accountId/teams/:teamId
 *
 * Delete team
 */

export const deleteDubberTeam = async (api: ApiCaller, groupId: string, accountId: string, teamId: string): Promise<{}> => {
    return await api<{}>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}`, 'DELETE');
};