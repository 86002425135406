import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../api';
import { State } from '../../store';
import { AdministratorRole } from '../administrators/api';
import { confirmMfaEmail, createSession, fetchMfaOptions, mfaAuthenticator, MfaOptions, requestMfaEmail, SessionStatus } from './api';
import { loginUrl } from './urls';
import history from '../../history';

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

interface SessionHook {
    token: string | null;
    expiresAt: string | null
    status: SessionStatus | null;
    roles: AdministratorRole[];
    administratorId: number | null;
}

export const useSession = () => {
    return useSelector<State, SessionHook>(state => ({
        token: state.auth.token,
        expiresAt: state.auth.expiresAt,
        status: state.auth.status,

        roles: state.auth.roles,
        administratorId: state.auth.administratorId
    }));
};

type CreateSessionFunc = (username: string, password: string) => void;

interface CreatedSession {
    token: string;
    expiresAt: string;
    status: SessionStatus;
    roles: AdministratorRole[];
    administratorId: number;
}

// Hook for creating a new session from a username and password
export const useCreateSession = (): TriggeredSubmitHookResponse<CreateSessionFunc, CreatedSession> => {
    interface Request {
        username: string;
        password: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [session, setSession] = useState<CreatedSession | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: CreateSessionFunc = (username: string, password: string) => {
        setRequest({
            username,
            password,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setSession(null);

                try {
                    const createdSession = await createSession(api, request.username, request.password);

                    if (!didCancel) {
                        setIsCreating(false);
                        setSession(createdSession);
                    }
                } catch (e) {
                    if (!didCancel) {
                        if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setSession(null);
                            setError('Unable to login.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        session,
        error,
        fieldErrors
    ];
};

// Hook for requesting available MFA options for a session
export const useFetchMfaOptions = (): TriggeredFetchHookResponse<() => void, MfaOptions> => {
    interface Request {
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [options, setOptions] = useState<MfaOptions | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = () => {
        setRequest({
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOptions(null);

                try {
                    const result = await fetchMfaOptions(api);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOptions(result);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOptions(null);
                            setError('Unable to fetch MFA options.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        options,
        error
    ];
};

// Hook for requesting using email for MFA
export const useRequestMfaEmail = (): TriggeredFetchHookResponse<() => void, boolean> => {
    interface Request {
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [requested, setRequested] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const create = () => {
        setRequest({
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setRequested(false);

                try {
                    await requestMfaEmail(api);

                    if (!didCancel) {
                        setIsCreating(false);
                        setRequested(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            setError(e.message);
                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setRequested(false);
                            setError('Unable to send email.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        requested,
        error
    ];
};

type ConfirmEmailFunc = (code: string) => void;

// Hook for confirming email for MFA
export const useConfirmMfaEmail = (): TriggeredSubmitHookResponse<ConfirmEmailFunc, boolean> => {
    interface Request {
        code: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: ConfirmEmailFunc = (code: string) => {
        setRequest({
            code,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsConfirming(true);
                setConfirmed(false);

                try {
                    await confirmMfaEmail(api, request.code);

                    if (!didCancel) {
                        setIsConfirming(false);
                        setConfirmed(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsConfirming(false);
                            setConfirmed(false);
                        } else {
                            setIsConfirming(false);
                            setConfirmed(false);
                            setError('Unable to confirm code.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isConfirming,
        confirmed,
        error,
        fieldErrors
    ];
};

type AuthenticatorFunc = (code: string) => void;

// Hook for completing MFA using Authenticator
export const useMfaAuthenticator = (): TriggeredSubmitHookResponse<AuthenticatorFunc, boolean> => {
    interface Request {
        code: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: AuthenticatorFunc = (code: string) => {
        setRequest({
            code,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsConfirming(true);
                setConfirmed(false);

                try {
                    await mfaAuthenticator(api, request.code);

                    if (!didCancel) {
                        setIsConfirming(false);
                        setConfirmed(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsConfirming(false);
                            setConfirmed(false);
                        } else {
                            setIsConfirming(false);
                            setConfirmed(false);
                            setError('Unable to confirm code.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isConfirming,
        confirmed,
        error,
        fieldErrors
    ];
};