import { ApiCaller, PagedResponse } from '../../../api';

export interface User {
    id: string;
    role: UserRole;
    username: string | null;
    firstName: string;
    lastName: string;
    confirmed: boolean;
    dateCreated: string;
    dateUpdated: string;
    mobileNumber: string | null;
    language: Language;
}

export type UserRole = 'Administrator' | 'StandardUser';
export type Language =
    'en-US'
    | 'en'
    | 'de'
    | 'el'
    | 'es'
    | 'fr'
    | 'it'
    | 'ja'
    | 'nl'
    | 'pl'
    | 'pt'
    | 'sv'
    | 'zh-CN'
    | 'zh-TW';

export const LANGUAGES: Language[] = [
    'en-US',
    'en',
    'de',
    'el',
    'es',
    'fr',
    'it',
    'ja',
    'nl',
    'pl',
    'pt',
    'sv',
    'zh-CN',
    'zh-TW'
];

/*
 * GET /api/dubber/:groupId/accounts/:accountId/users
 *
 * Get user listing for dubber account
 */

export const fetchDubberUsers = async (api: ApiCaller, groupId: string, accountId: string): Promise<User[]> => {
    return await api<User[]>(`dubber/${groupId}/accounts/${accountId}/users`, 'GET');
};

/*
 * GET /api/dubber/:groupId/accounts/:accountId/users/:userId
 *
 * Get user details
 */

export const fetchDubberUser = async (api: ApiCaller, groupId: string, accountId: string, userId: string): Promise<User> => {
    return await api<User>(`dubber/${groupId}/accounts/${accountId}/users/${userId}`, 'GET');
};

/*
 * POST /api/dubber/:groupId/accounts/:accountId/users/
 *
 * Create user
 */

export interface UserCreate {
    role: UserRole;
    email: string | null;
    firstName: string;
    lastName: string;
    mobileNumber: string | null;
    language: Language;
}

export const createDubberUser = async (api: ApiCaller, groupId: string, accountId: string, create: UserCreate): Promise<User> => {
    return await api<User>(`dubber/${groupId}/accounts/${accountId}/users`, 'POST', {
        ...create
    });
};

/*
 * PUT /api/dubber/:groupId/accounts/:accountId/users/:userId
 *
 * Update user
 */

export interface UserUpdate {
    firstName: string;
    lastName: string;
    mobileNumber: string | null;
    language: Language;
}

export const updateDubberUser = async (api: ApiCaller, groupId: string, accountId: string, userId: string, update: UserUpdate): Promise<User> => {
    return await api<User>(`dubber/${groupId}/accounts/${accountId}/users/${userId}`, 'PUT', {
        ...update
    });
};

/*
 * DELETE /api/dubber/:groupId/accounts/:accountId/users/:userId
 *
 * Delete user
 */

export const deleteDubberUser = async (api: ApiCaller, groupId: string, accountId: string, userId: string, removeRecordings: boolean = false): Promise<{}> => {
    return await api<{}>(`dubber/${groupId}/accounts/${accountId}/users/${userId}`, 'DELETE', {
        removeRecordings
    });
};