import { CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Routes from './Routes';

const useStyles = makeStyles({
    root: {
        display: 'flex'
    }
});

/**
 * Application root container
 */
export default function App() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Routes/>
        </div>
    );
};

