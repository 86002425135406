import { useCallback, useEffect, useState } from "react";
import { BadRequestError, FieldError, PagedResponse, ResponseError, useApi } from "../../../api";
import { createFax2MailStatement, CreateStatement, fetchFax2MailLatestStatements, fetchFax2MailStatementDownloadUrl, fetchFax2MailStatements, LatestStatement, Statement } from './api';
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { useDispatch } from "react-redux";
import { StatementFormat } from "../../fax2mail/api";
import { appWindowAddNotification } from "../../app-window/actions";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/*
 * Hook for getting fax2mail statements
 */

type FetchStatementsFunc = (organizationId: number, assignmentId: number, filters: Filters) => void;

interface FetchStatementsRequest {
    organizationId: number;
    assignmentId: number;
    billingYear?: number;
    billingMonth?: number;
    limit?: number;
    page?: number;
    ts: number;
}

interface Filters {
    billingYear?: number;
    billingMonth?: number;
    limit?: number;
    page?: number;
}

export const useFetchFax2MailStatements = (): TriggeredFetchHookResponse<FetchStatementsFunc, PagedResponse<Statement>> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchStatementsRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [statements, setStatements] = useState<PagedResponse<Statement> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchStatementsFunc = (organizationId: number, assignmentId: number, filters: Filters) => {
        setRequest({
            organizationId,
            assignmentId,
            ts: Date.now(),
            ...filters
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setStatements(null);

                try {
                    const statements = await fetchFax2MailStatements(api, request.organizationId, request.assignmentId, {
                        ...request
                    });

                    if (!didCancel) {
                        setIsFetching(false);
                        setStatements(statements);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setStatements(null);
                            setError('Unable to fetch fax2mail statements.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        statements,
        error
    ];
};

/*
 * Hook for fetching all statements for an assignment
 */

type FetchAllStatementsFunc = (organizationId: number, assignmentId: number) => void;

interface FetchAllStatementsRequest {
    organizationId: number;
    assignmentId: number;
    ts: number;
}

export const useFetchAllFax2MailStatements = (): TriggeredFetchHookResponse<FetchAllStatementsFunc, Statement[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchAllStatementsRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [statements, setStatements] = useState<Statement[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchAllStatementsFunc = (organizationId: number, assignmentId: number) => {
        setRequest({
            organizationId,
            assignmentId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setStatements(null);

                try {
                    let allStatements: Statement[] = [];
                    let pageToFetch: number | null = 1;

                    while (pageToFetch != null && !didCancel) {
                        const response = await fetchFax2MailStatements(api, request.organizationId, request.assignmentId, {
                            page: pageToFetch,
                            limit: 5000
                        });

                        if (!didCancel) {
                            allStatements = allStatements.concat(response.items);

                            if (response.totalPages > pageToFetch) {
                                pageToFetch++;
                            } else {
                                pageToFetch = null;
                            }
                        }
                    }

                    if (!didCancel) {
                        setIsFetching(false);
                        setStatements(allStatements);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setStatements(null);
                            setError('Unable to fetch fax2mail statements.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        statements,
        error
    ];
};

/*
 * Hook for getting latest fax2mail statements
 */

type FetchLatestStatementsFunc = (billingYear: number, billingMonth: number, limit?: number, page?: number) => void;

interface FetchLatestStatementsRequest {
    billingYear: number;
    billingMonth: number;
    limit?: number;
    page?: number;
    ts: number;
}

export const useFetchFax2MailLatestStatements = (): TriggeredFetchHookResponse<FetchLatestStatementsFunc, PagedResponse<LatestStatement>> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchLatestStatementsRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [statements, setStatements] = useState<PagedResponse<LatestStatement> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchLatestStatementsFunc = (billingYear: number, billingMonth: number, limit?: number, page?: number)  => {
        setRequest({
            billingYear,
            billingMonth,
            limit,
            page,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setStatements(null);

                try {
                    const statements = await fetchFax2MailLatestStatements(api, request.billingYear, request.billingMonth, request.page, request.limit);

                    if (!didCancel) {
                        setIsFetching(false);
                        setStatements(statements);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setStatements(null);
                            setError('Unable to fetch fax2mail statements.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        statements,
        error
    ];
};

/**
 * Hook for allowing downloads of statements
 */
export const useDownloadStatement = () => {
    let didCancel = false;
    const api = useApi();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, []);

    return useCallback(async (organizationId: number, assignmentId: number, statementId: number, format: StatementFormat) => {
        dispatch(appWindowAddNotification('Starting download', 'success'));

        try {
            const downloadUrl = await fetchFax2MailStatementDownloadUrl(api, organizationId, assignmentId, statementId, format);

            window.open(downloadUrl);
        } catch (e) {
            if (!didCancel) {
                dispatch(appWindowAddNotification('Download failed', 'error'));
            }
        }

    }, []);
};

/*
 * Hook for creating a fax2mail statement
 */

type CreateStatementsFunc = (form: CreateStatement) => void;

interface CreateStatementsRequest {
    form: CreateStatement;
    ts: number;
}

export const useCreateFax2MailStatement = (): TriggeredSubmitHookResponse<CreateStatementsFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<CreateStatementsRequest | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const submit: CreateStatementsFunc = (form: CreateStatement) => {
        setRequest({
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsSubmitting(true);
                setIsComplete(false);

                try {
                    await createFax2MailStatement(api, request.form);

                    if (!didCancel) {
                        setIsSubmitting(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            setIsSubmitting(false);
                            setIsComplete(false);
                            setError(e.fields && (e.fields.length > 0) ? null : e.message);
                            setFieldErrors(e.fields && (e.fields.length > 0) ? e.fields : []);
                        } else {
                            setIsSubmitting(false);
                            setIsComplete(false);
                            setError('Unable to create fax2mail statement.');
                            setFieldErrors([]);
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        submit,
        isSubmitting,
        isComplete,
        error,
        fieldErrors
    ];
};