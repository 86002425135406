import { Button, Grid, LinearProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchAllFax2MailOrganizations } from '../organizations/hooks';
import { Fax2MailPolicies } from '../policies';
import { SeatType, SEAT_BASIC } from './api';
import DefaultNotice from './DefaultNotice';
import Fax2MailOrganizationForm from './Fax2MailOrganizationForm';
import GenericLocation from './GenericLocation';
import { useFetchFax2MailSeats, useUpdateFax2MailSeats } from './hooks';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    locationHeader: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
}));

export interface SeatForm {
    id?: number;
    idx: number;
    name: string;
    type: SeatType;
    remove: boolean;
}

export type HandleChange = (idx: number, field: string, value: string) => void;
export type HandleAddSeat = () => void;

const SeatUpdatePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useNav('fax2mail', 'seats');

    const [fetchOrganizations, isFetchingOrganizations, allOrganizations, fetchOrganizationsError] = useFetchAllFax2MailOrganizations();
    const [fetchSeats, isFetchingSeats, seats, fetchSeatsError] = useFetchFax2MailSeats();
    const [submit, isSubmitting, isComplete, submitError] = useUpdateFax2MailSeats();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);
    const [formValues, setFormValues] = useState<SeatForm[] | null>(null);
    const [defaultNotice, setDefaultNotice] = useState(false);

    // Retrieve all organizations on first load
    useEffect(() => {
        fetchOrganizations();
    }, []);

    useProgressEffects(
        isFetchingOrganizations || isSubmitting,
        fetchOrganizationsError || fetchSeatsError || submitError
    );

    // Once organizations are loaded, filter to just active assignments
    const activeOrganizations = useMemo(() => {
        if (allOrganizations != null) {
            return allOrganizations.filter(o => o.isActive);
        }

        return null;
    }, [allOrganizations]);

    const selectedOrganization = useMemo(() => {
        if (selectedOrganizationId !== null && selectedAssignmentId !== null) {
            return (activeOrganizations || []).find(o => o.assignmentId == selectedAssignmentId);
        } else {
            return null;
        }
    }, [selectedOrganizationId, selectedAssignmentId]);

    // Once organization is selected, fetch current seats
    useEffect(() => {
        loadAll();
    }, [selectedAssignmentId]);

    // Used to reload page
    const loadAll = () => {
        if (selectedOrganization) {
            fetchSeats(selectedOrganization.id, selectedOrganization.assignmentId);
        }
    }

    // Once seats are loaded, update the form values with those values
    useEffect(() => {
        if (selectedOrganization) {
            if (seats !== null) {
                setDefaultNotice(seats.length === 0);

                if (seats.length === 0) {
                    setFormValues([
                        {
                            idx: 0,
                            name: "",
                            type: SEAT_BASIC,
                            remove: false
                        }
                    ]);
                } else {
                    setFormValues(seats.map((s, idx) => ({
                        ...s,
                        remove: false,
                        idx
                    })));
                }
            }
        }
    }, [seats]);

    // Event handler for input fields
    const handleFieldChange: HandleChange = (idx: number, field: string, value: string) => {
        if (formValues !== null) {
            switch (field) {
                case 'name':
                    formValues[idx].name = value;
                    break;
                case 'type':
                    formValues[idx].type = value as SeatType;
                    break;
                case 'remove':
                    formValues[idx].remove = !formValues[idx].remove;
                    break;
            }

            setFormValues([
                ...formValues
            ]);
        }
    };

    // Handle Add button which adds a seat line to be filled out
    const handleAddSeat: HandleAddSeat = () => {
        setFormValues([
            ...(formValues || []),
            {
                idx: (formValues || []).length,
                name: "",
                type: SEAT_BASIC,
                remove: false
            }
        ]);
    };

    // Handle the Update Seats button
    const handleSubmit = () => {
        if (selectedOrganizationId && selectedAssignmentId && formValues) {
            submit(selectedOrganizationId, selectedAssignmentId, {
                seats: formValues.filter(f => !f.remove && f.name.trim().length > 0)
            });
        }
    };

    // Reload page after submit completes
    useEffect(() => {
        if (isComplete) {
            loadAll();
            dispatch(appWindowAddNotification('Updated seats', 'success'));
        }
    }, [isComplete]);

    const showForm =
        !isFetchingSeats
        && formValues;

    return (
        <div className={classes.root}>
            <PageHeader text="Fax2Mail" subtext="Seats" />

            {!isFetchingOrganizations && activeOrganizations !== null &&
                <Paper className={classes.paper}>
                    <Fax2MailOrganizationForm
                        organizations={activeOrganizations}
                        selectedOrganizationId={selectedOrganizationId}
                        selectedAssignmentId={selectedAssignmentId}
                        onSelect={(orgId, asId) => {
                            setSelectedOrganizationId(orgId);
                            setSelectedAssignmentId(asId);
                        }} />
                </Paper>
            }

            {selectedOrganization &&
                <>
                    {isFetchingSeats && <LinearProgress variant="indeterminate" color="primary" />}

                    {showForm &&
                        <>
                            {defaultNotice && <DefaultNotice />}

                            <GenericLocation
                                seats={formValues || []}
                                isSubmitting={isSubmitting}
                                onFieldChange={handleFieldChange}
                                onAddSeat={handleAddSeat}
                            />

                            <Grid container justifyContent="flex-end">
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    disabled={false}
                                    onClick={() => handleSubmit()}>Update Seats</Button>
                            </Grid>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(SeatUpdatePage, Fax2MailPolicies.CanManageSeats);