import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import { Service, serviceName, SERVICE_HOSTED_PBX } from '../../../services';
import theme from '../../../theme';
import { GroupItem, HostedPbxOrganization } from '../../hosted-pbx/api';
import { useFetchAllHpbxOrganizations } from '../../hosted-pbx/hooks';
import { UnidentifiedDubPoint, User } from '../api';
import { productLabel } from '../helpers';
import { useFetchDubberDubPointSeatOptions } from './hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    option: {
        display: 'block'
    },
    optionSubtext: {
        fontSize: '0.8rem'
    },
    ext: {
        display: 'inline-block',
        width: '100px'
    }
}));

export interface FormValues {
    user: string;
    type: string;
    product: string;

    service: Service | '';
    hpbx: HostedPbxFormValues;

    retainUnidentifiedRecordings: boolean;
}

export interface HostedPbxFormValues {
    organization: HostedPbxOrganization | null;
    userId: string;
}

interface Props {
    groupId: string;
    accountId: string;
    serviceOptions: Service[];
    userOptions: User[];
    productOptions: string[];
    unidentifiedOptions: UnidentifiedDubPoint[];
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean | HostedPbxFormValues) => void;
    onEnter: () => void;
    onLoading: (loading: boolean) => void;
}

const DubPointCreateForm = (props: Props) => {
    const classes = useStyles();
    const { groupId, accountId, formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, onEnter, onLoading, userOptions, productOptions, unidentifiedOptions, serviceOptions } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const sortedUserOptions = useMemo(() => {
        return userOptions.sort((a, b) => {
            return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
        });
    }, [userOptions]);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('user')}>
                        <InputLabel htmlFor="userId">Dubber User</InputLabel>
                        <Select
                            id="user"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.user}
                            input={<Input name="user" id="user" />}
                            onChange={evt => onUpdate('user', evt.target.value)}>
                            <option></option>
                            {sortedUserOptions.map(user => <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>)}
                        </Select>
                        <ValidationMessage field="user" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('type')}>
                        <InputLabel htmlFor="type">Type</InputLabel>
                        <Select
                            id="userId"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.type}
                            input={<Input name="type" id="type" />}
                            onChange={evt => onUpdate('type', evt.target.value)}>
                            <option value="Recorder">Recorder</option>
                            <option value="Api">Api</option>
                        </Select>
                        <ValidationMessage field="type" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('service')}>
                        <InputLabel htmlFor="type">Service</InputLabel>
                        <Select
                            id="service"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.service}
                            input={<Input name="service" id="service" />}
                            onChange={evt => onUpdate('service', evt.target.value)}>
                            <option></option>
                            {serviceOptions.map(s => <option key={s} value={s}>{serviceName(s)}</option>)}
                        </Select>
                        <ValidationMessage field="service" />
                    </FormControl>
                </Grid>

                {formValues.service !== '' &&
                    <>

                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('product')}>
                                <InputLabel htmlFor="type">Product</InputLabel>
                                <Select
                                    id="product"
                                    margin="none"
                                    native={true}
                                    disabled={isSubmitting}
                                    value={formValues.product}
                                    input={<Input name="product" id="product" />}
                                    onChange={evt => onUpdate('product', evt.target.value)}>
                                    <option></option>
                                    {productOptions.map(prod => <option key={prod} value={prod}>{productLabel(prod)}</option>)}
                                </Select>
                                <ValidationMessage field="product" />
                            </FormControl>
                        </Grid>


                        {formValues.type === 'Recorder' &&
                            <>
                                {formValues.service === SERVICE_HOSTED_PBX &&
                                    <>
                                        <DubPointHostedPbxForm
                                            dubberGroupId={groupId}
                                            dubberAccountId={accountId}
                                            formValues={formValues.hpbx}
                                            isSubmitting={isSubmitting}
                                            fieldErrorMessages={fieldErrorMessages}
                                            onUpdate={onUpdate}
                                            onEnter={onEnter}
                                            onLoading={onLoading} />
                                    </>
                                }

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="retainUnidentifiedRecordings"
                                                checked={formValues.retainUnidentifiedRecordings}
                                                disabled={isSubmitting}
                                                onChange={evt => onUpdate('retainUnidentifiedRecordings', !formValues.retainUnidentifiedRecordings)}
                                                value="1"
                                                color="primary"
                                            />
                                        }
                                        label="Retain Unidentified Recordings" />
                                </Grid>
                            </>
                        }
                    </>
                }
            </Grid>
        </>
    );
};

interface HostedPbxProps {
    dubberGroupId: string;
    dubberAccountId: string;
    formValues: HostedPbxFormValues;
    isSubmitting: boolean;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean | HostedPbxFormValues) => void;
    onEnter: () => void;
    onLoading: (loading: boolean) => void;
}

const DubPointHostedPbxForm = (props: HostedPbxProps) => {
    const classes = useStyles();
    const { dubberGroupId, dubberAccountId, formValues, isSubmitting, fieldErrorMessages, onUpdate, onEnter, onLoading } = props;

    const [isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllHpbxOrganizations('active');
    const [fetchSeats, isFetchingSeats, seats, fetchSeatsError] = useFetchDubberDubPointSeatOptions();
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    useEffect(() => {
        if (formValues.organization) {
            fetchSeats(dubberGroupId, dubberAccountId, formValues.organization.broadworksGroup, formValues.organization.platform);
        }

    }, [formValues.organization]);

    useEffect(() => {
        onLoading(isFetchingOrganizations || isFetchingSeats);
    }, [isFetchingOrganizations, isFetchingSeats]);

    const sortedSeatOptions = useMemo(() => {
        // Ensure an org is selected and seats are loaded
        if (formValues.organization && seats) {
            return seats.sort((a, b) => {
                return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
            });
        } else {
            return [];
        }
    }, [formValues, seats]);

    const anyErrorMessage = fetchOrganizationsError || fetchSeatsError;

    return (
        <>
            {anyErrorMessage && <Typography variant="body1" color="error">{anyErrorMessage}</Typography>}

            <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth required error={!isValid('organizationId')}>
                    <InputLabel htmlFor="organizationId">Organization</InputLabel>
                    <Select
                        id="organizationId"
                        margin="none"
                        native={true}
                        disabled={isSubmitting}
                        value={formValues.organization?.id}
                        input={<Input name="hostedPbxOrganizationId" id="hostedPbxOrganizationId" />}
                        onChange={evt => {
                            var organization = null;

                            if (evt.target.value) {
                                organization = (organizations || []).find(o => String(o.id) === evt.target.value) || null;
                            }

                            onUpdate('hpbx', {
                                ...formValues,
                                organization: organization,
                                userId: ''
                            })
                        }}>
                        <option></option>
                        {(organizations || []).map(o => <option value={o.id} key={o.id}>{o.name} ({o.broadworksGroup})</option>)}
                    </Select>
                    <ValidationMessage field="hostedPbxOrganizationId" />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                {isFetchingSeats
                    ? <LinearProgress color="primary" />
                    :
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('userId')}>
                        <InputLabel htmlFor="type">BroadWorks Seat</InputLabel>
                        <Select
                            id="userId"
                            margin="none"
                            native={false}
                            disabled={isSubmitting}
                            value={formValues.userId}
                            input={<Input name="userId" id="userId" />}
                            onChange={evt => onUpdate('hpbx', {
                                ...formValues,
                                userId: evt.target.value
                            })}>
                            <MenuItem></MenuItem>
                            {(sortedSeatOptions || []).map(seat => <MenuItem value={seat.id} key={seat.id} disabled={!seat.available}>
                                <div className={classes.option}>
                                    <div>{seat.firstName} {seat.lastName}</div>
                                    <div className={classes.optionSubtext}>
                                        {seat.id}
                                    </div>
                                    <div className={classes.optionSubtext}>
                                        <div className={classes.ext}><em>Ext:</em> {seat.extension}</div>
                                        <div className={classes.ext}><em>Pack:</em> {seat.servicePack}</div>
                                    </div>
                                </div>
                            </MenuItem>)}
                        </Select>

                        <ValidationMessage field="broadworksUserId" />
                    </FormControl>
                }
            </Grid>
        </>
    );
}

export default DubPointCreateForm;