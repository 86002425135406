import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { ReactNode } from 'react';
import theme from '../theme';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    flex: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    text: string;
    subtext?: string;
    children?: ReactNode;
}

/**
 * Common page header. Displays H2 text and can contain additional elements such as buttons
 *
 * @param props
 * @constructor
 */
const PageHeader = (props: Props) => {
    const classes = useStyles();
    const {text, subtext, children} = props;

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <Typography variant="h2">{text}</Typography>
                    {subtext && <Typography variant="h3">{subtext}</Typography>}
                </Grid>

                <Grid item>
                    {children}
                </Grid>
            </Grid>
            <Divider/>
        </div>

    );
};

export default PageHeader;