/*
 * GET /api/webex/redsky/{assignmentId}
 *
 * Get report of current state of RedSky setup for a customer
 */

import { ApiCaller } from "../../../api";

export interface RedSkyReportLocation {
    id: string;
    name: string;
    additionalName: string | null;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    postalCode: string;
}

export interface RedSkyReportDevice {
    id: string;
    mac: string;
    type: string;
    owner: string;
    location: RedSkyReportLocation;
    emergencyLocation: RedSkyReportLocation;
}

export interface RedSkyReport {
    devices: RedSkyReportDevice[];
    webexLocations: RedSkyReportLocation[];
    redSkyLocations: RedSkyReportLocation[];
}

export const fetchRedSkyReport = async (api: ApiCaller, assignmentId: number): Promise<RedSkyReport> => {
    return await api<RedSkyReport>(`webex/redsky/${assignmentId}`);
};

export interface AssignmentEntry {
    macAddress: string;
    redSkyLocationId: string;
}

export interface AssignmentForm {
    changes: AssignmentEntry[];
}

export interface AssignmentResults {
    assigned: string[];
    failures: {
        macAddress: string;
        message: string;
    }[];
}

export const assignRedSkyMacs = async (api: ApiCaller, assignmentId: number, form: AssignmentForm): Promise<AssignmentResults> => {
    return await api<AssignmentResults>(`webex/redsky/${assignmentId}`, 'POST', {
        ...form
    });
};