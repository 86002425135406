import { Typography } from '@mui/material';
import * as React from 'react';
import { AdministratorRole } from '../administrators/api';
import { AdministratorPolicies, AdministratorPolicyRoles } from '../administrators/policies';
import { BandwidthPolicies, BandwidthPolicyRoles } from '../bandwidth/policies';
import { DirectRoutingPolicies, DirectRoutingPolicyRoles } from '../direct-routing/policies';
import { DubberPolicies, DubberPolicyRoles } from '../dubber/policies';
import { Fax2MailPolicies, Fax2MailPolicyRoles } from '../fax2mail/policies';
import { HostedPbxPolicies, HostedPbxPolicyRoles } from '../hosted-pbx/policies';
import { OrganizationPolicies, OrganizationPolicyRoles } from '../organizations/policies';
import { ReportingPolicies, ReportingPolicyRoles } from '../reporting/policies';
import { SipTrunkingPolicies, SipTrunkingPolicyRoles } from '../sip-trunking/policies';
import { UserPolicies, UserPolicyRoles } from '../users/policies';
import { WebexPolicies, WebexPolicyRoles } from '../webex/policies';
import { useSession } from './hooks';

export type Policies =
    AdministratorPolicies
    | BandwidthPolicies
    | DirectRoutingPolicies
    | DubberPolicies
    | Fax2MailPolicies
    | HostedPbxPolicies
    | OrganizationPolicies
    | ReportingPolicies
    | UserPolicies
    | SipTrunkingPolicies
    | WebexPolicies;

const AllPolicyRoles = {
    ...AdministratorPolicyRoles,
    ...BandwidthPolicyRoles,
    ...DirectRoutingPolicyRoles,
    ...DubberPolicyRoles,
    ...Fax2MailPolicyRoles,
    ...HostedPbxPolicyRoles,
    ...OrganizationPolicyRoles,
    ...ReportingPolicyRoles,
    ...UserPolicyRoles,
    ...SipTrunkingPolicyRoles,
    ...WebexPolicyRoles
};

// Determines if the given policy is satisfied by the given list of roles
export const authorizedFor = (policy: Policies, userRoles: AdministratorRole[]): boolean => {
    const requiredRoles = AllPolicyRoles[policy];

    for (let i = 0; i < userRoles.length; i++) {
        if (requiredRoles.indexOf(userRoles[i]) > -1) {
            return true;
        }
    }

    return false;
};

// Wraps a component with a policy check
// Displays an Access Denied message if policy fails
export const withPolicyRestriction = (Component: React.ComponentType<any>, policy: Policies): React.ComponentType<any> => {
    return (props: any) => {
        const session = useSession();

        return (
            authorizedFor(policy, session.roles)
                ? <Component {...props}/>
                : <>
                    <Typography variant="h6">Access Denied</Typography>
                    <Typography variant="body1">You do not have permission to access this page.</Typography>
                  </>
        );
    };
};