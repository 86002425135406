import { useEffect, useState } from 'react';
import { ResponseError, useApi } from '../../api';
import history from '../../history';
import { loginUrl } from '../auth/urls';
import { Account, fetchSipTrunkingBandwidthAccounts, fetchSipTrunkingBandwidthSubAccount, fetchSipTrunkingBandwidthSubAccounts, SubAccount, SubAccountDetails } from './api';

export * from './assignment/hooks';
export * from './organizations/hooks';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/**
 * Hook for fetching all accounts in Bandwidth used for SIP Trunking
 */

type FetchAccountsFunc = () => void;

export const useFetchSipTrunkingBandwidthAccounts = (): TriggeredFetchHookResponse<FetchAccountsFunc, Account[]> => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [accounts, setAccounts] = useState<Account[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchAccountsFunc = () => {
        setRequestTs(Date.now());
    };

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);
            setAccounts([]);

            try {
                const response = await fetchSipTrunkingBandwidthAccounts(api);
                if (!didCancel) {
                    setAccounts(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setAccounts([]);
                        setError('Unable to retrieve accounts.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [requestTs]);

    return [
        fetch,
        isFetching,
        accounts,
        error
    ];
};


/**
 * Hook for fetching all subaccounts in Bandwidth under the SIP Trunking account
 */

type FetchSubAccountsFunc = () => void;

export const useFetchSipTrunkingBandwidthSubAccounts = (): TriggeredFetchHookResponse<FetchSubAccountsFunc, SubAccount[]> => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [accounts, setAccounts] = useState<SubAccount[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchSubAccountsFunc = () => {
        setRequestTs(Date.now());
    };

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);
            setAccounts([]);

            try {
                const response = await fetchSipTrunkingBandwidthSubAccounts(api);
                if (!didCancel) {
                    setAccounts(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setAccounts([]);
                        setError('Unable to retrieve sub-accounts.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [requestTs]);

    return [
        fetch,
        isFetching,
        accounts,
        error
    ];
};

/**
 * Hook for fetching all sub-account details in Bandwidth under the SIP Trunking account
 */

type FetchSubAccountFunc = (accountId: string, peerId: string) => void;

interface FetchSubAccountRequest {
    accountId: string;
    peerId: string;
    ts: number;
}

type FetchAccountResponse = {
    fetch: FetchSubAccountFunc,
    isFetching: boolean,
    account: SubAccountDetails | null,
    error: string | null,
    errorCode: number| null
};

export const useFetchSipTrunkingBandwidthSubAccount = (): FetchAccountResponse => {
    const api = useApi();
    const [request, setRequest] = useState<FetchSubAccountRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [account, setAccount] = useState<SubAccountDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    const fetch: FetchSubAccountFunc = (accountId: string, peerId: string) => {
        setRequest({
            accountId,
            peerId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setErrorCode(null);
                setError(null);
                setAccount(null);

                try {
                    const response = await fetchSipTrunkingBandwidthSubAccount(api, request.accountId, request.peerId);
                    if (!didCancel) {
                        setAccount(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof ResponseError) {
                            if (e.code) {
                                setErrorCode(e.code);
                            }
                            setIsFetching(false);
                            setAccount(null);
                            setError('Unable to retrieve sub-account.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        fetch,
        isFetching,
        account,
        error,
        errorCode
    };
};