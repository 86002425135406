import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    Typography
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../api';
import { useValidationHelpers } from '../../form-helpers';
import theme from '../../theme';
import { OrganizationType } from '../organizations/api';
import { UserRole, USER_MANAGER } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRole[];
    setPassword: boolean;
    password?: string;
    confirmPassword?: string;
}

interface RoleOption {
    value: UserRole;
    label: string;
}

const roleOptions: RoleOption[] = [
    {
        value: USER_MANAGER,
        label: 'User Manager'
    }
];

interface Props {
    organizationType: OrganizationType;
    formValues: FormValues,
    requirePassword?: boolean,
    isSubmitting: boolean;
    validationMessage: string | null;
    fieldValidationMessages: FieldError[];
    onFieldChange: (evt: React.ChangeEvent<HTMLSelectElement|HTMLInputElement|HTMLTextAreaElement>) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

const UserForm = (props: Props) => {
    const classes = useStyles();
    const {
        isSubmitting,
        onFieldChange,
        onFieldKeyDown,
        validationMessage,
        fieldValidationMessages,
        formValues
    } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldValidationMessages);

    const requirePassword = props.requirePassword || false;

    return (
        <>
            {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('firstName')}>
                        <InputLabel htmlFor="firstName">First Name</InputLabel>
                        <Input
                            autoFocus
                            disabled={isSubmitting}
                            id="firstName"
                            value={formValues.firstName}
                            onChange={onFieldChange}
                            onKeyDown={onFieldKeyDown}
                            type="text"/>
                        <ValidationMessage field="firstName"/>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('lastName')}>
                        <InputLabel htmlFor="lastName">Last Name</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="lastName"
                            value={formValues.lastName}
                            onChange={onFieldChange}
                            onKeyDown={onFieldKeyDown}
                            type="text"/>
                        <ValidationMessage field="lastName"/>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('email')}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="email"
                            value={formValues.email}
                            onChange={onFieldChange}
                            onKeyDown={onFieldKeyDown}
                            type="text"/>
                        <ValidationMessage field="email"/>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('roles')}>
                        <FormLabel>Roles</FormLabel>
                        <FormGroup>
                            {roleOptions.map(option => {
                                return (
                                    <FormControlLabel
                                        key={option.value}
                                        control={
                                            <Checkbox
                                                name="roles"
                                                checked={formValues.roles.indexOf(option.value) > -1}
                                                disabled={isSubmitting}
                                                onChange={onFieldChange}
                                                value={option.value}/>
                                        }
                                        label={option.label}/>
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </Grid>

                {!requirePassword &&
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="setPassword"
                                    checked={formValues.setPassword}
                                    disabled={isSubmitting}
                                    onChange={onFieldChange}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Change Password?"/>
                    </Grid>
                }
            </Grid>

            <Collapse in={formValues.setPassword || requirePassword}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth required error={!isValid('password')}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                disabled={isSubmitting}
                                id="password"
                                value={formValues.password}
                                onChange={onFieldChange}
                                onKeyDown={onFieldKeyDown}
                                type="password"/>
                            <ValidationMessage field="password"/>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth required error={!isValid('confirmPassword')}>
                            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                            <Input
                                disabled={isSubmitting}
                                id="confirmPassword"
                                value={formValues.confirmPassword}
                                onChange={onFieldChange}
                                onKeyDown={onFieldKeyDown}
                                type="password"/>
                            <ValidationMessage field="confirmPassword"/>
                        </FormControl>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default UserForm;