import { ResponseError, useApi } from '../../api';
import { useEffect, useState } from 'react';
import history from '../../history';
import { loginUrl } from '../auth/urls';
import { Administrator, fetchAdministrator, unlockAdministrator } from './api';

type FetchAdministrator = [
    (id: number) => void,
    boolean,
    Administrator | null,
    string | null,
];

/**
 * Hook for retrieving administrator details from server
 * @param id
 */
export const useFetchAdministrator = (): FetchAdministrator => {
    interface Request {
        id: number;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [administrator, setAdministrator] = useState<Administrator | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const fetch = (id: number) => {
        setRequest({
            id,
            ts: Date.now()
        })
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchAdministrator(api, request.id);
                    if (!didCancel) {
                        setAdministrator(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setErrorMessage('Unable to retrieve administrator.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        administrator,
        errorMessage
    ];
};

type UnlockAdministrator = [
    (id: number) => void,
    boolean,
    boolean,
    string | null,
];

// Hook for unlocking an administrator account
export const useUnlockAdministrator = (): UnlockAdministrator => {
    interface Request {
        id: number;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isUnlocking, setIsUnlocking] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const unlock = (id: number) => {
        setRequest({
            id,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUnlocking(true);
                setIsComplete(false);

                try {
                    await unlockAdministrator(api, request.id);

                    if (!didCancel) {
                        setIsUnlocking(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsUnlocking(false);
                            setIsComplete(false);
                            setError('Unable to unlock administrator');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        unlock,
        isUnlocking,
        isComplete,
        error
    ];
};