import { FormControl, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { BandwidthSubAccount } from '../bandwidth-subaccounts/api';
import { WebexCustomer } from '../customers/api';
import { DubberAccount } from '../dubber-accounts/api';
import { webexIdToUuid } from '../helpers';
import { UpdateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    webexCustomerOptions: WebexCustomer[];
    bandwidthSubAccountOptions: BandwidthSubAccount[];
    dubberAccountOptions: DubberAccount[];
    formValues: UpdateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: UpdateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: UpdateAssignment): boolean => {
    return form.webexCustomerId !== ''
        && form.bandwidthSubAccount !== ''
        && form.dubberAccount !== '';
};

/**
 * Webex update assignment form
 * @param props
 * @constructor
 */
const UpdateAssignmentForm = (props: Props) => {
    const { webexCustomerOptions, bandwidthSubAccountOptions, dubberAccountOptions, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const displayError = errorMessage;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            <FormControl className={classes.formControl} fullWidth required error={!isValid('webexCustomerUuid')}>
                <InputLabel htmlFor="webexCustomerUuid">Webex Organization</InputLabel>

                <Select
                    id="webexCustomerUuid"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.webexCustomerId || ''}
                    onChange={evt => setFormValues({ ...formValues, webexCustomerId: evt.target.value })}
                    input={<Input name="webexCustomerUuid" id="webexCustomerUuid" />}>

                    <option key="" value="" />

                    {webexCustomerOptions.map(grp => <option key={grp.id}
                        value={grp.id}>{grp.name} ({webexIdToUuid(grp.orgId)})</option>)}
                </Select>

                <ValidationMessage field="webexCustomerUuid" />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthSubAccount')}>
                <InputLabel htmlFor="bandwidthSubAccount">Bandwidth Sub-Account</InputLabel>

                <Select
                    id="bandwidthSubAccount"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.bandwidthSubAccount || ''}
                    onChange={evt => setFormValues({ ...formValues, bandwidthSubAccount: evt.target.value })}
                    input={<Input name="bandwidthSubAccount" id="bandwidthSubAccount" />}>

                    <option key="" value="" />

                    {bandwidthSubAccountOptions.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                </Select>

                <ValidationMessage field="bandwidthSubAccount" />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth error={!isValid('dubberAccount')}>
                <InputLabel htmlFor="dubberAccount" shrink={true}>Dubber Account</InputLabel>

                <Select
                    id="dubberAccount"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.dubberAccount}
                    onChange={evt => setFormValues({ ...formValues, dubberAccount: evt.target.value })}
                    input={<Input name="dubberAccount" id="dubberAccount" />}>

                    <option key="" value="" />

                    {dubberAccountOptions.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                </Select>

                <ValidationMessage field="dubberAccount" />
            </FormControl>
        </>
    );
};

export default UpdateAssignmentForm;