import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/direct-routing/organizations
 *
 * Get all organizations with Direct Routing service assigned
 */

export interface Organization {
    id: number;
    assignmentId: number;
    iPilotAccountId: number;
    name: string;
    isActive: boolean;
    startedAt: string;
    cancelledAt: string;
}

export type Status = 'all' | 'active' | 'cancelled';

export const fetchDirectRoutingOrganizations = async (api: ApiCaller, search?: string, status: Status = 'active', page: number = 1, limit?: number): Promise<PagedResponse<Organization>> => {
    return await api<PagedResponse<Organization>>(`direct-routing/organizations`, 'GET', {
        search,
        status,
        page,
        limit
    });
};