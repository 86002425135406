import { ApiCaller } from '../../../../api';
import { OrderBase, OrderHistory, OrderNote } from '../api';

export interface TnOptionOrder extends OrderBase {
    type: 'TnOption';
    lastModifiedDate: string;
    groups: TnOptionOrderGroup[];
    errors: TnOptionOrderError[];
    history: OrderHistory[];
}

export interface TnOptionOrderGroup {
    numbers: string[];
    forwardedNumber: string | null | 'unchanged' | 'systemDefault';
    customUser: string | null | 'unchanged' | 'systemDefault';
    portOutPasscode: string | null | 'unchanged' | 'systemDefault';
    callingNameDisplayed: 'on' | 'off' | 'unchanged' | 'systemDefault';
    nnid: string | null | 'unchanged' | 'systemDefault';
    failoverUri: string | null | 'unchanged' | 'systemDefault';
    sms: null | 'on' | 'off' | 'unchanged'
}

export interface TnOptionOrderError {
    code: string;
    description: string;
    number: string;
}

/**
 * GET /bandwidth/{accountId}/orders/tn-options
 * 
 * Get all TN Option Orders for an account.
 * A number can be specified to get the orders that include that number
 * 
 * @param api
 * @param accountId
 * @param number
 */
export const fetchBandwidthTnOptionOrders = async (api: ApiCaller, accountId: string, number?: string): Promise<TnOptionOrder[]> => {
    return await api<TnOptionOrder[]>(`bandwidth/${accountId}/orders/tn-options`, 'GET', {
        number
    });
};

/**
 * GET /bandwidth/{accountId}/orders/tn-options/{orderId}
 * 
 * Get a specific TN Options Order
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthTnOptionOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<TnOptionOrder> => {
    return await api<TnOptionOrder>(`bandwidth/${accountId}/orders/tn-options/${orderId}`, 'GET');
};

export interface CreateBandwidthGeneralTnOptionOrderForm {
    numbers: string[];
    callingNameDisplay: null | 'on' | 'off' | 'unchanged' | 'systemDefault';
    nnid: string | null | 'unchanged' | 'systemDefault';
    failoverUri: string | null | 'unchanged' | 'systemDefault';
}

/**
 * POST /bandwidth/{accountId}/orders/tn-options/general
 * 
 * Create a new TN Option Order for changing general settings
 * 
 * @param api
 * @param form
 */
export const createBandwidthGeneralTnOptionOrder = async (api: ApiCaller, accountId: string, form: CreateBandwidthGeneralTnOptionOrderForm): Promise<TnOptionOrder> => {
    return await api<TnOptionOrder>(`bandwidth/${accountId}/orders/tn-options/general`, 'POST', {
        ...form
    });
};

export interface CreateBandwidthRoutingTnOptionOrderForm {
    numbers: string[];
    forwardedNumber: string | null | 'unchanged' | 'systemDefault';
    customUser: string | null | 'unchanged' | 'systemDefault';
}

/**
 * POST /bandwidth/{accountId}/orders/tn-options/routing
 * 
 * Create a new TN Option Order for changing routing settings
 * 
 * @param api
 * @param form
 */
export const createBandwidthRoutingTnOptionOrder = async (api: ApiCaller, accountId: string, form: CreateBandwidthRoutingTnOptionOrderForm): Promise<TnOptionOrder> => {
    return await api<TnOptionOrder>(`bandwidth/${accountId}/orders/tn-options/routing`, 'POST', {
        ...form
    });
};

export interface CreateBandwidthPortOutPasscodeTnOptionOrderForm {
    numbers: string[];
    passcode: string | null | 'unchanged' | 'systemDefault'
}

/**
 * POST /bandwidth/{accountId}/orders/tn-options/routing
 * 
 * Create a new TN Option Order for changing port-out passcode settings
 * 
 * @param api
 * @param form
 */
export const createBandwidthPortOutPasscodeTnOptionOrder = async (api: ApiCaller, accountId: string, form: CreateBandwidthPortOutPasscodeTnOptionOrderForm): Promise<TnOptionOrder> => {
    return await api<TnOptionOrder>(`bandwidth/${accountId}/orders/tn-options/port-out`, 'POST', {
        ...form
    });
};