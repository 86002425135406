import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../../theme';
import { bandwidthLocationViewUrl, bandwidthOrderE911ViewUrl, bandwidthOrderNewNumberViewUrl, bandwidthOrderPortInViewUrl, bandwidthOrderTnOptionViewUrl, bandwidthOrderViewUrl, bandwidthSubAccountViewUrl } from '../../urls';
import { Number } from '../api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    number: Number;
}

// Number Summary Box
const NumberSummary = (props: Props) => {
    const classes = useStyles();
    const { number } = props;

    const SubAccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(number.accountId, number.subAccountId)} {...props} />);
    const LocationLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthLocationViewUrl(number.accountId, number.subAccountId, number.locationId)} {...props} />);

    let orderUrl: string = '';

    switch (number.orderType) {
        case 'E911':
        case 'Lidb':
        case 'MoveNumber':
        case 'NewNumber':
        case 'PortIn':
        case 'TnOption':
            orderUrl = bandwidthOrderViewUrl(number.orderType, number.accountId, number.orderId);
            break;
    }

    const OrderLinkComponent = React.forwardRef((props, ref) => <RouterLink to={orderUrl} {...props} />);

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1}>
                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Sub-Account</Typography>
                    <Typography>
                        <Link component={SubAccountLinkComponent}>{number.subAccountName}</Link>
                    </Typography>
                </Grid>

                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Location</Typography>
                    <Typography>
                        <Link component={LocationLinkComponent}>{number.locationName}</Link>
                    </Typography>
                </Grid>

                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>
                        {number.status}
                    </Typography>
                </Grid>
            </Grid>

            {(number.city || number.state || number.rateCenter) &&
                <Grid container spacing={1}>
                    {number.city &&
                        <Grid xs={4} item>
                            <Typography variant="caption" color="textSecondary">City</Typography>
                            <Typography>
                                {number.city}
                            </Typography>
                        </Grid>
                    }

                    {number.state &&
                        <Grid xs={4} item>
                            <Typography variant="caption" color="textSecondary">State</Typography>
                            <Typography>
                                {number.state}
                            </Typography>
                        </Grid>
                    }

                    {number.rateCenter &&
                        <Grid xs={4} item>
                            <Typography variant="caption" color="textSecondary">Rate Center</Typography>
                            <Typography>
                                {number.rateCenter}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            }

            <Grid container spacing={1}>
                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">LATA</Typography>
                    <Typography>
                        {number.lata}
                    </Typography>
                </Grid>

                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Tier</Typography>
                    <Typography>
                        {number.tier}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid xs={12} item>
                    <Typography variant="caption" color="textSecondary">Order ID</Typography>
                    <Typography>
                        {orderUrl ? <Link component={OrderLinkComponent}>{number.orderId}</Link> : number.orderId}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Order Date</Typography>
                    <Typography>
                        {moment(number.orderCreateDate).format('MM/DD/YYYY hh:mm A')}
                    </Typography>
                </Grid>

                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">Last Modified Date</Typography>
                    <Typography>
                        {moment(number.lastModifiedDate).format('MM/DD/YYYY hh:mm A')}
                    </Typography>
                </Grid>

                <Grid xs={4} item>
                    <Typography variant="caption" color="textSecondary">In Service Date</Typography>
                    <Typography>
                        {moment(number.inServiceDate).format('MM/DD/YYYY hh:mm A')}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default NumberSummary;