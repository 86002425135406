export const DUBBER_ACCOUNT_INDEX_URL = '/dubber/accounts';
export const DUBBER_ACCOUNT_VIEW_URL = '/dubber/:groupId/accounts/:accountId';

export const DUBBER_USER_INDEX_URL = '/dubber/:groupId/accounts/:accountId/users';
export const DUBBER_USER_CREATE_URL = '/dubber/:groupId/accounts/:accountId/users/create';
export const DUBBER_USER_VIEW_URL = '/dubber/:groupId/accounts/:accountId/users/:userId';
export const DUBBER_USER_UPDATE_URL = '/dubber/:groupId/accounts/:accountId/users/:userId/update';

export const DUBBER_TEAM_INDEX_URL = '/dubber/:groupId/accounts/:accountId/teams';
export const DUBBER_TEAM_CREATE_URL = '/dubber/:groupId/accounts/:accountId/teams/create';
export const DUBBER_TEAM_VIEW_URL = '/dubber/:groupId/accounts/:accountId/teams/:teamId';

export const DUBBER_TEAM_MEMBER_VIEW_URL = '/dubber/:groupId/accounts/:accountId/teams/:teamId/member/:userId';
export const DUBBER_TEAM_MEMBER_CREATE_URL = '/dubber/:groupId/accounts/:accountId/teams/:teamId/member/create';
export const DUBBER_TEAM_MEMBER_UPDATE_URL = '/dubber/:groupId/accounts/:accountId/teams/:teamId/member/:userId/update';

export const DUBBER_DUBPOINT_INDEX_URL = '/dubber/:groupId/accounts/:accountId/dub-points';
export const DUBBER_DUBPOINT_CREATE_URL = '/dubber/:groupId/accounts/:accountId/dub-points/create';
export const DUBBER_DUBPOINT_VIEW_URL = '/dubber/:groupId/accounts/:accountId/dub-points/:dubPointId';
export const DUBBER_DUBPOINT_UPDATE_URL = '/dubber/:groupId/accounts/:accountId/dub-points/:dubPointId/update';

export const DUBBER_UNIDENTIFIED_DUBPOINT_INDEX_URL = '/dubber/unidentified-dub-points';
export const DUBBER_UNIDENTIFIED_DUBPOINT_VIEW_URL = '/dubber/:groupId/unidentified-dub-points/:dubPointId';
export const DUBBER_UNIDENTIFIED_DUBPOINT_UPDATE_URL = '/dubber/:groupId/unidentified-dub-points/:dubPointId/update';

// Accounts

export const dubberAccountIndexUrl = () => DUBBER_ACCOUNT_INDEX_URL;
export const dubberAccountViewUrl = (groupId: string, accountId: string) => DUBBER_ACCOUNT_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

// Users

export const dubberUserCreateUrl = (groupId: string, accountId: string) => DUBBER_USER_CREATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

export const dubberUserViewUrl = (groupId: string, accountId: string, userId: string) => DUBBER_USER_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':userId', userId);

export const dubberUserUpdateUrl = (groupId: string, accountId: string, userId: string) => DUBBER_USER_UPDATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':userId', userId);

export const dubberUserIndexUrl = dubberAccountViewUrl;

// Teams

export const dubberTeamIndexUrl = (groupId: string, accountId: string) => DUBBER_TEAM_INDEX_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

export const dubberTeamCreateUrl = (groupId: string, accountId: string) => DUBBER_TEAM_CREATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

export const dubberTeamViewUrl = (groupId: string, accountId: string, teamId: string) => DUBBER_TEAM_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':teamId', teamId);

export const dubberTeamMemberCreateUrl = (groupId: string, accountId: string, teamId: string) => DUBBER_TEAM_MEMBER_CREATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':teamId', teamId);

export const dubberTeamMemberViewUrl = (groupId: string, accountId: string, teamId: string, userId: string) => DUBBER_TEAM_MEMBER_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':teamId', teamId)
    .replace(':userId', userId);

export const dubberTeamMemberUpdateUrl = (groupId: string, accountId: string, teamId: string, userId: string) => DUBBER_TEAM_MEMBER_UPDATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':teamId', teamId)
    .replace(':userId', userId);

// Dub Points

export const dubberDubPointIndexUrl = (groupId: string, accountId: string) => DUBBER_DUBPOINT_INDEX_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

export const dubberDubPointCreateUrl = (groupId: string, accountId: string) => DUBBER_DUBPOINT_CREATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId);

export const dubberDubPointViewUrl = (groupId: string, accountId: string, dubPointId: string) => DUBBER_DUBPOINT_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':dubPointId', dubPointId);

export const dubberDubPointUpdateUrl = (groupId: string, accountId: string, dubPointId: string) => DUBBER_DUBPOINT_UPDATE_URL
    .replace(':groupId', groupId)
    .replace(':accountId', accountId)
    .replace(':dubPointId', dubPointId);

export const dubberUnidentifiedDubPointIndexUrl = () => DUBBER_UNIDENTIFIED_DUBPOINT_INDEX_URL;

export const dubberUnidentifiedDubPointViewUrl = (groupId: string, dubPointId: string) => DUBBER_UNIDENTIFIED_DUBPOINT_VIEW_URL
    .replace(':groupId', groupId)
    .replace(':dubPointId', dubPointId);

export const dubberUnidentifiedDubPointUpdateUrl = (groupId: string, dubPointId: string) => DUBBER_UNIDENTIFIED_DUBPOINT_UPDATE_URL
    .replace(':groupId', groupId)
    .replace(':dubPointId', dubPointId);