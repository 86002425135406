import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { SERVICE_DIRECT_ROUTING, SERVICE_FAX2MAIL, SERVICE_HOSTED_PBX, SERVICE_SIP_TRUNKING, SERVICE_WEBEX } from '../../../../services';
import theme from '../../../../theme';
import { AssignedService } from '../../api';
import DirectRoutingCard from './DirectRoutingCard';
import Fax2MailCard from './Fax2MailCard';
import HostedPbxCard from './HostedPbxCard';
import SipTrunkingCard from './SipTrunkingCard';
import WebexCard from './WebexCard';

const useStyles = makeStyles(() => ({
    root: {
        width: 275
    },
    serviceName: {
        ...theme.typography.h5,
        color: theme.palette.text.primary
    },
    serviceActions: {
        justifyContent: 'space-between'
    },
    removeButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText
        }
    }
}));

interface Props {
    organizationId: number;
    assignedService: AssignedService;
}

/**
 * A card in the assigned services grid.
 *
 * @param props
 * @constructor
 */
const ServiceCard = (props: Props) => {
    const classes = useStyles();
    const { organizationId, assignedService } = props;

    switch (assignedService.service) {
        case SERVICE_DIRECT_ROUTING:
            return <DirectRoutingCard organizationId={organizationId} assignmentId={assignedService.id} />;
        case SERVICE_HOSTED_PBX:
            return <HostedPbxCard organizationId={organizationId} assignmentId={assignedService.id} />;
        case SERVICE_FAX2MAIL:
            return <Fax2MailCard organizationId={organizationId} assignmentId={assignedService.id} />;
        case SERVICE_SIP_TRUNKING:
            return <SipTrunkingCard organizationId={organizationId} assignmentId={assignedService.id} />;
        case SERVICE_WEBEX:
            return <WebexCard organizationId={organizationId} assignmentId={assignedService.id} />;
        // Default card for services that have no custom card
        default:
            return (
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.serviceName} component="h4">
                            {assignedService?.service}
                        </Typography>
                    </CardContent>
                </Card>);
    }
};

export default ServiceCard;