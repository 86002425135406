import { Divider, FormControl, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { platformDisplay } from '../helpers';
import { useFetchHostedPbxBandwidthSubAccounts, useFetchHostedPbxDubberAccounts, useFetchHostedPbxGroups } from '../hooks';
import { Platform } from './api';
import HostedPbxFormValuesBase from './HostedPbxFormValuesBase';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues extends HostedPbxFormValuesBase {
    platform: string;
    broadworksServiceProvider: string;
    broadworksGroup: string;
    bandwidthSubAccount: string;
    dubberAccount: string | '';
}

interface Props {
    organization: OrganizationDetails;
    assignmentId?: number;
    formValues: FormValues,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: FormValues) => void;
}

export const formComplete = (organization: OrganizationDetails, form: FormValues): boolean => {
    return form.platform.length > 0
        && form.broadworksServiceProvider.length > 0
        && form.broadworksGroup.length > 0
        && form.bandwidthSubAccount.length > 0;
};

/**
 * Hosted PBX Assignment Form when assigning an Organization to BandWidth and BroadWorks accounts that are already created
 * @param props
 * @constructor
 */
const HostedPbxFormExistingAccounts = (props: Props) => {
    const { assignmentId, organization, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const [fetchGroups, isFetchingGroups, groups, groupError] = useFetchHostedPbxGroups();
    const [fetchSubAccounts, isFetchingSubAccounts, subAccounts, subAccountsError] = useFetchHostedPbxBandwidthSubAccounts();
    const [fetchDubberAccounts, isFetchingDubberAccounts, dubberAccounts, dubberAccountsError] = useFetchHostedPbxDubberAccounts();

    useEffect(() => {
        if (formValues.platform) {
            const platform = formValues.platform as Platform;

            fetchGroups(platform, 'name', true);
            fetchSubAccounts(platform);
        }
    }, [formValues.platform]);

    useEffect(() => {
        fetchDubberAccounts();
    }, []);

    const handleGroupChange = useCallback((id: string) => {
        if (groups !== null) {
            const group = groups.filter(grp => grp.id == id)[0];

            if (group !== null) {
                setFormValues({
                    ...formValues,
                    broadworksServiceProvider: group.serviceProvider,
                    broadworksGroup: group.id
                })
            }
        }
    }, [groups, formValues]);

    const displayError = errorMessage || groupError || subAccountsError || dubberAccountsError;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            {!isFetchingGroups
                && !isFetchingSubAccounts
                && !isFetchingDubberAccounts
                && groups !== null
                && subAccounts !== null
                && dubberAccounts !== null
                ? <>
                    {/* 
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('platform')}>
                        <InputLabel htmlFor="platform">Platform</InputLabel>

                        <Select
                            id="platform"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.platform || ''}
                            onChange={evt => setFormValues({ ...formValues, platform: evt.target.value })}
                            input={<Input name="platform" id="platform" />}>

                            <option value="" />
                            <option value="Averistar">{platformDisplay('Averistar')}</option>
                        </Select>

                        <ValidationMessage field="platform" />
                    </FormControl>
                    */}

                    <FormControl className={classes.formControl} fullWidth required error={!isValid('broadworksId')}>
                        <InputLabel htmlFor="broadworksGroup">Group</InputLabel>

                        <Select
                            id="broadworksGroup"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.broadworksGroup || ''}
                            onChange={evt => handleGroupChange(evt.target.value)}
                            input={<Input name="broadworksGroup" id="broadworksGroup" />}>

                            <option key="" value="" />

                            {groups.map(grp => <option key={grp.id}
                                value={grp.id}>{grp.name} ({grp.id})</option>)}
                        </Select>

                        <ValidationMessage field="broadworksGroup" />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthSubAccount')}>
                        <InputLabel htmlFor="bandwidthSubAccount">Bandwidth Sub-Account</InputLabel>

                        <Select
                            id="bandwidthSubAccount"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.bandwidthSubAccount || ''}
                            onChange={evt => setFormValues({ ...formValues, bandwidthSubAccount: evt.target.value })}
                            input={<Input name="bandwidthSubAccount" id="bandwidthSubAccount" />}>

                            <option key="" value="" />

                            {subAccounts.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                        </Select>

                        <ValidationMessage field="bandwidthSubAccount" />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth error={!isValid('dubberAccount')}>
                        <InputLabel htmlFor="dubberAccount" shrink={true}>Dubber Account</InputLabel>

                        <Select
                            id="dubberAccount"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.dubberAccount}
                            onChange={evt => setFormValues({ ...formValues, dubberAccount: evt.target.value })}
                            input={<Input name="dubberAccount" id="dubberAccount" />}>

                            <option key="" value="">None</option>

                            {dubberAccounts.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                        </Select>

                        <ValidationMessage field="dubberAccount" />
                    </FormControl>
                </> : <LinearProgress />
            }
        </>
    );
};

export default HostedPbxFormExistingAccounts;