import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { bandwidthSubAccountViewUrl } from '../../bandwidth/urls';
import { Link as RouterLink } from 'react-router-dom';
import { dubberAccountViewUrl } from '../../dubber/urls';
import { Platform } from './api';
import { platformDisplay } from '../helpers';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    platform: Platform;
    broadworksGroup: string;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
    dubberGroup: string;
    dubberAccount: string | null;
    assignmentDate: string;
    isCancelled: boolean;
    cancelDate: string;
}

/**
 * Displays a box showing basic information about a HPBX assignment
 * @param props
 * @constructor
 */
const AssignmentSummary = (props: Props) => {
    const classes = useStyles();
    const { platform, broadworksGroup, bandwidthAccount, bandwidthSubAccount, dubberGroup, dubberAccount, assignmentDate, isCancelled, cancelDate } = props;

    const BandwidthLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(bandwidthAccount, bandwidthSubAccount)} {...props} />);

    const DubberLinkComponent =
        dubberAccount
            ? React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(dubberGroup, dubberAccount)} {...props} />)
            : null;

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Platform</Typography>
                    <Typography>
                        {platformDisplay(platform)}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Group</Typography>
                    <Typography>
                        {broadworksGroup}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Bandwidth Sub-Account</Typography>
                    <Typography>
                        <Link component={BandwidthLinkComponent}>{bandwidthSubAccount}</Link>
                    </Typography>
                </Grid>

                {dubberAccount && DubberLinkComponent &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Dubber Account</Typography>
                        <Typography>
                            <Link component={DubberLinkComponent}>{dubberAccount}</Link>
                        </Typography>
                    </Grid>
                }

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Service Assigned</Typography>
                    <Typography>
                        {assignmentDate}
                    </Typography>
                </Grid>

                {isCancelled &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Service Cancelled</Typography>
                        <Typography>
                            {cancelDate}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default AssignmentSummary;