import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Account } from '../accounts/api';
import AddressBlock from '../AddressBlock';
import { Location } from '../locations/api';
import { bandwidthLocationViewUrl } from '../urls';

interface Props {
    account: Account;
    subAccountId: string;
    locations: Location[];
}

const LocationsTable = (props: Props) => {
    const { account, locations, subAccountId } = props;

    // Wholesale accounts don't have addresses tied to locations, so don't show Address column for them
    const showAddress = account.type !== 'Wholesale';

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Location</TableCell>
                    {showAddress && <TableCell>Address</TableCell>}
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {locations.map(l => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthLocationViewUrl(account.id, subAccountId, l.id)} {...props} />);

                    return (
                        <TableRow key={l.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{l.name}</Link>
                            </TableCell>
                            {showAddress &&
                                <TableCell>
                                    {l.address && <AddressBlock address={l.address} />}
                                </TableCell>
                            }
                            <TableCell>{l.description}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default LocationsTable;