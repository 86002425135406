import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/sip-trunking/organizations
 *
 * Get all organizations with SIP Trunking service assigned
 */

export interface Organization {
    id: number;
    assignmentId: number;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
    name: string;
    isActive: boolean;
    startedAt: string;
    cancelledAt: string;
}

export type Status = 'all' | 'active' | 'cancelled';

export const fetchSipTrunkingOrganizations = async (api: ApiCaller, search?: string, status: Status = 'active', page: number = 1, limit?: number): Promise<PagedResponse<Organization>> => {
    return await api<PagedResponse<Organization>>(`sip-trunking/organizations`, 'GET', {
        search,
        status,
        page,
        limit
    });
};
