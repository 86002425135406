import { BILLING_ASSOCIATE, SERVICE_MANAGER, SUPER_USER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, PROVISIONING } from '../administrators/api';

export enum ReportingPolicies {
    CanViewDidUsageReport = 'CanViewDidUsageReport',
    CanViewHpbxUsageReport = 'CanViewHpbxUsageReport'
}

export const ReportingPolicyRoles = {
    [ReportingPolicies.CanViewDidUsageReport]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [ReportingPolicies.CanViewHpbxUsageReport]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING]
};