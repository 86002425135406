import { BILLING_ASSOCIATE, SERVICE_MANAGER, SUPER_USER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER, PROVISIONING } from '../administrators/api';

export enum Fax2MailPolicies {
    CanManageAssignments = 'CanManageFax2MailAssignments',
    CanCancelAssignments = 'CanCancelFax2MailAssignments',
    CanManageSeats = 'CanManageFax2MailSeats',
    CanViewStatements = 'CanViewFax2MailStatements',
    CanManageStatements = 'CanManageFax2MailStatements',
}

export const Fax2MailPolicyRoles = {
    [Fax2MailPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [Fax2MailPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [Fax2MailPolicies.CanManageSeats]: [SUPER_USER, BILLING_ASSOCIATE, PROJECT_COORDINATOR, SYSTEMS_ENGINEER, SERVICE_MANAGER, PROVISIONING],

    [Fax2MailPolicies.CanViewStatements]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [Fax2MailPolicies.CanManageStatements]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
};