import { VariantType } from 'notistack';
import { SubNavItem, TopNavItem } from './NavDrawer';

// Fire when you want to indicate that the current page is doing some background work
export const APP_WINDOW_SHOW_PROGRESS = 'APP_WINDOW_SHOW_PROGRESS';

// Fire when the current page has finished its background work
export const APP_WINDOW_HIDE_PROGRESS = 'APP_WINDOW_HIDE_PROGRESS';

// Displays a notification in the window's notifications area
export const APP_WINDOW_ADD_NOTIFICATION = 'APP_WINDOW_ADD_NOTIFICATION';

// Displays a notification in the window's notifications area
export const APP_WINDOW_REMOVE_NOTIFICATION = 'APP_WINDOW_REMOVE_NOTIFICATION';

// Sets the current active navigation item
export const APP_WINDOW_SET_ACTIVE_NAV = 'APP_WINDOW_SET_ACTIVE_NAV';

export type AppWindowShowProgress = {
    type: typeof APP_WINDOW_SHOW_PROGRESS
}

export type AppWindowHideProgress = {
    type: typeof APP_WINDOW_HIDE_PROGRESS
}

export type AppWindowAddNotification = {
    type: typeof APP_WINDOW_ADD_NOTIFICATION,
    payload: {
        key: string,
        message: string,
        type: VariantType
    }
}

export type AppWindowRemoveNotification = {
    type: typeof APP_WINDOW_REMOVE_NOTIFICATION,
    payload: {
        key: string
    }
}

export type AppWindowSetActiveNav = {
    type: typeof APP_WINDOW_SET_ACTIVE_NAV,
    payload: {
        nav?: TopNavItem;
        subNav?: SubNavItem;
    }
};

export const appWindowShowProgress = (): AppWindowShowProgress => ({
    type: APP_WINDOW_SHOW_PROGRESS
});

export const appWindowHideProgress = (): AppWindowHideProgress => ({
    type: APP_WINDOW_HIDE_PROGRESS
});

export const appWindowAddNotification = (message: string, type: VariantType = 'default'): AppWindowAddNotification => ({
    type: APP_WINDOW_ADD_NOTIFICATION,
    payload: {
        key: String(new Date().getTime() + Math.random()),
        message,
        type
    }
});

export const appWindowRemoveNotification = (key: string): AppWindowRemoveNotification => ({
    type: APP_WINDOW_REMOVE_NOTIFICATION,
    payload: {
        key
    }
});

export const appWindowSetActiveNav = (nav?: TopNavItem, subNav?: SubNavItem): AppWindowSetActiveNav => ({
    type: APP_WINDOW_SET_ACTIVE_NAV,
    payload: {
        nav,
        subNav
    }
});

export type Actions =
    | AppWindowShowProgress
    | AppWindowHideProgress
    | AppWindowAddNotification
    | AppWindowRemoveNotification
    | AppWindowSetActiveNav;