import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import AddressBlock from '../../AddressBlock';
import { useFetchBandwidthAccount, useFetchBandwidthE911Order } from '../../hooks';
import HistoryNotesTable from '../HistoryNotesTable';
import E911Summary from './E911Summary';
import NumberTable from './NumberTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginBottom: theme.spacing(2)
    },
    section: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    padding: {
        padding: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {

}

// Page for viewing an E911 order
const E911ViewPage = (props: Props) => {
    const classes = useStyles();

    const accountId = props.match.params['accountId'] || null;
    const orderId = props.match.params['orderId'] || null;

    const [fetchOrder, isFetchingOrder, order, fetchError] = useFetchBandwidthE911Order();
    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchBandwidthAccount();

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingOrder || isFetchingAccount,
        fetchError || fetchAccountError
    );

    // Fetch order and account on load
    useEffect(() => {
        fetchOrder(accountId, orderId);
        fetchAccount(accountId);
    }, [accountId, orderId]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Order" subtext={orderId} />

            {!isFetchingOrder && order && !isFetchingAccount && account &&
                <>
                    <div className={classes.summary}>
                        <E911Summary
                            accountId={accountId}
                            account={account}
                            order={order} />
                    </div>

                    {order.address != null &&
                        <div className={classes.section}>
                            <Typography variant="h3">Service Address</Typography>

                            <Paper className={classes.paper}>
                                <div className={classes.padding}>
                                    <AddressBlock address={order.address} />
                                </div>
                            </Paper>
                        </div>
                    }

                    <div className={classes.section}>
                        <Typography variant="h3">Numbers</Typography>
                        <Typography variant="body1">{order.numbers.length} total</Typography>

                        <Paper className={classes.paper}>
                            <NumberTable
                                isComplete={order.status === 'Complete' || order.status === 'AdjustedComplete'}
                                errors={order.errors}
                                numbers={order.numbers} />
                        </Paper>
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">History & Notes</Typography>
                        <Paper className={classes.paper}>
                            <HistoryNotesTable
                                history={order.history}
                                notes={order.notes}
                            />
                        </Paper>
                    </div>
                </>

            }
        </div>
    );
};

export default E911ViewPage;