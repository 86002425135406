import { Email, Password, PhoneAndroid } from '@mui/icons-material';
import {
    Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { MfaMethods } from './api';
import { useFetchMfaOptions, useSession } from './hooks';

interface MethodSelectionProps {
    onCancel: () => void;
    onSelect: (method: MfaMethods) => void;
}

const MethodSelection = (props: MethodSelectionProps) => {
    const session = useSession();
    const { onCancel, onSelect } = props;
    const [fetchOptions, isFetchingOptions, mfaOptions, fetchError] = useFetchMfaOptions();

    useEffect(() => {
        fetchOptions();
    }, [session.token]);

    return <>
        <DialogContent>
            {fetchError && <DialogContentText color="warning">{fetchError}</DialogContentText>}

            {isFetchingOptions && <Box sx={{ textAlign: 'center' }}><CircularProgress color="primary" /></Box>}

            {!isFetchingOptions && mfaOptions &&
                <>
                    <DialogContentText color={'inherit'}>Select a method to complete authentication</DialogContentText>

                    <List>
                    {mfaOptions.methods.indexOf('Authenticator') > -1 &&
                            <ListItem disablePadding>
                            <ListItemButton onClick={() => onSelect('Authenticator')}>
                                    <ListItemIcon>
                                        <PhoneAndroid />
                                    </ListItemIcon>
                                    <ListItemText primary="Authenticator App" />
                                </ListItemButton>
                            </ListItem>
                        }

                        {mfaOptions.methods.indexOf('Email') > -1 &&
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => onSelect('Email')}>
                                    <ListItemIcon>
                                        <Email />
                                    </ListItemIcon>
                                    <ListItemText primary="Email Code" />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                </>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onCancel()} color="secondary">
                Cancel
            </Button>
        </DialogActions>
    </>;
};

export default MethodSelection;