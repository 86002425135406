import { AppBar, Button, CircularProgress, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchAllHpbxOrganizations } from '../assignment/hooks';
import { HostedPbxOrganizationForm } from '../components/HostedPbxOrganizationForm';
import { HostedPbxPolicies } from '../policies';
import { EligibleDevice, Profile } from './api';
import { useEnableZeroTouch, useFetchZeroTouchEligibleDevices, useSearchPolyZeroTouch, useSearchYealinkZeroTouch } from './hooks';
import moment = require('moment');
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Pagination from '../../../components/Pagination';
import SearchBar from './SearchBar';
import { PagedResponse } from '../../../api';
import theme from '../../../theme';
import { Platform } from '../api';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface SearchFields {
    search?: string;
    limit: number;
}

interface EnableRequest {
    deviceName: string;
    platform: Platform;
    groupId: string;
    ts: number;
}

/**
 * Page for enabling ZT for a device
 * */

const ZeroTouchIndexPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const session = useSession();

    useNav('hosted-pbx', 'zero-touch');

    const [isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllHpbxOrganizations('active');
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);
    const [fetchDevices, isFetchingDevices, devices, fetchDevicesError] = useFetchZeroTouchEligibleDevices();
    const [pageNumber, setPageNumber] = useState(1);

    const [searchPolyZtp, isSearchingPoly, polyZtpResponse, polyZtpError] = useSearchPolyZeroTouch();
    const [searchYealinkZtp, isSearchingYealink, yealinkZtpResponse, yealinkZtpError] = useSearchYealinkZeroTouch();

    // Search fields
    const [searchFields, setSearchFields] = useState<SearchFields>({
        limit: DEFAULT_PAGE_SIZE
    });

    const [enableRequest, setEnableRequest] = useState<EnableRequest | null>(null);
    const [enableDevice, isEnabling, isEnableSuccess, enableError] = useEnableZeroTouch();

    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const [warningDialogDeviceName, setWarningDialogDeviceName] = useState('');

    // Use page load bar for loading and when enabling ZTP for a device
    // Display error if one occurs during any network request
    useProgressEffects(
        isFetchingOrganizations || isEnabling || isFetchingDevices,
        fetchOrganizationsError || fetchDevicesError || enableError
    );

    // Get organization details of selected organization
    const organization = useMemo(() => {
        if (!organizations) {
            return null;
        }

        return organizations.find(o => o.assignmentId === selectedAssignmentId) || null;
    }, [selectedAssignmentId]);

    // Reset search form when group changes
    useEffect(() => {
        if (organization !== null) {
            setSearchFields({
                search: '',
                limit: searchFields.limit
            });

            setPageNumber(1);
        }
    }, [organization]);

    // Fetch devices when group changes
    useEffect(() => {
        if (organization !== null) {
            fetchDevices(organization.platform, organization.broadworksGroup);
        }
    }, [organization]);

    // Page devices when search or limit changes
    const pagedDevices: PagedResponse<EligibleDevice> | null = useMemo(() => {
        if (devices === null) {
            return null;
        }

        const filter = searchFields.search?.toLowerCase() || '';
        const spliceStart = (pageNumber - 1) * searchFields.limit;

        const filteredDevices = devices.filter(d => {
            return d.macAddress.toLowerCase().indexOf(filter) > -1
                || d.type.toLowerCase().indexOf(filter) > -1;
        });

        const totalItems = filteredDevices.length;
        const pageDevices = filteredDevices.splice(spliceStart, searchFields.limit);
        const totalPages = Math.ceil(pageDevices.length / searchFields.limit);

        return {
            page: pageNumber,
            pageSize: searchFields.limit,
            totalItems,
            totalPages,
            items: pageDevices
        };
    }, [devices, searchFields, pageNumber]);

    // Fetch Poly & Yealink ZTP statuses for found devices
    useEffect(() => {
        if (devices !== null) {
            refreshPolyZtpStatus();
            refreshYealinkZtpStatus();
        }
    }, [devices]);

    // Handle Search button
    const searchSubmit = (search: string, limit: number) => {
        // Reset back to page 1
        setPageNumber(1);

        setSearchFields({
            search,
            limit
        });
    };

    const refreshPolyZtpStatus = () => {
        if (devices !== null) {
            const polyDevices = devices.filter(d => d.manufacturer == 'Poly');

            if (polyDevices.length > 0) {
                searchPolyZtp(polyDevices.map(d => d.macAddress));
            }
        }
    }

    const refreshYealinkZtpStatus = () => {
        if (devices !== null) {
            const yealinkDevices = devices.filter(d => d.manufacturer == 'Yealink');

            if (yealinkDevices.length > 0) {
                searchYealinkZtp(yealinkDevices.map(d => d.macAddress));
            }
        }
    };

    // Display success message when a device gets enabled and refresh that manufacturer's devices
    useEffect(() => {
        if (isEnableSuccess && enableRequest !== null) {
            dispatch(appWindowAddNotification(`${enableRequest.deviceName} configured for ZTP`, 'success'));

            if (devices) {

                // Find device
                const device = (devices || []).find(d => d.name === enableRequest.deviceName);

                // Refresh ZTP status of all manufacturer's devices
                if (device) {
                    if (device.manufacturer === 'Poly') {
                        refreshPolyZtpStatus();
                    } else if (device.manufacturer === 'Yealink') {
                        refreshYealinkZtpStatus();
                    }
                }
            }
        }
    }, [isEnableSuccess]);

    type ZtpStatus = 'loading' | Profile | 'not-enabled' | 'error';

    // Get current status of a device
    const deviceZtpStatus = (device: EligibleDevice): ZtpStatus => {
        var isSearching;
        var ztpResponse;
        var ztpError;

        if (device.manufacturer === 'Poly') {
            isSearching = isSearchingPoly;
            ztpResponse = polyZtpResponse;
            ztpError = polyZtpError;
        } else if (device.manufacturer === 'Yealink') {
            isSearching = isSearchingYealink;
            ztpResponse = yealinkZtpResponse;
            ztpError = yealinkZtpError;
        } else {
            throw new Error('Unhandled manufacturer');
        }

        if (isSearching) {
            return 'loading';
        } else {
            if (ztpError) {
                return 'error';

            } else {
                const status = (ztpResponse || []).find(r => r.macAddress.toUpperCase() === device.macAddress.toUpperCase());

                if (status) {
                    return status.profile;
                } else {
                    return 'not-enabled';
                }
            }
        }
    };

    // Get text to display in Status column
    const deviceZtpStatusCol = (deviceStatus: ZtpStatus, device: EligibleDevice) => {
        switch (deviceStatus) {
            case 'loading':
                return <CircularProgress size={14} />;
            case 'error':
                return <Typography color="error">Error</Typography>;
            case 'Production':
            case 'Sandbox':
                // Display "for X" if the configured profile doesn't match the current environment
                if (deviceStatus === device.profile) {
                    return <Typography color="primary">Enabled</Typography>;
                } else {
                    return <Typography color="primary">Enabled for {deviceStatus}</Typography>;
                }
            case 'Invalid':
                return <Typography color="error">Misconfigured</Typography>;
            case 'not-enabled':
                return <Typography color="textSecondary">Not Enabled</Typography >;
        }
    };

    // Handle when Enable is clicked on a button
    const handleEnable = (deviceName: string) => {
        if (organization !== null) {
            setEnableRequest({
                platform: organization.platform,
                groupId: organization.broadworksGroup,
                deviceName,
                ts: Date.now()
            });
        }
    };

    // Perform ZTP enable when a device is selected
    useEffect(() => {
        if (enableRequest !== null) {
            enableDevice(enableRequest.platform, enableRequest.groupId, enableRequest.deviceName);
        }
    }, [enableRequest]);

    const canManage = useMemo(() => {
        return authorizedFor(HostedPbxPolicies.CanManageZeroTouch, session.roles)
    }, [session]);

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX" subtext="Zero Touch Provisioning" />

            {!isFetchingOrganizations && organizations &&
                <>
                    <Paper className={classes.paper}>
                        <HostedPbxOrganizationForm
                            organizations={organizations}
                            selectedOrganizationId={selectedOrganizationId}
                            selectedAssignmentId={selectedAssignmentId}
                            disabled={isEnabling || isFetchingDevices}
                            onSelect={(orgId, asId) => {
                                setSelectedOrganizationId(orgId);
                                setSelectedAssignmentId(asId);
                                setPageNumber(1);
                            }
                            } />
                    </Paper>

                    {organization && organization.phoneConfigurationMethod === 'FTP' && <>
                        <Paper>
                            <div className={classes.noResultsContainer}>
                                <Typography color="textSecondary" align="center">
                                    Organization does not qualify for ZTP because it uses legacy FTP phone configurations.
                            </Typography>
                            </div>
                        </Paper>
                    </>
                    }

                    {organization && organization.phoneConfigurationMethod === 'DMS' && <>
                        <Paper>
                            <SearchBar onSubmitSearch={searchSubmit} />

                            {pagedDevices && pagedDevices.totalItems === 0 && <div className={classes.noResultsContainer}>
                                <Typography color="textSecondary" align="center">
                                    No devices found.
                            </Typography>
                            </div>}

                            {pagedDevices && pagedDevices.totalItems > 0 && !isFetchingDevices &&
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>MAC Address</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Status</TableCell>
                                                {canManage && <TableCell>Enable ZTP</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pagedDevices.items.map(device => {
                                                const deviceStatus = deviceZtpStatus(device);

                                                const showButton = deviceStatus !== device.profile
                                                    && deviceStatus !== 'error'
                                                    && deviceStatus !== 'Invalid'
                                                    && deviceStatus !== 'loading';

                                                const buttonText = showButton && deviceStatus === 'not-enabled'
                                                    ? 'Enable'
                                                    : 'Update';

                                                return (
                                                    <TableRow key={device.name}>
                                                        <TableCell component="th" scope="row">{device.name}</TableCell>
                                                        <TableCell>{device.type}</TableCell>
                                                        <TableCell>{deviceZtpStatusCol(deviceStatus, device)}</TableCell>
                                                        {canManage && <TableCell>
                                                            {showButton && <Button
                                                                color="primary"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => {
                                                                    setWarningDialogOpen(true);
                                                                    setWarningDialogDeviceName(device.name);
                                                                }}
                                                                disabled={isEnabling}>{buttonText}</Button>}
                                                        </TableCell>}
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                    <Pagination
                                        totalItems={pagedDevices.totalItems}
                                        pageSize={pagedDevices.pageSize}
                                        page={pagedDevices.page}
                                        disabled={isEnabling}
                                        setParams={false}
                                        onChange={p => setPageNumber(p)} />
                                </>}
                        </Paper>
                    </>}
                </>
            }

            <ConfirmationDialog
                title={'Enable ZTP?'}
                //message={'Enabling ZTP on this device will force it to provision to the KM platform if it\'s currently turned on. Be advised when enabling for customers repurposing devices that may currently be in use on another service provider.'}
                message={'ZTP should only be enabled on devices during or after customer turn up to avoid disrupting prior service.'}
                showWorking={false}
                isOpen={warningDialogOpen}
                confirmText={'Enable'}
                cancelText={'Cancel'}
                cancelColor={'secondary'}
                onCancel={() => setWarningDialogOpen(false)}
                confirmColor={'primary'}
                onConfirm={() => {
                    setWarningDialogOpen(false);
                    handleEnable(warningDialogDeviceName);
                }} />
        </div>);
};

export default ZeroTouchIndexPage;
