import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../../theme';
import { NewNumberOrder, SubAccount, Location } from '../../api';
import { bandwidthLocationViewUrl, bandwidthSubAccountViewUrl } from '../../urls';
import moment = require('moment');
import { orderStatusDisplay, orderTypeDisplay } from '../helpers';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    accountId: string;
    order: NewNumberOrder;
    subAccount: SubAccount;
    location: Location;
}

// New Number Order Summary Box
const NewNumberSummary = (props: Props) => {
    const classes = useStyles();
    const { accountId, order, subAccount, location } = props;

    const SubAccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(accountId, order.subAccountId)} {...props} />);
    const LocationLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthLocationViewUrl(accountId, order.subAccountId, order.locationId)} {...props} />);

    const createDate = moment(order.createDate).format('MM/DD/YYYY hh:mm A');
    const lastModifiedDate = moment(order.lastModifiedDate).format('MM/DD/YYYY hh:mm A');

    const networks = [...new Set(order.completedNumbers.map(cn => cn.vendorName))];

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Sub-Account</Typography>
                    <Typography>
                        <Link component={SubAccountLinkComponent}>{subAccount.name}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Location</Typography>
                    <Typography>
                        <Link component={LocationLinkComponent}>{location.name}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                    <Typography>
                        {orderTypeDisplay(order.type)}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>
                        {orderStatusDisplay(order.status)}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Ordered By</Typography>
                    <Typography>
                        {order.createdByUser}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Order Date</Typography>
                    <Typography>
                        {createDate}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Last Updated</Typography>
                    <Typography>
                        {lastModifiedDate}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Network</Typography>
                    <Typography>
                        {networks.join(', ') || 'N/A'}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Customer Order ID</Typography>
                    <Typography>
                        {order.customerOrderId}
                    </Typography>
                </Grid>

                <Grid item xs={9}>
                    <Typography variant="caption" color="textSecondary">Description</Typography>
                    <Typography>
                        {order.summary}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default NewNumberSummary;