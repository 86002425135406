import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { Assignment } from './api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    assignment: Assignment;
    accountName: string;
}

/**
 * Displays a box showing basic information about a Direct Routing assignment
 * @param props
 * @constructor
 */
const AssignmentSummary = (props: Props) => {
    const classes = useStyles();
    const { assignment, accountName } = props;

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">iPilot Account</Typography>
                    <Typography>
                        {accountName}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Service Assigned</Typography>
                    <Typography>
                        {moment(assignment.startedAt).format('MM/DD/YYYY')}
                    </Typography>
                </Grid>

                {!assignment.isActive &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Service Cancelled</Typography>
                        <Typography>
                            {moment(assignment.cancelledAt).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default AssignmentSummary;