import { Checkbox, Divider, FormControl, FormControlLabel, Grid, Input, InputLabel, LinearProgress, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FieldError } from '../../../api';
import { STATES } from '../../../constants';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { platformDisplay } from '../helpers';
import HostedPbxFormValuesBase from './HostedPbxFormValuesBase';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues extends HostedPbxFormValuesBase {
    createDubberAccount: boolean;
    platform: string;
    timeZone: string | '';
    accountName: string;
    houseNumber: string;
    streetName: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}

interface Props {
    organization: OrganizationDetails;
    formValues: FormValues,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: FormValues) => void;
}

export const formComplete = (organization: OrganizationDetails, form: FormValues): boolean => {
    return form.platform.length > 0
        && form.accountName.length > 0
        && form.houseNumber.length > 0
        && form.streetName.length > 0
        && form.city.length > 0
        && form.state.length > 0
        && form.zipCode.length > 0
        && (!form.createDubberAccount || form.timeZone.length > 0);
};

/**
 * Hosted PBX Assignment Form that automatically creates accounts in Bandwidth and Broadworks for the Organization
 * @param props
 * @constructor
 */
const HostedPbxFormCreateAccounts = (props: Props) => {
    const { organization, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    // Set form values once organization details are loaded
    useEffect(() => {
        const address = (organization.address || '').split(' ');
        let houseNumber = '';
        let streetName = '';

        if (address.length > 0) {
            address.reverse();
            houseNumber = address.pop() || '';

            streetName = address.reverse().join(' ');
        }

        setFormValues({
            createDubberAccount: false,
            platform: 'Averistar',
            timeZone: '',
            accountName: organization.name,
            houseNumber: houseNumber,
            streetName: streetName,
            addressLine2: organization.address2 || '',
            city: organization.city || '',
            state: organization.state || '',
            zipCode: organization.zip || ''
        })
    }, [organization]);

    const onFieldChange = (fieldName: keyof FormValues, value: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={isSubmitting}
                                    checked={formValues.createDubberAccount}
                                    onChange={e => setFormValues({ ...formValues, createDubberAccount: e.target.checked })} />
                            }
                            label="Create Dubber Account" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('timeZone')}>
                        <InputLabel htmlFor="timeZone">Time Zone</InputLabel>

                        <Select
                            id="timeZone"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.timeZone || ''}
                            input={<Input name="timeZone" id="timeZone" />}
                            onChange={evt => onFieldChange('timeZone', evt.target.value)}>
                            <option value="" />
                            <option value="America/New_York">Eastern Time</option>
                            <option value="America/Chicago">Central Time</option>
                            <option value="America/Denver">Mountain Time</option>
                            <option value="America/Phoenix">Arizona Time</option>
                            <option value="America/Los_Angeles">Pacific Time</option>
                            <option value="America/Anchorage">Alaska Time</option>
                            <option value="Pacific/Honolulu">Hawaii Time</option>
                        </Select>

                        <ValidationMessage field="timeZone" />
                    </FormControl>
                </Grid>
            </Grid>

            {/* 
            <FormControl className={classes.formControl} fullWidth required error={!isValid('platform')}>
                <InputLabel htmlFor="platform">Platform</InputLabel>

                <Select
                    id="platform"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.platform || ''}
                    onChange={evt => setFormValues({ ...formValues, platform: evt.target.value })}
                    input={<Input name="platform" id="platform" />}>

                    <option value="" />
                    <option value="Cisco">{platformDisplay('Cisco')}</option>
                    <option value="Averistar">{platformDisplay('Averistar')}</option>
                </Select>

                <ValidationMessage field="platform" />
            </FormControl>
            */}


            <FormControl className={classes.formControl} fullWidth required error={!isValid('accountName')}>
                <InputLabel htmlFor="accountName">Account Name</InputLabel>

                <Input
                    autoFocus
                    value={formValues.accountName}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('accountName', evt.target.value)}
                    id="accountName"
                    type="text" />

                <ValidationMessage field="accountName" />
            </FormControl>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('houseNumber')}>
                        <InputLabel htmlFor="houseNumber">House Number</InputLabel>

                        <Input
                            value={formValues.houseNumber}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('houseNumber', evt.target.value)}
                            id="houseNumber"
                            type="text" />

                        <ValidationMessage field="houseNumber" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('streetName')}>
                        <InputLabel htmlFor="streetName">Street Name</InputLabel>

                        <Input
                            value={formValues.streetName}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('streetName', evt.target.value)}
                            id="streetName"
                            type="text" />

                        <ValidationMessage field="streetName" />
                    </FormControl>
                </Grid>
            </Grid>

            <FormControl className={classes.formControl} fullWidth error={!isValid('addressLine2')}>
                <InputLabel htmlFor="addressLine2">Address Line 2</InputLabel>

                <Input
                    value={formValues.addressLine2}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('addressLine2', evt.target.value)}
                    id="addressLine2"
                    type="text" />

                <ValidationMessage field="addressLine2" />
            </FormControl>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('city')}>
                        <InputLabel htmlFor="city">City</InputLabel>

                        <Input
                            value={formValues.city}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('city', evt.target.value)}
                            id="city"
                            type="text" />

                        <ValidationMessage field="city" />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={4}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('state')}>
                        <InputLabel htmlFor="state">State</InputLabel>

                        <Select
                            id="state"
                            native={true}
                            margin="none"
                            disabled={isSubmitting}
                            value={formValues.state || ''}
                            input={<Input name="state" id="state" />}
                            onChange={evt => onFieldChange('state', evt.target.value)}>
                            {StateOptions()}
                        </Select>

                        <ValidationMessage field="state" />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('zipCode')}>
                        <InputLabel htmlFor="zipCode">Zip Code</InputLabel>

                        <Input
                            value={formValues.zipCode}
                            disabled={isSubmitting}
                            onChange={evt => onFieldChange('zipCode', evt.target.value)}
                            id="zipCode"
                            type="text" />

                        <ValidationMessage field="zipCode" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value="" />];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};

export default HostedPbxFormCreateAccounts;