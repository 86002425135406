import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useFetchBandwidthAccount, useFetchBandwidthTnOptionOrder } from '../../hooks';
import HistoryNotesTable from '../HistoryNotesTable';
import NumberTable from './NumberTable';
import TnOptionSummary from './TnOptionSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginBottom: theme.spacing(2)
    },
    section: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    padding: {
        padding: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {

}

// Page for viewing a TN Option order
const TnOptionViewPage = (props: Props) => {
    const classes = useStyles();

    const accountId = props.match.params['accountId'] || null;
    const orderId = props.match.params['orderId'] || null;

    const [fetchOrder, isFetchingOrder, order, fetchError] = useFetchBandwidthTnOptionOrder();
    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchBandwidthAccount();

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingOrder || isFetchingAccount,
        fetchError || fetchAccountError
    );

    // Fetch order and account on load
    useEffect(() => {
        fetchOrder(accountId, orderId);
        fetchAccount(accountId);
    }, [accountId, orderId]);

    const numberItems = useMemo(() => {
        let items = [];

        if (order) {
            for (var i = 0; i < order.groups.length; i++) {
                const group = order.groups[i];

                for (var j = 0; j < group.numbers.length; j++) {
                    const number = group.numbers[j];
                    const error = order.errors.find(e => e.number == number)?.description || null;

                    items.push({
                        number: number,
                        status: error ? 'Failed' : (order.status.indexOf('Complete') > -1 || order.status.indexOf('Partial') > -1 ? 'Complete' : 'Processing'),
                        notes: error,
                        ...group
                    });
                }
            }
        }

        return items;
    }, [order]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Order" subtext={orderId} />

            {!isFetchingOrder && order && !isFetchingAccount && account &&
                <>
                    <div className={classes.summary}>
                        <TnOptionSummary
                            accountId={accountId}
                            account={account}
                            order={order} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">Numbers</Typography>
                        <Typography variant="body1">{order.groups.reduce((p, c, idx, arr) => {
                            return p + c.numbers.length;
                        }, 0)} total</Typography>

                        <Paper className={classes.paper}>
                            <NumberTable items={numberItems} />
                        </Paper>
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">History</Typography>
                        <Paper className={classes.paper}>
                            <HistoryNotesTable
                                history={order.history}
                                notes={[]}
                            />
                        </Paper>
                    </div>
                </>

            }
        </div>
    );
};

export default TnOptionViewPage;