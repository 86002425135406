import { Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { bandwidthNumberViewUrl } from '../urls';
import { formatUsNumber } from '../numbers/helpers';

interface Props {
    numbers: string[]
}

/**
 * Table for displaying all numbers under a location
 * @param props
 */
const NumbersTable = (props: Props) => {
    const { numbers } = props;

    return (
        <Grid container spacing={2}>
            {numbers.map(n => {
                const NumberLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n)} {...props} />);

                return (
                    <Grid item key={n} xs={3}>
                        <Typography align="center">
                            <Link component={NumberLinkComponent}>{formatUsNumber(n)}</Link>
                        </Typography>
                    </Grid>
                )
            })}
        </Grid >
    );
};

export default NumbersTable;