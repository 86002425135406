import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { amber, grey } from '@mui/material/colors';
import * as React from 'react';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: amber[800],
        borderColor: amber[900],
        borderWidth: 2,
        borderStyle: 'solid'
    },
    text: {
        color: grey[800]
    }
}));

interface Props {
}

/**
 * Displays a box alerting when an administrator is locked
 * @param props
 * @constructor
 */
const LockedNotice = (props: Props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Typography variant="h4" className={classes.text}>Account Locked</Typography>
            <Typography className={classes.text}>This account was locked due to too many failed logins.</Typography>
        </Paper>);
};

export default LockedNotice;