import { BILLING_ASSOCIATE, SERVICE_MANAGER, SUPER_USER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, PROVISIONING, SYSTEMS_ENGINEER } from '../administrators/api';

export enum HostedPbxPolicies {
    CanManageAssignments = 'CanManageHostedPbxAssignments',
    CanCancelAssignments = 'CanCancelHostedPbxAssignments',

    CanUseBulkUpload = 'CanUseBulkUpload',

    CanManageNumbers = 'CanManagedHostedPbxNumbers',

    CanManageZeroTouch = 'HostedPbxCanManageZeroTouch',

    CanUse9LineTool = 'HostedPbxCanUse9LineTool'
}

export const HostedPbxPolicyRoles = {
    [HostedPbxPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [HostedPbxPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],

    [HostedPbxPolicies.CanUseBulkUpload]: [SUPER_USER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER, SERVICE_MANAGER, PROVISIONING],

    [HostedPbxPolicies.CanManageNumbers]: [SUPER_USER, PROJECT_COORDINATOR, SYSTEMS_ENGINEER, SERVICE_MANAGER, PROVISIONING],

    [HostedPbxPolicies.CanManageZeroTouch]: [SUPER_USER, PROJECT_COORDINATOR, SERVICE_MANAGER, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER, PROVISIONING],

    [HostedPbxPolicies.CanUse9LineTool]: [SUPER_USER, PROJECT_COORDINATOR, SERVICE_MANAGER, SYSTEMS_ENGINEER, PROVISIONING]

};