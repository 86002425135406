import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { VariantType } from 'notistack';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import { Platform, updateHostedPbxAssignment } from '../api';
import { useFetchHostedPbxAssignment } from '../hooks';
import { HostedPbxPolicies } from '../policies';
import { hpbxAssignmentViewUrl } from '../urls';
import HostedPbxFormExistingAccounts, { FormValues } from './HostedPbxFormExistingAccounts';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    errorMessage: {
        backgroundColor: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * Hosted PBX assignment update form
 * @param props
 * @constructor
 */
const HostedPbxUpdatePage = (props: Props) => {
    const api = useApi();
    const classes = useStyles();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);
    const [fetchAssignment, isFetching, assignment, fetchError] = useFetchHostedPbxAssignment();

    const [formValues, setFormValues] = useState<FormValues>({
        platform: 'Averistar',
        broadworksServiceProvider: '',
        broadworksGroup: '',
        bandwidthSubAccount: '',
        dubberAccount: ''
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [fieldErrorMessages, setFieldErrorMessages] = useState<FieldError[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Actions
    const dispatch = useDispatch();
    const addNotification = (text: string, type: VariantType) => dispatch(appWindowAddNotification(text, type));

    useNav('hosted-pbx', 'organizations');

    useEffect(() => {
        fetchAssignment(organizationId, assignmentId);
    }, [organizationId, assignmentId]);

    useProgressEffects(
        isFetching || isFetchingOrganization || isSubmitting,
        fetchError || fetchOrganizationError
    );

    useEffect(() => {
        // Adjust form values after existing details are retrieved from the server
        if (!isFetching && assignment !== null) {
            setFormValues({
                ...assignment,
                dubberAccount: assignment.dubberAccount ? assignment.dubberAccount : ''
            });
        }
    }, [isFetching, assignment]);

    const handleCancel = useCallback(() => {
        history.push(hpbxAssignmentViewUrl(organizationId, assignmentId))
    }, []);

    const handleSubmit = () => {
        (async () => {
            setIsSubmitting(true);

            try {
                // Send the update request to the API
                await updateHostedPbxAssignment(api, organizationId, assignmentId, {
                    ...formValues,
                    platform: formValues.platform as Platform,
                    dubberAccount: (formValues.dubberAccount || '').length > 0 ? formValues.dubberAccount : null
                });

                // Create a notification about the update
                addNotification('Assignment updated.', 'success');

                // Forward back to service view page
                history.push(hpbxAssignmentViewUrl(organizationId, assignmentId));
            } catch (e) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, display field messages if they're present
                    // Else display the primary error message
                    if (e.fields && e.fields.length > 0) {
                        setErrorMessage(null);
                        setFieldErrorMessages(e.fields);
                    } else {
                        setErrorMessage(e.message);
                        setFieldErrorMessages([]);
                    }

                    setIsSubmitting(false);
                } else {
                    // For all other errors, display a generic message
                    addNotification('Unable to update assignment.', 'error');

                    setErrorMessage(null);
                    setFieldErrorMessages([]);
                    setIsSubmitting(false);
                }
            }
        })();
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX"
                        subtext={organization !== null ? organization.name : undefined}/>

            {assignment != null && organization != null &&
                <>
                    <Paper className={classes.paper}>
                        <HostedPbxFormExistingAccounts
                            organization={organization}
                            assignmentId={assignment.id}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            isSubmitting={isSubmitting}
                            errorMessage={errorMessage}
                            fieldErrorMessages={fieldErrorMessages}/>
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(HostedPbxUpdatePage, HostedPbxPolicies.CanManageAssignments);