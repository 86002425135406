import { combineReducers } from 'redux';
import { History } from 'history';
import appWindowReducer from './features/app-window/reducer';
import authReducer from './features/auth/reducer';
import { State } from './store';

export function createRootReducer(history: History) {
    return combineReducers<State>({
        appWindow: appWindowReducer,
        auth: authReducer
    });
};