import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { Assignment } from './api';
import moment = require('moment');
import { bandwidthSubAccountViewUrl } from '../../bandwidth/urls';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    assignment: Assignment;
}

/**
 * Displays a box showing basic information about a SIP Trunking assignment
 * @param props
 * @constructor
 */
const AssignmentSummary = (props: Props) => {
    const classes = useStyles();
    const { assignment } = props;

    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(assignment.bandwidthAccount, assignment.bandwidthSubAccount)} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Bandwidth Account</Typography>
                    <Typography>
                        {assignment.bandwidthAccount}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Bandwidth Sub-Account</Typography>
                    <Typography>
                        <Link component={LinkComponent}>{assignment.bandwidthSubAccount}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Service Assigned</Typography>
                    <Typography>
                        {moment(assignment.startedAt).format('MM/DD/YYYY')}
                    </Typography>
                </Grid>

                {!assignment.isActive &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Service Cancelled</Typography>
                        <Typography>
                            {moment(assignment.cancelledAt).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default AssignmentSummary;