import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { withPolicyRestriction } from '../../../auth/policies';
import { LidbOrderForm } from '../../api';
import { useFetchBandwidthNumber } from '../../hooks';
import { useCreateBandwidthLidbOrder } from '../../orders/hooks';
import { BandwidthPolicies } from '../../policies';
import { bandwidthNumberViewUrl } from '../../urls';
import { formatUsNumber } from '../helpers';
import OutboundCallerIdForm, { FormData } from './OutboundCallerIdForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for updating routing settings on a number
const RoutingUpdatePage = (props: Props) => {
    const number = String(props.match.params['number']);
    const dispatch = useDispatch();

    const classes = useStyles();
    const [fetch, isFetching, numberDetails, fetchErrorMessage] = useFetchBandwidthNumber();
    const [formValues, setFormValues] = useState<FormData>({
        name: '',
        serviceType: 'Business',
        display: 'Public'
    });

    const [originalFormValues, setOriginalFormValues] = useState<FormData>(formValues);

    const [update, isUpdating, completedOrder, updateError, updateFieldErrors] = useCreateBandwidthLidbOrder();

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        fetchErrorMessage
    );

    // Fetch number details on load
    useEffect(() => {
        fetch(number);
    }, [number]);

    // Update form when number was loaded
    useEffect(() => {
        if (numberDetails) {
            const existing: FormData = {
                name: numberDetails?.lidb?.name || '',
                serviceType: numberDetails?.lidb?.serviceType || 'Business',
                display: numberDetails?.lidb?.display === false ? 'Private' : 'Public'
            };

            setFormValues(existing);
            setOriginalFormValues(existing);
        }
    }, [numberDetails]);

    // Handle field updates
    const handleFieldChange = (fieldName: keyof FormData, value: string) => {
        let newValues = Object.assign({}, formValues);

        switch (fieldName) {
            case 'name':
                // Name can only be 15 characters long
                newValues[fieldName] = value.substring(0, 15);
                break;
            case 'serviceType':
                if (value === 'Business' || value === 'Residential') {
                    newValues[fieldName] = value;
                }
                break;
            case 'display':
                if (value === 'Public' || value === 'Private') {
                    newValues[fieldName] = value;
                }
                break;
        }

        setFormValues(newValues);
    };

    // Only allow the Update button to be active if the form has changed
    const formChanged = useMemo(() => {
        return formValues.name !== originalFormValues.name
            || formValues.display !== originalFormValues.display
            || formValues.serviceType !== originalFormValues.serviceType;
    }, [formValues]);

    // Cancel button returns to number details
    const handleCancel = useCallback(() => {
        history.push(bandwidthNumberViewUrl(number));
    }, [number]);

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Handle submit form
    const handleSubmit = useCallback(() => {
        if (numberDetails) {
            let submission: LidbOrderForm = {
                numbers: [number],
                name: formValues.name,
                display: formValues.display === 'Public',
                serviceType: formValues.serviceType
            };

            update(numberDetails.accountId, submission);
        }
    }, [formValues]);

    // Once an order is created, display a nice message and redirect back to the number details
    useEffect(() => {
        if (completedOrder) {
            dispatch(appWindowAddNotification(`Order ${completedOrder.id} created`, 'success'));
            history.push(bandwidthNumberViewUrl(number));
        }
    }, [completedOrder]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Numbers" subtext={formatUsNumber(number)} />

            {numberDetails !== null
                && <>
                    <Paper className={classes.paper}>
                        {numberDetails.lidb?.status === 'Pending' && <Typography color="secondary">Outbound Calling cannot be modified because there's currently an update processing.</Typography>}

                    {numberDetails.lidb?.status !== 'Pending' &&
                        <OutboundCallerIdForm
                            formValues={formValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                            fieldErrorMessages={updateFieldErrors}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />}
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating || !formChanged}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(RoutingUpdatePage, BandwidthPolicies.CanManageNumberLidb);