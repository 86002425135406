import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useSession } from '../../../auth/hooks';
import { authorizedFor } from '../../../auth/policies';
import AddressBlock from '../../AddressBlock';
import { useFetchBandwidthLocation, useFetchBandwidthSubAccount } from '../../hooks';
import { BandwidthPolicies } from '../../policies';
import HistoryNotesTable from '../HistoryNotesTable';
import { useFetchBandwidthPortInOrder, useTriggerBandwidthPortInOrder } from './hooks';
import NumberTable from './NumberTable';
import PortInActivationInformation from './PortInActivationInformation';
import PortInEndUserInformation from './PortInEndUserInformation';
import PortInInformation from './PortInInformation';
import PortInSummary from './PortInSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    address: {
        padding: theme.spacing(1)
    },
    summary: {
        marginBottom: theme.spacing(2)
    },
    section: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
}));

interface Props extends RouteComponentProps<any> {

}

// Page for viewing a Port-In order
const NewNumberViewPage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const session = useSession();

    const accountId = props.match.params['accountId'] || null;
    const orderId = props.match.params['orderId'] || null;

    const [fetchOrder, isFetchingOrder, order, fetchError] = useFetchBandwidthPortInOrder();
    const [fetchSubAccount, isFetchingSubAccount, subAccount, fetchSubAccountError] = useFetchBandwidthSubAccount();
    const [fetchLocation, isFetchingLocation, location, fetchLocationError] = useFetchBandwidthLocation();

    const [triggerDialogOpen, setTriggerDialogOpen] = useState(false);
    const [trigger, isTriggering, triggerComplete, triggerError] = useTriggerBandwidthPortInOrder();

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingOrder || isFetchingSubAccount || isFetchingLocation,
        fetchError || fetchSubAccountError || fetchLocationError || triggerError
    );

    // Fetch accounts on load
    useEffect(() => {
        fetchOrder(accountId, orderId);
    }, [accountId, orderId]);

    // Fetch subaccount & location when order is loaded
    useEffect(() => {
        if (order) {
            if (order.locationId != location?.id) {
                fetchSubAccount(accountId, order.subAccountId);
                fetchLocation(accountId, order.subAccountId, order.locationId);
            }
        }
    }, [order]);

    // Handle triggering of port
    const confirmTrigger = () => {
        trigger(accountId, orderId);
    };

    // Reload data when triggered
    useEffect(() => {
        if (triggerComplete) {
            dispatch(appWindowAddNotification('Port triggered.', 'success'));
            setTriggerDialogOpen(false);
            fetchOrder(accountId, orderId);
        }
    }, [triggerComplete]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Order" subtext={orderId}>
                {order?.allowTrigger && authorizedFor(BandwidthPolicies.CanTriggerNumberPort, session.roles) &&
                    <Button
                        className={classes.headerButton}
                        variant="text"
                        color="primary"
                        onClick={() => setTriggerDialogOpen(true)}>
                        Trigger Port
                    </Button>
                }
            </PageHeader>

            {!isFetchingOrder && order && !isFetchingSubAccount && subAccount && !isFetchingLocation && location &&
                <>
                    <div className={classes.summary}>
                        <PortInSummary
                            accountId={accountId}
                            order={order}
                            subAccount={subAccount}
                            location={location} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">Port Information</Typography>
                        <PortInInformation order={order} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h4">Activation Date & Time</Typography>
                        <PortInActivationInformation order={order} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h4">End-User Account Information</Typography>
                        <PortInEndUserInformation order={order} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h4">Service Address</Typography>
                        <Paper className={classes.address}>
                            <AddressBlock address={order.subscriber.serviceAddress} />
                        </Paper>
                    </div>


                    <div className={classes.section}>
                        <Typography variant="h3">Numbers</Typography>
                        <Typography variant="body1">{order.numbers.length} number{order.numbers.length != 1 ? 's' : ''}</Typography>

                        <Paper className={classes.paper}>
                            <NumberTable
                                numbers={order.numbers} />
                        </Paper>
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">History & Notes</Typography>
                        <Paper className={classes.paper}>
                            <HistoryNotesTable
                                history={order.history}
                                notes={order.notes}
                            />
                        </Paper>
                    </div>

                    <ConfirmationDialog
                        title={'Trigger Port?'}
                        message={'Are you sure you want to trigger this port?'}
                        showWorking={isTriggering}
                        isOpen={triggerDialogOpen}
                        confirmText={'Trigger'}
                        confirmColor={'primary'}
                        cancelText={'Cancel'}
                        cancelColor={'secondary'}
                        onCancel={() => setTriggerDialogOpen(false)}
                        onConfirm={() => confirmTrigger()} />
                </>
            }
        </div>
    );
};

export default NewNumberViewPage;