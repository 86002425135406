import { LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import theme from '../../../theme';
import { useProgressEffects } from '../../app-window/hooks';
import { NewNumberOrderSummary, OrderBase } from '../orders/api';
import { useFetchBandwidthNewNumberOrders, useFetchBandwidthPortInOrders } from '../orders/hooks';
import OrdersTable from './OrdersTable';
import moment = require('moment');
import { PortInOrderSummary } from '../orders/port-ins/api';

const useStyles = makeStyles(() => ({
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },

    addButton: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

interface Props {
    accountId: string;
    subAccountId: string;
    onLoaded: (locations: Array<NewNumberOrderSummary | PortInOrderSummary>) => void;
}

// Tab Pane for display New/Port numbers for a sub-account
const OrdersPane = (props: Props) => {
    const classes = useStyles();
    const { accountId, subAccountId, onLoaded } = props;
    const [fetchNewNumberOrders, isFetchingNewNumberOrders, newNumberOrders, fetchNewNumberOrdersError] = useFetchBandwidthNewNumberOrders();
    const [fetchPortInOrders, isFetchingPortInOrders, portInOrders, fetchPortInOrdersError] = useFetchBandwidthPortInOrders();

    const [page, setPage] = useState(1);

    const isFetching = isFetchingPortInOrders || isFetchingNewNumberOrders;

    useProgressEffects(
        false,
        fetchNewNumberOrdersError || fetchPortInOrdersError
    );

    useEffect(() => {
        fetchNewNumberOrders(accountId, subAccountId);
        fetchPortInOrders(accountId, subAccountId);
    }, [accountId, subAccountId]);

    const orders = useMemo(() => {
        if (isFetchingNewNumberOrders || isFetchingPortInOrders || !newNumberOrders || !portInOrders) {
            return null;
        }

        const combined: Array<NewNumberOrderSummary | PortInOrderSummary> = [];

        for (var i = 0; i < newNumberOrders.length; i++) {
            combined.push(newNumberOrders[i]);
        }

        for (var i = 0; i < portInOrders.length; i++) {
            combined.push(portInOrders[i]);
        }

        return combined.sort((a, b) => {
            return -moment(a.createDate).diff(moment(b.createDate));
        });
    }, [isFetchingNewNumberOrders, newNumberOrders, isFetchingPortInOrders, portInOrders]);

    useEffect(() => {
        if (orders !== null) {
            onLoaded(orders);
        }
    }, [orders]);

    // Paginate orders
    const pagedOrders = useMemo(() => {
        return (orders || [])
            .slice((page - 1) * DEFAULT_PAGE_SIZE, page * DEFAULT_PAGE_SIZE);
    }, [orders, page]);

    return (
        <>
            {orders && <>
                {orders.length === 0 &&
                    <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">
                            No orders found.
                        </Typography>
                    </div>
                }

                {orders.length > 0 &&
                    <>
                        <OrdersTable accountId={accountId} subAccountId={subAccountId} orders={pagedOrders} />

                        <Pagination
                            totalItems={orders.length}
                            pageSize={DEFAULT_PAGE_SIZE}
                            page={page}
                            disabled={isFetching}
                            setParams={false}
                            onChange={page => setPage(page)} />
                    </>
                }
            </>}

            {isFetching && <LinearProgress />}
        </>
    );
};

export default OrdersPane;