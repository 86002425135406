import { useEffect, useState } from "react";
import { PagedResponse, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { fetchFax2MailSeats, Seat, updateFax2MailSeats, UpdateForm } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/*
 * Hook for fetching all organizations with fax2mail service
 */

type FetchSeatsFunc = (organizationId: number, assignmentId: number) => void;

interface FetchSeatsRequest {
    organizationId: number;
    assignmentId: number;
    ts: number;
}

export const useFetchFax2MailSeats = (): TriggeredFetchHookResponse<FetchSeatsFunc, Seat[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchSeatsRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [seats, setSeats] = useState<Seat[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchSeatsFunc = (organizationId: number, assignmentId: number) => {
        setRequest({
            organizationId,
            assignmentId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setSeats(null);

                try {
                    const seats = await fetchFax2MailSeats(api, request.organizationId, request.assignmentId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setSeats(seats);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setSeats(null);
                            setError('Unable to fetch fax2mail seats.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        seats,
        error
    ];
};

/*
 * Hook for updating fax2mail seats for an assignment
 */

type UpdateSeatsFunc = (organizationId: number, assignmentId: number, form: UpdateForm) => void;

interface UpdateSeatsRequest {
    organizationId: number;
    assignmentId: number;
    form: UpdateForm;
    ts: number;
}

export const useUpdateFax2MailSeats = (): TriggeredFetchHookResponse<UpdateSeatsFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<UpdateSeatsRequest | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const submit: UpdateSeatsFunc = (organizationId: number, assignmentId: number, form: UpdateForm) => {
        setRequest({
            organizationId,
            assignmentId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsSubmitting(true);
                setIsComplete(false);

                try {
                    await updateFax2MailSeats(api, request.organizationId, request.assignmentId, request.form);

                    if (!didCancel) {
                        setIsSubmitting(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsSubmitting(false);
                            setIsComplete(false);
                            setError('Unable to update fax2mail seats.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        submit,
        isSubmitting,
        isComplete,
        error
    ];
};