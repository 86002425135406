import { useEffect, useState } from "react";
import { ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { BandwidthSubAccount, fetchWebexBandwidthSubAccounts } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/*
 * Hook for fetching all bandwidth sub-accounts within Webex bandwidth account
 */

interface FetchCustomersRequest {
    ts: number;
}

export const useFetchWebexBandwidthSubAccounts = (): TriggeredFetchHookResponse<() => void, BandwidthSubAccount[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchCustomersRequest | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [customers, setCustomers] = useState<BandwidthSubAccount[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = () => {
        setRequest({
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setCustomers(null);

                try {
                    const customers = await fetchWebexBandwidthSubAccounts(api);

                    if (!didCancel) {
                        setIsFetching(false);
                        setCustomers(customers);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setCustomers(null);
                            setError('Unable to fetch bandwidth sub-accounts.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        customers,
        error
    ];
};