import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { withPolicyRestriction } from '../../../auth/policies';
import { Direction } from '../../api';
import { useFetchBandwidthNumber } from '../../hooks';
import { useCreateBandwidthE911Order } from '../../orders/hooks';
import { BandwidthPolicies } from '../../policies';
import { bandwidthNumberViewUrl } from '../../urls';
import { formatUsNumber } from '../helpers';
import E911Form, { FormAddressData, FormData } from './E911Form';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for updating E911 settings on a number
const E911UpdatePage = (props: Props) => {
    const number = String(props.match.params['number']);
    const dispatch = useDispatch();

    const classes = useStyles();
    const [fetch, isFetching, numberDetails, fetchErrorMessage] = useFetchBandwidthNumber();

    const [formValues, setFormValues] = useState<FormData>({
        callerName: '',
        address: {
            housePrefix: '',
            houseNumber: '',
            houseSuffix: '',
            preDirectional: '',
            streetName: '',
            streetSuffix: '',
            postDirectional: '',
            addressLine2: '',
            city: '',
            stateCode: '',
            zip: '',
            plusFour: ''
        }
    });

    const [originalFormValues, setOriginalFormValues] = useState<FormData>(formValues);

    const [update, isUpdating, completedOrder, updateError, updateFieldErrors] = useCreateBandwidthE911Order();

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        fetchErrorMessage
    );

    // Fetch number details on load
    useEffect(() => {
        fetch(number);
    }, [number]);

    // Update form when number was loaded
    useEffect(() => {
        if (numberDetails) {
            const existing: FormData = {
                callerName: numberDetails?.e911?.name || '',
                address: {
                    housePrefix: numberDetails?.e911?.address?.housePrefix || '',
                    houseNumber: numberDetails?.e911?.address?.houseNumber || '',
                    houseSuffix: numberDetails?.e911?.address?.houseSuffix || '',
                    preDirectional: numberDetails?.e911?.address?.preDirectional || '',
                    streetName: numberDetails?.e911?.address?.streetName || '',
                    streetSuffix: numberDetails?.e911?.address?.streetSuffix || '',
                    postDirectional: numberDetails?.e911?.address?.postDirectional || '',
                    addressLine2: numberDetails?.e911?.address?.addressLine2 || '',
                    city: numberDetails?.e911?.address?.city || '',
                    stateCode: numberDetails?.e911?.address?.stateCode || '',
                    zip: numberDetails?.e911?.address?.zip || '',
                    plusFour: numberDetails?.e911?.address?.plusFour || ''
                }
            };

            setFormValues(existing);
            setOriginalFormValues(existing);
        }
    }, [numberDetails]);

    // Handle field updates
    const handleFieldChange = (fieldName: keyof FormData, value: string) => {
        let newValues = Object.assign({}, formValues);

        switch (fieldName) {
            case 'callerName':
                newValues[fieldName] = value;
                break;
        }

        setFormValues(newValues);
    };

    // Handle updates to address field
    const handleAddressFieldChange = (fieldName: keyof FormAddressData, value: string | Direction) => {
        let newBase = Object.assign({}, formValues);
        let newAddress = Object.assign({}, formValues.address);

        switch (fieldName) {
            case 'preDirectional':
            case 'postDirectional':
                newAddress[fieldName] = (value === '' ? '' : value as Direction);
                break;
            default:
                newAddress[fieldName] = value;
        }

        newBase.address = newAddress;

        setFormValues(newBase);
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Only allow the Update button to be active if the form has changed
    const formChanged = useMemo(() => {
        return formValues.callerName !== originalFormValues.callerName
            || formValues.address.addressLine2 !== originalFormValues.address.addressLine2
            || formValues.address.city !== originalFormValues.address.city
            || formValues.address.houseNumber !== originalFormValues.address.houseNumber
            || formValues.address.housePrefix !== originalFormValues.address.housePrefix
            || formValues.address.houseSuffix !== originalFormValues.address.houseSuffix
            || formValues.address.plusFour !== originalFormValues.address.plusFour
            || formValues.address.postDirectional !== originalFormValues.address.postDirectional
            || formValues.address.preDirectional !== originalFormValues.address.preDirectional
            || formValues.address.stateCode !== originalFormValues.address.stateCode
            || formValues.address.streetName !== originalFormValues.address.streetName
            || formValues.address.streetSuffix !== originalFormValues.address.streetSuffix
            || formValues.address.zip !== originalFormValues.address.zip;
    }, [formValues, originalFormValues]);

    // Cancel button returns to number details
    const handleCancel = useCallback(() => {
        history.push(bandwidthNumberViewUrl(number));
    }, [number]);

    // Handle submit form
    const handleSubmit = useCallback(() => {
        if (numberDetails) {
            update(numberDetails.accountId, {
                numbers: [number],
                callerName: formValues.callerName,
                address: {
                    housePrefix: formValues.address.housePrefix,
                    houseNumber: formValues.address.houseNumber,
                    houseSuffix: formValues.address.houseSuffix,
                    preDirectional: formValues.address.preDirectional as Direction,
                    streetName: formValues.address.streetName,
                    streetSuffix: formValues.address.streetSuffix,
                    postDirectional: formValues.address.postDirectional as Direction,
                    addressLine2: formValues.address.addressLine2,
                    city: formValues.address.city,
                    stateCode: formValues.address.stateCode,
                    zip: formValues.address.zip,
                    plusFour: formValues.address.plusFour
                }
            });
        }
    }, [formValues]);

    // Once an order is created, display a nice message and redirect back to the number details
    useEffect(() => {
        if (completedOrder) {
            dispatch(appWindowAddNotification(`Order ${completedOrder.id} created`, 'success'));
            history.push(bandwidthNumberViewUrl(number));
        }
    }, [completedOrder]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Numbers" subtext={formatUsNumber(number)} />

            {numberDetails !== null
                && <>
                <Paper className={classes.paper}>
                    {numberDetails.e911?.status === 'Pending' && <Typography color="secondary">E911 cannot be modified because there's currently an update processing.</Typography>}

                    {numberDetails.e911?.status !== 'Pending' &&
                        <E911Form
                            formValues={formValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                            fieldErrorMessages={updateFieldErrors}
                            onFieldChange={handleFieldChange}
                            onAddressFieldChange={handleAddressFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />}
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating || !formChanged}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(E911UpdatePage, BandwidthPolicies.CanManageNumberE911);