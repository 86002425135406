import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useCreateBandwidthMoveNumberOrder, useFetchBandwidthAccounts, useFetchBandwidthNumber } from '../../hooks';
import { bandwidthNumberMoveUrl, bandwidthNumberViewUrl, bandwidthOrderIndexUrl } from '../../urls';
import history from '../../../../history';
import MoveNumberForm, { FormData } from './MoveNumberForm';
import { CreateBandwidthMoveNumberOrderForm } from '../../api';
import { BandwidthPolicies } from '../../policies';
import { authorizedFor, withPolicyRestriction } from '../../../auth/policies';
import { formatUsNumber } from '../helpers';
import { Number } from '../../numbers/api';
import { Account } from '../../accounts/api';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    },
    currentDetails: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for updating a number to move it to another location
const MoveNumberPage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [fetch, isFetching, numberDetails, fetchErrorMessage] = useFetchBandwidthNumber();
    const [fetchAccounts, isFetchingAccounts, accounts, fetchAccountsErrorMessage] = useFetchBandwidthAccounts();
    const [create, isCreating, createdOrder, createOrderErrorMessage, createOrderFieldErrorMessages] = useCreateBandwidthMoveNumberOrder();

    // Update navigation
    useNav('bandwidth', 'orders');

    const number = String(props.match.params['number']);

    const [formValues, setFormValues] = useState<FormData>({
        targetAccountId: null,
        subAccountId: null,
        locationId: null
    });

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingAccounts,
        fetchErrorMessage || fetchAccountsErrorMessage
    );

    // Fetch all accounts & number details on load
    useEffect(() => {
        fetchAccounts();
        fetch(number);
    }, [number]);

    // Update form data once number is loaded with its current data
    useEffect(() => {
        if (numberDetails) {
            setFormValues({
                targetAccountId: numberDetails.accountId,
                subAccountId: numberDetails.subAccountId,
                locationId: numberDetails.locationId
            });
        }
    }, [numberDetails]);

    // Handle field updates
    const handleFieldChange = (fieldName: keyof FormData, value: string | null) => {
        let newValues = Object.assign({}, formValues);

        switch (fieldName) {
            case 'targetAccountId':
                newValues = {
                    targetAccountId: value,
                    subAccountId: null,
                    locationId: null
                };
                break;
            case 'subAccountId':
                newValues = {
                    ...newValues,
                    subAccountId: value,
                    locationId: null
                };
                break;

            case 'locationId':
                newValues = {
                    ...newValues,
                    locationId: value
                };
                break;
        }

        setFormValues(newValues);
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    const allowSubmit = useMemo(() => {
        if (!numberDetails) {
            return false;
        }

        if (!formValues.targetAccountId || !formValues.subAccountId || !formValues.locationId) {
            return false;
        }

        return formValues.locationId != numberDetails.locationId;
    }, [numberDetails, formValues]);

    const handleSubmit = useCallback(() => {
        if (formValues.targetAccountId && formValues.subAccountId && formValues.locationId && numberDetails) {
            var submission: CreateBandwidthMoveNumberOrderForm = {
                numbers: [number],
                accountId: formValues.targetAccountId,
                subAccountId: formValues.subAccountId,
                locationId: formValues.locationId,
                remove911: false
            };

            // Source account should only be set if moving numbers from one account to another
            if (formValues.targetAccountId != numberDetails.accountId) {
                submission.sourceAccountId = numberDetails.accountId;
            }

            create(submission);
        }

    }, [formValues]);

    const handleCancel = () => {
        // Back to number details on cancel
        history.push(bandwidthNumberViewUrl(number));
    };

    // Once order is submitted, return to number details
    useEffect(() => {
        if (createdOrder) {
            dispatch(appWindowAddNotification(`Order ${createdOrder.id} created`, 'success'));
            history.push(bandwidthNumberViewUrl(number));
        }
    }, [createdOrder]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Number" subtext={'Move ' + formatUsNumber(number)} />

            {!isFetchingAccounts && accounts && numberDetails &&
                <>
                <CurrentDetails accounts={accounts} number={numberDetails} />

                    <Paper className={classes.paper}>
                        <MoveNumberForm
                            formValues={formValues}
                            accountOptions={accounts}
                            isSubmitting={isCreating}
                            errorMessage={createOrderErrorMessage}
                            fieldErrorMessages={createOrderFieldErrorMessages}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isCreating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isCreating || !allowSubmit}
                            onClick={handleSubmit}>Move</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

const CurrentDetails = (props: { accounts: Account[], number: Number }) => {
    const { accounts, number } = props;
    const classes = useStyles();

    const account = accounts.find(a => a.id == number.accountId);
    const accountName = account
        ? account.name
        : number.accountId;

    return (
        <Paper className={classes.currentDetails}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>{accountName}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Sub-Account</Typography>
                    <Typography>{number.subAccountName}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Location</Typography>
                    <Typography>{number.locationName}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withPolicyRestriction(MoveNumberPage, BandwidthPolicies.CanMoveNumbers);