import { ApiCaller } from '../../api';

export * from './assignment/api';

/*
 * GET /api/direct-routing/ipilot-customers
 *
 * Gets all customers in iPilot
 */

export interface Customer {
    id: string;
    name: string;
    subdomain: string;
}

export const fetchDirectRoutingIPilotCustomers = async (api: ApiCaller): Promise<Customer[]> => {
    return await api<Customer[]>(`direct-routing/ipilot-customers`, 'GET');
};