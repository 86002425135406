import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NewNumberOrderSummary } from '../orders/api';
import { orderStatusDisplay, orderTypeDisplay } from '../orders/helpers';
import { PortInOrderSummary } from '../orders/port-ins/api';
import { bandwidthOrderViewUrl } from '../urls';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

interface Props {
    accountId: string;
    subAccountId: string;
    orders: Array<NewNumberOrderSummary | PortInOrderSummary>;
}

/**
 * Table for displaying all orders under a sub-account
 * @param props
 */
const OrdersTable = (props: Props) => {
    const { accountId, subAccountId, orders } = props;
    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Customer Order ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Numbers</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orders.map(order => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthOrderViewUrl(order.type, order.accountId, order.id)} {...props} />);

                    const createDate = moment(order.createDate).format('MM/DD/YYYY hh:mm A');

                    return (
                        <TableRow key={order.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{order.id}</Link>
                            </TableCell>
                            <TableCell>{orderTypeDisplay(order.type)}</TableCell>
                            <TableCell>{order.customerOrderId}</TableCell>
                            <TableCell>{orderStatusDisplay(order.status)}</TableCell>
                            <TableCell className={classes.noWrap}>{createDate}</TableCell>
                            <TableCell className={classes.noWrap}>{order.numberCount}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default OrdersTable;