import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import AddressBlock from '../../AddressBlock';
import { E911 } from '../api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    e911: E911;
}

// E911 Summary Box
const E911Summary = (props: Props) => {
    const classes = useStyles();
    const { e911 } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Name</Typography>
                    <Typography>
                        {e911.name}
                    </Typography>
                </Grid>

                {e911.address !== null &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Address</Typography>
                        <Typography>
                            <AddressBlock address={e911.address} />
                        </Typography>
                    </Grid>
                }
            </Grid>

            {e911.isAdjusted && <>
                <Typography variant="body2" color="secondary">The E911 address above has been adjusted to reflect a registered service address.<br/>The address should be resubmitted to remove this warning message.</Typography>
            </>}
        </Paper>
    );
}

export default E911Summary;