import { ApiCaller, PagedResponse } from '../../../api';
import { Platform } from '../api';

/*
 * GET /api/hosted-pbx/numbers
 *
 * Get assigned number counts for all groups
 */

export interface NumberCount {
    groupId: string;
    count: number;
}

export const fetchNumberCounts = async (api: ApiCaller, platform: Platform): Promise<NumberCount[]> => {
    return await api<NumberCount[]>(`hosted-pbx/numbers`, 'GET', {
        platform
    });
};

/*
 * GET /api/hosted-pbx/numbers/:groupId
 *
 * Get assigned number counts for all groups
 */

export interface Number {
    number: string;
    isAssigned: boolean;
    isActivated: boolean;
}

export const fetchNumbers = async (api: ApiCaller, platform: Platform, groupId: string, digits?: string, isAssigned?: boolean, page: number = 1, limit?: number): Promise<PagedResponse<Number>> => {
    return await api<PagedResponse<Number>>(`hosted-pbx/numbers/${groupId}`, 'GET', {
        platform,
        page,
        limit,
        isAssigned,
        digits
    });
};

/*
 * POST /api/hosted-pbx/numbers/:groupId
 * 
 * Add numbers to the given group
 */

export const addNumbers = async (api: ApiCaller, platform: Platform, groupId: string, numbers: string[]) => {
    return await api(`hosted-pbx/numbers/${groupId}`, 'POST', {
        platform,
        numbers
    });
};

/*
 * DELETE /api/hosted-pbx/numbers/:groupId
 * 
 * Deletes numbers from the given group
 */

export const removeNumbers = async (api: ApiCaller, platform: Platform, groupId: string, numbers: string[]) => {
    return await api(`hosted-pbx/numbers/${groupId}`, 'DELETE', {
        platform,
        numbers
    });
};