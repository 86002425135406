import { FormControl, Grid, Input, InputLabel, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    name: string;
    description: string;
}

interface Props {
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean) => void;
    onEnter: () => void;
}

const TeamCreateForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, onEnter, } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onEnter();
        }
    };

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('name')}>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            autoFocus
                            disabled={isSubmitting}
                            id="name"
                            value={formValues.name}
                            onChange={evt => onUpdate('name', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="name" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('description')}>
                        <InputLabel htmlFor="description">Description</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="description"
                            value={formValues.description}
                            onChange={evt => onUpdate('description', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="description" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default TeamCreateForm;