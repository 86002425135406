import { ApiCaller } from '../../../api';

export interface Account {
    id: string;
    name: string;
    type: AccountType;
    service: ServiceType;
    features: Feature[];
    originationHosts: string[];
    terminationHosts: string[];
}

export type AccountType = 'UC' | 'UCImplied' | 'Wholesale';

export type ServiceType = 'BroadCloudPaas' | 'SipTrunking' | 'WebexWholesale';

export type Feature = 'E911';

/**
 * GET /bandwidth
 * 
 * Fetch Bandwidth accounts
 * 
 * @param api
 */
export const fetchBandwidthAccounts = async (api: ApiCaller): Promise<Account[]> => {
    return await api<Account[]>(`bandwidth`, 'GET');
};

/**
 * GET /bandwidth/{accountId}
 * 
 * Fetch Bandwidth account details
 * 
 * @param api
 */
export const fetchBandwidthAccount = async (api: ApiCaller, accountId: string): Promise<Account> => {
    return await api<Account>(`bandwidth/${accountId}`, 'GET');
};