import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { OrderBase } from '../api';
import { useFetchBandwidthAccount, useFetchBandwidthNewNumberOrders } from '../hooks';
import { Location } from '../locations/api';
import { SubAccountNumbers } from './api';
import { useFetchBandwidthSubAccount } from './hooks';
import LocationsPane from './LocationsPane';
import NumbersPane from './NumbersPane';
import SubAccountSummary from './SubAccountSummary';
import OrdersPane from './OrdersPane';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    tabPaperContents: {
    },
    summary: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    }
}));

interface Props extends RouteComponentProps<any> {
}

type TabValue = 'locations' | 'numbers' | 'orders';

// Page for view a sub-account's details
const SubAccountViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();

    const [fetchAccount, isFetchingAccount, account, fetchAccountErrorMessage] = useFetchBandwidthAccount();
    const [fetch, isFetching, subaccount, fetchErrorMessage, fetchErrorCode] = useFetchBandwidthSubAccount();

    const [activeTab, setActiveTab] = useState<TabValue>('locations');
    const [locations, setLocations] = useState<Location[] | null>(null);
    const [numbers, setNumbers] = useState<SubAccountNumbers | null>(null);
    const [orders, setOrders] = useState<OrderBase[] | null>(null);

    const accountId = props.match.params['accountId'];
    const subAccountId = props.match.params['subAccountId'];

    // Update navigation
    useNav('bandwidth', 'sub-accounts');

    // Don't display an error toast if sub-account doesn't exist
    const fetchErrorToast = fetchErrorCode !== 404 ? fetchErrorMessage : null;

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingAccount,
        fetchErrorToast || fetchAccountErrorMessage
    );

    // Fetch account & sub-account details, locations, numbers on load
    useEffect(() => {
        fetchAccount(accountId);
        fetch(accountId, subAccountId);
    }, [accountId, subAccountId]);

    const totalNumbers = useMemo(() => {
        if (numbers) {
            let total = 0;

            numbers.locations.forEach(l => {
                total += l.numbers.length;
            });

            return total;
        } else {
            return null;
        }
    }, [numbers]);


    const handleTabChange = (_: React.ChangeEvent<{}>, value: TabValue) => {
        setActiveTab(value);
    };

    return (
        <div className={classes.root}>

            {!isFetching && <PageHeader text="Bandwidth Sub-Account" subtext={subaccount?.name || subAccountId} />}

            {fetchErrorCode === 404 && <Typography>Sub-Account no longer exists.</Typography>}

            {!isFetching && account && subaccount !== null &&
                <>
                    <div className={classes.summary}>
                        <SubAccountSummary subaccount={subaccount} />
                    </div>

                    <Paper className={classes.paper}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={activeTab} onChange={handleTabChange}>
                                <Tab value="locations" label={locations ? `Locations (${locations.length})` : "Locations"} />
                                <Tab value="numbers" label={numbers ? `Numbers (${totalNumbers})` : "Numbers"} />
                                <Tab value="orders" label={orders ? `Orders (${orders.length})` : "Orders"} />
                            </Tabs>
                        </Box>

                        <div className={classes.tabPaperContents}>
                            <div style={{ display: activeTab !== 'locations' ? "none" : "block" }}>
                                <LocationsPane account={account} subAccountId={subAccountId} onLoaded={l => setLocations(l)} />
                            </div>

                            <div style={{ display: activeTab !== 'numbers' ? "none" : "block" }}>
                                <NumbersPane accountId={accountId} subAccountId={subAccountId} onLoaded={n => setNumbers(n)} />
                        </div>

                        <div style={{ display: activeTab !== 'orders' ? "none" : "block" }}>
                                <OrdersPane accountId={accountId} subAccountId={subAccountId} onLoaded={o => setOrders(o)} />
                            </div>
                        </div>
                    </Paper>
                </>
            }
        </div>
    );
};

export default SubAccountViewPage;