import { Search as SearchIcon } from '@mui/icons-material';
import {
    AppBar,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    block: {
        display: 'block',
    }
}));

interface Props {
    //accountOptions: Account[];
    //accountId: string;
    search: string;
    limit: number;
    onSubmitSearch: (search: string, limit: number) => void;
}

/**
 * Displays the top portion of the Numbers table, which includes a search bar
 */
export default function NumberSearchBar(props: Props) {
    const classes = useStyles();
    const { onSubmitSearch, search, limit } = props; 

    // The value of the search & limit fields are tracked internally but defaults to what's in the query string
    const [searchValue, setSearchValue] = useState(search);
    const [limitValue, setLimitValue] = useState(limit || DEFAULT_PAGE_SIZE);
    const [searchValueError, setSearchValueError] = useState<string | null>(null);

    // Update internal form state if props change
    useEffect(() => {
        setSearchValue(search);
        setLimitValue(limit || DEFAULT_PAGE_SIZE);
    }, [search, limit]);

    const submit = () => {
        // Ensure search value is valid
        // It can be only 3, 6, 7, or 10 digits long since this is what BW supports
        // Also it can be empty
        if ([0, 3, 6, 7, 10].indexOf(searchValue.length) > -1) {
            onSubmitSearch(searchValue, limitValue);
            setSearchValueError(null);
        } else {
            setSearchValueError('Search can only be 3, 6, 7, or 10 digits long.');
        }
    }

    function handleKeypress(evt: React.KeyboardEvent) {
        if (evt.key === 'Enter') {
            submit();
        }
    }

    const handleSearchChange = (value: string) => {
        // Remove any non-numeric character from search string
        value = ((value || '').replace(/[^0-9]/, '')).trim();

        // Strip any 1 prefixes
        value = ((value || '').replace(/^1+/, '')).trim();

        setSearchValue(value);
    };

    return (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <SearchIcon className={classes.block} color="inherit" />
                    </Grid>

                    <Grid item xs>
                        <TextField
                            fullWidth
                            placeholder="Search numbers (NPA, NPANXX, NPANXXXXXX)"
                            variant="standard"
                            value={searchValue || ''}
                            onChange={evt => handleSearchChange(evt.target.value)}
                            onKeyPress={handleKeypress}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />

                        {searchValueError && <FormHelperText error>{searchValueError}</FormHelperText>}
                    </Grid>

                    <Grid item xs>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="per-page">Per Page</InputLabel>
                            <Select
                                fullWidth
                                value={limitValue}
                                displayEmpty={true}
                                disableUnderline
                                input={<Input name="per-page" id="per-page" />}
                                onKeyPress={handleKeypress}
                                onChange={evt => setLimitValue(parseInt(String(evt.target.value)))}>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" onClick={submit}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}