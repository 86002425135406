import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldError } from '../../../api';
import HostedPbxFormExistingAccounts, {
    formComplete as formCompleteExistingAccounts,
    FormValues as FormValuesExistingAccounts
} from '../../hosted-pbx/assignment/HostedPbxFormExistingAccounts';

import HostedPbxFormCreateAccounts, {
    formComplete as formCompleteCreateAccounts,
    FormValues as FormValuesCreateAccounts
} from '../../hosted-pbx/assignment/HostedPbxFormCreateAccounts';
import { makeStyles } from "@mui/styles";
import { OrganizationDetails } from '../api';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    onReady: (ready: boolean, values: { [key: string]: string | boolean | number | number[] | undefined | null }) => void;
    submitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
}

/**
 * Step 2 of the service assignment wizard when Hosted PBX service is selected.
 *
 * @param props
 * @constructor
 */
const HostedPbxStep = (props: Props) => {
    const { organization, onReady, submitting, errorMessage, fieldErrorMessages } = props;
    const classes = useStyles();
    const [useExisting, setUseExisting] = useState<boolean>(false);

    const [existingAccountsFormValues, setExistingAccountsFormValues] = useState<FormValuesExistingAccounts>({
        platform: 'Averistar',
        broadworksServiceProvider: '',
        broadworksGroup: '',
        bandwidthSubAccount: '',
        dubberAccount: ''
    });

    const [createAccountsFormValues, setCreateAccountsFormValues] = useState<FormValuesCreateAccounts>({
        createDubberAccount: false,
        platform: 'Averistar',
        timeZone: '',
        accountName: '',
        houseNumber: '',
        streetName: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    });

    // If all values are filled out, then the Finish button becomes active.
    // Pressing the Finish button executes the function we pass to OnCanFinish
    useEffect(() => {
        if (useExisting) {
            if (formCompleteExistingAccounts(organization, existingAccountsFormValues)) {
                onReady(true, {
                    useExisting: true,
                    platform: existingAccountsFormValues.platform,
                    broadworksServiceProvider: existingAccountsFormValues.broadworksServiceProvider,
                    broadworksGroup: existingAccountsFormValues.broadworksGroup,
                    bandwidthSubAccount: existingAccountsFormValues.bandwidthSubAccount,
                    dubberAccount: (existingAccountsFormValues.dubberAccount ? existingAccountsFormValues.dubberAccount : null)
                });
            } else {
                onReady(false, {});
            }
        } else {
            if (formCompleteCreateAccounts(organization, createAccountsFormValues)) {
                onReady(true, {
                    useExisting: false,
                    ...createAccountsFormValues
                });
            } else {
                onReady(false, {});
            }
        }
    }, [useExisting, existingAccountsFormValues, createAccountsFormValues]);

    return (
        <>
            <Typography variant="h4">Hosted PBX</Typography>

            <FormControl className={classes.formControl} fullWidth required>
                <RadioGroup name="useExisting" value={useExisting ? '1' : '0'} onChange={(evt, val) => setUseExisting(val === '1')}>
                    <FormControlLabel
                        value="0"
                        control={<Radio />}
                        disabled={submitting}
                        label={"Create New Bandwidth & BroadWorks Accounts"}
                    />

                    <FormControlLabel
                        value="1"
                        control={<Radio />}
                        disabled={submitting}
                        label={"Use Existing Bandwidth & BroadWorks Accounts"}
                    />
                </RadioGroup>
            </FormControl>

            {useExisting
                ? <HostedPbxFormExistingAccounts
                    organization={organization}
                    formValues={existingAccountsFormValues}
                    setFormValues={setExistingAccountsFormValues}
                    isSubmitting={submitting}
                    errorMessage={errorMessage}
                    fieldErrorMessages={fieldErrorMessages}
                />
                : <HostedPbxFormCreateAccounts
                    organization={organization}
                    formValues={createAccountsFormValues}
                    isSubmitting={submitting}
                    errorMessage={errorMessage}
                    fieldErrorMessages={fieldErrorMessages}
                    setFormValues={setCreateAccountsFormValues} />
            }
        </>
    );
};

export default HostedPbxStep;