import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { withPolicyRestriction } from '../../../auth/policies';
import { Product } from '../../constants';
import { productOptionsForDubPoint } from '../../helpers';
import { useFetchDubberAccounts } from '../../hooks';
import { DubberPolicies } from '../../policies';
import { dubberDubPointViewUrl, dubberUnidentifiedDubPointViewUrl } from '../../urls';
import DubPointServiceSection from '../DubPointServiceSection';
import { useAssignUnidentifiedDubberDubPoint, useFetchDubberUnidentifiedDubPoint } from '../hooks';
import UnidentifiedDubPointSummary from './UnidentifiedDubPointSummary';
import UnidentifiedDubPointUpdateForm, { FormValues } from './UnidentifiedDubPointUpdateForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const UnidentifiedDubPointUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const dubPointId = props.match.params['dubPointId'];

    const [fetchAccounts, isFetchingAccounts, accounts, fetchAccountsError] = useFetchDubberAccounts();
    const [fetchDubPoint, isFetchingDubPoint, dubPoint, fetchDubPointError] = useFetchDubberUnidentifiedDubPoint();

    const [updateDubPoint, isUpdatingDubPoint, dubPointUpdated, updateDubPointError, updateDubPointFieldErrors] = useAssignUnidentifiedDubberDubPoint();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [formValues, setFormValues] = useState<FormValues>({
        accountId: '',
        userId: '',
        product: '',
        retainUnidentifiedRecordings: true
    });

    useNav('dubber', 'unidentified-dub-points');

    useProgressEffects(
        isFetchingAccounts || isFetchingDubPoint,
        fetchAccountsError || fetchDubPointError || updateDubPointError
    );

    useEffect(() => {
        fetchAccounts(groupId);
        fetchDubPoint(groupId, dubPointId);
    }, [groupId]);

    // Determine service & product options based off dub point details
    const products = useMemo(() => {
        let options: Product[] = [];

        if (dubPoint) {
            options = productOptionsForDubPoint(dubPoint);
        }

        return options;
    }, [dubPoint]);

    const handleUpdate = (field: keyof FormValues, value: string | boolean) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Pressing cancel goes back to the dub point view
    const handleCancel = () => {
        history.push(dubberUnidentifiedDubPointViewUrl(groupId, dubPointId));
    };

    const handleSubmit = () => {
        updateDubPoint(groupId, formValues.accountId, {
            ...formValues,
            dubPointId
        })
    };

    // Redirect to view on update
    useEffect(() => {
        if (dubPointUpdated) {
            dispatch(appWindowAddNotification('Dub Point assigned.', 'success'));
            history.push(dubberDubPointViewUrl(groupId, formValues.accountId, dubPointUpdated.id));
        }
    }, [dubPointUpdated]);

    return (
        <div className={classes.root}>
            {accounts && dubPoint &&
                <>
                    <PageHeader text="Assign Dub Point" subtext={dubPointId} />

                    <UnidentifiedDubPointSummary
                        groupId={groupId}
                        dubPoint={dubPoint} />

                    <DubPointServiceSection dubPoint={dubPoint} />

                    <Paper className={classes.paper}>
                        <UnidentifiedDubPointUpdateForm
                            groupId={groupId}
                            accountOptions={accounts}
                            productOptions={products}
                            formValues={formValues}
                            isSubmitting={isUpdatingDubPoint}
                            onUpdate={handleUpdate}
                            onLoading={setIsLoading}
                            onEnter={() => handleSubmit()}
                            errorMessage={updateDubPointError}
                            fieldErrorMessages={updateDubPointFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdatingDubPoint || isLoading}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdatingDubPoint || isLoading || !formValues.accountId}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(UnidentifiedDubPointUpdatePage, DubberPolicies.CanManageDubPoints);