import { ApiCaller } from '../../../../api';
import { OrderBase, OrderHistory, OrderNote } from '../api';

export interface LidbOrderListItem extends OrderBase {
    type: 'Lidb';
    lastModifiedDate: string;
    numberCount: number;
}

export interface LidbOrder extends OrderBase {
    type: 'Lidb';
    lastModifiedDate: string;
    groups: LidbOrderGroup[];
    errors: LidbError[];
    history: OrderHistory[];
    notes: OrderNote[];
}

export interface LidbOrderGroup {
    numbers: string[];
    name: string;
    serviceType: 'Business' | 'Residential';
    display: boolean;
}

export interface LidbError {
    code: string;
    description: string;
    number: string;
}

export interface LidbOrderForm {
    numbers: string[];
    name: string;
    serviceType: 'Business' | 'Residential';
    display: boolean;
}

/**
 * GET /bandwidth/{accountId}/orders/lidbs
 * 
 * Get all LIDB Orders for an account.
 * A number can be specified to get the orders that include that number
 * 
 * @param api
 * @param accountId
 * @param number
 */
export const fetchBandwidthLidbOrders = async (api: ApiCaller, accountId: string, number?: string): Promise<LidbOrderListItem[]> => {
    return await api<LidbOrderListItem[]>(`bandwidth/${accountId}/orders/lidbs`, 'GET', {
        number
    });
};

/**
 * GET /bandwidth/{accountId}/orders/lidbs/{orderId}
 * 
 * Get a specific LIDB Order
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthLidbOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<LidbOrder> => {
    return await api<LidbOrder>(`bandwidth/${accountId}/orders/lidbs/${orderId}`, 'GET');
};

/**
 * POST /bandwidth/{accountId}/orders/lidbs
 * 
 * Create a new LIDB Order
 * 
 * @param api
 * @param accountId
 * @param form
 */
export const createBandwidthLidbOrder = async (api: ApiCaller, accountId: string, form: LidbOrderForm): Promise<LidbOrder> => {
    return await api<LidbOrder>(`bandwidth/${accountId}/orders/lidbs`, 'POST', {
        ...form
    });
};
