import { ApiCaller } from '../../api';
import { AdministratorRole } from '../administrators/api';

export interface UserProfile {
    firstName: string;
    lastName: string;
    email: string;
    roles: AdministratorRole[];
    authenticatorConfigured: boolean;
}

// Fetch current user profile
export const fetchUserProfile = async (api: ApiCaller): Promise<UserProfile> => {
    return await api<UserProfile>('user-profile', 'GET');
};

export interface UserProfileUpdate {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
}

// Update user profile
export const updateUserProfile = async (api: ApiCaller, update: UserProfileUpdate): Promise<UserProfile> => {
    return await api<UserProfile>('user-profile', 'PUT', update);
};

// Reset Authenticator for current user
export const deleteAuthenticator = async (api: ApiCaller): Promise<{}> => {
    return await api<{}>('user-profile/authenticator', 'DELETE');
};

export interface AuthenticatorSetup {
    qr: string;
    code: string;
}

// Setup Authenticator for current user
export const setupAuthenticator = async (api: ApiCaller): Promise<AuthenticatorSetup> => {
    return await api<AuthenticatorSetup>('user-profile/authenticator-setup', 'POST');
};

// Confirm Authenticator for current user
export const confirmAuthenticator = async (api: ApiCaller, passcode: string): Promise<{}> => {
    return await api<{}>('user-profile/authenticator-confirm', 'POST', {
        passcode
    });
};