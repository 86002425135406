export const SIP_TRUNKING_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/sip-trunking/:assignmentId';
export const SIP_TRUNKING_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/sip-trunking/:assignmentId/update';

export const SIP_TRUNKING_ORGANIZATION_INDEX_URL = '/sip-trunking/organizations';

export const sipTrunkingAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    SIP_TRUNKING_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const sipTrunkingAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    SIP_TRUNKING_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const sipTrunkingOrganizationIndexUrl = () => SIP_TRUNKING_ORGANIZATION_INDEX_URL;