import * as React from 'react';
import { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import history from '../../history';
import { userProfileUrl } from '../user-profile/urls';

interface Props {
    onLogout: () => void;
}

/**
 * User menu appears in the top-right of the app bar.
 * The menu includes links to adjust the user's profile or logout
 *
 * @param props
 * @constructor
 */
export function UserHeaderMenu(props: Props) {
    const { onLogout } = props;
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement|null>(null);
    const open = menuAnchor !== null;

    return (
        <div>
            <IconButton
                aria-owns={open ? 'nav-menu-user' : undefined}
                aria-haspopup='true'
                onClick={evt => setMenuAnchor(evt.currentTarget)}
                color="inherit">
                <AccountCircle />
            </IconButton>

            <Menu
                id='nav-menu-user'
                anchorEl={menuAnchor}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setMenuAnchor(null)}>
                <MenuItem onClick={() => { setMenuAnchor(null);  history.push(userProfileUrl()) }}>View Profile</MenuItem>
                <Divider/>
                <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
        </div>
    );
}