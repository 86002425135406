import { Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { loginUrl } from '../../auth/urls';
import { fetchHostedPbxOrganizations, HostedPbxOrganization, Status } from '../api';
import OrganizationTable from './OrganizationTable';
import SearchBar from './SearchBar';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    search?: string;
    status?: string;
    page?: number;
    limit?: number;
}

interface Page {
    current: number;
    size: number;
    total: number;
}

type UseHpbxOrganizationListFetchResult = [
    boolean,
    Array<HostedPbxOrganization> | null,
    Page | null,
    string | null
];

export const useHpbxOrganizationListFetch = (search: string, status: Status = 'active', pageNumber: number = 1, limit: number | undefined): UseHpbxOrganizationListFetchResult => {
    const api = useApi();

    // Indicates that we're currently fetching items from the server
    const [fetching, setFetching] = useState(false);

    // The list of organizations retrieved
    const [items, setItems] = useState<Array<HostedPbxOrganization> | null>(null);

    // Details about the page returned by the server
    const [page, setPage] = useState<Page | null>(null);

    // Error message returned by the server
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setFetching(true);

            try {
                const response = await fetchHostedPbxOrganizations(api, search, status, pageNumber, limit);
                if (!didCancel) {
                    setItems(response.items);

                    setPage({
                        current: response.page,
                        size: response.pageSize,
                        total: response.totalItems,
                    });

                    setFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setFetching(false);
                        setError('Unable to retrieve organizations');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [search, status, pageNumber, limit]);

    return [
        fetching,
        items,
        page,
        error
    ];
};

const isStatus = (input: Status | string | undefined): input is Status => {
    return input === 'active' || input === 'cancelled' || input === 'all';
}

/**
 * Displays a table of all organizations with Hosted PBX service assigned
 *
 * @constructor
 */

const OrganizationsIndexPage = (props: Props) => {
    const classes = useStyles();
    const params = parse(location.search);
    const searchQuery = params['search'] !== undefined ? String(params['search']) : undefined;
    const statusQuery = params['status'] !== undefined ? String(params['status']) : undefined;
    const pageQuery = params['page'] !== undefined ? parseInt(String(params['page'])) : undefined;
    const limitQuery = params['limit'] !== undefined ? parseInt(String(params['limit'])) : undefined;

    const [form, setForm] = useState<Form>({
        // Default query state to what's passed in the URL query string
        search: searchQuery,
        status: statusQuery,
        page: pageQuery,
        limit: limitQuery
    });

    // A change in params causes a change in state
    if ((searchQuery !== form.search) || (statusQuery !== form.status) || (pageQuery !== form.page) || (limitQuery !== form.limit)) {
        setForm({
            search: searchQuery,
            status: statusQuery,
            page: pageQuery,
            limit: limitQuery
        });
    }

    const [isFetching, items, page, error] = useHpbxOrganizationListFetch(form.search || '', isStatus(statusQuery) ? statusQuery : 'active', form.page, form.limit);

    const handleSubmitSearch = (search: string, status: string, limit: number) => {
        history.push(`?search=${search}&status=${status}&limit=${limit}&page=1`);
    };

    useNav('hosted-pbx', 'organizations');
    useProgressEffects(isFetching, error);

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX" subtext="Organizations w/ Service" />
            <Paper>
                <SearchBar
                    search={form.search}
                    status={form.status}
                    limit={form.limit}
                    onSubmitSearch={handleSubmitSearch} />

                {items !== null &&
                    items.length > 0 &&
                    <>
                        <OrganizationTable items={items} />

                        {page !== null &&
                            <Pagination
                                totalItems={page.total}
                                pageSize={page.size}
                                page={page.current}
                                disabled={isFetching}
                                additionalParams={{
                                    search: form.search,
                                    limit: form.limit
                                }}
                                onChange={() => null} />
                        }
                    </>
                }

                {items !== null &&
                    items.length === 0 &&
                    <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">
                            No organizations found.
                        </Typography>
                    </div>
                }
            </Paper>
        </div>
    );
};

export default OrganizationsIndexPage;