import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import { Service, SERVICE_HOSTED_PBX } from '../../../services';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchAllHpbxOrganizations, useFetchHostedPbxGroups } from '../../hosted-pbx/hooks';
import { useFetchDubberAccount } from '../accounts/hooks';
import { PRODUCT_CALL_RECORDING, PRODUCT_CALL_RECORDING_WITH_AI, PRODUCT_RESERVED } from '../constants';
import { useCreateDubberDubPoint, useFetchDubberUnidentifiedDubPoints, useFetchDubberUsers } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberDubPointIndexUrl } from '../urls';
import { CreateDubPoint } from './api';
import DubPointCreateForm, { FormValues, HostedPbxFormValues } from './DubPointCreateForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const DubPointCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];

    // a userId can be passed along to automatic select a user
    const queryString = parse(location.search);
    const userId = String(queryString['userId'] || '');

    const [isFetchingHpbxOrganizations, hpbxOrganizations, fetchHpbxOrganizationsError] = useFetchAllHpbxOrganizations('active');

    const [createDubPoint, isCreatingDubPoint, createdDubPoint, createDubPointError, createDubPointFieldErrors] = useCreateDubberDubPoint();
    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchUsers, isFetchingUsers, users, fetchUsersError] = useFetchDubberUsers();
    const [fetchUnidentifiedDubPoints, isFetchingUnidentifiedDubPoints, unidentifiedDubPoints, fetchUnidentifiedDubPointsError] = useFetchDubberUnidentifiedDubPoints();

    const [formValues, setFormValues] = useState<FormValues>({
        type: 'Recorder',
        user: userId,
        product: PRODUCT_CALL_RECORDING,
        retainUnidentifiedRecordings: true,
        service: 'HostedPBX',
        hpbx: {
            organization: null,
            userId: ''
        }
    });

    const [isSeatLoading, setIsSeatLoading] = useState(false);

    useNav('dubber', 'accounts');

    useEffect(() => {
        fetchAccount(groupId, accountId);
        fetchUsers(groupId, accountId);
        fetchUnidentifiedDubPoints(groupId);
    }, [groupId, accountId]);

    useProgressEffects(
        isFetchingAccount || isFetchingUsers || isFetchingHpbxOrganizations || isFetchingUnidentifiedDubPoints,
        fetchAccountError || fetchUsersError || fetchHpbxOrganizationsError || fetchUnidentifiedDubPointsError
    );

    const products = [
        PRODUCT_CALL_RECORDING,
        PRODUCT_CALL_RECORDING_WITH_AI,
        PRODUCT_RESERVED
    ];

    const services: Service[] = [
        SERVICE_HOSTED_PBX
    ];


    const handleUpdate = (field: keyof FormValues, value: string | boolean | HostedPbxFormValues) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Press cancel goes back to dub listing
    const handleCancel = () => {
        history.push(dubberDubPointIndexUrl(groupId, accountId));
    };

    const handleSubmit = () => {
        var post: CreateDubPoint = {
            user: formValues.user,
            type: formValues.type,
            product: formValues.product,
            retainUnidentifiedRecordings: formValues.retainUnidentifiedRecordings,
            externalType: '',
            serviceProvider: '',
            externalGroup: '',
            externalIdentifier: ''
        };

        // Assign identifiers based on service
        switch (formValues.service) {
            case "HostedPBX":
                post.externalType = "broadworks";
                post.serviceProvider = formValues.hpbx.organization?.broadworksServiceProvider || '';
                post.externalGroup = formValues.hpbx.organization?.broadworksGroup || '';
                post.externalIdentifier = formValues.hpbx.userId;
                break;
        }

        createDubPoint(groupId, accountId, post);

    };

    // Redirect to dub point listing once updated
    useEffect(() => {
        if (createdDubPoint) {
            dispatch(appWindowAddNotification('Dub Point created.', 'success'));

            history.push(dubberDubPointIndexUrl(groupId, accountId));
        }
    }, [createdDubPoint]);

    return (
        <div className={classes.root}>
            {!isFetchingAccount && account && hpbxOrganizations && users && unidentifiedDubPoints &&
                <>
                    <PageHeader text="Create Dub Point" subtext={account.name} />

                    <Paper className={classes.paper}>
                        <DubPointCreateForm
                            accountId={accountId}
                            groupId={groupId}
                            userOptions={users}
                            serviceOptions={services}
                            productOptions={products}
                            unidentifiedOptions={unidentifiedDubPoints}
                            formValues={formValues}
                            isSubmitting={isCreatingDubPoint}
                            onUpdate={handleUpdate}
                            onEnter={() => handleSubmit()}
                            onLoading={loading => setIsSeatLoading(loading)}
                            errorMessage={createDubPointError}
                            fieldErrorMessages={createDubPointFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isCreatingDubPoint || isSeatLoading}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isCreatingDubPoint || isSeatLoading}
                            onClick={handleSubmit}>Create</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(DubPointCreatePage, DubberPolicies.CanManageDubPoints);