import { FormControl, Grid, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { productLabel } from '../helpers';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    option: {
        display: 'block'
    },
    optionSubtext: {
        fontSize: '0.8rem'
    },
    ext: {
        display: 'inline-block',
        width: '100px'
    }
}));

export interface FormValues {
    product: string;
}

interface Props {
    productOptions: string[];
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string | boolean) => void;
    onEnter: () => void;
}

const DubPointUpdateForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, onEnter, productOptions } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onEnter();
        }
    };

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('product')}>
                        <InputLabel htmlFor="type">Product</InputLabel>
                        <Select
                            id="product"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.product}
                            input={<Input name="product" id="product" />}
                            onKeyDown={handleKeyDown}
                            onChange={evt => onUpdate('product', evt.target.value)}>
                            <option></option>
                            {productOptions.map(prod => <option key={prod} value={prod}>{productLabel(prod)}</option>)}
                        </Select>
                        <ValidationMessage field="product" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default DubPointUpdateForm;