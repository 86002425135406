import { FormControl, FormControlLabel, Grid, Input, InputLabel, LinearProgress, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect } from 'react';
import { FieldError } from '../../../api';
import { STATES } from '../../../constants';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { useFetchSipTrunkingBandwidthAccounts, useFetchSipTrunkingBandwidthSubAccounts } from '../hooks';
import { CreateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    assignmentId?: number;
    formValues: CreateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: CreateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: CreateAssignment): boolean => {
    const addressFieldsComplete =
        form.useExisting
        || (
            (form.accountName || '').length > 0
            && (form.bandwidthAccount || '').length > 0
            && (form.houseNumber || '').length > 0
            && (form.streetName || '').length > 0
            && (form.city || '').length > 0
            && (form.state || '').length > 0
            && (form.zipCode || '').length > 0
        );

    return addressFieldsComplete;
};

/**
 * SIP Trunking assignment form
 * @param props
 * @constructor
 */
const CreateAssignmentForm = (props: Props) => {
    const { organization, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);
    const [fetchAccounts, isFetchingAccounts, accounts, accountsError] = useFetchSipTrunkingBandwidthAccounts();
    const [fetchSubAccounts, isFetchingSubAccounts, subAccounts, subAccountsError] = useFetchSipTrunkingBandwidthSubAccounts();

    useEffect(() => {
        fetchAccounts();
        fetchSubAccounts();
    }, [])

    // Set form values once organization details & accounts are loaded
    useEffect(() => {
        if (organization && accounts) {
            const address = (organization.address || '').split(' ');
            let houseNumber = '';
            let streetName = '';

            if (address.length > 0) {
                address.reverse();
                houseNumber = address.pop() || '';

                streetName = address.reverse().join(' ');
            }

            // Auto pick account based on starting letter
            var accountId = '';

            if (accounts.length === 2) {
                accountId = accounts[0].id;

                const firstChar = (organization.name[0] || 'a').toLowerCase();
                if (firstChar >= 'n') {
                    accountId = accounts[1].id;
                }
            }

            setFormValues({
                ...formValues,
                accountName: organization.name,
                bandwidthAccount: accountId,
                houseNumber: houseNumber,
                streetName: streetName,
                addressLine2: organization.address2 || '',
                city: organization.city || '',
                state: organization.state || '',
                zipCode: organization.zip || ''
            });
        }
    }, [organization, accounts]);

    const handleFieldChange = (fieldName: keyof CreateAssignment, value: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    const displayError = errorMessage || subAccountsError;

    return (
        <>
            {!isFetchingAccounts && accounts !== null && !isFetchingSubAccounts && subAccounts
                ?
                <>
                    {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

                    <FormControl className={classes.formControl} fullWidth required>
                        <RadioGroup
                            name="useExisting"
                            value={formValues.useExisting ? '1' : '0'}
                            onChange={(evt, val) => setFormValues({ ...formValues, useExisting: val === '1' })}>

                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                disabled={isSubmitting}
                                label={"Create New Bandwidth Account"}
                            />

                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                disabled={isSubmitting}
                                label={"Use Existing Bandwidth Account"}
                            />
                        </RadioGroup>
                    </FormControl>

                    {!formValues.useExisting &&
                        <>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthAccount')}>
                                <InputLabel htmlFor="state">Parent Account</InputLabel>
                                <Select
                                    id="bandwidthAccount"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.bandwidthAccount || ''}
                                    input={<Input name="bandwidthAccount" id="bandwidthAccount" />}
                                    onChange={evt => handleFieldChange('bandwidthAccount', evt.target.value)}>
                                    <option key="" value="" />

                                    {accounts.map(acct => {
                                        return <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>;
                                    })}
                                </Select>

                                <ValidationMessage field="bandwidthAccount" />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth required error={!isValid('accountName')}>
                                <InputLabel htmlFor="accountName">Account Name</InputLabel>

                                <Input
                                    autoFocus
                                    value={formValues.accountName || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => handleFieldChange('accountName', evt.target.value)}
                                    id="accountName"
                                    type="text" />

                                <ValidationMessage field="accountName" />
                            </FormControl>

                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('houseNumber')}>
                                        <InputLabel htmlFor="houseNumber">House Number</InputLabel>

                                        <Input
                                            value={formValues.houseNumber || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('houseNumber', evt.target.value)}
                                            id="houseNumber"
                                            type="text" />

                                        <ValidationMessage field="houseNumber" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={10}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('streetName')}>
                                        <InputLabel htmlFor="streetName">Street Name</InputLabel>

                                        <Input
                                            value={formValues.streetName || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('streetName', evt.target.value)}
                                            id="streetName"
                                            type="text" />

                                        <ValidationMessage field="streetName" />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl className={classes.formControl} fullWidth error={!isValid('addressLine2')}>
                                <InputLabel htmlFor="addressLine2">Address Line 2</InputLabel>

                                <Input
                                    value={formValues.addressLine2 || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => handleFieldChange('addressLine2', evt.target.value)}
                                    id="addressLine2"
                                    type="text" />

                                <ValidationMessage field="addressLine2" />
                            </FormControl>

                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('city')}>
                                        <InputLabel htmlFor="city">City</InputLabel>

                                        <Input
                                            value={formValues.city || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('city', evt.target.value)}
                                            id="city"
                                            type="text" />

                                        <ValidationMessage field="city" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sm={4}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('state')}>
                                        <InputLabel htmlFor="state">State</InputLabel>

                                        <Select
                                            id="state"
                                            native={true}
                                            margin="none"
                                            disabled={isSubmitting}
                                            value={formValues.state || ''}
                                            input={<Input name="state" id="state" />}
                                            onChange={evt => handleFieldChange('state', evt.target.value)}>
                                            {StateOptions()}
                                        </Select>

                                        <ValidationMessage field="state" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sm={2}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('zipCode')}>
                                        <InputLabel htmlFor="zipCode">Zip Code</InputLabel>

                                        <Input
                                            value={formValues.zipCode || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('zipCode', evt.target.value)}
                                            id="zipCode"
                                            type="text" />

                                        <ValidationMessage field="zipCode" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {formValues.useExisting &&
                        <>
                            <FormControl className={classes.formControl} fullWidth required
                                error={!isValid('bandwidthSubAccount')}>
                                <InputLabel htmlFor="bandwidthSubAccount">Bandwidth Sub-Account</InputLabel>

                                <Select
                                    id="bandwidthSubAccount"
                                    margin="none"
                                    native={true}
                                    disabled={isSubmitting}
                                    value={`${formValues.bandwidthAccount}/${formValues.bandwidthSubAccount}` || ''}
                                    onChange={evt => {
                                        if (evt.target.value && evt.target.value.length > 0) {
                                            const x = evt.target.value.split('/');

                                            setFormValues({
                                                ...formValues,
                                                bandwidthAccount: x[0],
                                                bandwidthSubAccount: x[1]
                                            });
                                        }
                                    }}
                                    input={<Input name="bandwidthSubAccount" id="bandwidthSubAccount" />}>

                                    <option key="" value="" />

                                    {subAccounts.map(acct => {
                                        const key = `${acct.accountId}/${acct.id}`;

                                        return <option key={key} value={key}>{acct.name} ({acct.accountId} / {acct.id})</option>;
                                    })}
                                </Select>

                                <ValidationMessage field="bandwidthSubAccount" />
                            </FormControl>
                        </>
                    }
                </>
                : <LinearProgress />
            }
        </>
    );
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value="" />];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};

export default CreateAssignmentForm;