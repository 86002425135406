import { ApiCaller, PagedResponse } from '../../../api';
import { Platform } from '../api';

export type Profile = 'Production' | 'Sandbox' | 'Invalid';

export interface ZeroTouchView {
    macAddress: string;
    deviceModel?: string;
    groupId?: string;
    profile: Profile;
}

export interface EligibleDevice {
    name: string;
    macAddress: string;
    type: string;
    manufacturer: 'Poly' | 'Yealink';
    existingDeviceNotice: boolean;
    profile: Profile;
}

/*
 * GET /api/hosted-pbx/zero-touch/poly/{mac}
 *
 * Get ZT status for Poly device
 */

export const fetchPolyZeroTouch = async (api: ApiCaller, mac: string): Promise<ZeroTouchView> => {
    return await api<ZeroTouchView>(`hosted-pbx/zero-touch/poly/${mac}`, 'GET');
};

/*
 * POST /api/hosted-pbx/zero-touch/poly/search
 * 
 * Get ZT status for multiple Poly devices
 */
export const searchPolyZeroTouch = async (api: ApiCaller, macs: string[]): Promise<ZeroTouchView[]> => {
    return await api<ZeroTouchView[]>('hosted-pbx/zero-touch/poly/search', 'POST', {
        macAddresses: macs
    });
};

/*
 * POST /api/hosted-pbx/zero-touch
 * 
 * Enable ZT for a BroadWorks device
 */

export const enableZeroTouch = async (api: ApiCaller, platform: Platform, groupId: string, deviceName: string): Promise<{}> => {
    return await api<{}>('hosted-pbx/zero-touch', 'POST', {
        platform,
        groupId,
        deviceName
    });
};

/*
 * GET /api/hosted-pbx/zero-touch/yealink/{mac}
 *
 * Get ZT status for Yealink device
 */

export const fetchYealinkZeroTouch = async (api: ApiCaller, mac: string): Promise<ZeroTouchView> => {
    return await api<ZeroTouchView>(`hosted-pbx/zero-touch/yealink/${mac}`, 'GET');
};

/*
 * POST /api/hosted-pbx/zero-touch/yealink/search
 * 
 * Get ZT status for multiple Yealink devices
 */
export const searchYealinkZeroTouch = async (api: ApiCaller, macs: string[]): Promise<ZeroTouchView[]> => {
    return await api<ZeroTouchView[]>('hosted-pbx/zero-touch/yealink/search', 'POST', {
        macAddresses: macs
    });
};

/*
 * GET /api/hosted-pbx/zero-touch/eligible-devices/{groupId}
 * 
 * Get all devices in a group that are eligible for ZTP
 */
export const fetchZeroTouchEligibleDevices = async (api: ApiCaller, platform: Platform, groupId: string): Promise<EligibleDevice[]> => {
    return await api<EligibleDevice[]>(`hosted-pbx/zero-touch/eligible-devices/${groupId}`, 'GET', {
        platform
    });
};