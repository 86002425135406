import { ApiCaller } from '../../../../api';
import { OrderBase, OrderHistory, OrderNote, OrderStatus } from '../api';

export interface NewNumberOrderSummary extends OrderBase {
    id: string;
    type: 'NewNumber';
    accountId: string;
    numberCount: number;
    customerOrderId: string;
    userId: string;
    createDate: string;
    lastModifiedDate: string;
    status: OrderStatus;
    summary: string;
}

/**
 * GET /bandwidth/{accountId}/orders/new-numbers
 * 
 * Get all New Number Orders for an account. Can be filtered to just one sub-account
 * 
 * @param api
 * @param accountId
 * @param subAccountId
 */
export const fetchBandwidthNewNumberOrders = async (api: ApiCaller, accountId: string, subAccountId?: string): Promise<NewNumberOrderSummary[]> => {
    return await api<NewNumberOrderSummary[]>(`bandwidth/${accountId}/orders/new-numbers`, 'GET', {
        subAccountId
    });
};

export interface NewNumberOrder extends OrderBase {
    id: string;
    type: 'NewNumber';
    createDate: string;
    lastModifiedDate: string;
    completeDate: string | null;
    subAccountId: string;
    locationId: string;
    createdByUser: string;
    customerOrderId: string | null;
    status: OrderStatus;
    name: string;
    summary: string;
    backOrderRequested: boolean;
    partialAllowed: boolean;
    completedQuantity: number;
    failedQuantity: number;
    completedNumbers: CompletedNumber[];
    failedNumbers: string[];
    combinedSearchAndOrderType: CombinedSearchAndOrderType;
    ExistingTelephoneNumberOrderType: ExistingTelephoneNumberOrderType;
    errors: NewNumberOrderError[];
    history: OrderHistory[];
    notes: OrderNote[];
}

export interface CompletedNumber {
    city: string;
    lata: string;
    rateCenter: string;
    state: string;
    fullNumber: string;
    tier: number;
    vendorId: number;
    vendorName: string;
}

export interface CombinedSearchAndOrderType {
    rateCenter: string;
    state: string;
    enableLca: boolean;
    quantity: number;
}

export interface ExistingTelephoneNumberOrderType {
    numbers: string[];
}

export interface NewNumberOrderError {
    code: string;
    description: string;
    number: string;
}

/**
 * GET /bandwidth/{accountId}/orders/new-numbers/{orderId}
 * 
 * Get New Number Order details
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthNewNumberOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<NewNumberOrder> => {
    return await api<NewNumberOrder>(`bandwidth/${accountId}/orders/new-numbers/${orderId}`, 'GET');
};