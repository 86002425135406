import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useProgressEffects } from '../../app-window/hooks';
import { SubAccountNumbers } from '../sub-accounts/api';
import { useFetchBandwidthSubAccountNumbers } from '../sub-accounts/hooks';
import NumbersTable, { ListItem } from './NumbersTable';
import NumbersSearchBar from './NumbersSearchBar';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

interface Props {
    accountId: string;
    subAccountId: string;
    onLoaded: (numbers: SubAccountNumbers) => void;
}

interface Form {
    search: string;
    page: number;
    limit: number;
}

const NumbersPane = (props: Props) => {
    const { accountId, subAccountId, onLoaded } = props;
    const classes = useStyles();
    const [fetch, isFetching, numbers, fetchError] = useFetchBandwidthSubAccountNumbers();
    const [form, setForm] = useState<Form>({
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    });

    useProgressEffects(
        false,
        fetchError
    );

    // Fetch numbers on load
    useEffect(() => {
        fetch(accountId, subAccountId);
    }, [accountId, subAccountId]);

    useEffect(() => {
        if (!isFetching && numbers) {
            onLoaded(numbers);
        }
    }, [isFetching, numbers]);

    const listItems = useMemo(() => {
        var list: ListItem[] = [];

        if (numbers) {
            numbers.locations.forEach(l => {
                l.numbers.forEach(n => {
                    list.push({
                        number: n,
                        locationId: l.locationId,
                        locationName: l.locationName
                    })
                });
            });

            // Order by number
            list.sort((a, b) => a.number.localeCompare(b.number));
        }

        return list;
    }, [numbers]);

    // Filter numbers
    const filtredListItems = useMemo(() => {
        const { search } = form;

        return (listItems || [])
            .sort((a, b) => a.number.localeCompare(b.number))
            .filter(i => {
                return (i.number || '').toLowerCase().includes(search.toLowerCase())
                    || i.locationName.toLowerCase().includes(search.toLowerCase())
                    || i.locationId == search;
            })
    }, [numbers, form]);

    // Pagination filtered numbers
    const pagedListItems = useMemo(() => {
        const { page, limit } = form;

        return (filtredListItems || [])
            .slice((page - 1) * limit, page * limit);
    }, [filtredListItems, form]);

    // Handle search filter
    const handleSearch = (search: string, limit: number) => {
        setForm({
            search,
            limit,
            page: 1
        })
    };

    // Handle pagination
    const handlePage = (page: number) => {
        setForm({
            ...form,
            page
        })
    };

    return (
        <>
            {!isFetching && numbers &&
                <>
                    {listItems.length === 0
                        && <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">No numbers found.</Typography>
                        </div>
                    }

                    {listItems.length > 0
                        && <>
                            <NumbersSearchBar onSubmitSearch={handleSearch} />

                            <NumbersTable accountId={accountId} subAccountId={subAccountId} numbers={pagedListItems} />

                            <Pagination
                                totalItems={filtredListItems.length}
                                pageSize={form.limit}
                                page={form.page}
                                disabled={isFetching}
                                setParams={false}
                                onChange={handlePage} />
                        </>
                    }
                </>
            }

            {isFetching && <LinearProgress />}
        </>
    );
};

export default NumbersPane;