import { Input, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Seat } from '../api';
import { PageValues, SeatError } from './StatementForm';

interface Props {
    isSubmitting: boolean;
    onChange: (seatId: number, field: 'pagesIn' | 'pagesOut', value: string) => void;
    seats: Seat[];
    seatPageValues: PageValues[];
    seatErrors: SeatError[];
}

const StatementFormSeats = (props: Props) => {
    const { isSubmitting, onChange, seats, seatErrors, seatPageValues } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Pages In</TableCell>
                    <TableCell>Pages Out</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {seats.map(s => {
                    var values = seatPageValues.find(v => v.seatId == s.id);
                    var errors = seatErrors.filter(e => e.seatId == s.id);

                    return (
                        <TableRow key={s.id}>
                            <TableCell>{s.name}</TableCell>
                            <TableCell>{s.type}</TableCell>
                            <TableCell>
                                <Input
                                    error={errors.find(e => e.field.toLowerCase() === 'pagesin') !== undefined}
                                    name="pagesIn"
                                    value={values?.pagesIn || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onChange(s.id, 'pagesIn', evt.target.value)}
                                    onKeyDown={() => false}
                                    type="number" />
                            </TableCell>
                            <TableCell>
                                <Input
                                    error={errors.find(e => e.field.toLowerCase() === 'pagesout') !== undefined}
                                    name="pagesOut"
                                    value={values?.pagesOut || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => onChange(s.id, 'pagesOut', evt.target.value)}
                                    onKeyDown={() => false}
                                    type="number" />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default StatementFormSeats;