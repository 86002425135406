import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { createOrganization, fetchAllResellers, OrganizationDetails, TYPE_ENDCLIENT } from '../api';
import { FormValues, OrganizationForm } from '../OrganizationForm';
import { OrganizationPolicies } from '../policies';
import { organizationIndexUrl, organizationViewUrl } from '../urls';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    errorMessage: {
        backgroundColor: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface FormHandler {
    isSubmitting: boolean;
    submit: (formValues: FormValues) => void;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    failureMessage: string | null;
}

// Hook for handling the form submission
const useFormHandler = (callback: (organization: OrganizationDetails) => void): FormHandler => {
    const api = useApi();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationFieldMessages, setValidationFieldMessages] = useState<FieldError[]>([]);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    const submit = (formValues: FormValues) => {
        (async () => {
            setIsSubmitting(true);

            try {
                // Call API to create organization
                const organization = await createOrganization(api, {
                    ...formValues,
                    sapId: (formValues.sapId || '').length > 0 ? formValues.sapId : null
                });

                callback(organization);
            } catch (e) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, we'll either display error messages under the fields
                    // or display a generic error message if no field errors are listed
                    setValidationMessage(e.fields && (e.fields.length > 0) ? null : e.message);
                    setValidationFieldMessages(e.fields && (e.fields.length > 0) ? e.fields : []);
                } else {
                    // For generic errors, display a generic error message
                    setFailureMessage('Unable to save organization.');
                    setValidationMessage(null);
                    setValidationFieldMessages([]);
                }

                setIsSubmitting(false);
            }
        })();
    };

    return {
        isSubmitting,
        submit,
        validationMessage,
        validationFieldMessages,
        failureMessage
    };
};

const numericFields = [
    'resellerId'
];

/**
 * Page containing the form for creating an organization
 * @constructor
 */
const OrganizationCreatePage = () => {
    const classes = useStyles();
    const api = useApi();
    const dispatch = useDispatch();
    const [resellerOptions, setResellerOptions] = useState<Array<{ id: number, name: string }> | null>(null);

    useNav('organizations');

    // Form values use internal state
    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        sapId: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        type: TYPE_ENDCLIENT,
        resellerId: null
    });

    // Retrieve reseller options on init load
    useEffect(() => {
        if (resellerOptions == null) {
            (async () => {
                try {
                    const resellers = await fetchAllResellers(api);
                    setResellerOptions(resellers);
                } catch(e) {
                    setResellerOptions([]);
                    dispatch(appWindowAddNotification('Unable to retrieve reseller options.', 'error'));
                }
            })();
        }
    }, [resellerOptions]);

    const {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        submit,
        failureMessage: createError
    } = useFormHandler(organization => {
        // Create a notification about the creation
        dispatch(appWindowAddNotification('Organization created.', 'success'));

        // Redirect to the view page after creation
        history.push(organizationViewUrl(organization.id));
    });

    useProgressEffects(isSubmitting, createError);

    // Cancel button goes back to organization index
    const handleCancel = () => {
        history.push(organizationIndexUrl());
    };

    // Handle submit button
    const handleSubmit = () => {
        submit(formValues);
    };

    // Adjust form state when a field is modified
    const handleFieldChange = (fieldName: string, value: string | number | null) => {
        // Ensure ResellerID is numeric or NULL if not selected
        if (numericFields.indexOf(fieldName) > -1) {
            if (String(value).length > 0) {
                value = parseInt(String(value));
            } else {
                value = null;
            }
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Create Organization"/>

            <Paper className={classes.paper}>
                <OrganizationForm
                    formValues={formValues}
                    isSubmitting={isSubmitting}
                    resellerOptions={resellerOptions}
                    validationMessage={validationMessage}
                    validationFieldMessages={validationFieldMessages}
                    onFieldChange={handleFieldChange}
                    onFieldKeyDown={handleKeyDown}/>
            </Paper>

            <Grid container justifyContent="flex-end">
                <Button
                    className={classes.button}
                    color="inherit"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleCancel}>Cancel</Button>

                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}>Save</Button>
            </Grid>
        </div>
    );
};

export default withPolicyRestriction(OrganizationCreatePage, OrganizationPolicies.CanManage);