import * as React from 'react';
import { Grid } from '@mui/material';
import ServiceCard from './ServiceCard';
import { AssignedService } from '../../api';

interface Props {
    organizationId: number;
    assignedServices: AssignedService[];
}

/**
 * Displays a grid of services assigned to an organization
 *
 * @param props
 * @constructor
 */
const ServiceGrid = (props: Props) => {
    const { organizationId, assignedServices } = props;

    return (
        <Grid container spacing={8}>
            {assignedServices.map(assignedService =>
                <Grid item key={assignedService.resource}>
                    <ServiceCard organizationId={organizationId} assignedService={assignedService} />
                </Grid>
            )}
        </Grid>
    );
};

export default ServiceGrid;