import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organization/{organizationId}/services/direct-routing
 *
 * Get all direct routing assignments for an organization
 */

export interface Assignment {
    id: number;
    startedAt: string;
    isActive: boolean;
    cancelledAt: string | null;
    iPilotAccountId: string;
    dubberAccountId: string;
}

export interface CreateAssignment {
    iPilotAccountId: number | null
    useDubber?: boolean;
    createDubberAccount?: boolean;
    accountName?: string;
    timeZone?: string;
}

export const fetchDirectRoutingAssignments = async (api: ApiCaller, organizationId: number): Promise<Assignment[]> => {
    return await api<Assignment[]>(`organizations/${organizationId}/services/direct-routing`, 'GET');
};

/*
 * GET /api/organization/{organizationId}/services/direct-routing/{assignmentId}
 *
 * Get direct routing assignment
 */

export const fetchDirectRoutingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/direct-routing/${assignmentId}`, 'GET');
};

/*
 * POST /api/organization/{organizationId}/services/direct-routing
 *
 * Assign Direct Routing service to an organization
 */

export const createDirectRoutingAssignment = async (api: ApiCaller, organizationId: number, form: CreateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/direct-routing`, 'POST', {
        ...form
    });
};

export interface UpdateAssignment {
    iPilotAccountId: number | null
};

/*
 * PUT /api/organization/{organizationId}/services/direct-routing/{assignmentId}
 *
 * Update Direct Routing service assignment
 */

export const updateDirectRoutingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/direct-routing/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * POST /api/organization/{organizationId}/services/direct-routing/{assignmentId}/cancel-check
 *
 * Check if a Direct Routing assignment can be cancelled
 */

export interface CancelCheck {
    success: boolean;
    accountExists: boolean;
    accountId: number;
    accountName: string | null;
    numberCount: number;
}

export const cancelCheckDirectRoutingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<CancelCheck> => {
    return await api<CancelCheck>(`organizations/${organizationId}/services/direct-routing/${assignmentId}/cancel-check`, 'POST');
};



/*
 * DELETE /api/organization/{organizationId}/services/direct-routing/{assignmentId}
 *
 * Cancel a Direct Routing service assignment
 */

export const cancelDirectRoutingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/direct-routing/${assignmentId}`, 'DELETE');
};
