import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organization/{organizationId}/services/webex
 *
 * Get all webex assignments for an organization
 */

export interface Assignment {
    id: number;
    startedAt: string;
    isActive: boolean;
    cancelledAt: string | null;
    webexCustomerId: string;
    webexOrgId: string;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
    dubberGroup: string;
    dubberAccount: string;
    migratedBroadCloudPaasAssignmentId: string | null;
}

export const fetchWebexAssignments = async (api: ApiCaller, organizationId: number): Promise<Assignment[]> => {
    return await api<Assignment[]>(`organizations/${organizationId}/services/webex`, 'GET');
};

/*
 * GET /api/organization/{organizationId}/services/webex/{assignmentId}
 *
 * Get webex assignment
 */

export const fetchWebexAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/webex/${assignmentId}`, 'GET');
};

export interface CreateAssignment {
    useExisting: boolean;

    // Fields when useExisting is true

    webexCustomerId?: string;
    bandwidthSubAccount?: string;
    dubberAccount?: string;

    // Fields when useExisting is false

    accountName?: string;
    locationName?: string;
    houseNumber?: string;
    streetName?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    timeZone?: string;
    administratorEmail?: string;
};

/*
 * POST /api/organization/{organizationId}/services/webex
 *
 * Assign webex service to an organization
 */

export const createWebexAssignment = async (api: ApiCaller, organizationId: number, form: CreateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/webex/`, 'POST', {
        ...form
    });
};

export interface UpdateAssignment {
    webexCustomerId: string;
    bandwidthSubAccount: string;
    dubberAccount: string;
};

/*
 * PUT /api/organization/{organizationId}/services/webex/{assignmentId}
 *
 * Update webex service assignment
 */

export const updateWebexAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/webex/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * POST /api/organization/{organizationId}/services/webex/{assignmentId}/cancel-check
 *
 * Check if Webex assignment can be cancelled
 */

export type ServiceStatus = 'Success' | 'SuccessDeleted' | 'Failed';

export interface CancelCheck {
    success: boolean;
    webex: WebexCancelCheck;
    bandwidth: BandwidthCancelCheck;
    dubber: DubberCancelCheck;
}

export interface WebexCancelCheck {
    success: boolean;
    status: ServiceStatus;
    id: string;
    name: string | null;
    seatCount: number;
}

export interface BandwidthCancelCheck {
    success: boolean;
    status: ServiceStatus;
    id: number;
    name: string | null;
    numberCount: number;
    locationCount: number;
}

export interface DubberCancelCheck {
    success: boolean;
    status: ServiceStatus;
    id: number;
    name: string | null;
    userCount: number;
    dubPointCount: number;
}

export const cancelCheckWebexAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<CancelCheck> => {
    return await api<CancelCheck>(`organizations/${organizationId}/services/webex/${assignmentId}/cancel-check`, 'POST');
};



/*
 * DELETE /api/organization/{organizationId}/services/webex/{assignmentId}
 *
 * Cancel a webex service assignment
 */

export const cancelWebexAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/webex/${assignmentId}`, 'DELETE');
};
