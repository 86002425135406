import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { createDubberTeam, deleteDubberTeam, fetchDubberTeam, fetchDubberTeams, Team, TeamCreate, TeamUpdate, updateDubberTeam } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

// Hook for fetching teams under dubber account
type FetchTeamsFunc = (groupId: string, accountId: string) => void;

export const useFetchDubberTeams = (): TriggeredFetchHookResponse<FetchTeamsFunc, Team[]> => {
    interface Request {
        groupId: string;
        accountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [teams, setTeams] = useState<Team[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchTeamsFunc = (groupId: string, accountId: string) => {
        setRequest({
            groupId,
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setTeams(null);

                try {
                    const u = await fetchDubberTeams(api, request.groupId, request.accountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setTeams(u);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setTeams(null);
                            setError('Unable to fetch dubber teams.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        teams,
        error
    ];
};

// Hook for fetching team details

type FetchTeamFunc = (groupId: string, accountId: string, teamId: string) => void;

export const useFetchDubberTeam = (): TriggeredFetchHookResponse<FetchTeamFunc, Team> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [team, setTeam] = useState<Team | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchTeamFunc = (groupId: string, accountId: string, teamId: string) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setTeam(null);

                try {
                    const t = await fetchDubberTeam(api, request.groupId, request.accountId, request.teamId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setTeam(t);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setTeam(null);
                            setError('Unable to fetch dubber team.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        team,
        error
    ];
};

// Hook for creating a dubber team
type CreateTeamFunc = (groupId: string, accountId: string, create: TeamCreate) => void;

export const useCreateDubberTeam = (): TriggeredSubmitHookResponse<CreateTeamFunc, Team> => {
    interface Request {
        groupId: string;
        accountId: string;
        create: TeamCreate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [team, setTeam] = useState<Team | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: CreateTeamFunc = (groupId: string, accountId: string, create: TeamCreate) => {
        setRequest({
            groupId,
            accountId,
            create,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setTeam(null);

                try {
                    const createdTeam = await createDubberTeam(api, request.groupId, request.accountId, request.create);

                    if (!didCancel) {
                        setIsCreating(false);
                        setTeam(createdTeam);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setTeam(null);
                            setError('Unable to create dubber team');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        team,
        error,
        fieldErrors
    ];
};

// Hook for updating a dubber team
type UpdateTeamFunc = (groupId: string, accountId: string, teamId: string, update: TeamUpdate) => void;

export const useUpdateDubberTeam = (): TriggeredSubmitHookResponse<UpdateTeamFunc, Team> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        update: TeamUpdate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [team, setTeam] = useState<Team | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: UpdateTeamFunc = (groupId: string, accountId: string, teamId: string, update: TeamUpdate) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            update,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setTeam(null);

                try {
                    const updatedTeam = await updateDubberTeam(api, request.groupId, request.accountId, request.teamId, request.update);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setTeam(updatedTeam);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setTeam(null);
                            setError('Unable to update dubber team');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isUpdating,
        team,
        error,
        fieldErrors
    ];
};

// Hook for deleting dubber team

type DeleteTeamFunc = (groupId: string, accountId: string, teamId: string) => void;

export const useDeleteDubberTeam = (): TriggeredFetchHookResponse<DeleteTeamFunc, boolean> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetch: DeleteTeamFunc = (groupId: string, accountId: string, teamId: string) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsDeleting(true);
                setIsDeleted(false);

                try {
                    await deleteDubberTeam(api, request.groupId, request.accountId, request.teamId);

                    if (!didCancel) {
                        setIsDeleting(false);
                        setIsDeleted(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsDeleting(false);
                            setIsDeleted(false);
                            setError('Unable to delete dubber team.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isDeleting,
        isDeleted,
        error
    ];
};