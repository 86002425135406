import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import Pagination from '../../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useFetchBandwidthAccounts, useFetchBandwidthNumbers } from '../../hooks';
import NumberTable from './NumberTable';
const queryString = require('query-string');
import NumberSearchBar from './NumberSearchBar';
import AccountForm from '../../sub-accounts/AccountForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    //accountId: string;
    search: string;
    page: number;
    limit: number;
}

const compareForm = (a: Form, b: Form) =>
    a.search == b.search
    //&& a.accountId == b.accountId
    && a.page == b.page
    && a.limit == b.limit;

const formFromQuery = (query: ParsedQuery<string>): Form => {
    const searchQuery = String(query['search'] || '');
    //const accountIdQuery = String(query['accountId'] || '');
    const pageQuery = parseInt(String(query['page'] || '1'));

    const limitQuery = parseInt(String(query['limit'] || DEFAULT_PAGE_SIZE));

    return {
        //accountId: accountIdQuery,
        search: searchQuery,
        page: pageQuery,
        limit: limitQuery
    }
};

// Page for search for all numbers
const NumberIndexPage = (props: Props) => {
    const classes = useStyles();
    const [form, setForm] = useState<Form>({
        //accountId: '',
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    });

    const [pageMap, setPageMap] = useState<string[]>(['1']);

    const [fetch, isFetching, numbers, fetchErrorMessage] = useFetchBandwidthNumbers();
    const [fetchAccounts, isFetchingAccounts, accounts, fetchAccountsErrorMessage] = useFetchBandwidthAccounts();

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Parse query string
    const params = parse(location.search);

    // Refresh list if current form settings don't match the query string
    var queryForm = formFromQuery(params);

    if (!compareForm(form, queryForm)) {
        setForm(queryForm);
    }

    // When account is changed, reset rest of form
    const handleAccountUpdate = (accountId: string) => {
        // Reset form except for the account field
        history.push('?' + queryString.stringify({
            accountId,
            search: '',
            page: 1,
            limit: queryForm.limit,
        }));
    };

    // Submitting the search will update the query string and force a fetch
    const handleSubmitSearch = (search: string, limit: number) => {
        var query: Record<string, string> = {
            limit: String(limit),
            page: '1'
        };

        if (search && search.length > 0) {
            query['search'] = search;
        }

        // Keep accountId value from account selection form
        /*
        if (form.accountId && form.accountId.length > 0) {
            query['accountId'] = form.accountId;
        }
        */

        const params = new URLSearchParams(query);

        history.push('?' + params.toString());
    }

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingAccounts,
        fetchErrorMessage || fetchAccountsErrorMessage
    );


    // Fetch accounts on load
    useEffect(() => {
        fetchAccounts();
    }, []);

    // Trigger fetch when form is updated
    useEffect(() => {
        // Require accountId to be set
        //if (form.accountId) {
        var bwPage = '1';

        // Page number from the form is OUR page number, but Bandwidth uses an actual telephone number to designate page
        // The bandwidth page should be in the mapping array
        if (form.page !== 1) {
            bwPage = pageMap[form.page - 1] || '1';
        }

        // Reset map page if requesting the first page
        if (form.page === 1) {
            setPageMap(['1']);
        }

        //fetch(form.accountId, form.search, bwPage, form.limit);
        fetch(undefined, form.search, bwPage, form.limit);
        //}
    }, [form]);

    useEffect(() => {
        if (numbers && numbers.next) {
            // Add Bandwidth's next page number if it's not already been added
            let newMap = pageMap;
            newMap[form.page] = numbers.next;

            setPageMap(newMap);
        }
    }, [numbers]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth" subtext="Numbers" />

            {/*!isFetchingAccounts && accounts !== null &&
                <Paper className={classes.paper}>
                    <AccountForm
                        accounts={accounts}
                        selectedAccountId={form.accountId}
                        onSelect={(accountId: string) => handleAccountUpdate(accountId)} />
                </Paper>
            */}

            {!isFetchingAccounts && accounts !== null &&
                <Paper>
                    <NumberSearchBar
                        search={form.search}
                        limit={form.limit}
                        onSubmitSearch={handleSubmitSearch}
                    />

                    {numbers !== null
                        && numbers.numbers.length === 0
                        && <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">No numbers found.</Typography>
                        </div>
                    }

                    {numbers !== null
                        && numbers.numbers.length > 0
                        && <>
                            <NumberTable numbers={numbers.numbers} accounts={accounts} />

                            <Pagination
                                totalItems={numbers.total || 0}
                                pageSize={form.limit}
                                page={form.page}
                                disabled={isFetching}
                                additionalParams={{
                                    //accountId: form.accountId,
                                    search: form.search,
                                    limit: form.limit
                                }}
                                onChange={() => null} />
                        </>
                    }
                </Paper>
            }
        </div>
    );
};

export default NumberIndexPage;