import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/webex/bandwidth-subaccounts
 *
 * Get all bandwidth sub-accounts in webex bandwidth account
 */

export interface BandwidthSubAccount {
    id: string;
    accountId: string;
    name: string;
    description: string;
}

export const fetchWebexBandwidthSubAccounts = async (api: ApiCaller): Promise<BandwidthSubAccount[]> => {
    return await api<BandwidthSubAccount[]>('webex/bandwidth-subaccounts', 'GET');
};