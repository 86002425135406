import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { Account } from '../../accounts/api';
import { E911Order } from '../../api';
import moment = require('moment');
import { orderStatusDisplay } from '../helpers';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    accountId: string;
    account: Account;
    order: E911Order;
}

// E911 Order Summary Box
const E911Summary = (props: Props) => {
    const classes = useStyles();
    const { accountId, account, order } = props;

    const createDate = moment(order.createDate).format('MM/DD/YYYY hh:mm A');
    const lastModifiedDate = moment(order.lastModifiedDate).format('MM/DD/YYYY hh:mm A');

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>
                        {account.name}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                    <Typography>
                        {order.action == "Update" ? "E911 Update" : "E911 Removal"}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>
                        {orderStatusDisplay(order.status)}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Ordered By</Typography>
                    <Typography>
                        {order.createdByUser}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Order Date</Typography>
                    <Typography>
                        {createDate}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Last Updated</Typography>
                    <Typography>
                        {lastModifiedDate}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Customer Order ID</Typography>
                    <Typography>
                        {order.customerOrderId}
                    </Typography>
                </Grid>

            </Grid>
        </Paper>
    );
}

export default E911Summary;