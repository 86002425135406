import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
}

interface Props {
    organization: OrganizationDetails;
    assignmentId?: number;
    formValues: FormValues,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: FormValues) => void;
}

export const formComplete = (organization: OrganizationDetails, form: FormValues): boolean => true;

/**
 * Fax2Mail assignment form
 * @param props
 * @constructor
 */
const AssignmentForm = (props: Props) => {
    const { assignmentId, organization, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const displayError = errorMessage;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            <Typography variant="body1">No additional information needed.</Typography>
        </>
    );
};

export default AssignmentForm;