import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import PageHeader from '../../components/PageHeader';
import { MAIN_WIDTH } from '../../constants';
import history from '../../history';
import theme from '../../theme';
import { appWindowAddNotification } from '../app-window/actions';
import { useNav, useProgressEffects } from '../app-window/hooks';
import { useSession } from '../auth/hooks';
import { useConfirmAuthenticator, useDeleteAuthenticator, useFetchUserProfile, useSetupAuthenticator } from './hooks';
import { userProfileUpdateUrl, userProfileUrl } from './urls';
import UserSummary from './UserSummary';
import EnableAuthenticatorDialog from './EnableAuthenticatorDialog';
import MfaSummary from './MfaSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    summary: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    subheading: {
        display: 'flex'
    },
}));

const UserProfilePage = () => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const [fetch, isFetching, profile, fetchError] = useFetchUserProfile();
    const [deleteAuthenticator, isDeletingAuthenticator, authenticatorDeleted, deleteAuthenticatorError] = useDeleteAuthenticator();
    const [deleteAuthenticatorDialogOpen, setDeleteAuthenticatorDialogOpen] = useState(false);

    const [enableAuthenticatorDialogOpen, setEnableAuthenticatorDialogOpen] = useState(false);
    const [setupAuthenticator, isSettingUpAuthenticator, authenticatorSetup, authenticatorSetupError, _, resetSetup] = useSetupAuthenticator();
    const [confirmAuthenticator, isConfirmingAuthenticator, authenticatorConfirmed, authenticatorConfirmError, __, resetConfirm] = useConfirmAuthenticator();

    useNav();

    useProgressEffects(isFetching, fetchError || deleteAuthenticatorError);

    useEffect(() => {
        fetch();
    }, [session.token]);

    const confirmDeleteAuthenticator = () => {
        deleteAuthenticator();
    };

    // Show a success message and reload when authenticator removed
    useEffect(() => {
        if (authenticatorDeleted) {
            dispatch(appWindowAddNotification('Authenticator removed.', 'success'));

            setDeleteAuthenticatorDialogOpen(false);
            fetch();
        }
    }, [authenticatorDeleted]);

    // Request authenticator setup when dialog opens
    useEffect(() => {
        if (enableAuthenticatorDialogOpen) {
            resetSetup();
            resetConfirm();
            setupAuthenticator();
        }
    }, [enableAuthenticatorDialogOpen]);

    // Display message and reload when authenticator is confirmed
    useEffect(() => {
        if (authenticatorConfirmed) {
            dispatch(appWindowAddNotification('Authenticator configured.', 'success'));

            setEnableAuthenticatorDialogOpen(false);
            fetch();
        }
    }, [authenticatorConfirmed]);

    return (
        <div className={classes.root}>
            {profile &&
                <>
                    <PageHeader text="Profile">
                        <Button
                            className={classes.headerButton}
                            variant="text"
                            color="primary"
                            component={React.forwardRef((props, ref) => <Link to={userProfileUpdateUrl()} {...props} />)}>
                            Update
                        </Button>
                    </PageHeader>

                    <UserSummary profile={profile} />

                    <div className={classes.subheading}>
                        <Typography variant="h3">Two-Factor Authentication</Typography>
                        {!profile.authenticatorConfigured
                            ? <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                onClick={() => setEnableAuthenticatorDialogOpen(true)}>
                                Use Authenticator App
                            </Button>
                            : <Button
                                className={classes.headerButton}
                                onClick={() => setDeleteAuthenticatorDialogOpen(true)}
                                variant="text"
                                color="secondary">
                                Disable Authenticator App
                            </Button>}
                    </div>

                    <div className={classes.summary}>
                        <MfaSummary profile={profile} />
                    </div>
                </>
            }

            <ConfirmationDialog
                title={'Disable Authenticator App?'}
                message={'Are you sure you want to disable Authenticator for your account? You can re-enable it later.'}
                showWorking={isDeletingAuthenticator}
                isOpen={deleteAuthenticatorDialogOpen}
                confirmText={'Remove'}
                cancelText={'Cancel'}
                onCancel={() => setDeleteAuthenticatorDialogOpen(false)}
                onConfirm={() => confirmDeleteAuthenticator()} />

            <EnableAuthenticatorDialog
                isOpen={enableAuthenticatorDialogOpen}
                qr={authenticatorSetup?.qr ?? null}
                code={authenticatorSetup?.code ?? null}
                setupError={authenticatorSetupError}
                confirmError={authenticatorConfirmError}
                isSettingUp={isSettingUpAuthenticator}
                isConfirming={isConfirmingAuthenticator}
                onCancel={() => setEnableAuthenticatorDialogOpen(false)}
                onConfirm={(code: string) => confirmAuthenticator(code)}
            />

        </div >
    );
};

export default UserProfilePage;