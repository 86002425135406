import { useEffect, useState } from 'react';
import { ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { loginUrl } from '../../auth/urls';
import { fetchHostedPbxOrganizations, HostedPbxOrganization, Status } from '../api';
import {
    fetchHostedPbxAssignment, HostedPbxAssignment
} from './api';

type FetchHookResponse<T> = [
    boolean,
    T | null,
    string | null
];

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/**
 * Hook for fetching all HPBX assigned organizations
 * */
export const useFetchAllHpbxOrganizations = (status: Status = 'all'): FetchHookResponse<HostedPbxOrganization[]> => {
    const api = useApi();

    // Indicates that we're currently fetching items from the server
    const [isFetching, setIsFetching] = useState(false);

    // The list of organizations w/ HPBX assignments retrieved
    const [organizations, setOrganizations] = useState<HostedPbxOrganization[] | null>(null);

    // Error message returned by the server
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                let allOrganizations: HostedPbxOrganization[] = [];
                let pageToFetch: number | null = 1;

                while (pageToFetch != null && !didCancel) {
                    const response = await fetchHostedPbxOrganizations(api, '', status, pageToFetch, 5000);

                    if (!didCancel) {
                        allOrganizations = allOrganizations.concat(response.items);

                        if (response.totalPages > pageToFetch) {
                            pageToFetch++;
                        } else {
                            pageToFetch = null;
                        }
                    }
                }

                if (!didCancel) {
                    setOrganizations(allOrganizations);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError('Unable to retrieve organizations');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, []);

    return [
        isFetching,
        organizations,
        error
    ];
};

/**
 * Hook for retrieving Hosted PBX assignment from server
 */

type FetchHostedPbxAssignmentFunc = (organizationId: number, assignmentId: number) => void;

export const useFetchHostedPbxAssignment = (): TriggeredFetchHookResponse<FetchHostedPbxAssignmentFunc, HostedPbxAssignment> => {
    const api = useApi();

    const [organizationId, setOrganizationId] = useState<number | null>(null);
    const [assignmentId, setAssignmentId] = useState<number | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);

    const [isFetching, setIsFetching] = useState(false);
    const [assignment, setAssignment] = useState<HostedPbxAssignment | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (organizationId: number, assignmentId: number) => {
        setOrganizationId(organizationId);
        setAssignmentId(assignmentId);
        setRequestTs(Date.now());
    };

    useEffect(() => {
        if (organizationId && assignmentId) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchHostedPbxAssignment(api, organizationId, assignmentId);
                    if (!didCancel) {
                        setAssignment(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve Hosted PBX details.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [organizationId, assignmentId, requestTs]);

    return [
        fetch,
        isFetching,
        assignment,
        error
    ];
};