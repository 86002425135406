import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import theme from '../../theme';

interface Props {
    isOpen: boolean;
    qr: string | null;
    code: string | null;
    setupError: string | null;
    confirmError: string | null;
    isSettingUp: boolean;
    isConfirming: boolean;
    onCancel: () => void;
    onConfirm: (code: string) => void;
}

const useStyles = makeStyles(() => ({
    secondary: {
        marginTop: theme.spacing(1),
        fontSize: '0.9rem'
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    center: {
        textAlign: 'center'
    }
}));

/**
 * Dialog box on User Profile page for enabling an Autheticator app for MFA
 *
 * @param props
 * @constructor
 */
const EnableAuthenticatorDialog = (props: Props) => {
    const {
        isOpen,
        qr,
        code,
        setupError,
        confirmError,
        isSettingUp,
        isConfirming,
        onCancel,
        onConfirm
    } = props;

    const classes = useStyles();

    const [confirmationCode, setConfirmationCode] = useState('');

    // Clear code on open
    useEffect(() => {
        if (isOpen) {
            setConfirmationCode('');
        }
    }, [isOpen])

    const handleCancel = () => {
        if (!isConfirming) {
            onCancel();
        }
    };

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onConfirm(confirmationCode);
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isSettingUp && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isSettingUp]);

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={handleCancel}
            aria-labelledby="confirmation-dialog-title">
            <DialogTitle id="confirmation-dialog-title">Enable Authenticator App</DialogTitle>
            <DialogContent>
                {isSettingUp && <div className={classes.center}><CircularProgress color="primary" /></div>}

                {setupError && <DialogContentText color="error">{setupError}</DialogContentText>}

                {!isSettingUp &&
                    <>
                        <DialogContentText color="textPrimary">
                            Scan the QR code or use the setup key within your Authenticator application. Once added, enter the current passcode below to confirm.
                        </DialogContentText>

                        {qr && <div className={classes.center}><img src={qr} /></div>}

                        <DialogContentText color="textSecondary" className={classes.center}>{code}</DialogContentText>

                        <FormControl className={classes.formControl} fullWidth required error={confirmError ? true : false}>
                            <InputLabel htmlFor="confirmationCode">Confirmation Code</InputLabel>

                            <Input
                                //autoFocus
                                inputRef={inputRef}
                                disabled={isConfirming}
                                value={confirmationCode}
                                onKeyDown={evt => handleKeyDown(evt)}
                                onChange={evt => setConfirmationCode(evt.target.value)}
                                id="confirmationCode"
                                type="text" />

                            {confirmError && <FormHelperText error>{confirmError}</FormHelperText>}
                        </FormControl>
                    </>
                }
            </DialogContent>

            {!isSettingUp && <>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary" disabled={isConfirming}>
                        Cancel
                    </Button>

                    <Button onClick={() => onConfirm(confirmationCode)} color="primary" autoFocus disabled={isConfirming}>
                        Confirm
                    </Button>
                </DialogActions>
                {isConfirming && <LinearProgress variant="indeterminate" color="primary" />}
            </>}
        </Dialog>
    );
};

export default EnableAuthenticatorDialog;