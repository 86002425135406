import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import CancellationNotice from '../../services/CancellationNotice';
import { DirectRoutingPolicies } from '../policies';
import { directRoutingAssignmentUpdateUrl } from '../urls';
import AssignmentSummary from './AssignmentSummary';
import CancelDialog from './CancelDialog';
import { useFetchDirectRoutingAssignment } from './hooks';
import moment = require('moment');
import { useFetchDirectRoutingIPilotCustomers } from '../hooks';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * SIP Trunking assignment view page
 * @param props
 * @constructor
 */
const AssignmentViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);
    const [fetch, isFetchingAssignment, assignment, fetchAssignmentError] = useFetchDirectRoutingAssignment();
    const [fetchCustomers, isFetchingCustomers, ipilotCustomers, fetchCustomersError] = useFetchDirectRoutingIPilotCustomers();

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    useNav('direct-routing', 'organizations');

    useProgressEffects(
        isFetchingAssignment || isFetchingOrganization || isFetchingCustomers,
        fetchAssignmentError || fetchOrganizationError || fetchCustomersError
    );

    useEffect(() => {
        fetch(organizationId, assignmentId);
        fetchCustomers();
    }, [organizationId, assignmentId]);

    const iPilotAccountName = useMemo(() => {
        if (assignment !== null && ipilotCustomers !== null) {
            const customer = ipilotCustomers.find(c => c.id == assignment.iPilotAccountId);

            return customer === undefined ? 'Unknown' : customer.name;
        } else {
            return undefined;
        }
    }, [assignment, ipilotCustomers]);

    const isCancelled = useMemo(() => {
        return !assignment?.isActive;
    }, [assignment]);

    // When service is cancelled, display a notification and refresh data
    const handleCancelComplete = () => {
        dispatch(appWindowAddNotification('Direct Routing service cancelled', 'success'));
        fetch(organizationId, assignmentId);
        setCancelDialogOpen(false);
    }

    return (
        <div className={classes.root}>
            <PageHeader text="Direct Routing for Teams"
                subtext={organization !== null ? organization.name : undefined}>

                {organization !== null
                    && !isCancelled
                    && iPilotAccountName !== undefined
                    && <>
                        {authorizedFor(DirectRoutingPolicies.CanManageAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={directRoutingAssignmentUpdateUrl(organizationId, assignmentId)} {...props} />)}>
                                Update
                            </Button>
                        }

                        {authorizedFor(DirectRoutingPolicies.CanCancelAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                onClick={() => setCancelDialogOpen(true)}
                                variant="text"
                                color="secondary">
                                Cancel Service
                            </Button>
                        }
                    </>
                }
            </PageHeader>

            {organization !== null
                && assignment !== null
                && iPilotAccountName !== undefined
                && <>
                    <div className={classes.marginBottom}>
                        {isCancelled &&
                            <CancellationNotice
                                cancelDate={moment(assignment.cancelledAt).format('MM/DD/YYYY')} />
                        }
                    </div>

                    <div className={classes.marginBottom}>
                        <AssignmentSummary assignment={assignment} accountName={iPilotAccountName} />

                    </div>

                    <CancelDialog
                        isOpen={cancelDialogOpen}
                        assignmentId={assignmentId}
                        organizationId={organizationId}
                        organizationName={organization.name}
                        onComplete={() => handleCancelComplete()}
                        onCancel={() => setCancelDialogOpen(false)}
                    />
                </>
            }
        </div>
    );
};

export default AssignmentViewPage;