import { FormControl, Input, InputLabel, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { HostedPbxOrganization } from '../api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organizations: HostedPbxOrganization[];
    selectedOrganizationId: number | null;
    selectedAssignmentId: number | null;
    onSelect: (organizationId: number | null, assignmentId: number | null) => void;
    disabled?: boolean;
}

export const HostedPbxOrganizationForm = (props: Props) => {
    const classes = useStyles();
    const { organizations, selectedOrganizationId, selectedAssignmentId, onSelect } = props;
    const selected = selectedOrganizationId + '/' + selectedAssignmentId;
    const disabled = props.disabled || false;

    return (
        <FormControl className={classes.formControl} fullWidth required>
            <InputLabel htmlFor="selectedAssignmentId">Organization</InputLabel>
            <Select
                id="selectedAssignmentId"
                native
                margin="none"
                value={selected || ''}
                input={<Input name="selectedAssignmentId" id="selectedAssignmentId" />}
                disabled={disabled}
                onChange={evt => {
                    if (evt.target.value.length > 0) {
                        const [organizationId, assignmentId] = evt.target.value.split('/');
                        onSelect(parseInt(organizationId), parseInt(assignmentId));
                    } else {
                        onSelect(null, null);
                    }
                }}
                onKeyDown={() => false}>
                <option value="" />
                {organizations.map(o => <option key={o.assignmentId} value={o.id + '/' + o.assignmentId}>{o.name} ({o.broadworksGroup})</option>)}
            </Select>
        </FormControl>
    );
};