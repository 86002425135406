import { ApiCaller } from '../../../api';
import { Platform } from '../../hosted-pbx/api';

type DubPointType = 'Api' | 'Recorder' | 'Unidentified';

export interface DubPointBase {
    id: string;
    type: DubPointType;
    label: string;
    number: string;
    status: DubPointStatus;
    dateCreated: string;
    dateUpdated: string;
    ai: boolean;
    externalType: string;
    serviceProvider: string;
    externalGroup: string;
    externalIdentifier: string;
}

export interface DubPoint extends DubPointBase {
    user: string;
    product: string;
    playback: boolean | null;
}

export interface UnidentifiedDubPoint extends DubPointBase {
    recordingCount: number;
}

export interface AssignUnidentifiedDubPoint {
    userId: string;
    dubPointId: string;
    product: string;
    retainUnidentifiedRecordings: boolean;
}

export type DubPointStatus = 'Active' | 'Suspended';

export interface CreateDubPoint {
    user: string;
    type: string;
    product: string;
    externalType: string;
    serviceProvider: string;
    externalGroup: string;
    externalIdentifier: string;
    retainUnidentifiedRecordings: boolean;
}

export interface UpdateDubPoint {
    product: string;
}

export interface SeatOption {
    id: string;
    firstName: string;
    lastName: string;
    department: string;
    extension: string;
    servicePack: string;
    available: boolean;
}

/*
 * GET /api/dubber/:groupId/accounts/:accountId/dub-points
 *
 * Get dub point listing for account
 */

export const fetchDubberDubPoints = async (api: ApiCaller, groupId: string, accountId: string): Promise<DubPoint[]> => {
    return await api<DubPoint[]>(`dubber/${groupId}/accounts/${accountId}/dub-points`, 'GET');
};

/*
 * GET /api/dubber/:groupId/unidentified-dub-points
 *
 * Get unidentified dub point listing
 */

export const fetchDubberUnidentifiedDubPoints = async (api: ApiCaller, groupId: string): Promise<UnidentifiedDubPoint[]> => {
    return await api<UnidentifiedDubPoint[]>(`dubber/${groupId}/unidentified-dub-points`, 'GET');
};

/*
 * GET /api/dubber/:groupId/unidentified-dub-points/:dubPointId
 *
 * Get unidentified dub point detail
 */

export const fetchDubberUnidentifiedDubPoint = async (api: ApiCaller, groupId: string, dubPointId: string): Promise<UnidentifiedDubPoint> => {
    return await api<UnidentifiedDubPoint>(`dubber/${groupId}/unidentified-dub-points/${dubPointId}`, 'GET');
};

/*
 * GET /api/dubber/:groupId/accounts/:accountId/dub-points/{:dubPointId}
 *
 * Get dub point details
 */

export const fetchDubberDubPoint = async (api: ApiCaller, groupId: string, accountId: string, dubPointId: string): Promise<DubPoint> => {
    return await api<DubPoint>(`dubber/${groupId}/accounts/${accountId}/dub-points/${dubPointId}`, 'GET');
};

/*
 * GET /api/dubber/:groupId/accounts/:accountId/dub-points/seat-options/:hpbxGroupId
 *
 * Get dub point details
 */

export const fetchDubberDubPointSeatOptions = async (api: ApiCaller, groupId: string, accountId: string, hpbxGroupId: string, hpbxPlatform: Platform): Promise<SeatOption[]> => {
    return await api<SeatOption[]>(`dubber/${groupId}/accounts/${accountId}/dub-points/seat-options/${hpbxGroupId}`, 'GET', {
        broadworksPlatform: hpbxPlatform
    });
};

/*
 * POST /api/dubber/:groupId/accounts/:accountId/dub-points
 *
 * Create a DUB point
 */

export const createDubberDubPoint = async (api: ApiCaller, groupId: string, accountId: string, dubPoint: CreateDubPoint): Promise<DubPoint> => {
    return await api<DubPoint>(`dubber/${groupId}/accounts/${accountId}/dub-points`, 'POST', {
        ...dubPoint
    });
};

/*
 * PUT /api/dubber/:groupId/accounts/:accountId/dub-points/assign
 *
 * Assign an unidentified DUB point
 */

export const assignUnidentifiedDubberDubPoint = async (api: ApiCaller, groupId: string, accountId: string, dubPoint: AssignUnidentifiedDubPoint): Promise<DubPoint> => {
    return await api<DubPoint>(`dubber/${groupId}/accounts/${accountId}/dub-points/assign`, 'PUT', {
        ...dubPoint
    });
};

/*
 * PUT /api/dubber/:groupId/accounts/:accountId/dub-points/{:dubPointId}
 *
 * Update a DUB point
 */

export const updateDubberDubPoint = async (api: ApiCaller, groupId: string, accountId: string, dubPointId: string, dubPoint: UpdateDubPoint): Promise<DubPoint> => {
    return await api<DubPoint>(`dubber/${groupId}/accounts/${accountId}/dub-points/${dubPointId}`, 'PUT', {
        ...dubPoint
    });
};


/*
 * DELETE /api/dubber/:groupId/accounts/:accountId/dub-points/{:dubPointId}
 *
 * Delete a DUB point
 */

export const deleteDubberDubPoint = async (api: ApiCaller, groupId: string, accountId: string, dubPointId: string): Promise<DubPoint> => {
    return await api<DubPoint>(`dubber/${groupId}/accounts/${accountId}/dub-points/${dubPointId}`, 'DELETE');
};