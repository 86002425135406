import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useDeleteDubberDubPoint, useFetchDubberAccount, useFetchDubberDubPoint, useFetchDubberUsers } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberAccountViewUrl, dubberDubPointUpdateUrl } from '../urls';
import DubPointServiceSection from './DubPointServiceSection';
import DubPointSummary from './DubPointSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const DubPointViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const dubPointId = props.match.params['dubPointId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchDubPoint, isFetchingDubPoint, dubPoint, fetchDubPointError] = useFetchDubberDubPoint();
    const [deleteDubPoint, isDeletingDubPoint, dubPointDeleted, deleteDubPointError] = useDeleteDubberDubPoint();
    const [fetchUsers, isFetchingUsers, users, fetchUsersError] = useFetchDubberUsers();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingDubPoint || isFetchingUsers,
        fetchAccountError || fetchDubPointError || deleteDubPointError || fetchUsersError
    );

    useEffect(() => {
        fetchAccount(groupId, accountId);
        fetchDubPoint(groupId, accountId, dubPointId);
    }, [accountId, dubPointId]);

    useEffect(() => {
        if (dubPoint) {
            fetchUsers(groupId, accountId);
        }
    }, [dubPoint]);

    const confirmDelete = () => {
        deleteDubPoint(groupId, accountId, dubPointId);
    };

    // Back to account view if deleted
    useEffect(() => {
        if (dubPointDeleted) {
            dispatch(appWindowAddNotification('Dub Point deleted.', 'success'));
            history.push(dubberAccountViewUrl(groupId, accountId));
        }
    }, [dubPointDeleted]);

    const [user, userName] = useMemo(() => {
        if (users && dubPoint) {

            const user = (users || []).find(u => u.id === dubPoint.user) || null;

            const userName = dubPoint.user
                ? ((user !== null) ? (user.firstName + " " + user.lastName) : dubPoint.user)
                : 'None';

            return [user, userName];
        }

        return [null, null];
    }, [users, dubPoint]);

    return (
        <div className={classes.root}>
            {dubPoint !== null && account !== null && userName !== null
                && <>
                    <PageHeader text="Dubber Dub Points" subtext={dubPointId}>
                        {authorizedFor(DubberPolicies.CanManageDubPoints, session.roles) && dubPoint.status === 'Active' &&
                            <>
                                {dubPoint.type === 'Recorder' &&
                                    <Button
                                        className={classes.button}
                                        variant="text"
                                        color="primary"
                                        component={React.forwardRef((props, ref) => <Link to={dubberDubPointUpdateUrl(groupId, accountId, dubPointId)} {...props as any} ref={ref} />)}>
                                        Update
                                    </Button>
                                }

                                <Button
                                    className={classes.button}
                                    variant="text"
                                    onClick={() => setDeleteDialogOpen(true)}
                                    color="secondary">
                                    Delete
                                </Button>
                            </>
                        }
                    </PageHeader>

                    <DubPointSummary
                        groupId={groupId}
                        account={account}
                        user={user}
                        username={userName}
                        dubPoint={dubPoint} />

                    <DubPointServiceSection dubPoint={dubPoint} />

                    <ConfirmationDialog
                        title={'Delete Team?'}
                        message={'Are you sure you want to delete this Dub Point?'}
                        showWorking={isDeletingDubPoint}
                        isOpen={deleteDialogOpen}
                        confirmText={'Delete'}
                        cancelText={'Cancel'}
                        onCancel={() => setDeleteDialogOpen(false)}
                        onConfirm={() => confirmDelete()} />
                </>
            }
        </div>
    );
};

export default DubPointViewPage;