import { useEffect, useState } from "react";
import { ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { fetchBandwidthSubAccount, fetchBandwidthSubAccountNumbers, fetchBandwidthSubAccounts, SubAccount, SubAccountIndex, SubAccountNumbers } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredFetchHookResponseWithCode<R, S> = [
    ...TriggeredFetchHookResponse<R, S>,
    number | null
];

type FetchBandwidthSubAccountsFunc = (accountId: string) => void;

/**
 * Hook for fetching all Bandwidth sub-accounts
 */
export const useFetchBandwidthSubAccounts = (): TriggeredFetchHookResponse<FetchBandwidthSubAccountsFunc, SubAccountIndex[]> => {
    interface Request {
        accountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [subAccounts, setSubAccounts] = useState<SubAccountIndex[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchBandwidthSubAccountsFunc = (accountId: string) => {
        setRequest({
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setSubAccounts(null);

                try {
                    const subAccounts = await fetchBandwidthSubAccounts(api, request.accountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setSubAccounts(subAccounts);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setSubAccounts(null);
                            setError('Unable to fetch sub-account.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        subAccounts,
        error
    ];
};

type FetchBandwidthSubAccountFunc = (accountId: string, subAccountId: string) => void;

/**
 * Hook for fetching a Bandwidth sub-account
 */
export const useFetchBandwidthSubAccount = (): TriggeredFetchHookResponseWithCode<FetchBandwidthSubAccountFunc, SubAccount> => {
    interface Request {
        accountId: string;
        subAccountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [subAccount, setSubAccount] = useState<SubAccount | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    const fetch: FetchBandwidthSubAccountFunc = (accountId: string, subAccountId: string) => {
        setRequest({
            accountId,
            subAccountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setSubAccount(null);

                try {
                    const subAccount = await fetchBandwidthSubAccount(api, request.accountId, request.subAccountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setSubAccount(subAccount);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setSubAccount(null);

                            if (e instanceof ResponseError) {
                                setErrorCode(e.code || null);

                            }

                            setError('Unable to fetch sub-account');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        subAccount,
        error,
        errorCode
    ];
};

type FetchBandwidthSubAccountNumbersFunc = (accountId: string, subAccountId: string) => void;

/**
 * Hook for fetching all numbers under a Bandwidth Sub-Account
 */
export const useFetchBandwidthSubAccountNumbers = (): TriggeredFetchHookResponse<FetchBandwidthSubAccountNumbersFunc, SubAccountNumbers> => {
    interface Request {
        accountId: string;
        subAccountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [numbers, setNumbers] = useState<SubAccountNumbers | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchBandwidthSubAccountNumbersFunc = (accountId: string, subAccountId: string) => {
        setRequest({
            accountId,
            subAccountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setNumbers(null);

                try {
                    const response = await fetchBandwidthSubAccountNumbers(api, request.accountId, request.subAccountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setNumbers(response);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setNumbers(null);
                            setError('Unable to fetch numbers.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        numbers,
        error
    ];
};