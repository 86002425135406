import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { SubAccount } from './api';
import AddressBlock from '../AddressBlock';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    subaccount: SubAccount;
}

// Sub-Account Summary Box
const SubAccountSummary = (props: Props) => {
    const classes = useStyles();
    const { subaccount } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                {subaccount.description &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Description</Typography>
                        <Typography>
                            {subaccount.description}
                        </Typography>
                    </Grid>
                }

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Address Type</Typography>
                    <Typography>
                        {subaccount.address.addressType}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Address</Typography>
                    <Typography>
                        <AddressBlock address={subaccount.address} />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default SubAccountSummary;