import fetch from 'cross-fetch'
import { State, Store } from './store';
import { useSelector, useDispatch } from 'react-redux';

// By default, all API requests will use /api/ as the base URL
// This can be changed via configureBaseUrl
let apiBaseUrl = '/api';

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE';

// Fired after successful API call
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';

export type ApiCallSuccessAction = {
    type: typeof API_CALL_SUCCESS,
    payload: {
        url: string;
        method: Methods
    }
}


export const apiCallSuccessAction = (url: string, method: Methods): ApiCallSuccessAction => ({
    type: API_CALL_SUCCESS,
    payload: {
        url,
        method
    }
});

export type Actions =
    | ApiCallSuccessAction;

/**
 * Base interface for all API related errors
 */
export class ApiError {
    public message: string;

    constructor(message: string) {
        this.message = message;
    }
}

/**
 * Thrown if a server's response cannot be parsed as JSON
 */
export class JsonParsingError extends ApiError {
}

/**
 * Thrown when a server sends back a non-OK response
  */
export class ResponseError extends ApiError {
    public code?: number;

    constructor(message: string, code?: number) {
        super(message);
        this.code = code;
    }
}

/**
 * Thrown when a server sends back a 400 response
 * This can include a list of field-specific errors
 */
export class BadRequestError extends ResponseError {
    public fields: Array<FieldError>;

    constructor(message: string, fields: Array<FieldError> = []) {
        super(message);
        this.code = 400;
        this.fields = fields;
    }
}

export interface FieldError {
    field: string;
    message: string;
}

//type Params = { [key: string]: string | string[] | number | number[] | boolean | boolean[] | undefined | null | Params | Params[] };

type Params = { [key: string]: any };

export type ApiCaller = <R>(url: string, method?: Methods, params?: Params) => Promise<R>;

/**
 * Changes the base URL that's prepended to all API requests.
 * By default, this is set to /api
 * @param baseUrl
 */
export function configureBaseUrl(baseUrl: string) {
    apiBaseUrl = baseUrl;
}

/**
 * A hook allowing a React component to utilize the API system
 * The hook allows the current user token to be included in the request
 * if the user is logged in
 */
export function useApi(): ApiCaller {
    const token = useSelector<State, string | null>(state => state.auth.token);
    const dispatch = useDispatch();

    return async <R extends Object>(url: string, method: Methods = 'GET', params?: Params): Promise<R> => {
        var response: any = callApi(url, method, token, params);

        dispatch(apiCallSuccessAction(url, method));

        return response;
    };
}

/**
 * Calls the API with the given URL, method, auth token, and params.
 * This expects the response to be JSON.
 * @param url
 * @param method
 * @param authToken
 * @param params
 * @throws {ApiError} if the request cannot be made
 * @throws {JsonParsingError} if the response cannot be parsed as JSON
 * @throws {BadRequestError} if the response results in a 400 error
 * @throws {ResponseError} if the response results in an error other than 400
 */
async function callApi<R extends Object>(url: string, method: Methods = 'GET', authToken: string|null, params?: Params): Promise<R> {
    const requestUrl = buildRequestUrl(url, method, params);
    const request = buildRequest(method, authToken, params);
    let response = null;
    let json = null;

    try {
        response = await fetch(requestUrl, request);
    } catch(e) {
        throw new ApiError('Unable to communicate with server.');
    }

    if (response !== null) {
        // If response type is JSON, parse it
        let contentType = response.headers.get('Content-Type');

        if (contentType !== null && contentType.indexOf('application/json') > -1) {
            try {
                json = await response.json();
            } catch (e) {
                throw new JsonParsingError('Unable to parse server response.');
            }
        }

        if (!response.ok) {
            let exception = null;

            if (response.status === 400) {
                let fieldErrors: Array<FieldError> = [];

                if ('errors' in json) {
                    for (let i = 0; i < json.errors.length; i++) {
                        const error = json.errors[i];
                        if (error.field !== undefined) {
                            // Field name will be PascalCased from the server. Convert to camelCase.
                            // Nested objects are separated by periods and each layer also needs to be camelCased.
                            const segments = String(error.field).split('.');

                            const fieldName = segments.map(s => s.charAt(0).toLowerCase() + s.slice(1)).join('.');

                            fieldErrors.push({
                                field: fieldName,
                                message: error.message
                            });
                        }
                    }
                }

                exception = new BadRequestError(json.message, fieldErrors)
            } else {
                exception = new ResponseError(json.message, response.status);
            }

            throw exception;
        }
    }

    return json as R;
}

/**
 * Build out the URL to request based off the method and params.
 * @param url
 * @param method
 * @param params
 */
function buildRequestUrl(url: string, method: Methods, params?: Params): string {
    // On GET requests, params are sent as a query string instead of being in body
    let queryString = '';
    if ((method === 'GET') && (params !== undefined)) {
        queryString = `?${buildQueryString(params)}`;
    }

    return `${apiBaseUrl}/${url}${queryString}`;
}

/**
 * Build out the request details
 * @param method
 * @param authToken
 * @param params
 */
function buildRequest(method: Methods, authToken: string|null, params?: Params): Object {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };

    // Body should only get set when the Method isn't GET
    const body = method !== 'GET'
        ? JSON.stringify(params)
        : undefined;

    return {
        method,
        headers: headers,
        body,
        credentials: 'include'
    }
}

/**
 * Build query string for request
 * @param data
 */
export function buildQueryString(data: Params) {
    let query = [];

    for (const key in data) {
        if (data.hasOwnProperty(key) && (data[key] !== undefined)) {
            query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key] as string));
        }
    }

    return query.join('&');
}

/**
 * Many API responses utilize a common structure for pagination
 */
export interface PagedResponse<R> {
    page: number;
    totalPages: number;
    pageSize: number;
    totalItems: number;
    items: Array<R>
}