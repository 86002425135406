import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Service, serviceName } from '../../../services';
import {
    OrganizationType,
    TYPE_ADMINISTRATOR,
    TYPE_ENDCLIENT,
    TYPE_RESELLER
} from '../api';
import { organizationViewUrl } from '../urls';


export interface OrganizationTableItem {
    id: number;
    sapId: string;
    name: string;
    type: OrganizationType;
    services: Array<Service>
}

interface Props {
    items: Array<OrganizationTableItem>
}

const formatType = (type: OrganizationType) => {
    switch (type) {
        case TYPE_ENDCLIENT: return 'End Client';
        case TYPE_RESELLER: return 'Reseller';
        case TYPE_ADMINISTRATOR: return 'Administrator';
    }
};

export default function OrganizationTable(props: Props) {
    const { items } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell>SAP ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Services</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(org => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={organizationViewUrl(org.id)} {...props} />);

                    return (
                        <TableRow key={org.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{org.name}</Link>
                            </TableCell>
                            <TableCell>{org.sapId}</TableCell>
                            <TableCell>{formatType(org.type)}</TableCell>
                            <TableCell>{org.services.map(svc => serviceName(svc)).join(', ')}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}