import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { OrderBase } from './api';
import moment = require('moment');
import { orderStatusDisplay } from './helpers';

const useStyles = makeStyles(() => ({
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

interface Props {
    orders: ListItem[];
    showFocDate: boolean;
    url: (accountId: string, orderId: string) => string;
}

export interface ListItem extends OrderBase {
    focDate?: string;
    number: string;
}

/**
 * Table for displaying a list of orders with basic fields
 * @param props
 */
const OrderIndexTable = (props: Props) => {
    const { showFocDate, orders, url } = props;
    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer Order ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Order Date</TableCell>
                    {showFocDate && <TableCell>FOC Date</TableCell>}
                    <TableCell>Numbers</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orders.map(order => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={url(order.accountId, order.id)} {...props} />);
                    const createDate = moment(order.createDate).format('MM/DD/YYYY hh:mm A');
                    const focDate = order.focDate ? moment(order.focDate).format('MM/DD/YYYY') : '';

                    return (
                        <TableRow key={order.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{order.id}</Link>
                            </TableCell>
                            <TableCell>{order.customerOrderId}</TableCell>
                            <TableCell>{orderStatusDisplay(order.status)}</TableCell>
                            <TableCell className={classes.noWrap}>{createDate}</TableCell>
                            {showFocDate && <TableCell className={classes.noWrap}>{focDate}</TableCell>}
                            <TableCell className={classes.noWrap}>{order.number}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default OrderIndexTable;