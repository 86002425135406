import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import { loginUrl } from "../../auth/urls";
import history from '../../../history';
import { AssignmentForm, AssignmentResults, assignNineLineLocations, fetchNineLineReport, NineLineReport } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/*
 * Hook for fetching 9Line E911 report
 */

type FetchNineLineReportFunc = (assignmentId: number) => void;

export const useFetchNineLineE911Report = (): TriggeredFetchHookResponse<FetchNineLineReportFunc, NineLineReport> => {
    interface Request {
        assignmentId: number;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [report, setReport] = useState<NineLineReport | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);

    const fetch: FetchNineLineReportFunc = (assignmentId: number) => {
        setRequest({
            assignmentId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setError(null);
                setReport(null);
                setValidationError(null);

                try {
                    const report = await fetchNineLineReport(api, request.assignmentId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setReport(report);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if ((e instanceof ResponseError) && (e.code === 400)) {
                            setIsFetching(false);
                            setReport(null);
                            setError(null);
                            setValidationError(e.message);
                        } else {
                            setIsFetching(false);
                            setReport(null);
                            setError('Unable to fetch 9Line details.');
                            setValidationError(null);
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        report,
        error,
        validationError
    ];
};

/*
 * Hook for assinging locations to devices in 9line
 */

type AssignNineLineLocationsFunc = (assignmentId: number, form: AssignmentForm) => void;

export const useAssignNineLineLocations = (): TriggeredSubmitHookResponse<AssignNineLineLocationsFunc, AssignmentResults> => {
    interface Request {
        requestTs: number;
        assignmentId: number;
        form: AssignmentForm;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isAssigning, setIsAssigning] = useState(false);
    const [results, setResults] = useState<AssignmentResults | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const assign = (assignmentId: number, form: AssignmentForm) => {
        setRequest({
            assignmentId,
            form,
            requestTs: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsAssigning(true);
                setResults(null);

                try {
                    const assignmentResults = await assignNineLineLocations(api, request.assignmentId, request.form);

                    if (!didCancel) {
                        setIsAssigning(false);
                        setResults(assignmentResults);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsAssigning(false);
                        } else {
                            setIsAssigning(false);
                            setResults(null);
                            setError('Unable to assign locations');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        assign,
        isAssigning,
        results,
        error,
        fieldErrors
    ]
};