import { ApiCaller, PagedResponse } from '../../api';

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRole[];
}

export const USER_MANAGER = 'UserManager';

export type UserRole =
    typeof USER_MANAGER;

export const USER_ROLES = [
    USER_MANAGER
];

/*
 * GET /api/organizations/:organizationId/users
 *
 * Gets all users within an organization
 */
export const fetchUsers = async (api: ApiCaller, organizationId: number, page: number, limit: number | undefined = undefined): Promise<PagedResponse<User>> => {
    return await api<PagedResponse<User>>(`organizations/${organizationId}/users`, 'GET', {
        page,
        limit
    });
};

/*
 * GET /api/organizations/:organizationId/users/:userId
 *
 * Get a user's details
 */
export const fetchUser = async (api: ApiCaller, organizationId: number, userId: number): Promise<User> => {
    return await api<User>(`organizations/${organizationId}/users/${userId}`, 'GET');
};

/*
 * POST /api/organizations/:organizationId/users
 *
 * Creates a new user
 */

interface CreateUserForm {
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRole[];
    password: string;
}

export const createUser = async (api: ApiCaller, organizationId: number, form: CreateUserForm): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/users`, 'POST', {
        ...form
    });
};

/*
 * PUT /api/organizations/:organizationId/users/:userId
 *
 * Updates an existing user
 */

interface UpdateUserForm {
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRole[];
    // Password can be omitted so it isn't changed
    password?: string;
}

export const updateUser = async (api: ApiCaller, organizationId: number, userId: number, form: UpdateUserForm): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/users/${userId}`, 'PUT', {
        ...form
    });
};

/*
 * DELETE /api/organizations/:organizationId/users/:userId
 *
 * Deletes a user
 */
export const deleteUser = async (api: ApiCaller, organizationId: number, userId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/users/${userId}`, 'DELETE');
};
