import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Account } from '../../accounts/api';
import { bandwidthNumberViewUrl } from '../../urls';
import { NumberBrief } from '../api';
import { formatUsNumber } from '../helpers';

interface Props {
    numbers: NumberBrief[];
    accounts: Account[];
}

const NumberTable = (props: Props) => {
    const { accounts, numbers } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Account</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {numbers.map(n => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n.number)} {...props} />);

                    return (
                        <TableRow key={n.number}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{formatUsNumber(n.number)}</Link>
                            </TableCell>
                            <TableCell>{n.status}</TableCell>
                            <TableCell>{accounts.find(a => a.id === n.accountId)?.name || 'Unknown'}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;