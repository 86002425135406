import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import theme from '../../../theme';

interface Props {
    showWorking: boolean;
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: (removeRecordings: boolean) => void;
}

const useStyles = makeStyles(() => ({
    secondary: {
        marginTop: theme.spacing(1),
        fontSize: '0.9rem'
    },
}));

/**
 * User Deletion confirmation modal
 *
 * @param props
 * @constructor
 */
const DeleteDialog = (props: Props) => {
    const {
        showWorking,
        isOpen,
        onCancel,
        onConfirm
    } = props;

    const cancelColor = 'primary';
    const confirmColor = 'secondary';

    const classes = useStyles();
    const [deleteRecordings, setDeleteRecordings] = useState<boolean>(false);

    const handleCancel = () => {
        if (!showWorking) {
            onCancel();
        }
    };

    const handleConfirm = () => {
        if (!showWorking) {
            onConfirm(deleteRecordings);
        }
    }

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={handleCancel}
            aria-labelledby="confirmation-dialog-title">
            <DialogTitle id="confirmation-dialog-title">Delete User?</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    Are you sure you want to delete this user?
                </DialogContentText>

                <DialogContentText color="textPrimary">
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="deleteRecordings"
                                checked={deleteRecordings}
                                disabled={showWorking}
                                onChange={evt => setDeleteRecordings(!deleteRecordings)}
                                value="1"
                                color="primary"
                            />
                        }
                        label="Delete Recordings" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color={cancelColor} disabled={showWorking}>
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color={confirmColor} autoFocus disabled={showWorking}>
                    Delete
                </Button>
            </DialogActions>
            {showWorking && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

export default DeleteDialog;