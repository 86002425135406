import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatUsNumber } from '../../numbers/helpers';
import { bandwidthNumberViewUrl } from '../../urls';

interface Props {
    numbers: string[];
}


/**
 * Table for displaying a list of numbers on a port-in order
 * @param props
 */
const NumberTable = (props: Props) => {
    const { numbers } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {numbers.map(n => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n)} {...props} />);

                    return (
                        <TableRow key={n}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{formatUsNumber(n)}</Link>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;
