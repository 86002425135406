import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import { Account } from '../api';
import { dubberAccountViewUrl } from '../urls';
import { Team } from './api';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    groupId: string;
    account: Account;
    team: Team;
}

const TeamSummary = (props: Props) => {
    const classes = useStyles();
    const { groupId, account, team } = props;

    const AccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(groupId, account.id)} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>
                        <Link component={AccountLinkComponent}>{account.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">ID</Typography>
                    <Typography>{team.id}</Typography>
                </Grid>

                {team.description &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Description</Typography>
                        <Typography>{team.description}</Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default TeamSummary;