import { VariantType } from 'notistack';
import { SubNavItem, TopNavItem } from './NavDrawer';

export const INITIAL_STATE: AppWindowState = {
    notifications: [],
    activeNav: undefined,
    activeSubNav: undefined,
    showProgressBar: false
};

/**
 * App Window State
 */
export interface AppWindowState {
    notifications: Array<{
        key: string;
        message: string;
        type: VariantType
    }>;
    activeNav?: TopNavItem;
    activeSubNav?: SubNavItem;
    showProgressBar: boolean;
}