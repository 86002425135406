import { useEffect, useState } from 'react';
import { ResponseError, useApi } from '../../api';
import history from '../../history';
import { loginUrl } from '../auth/urls';
import { Customer, fetchDirectRoutingIPilotCustomers } from './api';

export * from './assignment/hooks';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/**
 * Hook for fetching all customers in iPilot
 */

type FetchCustomersFunc = () => void;

export const useFetchDirectRoutingIPilotCustomers = (): TriggeredFetchHookResponse<FetchCustomersFunc, Customer[]> => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [customers, setCustomers] = useState<Customer[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchCustomersFunc = () => {
        setRequestTs(Date.now());
    };

    useEffect(() => {
        if (requestTs !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setCustomers(null);

                try {
                    const response = await fetchDirectRoutingIPilotCustomers(api);
                    if (!didCancel) {
                        setCustomers(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setCustomers([]);
                            setError('Unable to retrieve customers.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [requestTs]);

    return [
        fetch,
        isFetching,
        customers,
        error
    ];
};