import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { checkBandwidthPortability, NumberPortability } from "./api";

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];
type CheckBandwidthPortability = (numbers: string[]) => void;

/**
 * Hook for checking Bandwidth number portability
 */
export const useCheckBandwidthPortability = (): TriggeredSubmitHookResponse<CheckBandwidthPortability, NumberPortability> => {
    interface Request {
        numbers: string[];
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isChecking, setIsChecking] = useState(false);
    const [results, setResults] = useState<NumberPortability | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const check: CheckBandwidthPortability = (numbers: string[]) => {
        setRequest({
            numbers,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsChecking(true);
                setResults(null);

                try {
                    const r = await checkBandwidthPortability(api, request.numbers);

                    if (!didCancel) {
                        setIsChecking(false);
                        setResults(r);
                        setError(null);
                        setFieldErrors([]);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsChecking(false);
                        } else {
                            setIsChecking(false);
                            setResults(null);
                            setError('Unable to check number portability.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        check,
        isChecking,
        results,
        error,
        fieldErrors
    ];
};