import { Button, Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import LocationTable from './LocationTable';
import { HandleAddSeat, HandleChange, SeatForm } from './SeatUpdatePage';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

interface GenericLocationProps {
    seats: SeatForm[];
    onFieldChange: HandleChange;
    onAddSeat: HandleAddSeat;
    isSubmitting: boolean;
}

// Used when organization is Quickbooks and seats are not separated out by ship-to locations

const GenericLocation = (props: GenericLocationProps) => {
    const classes = useStyles();
    const { onAddSeat, onFieldChange, seats, isSubmitting } = props;

    return (
        <>
            <Paper className={classes.paper}>
                <LocationTable seats={seats} onFieldChange={onFieldChange} isSubmitting={isSubmitting} />
                <Button className={classes.addButton} variant="contained" color="inherit" size="small" onClick={evt => onAddSeat()}><Add/></Button>
            </Paper>
        </>
    );
};

export default GenericLocation;