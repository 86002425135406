import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Service } from '../../../services';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    selectedService: Service|null;
    serviceOptions: {
        id: Service;
        label: string;
        disabled: boolean;
    }[];
    onChange: (service: Service) => void;
}

/**
 * Step one of the service assignment wizard.
 * Requires the user to select a service to move to step 2
 *
 * @param props
 * @constructor
 */
const StepOne = (props: Props) => {
    const classes = useStyles();
    const { serviceOptions, selectedService, onChange } = props;

    return (
        <FormControl className={classes.formControl} fullWidth required>
            <FormLabel htmlFor="service">Service</FormLabel>
            <RadioGroup>
                {serviceOptions.map(service =>
                    <FormControlLabel
                        key={service.id}
                        value={service.id}
                        disabled={service.disabled}
                        control={<Radio />}
                        label={service.label}
                        onChange={() => onChange(service.id)}
                        checked={service.id == selectedService}
                    />
                )}
            </RadioGroup>
        </FormControl>
    );
};

export default StepOne;