import { useEffect, useState } from 'react';
import { fetchOrganizationDetails, OrganizationDetails, AssignedService, fetchAssignedServices } from './api';
import { ResponseError, useApi } from '../../api';
import history from '../../history';
import { loginUrl } from '../auth/urls';

/**
 * Hook for retrieving organization details from server
 * @param organizationId
 */
export const useFetchOrganizationDetails = (organizationId: number): [boolean, OrganizationDetails | null, string | null] => {
    const api = useApi();
    const [fetching, setFetching] = useState(false);
    const [org, setOrg] = useState<OrganizationDetails|null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setFetching(true);

            try {
                const response = await fetchOrganizationDetails(api, organizationId);
                if (!didCancel) {
                    setOrg(response);
                    setFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setFetching(false);
                        setError('Unable to retrieve organization details.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [organizationId]);

    return [
        fetching,
        org,
        error
    ];
};

type FetchAssignedServicesResult = [
    boolean,
    AssignedService[] | null,
    string | null
];

/**
 * Hook for retrieving all services assigned to an organization
 * @param organizationId
 */
export const useFetchAssignedServices = (organizationId: number): FetchAssignedServicesResult => {
    const api = useApi();
    const [fetching, setFetching] = useState(false);
    const [services, setServices] = useState<AssignedService[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setFetching(true);

            try {
                const response = await fetchAssignedServices(api, organizationId);
                if (!didCancel) {
                    setServices(response);
                    setFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setFetching(false);
                        setError('Unable to retrieve assigned services.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [organizationId]);

    return [
        fetching,
        services,
        error
    ];
}