import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organization/{organizationId}/services/fax2mail
 *
 * Get all fax2mail assignments for an organization
 */

export interface Assignment {
    id: number;
    startedAt: string;
    isActive: boolean;
    cancelledAt: string | null;
}

export const fetchFax2MailAssignments = async (api: ApiCaller, organizationId: number): Promise<Assignment[]> => {
    return await api<Assignment[]>(`organizations/${organizationId}/services/fax2mail`, 'GET');
};

/*
 * GET /api/organization/{organizationId}/services/fax2mail/{assignmentId}
 *
 * Get fax2mail assignment
 */


export const fetchFax2MailAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/fax2mail/${assignmentId}`, 'GET');
};

export interface AssignmentForm {
};

/*
 * POST /api/organization/{organizationId}/services/fax2mail
 *
 * Assign fax2mail service to an organization
 */

export const createFax2MailAssignment = async (api: ApiCaller, organizationId: number, form: AssignmentForm): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/fax2mail/`, 'POST', {
        ...form
    });
};

/*
 * PUT /api/organization/{organizationId}/services/fax2mail/{assignmentId}
 *
 * Update fax2mail service assignment
 */

export const updateFax2MailAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: AssignmentForm): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/fax2mail/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * DELETE /api/organization/{organizationId}/services/fax2mail/{assignmentId}
 *
 * Cancel a fax2mail service assignment
 */

export const cancelFax2MailAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/fax2mail/${assignmentId}`, 'DELETE');
};
