import {
    AppBar,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import theme from '../../../theme';

interface Props {
    onSubmitSearch: (search: string, limit: number) => void;
}

const useStyles = makeStyles(() => ({
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    block: {
        display: 'block',
    }
}));

export default function SearchBar(props: Props) {
    const classes = useStyles();

    const { onSubmitSearch } = props;

    // The value of the search & limit fields are tracked internally
    const [searchValue, setSearchValue] = useState('');
    const [limitValue, setLimitValue] = useState(DEFAULT_PAGE_SIZE);

    const submit = () => onSubmitSearch(
        searchValue,
        limitValue
    );

    function handleKeypress(evt: React.KeyboardEvent) {
        if (evt.key === 'Enter') {
            submit();
        }
    }

    return (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
                <Grid container spacing={4} alignItems="center">
                    <Grid item>
                        <SearchIcon className={classes.block} color="inherit" />
                    </Grid>

                    <Grid item xs>
                        <TextField
                            fullWidth
                            placeholder="Search devices"
                            value={searchValue}
                            onChange={evt => setSearchValue(evt.target.value)}
                            onKeyPress={handleKeypress}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="per-page">Per Page</InputLabel>
                            <Select
                                fullWidth
                                value={limitValue}
                                displayEmpty={true}
                                disableUnderline
                                input={<Input name="per-page" id="per-page" />}
                                onKeyPress={handleKeypress}
                                onChange={evt => setLimitValue(parseInt(String(evt.target.value)))}>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" onClick={submit}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}