import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organizations/:id/services/fax2mail/:assignmentId/statements
 *
 * Get all Fax2Mail statements for an organization's assignment
 */

export interface Statement {
    id: number;
    billingYear: number;
    billingMonth: number;
    revision: number;
    generatedAt: string;
    totalPagesIn: number;
    totalPagesOut: number;
    totalCharges: number;
    formats: StatementFormat[];
}

export type StatementFormat = 'Excel';

interface FetchStatementsParams {
    billingYear?: number;
    billingMonth?: number;
    limit?: number;
    page?: number;
}

export const fetchFax2MailStatements = async (api: ApiCaller, organizationId: number, assignmentId: number, params?: FetchStatementsParams): Promise<PagedResponse<Statement>> => {
    return api<PagedResponse<Statement>>(`organizations/${organizationId}/services/fax2mail/${assignmentId}/statements`, 'GET', params)
};

export interface LatestStatement {
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    details: Statement;
}

/*
 * GET /api/fax2mail/statements/{billingYear}-{billingMonth}/latest
 * 
 * Get all the latest revisions of fax2mail statements for a billing period
 */

export const fetchFax2MailLatestStatements = async (api: ApiCaller, billingYear: number, billingMonth: number, page?: number, limit?: number): Promise<PagedResponse<LatestStatement>> => {
    return api<PagedResponse<LatestStatement>>(`fax2mail/statements/${billingYear}-${billingMonth}/latest`, 'GET', {
        page,
        limit
    })
};

/*
 * GET /api/organizations/:organizationId/services/fax2mail/:assignmentId/statements/:statementId/:format
 *
 * Get public URL to download statement file
 */

export const fetchFax2MailStatementDownloadUrl = async (api: ApiCaller, organizationId: number, assignmentId: number, statementId: number, format: StatementFormat): Promise<string> => {
    const response = await api<{ url: string }>(`organizations/${organizationId}/services/fax2mail/${assignmentId}/statements/${statementId}/${format}`, 'GET');

    return response.url;
};

/*
 * POST /api/organizations/:id/services/fax2mail/:assignmentId/statements
 * 
 * Create a fax2mail statement for an assignment
 */

export interface CreateStatement {
    organizationId: number;
    assignmentId: number;
    billingYear: number;
    billingMonth: number;
    seats: CreateStatementSeat[]
}

export interface CreateStatementSeat {
    seatId: number;
    pagesIn: number;
    pagesOut: number;
}

export const createFax2MailStatement = async (api: ApiCaller, form: CreateStatement): Promise<{}> => {
    return api<{}>(`organizations/${form.organizationId}/services/fax2mail/${form.assignmentId}/statements`, 'POST', {
        ...form
    })
};