import { ApiCaller, PagedResponse } from '../../api';
import { Platform } from './assignment/api';

export * from './assignment/api';
export * from './bulk-uploads/api';
export * from './9line/api';


/*
 * GET /api/hosted-pbx/groups
 *
 * Get all groups in BroadWorks
 */

export interface GroupItem {
    id: string;
    name: string;
    serviceProvider: string;
}

export const fetchHostedPbxGroups = async (api: ApiCaller, platform: Platform): Promise<GroupItem[]> => {
    return await api<GroupItem[]>(`hosted-pbx/groups`, 'GET', {
        platform
    });
};

/*
 * GET /api/hosted-pbx/groups/:groupId
 *
 * Get all groups in BroadWorks
 */

export interface Group {
    id: string;
    name: string;
    userCount: number;
    departmentCount: number;
}

export const fetchHostedPbxGroup = async (api: ApiCaller, platform: Platform, groupId: string): Promise<Group> => {
    return await api<Group>(`hosted-pbx/groups/${groupId}`, 'GET', {
        platform
    });
};

/*
 * GET /api/hosted-pbx/groups/:groupId/seats
 * 
 * Get all seats in a group
 */

export interface Seat {
    id: string;
    firstName: string;
    lastName: string;
    department: string;
    extension: string;
}

export const fetchHostedPbxSeats = async (api: ApiCaller, platform: Platform, groupId: string): Promise<Seat[]> => {
    return await api<Seat[]>(`hosted-pbx/groups/${groupId}/seats`, 'GET', {
        platform
    });
};


/*
 * GET /api/hosted-pbx/bandwidth-subaccounts
 *
 * Gets all sub accounts in Bandwidth
 */

export interface SubAccountItem {
    id: string;
    name: string;
    description: string | null;
}

export const fetchHostedPbxBandwidthSubAccounts = async (api: ApiCaller, platform: Platform): Promise<SubAccountItem[]> => {
    return await api<SubAccountItem[]>(`hosted-pbx/bandwidth-subaccounts`, 'GET', {
        platform
    });
};

/*(
 * GET /api/hosted-pbx/bandwidth-subaccounts/:subaccount
 *
 * Gets sub account details from Bandwidth
 */

export interface SubAccount {
    id: string;
    name: string;
    description: string | null;
    numberCount: number;
    locationCount: number;
}

export const fetchHostedPbxBandwidthSubAccount = async (api: ApiCaller, subaccountId: string): Promise<SubAccount> => {
    return await api<SubAccount>(`hosted-pbx/bandwidth-subaccounts/${subaccountId}`, 'GET');
};

/*
 * GET /api/hosted-pbx/organizations
 *
 * Fetches a list of organizations assigned the HPBX service
 */
export interface HostedPbxOrganization {
    id: number;
    name: string;
    assignmentId: number;
    platform: Platform;
    phoneConfigurationMethod: 'FTP' | 'DMS',
    broadworksServiceProvider: string;
    broadworksGroup: string;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
    dubberGroup: string;
    dubberAccount: string | null;
    isActive: boolean;
    startedAt: string;
    cancelledAt: string | null;
}

export type Status = 'all' | 'active' | 'cancelled';

export const fetchHostedPbxOrganizations = async (api: ApiCaller, search: string, status: Status = 'active', page: number = 1, limit: number | undefined): Promise<PagedResponse<HostedPbxOrganization>> => {
    return await api<PagedResponse<HostedPbxOrganization>>('hosted-pbx/organizations', 'GET', {
        search,
        status,
        page,
        limit
    });
};

/*
 * GET /api/hosted-pbx/dubber-accounts
 * 
 * Get list of all accounts in the Dubber call recording platform
 */

export interface DubberAccount {
    id: string;
    name: string;
}

export const fetchHostedPbxDubberAccounts = async (api: ApiCaller): Promise<DubberAccount[]> => {
    return await api<DubberAccount[]>(`hosted-pbx/dubber-accounts`, 'GET');
};

/*
 * GET /api/hosted-pbx/dubber-accounts/{accountId}
 * 
 * Get details about a dubber account used by an HPBX client
 */

export interface DubberAccountDetails {
    id: string;
    name: string;
    userCount: number;
    dubPointCount: number;
}

export const fetchHostedPbxDubberAccount = async (api: ApiCaller, accountId: string): Promise<DubberAccountDetails> => {
    return await api<DubberAccountDetails>(`hosted-pbx/dubber-accounts/${accountId}`, 'GET');
};