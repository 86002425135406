import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchDubberAccount, useFetchDubberUser, useUpdateDubberUser } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberUserViewUrl } from '../urls';
import UserUpdateForm, { FormValues } from './UserUpdateForm';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const UserUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const userId = props.match.params['userId'];

    const [fetchUser, isFetchingUser, user, fetchUserError] = useFetchDubberUser();
    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [updateUser, isUpdatingUser, updatedUser, updateUserError, updateUserFieldErrors] = useUpdateDubberUser();

    const [formValues, setFormValues] = useState<FormValues>({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        language: 'en-US'
    });

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingUser || isFetchingAccount,
        fetchUserError || fetchAccountError
    );

    // Fetch user details on load
    useEffect(() => {
        fetchAccount(groupId, accountId);
        fetchUser(groupId, accountId, userId);
    }, [accountId, userId]);

    // Update default form values after load
    useEffect(() => {
        if (user) {
            setFormValues({
                firstName: user.firstName,
                lastName: user.lastName,
                mobileNumber: user.mobileNumber || '',
                language: user.language
            });
        }
    }, [user]);

    const handleUpdate = (field: keyof FormValues, value: string) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Press cancel goes back to the user view
    const handleCancel = () => {
        history.push(dubberUserViewUrl(groupId, accountId, userId));
    };

    const handleSubmit = () => {
        updateUser(groupId, accountId, userId, {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            language: formValues.language,
            mobileNumber: formValues.mobileNumber || null
        });
    };

    // Redirect back to view once updated
    useEffect(() => {
        if (updatedUser) {
            dispatch(appWindowAddNotification('User updated.', 'success'));
            history.push(dubberUserViewUrl(groupId, accountId, updatedUser.id));
        }
    }, [updatedUser]);

    return (
        <div className={classes.root}>
            {user !== null && account !== null
                && <>
                    <PageHeader text="Update User" subtext={`${user.firstName} ${user.lastName}`} />

                    <Paper className={classes.paper}>
                        <UserUpdateForm
                            formValues={formValues}
                            isSubmitting={isUpdatingUser}
                            onUpdate={handleUpdate}
                            onEnter={() => handleSubmit()}
                            errorMessage={updateUserError}
                            fieldErrorMessages={updateUserFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdatingUser}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdatingUser}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(UserUpdatePage, DubberPolicies.CanUpdateUsers);