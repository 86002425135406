import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../../api";
import history from '../../../../history';
import { loginUrl } from "../../../auth/urls";
import { createBandwidthGeneralTnOptionOrder, CreateBandwidthGeneralTnOptionOrderForm, createBandwidthPortOutPasscodeTnOptionOrder, CreateBandwidthPortOutPasscodeTnOptionOrderForm, createBandwidthRoutingTnOptionOrder, CreateBandwidthRoutingTnOptionOrderForm, fetchBandwidthTnOptionOrder, fetchBandwidthTnOptionOrders, TnOptionOrder } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type FetchBandwidthTnOptionOrdersFunc = (accountId: string, number?: string) => void;

/**
 * Hook for fetching TN Option Orders
 * */
export const useFetchBandwidthTnOptionOrders = (): TriggeredFetchHookResponse<FetchBandwidthTnOptionOrdersFunc, TnOptionOrder[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthTnOptionOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [orders, setOrders] = useState<TnOptionOrder[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthTnOptionOrdersRequest {
        accountId: string;
        number?: string;
        ts: number;
    }

    const fetch: FetchBandwidthTnOptionOrdersFunc = (accountId: string, number?: string) => {
        setRequest({
            accountId,
            number,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrders(null);

                try {
                    var orders = await fetchBandwidthTnOptionOrders(api, request.accountId, request.number);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrders(orders);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrders(null);
                            setError('Unable to fetch Bandwidth Orders.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        orders,
        error
    ];
};

type FetchBandwidthTnOptionOrderFunc = (accountId: string, orderId: string) => void;

/**
 * Hook for fetching a TN Option Order
 * */
export const useFetchBandwidthTnOptionOrder = (): TriggeredFetchHookResponse<FetchBandwidthTnOptionOrderFunc, TnOptionOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthTnOptionOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [order, setOrder] = useState<TnOptionOrder | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthTnOptionOrdersRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: FetchBandwidthTnOptionOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrder(null);

                try {
                    var order = await fetchBandwidthTnOptionOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrder(null);
                            setError('Unable to fetch Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        order,
        error
    ];
};

type CreateBandwidthGeneralTnOptionOrderFunc = (accountId: string, form: CreateBandwidthGeneralTnOptionOrderForm) => void;

/**
 * Hook for creating a TN Option Order for General settings
 * */
export const useCreateBandwidthGeneralTnOptionOrder = (): TriggeredSubmitHookResponse<CreateBandwidthGeneralTnOptionOrderFunc, TnOptionOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [createdOrder, setCreatedOrder] = useState<TnOptionOrder | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    interface Request {
        accountId: string;
        form: CreateBandwidthGeneralTnOptionOrderForm;
        ts: number;
    }

    const create: CreateBandwidthGeneralTnOptionOrderFunc = (accountId: string, form: CreateBandwidthGeneralTnOptionOrderForm) => {
        setRequest({
            accountId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setCreatedOrder(null);

                try {
                    const created = await createBandwidthGeneralTnOptionOrder(api, request.accountId, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setCreatedOrder(created);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setCreatedOrder(null);
                            setError('Unable to create Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        createdOrder,
        error,
        fieldErrors
    ];
};

type CreateBandwidthRoutingTnOptionOrderFunc = (accountId: string, form: CreateBandwidthRoutingTnOptionOrderForm) => void;

/**
 * Hook for creating a TN Option Order for Routing settings
 * */
export const useCreateBandwidthRoutingTnOptionOrder = (): TriggeredSubmitHookResponse<CreateBandwidthRoutingTnOptionOrderFunc, TnOptionOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [createdOrder, setCreatedOrder] = useState<TnOptionOrder | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    interface Request {
        accountId: string;
        form: CreateBandwidthRoutingTnOptionOrderForm;
        ts: number;
    }

    const create: CreateBandwidthRoutingTnOptionOrderFunc = (accountId: string, form: CreateBandwidthRoutingTnOptionOrderForm) => {
        setRequest({
            accountId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setCreatedOrder(null);

                try {
                    const created = await createBandwidthRoutingTnOptionOrder(api, request.accountId, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setCreatedOrder(created);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setCreatedOrder(null);
                            setError('Unable to create Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        createdOrder,
        error,
        fieldErrors
    ];
};

type CreateBandwidthPortOutPasscodeTnOptionOrderFunc = (accountId: string, form: CreateBandwidthPortOutPasscodeTnOptionOrderForm) => void;

/**
 * Hook for creating a TN Option Order for Routing settings
 * */
export const useCreateBandwidthPortOutPasscodeTnOptionOrder = (): TriggeredSubmitHookResponse<CreateBandwidthPortOutPasscodeTnOptionOrderFunc, TnOptionOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [createdOrder, setCreatedOrder] = useState<TnOptionOrder | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    interface Request {
        accountId: string;
        form: CreateBandwidthPortOutPasscodeTnOptionOrderForm;
        ts: number;
    }

    const create: CreateBandwidthPortOutPasscodeTnOptionOrderFunc = (accountId: string, form: CreateBandwidthPortOutPasscodeTnOptionOrderForm) => {
        setRequest({
            accountId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setCreatedOrder(null);

                try {
                    const created = await createBandwidthPortOutPasscodeTnOptionOrder(api, request.accountId, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setCreatedOrder(created);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setCreatedOrder(null);
                            setError('Unable to create Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        createdOrder,
        error,
        fieldErrors
    ];
};