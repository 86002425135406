import {
    AppBar,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select, Toolbar
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    block: {
        display: 'block',
    }
}));

interface Props {
    limit: number;
    onSubmitSearch: (limit: number) => void;
}

export default function UnidentifiedDubPointSearchBar(props: Props) {
    const classes = useStyles();
    const { onSubmitSearch, limit } = props;

    const [limitValue, setLimitValue] = useState(limit | DEFAULT_PAGE_SIZE);

    const submit = () => onSubmitSearch(limitValue);

    function handleKeypress(evt: React.KeyboardEvent) {
        if (evt.key === 'Enter') {
            submit();
        }
    }

    return (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="per-page">Per Page</InputLabel>
                            <Select
                                fullWidth
                                value={limitValue}
                                displayEmpty={true}
                                disableUnderline
                                input={<Input name="per-page" id="per-page" />}
                                onKeyPress={handleKeypress}
                                onChange={evt => setLimitValue(parseInt(String(evt.target.value)))}>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" onClick={submit}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}