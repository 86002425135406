import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { Platform } from "../../hosted-pbx/api";
import { assignUnidentifiedDubberDubPoint, AssignUnidentifiedDubPoint, createDubberDubPoint, CreateDubPoint, deleteDubberDubPoint, DubPoint, fetchDubberDubPoint, fetchDubberDubPoints, fetchDubberDubPointSeatOptions, fetchDubberUnidentifiedDubPoint, fetchDubberUnidentifiedDubPoints, SeatOption, UnidentifiedDubPoint, updateDubberDubPoint, UpdateDubPoint } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type FetchDubPointsFunc = (groupId: string, accountId: string) => void;

// Hook for fetching dub points

export const useFetchDubberDubPoints = (): TriggeredFetchHookResponse<FetchDubPointsFunc, DubPoint[]> => {
    interface Request {
        groupId: string;
        accountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [dubPoints, setDubPoints] = useState<DubPoint[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchDubPointsFunc = (groupId: string, accountId: string) => {
        setRequest({
            groupId,
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDubPoints(null);

                try {
                    const dp = await fetchDubberDubPoints(api, request.groupId, request.accountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setDubPoints(dp);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDubPoints(null);
                            setError('Unable to fetch dubber dub points.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        dubPoints,
        error
    ];
};

type FetchUnidentifiedDubPointsFunc = (groupId: string) => void;

// Hook for fetching dub points

export const useFetchDubberUnidentifiedDubPoints = (): TriggeredFetchHookResponse<FetchUnidentifiedDubPointsFunc, UnidentifiedDubPoint[]> => {
    interface Request {
        groupId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [dubPoints, setDubPoints] = useState<UnidentifiedDubPoint[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchUnidentifiedDubPointsFunc = (groupId: string) => {
        setRequest({
            groupId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDubPoints(null);

                try {
                    const dp = await fetchDubberUnidentifiedDubPoints(api, request.groupId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setDubPoints(dp);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDubPoints(null);
                            setError('Unable to fetch dubber dub points.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        dubPoints,
        error
    ];
};

type FetchUnidentifiedDubPointFunc = (groupId: string, dubPointId: string) => void;

// Hook for fetching unidentified dub point

export const useFetchDubberUnidentifiedDubPoint = (): TriggeredFetchHookResponse<FetchUnidentifiedDubPointFunc, UnidentifiedDubPoint> => {
    interface Request {
        groupId: string;
        dubPointId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [dubPoint, setDubPoint] = useState<UnidentifiedDubPoint | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchUnidentifiedDubPointFunc = (groupId: string, dubPointId: string) => {
        setRequest({
            groupId,
            dubPointId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDubPoint(null);

                try {
                    const dp = await fetchDubberUnidentifiedDubPoint(api, request.groupId, request.dubPointId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setDubPoint(dp);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDubPoint(null);
                            setError('Unable to fetch dubber dub point.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        dubPoint,
        error
    ];
};


type FetchDubPointFunc = (groupId: string, accountId: string, dubPointId: string) => void;

// Hook for fetching dub point

export const useFetchDubberDubPoint = (): TriggeredFetchHookResponse<FetchDubPointFunc, DubPoint> => {
    interface Request {
        groupId: string;
        accountId: string;
        dubPointId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [dubPoint, setDubPoint] = useState<DubPoint | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchDubPointFunc = (groupId: string, accountId: string, dubPointId: string) => {
        setRequest({
            groupId,
            accountId,
            dubPointId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDubPoint(null);

                try {
                    const dp = await fetchDubberDubPoint(api, request.groupId, request.accountId, request.dubPointId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setDubPoint(dp);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDubPoint(null);
                            setError('Unable to fetch dubber dub point.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        dubPoint,
        error
    ];
};

// Hook for creating a dubber dub point
type CreateDubPointFunc = (groupId: string, accountId: string, dubPoint: CreateDubPoint) => void;

export const useCreateDubberDubPoint = (): TriggeredSubmitHookResponse<CreateDubPointFunc, DubPoint> => {
    interface Request {
        groupId: string;
        accountId: string;
        dubPoint: CreateDubPoint;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [dubPoint, setDubPoint] = useState<DubPoint | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: CreateDubPointFunc = (groupId: string, accountId: string, dubPoint: CreateDubPoint) => {
        setRequest({
            groupId,
            accountId,
            dubPoint,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setDubPoint(null);

                try {
                    const createdDubPoint = await createDubberDubPoint(api, request.groupId, request.accountId, request.dubPoint);

                    if (!didCancel) {
                        setIsCreating(false);
                        setDubPoint(createdDubPoint);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setDubPoint(null);
                            setError('Unable to create dubber dub point');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isCreating,
        dubPoint,
        error,
        fieldErrors
    ];
};

// Hook for assigning an unidentified dub point
type AssignUnidentifiedDubPointFunc = (groupId: string, accountId: string, dubPoint: AssignUnidentifiedDubPoint) => void;

export const useAssignUnidentifiedDubberDubPoint = (): TriggeredSubmitHookResponse<AssignUnidentifiedDubPointFunc, DubPoint> => {
    interface Request {
        groupId: string;
        accountId: string;
        dubPoint: AssignUnidentifiedDubPoint;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [dubPoint, setDubPoint] = useState<DubPoint | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: AssignUnidentifiedDubPointFunc = (groupId: string, accountId: string, dubPoint: AssignUnidentifiedDubPoint) => {
        setRequest({
            groupId,
            accountId,
            dubPoint,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setDubPoint(null);

                try {
                    const createdDubPoint = await assignUnidentifiedDubberDubPoint(api, request.groupId, request.accountId, request.dubPoint);

                    if (!didCancel) {
                        setIsCreating(false);
                        setDubPoint(createdDubPoint);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setDubPoint(null);
                            setError('Unable to assign dubber dub point');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isCreating,
        dubPoint,
        error,
        fieldErrors
    ];
};

type FetchDubPointSeatOptionsFunc = (groupId: string, accountId: string, hpbxGroupId: string, hpbxPlatform: Platform) => void;

// Hook for fetching seat options for creating a new dub point

export const useFetchDubberDubPointSeatOptions = (): TriggeredFetchHookResponse<FetchDubPointSeatOptionsFunc, SeatOption[]> => {
    interface Request {
        groupId: string;
        accountId: string;
        hpbxGroupId: string;
        hpbxPlatform: Platform;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [options, setOptions] = useState<SeatOption[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchDubPointSeatOptionsFunc = (groupId: string, accountId: string, hpbxGroupId: string, hpbxPlatform: Platform) => {
        setRequest({
            groupId,
            accountId,
            hpbxGroupId,
            hpbxPlatform,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOptions(null);

                try {
                    const dp = await fetchDubberDubPointSeatOptions(api, request.groupId, request.accountId, request.hpbxGroupId, request.hpbxPlatform);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOptions(dp);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOptions(null);
                            setError('Unable to fetch dubber seat options.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        options,
        error
    ];
};

// Hook for updating a dubber dub point
type UpdateDubPointFunc = (groupId: string, accountId: string, dubPointId: string, dubPoint: UpdateDubPoint) => void;

export const useUpdateDubberDubPoint = (): TriggeredSubmitHookResponse<UpdateDubPointFunc, DubPoint> => {
    interface Request {
        groupId: string;
        accountId: string;
        dubPointId: string;
        dubPoint: UpdateDubPoint;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [dubPoint, setDubPoint] = useState<DubPoint | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: UpdateDubPointFunc = (groupId: string, accountId: string, dubPointId: string, dubPoint: UpdateDubPoint) => {
        setRequest({
            groupId,
            accountId,
            dubPointId,
            dubPoint,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setDubPoint(null);

                try {
                    const updatedDubPoint = await updateDubberDubPoint(api, request.groupId, request.accountId, request.dubPointId, request.dubPoint);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setDubPoint(updatedDubPoint);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setDubPoint(null);
                            setError('Unable to update dubber dub point');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isUpdating,
        dubPoint,
        error,
        fieldErrors
    ];
};

type DeleteDubPointFunc = (groupId: string, accountId: string, dubPointId: string) => void;

// Hook for deleting dub point

export const useDeleteDubberDubPoint = (): TriggeredFetchHookResponse<DeleteDubPointFunc, boolean> => {
    interface Request {
        groupId: string;
        accountId: string;
        dubPointId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isComplete, setIsComplete] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchDubPointFunc = (groupId: string, accountId: string, dubPointId: string) => {
        setRequest({
            groupId,
            accountId,
            dubPointId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsDeleting(true);
                setIsComplete(null);

                try {
                    await deleteDubberDubPoint(api, request.groupId, request.accountId, request.dubPointId);

                    if (!didCancel) {
                        setIsDeleting(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsDeleting(false);
                            setIsComplete(null);
                            setError('Unable to delete dubber dub point.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isDeleting,
        isComplete,
        error
    ];
};