import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../../api";
import history from '../../../../history';
import { loginUrl } from "../../../auth/urls";
import { createBandwidthLidbOrder, LidbOrder, LidbOrderForm, fetchBandwidthLidbOrder, fetchBandwidthLidbOrders, LidbOrderListItem } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

/**
 * Hook for fetching LIDB Orders
 * */

type FetchBandwidthLidbOrdersFunc = (accountId: string, number?: string) => void;

export const useFetchBandwidthLidbOrders = (): TriggeredFetchHookResponse<FetchBandwidthLidbOrdersFunc, LidbOrderListItem[]> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthLidbOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [orders, setOrders] = useState<LidbOrderListItem[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthLidbOrdersRequest {
        accountId: string;
        number?: string;
        ts: number;
    }

    const fetch: FetchBandwidthLidbOrdersFunc = (accountId: string, number?: string) => {
        setRequest({
            accountId,
            number,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrders(null);

                try {
                    var orders = await fetchBandwidthLidbOrders(api, request.accountId, request.number);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrders(orders);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrders(null);
                            setError('Unable to fetch Bandwidth Orders.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        orders,
        error
    ];
};

/**
 * Hook for fetching an LIDB Order
 * */

type FetchBandwidthLidbOrderFunc = (accountId: string, orderId: string) => void;

export const useFetchBandwidthLidbOrder = (): TriggeredFetchHookResponse<FetchBandwidthLidbOrderFunc, LidbOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<FetchBandwidthLidbOrdersRequest | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [order, setOrder] = useState<LidbOrder | null>(null);
    const [error, setError] = useState<string | null>(null);

    interface FetchBandwidthLidbOrdersRequest {
        accountId: string;
        orderId: string;
        ts: number;
    }

    const fetch: FetchBandwidthLidbOrderFunc = (accountId: string, orderId: string) => {
        setRequest({
            accountId,
            orderId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setOrder(null);

                try {
                    var order = await fetchBandwidthLidbOrder(api, request.accountId, request.orderId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setOrder(order);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setOrder(null);
                            setError('Unable to fetch Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        order,
        error
    ];
};

/**
 * Hook for creating a LIDB Order
 * */

type CreateBandwidthLidbOrderFunc = (accountId: string, form: LidbOrderForm) => void;

export const useCreateBandwidthLidbOrder = (): TriggeredSubmitHookResponse<CreateBandwidthLidbOrderFunc, LidbOrder> => {
    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [createdOrder, setCreatedOrder] = useState<LidbOrder | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    interface Request {
        accountId: string;
        form: LidbOrderForm;
        ts: number;
    }

    const create: CreateBandwidthLidbOrderFunc = (accountId: string, form: LidbOrderForm) => {
        setRequest({
            accountId,
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setCreatedOrder(null);

                try {
                    const created = await createBandwidthLidbOrder(api, request.accountId, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setCreatedOrder(created);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setCreatedOrder(null);
                            setError('Unable to create Bandwidth Order.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        createdOrder,
        error,
        fieldErrors
    ];
};