import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { createDubberUser, deleteDubberUser, fetchDubberUser, fetchDubberUsers, updateDubberUser, User, UserCreate, UserUpdate } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

// Hook for fetching users under dubber account
type FetchUsersFunc = (groupId: string, accountId: string) => void;

export const useFetchDubberUsers = (): TriggeredFetchHookResponse<FetchUsersFunc, User[]> => {
    interface Request {
        groupId: string;
        accountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [users, setUsers] = useState<User[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchUsersFunc = (groupId: string, accountId: string) => {
        setRequest({
            groupId,
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setUsers(null);

                try {
                    const u = await fetchDubberUsers(api, request.groupId, request.accountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setUsers(u);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setUsers(null);
                            setError('Unable to fetch dubber users.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        users,
        error
    ];
};



// Hook for fetching user details

type FetchUserFunc = (groupId: string, accountId: string, userId: string) => void;

export const useFetchDubberUser = (): TriggeredFetchHookResponse<FetchUserFunc, User> => {
    interface Request {
        groupId: string;
        accountId: string;
        userId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchUserFunc = (groupId: string, accountId: string, userId: string) => {
        setRequest({
            groupId,
            accountId,
            userId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setUser(null);

                try {
                    const u = await fetchDubberUser(api, request.groupId, request.accountId, request.userId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setUser(u);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setUser(null);
                            setError('Unable to fetch dubber user.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        user,
        error
    ];
};

// Hook for creating a dubber user
type CreateUserFunc = (groupId: string, accountId: string, create: UserCreate) => void;

export const useCreateDubberUser = (): TriggeredSubmitHookResponse<CreateUserFunc, User> => {
    interface Request {
        groupId: string;
        accountId: string;
        create: UserCreate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: CreateUserFunc = (groupId: string, accountId: string, create: UserCreate) => {
        setRequest({
            groupId,
            accountId,
            create,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setUser(null);

                try {
                    const createdUser = await createDubberUser(api, request.groupId, request.accountId, request.create);

                    if (!didCancel) {
                        setIsCreating(false);
                        setUser(createdUser);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setUser(null);
                            setError('Unable to create dubber user');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        user,
        error,
        fieldErrors
    ];
};

// Hook for updating a dubber user
type UpdateUserFunc = (groupId: string, accountId: string, userId: string, update: UserUpdate) => void;

export const useUpdateDubberUser = (): TriggeredSubmitHookResponse<UpdateUserFunc, User> => {
    interface Request {
        groupId: string;
        accountId: string;
        userId: string;
        update: UserUpdate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: UpdateUserFunc = (groupId: string, accountId: string, userId: string, update: UserUpdate) => {
        setRequest({
            groupId,
            accountId,
            userId,
            update,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setUser(null);

                try {
                    const updatedUser = await updateDubberUser(api, request.groupId, request.accountId, request.userId, request.update);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setUser(updatedUser);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setUser(null);
                            setError('Unable to update dubber user');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isUpdating,
        user,
        error,
        fieldErrors
    ];
};

// Hook for deleting dubber user

type DeleteUserFunc = (groupId: string, accountId: string, userId: string, removeRecordings: boolean) => void;

export const useDeleteDubberUser = (): TriggeredFetchHookResponse<DeleteUserFunc, boolean> => {
    interface Request {
        groupId: string;
        accountId: string;
        userId: string;
        removeRecordings: boolean;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetch: DeleteUserFunc = (groupId: string, accountId: string, userId: string, removeRecordings: boolean) => {
        setRequest({
            groupId,
            accountId,
            userId,
            removeRecordings,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsDeleting(true);
                setIsDeleted(false);

                try {
                    await deleteDubberUser(api, request.groupId, request.accountId, request.userId, request.removeRecordings);

                    if (!didCancel) {
                        setIsDeleting(false);
                        setIsDeleted(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsDeleting(false);
                            setIsDeleted(false);
                            setError('Unable to delete dubber user.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isDeleting,
        isDeleted,
        error
    ];
};