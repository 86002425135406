import { useEffect, useState } from 'react';
import { ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { BulkUpload, fetchBulkUploads } from '../api';
import { HostedPbxPolicies } from '../policies';


/**
 * Hook for fetching bulk uploads
 */

type OrderBy = 'id' | 'uploadedAt';
type Dir = 'asc' | 'desc';

interface UseFetchBulkUploads {
    fetch: (organizationId: number, assignmentId: number, orderBy: OrderBy, dir: Dir, pageNumber: number, limit?: number) => void;
    isFetching: boolean;
    uploads: BulkUpload[] | null;
    error: string | null;
}

interface Request {
    requestTs: number;
    organizationId: number;
    assignmentId: number;
    orderBy: OrderBy;
    dir: Dir;
    pageNumber: number;
    limit?: number;
}

export const useFetchBulkUploads = (): UseFetchBulkUploads => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [uploads, setUploads] = useState<BulkUpload[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);
    const session = useSession();

    const fetch = (organizationId: number, assignmentId: number, orderBy: OrderBy, dir: Dir, pageNumber: number, limit?: number) => {
        setRequest({
            requestTs: Date.now(),
            organizationId,
            assignmentId,
            orderBy,
            dir,
            pageNumber,
            limit
        })
    };

    useEffect(() => {
        if (request !== null && authorizedFor(HostedPbxPolicies.CanUseBulkUpload, session.roles)) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchBulkUploads(api, request.organizationId, request.assignmentId, request.orderBy, request.dir, request.pageNumber, request.limit);

                    if (!didCancel) {
                        setUploads(response.items);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve uploads.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return {
        fetch,
        isFetching,
        uploads,
        error
    };
};