import { Box, Button, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { BadRequestError, ResponseError, useApi } from '../../../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { deleteOrganization } from '../api';
import { useFetchOrganizationDetails } from '../hooks';
import { OrganizationPolicies } from '../policies';
import { organizationIndexUrl, organizationUpdateUrl, organizationViewUrl } from '../urls';
import ServicesTabContent from './services/ServicesTabContent';
import SummaryBox from './SummaryBox';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    tabBar: {
        marginBottom: theme.spacing(2)
    },
    servicesHeading: {
        marginRight: theme.spacing(1)
    },
    servicesGrid: {
        margin: theme.spacing(1),
        marginLeft: 0
    },
    serviceCard: {
        width: 275
    },
    serviceName: {
        ...theme.typography.h5,
        color: theme.palette.text.primary
    },
    serviceActions: {
        justifyContent: 'space-between'
    },
    removeButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText
        }
    }
}));

type Tabs = 'services' | 'users';

interface Props extends RouteComponentProps<any> {
    activeTab?: string;
}

type DeleteConfirmResponse = [
    () => void,
    boolean,
    string | null
];

// Hook to handle the API calls for deletion
const useDeleteConfirm = (organizationId: number, onSuccess: () => void): DeleteConfirmResponse => {
    const api = useApi();
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<string | null>(null);

    let didCancel = false;

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, []);

    const confirm = useCallback(async () => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            await deleteOrganization(api, organizationId);

            if (!didCancel) {
                setIsDeleting(false);
                onSuccess();
            }
        } catch (e) {
            if (!didCancel) {
                // Redirect to login page if 401
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // Display validation error message
                    setDeleteError(e.message);
                } else if (e instanceof Error) {
                    setDeleteError(e.message);
                }

                setIsDeleting(false);
            }
        }

    }, [organizationId]);

    return [
        confirm,
        isDeleting,
        deleteError
    ];
};

/**
 * Organization view page
 * @param props
 * @constructor
 */
const OrganizationViewPage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const session = useSession();

    // Organization ID is part of the URL
    const organizationId = props.match.params['id'] || props.match.params['organizationId'];

    // Get active tab from props, else default to services view
    const activeTab = props.activeTab || 'services';

    // Use fetcher hook to retrieve organization details
    const [isFetching, organization, error] = useFetchOrganizationDetails(organizationId);

    // Allows the active tab (users, services) to indicate that it's doing activity and should show a progress bar
    const [activeTabHasActivity, setActiveTabHasActivity] = useState(false);

    // Allow for deleting the organization
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [confirmDelete, isDeleting, deleteError] = useDeleteConfirm(organizationId, () => {
        dispatch(appWindowAddNotification('Organization deleted.', 'success'));
        history.push(organizationIndexUrl());
    });

    useNav('organizations');

    useProgressEffects(
        isFetching || isDeleting || activeTabHasActivity,
        error || deleteError
    );

    return (
        <div className={classes.root}>
            {!isFetching &&
                organization !== null &&
                <>
                    <PageHeader text={organization.name}>
                        {authorizedFor(OrganizationPolicies.CanManage, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={organizationUpdateUrl(organizationId)} {...props as any} ref={ref} />)}>
                                Update
                            </Button>
                        }

                        {authorizedFor(OrganizationPolicies.CanManage, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                onClick={() => setDeleteDialogOpen(true)}
                                color="secondary">
                                Delete
                            </Button>
                        }
                    </PageHeader>

                    <SummaryBox
                        sapId={organization.sapId}
                        address={organization.address}
                        address2={organization.address2}
                        city={organization.city}
                        state={organization.state}
                        zip={organization.zip}
                        reseller={organization.reseller}
                    />

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant="fullWidth" value={activeTab}>
                            <Tab
                                label="Services"
                                value="services"
                                component={React.forwardRef((props, ref) => <Link to={organizationViewUrl(organizationId)} {...props} />)} />
                        </Tabs>
                    </Box>

                    {activeTab === 'services' &&
                        <ServicesTabContent
                            organizationId={organizationId}
                            showAddButton={authorizedFor(OrganizationPolicies.CanManage, session.roles)}
                        />
                    }
                </>
            }

            <ConfirmationDialog
                title={'Delete Organization?'}
                message={'Are you sure you want to delete this organization?'}
                showWorking={isDeleting}
                isOpen={deleteDialogOpen}
                confirmText={'Delete'}
                cancelText={'Cancel'}
                onCancel={() => setDeleteDialogOpen(false)}
                onConfirm={() => confirmDelete()} />
        </div>
    );
};

export default OrganizationViewPage;