import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatUsNumber } from '../../numbers/helpers';
import { bandwidthNumberViewUrl } from '../../urls';

interface Props {
    items: NumberItem[];
}

interface NumberItem {
    number: string;
    forwardedNumber: string | null;
    customUser: string | null;
    portOutPasscode: string | null;
    callingNameDisplayed: string | null;
    nnid: string | null;
    failoverUri: string | null;
    sms: string | null;
    status: string;
    notes: string | null;
}

const useStyles = makeStyles(() => ({
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

/**
 * Table for displaying a list of failed and completed numbers on a TN Option order
 * @param props
 */
const NumberTable = (props: Props) => {
    const { items } = props;
    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Calling Name Display</TableCell>
                    <TableCell>Forwarded Number</TableCell>
                    <TableCell>Passcode</TableCell>
                    <TableCell>NNID Assignment</TableCell>
                    <TableCell>Failover URL</TableCell>
                    <TableCell>SMS</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Notes</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {items.map((item, idx) => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(item.number)} {...props} />);

                    return (
                        <TableRow key={idx}>
                            <TableCell component="th" scope="row" className={classes.noWrap}>
                                <Link component={LinkComponent}>{formatUsNumber(item.number)}</Link>
                            </TableCell>
                            <TableCell>{item.callingNameDisplayed}</TableCell>
                            <TableCell>{item.forwardedNumber}</TableCell>
                            <TableCell>{item.portOutPasscode}</TableCell>
                            <TableCell>{item.nnid}</TableCell>
                            <TableCell>{item.failoverUri}</TableCell>
                            <TableCell>{item.sms}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.notes}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;
