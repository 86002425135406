import { Button, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link } from 'react-router-dom';
import theme from '../../../../theme';
import { organizationAssignServiceUrl } from '../../urls';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(1)
    }
}));

interface Props {
    organizationId: number;
    heading?: string;
    showAddButton?: boolean;
}

/**
 * Services header bar
 *
 * @param props
 * @constructor
 */
const ServicesBar = (props: Props) => {
    const classes = useStyles();
    const { organizationId, showAddButton } = props;

    const heading = props.heading || 'Services';

    return (
        <div className={classes.root}>
            <Typography variant="h3">{heading}</Typography>

            {showAddButton &&
                <Button
                    className={classes.button}
                    variant="text"
                    color="primary"
                    component={React.forwardRef((props, ref) => <Link to={organizationAssignServiceUrl(organizationId)} {...props} />)}>
                    Add New
                </Button>
            }
        </div>
    );
};

export default ServicesBar;