import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel, Grid,
    Input,
    InputLabel,
    Typography
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../api';
import { useValidationHelpers } from '../../form-helpers';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    setPassword: boolean;
    password: string;
    confirmPassword: string;
}

interface Props {
    formValues: FormValues;
    isSubmitting: boolean;
    validationMessage: string | null;
    fieldValidationMessages: FieldError[];
    onEnter: () => void;
    onUpdate: (field: keyof FormValues, value: string | boolean) => void;
}

const UserProfileForm = (props: Props) => {
    const classes = useStyles();
    const {
        formValues,
        isSubmitting,
        validationMessage,
        fieldValidationMessages,
        onEnter,
        onUpdate
    } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldValidationMessages);

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onEnter();
        }
    };

    return (
        <>
            {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('firstName')}>
                        <InputLabel htmlFor="firstName">First Name</InputLabel>
                        <Input
                            autoFocus
                            disabled={isSubmitting}
                            id="firstName"
                            value={formValues.firstName}
                            onChange={evt => onUpdate('firstName', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="firstName" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('lastName')}>
                        <InputLabel htmlFor="lastName">Last Name</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="lastName"
                            value={formValues.lastName}
                            onChange={evt => onUpdate('lastName', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="lastName" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('email')}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="email"
                            value={formValues.email}
                            onChange={evt => onUpdate('email', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="email" />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="setPassword"
                                checked={formValues.setPassword}
                                disabled={isSubmitting}
                                onChange={evt => onUpdate('setPassword', !formValues.setPassword)}
                                value="1"
                                color="primary"
                            />
                        }
                        label="Change Password?" />
                </Grid>
            </Grid>

            <Collapse in={formValues.setPassword}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth required error={!isValid('password')}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                disabled={isSubmitting}
                                id="password"
                                value={formValues.password}
                                onChange={evt => onUpdate('password', evt.target.value)}
                                onKeyDown={handleKeyDown}
                                type="password" />
                            <ValidationMessage field="password" />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth required error={!isValid('confirmPassword')}>
                            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                            <Input
                                disabled={isSubmitting}
                                id="confirmPassword"
                                value={formValues.confirmPassword}
                                onChange={evt => onUpdate('confirmPassword', evt.target.value)}
                                onKeyDown={handleKeyDown}
                                type="password" />
                            <ValidationMessage field="confirmPassword" />
                        </FormControl>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default UserProfileForm;