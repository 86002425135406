import { ApiCaller } from '../../../../api';
import { OrderBase, OrderHistory, OrderNote } from '../api';

export interface CreateBandwidthMoveNumberOrderForm {
    // Only required when moving numbers across accounts
    sourceAccountId?: string;
    numbers: string[];
    accountId: string;
    subAccountId: string;
    locationId: string;
    remove911: boolean;
}

/**
 * POST /bandwidth/{accountId}/orders/move-numbers
 * 
 * Create a new move order to move a number from one location to another
 * 
 * @param api
 * @param form
 */
export const createBandwidthMoveNumberOrder = async (api: ApiCaller, form: CreateBandwidthMoveNumberOrderForm): Promise<null> => {
    return await api<null>(`bandwidth/${form.accountId}/orders/move-numbers`, 'POST', {
        ...form
    });
};

export interface MoveNumberOrderSummary extends OrderBase {
    type: 'MoveNumber';
    lastModifiedDate: string;
    sourceAccountId: string;
    numberCount: number;
}

/**
 * GET /bandwidth/{accountId}/orders/move-numbers
 * 
 * Get all Move Number Orders for an account.
 * A number can be specified to get the orders that include that number
 * 
 * @param api
 * @param accountId
 * @param number
 */
export const fetchBandwidthMoveNumberOrders = async (api: ApiCaller, accountId: string, number?: string): Promise<MoveNumberOrderSummary[]> => {
    return await api<MoveNumberOrderSummary[]>(`bandwidth/${accountId}/orders/move-numbers`, 'GET', {
        number
    });
};

export interface MoveNumberOrder extends OrderBase {
    type: 'MoveNumber';
    lastModifiedDate: string;
    sourceAccountId: string;
    subAccountId: string;
    locationId: string;
    numbers: string[];
    errors: MoveNumberOrderError[];
    history: OrderHistory[];
    notes: OrderNote[];
}

export interface MoveNumberOrderError {
    code: string;
    description: string;
    numbers: string[];
}

/**
 * GET /bandwidth/{accountId}/orders/move-numbers/{orderId}
 * 
 * Get a specific Move Number Order
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthMoveNumberOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<MoveNumberOrder> => {
    return await api<MoveNumberOrder>(`bandwidth/${accountId}/orders/move-numbers/${orderId}`, 'GET');
};