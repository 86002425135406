import { Button, FormControl, Grid, LinearProgress, Link, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import theme from '../../../theme';
import { BulkUpload } from '../api';
import { useCreateBulkUpload, useFetchBulkUpload } from './hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface UploadFormProps {
    organizationId: number;
    assignmentId: number;
    onComplete: (upload: BulkUpload) => void;
    showErrors: (errors: string[]) => void;
    downloadUserSheet: (uploadId: number) => void;
}

type Status = 'idle' | 'uploading' | 'processing' | 'error' | 'success';

export const UploadForm = (props: UploadFormProps) => {
    const { organizationId, assignmentId, showErrors, onComplete, downloadUserSheet } = props;
    const classes = useStyles();

    const [error, setError] = useState<string | null>(null);
    const [uploadErrorCount, setUploadErrorCount] = useState(0);
    const [status, setStatus] = useState<Status>('idle');
    const [filename, setFilename] = useState<string | null>(null);
    const { create, isCreating, upload: createdUpload, error: createError, fieldErrors: createFieldErrors } = useCreateBulkUpload();
    const { fetch, isFetching, upload: fetchedUpload, error: fetchError } = useFetchBulkUpload();

    const [activeUpload, setActiveUpload] = useState<BulkUpload | null>(null);

    const fileInput = useRef<HTMLInputElement | null>(null);

    // Clear current state if organization changes
    useEffect(() => {
        setStatus('idle');
        setFilename(null);
    }, [props.assignmentId]);

    // If any error occurred during the upload, show a generic error
    useEffect(() => {
        if ((createFieldErrors !== null && createFieldErrors.length > 0) || createError !== null) {
            setStatus('error');

            setError('File was rejected by the server.');
        }
    }, [createError, createFieldErrors]);

    // Track active upload once started
    useEffect(() => {
        if (createdUpload != null) {
            setActiveUpload(createdUpload);
        }
    }, [createdUpload]);

    // Update active upload when updates are fetched
    useEffect(() => {
        if (fetchedUpload != null) {
            setActiveUpload(fetchedUpload);
        }
    }, [fetchedUpload])


    // If currentUpload is set, then a file was uploaded and we're waiting for it to be processed.
    // Set up a timer to check the status periodically.
    useEffect(() => {
        if (activeUpload !== null) {
            switch (activeUpload.status) {
                case 'Pending':
                case 'Working':
                    setStatus('processing');

                    // If upload is still running, schedule an update
                    const desc = setInterval(() => {
                        if (!isFetching) {
                            fetch(organizationId, assignmentId, activeUpload.id);
                        }
                    }, 3000);

                    return () => {
                        clearInterval(desc);
                    };

                case 'Error':
                    setError(null);
                    setUploadErrorCount(activeUpload.errors.length);
                    setStatus('error');
                    onComplete(activeUpload);
                    break;

                case 'Success':
                    setStatus('success');
                    setError(null);
                    setUploadErrorCount(activeUpload.errors.length);
                    onComplete(activeUpload);
                    break;
            }
        }
    }, [activeUpload]);

    // Clicking "Select File" triggers the file input field to request a file
    const handleSelectFileClick = () => {
        if (fileInput !== null && fileInput.current !== null) {
            fileInput.current.click();
        }
    };

    // Once a file is selected, the contents are read and submitted to the server
    const handleFileSelection = (target: HTMLInputElement) => {
        if (target !== null && target.files !== null && target.files.length == 1) {
            const fileReader = new FileReader();

            fileReader.onerror = (e) => {
                setStatus('error');
                setError('Unable to read file');
                setFilename(null);
            };

            // Once FileReader is done reading the file, upload it to the server
            fileReader.onload = (e) => {
                if (e.target !== null && e.target.result !== null) {
                    setFilename(target && target.files ? target.files[0].name : null);
                    setStatus('uploading');

                    // Upload file
                    const b64content = e.target.result.toString().split(',')[1];

                    create(organizationId, assignmentId, b64content);
                }
            };

            // Reads selected file as a Base64-encoded string
            fileReader.readAsDataURL(target.files[0]);
        }
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth required>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        disabled={status === 'uploading' || status === 'processing'}
                        onClick={handleSelectFileClick}>Select File</Button>

                    <input
                        ref={fileInput}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        id="upload-field"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={evt => handleFileSelection(evt.target)}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={9}>
                {status == 'uploading' &&
                    <>
                        <Typography>Uploading {filename}</Typography>
                        <LinearProgress variant="indeterminate" color="primary" />
                    </>}

                {status == 'processing' &&
                    <>
                        <Typography>Processing {filename}</Typography>
                        <LinearProgress variant="determinate" color="primary" value={activeUpload && activeUpload.pctComplete ? activeUpload.pctComplete : 0} />
                    </>}

                {status == 'success' && activeUpload !== null &&
                    <>
                        <Typography color="default">
                            {activeUpload.errors.length > 0
                                ? <>Upload completed, with <Link href="#" onClick={() => showErrors(activeUpload.errors)} color="secondary">{activeUpload.errors.length} error{activeUpload.errors.length != 1 ? 's' : ''}</Link></>
                                : <>Upload completed</>
                            }
                        </Typography>

                        <Typography component="p"><Link
                            href="#"
                            onClick={() => downloadUserSheet(activeUpload.id)}
                            color="primary">Download User Sheet</Link></Typography>
                    </>}

                {status == 'error' &&
                    <Typography color="error">
                        {uploadErrorCount === 0 && error}

                        {uploadErrorCount > 0 && activeUpload &&
                            <>Upload failed
                                <Typography component="p"><Link
                                    href="#"
                                    onClick={() => showErrors(activeUpload.errors)}
                                    color="secondary">View {uploadErrorCount} Error{uploadErrorCount != 1 ? 's' : ''}</Link></Typography></>}
                    </Typography>
                }
            </Grid>
        </Grid>
    );
};