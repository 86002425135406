import { BadRequestError, FieldError, ResponseError, useApi } from '../../api';
import { useEffect, useState } from 'react';
import { AuthenticatorSetup, confirmAuthenticator, deleteAuthenticator, fetchUserProfile, setupAuthenticator, updateUserProfile, UserProfile, UserProfileUpdate } from './api';
import history from '../../history';
import { loginUrl } from '../auth/urls';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[],
    () => void
];

// Hook for retrieving current user's profile
export const useFetchUserProfile = (): TriggeredFetchHookResponse<() => void, UserProfile>   => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = () => {
        setRequestTs(Date.now());
    };

    useEffect(() => {
        if (requestTs !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setProfile(null);
                setError(null);

                try {
                    const response = await fetchUserProfile(api);
                    if (!didCancel) {
                        setProfile(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve profile.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [requestTs]);

    return [
        fetch,
        isFetching,
        profile,
        error
    ];
};

// Hook for updating current user's profile
type UserProfileUpdateFunc = (update: UserProfileUpdate) => void;

export const useUpdateUserProfile = (): TriggeredSubmitHookResponse<(update: UserProfileUpdate) => void, UserProfile> => {
    interface Request {
        update: UserProfileUpdate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: UserProfileUpdateFunc = (update: UserProfileUpdate) => {
        setRequest({
            update,
            ts: Date.now()
        });
    };

    const reset = () => {
        setError(null);
        setFieldErrors([]);
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setProfile(null);
                setError(null);
                setFieldErrors([]);

                try {
                    const updatedProfile = await updateUserProfile(api, request.update);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setProfile(updatedProfile);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setProfile(null);
                            setError('Unable to update user profile');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isUpdating,
        profile,
        error,
        fieldErrors,
        reset
    ];
};

// Hook for deleting current user's authenticator settings
export const useDeleteAuthenticator = (): TriggeredFetchHookResponse<() => void, boolean> => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const remove = () => {
        setRequestTs(Date.now());
    };

    const reset = () => {
        setError(null);
    };

    useEffect(() => {
        if (requestTs !== null) {
            let didCancel = false;

            (async () => {
                setIsDeleting(true);
                setDeleted(false);
                setError(null);

                try {
                    await deleteAuthenticator(api);

                    if (!didCancel) {
                        setDeleted(true);
                        setIsDeleting(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsDeleting(false);
                            setError('Unable to delete authenticator settings.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [requestTs]);

    return [
        remove,
        isDeleting,
        deleted,
        error
    ];
};

// Hook for setting up Authenticator app for current user
export const useSetupAuthenticator = (): TriggeredSubmitHookResponse<() => void, AuthenticatorSetup> => {
    const api = useApi();
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [isRequesting, setIsRequesting] = useState(false);
    const [result, setResult] = useState<AuthenticatorSetup | null>(null);
    const [error, setError] = useState<string | null>(null);

    const setup = () => {
        setRequestTs(Date.now());
    };

    const reset = () => {
        setError(null);
    };

    useEffect(() => {
        if (requestTs !== null) {
            let didCancel = false;

            (async () => {
                setIsRequesting(true);
                setResult(null);
                setError(null);

                try {
                    const setup = await setupAuthenticator(api);

                    if (!didCancel) {
                        setResult(setup);
                        setIsRequesting(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsRequesting(false);
                            setError('Unable to setup authenticator app.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [requestTs]);

    return [
        setup,
        isRequesting,
        result,
        error,
        [],
        reset
    ];
};

export const useConfirmAuthenticator = (): TriggeredSubmitHookResponse<(code: string) => void, boolean> => {
    interface Request {
        code: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create = (code: string) => {
        setRequest({
            code,
            ts: Date.now()
        });
    };

    const reset = () => {
        setError(null);
        setFieldErrors([]);
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsConfirming(true);
                setComplete(false);
                setError(null);
                setFieldErrors([]);

                try {
                    await confirmAuthenticator(api, request.code);

                    if (!didCancel) {
                        setIsConfirming(false);
                        setComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsConfirming(false);
                        } else {
                            setIsConfirming(false);
                            setComplete(false);
                            setError('Unable to confirm authenticator app');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isConfirming,
        complete,
        error,
        fieldErrors,
        reset
    ];
};