import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchDubberAccount } from '../accounts/hooks';
import { DubberPolicies } from '../policies';
import { dubberTeamIndexUrl } from '../urls';
import { useCreateDubberTeam } from './hooks';
import TeamCreateForm, { FormValues } from './TeamCreateForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const TeamCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [createTeam, isCreatingTeam, createdTeam, createTeamError, createTeamFieldErrors] = useCreateDubberTeam();

    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        description: ''
    });

    useNav('dubber', 'accounts');

    useEffect(() => {
        fetchAccount(groupId, accountId);
    }, [groupId, accountId]);

    useProgressEffects(
        isFetchingAccount,
        fetchAccountError
    );

    const handleUpdate = (field: keyof FormValues, value: string | boolean) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Press cancel goes back to team listing
    const handleCancel = () => {
        history.push(dubberTeamIndexUrl(groupId, accountId));
    };

    const handleSubmit = () => {
        createTeam(groupId, accountId, {
            ...formValues
        });
    };

    // Redirect to team listing once updated
    useEffect(() => {
        if (createdTeam) {
            dispatch(appWindowAddNotification('Team created.', 'success'));

            history.push(dubberTeamIndexUrl(groupId, accountId));
        }
    }, [createdTeam]);

    return (
        <div className={classes.root}>
            {!isFetchingAccount && account &&
                <>
                    <PageHeader text="Create Team" subtext={account.name} />

                    <Paper className={classes.paper}>
                        <TeamCreateForm
                            formValues={formValues}
                            isSubmitting={isCreatingTeam}
                            onUpdate={handleUpdate}
                            onEnter={() => handleSubmit()}
                            errorMessage={createTeamError}
                            fieldErrorMessages={createTeamFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isCreatingTeam}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isCreatingTeam}
                            onClick={handleSubmit}>Create</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(TeamCreatePage, DubberPolicies.CanManageTeams);