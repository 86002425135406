import { Delete } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { LosingCarrier } from '../api';

const useStyles = makeStyles(() => ({
    gridItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0)
    },
    message: {
        marginTop: theme.spacing(1)
    },
    losingCarrier: {
        marginBottom: theme.spacing(3)
    },
    numbers: {
        listStyle: 'none',
        padding: 0,
        margin: 0
    }
}));

interface Props {
    losingCarriers: LosingCarrier[];
}

const LosingCarrierPane = (props: Props) => {
    const classes = useStyles();
    const { losingCarriers } = props;

    return (
        <Grid container>
            <Grid xs={6} item className={classes.gridItem}>
                <Typography variant="h4" color="primary">Losing Carriers ({losingCarriers.length})</Typography>

                {losingCarriers.length > 0
                    ? losingCarriers.map(c => <LosingCarrierView losingCarrier={c} key={c.name} />)
                    : <Typography className={classes.message}>No losing carriers.</Typography>
                }
            </Grid>
        </Grid>
    );
};

const LosingCarrierView = (props: { losingCarrier: LosingCarrier }) => {
    const classes = useStyles();
    const { losingCarrier } = props;

    return <div className={classes.losingCarrier}>
        <Typography variant="h6" className={classes.subheading}>{losingCarrier.name}</Typography>

        <Grid container spacing={1} columnSpacing={2}>
            <Grid item>
                <Typography variant="caption" color="textSecondary">SPID</Typography>
                <Typography>{losingCarrier.spid || 'N/A'}</Typography>
            </Grid>

            <Grid item>
                <Tooltip arrow title='Specifies if the losing carrier requires an account number to do a port. Note: the porting-in carrier may still require an account number even if this is "No"'>
                    <Typography variant="caption" color="textSecondary">
                        Account Number Required
                    </Typography>
                </Tooltip>

                <Typography>{losingCarrier.accountNumberRequired ? "Yes" : "No"}</Typography>
            </Grid>

            <Grid item>
                <Typography variant="caption" color="textSecondary">Wireless</Typography>
                <Typography>{losingCarrier.isWireless ? "Yes" : "No"}</Typography>
            </Grid>

            <Grid item>
                <Tooltip arrow title="Minimum amount of business days required for porting">
                    <Typography variant="caption" color="textSecondary">
                        Min. Porting Interval
                    </Typography>
                </Tooltip>

                <Typography>{losingCarrier.minimumPortingInterval}</Typography>
            </Grid>
        </Grid>

        <Grid container spacing={1} columnSpacing={2}>
            <Grid item>
                <Typography variant="caption" color="textSecondary">Numbers</Typography>
                <Typography component="div">
                    <ul className={classes.numbers}>
                        {losingCarrier.numbers.sort().map(n => <li key={n}>{n}</li>)}
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    </div>;
};

export default LosingCarrierPane;