import { useSnackbar, VariantType } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../store';
import { appWindowRemoveNotification } from './actions';

interface SelectedState {
    notifications: Array<{
        key: string,
        message: string,
        type: VariantType
    }>;
}

/**
 * Displays notifications using notistack
 * @constructor
 */
const Notifier = () => {
    const [displayed, setDisplayed] = useState<Array<string>>([]);

    const { notifications } = useSelector<State, SelectedState>(state => ({
        notifications: state.appWindow.notifications
    }));

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const remove = (key: string) => dispatch(appWindowRemoveNotification(key));


    useEffect(() => {
        notifications.forEach(notification => {
            // Do nothing if snackbar is already displayed
            if (displayed.indexOf(notification.key) > -1) {
                return;
            }

            // Display snackbar using notistack
            enqueueSnackbar(notification.message, {
                variant: notification.type,
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                }
            });

            // Keep track of snackbars that we've displayed
            setDisplayed([...displayed, notification.key]);

            // Dispatch action to remove snackbar from redux store
            remove(notification.key);
        });
    }, [notifications]);

    return <></>;
};

export default Notifier;