import { OrderStatus, OrderType } from './api';

export const orderTypeDisplay = (type: OrderType) => {
    switch (type) {
        case 'Lidb': return 'Calling Name';
        case 'MoveNumber': return 'Move Number';
        case 'NewNumber': return 'New Number';
        case 'TnOption': return 'TN Option';
        case 'PortIn': return 'Port-In';
        default: return type;
    }
};

export const orderStatusDisplay = (status: OrderStatus) => {
    switch (status) {
        case 'AdjustedComplete': return 'Adjusted Complete';
        case 'AdjustedPartial': return 'Adjusted Partial';
        case 'Foc': return 'FOC';
        case 'PartialComplete': return 'Partial Complete';
        case 'PendingDocuments': return 'Pending Documents';
        case 'RequestedCancel': return 'Requested Cancel';
        case 'RequestedSupp': return 'Requested SUPP';
        default: return status;
    }
}