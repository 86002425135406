import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { PortInOrder } from './api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    order: PortInOrder;
}

// Port In Order Information Box
const PortInInformation = (props: Props) => {
    const classes = useStyles();
    const { order } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Port Order Number (PON)</Typography>
                    <Typography>
                        {order.pon}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Port Type</Typography>
                    <Typography>
                        {order.portType}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Full / Partial Port</Typography>
                    <Typography>
                        {order.partialPort ? "Partial" : "Full"}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Billing Telephone Number (BTN)</Typography>
                    <Typography>
                        {order.billingTelephoneNumber}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Losing Carrier</Typography>
                    <Typography>
                        {order.losingCarrierName}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Winning Carrier</Typography>
                    <Typography>
                        {order.vendorName}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Alternative SPID</Typography>
                    <Typography>
                        {order.alternateSpid || 'N/A'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PortInInformation;