import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor, withPolicyRestriction } from '../../auth/policies';
import { Fax2MailPolicies } from '../policies';
import { fax2mailStatementCreateUrl } from '../urls';
import { useFetchFax2MailLatestStatements } from './hooks';
import LatestStatementsTable from './LatestStatementsTable';
import SearchBar from './SearchBar';
import moment = require('moment');

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    }
}));

interface Form {
    billingPeriod?: string;
    page?: number;
    limit?: number;
}

/**
 * Statement list page
 * @param props
 */
const StatementsIndexPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const [fetch, isFetching, statements, fetchError] = useFetchFax2MailLatestStatements();

    const params = parse(location.search);

    const billingPeriodQuery = params['billingPeriod'] !== undefined
        ? String(params['billingPeriod'])
        : undefined;

    const pageQuery = params['page'] !== undefined
        ? parseInt(String(params['page']))
        : undefined;

    const limitQuery = params['limit'] !== undefined
        ? parseInt(String(params['limit']))
        : undefined;

    const [form, setForm] = useState<Form>({
        // Default query state to what's passed in the URL query string
        billingPeriod: moment.utc().subtract(1, 'months').format('YYYY-MM-01'),
        page: pageQuery,
        limit: limitQuery
    });

    // A change in params causes a change in state
    if ((billingPeriodQuery !== form.billingPeriod) || (pageQuery !== form.page) || (limitQuery !== form.limit)) {
        if (billingPeriodQuery !== undefined) {
            setForm({
                ...form,
                page: pageQuery,
                limit: limitQuery,
                billingPeriod: billingPeriodQuery
            })
        }
    }

    const billingPeriod = moment(form.billingPeriod).utc();

    useEffect(() => {
        fetch(
            parseInt(billingPeriod.format('YYYY')),
            parseInt(billingPeriod.format('MM')),
            form.limit,
            form.page
        );
    }, [form])

    useNav('fax2mail', 'statements');

    useProgressEffects(isFetching, fetchError);

    const handleSubmitSearch = (billingPeriod: string, limit?: number) => {
        var query: Record<string, string> = {
            billingPeriod: billingPeriod,
            limit: String(limit),
            page: "1"
        };

        const params = new URLSearchParams(query);

        history.push('?' + params.toString());
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Fax2Mail" subtext="Statements">
                {authorizedFor(Fax2MailPolicies.CanManageStatements, session.roles) &&
                    <Button
                        className={classes.headerButton}
                        variant="text"
                        color="primary"
                        component={React.forwardRef((props, ref) => <Link to={fax2mailStatementCreateUrl()} {...props} />)}>
                        Add New
                    </Button>
                }
            </PageHeader>

            <Paper>
                <SearchBar
                    billingPeriod={form.billingPeriod || moment.utc().format('YYYY-MM')}
                    limit={form.limit}
                    onSubmitSearch={handleSubmitSearch} />

                {statements !== null && statements.totalItems > 0 &&
                    <>
                        <LatestStatementsTable statements={statements.items} />

                        <Pagination
                            totalItems={statements.totalItems}
                            pageSize={statements.pageSize}
                            page={statements.page}
                            disabled={isFetching}
                            additionalParams={{
                                billingPeriod: form.billingPeriod,
                                limit: form.limit
                            }}
                            onChange={() => null} />

                    </>
                }

                {statements !== null && statements.totalItems === 0 &&
                    <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">
                            No statements found.
                        </Typography>
                    </div>
                }
            </Paper>
        </div>
    );
};

export default withPolicyRestriction(StatementsIndexPage, Fax2MailPolicies.CanViewStatements);