import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { ALL_TEAM_NAME } from '../constants';
import { useDeleteDubberTeam, useFetchDubberAccount, useFetchDubberTeam, useFetchDubberTeamMembers } from '../hooks';
import { DubberPolicies } from '../policies';
import TeamMemberTable from '../team-members/TeamMemberTable';
import { dubberAccountViewUrl, dubberTeamMemberCreateUrl } from '../urls';
import TeamSummary from './TeamSummary';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const TeamViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const teamId = props.match.params['teamId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchTeam, isFetchingTeam, team, fetchTeamError] = useFetchDubberTeam();
    const [deleteTeam, isDeletingTeam, teamDeleted, deleteTeamError] = useDeleteDubberTeam();
    const [fetchMembers, isFetchingMembers, members, fetchMembersError] = useFetchDubberTeamMembers();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingTeam || isFetchingMembers,
        fetchAccountError || fetchTeamError || deleteTeamError || fetchMembersError
    );

    useEffect(() => {
        fetchTeam(groupId, accountId, teamId);
        fetchMembers(groupId, accountId, teamId);
        fetchAccount(groupId, accountId);
    }, [accountId, teamId]);

    // Paginate members
    const params = parse(location.search);
    const page = params["page"] !== undefined ? parseInt(String(params["page"])) : 1;
    const limit = DEFAULT_PAGE_SIZE;

    const pagedMembers = useMemo(() => {
        if (members === null) {
            return null;
        }

        return {
            members: members.slice((page - 1) * limit, page * limit),
            totalItems: members.length,
            pageSize: limit,
            page: page
        };
    }, [members, page]);

    const confirmDelete = () => {
        deleteTeam(groupId, accountId, teamId);
    };

    // Back to team listing if deleted
    useEffect(() => {
        if (teamDeleted) {
            dispatch(appWindowAddNotification('Team deleted.', 'success'));
            history.push(dubberAccountViewUrl(groupId, accountId));
        }
    }, [teamDeleted]);

    return (
        <div className={classes.root}>
            {team !== null && account !== null
                && <>
                    <PageHeader text="Dubber Teams" subtext={team.name}>
                        {team && team.name !== ALL_TEAM_NAME && authorizedFor(DubberPolicies.CanManageTeams, session.roles) &&
                            <>
                                <Button
                                    className={classes.button}
                                    variant="text"
                                    onClick={() => setDeleteDialogOpen(true)}
                                    color="secondary">
                                    Delete
                                </Button>
                            </>
                        }
                    </PageHeader>

                    <TeamSummary
                        groupId={groupId}
                        account={account}
                        team={team} />

                    {members !== null && pagedMembers !== null &&
                        <div className={classes.section}>
                            <div className={classes.subheading}>
                                <Typography variant="h3">Members</Typography>
                                {authorizedFor(DubberPolicies.CanManageTeams, session.roles) &&
                                    <Button
                                        className={classes.button}
                                        variant="text"
                                        color="primary"
                                        component={React.forwardRef((props, ref) => <Link to={dubberTeamMemberCreateUrl(groupId, accountId, teamId)} {...props} />)}>
                                        Add Member/Dub Point
                                    </Button>
                                }
                            </div>

                            {pagedMembers.totalItems === 0
                                ? <Typography variant="body2">No users have been assigned to this team.</Typography>
                                : <Paper className={classes.paper}>
                                    <TeamMemberTable
                                        accountId={accountId}
                                        groupId={groupId}
                                        teamId={teamId}
                                        members={pagedMembers?.members || []} />

                                    <Pagination
                                        totalItems={pagedMembers.totalItems}
                                        pageSize={pagedMembers.pageSize}
                                        page={pagedMembers.page}
                                        disabled={false}
                                        onChange={() => null} />
                                </Paper>
                            }
                        </div>
                    }

                    <ConfirmationDialog
                        title={'Delete Team?'}
                        message={'Are you sure you want to delete this team?'}
                        showWorking={isDeletingTeam}
                        isOpen={deleteDialogOpen}
                        confirmText={'Delete'}
                        cancelText={'Cancel'}
                        onCancel={() => setDeleteDialogOpen(false)}
                        onConfirm={() => confirmDelete()} />
                </>
            }
        </div>
    );
};

export default TeamViewPage;