import { ApiCaller } from '../../../api';

export interface NumberPortability {
    portableNumbers: string[];
    unportableNumbers: string[];
    portabilityErrors: PortabilityError[];
    rateCenters: RateCenter[];
    losingCarriers: LosingCarrier[];
}

export interface PortabilityError {
    number: string;
    error: string;
}

export interface RateCenter {
    name: string;
    city: string;
    state: string;
    lata: string;
    tiers: number[];
    numbers: string[];
    isSupported: boolean;
}

export interface LosingCarrier {
    name: string;
    spid: string;
    isWireless: boolean;
    accountNumberRequired: boolean;
    minimumPortingInterval: number;
    numbers: string[];
    isSupported: boolean;
}

/**
 * POST /bandwidth/port-check
 * 
 * Check portability status of numbers
 * 
 * @param api
 * @param numbers
 */
export const checkBandwidthPortability = async (api: ApiCaller, numbers: string[]): Promise<NumberPortability> => {
    return await api<NumberPortability>(`bandwidth/port-check`, 'POST', {
        numbers
    });
};