import { ApiCaller } from '../../../api';

/*
 * GET /api/webex/dubber-accounts
 *
 * Get all dubber accounts in group for webex
 */

export interface DubberAccount {
    id: string;
    name: string;
}

export const fetchWebexDubberAccounts = async (api: ApiCaller): Promise<DubberAccount[]> => {
    return await api<DubberAccount[]>('webex/dubber-accounts', 'GET');
};