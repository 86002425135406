import { Button, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useDownloadStatement } from '../hooks';
import { Statement } from '../statements/api';
import moment = require('moment');

const padMonth = (i: number) => i < 10 ? ('0' + i) : i;

const useStyles = makeStyles(() => ({
    nowrap: {
        'white-space': 'nowrap'
    }
}));

interface Props {
    organizationId: number;
    assignmentId: number;
    statement: Statement;
}

/**
 * Row in the statement listing table
 *
 * @param props
 * @constructor
 */
const StatementRow = (props: Props) => {
    const { organizationId, assignmentId, statement } = props;
    const classes = useStyles();
    const billingMonth = moment(`${statement.billingYear}-${padMonth(statement.billingMonth)}-01`).utc().format('MMM, Y');
    const download = useDownloadStatement();
    const generatedDate = moment(statement.generatedAt).format('MM/DD/YYYY');
    const generatedTime = moment(statement.generatedAt).format('hh:mm A');

    return (
        <TableRow>
            <TableCell component="th" scope="row">{billingMonth}</TableCell>
            <TableCell>{statement.revision}</TableCell>
            <TableCell>{generatedDate}<br />{generatedTime}</TableCell>
            <TableCell className={classes.nowrap}>{statement.totalPagesIn} / {statement.totalPagesOut}</TableCell>
            <TableCell className={classes.nowrap}>$ {statement.totalCharges.toFixed(2)}</TableCell>
            <TableCell>
                {statement.formats.map(format => {
                    return (
                        <Button
                            key={format}
                            variant="text"
                            onClick={() => download(organizationId, assignmentId, statement.id, format)}
                            color="primary">
                            {format}
                        </Button>
                    );
                })}
            </TableCell>
        </TableRow>
    );
};

export default StatementRow;