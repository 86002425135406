import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, List,
    ListItemText, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { AssignmentResults } from './api';

interface Props {
    isOpen: boolean;
    results: AssignmentResults | null;
    onClose: () => void;
}

const useStyles = makeStyles(() => ({
    dialog: {
        minWidth: '800px'
    },
    center: {
        textAlign: 'center'
    }
}));

const AssignmentDialog = (props: Props) => {
    const { isOpen, results, onClose } = props;
    const classes = useStyles();

    return (
        <Dialog
            fullScreen={false}
            maxWidth="lg"
            className={classes.dialog}
            open={isOpen}>
            <DialogContent>
                <DialogContentText>
                    {results === null && <CircularProgress />}

                    {results !== null && <>
                        <div className={classes.center}>
                            {results.failures.length === 0 && <CheckCircleOutline color="success" fontSize="large" />}
                            {results.failures.length > 0 && <ErrorOutline color="error" fontSize="large" />}
                        </div>

                        {results.failures.length > 0
                            ? <Typography color="secondary">
                                <div className={classes.center}>{results.assigned.length} device{results.assigned.length != 1 ? 's' : ''} could not be updated</div>
                                <List>
                                    {results.failures.map(f => <ListItemText>{f.macAddress}: {f.message}</ListItemText>)}
                                </List>
                            </Typography>
                            : <Typography className={classes.center}>{results.assigned.length} device{results.assigned.length != 1 ? 's' : ''} ha{results.assigned.length == 1 ? 's' : 've'} been updated</Typography>
                        }
                    </>}
                </DialogContentText>
            </DialogContent>

            {results !== null &&
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary" disabled={false}>
                        Close
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default AssignmentDialog;