import { useEffect, useState } from "react";
import { FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { fetchBandwidthNumber, fetchBandwidthNumbers, Number, NumberPage } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredFetchHookResponseWithCode<R, S> = [
    ...TriggeredFetchHookResponse<R, S>,
    number | null
];

type FetchBandwidthNumbersFunc = (accountId?: string, search?: string, page?: string, limit?: number) => void;

/**
 * Hook for fetching Bandwidth numbers
 */
export const useFetchBandwidthNumbers = (): TriggeredFetchHookResponse<FetchBandwidthNumbersFunc, NumberPage> => {
    interface Request {
        accountId?: string;
        search?: string;
        page?: string;
        limit?: number;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [numbers, setNumbers] = useState<NumberPage | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchBandwidthNumbersFunc = (accountId?: string, search?: string, page?: string, limit?: number) => {
        setRequest({
            accountId,
            search,
            page,
            limit,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setNumbers(null);

                try {
                    const numbers = await fetchBandwidthNumbers(api, request.accountId, request.search, request.page, request.limit);

                    if (!didCancel) {
                        setIsFetching(false);
                        setNumbers(numbers);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setNumbers(null);
                            setError('Unable to fetch numbers.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        numbers,
        error
    ];
};

type FetchBandwidthNumberFunc = (number: string) => void;

/**
 * Hook for fetching a Bandwidth number's details
 */
export const useFetchBandwidthNumber = (): TriggeredFetchHookResponseWithCode<FetchBandwidthNumberFunc, Number> => {
    interface Request {
        number: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [number, setNumber] = useState<Number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    const fetch: FetchBandwidthNumberFunc = (number: string) => {
        setRequest({
            number,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setNumber(null);

                try {
                    const number = await fetchBandwidthNumber(api, request.number);

                    if (!didCancel) {
                        setIsFetching(false);
                        setNumber(number);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setNumber(null);

                            if (e instanceof ResponseError) {
                                setErrorCode(e.code || null);

                            }

                            setError('Unable to fetch number.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        number,
        error,
        errorCode
    ];
};