import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { createBandwidthLocation, deleteBandwidthLocation, fetchBandwidthLocation, fetchBandwidthLocationNumbers, fetchBandwidthLocations, Location, LocationForm, NumbersPage, updateBandwidthLocation } from './api';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type TriggeredFetchHookResponseWithCode<R, S> = [
    ...TriggeredFetchHookResponse<R, S>,
    number | null
];

type FetchBandwidthLocationsFunc = (accountId: string, subAccountId: string) => void;

/**
 * Hook for fetching Bandwidth locations under a sub-account
 */
export const useFetchBandwidthLocations = (): TriggeredFetchHookResponse<FetchBandwidthLocationsFunc, Location[]> => {
    interface Request {
        accountId: string;
        subAccountId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [locations, setLocations] = useState<Location[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchBandwidthLocationsFunc = (accountId: string, subAccountId: string) => {
        setRequest({
            accountId,
            subAccountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setLocations(null);

                try {
                    const locations = await fetchBandwidthLocations(api, request.accountId, request.subAccountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setLocations(locations);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setLocations(null);
                            setError('Unable to fetch locations.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        locations,
        error
    ];
};

type FetchBandwidthLocationFunc = (accountId: string, subAccountId: string, locationId: string) => void;

/**
 * Fetch a Bandwidth location under a sub-account 
 */
export const useFetchBandwidthLocation = (): TriggeredFetchHookResponseWithCode<FetchBandwidthLocationFunc, Location> => {
    interface Request {
        accountId: string;
        subAccountId: string;
        locationId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [location, setLocation] = useState<Location | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    const fetch: FetchBandwidthLocationFunc = (accountId: string, subAccountId: string, locationId: string) => {
        setRequest({
            accountId,
            subAccountId,
            locationId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setLocation(null);

                try {
                    const location = await fetchBandwidthLocation(api, request.accountId, request.subAccountId, request.locationId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setLocation(location);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setLocation(null);

                            if (e instanceof ResponseError) {
                                setErrorCode(e.code || null);

                            }

                            setError('Unable to fetch location');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        location,
        error,
        errorCode
    ];
};

type CreateUpdateLocationFunc = (form: LocationForm) => void;

interface CreateUpdateLocationRequest {
    form: LocationForm;
    ts: number;
}

/**
 * Hook for creating a new bandwidth location under a sub-account
 * */
export const useCreateBandwidthLocation = (): TriggeredSubmitHookResponse<CreateUpdateLocationFunc, Location> => {
    const api = useApi();
    const [request, setRequest] = useState<CreateUpdateLocationRequest | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [location, setLocation] = useState<Location | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const create: CreateUpdateLocationFunc = (form: LocationForm) => {
        setRequest({
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setLocation(null);

                try {
                    const createdLocation = await createBandwidthLocation(api, request.form);

                    if (!didCancel) {
                        setIsCreating(false);
                        setLocation(createdLocation);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setLocation(null);
                            setError('Unable to create Bandwidth location.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        create,
        isCreating,
        location,
        error,
        fieldErrors
    ];
};

/**
 * Hook for updating bandwidth location under a sub-account
 * */
export const useUpdateBandwidthLocation = (): TriggeredSubmitHookResponse<CreateUpdateLocationFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<CreateUpdateLocationRequest | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: CreateUpdateLocationFunc = (form: LocationForm) => {
        setRequest({
            form,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsUpdating(true);
                setSuccess(false);

                try {
                    await updateBandwidthLocation(api, request.form);

                    if (!didCancel) {
                        setIsUpdating(false);
                        setSuccess(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsUpdating(false);
                        } else {
                            setIsUpdating(false);
                            setSuccess(false);
                            setError('Unable to update Bandwidth location.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isUpdating,
        success,
        error,
        fieldErrors
    ];
};

type DeleteLocationFunc = (accountId: string, subAccountId: string, locationId: string) => void;

interface DeleteLocationRequest {
    accountId: string;
    subAccountId: string;
    locationId: string;
    ts: number;
}

/**
 * Remove a Bandwidth location
 * */
export const useDeleteBandwidthLocation = (): TriggeredFetchHookResponse<DeleteLocationFunc, boolean> => {
    const api = useApi();
    const [request, setRequest] = useState<DeleteLocationRequest | null>(null);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const cancel: DeleteLocationFunc = (accountId: string, subAccountId: string, locationId: string) => {
        setRequest({
            accountId,
            subAccountId,
            locationId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCancelling(true);
                setIsComplete(false);

                try {
                    await deleteBandwidthLocation(api, request.accountId, request.subAccountId, request.locationId);

                    if (!didCancel) {
                        setIsCancelling(false);
                        setIsComplete(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsCancelling(false);
                            setIsComplete(false);
                            setError('Unable to delete Bandwidth location.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        cancel,
        isCancelling,
        isComplete,
        error
    ];
};

type FetchBandwidthLocationNumbersFunc = (accountId: string, subAccountId: string, locationId: string, page?: string, limit?: string) => void;

/**
 * Hook for fetching numbers in a location
 */
export const useFetchBandwidthLocationNumbers = (): TriggeredFetchHookResponse<FetchBandwidthLocationNumbersFunc, NumbersPage> => {
    interface Request {
        accountId: string;
        subAccountId: string;
        locationId: string;
        page?: string;
        limit?: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState<NumbersPage | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchBandwidthLocationNumbersFunc = (accountId: string, subAccountId: string, locationId: string, page?: string, limit?: string) => {
        setRequest({
            accountId,
            subAccountId,
            locationId,
            page,
            limit,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setPage(null);

                try {
                    const response = await fetchBandwidthLocationNumbers(api, request.accountId, request.subAccountId, request.locationId, request.page, request.limit);

                    if (!didCancel) {
                        setIsFetching(false);
                        setPage(response);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setPage(null);
                            setError('Unable to fetch numbers.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        page,
        error
    ];
};