import { Card, CardActionArea, CardContent, LinearProgress, Typography } from '@mui/material';
import { DialerSip, Route } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFetchDirectRoutingAssignment } from '../../../direct-routing/assignment/hooks';
import { directRoutingAssignmentViewUrl } from '../../../direct-routing/urls';
import moment = require('moment');
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        width: 275
    },
    serviceName: {
        ...theme.typography.h5,
        color: theme.palette.text.primary
    },
    details: {
        marginTop: theme.spacing(1)
    }
}));

interface Props {
    organizationId: number;
    assignmentId: number;
}

/**
 * A card in the assigned services grid when the service is Direct Routing
 *
 * @param props
 * @constructor
 */
const DirectRoutingCard = (props: Props) => {
    const classes = useStyles();
    const { organizationId, assignmentId } = props;

    const [fetch, isFetching, assignment, error] = useFetchDirectRoutingAssignment();

    const formatCancelDate = useCallback((ts: any) => moment(ts).utc().format('MM/DD/YYYY'), []);

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [organizationId, assignmentId])

    return (
        <Card className={classes.root}>
            <CardActionArea component={React.forwardRef((props, ref) => <Link to={directRoutingAssignmentViewUrl(organizationId, assignmentId)} {...props} />)}>
                <CardContent>
                    <Typography className={classes.serviceName} component="h4">
                        <Route fontSize="small" /> Direct Routing for Teams
                    </Typography>

                    {!isFetching && assignment != null &&
                        <Typography className={classes.details} component="p">
                            Account ID: {assignment.iPilotAccountId}
                            {!assignment.isActive && <><br />Cancelled: {formatCancelDate(assignment.cancelledAt)}</>
                            }
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>

            {isFetching && <LinearProgress variant="indeterminate" color="primary" />}
        </Card>
    );
};

export default DirectRoutingCard;