import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useSession } from '../../../auth/hooks';
import { authorizedFor } from '../../../auth/policies';
import { useFetchDubberUnidentifiedDubPoint } from '../../hooks';
import { DubberPolicies } from '../../policies';
import { dubberUnidentifiedDubPointUpdateUrl } from '../../urls';
import DubPointServiceSection from '../DubPointServiceSection';
import UnidentifiedDubPointSummary from './UnidentifiedDubPointSummary';


const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for viewing an unidentified dub point
const UnidentifiedDubPointViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const groupId = props.match.params['groupId'];
    const dubPointId = props.match.params['dubPointId'];

    const [fetch, isFetching, dubPoint, errorMessage] = useFetchDubberUnidentifiedDubPoint();

    // Update navigation
    useNav('dubber', 'unidentified-dub-points');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        errorMessage
    );

    useEffect(() => {
        fetch(groupId, dubPointId);
    }, [groupId, dubPointId]);

    return (
        <div className={classes.root}>
            {dubPoint !== null &&
                <>
                    <PageHeader text="Unidentified Dub Points" subtext={dubPointId}>
                        {authorizedFor(DubberPolicies.CanManageDubPoints, session.roles) && dubPoint.type === 'Unidentified' &&
                            <Button
                                className={classes.button}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={dubberUnidentifiedDubPointUpdateUrl(groupId, dubPointId)} {...props as any} ref={ref} />)}>
                                Assign
                            </Button>
                        }
                    </PageHeader>

                    <UnidentifiedDubPointSummary
                        groupId={groupId}
                        dubPoint={dubPoint} />

                    <DubPointServiceSection dubPoint={dubPoint} />
                </>
            }
        </div>
    );
};

export default UnidentifiedDubPointViewPage;