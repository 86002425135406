import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatUsNumber } from '../../numbers/helpers';
import { bandwidthNumberViewUrl } from '../../urls';
import { CompletedNumber, NewNumberOrderError } from './api';

interface Props {
    errors: NewNumberOrderError[];
    completedNumbers: CompletedNumber[];
    failedNumbers: string[];
}


/**
 * Table for displaying a list of failed and completed numbers on a new number order
 * @param props
 */
const NumberTable = (props: Props) => {
    const { completedNumbers, errors, failedNumbers } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Rate Center</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {failedNumbers.map(n => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n)} {...props} />);
                    const description = errors.find(e => e.number == n)?.description;

                    return (
                        <TableRow key={n}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{formatUsNumber(n)}</Link>
                            </TableCell>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell>Failed</TableCell>
                            <TableCell>{description}</TableCell>
                        </TableRow>
                    )
                })}

                {completedNumbers.map(cn => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(cn.fullNumber)} {...props} />);

                    return (
                        <TableRow key={cn.fullNumber}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{formatUsNumber(cn.fullNumber)}</Link>
                            </TableCell>
                            <TableCell>{cn.rateCenter}</TableCell>
                            <TableCell>{cn.city}</TableCell>
                            <TableCell>{cn.state}</TableCell>
                            <TableCell>Complete</TableCell>
                            <TableCell/>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;
