export function formatUsNumber(n: string) {
    if (n.length === 10) {
        var npa = n.substring(0, 3);
        var nxx = n.substring(3, 6);
        var xxxx = n.substring(6);

        return `(${npa}) ${nxx}-${xxxx}`;
    } else {
        return n;
    }
}