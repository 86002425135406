import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import AdministratorForm, { FormValues } from '../AdministratorForm';
import { updateAdministrator } from '../api';
import { useFetchAdministrator } from '../hooks';
import { AdministratorPolicies } from '../policies';
import { administratorIndexUrl } from '../urls';


const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface FormHandler {
    isSubmitting: boolean;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    errorMessage: string | null;
    submit: (formValues: FormValues) => void;
}

// Hook for handling the form submission
const useFormHandler = (administratorId: number, callback: () => void): FormHandler => {
    const api = useApi();
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validationFieldMessages, setValidationFieldMessages] = useState<FieldError[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Validates that all fields are filled out.
    // If they are, validate that password and confirm password are equal
    const validate = (formValues: FormValues) => {
        const requiredFields: Array<keyof FormValues> = [
            'firstName',
            'lastName',
            'email'
        ];

        if (formValues.setPassword) {
            requiredFields.push('password');
            requiredFields.push('confirmPassword');
        }

        let fieldErrors: FieldError[] = [];

        requiredFields.forEach(field => {
            let value = formValues[field] !== undefined ? String(formValues[field]) : '';

            if (value.length === 0) {
                fieldErrors.push({
                    field,
                    message: 'Field is required.'
                })
            }
        });

        if (fieldErrors.length === 0 && formValues.setPassword) {
            if (formValues.password !== formValues.confirmPassword) {
                fieldErrors.push({
                    field: 'password',
                    message: 'Passwords do not match.'
                });

                fieldErrors.push({
                    field: 'confirmPassword',
                    message: 'Passwords do not match.'
                });
            }
        }

        setValidationFieldMessages(fieldErrors);

        return fieldErrors.length === 0;
    };

    const submit = (formValues: FormValues) => {
        // Do some local validation before sending it to the server.
        // This is because we have a field (confirmPassword) which is not part of the API
        if (validate(formValues)) {
            (async () => {
                setIsSubmitting(true);

                try {
                    // Call API to update administrator
                    await updateAdministrator(api, administratorId, {
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        email: formValues.email,
                        roles: formValues.roles,
                        password: formValues.setPassword
                            ? formValues.password
                            : undefined
                    });

                    callback();
                } catch (e) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else if (e instanceof BadRequestError) {
                        // For bad request errors, we'll either display error messages under the fields
                        // or display a generic error message if no field errors are listed
                        setValidationMessage(e.fields && (e.fields.length > 0) ? null : e.message);
                        setValidationFieldMessages(e.fields && (e.fields.length > 0) ? e.fields : []);
                    } else {
                        // For generic errors, display a generic error message
                        setErrorMessage('Unable to update administrator.');
                        setValidationMessage(null);
                        setValidationFieldMessages([]);
                    }

                    setIsSubmitting(false);
                }
            })();
        }
    };

    return {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        errorMessage,
        submit
    }
};

const AdministratorUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const administratorId = props.match.params['id'];

    const [formValues, setFormValues] = useState<FormValues>({
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
        setPassword: false,
        password: '',
        confirmPassword: ''
    });

    const [fetch, isFetching, administrator, fetchError] = useFetchAdministrator();

    useEffect(() => {
        fetch(administratorId);
    }, [administratorId])

    // Pre-populate form values once admin details are loaded
    useEffect(() => {
        if (administrator !== null) {
            setFormValues({
                firstName: administrator.firstName,
                lastName: administrator.lastName,
                email: administrator.email,
                roles: administrator.roles,
                setPassword: false,
                password: '',
                confirmPassword: ''
            })
        }
    }, [administrator]);

    const {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        errorMessage,
        submit
    } = useFormHandler(administratorId, () => {
        // Create a notification about the update
        dispatch(appWindowAddNotification('Administrator updated.', 'success'));

        // Forward back to list page
        history.push(administratorIndexUrl());
    });

    useNav('administrators');

    // Show progress bar when fetching or updating administrator.
    // Show error message if either fails
    useProgressEffects(
        isFetching || isSubmitting,
        fetchError || errorMessage
    );

    // Adjust form state when a field is modified
    const handleFieldChange = (evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
        const fieldName = evt.target.id || evt.target.name;
        const target = evt.target;

        let value: string | string[] | boolean = evt.target.value;

        // Roles is stored in a string[]
        // If a Role checkbox is toggled, modify the array
        if (target.type === 'checkbox' && fieldName === 'roles') {
            const checked = (target as HTMLInputElement).checked;
            let roles = formValues.roles;

            if (checked) {
                value = [...roles, value];
            } else {
                value = roles.filter(r => r !== value);
            }
        } else if (target.type === 'checkbox') {
            value = (target as HTMLInputElement).checked;
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Cancel goes back to user index
    const handleCancel = () => {
        history.push(administratorIndexUrl());
    };

    const handleSubmit = () => {
        submit(formValues);
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Update User" subtext={administrator !== null ? `${administrator.firstName} ${administrator.lastName}` : undefined}/>

            {administrator !== null &&
                <>
                    <Paper className={classes.paper}>
                        <AdministratorForm
                            formValues={formValues}
                            validationMessage={validationMessage}
                            fieldValidationMessages={validationFieldMessages}
                            isSubmitting={isSubmitting}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(AdministratorUpdatePage, AdministratorPolicies.CanViewAndManage);