import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { BulkUpload } from '../api';
import moment = require('moment');

const useStyles = makeStyles(theme => ({
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

interface UploadTableProps {
    uploads: BulkUpload[];
    onShowErrors: (uploadId: number) => void;
    onShowZtpWarnings: (uploadId: number) => void;
    onDownloadPasswords: (uploadId: number) => void;
}

export const UploadTable = (props: UploadTableProps) => {
    const { uploads, onShowErrors, onShowZtpWarnings, onDownloadPasswords } = props;
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.noWrap}>
                    <TableCell>Uploaded At</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>User Logins</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {uploads.map(upload => {
                    return (
                        <TableRow key={upload.id}>
                            <TableCell className={classes.noWrap} component="th" scope="row">
                                {moment(upload.uploadedAt).format('MM/DD/YYYY hh:mm A')}
                            </TableCell>
                            <TableCell className={classes.noWrap}>{upload.uploadedBy.firstName} {upload.uploadedBy.lastName}</TableCell>

                            <TableCell>
                                {upload.status === 'Error'
                                    && <Link
                                        href="#"
                                        onClick={() => onShowErrors(upload.id)}
                                        color="secondary">Error ({(upload.errorMessage || '').split("\n").length})</Link>}

                                {upload.status === 'Success' && upload.ztpErrorMessages !== null
                                    && <Link
                                        href="#"
                                    onClick={() => onShowZtpWarnings(upload.id)}
                                        color="secondary">Success w/ ZTP Warnings ({(upload.ztpErrorMessages || '').split("\n").length})</Link>}

                                {upload.status === 'Success' && upload.ztpErrorMessages === null
                                    && <>Success</>}

                                {upload.status !== 'Success' && upload.status !== 'Error'
                                    && <>{upload.status}</>}
                            </TableCell>

                            <TableCell>
                                {upload.status === 'Success'
                                    ? <Link
                                        href="#"
                                        onClick={() => onDownloadPasswords(upload.id)}
                                        color="primary">Download</Link>
                                    : 'N/A'}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};