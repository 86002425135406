import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import {
    fetchAllResellers,
    fetchOrganizationDetails,
    OrganizationDetails,
    TYPE_ENDCLIENT,
    updateOrganization
} from '../api';
import { FormValues, OrganizationForm } from '../OrganizationForm';
import { OrganizationPolicies } from '../policies';
import { organizationViewUrl } from '../urls';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    errorMessage: {
        backgroundColor: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Fetch {
    isFetching: boolean;
    organization: OrganizationDetails | null;
    resellerOptions: Array<{ id: number, name: string }> | null;
    error: string | null;
}

// Hook for retrieving organization details
const useFetch = (organizationId: number): Fetch => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [organization, setOrganization] = useState<OrganizationDetails | null>(null);
    const [resellerOptions, setResellerOptions] = useState<Array<{ id: number, name: string }> | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const [organization, resellerOptions] = await Promise.all([
                    fetchOrganizationDetails(api, organizationId),
                    fetchAllResellers(api),
                ]);

                if (!didCancel) {
                    setIsFetching(false);
                    setOrganization(organization);
                    setResellerOptions(resellerOptions);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError(error);
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [true]);

    return {
        isFetching,
        organization,
        resellerOptions,
        error
    };
};

interface FormHandler {
    isSubmitting: boolean;
    submit: (formValues: FormValues) => void;
    validationMessage: string | null;
    validationFieldMessages: FieldError[];
    failureMessage: string | null;
}

// Hook for handling the update submission
const useFormHandler = (organizationId: number, callback: () => void): FormHandler => {
    const api = useApi();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationFieldMessages, setValidationFieldMessages] = useState<FieldError[]>([]);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    const submit = (formValues: FormValues) => {
        (async () => {
            setIsSubmitting(true);

            try {
                // Call API to login
                await updateOrganization(api, organizationId, {
                    ...formValues,
                    sapId: (formValues.sapId || '').length > 0 ? formValues.sapId : null
                });

                callback();
            } catch (e) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, we'll either display error messages under the fields
                    // or display a generic error message if no field errors are listed
                    setValidationMessage(e.fields && (e.fields.length > 0) ? null : e.message);
                    setValidationFieldMessages(e.fields && (e.fields.length > 0) ? e.fields : []);
                } else {
                    // For generic errors, display a generic error message
                    setFailureMessage('Unable to update organization.');
                    setValidationMessage(null);
                    setValidationFieldMessages([]);
                }

                setIsSubmitting(false);
            }
        })();
    };

    return {
        isSubmitting,
        submit,
        validationMessage,
        validationFieldMessages,
        failureMessage
    }
};

const numericFields = [
    'resellerId'
];

/**
 * Dialog containing the form for updating an organization
 * @param props
 * @constructor
 */
const OrganizationUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useNav('organizations');

    // Form values use internal state
    const [formValues, setFormValues] = useState<FormValues>({
        sapId: '',
        name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        type: TYPE_ENDCLIENT,
        resellerId: null
    });

    const organizationId = props.match.params['id'];

    const { isFetching, organization, resellerOptions, error: fetchError } = useFetch(organizationId);

    const {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        submit,
        failureMessage: updateError
    } = useFormHandler(organizationId, () => {
        // Create a notification about the update
        dispatch(appWindowAddNotification('Organization updated.', 'success'));

        // Forward back to view page
        history.push(organizationViewUrl(organizationId));
    });

    useProgressEffects(
        isFetching || isSubmitting,
        fetchError || updateError
    );

    // Set form values when organization is retrieved
    useEffect(() => {
        if (organization !== null) {
            setFormValues({
                sapId: organization.sapId || '',
                name: organization.name,
                address: organization.address || '',
                address2: organization.address2 || '',
                city: organization.city || '',
                state: organization.state || '',
                zip: organization.zip || '',
                type: organization.type,
                resellerId: organization.reseller ? organization.reseller.id : null
            });
        }
    }, [organization]);

    // Handle submit button
    const handleSubmit = () => {
        submit(formValues);
    };

    // Cancel redirects back to the view URL
    const handleCancel = () => {
        history.push(organizationViewUrl(organizationId));
    };

    // Adjust form state when a field is modified
    const handleFieldChange = (fieldName: string, value: string | number | null) => {
        // Ensure ResellerID is numeric or NULL if not selected
        if (numericFields.indexOf(fieldName) > -1) {
            if (value !== null && value !== undefined && String(value).length > 0) {
                value = parseInt(String(value));
            } else {
                value = null;
            }
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Update Organization"
                        subtext={organization !== null ? organization.name : undefined}/>

            {!isFetching &&
            <>
                <Paper className={classes.paper}>
                    <OrganizationForm
                        formValues={formValues}
                        isSubmitting={isSubmitting}
                        resellerOptions={resellerOptions}
                        validationMessage={validationMessage}
                        validationFieldMessages={validationFieldMessages}
                        onFieldChange={handleFieldChange}
                        onFieldKeyDown={handleKeyDown}/>
                </Paper>

                <Grid container justifyContent="flex-end">
                    <Button
                    className={classes.button}
                    color="inherit"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleCancel}>Cancel</Button>

                    <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}>Update</Button>
                </Grid>
            </>
            }
        </div>
    );
};

export default withPolicyRestriction(OrganizationUpdatePage, OrganizationPolicies.CanManage);