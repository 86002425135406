import { FormControl, InputLabel, Input, Typography, RadioGroup, FormControlLabel, Radio, Grid, FormLabel, FormHelperText } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    callingNameDisplay: 'systemDefault' | 'off' | 'on';
    nnid: string;
    failoverUri: string;
}

const GeneralForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, onFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <FormControl className={classes.formControl} fullWidth error={!isValid('callingNameDisplay')}>
                <FormLabel htmlFor="callingNameDisplay">Calling Name Display</FormLabel>
                <RadioGroup
                    row
                    value={formValues.callingNameDisplay}
                    onChange={evt => onFieldChange('callingNameDisplay', evt.target.value)}
                    name="callingNameDisplay">
                    <FormControlLabel value={'systemDefault'} control={<Radio />} label="Default" />
                    <FormControlLabel value={'on'} control={<Radio />} label="On" />
                    <FormControlLabel value={'off'} control={<Radio />} label="Off" />
                </RadioGroup>

                <ValidationMessage field="callingNameDisplay" />
            </FormControl>

            {/*
            <FormControl className={classes.formControl} fullWidth error={!isValid('failoverUri')}>
                <InputLabel htmlFor="failoverUri">Failover URI</InputLabel>
                <Input
                    value={formValues.failoverUri || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('failoverUri', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="failoverUri"
                    type="text" />

                <FormHelperText>Must be a SIP URI format 'name@ip:port' or in the number format '5555555555'</FormHelperText>

                <ValidationMessage field="failoverUri" />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth error={!isValid('nnid')}>
                <InputLabel htmlFor="nnid">NNID Assignment</InputLabel>
                <Input
                    value={formValues.nnid || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('nnid', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="nnid"
                    type="text" />
                <ValidationMessage field="nnid" />
            </FormControl>
            */}
        </>
    );
};

export default GeneralForm;