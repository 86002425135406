import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { DubPointBase } from './api';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    dubPoint: DubPointBase;
}

const TeamsSummary = (props: Props) => {
    const classes = useStyles();
    const { dubPoint } = props;

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Tenant ID</Typography>
                    <Typography>{dubPoint.externalGroup}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">User ID</Typography>
                    <Typography>{dubPoint.externalIdentifier}</Typography>
                </Grid>
            </Grid>
        </Paper>);
};

export default TeamsSummary;