import { useEffect, useState } from "react";
import { ResponseError, useApi } from "../../../api";
import { fetchWebexProductCounts, WebexProductCounts } from "./api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";

type UseFetchWebexProductCounts = [
    fetch: (organizationId: number, assignmentId: number) => void,
    isFetching: boolean,
    counts: WebexProductCounts | null,
    error: string | null
];

interface Request {
    requestTs: number;
    organizationId: number;
    assignmentId: number;
}

export const useFetchWebexProductCounts = (): UseFetchWebexProductCounts => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [counts, setCounts] = useState<WebexProductCounts | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [request, setRequest] = useState<Request | null>(null);

    const fetch = (organizationId: number, assignmentId: number) => {
        setRequest({
            requestTs: Date.now(),
            organizationId,
            assignmentId
        })
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchWebexProductCounts(api, request.organizationId, request.assignmentId);

                    if (!didCancel) {
                        setCounts(response);
                        setIsFetching(false);
                        setError(null);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            setIsFetching(false);
                            history.push(loginUrl());
                        } else if ((e instanceof ResponseError) && (e.code === 500)) {
                            setIsFetching(false);
                            setError(e.message);
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve product counts');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        counts,
        error
    ];
};