import { ApiCaller, PagedResponse } from '../../../api';

export interface Account {
    id: string;
    billingId: string;
    group: string | null;
    name: string;
    status: string;
    trialAccount: boolean;
    dateCreated: string;
    dateUpdated: string;
    timeZone: string;
    address: Address | null;
    phone: string | null;
    retentionPeriod: number | null;
    retentionUnits: string | null;
    externalReference: string | null;
    allowInbound: boolean;
    allowOutbound: boolean;
    metadata: { [name: string]: string };
    associatedService: AssociatedService | null;
}

interface AssociatedService {
    url: string;
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    groupId: string;
}

export interface Address {
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
}

/*
 * GET /api/dubber/:groupId/accounts
 *
 * Get all accounts within dubber.
 */

export const fetchDubberAccounts = async (api: ApiCaller, groupId: string): Promise<Account[]> => {
    return await api<Account[]>(`dubber/${groupId}/accounts`, 'GET');
};


/*
 * GET /api/dubber/:groupId/accounts/:accountId
 *
 * Get account details
 */

export const fetchDubberAccount = async (api: ApiCaller, groupId: string, accountId: string): Promise<Account> => {
    return await api<Account>(`dubber/${groupId}/accounts/${accountId}`, 'GET');
};