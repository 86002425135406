import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { Account } from '../api';
import moment = require('moment');
import { organizationViewUrl } from '../../organizations/urls';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    account: Account;
}

const AccountSummary = (props: Props) => {
    const classes = useStyles();
    const { account } = props;


    const OrganizationLinkComponent = React.forwardRef((props, ref) => <RouterLink to={organizationViewUrl(account.associatedService?.organizationId || '')} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">ID</Typography>
                    <Typography>{account.id}</Typography>
                </Grid>

                {account.billingId &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Billing ID</Typography>
                        <Typography>{account.billingId}</Typography>
                    </Grid>
                }

                {account.associatedService &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Organization</Typography>
                        <Typography><Link component={OrganizationLinkComponent}>{account.associatedService.organizationName}</Link></Typography>
                    </Grid>
                }

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Status</Typography>
                    <Typography>{account.status}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Trial Account</Typography>
                    <Typography>{account.trialAccount ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Time Zone</Typography>
                    <Typography>{account.timeZone}</Typography>
                </Grid>

                {account.phone &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Phone</Typography>
                        <Typography>{account.phone}</Typography>
                    </Grid>
                }

                {account.address &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Address</Typography>
                        <Typography>
                            {account.address.address}<br />
                            {account.address.suburb}, {account.address.state} {account.address.postcode}
                        </Typography>
                    </Grid>
                }

                {account.retentionPeriod &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Retention</Typography>
                        <Typography>
                            {account.retentionPeriod} {account.retentionUnits}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default AccountSummary;