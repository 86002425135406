export const ADMINISTRATOR_INDEX_URL = '/administrators';
export const ADMINISTRATOR_CREATE_URL = '/administrators/create';
export const ADMINISTRATOR_VIEW_URL = '/administrators/:id';
export const ADMINISTRATOR_UPDATE_URL = '/administrators/:id/update';

export const administratorIndexUrl = () => ADMINISTRATOR_INDEX_URL;

export const administratorViewUrl = (id: string|number) =>
    ADMINISTRATOR_VIEW_URL.replace(':id', String(id));

export const administratorCreateUrl = () => ADMINISTRATOR_CREATE_URL;

export const administratorUpdateUrl = (id: string|number) =>
    ADMINISTRATOR_UPDATE_URL.replace(':id', String(id));