import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText, LinearProgress,
    TextField
} from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Platform } from '../api';
import { useAddNumbers } from './hooks';

interface Props {
    platform: Platform;
    groupId: string;
    isOpen: boolean;
    onCancel: () => void;
    onComplete: () => void;
}

// Dialog allowing for adding numbers to a group
const AddNumbersDialog = (props: Props) => {
    const { platform, groupId, isOpen, onCancel, onComplete } = props;

    const [submit, isSubmitting, validationMessage, fatalMessage, reset] = useAddNumbers(() => {
        onComplete();
    });

    // Holds the raw value of the numbers text area field
    const [numbersTextarea, setNumbersTextarea] = useState('');

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        // Remove all characters from input except numbers and newlines
        setNumbersTextarea(e.target.value.replace(/[^0-9\n]/g, ''));
    };

    const numbers = useMemo(() => {
        return numbersTextarea
            .split("\n")
            .map(n => n.trim())
            .filter(n => n.length > 0);
    }, [numbersTextarea])

    const handleSubmit = () => {
        submit(platform, groupId, numbers);
    };

    // Reset form on open
    useEffect(() => {
        if (isOpen) {
            setNumbersTextarea('');
            reset();
        }
    }, [isOpen]);

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={onCancel}
            aria-labelledby="add-numbers-dialog-title">
            <DialogTitle id="add-numbers-dialog-title">Add Numbers</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter one number per line.
                </DialogContentText>

                <TextField
                    error={validationMessage !== null || fatalMessage !== null}
                    margin="dense"
                    variant="standard"
                    id="name"
                    label="Numbers"
                    type="text"
                    fullWidth
                    multiline
                    rows={10}
                    value={numbersTextarea}
                    onChange={handleNumberChange}
                />

                {validationMessage !== null &&
                    <FormHelperText error>
                        {validationMessage}
                    </FormHelperText>
                }

                {fatalMessage !== null &&
                    <FormHelperText error>
                        {fatalMessage}
                    </FormHelperText>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel} color="primary" disabled={isSubmitting}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="secondary" autoFocus disabled={isSubmitting || numbers.length === 0}>
                    {numbers.length > 0
                        && <>Add {numbers.length} Number{numbers.length > 1 ? "s" : ""}</>
                        || <>Add Numbers</>
                    }
                </Button>
            </DialogActions>

            {isSubmitting && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

export default AddNumbersDialog;