import { useEffect, useState } from "react";
import { BadRequestError, FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { deleteDubberTeamMember, fetchDubberTeamMember, fetchDubberTeamMembers, TeamMember, TeamMemberCreateUpdate, TeamMemberDubPoint, TeamMemberIndex, updateDubberTeamMember } from "./api";

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

type TriggeredSubmitHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null,
    FieldError[]
];

type FetchTeamMembersFunc = (groupId: string, accountId: string, teamId: string) => void;

// Hook for fetching team members

export const useFetchDubberTeamMembers = (): TriggeredFetchHookResponse<FetchTeamMembersFunc, TeamMemberIndex[]> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [members, setMembers] = useState<TeamMemberIndex[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchTeamMembersFunc = (groupId: string, accountId: string, teamId: string) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setMembers(null);

                try {
                    const members = await fetchDubberTeamMembers(api, request.groupId, request.accountId, request.teamId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setMembers(members);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setMembers(null);
                            setError('Unable to fetch dubber team members.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        members,
        error
    ];
};

// Hook for retrieving team member details

type FetchTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string) => void;

export const useFetchDubberTeamMember = (): TriggeredFetchHookResponse<FetchTeamMemberFunc, TeamMember> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        userId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [member, setMember] = useState<TeamMember | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            userId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setMember(null);

                try {
                    const details = await fetchDubberTeamMember(api, request.groupId, request.accountId, request.teamId, request.userId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setMember(details);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setMember(null);
                            setError('Unable to fetch dubber team member.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        member,
        error
    ];
};

// Hook for updating a dubber team member
type UpdateTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string, update: TeamMemberCreateUpdate) => void;

export const useUpdateDubberTeamMember = (): TriggeredSubmitHookResponse<UpdateTeamMemberFunc, TeamMember> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        userId: string;
        update: TeamMemberCreateUpdate;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [member, setMember] = useState<TeamMember | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const update: UpdateTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string, updateForm: TeamMemberCreateUpdate) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            userId,
            update: updateForm,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsCreating(true);
                setMember(null);

                try {
                    const updatedMember = await updateDubberTeamMember(api, request.groupId, request.accountId, request.teamId, request.userId, request.update);

                    if (!didCancel) {
                        setIsCreating(false);
                        setMember(updatedMember);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // For bad request errors, display field messages if they're present
                            // Else display the primary error message
                            if (e.fields && e.fields.length > 0) {
                                setError(null);
                                setFieldErrors(e.fields);
                            } else {
                                setError(e.message);
                                setFieldErrors([]);
                            }

                            setIsCreating(false);
                        } else {
                            setIsCreating(false);
                            setMember(null);
                            setError('Unable to update dubber team member');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        update,
        isCreating,
        member,
        error,
        fieldErrors
    ];
};

type DeleteTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string) => void;

// Hook for deleting a user from a team

export const useDeleteDubberTeamMember = (): TriggeredFetchHookResponse<DeleteTeamMemberFunc, boolean> => {
    interface Request {
        groupId: string;
        accountId: string;
        teamId: string;
        userId: string;
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: DeleteTeamMemberFunc = (groupId: string, accountId: string, teamId: string, userId: string) => {
        setRequest({
            groupId,
            accountId,
            teamId,
            userId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsDeleting(true);
                setIsDeleted(null);

                try {
                    await deleteDubberTeamMember(api, request.groupId, request.accountId, request.teamId, request.userId);

                    if (!didCancel) {
                        setIsDeleting(false);
                        setIsDeleted(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsDeleting(false);
                            setIsDeleted(null);
                            setError('Unable to delete dubber team member.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isDeleting,
        isDeleted,
        error
    ];
};

