import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useMemo, useState } from 'react';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import theme from '../../../theme';
import NumbersSearchBar from '../sub-accounts/NumbersSearchBar';
import NumbersTable from './NumbersTable';

const useStyles = makeStyles(() => ({
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    table: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));

interface Props {
    numbers: string[];
}

interface Form {
    search: string;
    page: number;
    limit: number;
}

const LocationNumbers = (props: Props) => {
    const { numbers } = props;
    const classes = useStyles();

    const [form, setForm] = useState<Form>({
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    });

    // Filter numbers
    const filteredNumbers = useMemo(() => {
        const { search } = form;

        return (numbers || [])
            .sort((a, b) => a.localeCompare(b))
            .filter(n => n.includes(search));
    }, [numbers, form]);

    // Pagination filtered numbers
    const pagedNumbers = useMemo(() => {
        const { page, limit } = form;

        return (filteredNumbers || [])
            .slice((page - 1) * limit, page * limit);
    }, [filteredNumbers, form]);

    // Handle search filter
    const handleSearch = (search: string, limit: number) => {
        setForm({
            search,
            limit,
            page: 1
        })
    };

    // Handle pagination
    const handlePage = (page: number) => {
        setForm({
            ...form,
            page
        })
    };

    return (
        <>
            <NumbersSearchBar onSubmitSearch={handleSearch} />

            <div className={classes.table}>
                <NumbersTable numbers={pagedNumbers} />
            </div>

            <Pagination
                totalItems={filteredNumbers.length}
                pageSize={form.limit}
                page={form.page}
                disabled={false}
                setParams={false}
                onChange={handlePage} />

        </>
    );
};

export default LocationNumbers;