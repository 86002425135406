import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, ListSubheader, MenuItem, Paper, Select, SelectProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { NineLineDevice, NineLineLocation } from './api';
import { useAssignNineLineLocations } from './hooks';
import AssignmentDialog from './AssignmentDialog';
import { Search } from '@mui/icons-material';

interface Props {
    assignmentId: number;
    devices: NineLineDevice[];
    departments: string[];
    locations: NineLineLocation[];
    onWorking: (working: boolean) => void;
    onError: (errorMsg: string) => void;
    refresh: () => void;
}

const useStyles = makeStyles(() => ({
    paper: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subheading: {
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    buttonBar: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'right'
    },
    menuItem: {
        display: 'block'
    }
}));

interface DepartmentDevices {
    department: string;
    devices: NineLineDevice[];
}

interface Change {
    deviceName: string;
    locationId: string;
}

interface AssignmentSubmission {
    ts: Date;
    changes: Change[]
}

const simplifyDeviceType = (deviceType: string) => deviceType.replace(/^KMVOICE\-/, '');

export default function DevicesTab(props: Props) {
    const classes = useStyles();
    const { assignmentId, devices, departments, locations, onWorking, onError, refresh } = props;
    const [assign, isAssigning, assignmentResults, assignmentError, assignmentFieldErrors] = useAssignNineLineLocations();
    const [onlyShowUnassigned, setOnlyShowUnassigned] = useState(false);

    const [assignmentSubmission, setAssignmentSubmission] = useState<AssignmentSubmission | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Any recommendation locations for devices get automatically added to change list
    const [changes, setChanges] = useState<Change[]>(
        devices
            .filter(d => d.recommendedEmergencyLocation !== null)
            .map(d => ({
                deviceName: d.name,
                locationId: d?.recommendedEmergencyLocation?.id || ''
            }))
    );

    useEffect(() => {
        onWorking(isAssigning);
    }, [isAssigning]);

    useEffect(() => {
        if (assignmentError) {
            onError(assignmentError);
        }
    }, [assignmentError]);

    const unassignedDevices = useMemo(() => {
        return devices.filter(d => d.emergencyLocation === null);
    }, [devices]);

    const displayedDevices = onlyShowUnassigned ? unassignedDevices : devices;

    // Group devices by department
    const departmentDevices: DepartmentDevices[] = useMemo(() => {
        var departments = [...new Set(displayedDevices.map(d => d?.owner?.department || ''))];

        return departments.map(d => {
            return {
                department: d || 'No Department',
                devices: displayedDevices.filter(dev => (dev?.owner?.department || '') == d).sort((a, b) => a.mac.toLowerCase().localeCompare(b.mac.toLowerCase()))
            }
        })
            .filter(l => l.devices.length > 0)
            .sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()))

    }, [displayedDevices]);

    const getValue = useCallback((deviceName: string) => {
        // Get default value
        const defaultVal = devices.find(d => d.name == deviceName)?.emergencyLocation?.id;
        const changedVal = changes.find(c => c.deviceName == deviceName)?.locationId;

        return (changedVal || defaultVal) || '';
    }, [devices, changes]);

    const valueChanged = useCallback((deviceName: string) => {
        const defaultVal = devices.find(d => d.name == deviceName)?.emergencyLocation?.id;
        const changedVal = changes.find(c => c.deviceName == deviceName)?.locationId;

        return changedVal !== undefined && (defaultVal !== changedVal);
    }, [devices, changes]);

    const setValue = useCallback((deviceName: string, locationId: string) => {
        var copy = [...changes].filter(c => c.deviceName != deviceName);

        setChanges([
            ...copy,
            {
                deviceName,
                locationId
            }
        ]);
    }, [changes]);

    const handleAssignmentButton = useCallback(() => {
        if (changes.length > 0) {
            setAssignmentSubmission({
                ts: new Date(),
                changes
            })
        }
    }, [assignmentId, changes]);

    // Refresh on close
    const handleDialogClose = () => {
        setDialogOpen(false);
        refresh();
    };

    useEffect(() => {
        if (assignmentSubmission) {
            assign(assignmentId, { changes });
            setDialogOpen(true);
        }
    }, [assignmentSubmission]);

    return (
        <>
            {devices.length > 0 && <>
                <Grid container>
                    <Grid item xs={9}>
                        <FormControlLabel
                            control={<Checkbox />}
                            value={onlyShowUnassigned}
                            onChange={() => setOnlyShowUnassigned(!onlyShowUnassigned)}
                            disabled={unassignedDevices.length === 0}
                            label={`Only show devices missing an emergency location (${unassignedDevices.length})`} />
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.buttonBar}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={changes.length === 0 || isAssigning}
                                onClick={() => handleAssignmentButton()}>Apply Changes{changes.length > 0 && <> ({changes.length})</>}</Button>
                        </div>
                    </Grid>
                </Grid>

                {departmentDevices.map(d => {
                    return (
                        <Paper key={d.department} className={classes.paper}>
                            <div className={classes.subheading}>
                                <Typography variant="h4">{d.department}</Typography>
                            </div>

                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%">Device Name</TableCell>
                                        <TableCell width="20%">MAC Address</TableCell>
                                        <TableCell width="20%">Device Type</TableCell>
                                        <TableCell width="20%">Users</TableCell>
                                        <TableCell width="20%">Emergency Location</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {d.devices.map(dev => {
                                        const users = dev.users.map(user =>
                                            <Tooltip title=<>
                                                Name: {user.firstName} {user.lastName}<br />
                                                Id: {user.userId}<br />
                                                Lineport: {user.linePortId}<br />
                                                Ext: {user.extension}
                                            </>><span>{user.firstName} {user.lastName}</span></Tooltip>);

                                        const concattedUsers = users.length > 0
                                            ? users.reduce((previous, current, idx) => <>{previous}, {current}</>)
                                            : <></>;


                                        return (
                                            <TableRow selected={valueChanged(dev.name)} key={dev.name}>
                                                <TableCell>{dev.name}</TableCell>
                                                <TableCell>{dev.mac}</TableCell>
                                                <TableCell>{simplifyDeviceType(dev.type)}</TableCell>
                                                <TableCell>{concattedUsers}</TableCell>
                                                <TableCell>
                                                    <FormControl variant="standard" size="small" sx={{ width: '100%' }}>
                                                        <LocationSelect
                                                            deviceName={dev.name}
                                                            locations={locations}
                                                            value={getValue(dev.name)}
                                                            onChange={e => setValue(dev.name, e.target.value)}
                                                            renderValue={(id: string) => {
                                                                let location;

                                                                if (id) {
                                                                    location = locations.find(l => l.id == id);
                                                                }

                                                                if (location !== undefined) {
                                                                    return <div>{location.name}</div>;
                                                                } else {
                                                                    return <div />;
                                                                }
                                                            }}/>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    );
                })
                }
            </>}

            <AssignmentDialog isOpen={dialogOpen} results={assignmentResults} onClose={handleDialogClose} />
        </>
    );
}

interface LocationSelectProps extends SelectProps<string> {
    deviceName: string;
    locations: NineLineLocation[];
}

const LocationSelect = (props: LocationSelectProps) => {
    const { deviceName, locations } = props;

    const classes = useStyles();
    const [search, setSearch] = useState('');

    const filteredLocations = useMemo(() => {
        if ((search || '').length === 0) {
            return locations;
        }

        const s = search.toLocaleLowerCase();

        return locations.filter(l => {
            return l.id.toLocaleLowerCase().indexOf(s) > -1
                || l.address1.toLocaleLowerCase().indexOf(s) > -1
                || (l.address2 || '').toLocaleLowerCase().indexOf(s) > -1
                || l.name.toLocaleLowerCase().indexOf(s) > -1
                || l.city.toLocaleLowerCase().indexOf(s) > -1
                || l.state.toLocaleLowerCase().indexOf(s) > -1
                || l.postalCode.toLocaleLowerCase().indexOf(s) > -1;
        });
    }, [search, locations]);

    return <Select
        MenuProps={{ autoFocus: false }}
        onClose={() => setSearch('') }
        {...props}>
        <ListSubheader>
            <TextField
                size="small"
                autoFocus
                placeholder="Search locations"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                        e.stopPropagation();
                    }
                }}
            />
        </ListSubheader>

        {filteredLocations.map(l => {
            return (
                <MenuItem dense divider className={classes.menuItem} key={deviceName + ':' + l.id} value={l.id}>
                    <Typography component="div">{l.name}</Typography>
                    <Typography component="div" variant="body2">
                        {l.address1}<br />
                        {l.address2 && <>{l.address2}<br /></>}
                        {l.city}, {l.state} {l.postalCode}</Typography>
                </MenuItem>

            );
        })}
    </Select>;
};