import { Checkbox, FormControl, Input, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { SeatType, seatTypeName, SEAT_BASIC, SEAT_ENCRYPTED, SEAT_PREMIUM, SEAT_PREMIUMPLUS } from './api';
import { HandleChange, SeatForm } from './SeatUpdatePage';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formControl: {
        padding: 0
    }
}));

interface LocationTableProps {
    seats: SeatForm[];
    onFieldChange: HandleChange;
    isSubmitting: boolean;
}

const LocationTable = (props: LocationTableProps) => {
    const classes = useStyles();
    const { onFieldChange, seats, isSubmitting } = props;

    const options: SeatType[] = [
        SEAT_BASIC,
        SEAT_PREMIUM,
        SEAT_PREMIUMPLUS,
        SEAT_ENCRYPTED,
    ];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Remove</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {seats.map(s => {
                    return (
                        <TableRow key={s.idx}>
                            <TableCell>
                                <FormControl className={classes.formControl} fullWidth required>
                                    <Input
                                        name="name"
                                        value={s.name}
                                        disabled={isSubmitting}
                                        onChange={evt => onFieldChange(s.idx, 'name', evt.currentTarget.value)}
                                        onKeyDown={() => false}
                                        type="text" />
                                </FormControl>
                            </TableCell>

                            <TableCell>
                                <FormControl className={classes.formControl} fullWidth required>
                                    <Select
                                        native
                                        name="type"
                                        margin="none"
                                        value={s.type}
                                        disabled={isSubmitting}
                                        onChange={evt => onFieldChange(s.idx, 'type', evt.target.value)}
                                        onKeyDown={() => false}>
                                        {options.map(o => <option key={o} value={o}>{seatTypeName(o)}</option>)}
                                    </Select>
                                </FormControl>
                            </TableCell>

                            <TableCell>
                                <Checkbox
                                    checked={s.remove}
                                    disabled={isSubmitting}
                                    name="remove"
                                    onChange={evt => onFieldChange(s.idx, 'remove', 'toggle')}
                                    value="1" />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default LocationTable;