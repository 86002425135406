import { LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useMemo, useState } from 'react';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import theme from '../../../theme';
import { OrderStatus, ORDER_STATUSES } from './api';
import OrderIndexTable, { ListItem } from './OrderIndexTable';
import OrderSearchBar from './OrderSearchBar';

const useStyles = makeStyles(() => ({
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface ListItemWithNumberList extends ListItem {
    numberList?: string[];
}

interface Props {
    loading: boolean;
    error: string | null;
    orders: ListItemWithNumberList[] | null;
    showFocDate?: boolean;
    statusOptions?: OrderStatus[];
    url: (accountId: string, orderId: string) => string;
}

export const OrderIndexTabContents = (props: Props) => {
    const { showFocDate, loading, error, orders, url } = props;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [search, setSearch] = useState<string | null>(null);
    const [status, setStatus] = useState<OrderStatus | ''>('');
    const classes = useStyles();

    const statusOptions = props.statusOptions || ORDER_STATUSES;

    const handleSearch = (search: string, status: OrderStatus | '', limit: number) => {
        setPage(1); // Reset page
        setSearch(search.toLowerCase());
        setStatus(status);
        setPageSize(limit);
    };

    const filteredOrders = useMemo(() => {
        var o = (orders || []);

        if (search !== null && search.length > 0) {
            o = o.filter(o => {
                return o.id.toLowerCase().indexOf(search) > -1
                    || (o.customerOrderId || '').toLowerCase().indexOf(search) > -1
                    //|| (o.numberList !== undefined && (o.numberList.indexOf(search) > -1))
                    || (o.numberList !== undefined && (o.numberList.findIndex(l => l.indexOf(search.replace(/[^0-9]/, '')) > -1) > -1))
            });
        }

        if (status !== '') {
            o = o.filter(o => o.status == status);
        }

        return o;
    }, [orders, search, status]);

    const pagedOrders = useMemo(() => {
        return filteredOrders
            .slice()
            .splice(pageSize * (page - 1), pageSize);
    }, [filteredOrders, page, search, pageSize]);

    return (
        <>
            {loading && <LinearProgress />}
            {!loading && error && <Typography color="error">Unable to retrieve orders.</Typography>}

            {!loading && !error && orders && <>
                <OrderSearchBar onSubmitSearch={handleSearch} statusOptions={statusOptions} />

                {filteredOrders.length === 0 && <div className={classes.noResultsContainer}>
                    <Typography color="textSecondary" align="center">
                        No orders found.
                    </Typography>
                </div>}

                {filteredOrders.length > 0 && <>
                    <OrderIndexTable showFocDate={showFocDate || false} orders={pagedOrders} url={url} />
                    <Pagination
                        totalItems={filteredOrders.length}
                        pageSize={pageSize}
                        page={page}
                        disabled={false}
                        setParams={false}
                        additionalParams={{
                        }}
                        onChange={p => setPage(p)} />
                </>}
            </>}
        </>
    );
};

export default OrderIndexTabContents;