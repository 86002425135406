import { FormControl, FormHelperText, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    disabled?: boolean;
    validationFieldMessages: FieldError[];
    value: string;
    onUpdate: (value: string) => void;
}

const NumberForm = (props: Props) => {
    const classes = useStyles();
    const { validationFieldMessages, onUpdate, value } = props;
    const disabled = props.disabled || false;
    

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        // Remove all characters from input except numbers and newlines
        onUpdate(e.target.value.replace(/[^0-9,\n]/g, ''));
    };

    const { isValid, ValidationMessage } = useValidationHelpers(validationFieldMessages);

    return (
        <FormControl className={classes.formControl} fullWidth required>
            <TextField
                error={!isValid('numbers')}
                margin="dense"
                variant="standard"
                id="numbers"
                label="Numbers"
                type="text"
                fullWidth
                multiline
                rows={5}
                value={value}
                disabled={disabled}
                onChange={handleNumberChange}
            />

            {isValid('numbers') && <FormHelperText>Enter one number per line or separated by commas</FormHelperText> }

            <ValidationMessage field="numbers" />
        </FormControl>
    );
};

export default NumberForm;