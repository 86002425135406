import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchBandwidthAccount, useFetchBandwidthSubAccount } from '../hooks';
import { BandwidthPolicies } from '../policies';
import { bandwidthLocationViewUrl, bandwidthSubAccountViewUrl } from '../urls';
import { LocationAddressForm, LocationForm as LocationFormData } from './api';
import { useCreateBandwidthLocation } from './hooks';
import LocationForm from './LocationForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    errorMessage: {
        backgroundColor: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const LocationCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [fetchAccount, isFetchingAccount, account, fetchAccountErrorMessage] = useFetchBandwidthAccount();
    const [fetchSubAccount, isFetchingSubAccount, subAccount, fetchSubAccountErrorMessage] = useFetchBandwidthSubAccount();

    const [create, isCreating, createdLocation, createErrorMessage, createFieldErrorMessages] = useCreateBandwidthLocation();

    const accountId = props.match.params['accountId'];
    const subAccountId = props.match.params['subAccountId'];

    const [formValues, setFormValues] = useState<LocationFormData>({
        accountId,
        subAccountId,
        name: '',
        description: '',
        isDefault: false,
        failoverUri: '',
        originationDistribution: 'None',
        originationHosts: [],
        terminationHosts: [],
        trafficAllowed: 'Domestic',
        callingNameDisplayed: true,
        callingNameEnforced: false,
        premiseTrunks: null,
        address: null,
        stirShaken: {
            inboundVerification: 'Off',
            transmitIdentityHeader: false
        }
    });

    // Update nav
    useNav('bandwidth', 'locations');

    // Display progress bar and errors
    useProgressEffects(
        isFetchingAccount || isFetchingSubAccount,
        fetchAccountErrorMessage || fetchSubAccountErrorMessage
    );

    // Fetch account details on load
    useEffect(() => {
        fetchAccount(accountId);
        fetchSubAccount(accountId, subAccountId);
    }, []);

    // Adjust form based on account type once loaded
    useEffect(() => {
        if (account && subAccount) {
            var newFormValues = formValues;

            newFormValues.originationHosts = account.originationHosts;
            newFormValues.terminationHosts = account.terminationHosts;

            // Force traffic distribution to be Random and enable International on HPBX
            if (account.service === 'BroadCloudPaas') {
                newFormValues = {
                    ...newFormValues,
                    originationDistribution: 'Random',
                    trafficAllowed: 'Full'
                };
            }

            // Address fields required if not Wholesale account
            if (account.type !== 'Wholesale') {
                newFormValues = {
                    ...newFormValues,
                    address: {
                        housePrefix: null,
                        houseNumber: '',
                        houseSuffix: null,
                        preDirectional: null,
                        streetName: '',
                        streetSuffix: null,
                        postDirectional: null,
                        addressLine2: null,
                        city: '',
                        stateCode: '',
                        zip: '',
                        plusFour: null,
                        country: 'United States',
                        latitude: '',
                        longitude: ''
                    }
                };
            }

            // Premise trunks required on Premise UC accounts
            if (subAccount.ucTrunkType === 'Premise') {
                newFormValues = {
                    ...newFormValues,
                    premiseTrunks: 1    // All SIP Trunking locations should have 1 premise trunk. The total allowed trunks is set on another infrastructure account
                };
            }

            setFormValues(newFormValues);
        }
    }, [account, subAccount])

    // On successful create, redirect to the view page
    useEffect(() => {
        if (createdLocation) {
            dispatch(appWindowAddNotification('Location created.', 'success'));

            history.push(bandwidthLocationViewUrl(accountId, subAccountId, createdLocation.id));
        }
    }, [createdLocation]);

    // Handle submit button
    const handleSubmit = () => {
        create(formValues);
    };

    // Cancel redirects back to the sub-account view URL
    const handleCancel = () => {
        history.push(bandwidthSubAccountViewUrl(accountId, subAccountId));
    };

    // Adjust form state when a field is modified
    const handleFieldChange = (fieldName: keyof LocationFormData, value: boolean | string | string[] | number | null | LocationAddressForm) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Create Location"
                subtext={subAccount !== null ? subAccount.name : undefined} />

            {!isFetchingSubAccount && !isFetchingAccount && account && subAccount &&
                <>
                    <Paper className={classes.paper}>
                        <LocationForm
                            isWholesaleAccount={account.type == 'Wholesale'}
                            accountService={account.service}
                            formValues={formValues}
                            isSubmitting={isCreating}
                            validationMessage={createErrorMessage}
                            validationFieldMessages={createFieldErrorMessages}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown} />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isCreating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isCreating}
                            onClick={handleSubmit}>Create</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(LocationCreatePage, BandwidthPolicies.CanManageLocations);
