import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { BulkUpload } from '../api';
import moment = require('moment');

const useStyles = makeStyles(theme => ({
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));

interface UploadTableProps {
    uploads: BulkUpload[];
    showErrors: (errors: string[]) => void;
    downloadUserSheet: (uploadId: number) => void;
}

export const UploadTable = (props: UploadTableProps) => {
    const { uploads, showErrors, downloadUserSheet } = props;
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.noWrap}>
                    <TableCell>Uploaded At</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>User Sheet</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {uploads.map(upload => {
                    return (
                        <TableRow key={upload.id}>
                            <TableCell className={classes.noWrap} component="th" scope="row">
                                {moment(upload.uploadedAt).format('MM/DD/YYYY hh:mm A')}
                            </TableCell>
                            <TableCell className={classes.noWrap}>{upload.uploadedBy.firstName} {upload.uploadedBy.lastName}</TableCell>

                            <TableCell>
                                {upload.status === 'Error'
                                    && <Link
                                        href="#"
                                        onClick={() => showErrors(upload.errors)}
                                        color="secondary">Failed ({upload.errors.length})</Link>}

                                {upload.status === 'Success' && upload.errors.length > 0
                                    && <Link
                                        href="#"
                                        onClick={() => showErrors(upload.errors)}
                                        color="secondary">Completed w/ Errors ({upload.errors.length})</Link>}

                                {upload.status === 'Success' && upload.errors.length === 0
                                    && <>Completed</>}

                                {upload.status !== 'Success' && upload.status !== 'Error'
                                    && <>{upload.status}</>}
                            </TableCell>

                            <TableCell>
                                {upload.status === 'Success' && <Link href="#" onClick={() => downloadUserSheet(upload.id)}>Download</Link>}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};