import { Alert, Box, CircularProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { WebexOrganizationForm } from '../components/WebexOrganizationForm';
import { useFetchAllWebexOrganizations } from '../hooks';
import { WebexPolicies } from '../policies';
import DevicesTab from './DevicesTab';
import { useFetchRedSkyE911Report } from './hooks';

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

type ToolTabs = 'devices';

/**
 * Red Sky Tool index page
 */
const RedSkyIndexPage = (props: Props) => {
    const classes = useStyles();

    const [fetchOrganizations, isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllWebexOrganizations();
    const [fetchReport, isFetchingReport, report, fetchReportError, fetchReportValidationError] = useFetchRedSkyE911Report();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);

    const [selectedTab, setSelectedTab] = useState<ToolTabs>('devices');
    const [tabWorking, setTabWorking] = useState(false);
    const [tabError, setTabError] = useState<string | null>(null);

    useNav('webex', 'redsky');

    useProgressEffects(
        isFetchingOrganizations,
        fetchOrganizationsError || fetchReportError || tabError
    );

    // Fetch orgs on load
    useEffect(() => {
        fetchOrganizations();
    }, []);

    useEffect(() => {
        if (selectedAssignmentId && selectedOrganizationId) {
            fetchReport(selectedAssignmentId);

        }
    }, [selectedOrganizationId, selectedAssignmentId]);

    const unconfiguredDevices = useMemo(() => {
        if (report) {
            return report.devices.filter(d => d.emergencyLocation == null);
        } else {
            return null;
        }
    }, [report])

    return (
        <div className={classes.root}>
            <PageHeader text="Cloud PBX w/ Webex" subtext="RedSky E911 Tool" />

            {!isFetchingOrganizations && organizations !== null &&
                <Paper className={classes.paper}>
                    <WebexOrganizationForm
                        organizations={organizations}
                        selectedOrganizationId={selectedOrganizationId}
                        selectedAssignmentId={selectedAssignmentId}
                        onSelect={(orgId, asId) => {
                            setSelectedOrganizationId(orgId);
                            setSelectedAssignmentId(asId);
                        }}
                        disabled={isFetchingReport || tabWorking}
                    />
                </Paper>
            }

            {isFetchingReport && <>
                <Box>
                    <CircularProgress size="1em" /> Retrieving information
                </Box>
            </>}

            {fetchReportValidationError && <>
                <Alert severity="error">{fetchReportValidationError}</Alert>
            </>}

            {report && selectedAssignmentId && <>
                {selectedTab == 'devices' &&
                    <DevicesTab
                        assignmentId={selectedAssignmentId}
                        devices={report.devices || []}
                        webexLocations={report.webexLocations}
                        redSkyLocations={report.redSkyLocations}
                        onWorking={working => setTabWorking(working)}
                        onError={errorMsg => setTabError(errorMsg)}
                        refresh={() => fetchReport(selectedAssignmentId)}
                    />}
            </>}
        </div>
    );
};

export default withPolicyRestriction(RedSkyIndexPage, WebexPolicies.CanUseRedSkyTool);