import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Account } from '../api';
import moment = require('moment');
import { dubberAccountViewUrl } from '../urls';

interface Props {
    groupId: string;
    items: Account[]
}

const AccountTable = (props: Props) => {
    const { groupId, items } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(a => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(groupId, a.id)} {...props} />);

                    return (
                        <TableRow key={a.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{a.name}</Link>
                            </TableCell>
                            <TableCell>{a.status}</TableCell>
                            <TableCell>{moment(a.dateCreated).format('MM/DD/YYYY')}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default AccountTable;