import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import Pagination from '../../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useFetchDubberGroups, useFetchDubberUnidentifiedDubPoints } from '../../hooks';
import UnidentifiedDubPointSearchBar from './UnidentifiedDubPointSearchBar';
import UnidentifiedDubPointTable from './UnidentifiedDubPointTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    page: number;
    limit: number;
}

const compareForm = (a: Form, b: Form) => a.page == b.page && a.limit == b.limit;

const formFromQuery = (query: ParsedQuery<string>): Form => {
    const pageQuery = parseInt(String(query['page'] || 1));
    const limitQuery = parseInt(String(query['limit'] || DEFAULT_PAGE_SIZE));

    return {
        page: pageQuery,
        limit: limitQuery
    }
};

const handleSubmitSearch = (limit: number) => {
    var query: Record<string, string> = {
        limit: String(limit),
        page: "1"
    };

    const params = new URLSearchParams(query);

    history.push('?' + params.toString());
}

// Page for listing all unidentified dub points in Dubber
const UnidentifiedDubPointIndexPage = (props: Props) => {
    const classes = useStyles();
    const [form, setForm] = useState<Form>({
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    })

    const [fetchGroups, isFetchingGroups, groups, groupErrorMessage] = useFetchDubberGroups();
    const [fetch, isFetching, dubPoints, errorMessage] = useFetchDubberUnidentifiedDubPoints();

    // Parse query string
    const params = parse(location.search);

    // Refresh list if current form settings don't match the query string
    var queryForm = formFromQuery(params);

    if (!compareForm(form, queryForm)) {
        setForm(queryForm);
    }

    // Update navigation
    useNav('dubber', 'unidentified-dub-points');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingGroups,
        errorMessage || groupErrorMessage
    );

    // Trigger group fetch on page load
    useEffect(() => {
        fetchGroups();
    }, []);

    // Once groups are fetched, pull unidentified dub points
    // Right now we assume there's only one group
    useEffect(() => {
        if (groups && groups.length === 1) {
            fetch(groups[0].id);
        }
    }, [groups]);

    const groupId = useMemo(() => {
        if (groups && groups.length === 1) {
            return groups[0].id;
        }

        return null;
    }, [groups]);

    // Pagination dub points based on filter form
    const pagedDubPoints = useMemo(() => {
        if (dubPoints === null) {
            return null;
        }

        return {
            dubPoints: dubPoints.slice((form.page - 1) * form.limit, form.page * form.limit),
            totalItems: dubPoints.length,
            pageSize: form.limit,
            page: form.page
        };
    }, [dubPoints, form]);

    return (
        <div className={classes.root}>
            <PageHeader text="Dubber" subtext="Unidentified Dub Points" />

            <Paper>
                <UnidentifiedDubPointSearchBar
                    limit={form.limit}
                    onSubmitSearch={handleSubmitSearch}
                />

                {dubPoints !== null
                    && dubPoints.length === 0
                    && <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">No Dub Points found.</Typography>
                    </div>
                }

                {pagedDubPoints !== null
                    && pagedDubPoints.totalItems > 0
                    && groupId != null
                    && <>
                        <UnidentifiedDubPointTable groupId={groupId} dubPoints={pagedDubPoints.dubPoints} />

                        <Pagination
                            totalItems={pagedDubPoints.totalItems}
                            pageSize={pagedDubPoints.pageSize}
                            page={pagedDubPoints.page}
                            disabled={isFetching}
                            additionalParams={{
                                limit: form.limit
                            }}
                            onChange={() => null} />
                    </>
                }
            </Paper>
        </div>
    );
};

export default UnidentifiedDubPointIndexPage;