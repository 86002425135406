import { FormControl, FormHelperText, Input, InputLabel, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    passcode: string;
}

const PasscodeForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, onFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <FormControl className={classes.formControl} fullWidth error={!isValid('passcode')}>
                <InputLabel htmlFor="passcode">Passcode</InputLabel>
                <Input
                    value={formValues.passcode || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('passcode', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="passcode"
                    type="text" />

                {isValid('passcode') && <FormHelperText>Must be alphanumeric and 4-10 characters in length</FormHelperText>}

                <ValidationMessage field="passcode" />
            </FormControl>
        </>
    );
};

export default PasscodeForm;