import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchDubberDubPoints } from '../dub-points/hooks';
import { useFetchDubberAccount, useFetchDubberTeam, useFetchDubberTeamMembers, useFetchDubberUsers, useUpdateDubberTeamMember } from '../hooks';
import { DubberPolicies } from '../policies';
import TeamSummary from '../teams/TeamSummary';
import { dubberTeamViewUrl } from '../urls';
import TeamMemberForm, { FormValues } from './CreateTeamMemberForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const TeamMemberCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const teamId = props.match.params['teamId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchUsers, isFetchingUsers, users, fetchUsersError] = useFetchDubberUsers();
    const [fetchTeam, isFetchingTeam, team, fetchTeamError] = useFetchDubberTeam();
    const [fetchMembers, isFetchingMembers, currentMembers, fetchMembersError] = useFetchDubberTeamMembers();
    const [fetchDubPoints, isFetchingDubPoints, dubPoints, fetchDubPointsError] = useFetchDubberDubPoints();
    const [updateMember, isUpdatingMember, memberUpdated, updateMemberError, updateMemberFieldErrors] = useUpdateDubberTeamMember();


    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingTeam || isFetchingMembers || isFetchingUsers || isFetchingDubPoints,
        fetchAccountError || fetchTeamError || fetchMembersError || fetchUsersError || fetchDubPointsError
    );

    useEffect(() => {
        fetchUsers(groupId, accountId);
        fetchTeam(groupId, accountId, teamId);
        fetchMembers(groupId, accountId, teamId);
        fetchAccount(groupId, accountId);
        fetchDubPoints(groupId, accountId);
    }, [accountId, teamId]);

    const [formValues, setFormValues] = useState<FormValues>({
        userId: '',
        listener: false,
        dubPointIds: []
    });

    const handleUpdate = (field: keyof FormValues, value: string | boolean | string[]) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Redirect back to view on cancel
    const handleCancel = () => {
        history.push(dubberTeamViewUrl(groupId, accountId, teamId));
    };

    const handleSubmit = () => {
        updateMember(groupId, accountId, teamId, formValues.userId, {
            listener: formValues.listener,
            dubPointIds: formValues.dubPointIds
        });
    };

    // Redirect to user view once updated
    useEffect(() => {
        if (memberUpdated) {
            dispatch(appWindowAddNotification('Team Member updated.', 'success'));
            history.push(dubberTeamViewUrl(groupId, accountId, teamId));
        }
    }, [memberUpdated]);

    return (
        <div className={classes.root}>

            {team !== null && account !== null && currentMembers !== null && users !== null && dubPoints !== null
                && <>
                    <PageHeader text="Add/Update Team Member" subtext={team.name} />

                    <TeamSummary
                        groupId={groupId}
                        account={account}
                        team={team} />

                    <Paper className={classes.paper}>
                        <TeamMemberForm
                            groupId={groupId}
                            accountId={accountId}
                            teamId={teamId}
                            userOptions={users}
                            accountDubPoints={dubPoints}
                            teamMembers={currentMembers}
                            formValues={formValues}
                            isSubmitting={isUpdatingMember}
                            onUpdate={handleUpdate}
                            errorMessage={updateMemberError}
                            fieldErrorMessages={updateMemberFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdatingMember}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdatingMember}
                            onClick={handleSubmit}>Save</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(TeamMemberCreatePage, DubberPolicies.CanManageTeams);