import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { Seat } from '../api';
import { PageValues, SeatError } from './StatementForm';
import StatementFormSeats from './StatementFormSeats';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    locationHeader: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface StatementFormLocationProps {
    isSubmitting: boolean;
    onChange: (seatId: number, field: 'pagesIn' | 'pagesOut', value: string) => void;
    seats: Seat[];
    seatPageValues: PageValues[];
    seatErrors: SeatError[];
}

const StatementFormGenericLocation = (props: StatementFormLocationProps) => {
    const classes = useStyles();
    const { isSubmitting, onChange, seats, seatErrors, seatPageValues } = props;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {seats.length === 0 && <Typography color="secondary" component="p">No seats have been entered for this organization</Typography>}

                {seats.length > 0 && <StatementFormSeats
                    isSubmitting={isSubmitting}
                    onChange={onChange}
                    seats={seats}
                    seatErrors={seatErrors}
                    seatPageValues={seatPageValues}
                />}
            </Paper>
        </div>
    );
};

export default StatementFormGenericLocation;