import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useFetchDubberAccount } from '../hooks';
import AccountSummary from './AccountSummary';
import AccountViewTabs, { Tab } from './AccountViewTabs';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
    activeTab?: Tab;
}

const AccountViewPage = (props: Props) => {
    const classes = useStyles();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const activeTab = props.activeTab ?? 'users';

    const [fetch, isFetching, account, fetchError] = useFetchDubberAccount();

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetching,
        fetchError
    );

    useEffect(() => {
        fetch(groupId, accountId);
    }, [accountId]);

    return (
        <div className={classes.root}>
            {account !== null
                && <>
                    <PageHeader text="Dubber Accounts" subtext={account.name} />

                    <div className={classes.marginBottom}>
                        <AccountSummary account={account} />
                    </div>

                    <AccountViewTabs
                        groupId={groupId}
                        account={account}
                        activeTab={activeTab} />
                </>
            }
        </div>
    );
};

export default AccountViewPage;