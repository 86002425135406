import { ApiCaller } from '../../../../api';
import { AddressBase, AddressType, OrderBase, OrderHistory, OrderNote } from '../../api';
import { OrderStatus } from '../api';

/*
export interface PortInOrderPage {
    total: number;
    next: string | null;
    orders: PortInOrderSummary[];
}
*/

export interface PortInOrderSummary extends OrderBase {
    id: string;
    type: 'PortIn';
    accountId: string;
    numberCount: number;
    customerOrderId: string | null;
    userId: string;
    lastModifiedDate: string;
    createDate: string;
    billingTelephoneNumber: string;
    companyName: string;
    lnpLosingCarrierId: number;
    lnpLosingCarrierName: string;
    ActualFocDate: string | null;
    requestedFocDate: string | null;
    actualFocDate: string | null;
    vendorId: number;
    vendorName: string;
    pon: string;
}

/**
 * GET /bandwidth/{accountId}/orders/port-ins
 * 
 * Get all Port-In Orders for an account. Can be filtered to just one sub-account or number
 * 
 * @param api
 * @param accountId
 */
export const fetchBandwidthPortInOrders = async (api: ApiCaller, accountId: string, subAccountId?: string, number?: string): Promise<PortInOrderSummary[]> => {
    return await api<PortInOrderSummary[]>(`bandwidth/${accountId}/orders/port-ins`, 'GET', {
        subAccountId,
        number
    });
};

export interface PortInOrder extends OrderBase {
    id: string;
    type: 'PortIn';
    status: OrderStatus;
    requestedFocDate: string | null;
    earliestEstimate: string | null;
    actualFocDate: string | null;
    loaAuthorizingPerson: string;
    subscriber: PortInSubscriber;
    billingTelephoneNumber: string;
    numbers: string[];
    pon: string;
    accountId: string;
    subAccountId: string;
    locationId: string;
    losingCarrierName: string;
    losingCarrierSpid: string;
    losingCarrierIsWireless: string;
    alternateSpid: string;
    vendorName: string;
    createDate: string;
    lastModifiedDate: string;
    userId: string;
    lastModifiedBy: string;
    customerOrderId: string;
    partialPort: boolean;
    immediately: boolean;
    triggered: boolean;
    portType: string;
    wirelessAccountNumber: string;
    wirelessPin: string;
    allowTrigger: boolean;
    history: OrderHistory[];
    notes: OrderNote[];
}

export interface PortInSubscriber {
    subscriberType: string;
    businessName: string;
    serviceAddress: PortInAddress;
}

export interface PortInAddress extends AddressBase {
    addressType: AddressType;
}

/**
 * GET /bandwidth/{accountId}/orders/port-ins/{orderId}
 * 
 * Get Port-In Order details
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthPortInOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<PortInOrder> => {
    return await api<PortInOrder>(`bandwidth/${accountId}/orders/port-ins/${orderId}`, 'GET');
};

/**
 * POST /bandwidth/{accountId}/orders/port-ins/{orderId}/trigger
 * 
 * Trigger a port-in order on FOC date
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const triggerBandwidthPortInOrder = async (api: ApiCaller, accountId: string, orderId: string): Promise<void> => {
    return await api<void>(`bandwidth/${accountId}/orders/port-ins/${orderId}/trigger`, 'POST');
};