// IDs in Webex API are a Base64-encoded URL that includes the UUID of the entity
// This helper function converts that string to just the UUID
export function webexIdToUuid(id: string) {
    var uuid = "UNKNOWN";

    try {
        var decoded = atob(id);
        var matches = decoded.match('/([a-z0-9\-]+)$');

        if (matches?.length == 2) {
            uuid = matches[1].toLowerCase();
        }
    } catch(e) {
    }

    return uuid;
}