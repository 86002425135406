import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment = require('moment');
import { LatestStatement } from './api';
import { fax2mailAssignmentViewUrl } from '../urls';
import { useDownloadStatement } from './hooks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    nowrap: {
        'white-space': 'nowrap'
    }
}));

interface Props {
    statements: LatestStatement[]; 
}

const LatestStatementsTable = (props: Props) => {
    const { statements } = props;
    const download = useDownloadStatement();
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell>Revision</TableCell>
                    <TableCell>Generated (UTC)</TableCell>
                    <TableCell>Total Pages In / Out</TableCell>
                    <TableCell>Total Charges</TableCell>
                    <TableCell>Download</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {statements.map(item => {
                    const stmt = item.details;
                    const LinkComponent = (props: any) => <RouterLink to={fax2mailAssignmentViewUrl(item.organizationId, item.assignmentId)} {...props} />;
                    const generatedDate = stmt != null ? moment(stmt.generatedAt).format('MM/DD/YYYY') : null;
                    const generatedTime = stmt != null ? moment(stmt.generatedAt).format('hh:mm A') : null;

                    return (
                        <TableRow key={item.assignmentId}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{item.organizationName}</Link>
                            </TableCell>
                            <TableCell>{stmt ? stmt.revision : 'N/A'}</TableCell>
                            <TableCell>{stmt ? <>{generatedDate}<br />{generatedTime}</> : 'N/A'}</TableCell>
                            <TableCell className={classes.nowrap}>{stmt ? (stmt.totalPagesIn + ' / ' + stmt.totalPagesOut) : 'N/A'}</TableCell>
                            <TableCell className={classes.nowrap}>{stmt ? '$ ' + stmt.totalCharges.toFixed(2) : 'N/A'}</TableCell>
                            <TableCell>
                                {stmt ? stmt.formats.map(format => {
                                    return (
                                        <Button
                                            key={format}
                                            variant="text"
                                            onClick={() => download(item.organizationId, item.assignmentId, stmt.id, format)}
                                            color="primary">
                                            {format}
                                        </Button>
                                    );
                                }) : 'N/A'}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default LatestStatementsTable;