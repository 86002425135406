import { Divider, FormControl, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { SubAccount } from '../api';
import { UpdateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    subAccountOptions: SubAccount[];
    formValues: UpdateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: UpdateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: UpdateAssignment): boolean => {
    return form.bandwidthSubAccount !== '';
};

/**
 * SIP Trunking update assignment form
 * @param props
 * @constructor
 */
const UpdateAssignmentForm = (props: Props) => {
    const { organization, subAccountOptions, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);
    
    const displayError = errorMessage;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            <FormControl className={classes.formControl} fullWidth required
                error={!isValid('bandwidthSubAccount')}>
                <InputLabel htmlFor="bandwidthSubAccount">Bandwidth Sub-Account</InputLabel>

                <Select
                    id="bandwidthSubAccount"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={`${formValues.bandwidthAccount}/${formValues.bandwidthSubAccount}` || ''}
                    onChange={evt => {
                        if (evt.target.value && evt.target.value.length > 0) {
                            const x = evt.target.value.split('/');

                            setFormValues({
                                ...formValues,
                                bandwidthAccount: x[0],
                                bandwidthSubAccount: x[1]
                            });
                        }
                    }}
                    input={<Input name="bandwidthSubAccount" id="bandwidthSubAccount" />}>

                    <option key="" value="" />

                    {subAccountOptions.map(acct => {
                        const key = `${acct.accountId}/${acct.id}`;

                        return <option key={key} value={key}>{acct.name} ({acct.accountId} / {acct.id})</option>;
                    })}
                </Select>

                <ValidationMessage field="bandwidthSubAccount" />
            </FormControl>
        </>
    );
};

export default UpdateAssignmentForm;