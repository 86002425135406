import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { RateCenter } from './api';

const useStyles = makeStyles(() => ({
    gridItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0)
    },
    message: {
        marginTop: theme.spacing(1)
    },
    rateCenter: {
        marginBottom: theme.spacing(3)
    },
    numbers: {
        listStyle: 'none',
        padding: 0,
        margin: 0
    }
}));

interface Props {
    rateCenters: RateCenter[];
}

const RateCenterPane = (props: Props) => {
    const classes = useStyles();
    const { rateCenters } = props;

    const supportedRateCenters = rateCenters.filter(r => r.isSupported);
    const unsupportedRateCenters = rateCenters.filter(r => !r.isSupported);

    return (
        <Grid container>
            <Grid xs={6} item className={classes.gridItem}>
                <Typography variant="h4" color="primary">Supported Rate Centers ({supportedRateCenters.length})</Typography>

                {supportedRateCenters.length > 0
                    ? supportedRateCenters.map(r => <RateCenterView rateCenter={r} key={r.name} />)
                    : <Typography className={classes.message}>None of the rate centers are supported.</Typography>
                }
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

            <Grid xs={6} item className={classes.gridItem}>
                <Typography variant="h4" color="error">Unsupported Rate Centers ({unsupportedRateCenters.length})</Typography>
                {unsupportedRateCenters.length > 0
                    ? unsupportedRateCenters.map(r => <RateCenterView rateCenter={r} key={r.name} />)
                    : <Typography className={classes.message}>All of the rate centers are supported.</Typography>
                }
            </Grid>
        </Grid>
    );
};

const RateCenterView = (props: { rateCenter: RateCenter }) => {
    const classes = useStyles();
    const { rateCenter } = props;

    return <div className={classes.rateCenter}>
        <Typography variant="h6" className={classes.subheading}>{rateCenter.name}</Typography>

        <Grid container spacing={1} columnSpacing={2}>
            <Grid item>
                <Typography variant="caption" color="textSecondary">City</Typography>
                <Typography>{rateCenter.city}</Typography>
            </Grid>

            <Grid item>
                <Typography variant="caption" color="textSecondary">State</Typography>
                <Typography>{rateCenter.state}</Typography>
            </Grid>

            <Grid item>
                <Typography variant="caption" color="textSecondary">LATA</Typography>
                <Typography>{rateCenter.lata}</Typography>
            </Grid>

            {rateCenter.tiers.length > 0 &&
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Tiers</Typography>
                    <Typography>{rateCenter.tiers.sort().join(', ')}</Typography>
                </Grid>
            }
        </Grid>

        <Grid container spacing={1} columnSpacing={2}>
            <Grid item>
                <Typography variant="caption" color="textSecondary">Numbers</Typography>
                <Typography component="div">
                    <ul className={classes.numbers}>
                        {rateCenter.numbers.sort().map(n => <li key={n}>{n}</li>)}
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    </div>;
};

export default RateCenterPane;