export const WEBEX_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/webex/:assignmentId';
export const WEBEX_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/webex/:assignmentId/update';
export const WEBEX_ORGANIZATION_INDEX_URL = '/webex/organizations';
export const WEBEX_BULK_UPLOADS_INDEX_URL = '/webex/bulk-uploads';
export const WEBEX_REDSKY_INDEX_URL = '/webex/redsky';

export const webexOrganizationIndexUrl = () => WEBEX_ORGANIZATION_INDEX_URL;

export const webexAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    WEBEX_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const webexAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    WEBEX_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const webexBulkUploadsIndexUrl = () => WEBEX_BULK_UPLOADS_INDEX_URL;

export const webexRedSkyIndexUrl = () => WEBEX_REDSKY_INDEX_URL;