import { FormControl, Input, InputLabel, LinearProgress, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from 'react';
import theme from '../../../theme';
import { Account } from '../accounts/api';
import { useFetchBandwidthSubAccounts } from '../hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    disabled: boolean;
    accounts: Account[];
    onError: (message: string) => void;
    onSelected: (accountId: string, subAccountId: string) => void;
}

export const SearchForm = (props: Props) => {
    const classes = useStyles();
    const { accounts, disabled, onError, onSelected } = props;
    const [fetchSubAccounts, isFetchingSubAccounts, subaccounts, subAccountsErrorMessage] = useFetchBandwidthSubAccounts();

    const [accountId, setAccountId] = useState<string | null>(null);
    const [subAccountId, setSubAccountId] = useState<string | null>(null);

    // Alert parent when account & subaccount are selected
    useEffect(() => {
        if (accountId !== null && subAccountId !== null) {
            onSelected(accountId, subAccountId);
        }
    }, [accountId, subAccountId]);

    // Fetch sub-accounts when account selected
    useEffect(() => {
        if (accountId && !subAccountId) {
            fetchSubAccounts(accountId);
        }
    }, [accountId, subAccountId]);

    // Report any error back to the parent
    useEffect(() => {
        if (subAccountsErrorMessage) {
            onError(subAccountsErrorMessage);
        }

    }, [subAccountsErrorMessage]);

    return (
        <>
            <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor="accountId">Account</InputLabel>
                <Select
                    id="accountId"
                    native
                    margin="none"
                    value={accountId || ''}
                    disabled={disabled}
                    input={<Input name="accountId" id="accountId" />}
                    onChange={evt => {
                        setAccountId(evt.target.value || null);
                        setSubAccountId(null);
                    }}
                    onKeyDown={() => false}>
                    <option value="" />
                    {accounts.map(acc => <option key={acc.id} value={acc.id}>{acc.name} ({acc.id})</option>)}
                </Select>
            </FormControl>

            {accountId &&
                <FormControl className={classes.formControl} fullWidth required>
                    {isFetchingSubAccounts && <LinearProgress />}

                    {!isFetchingSubAccounts && subaccounts &&
                        <>
                            <InputLabel htmlFor="subAccountId">Sub-Account</InputLabel>
                            <Select
                                id="subAccountId"
                                native
                                margin="none"
                                value={subAccountId || ''}
                                disabled={disabled}
                                input={<Input name="subAccountId" id="subAccountId" />}
                                onChange={evt => setSubAccountId(evt.target.value || null)}
                                onKeyDown={() => false}>
                                <option value="" />
                                {subaccounts.sort((a, b) => a.name.localeCompare(b.name)).map(sa => <option key={sa.id} value={sa.id}>{sa.name} ({sa.id})</option>)}
                            </Select>
                        </>
                    }
                </FormControl>
            }
        </>
    );
};

export default SearchForm;