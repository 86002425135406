import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_MFA_COMPLETE } from './actions';
import { AuthState, INITIAL_STATE } from './store';
import moment = require('moment');
import { AllActions } from '../../store';
import { API_CALL_SUCCESS } from '../../api';
import { SESSION_LIFESPAN } from '../../constants';

/**
 * Reducer for Authentication state
 * @param state
 * @param action
 */
export default function reducer(state: AuthState = INITIAL_STATE, action: AllActions): AuthState {
    switch (action.type) {
        case AUTH_LOGIN:
            state = {
                ...state,
                token: action.payload.token,
                expiresAt: action.payload.expiresAt,
                roles: action.payload.roles,
                administratorId: action.payload.administratorId
            };
            break;

        case AUTH_MFA_COMPLETE:
            state = {
                ...state,
                status: 'Authenticated'
            };
            break;

        case AUTH_LOGOUT:
            state = INITIAL_STATE;
            break;

        // On a successful API call, the lifetime of the current session is extended to the current time + session lifespan
        case API_CALL_SUCCESS:
            // Only update if a session already exists and the API call wasn't to the sessions endpoint
            if ((action.payload.url != 'sessions') && (state.expiresAt != null) && (state.status === 'Authenticated')) {
                state = {
                    ...state,
                    expiresAt: moment.utc().add(SESSION_LIFESPAN, 'seconds').format()
                };
            }
            break;

    }

    return state;
}