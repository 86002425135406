import { Button, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from 'react-router';
import { FieldError } from "../../api";
import PageHeader from '../../components/PageHeader';
import { MAIN_WIDTH } from '../../constants';
import history from '../../history';
import theme from '../../theme';
import { appWindowAddNotification } from "../app-window/actions";
import { useNav, useProgressEffects } from "../app-window/hooks";
import { useSession } from "../auth/hooks";
import { useFetchUserProfile, useUpdateUserProfile } from "./hooks";
import { userProfileUrl } from "./urls";
import UserProfileForm, { FormValues } from './UserProfileForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const UserProfileUpdatePage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const [fetch, isFetching, profile, fetchError] = useFetchUserProfile();
    const [update, isUpdating, updatedProfile, updateError, updateServerFieldErrors] = useUpdateUserProfile();
    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const [formValues, setFormValues] = useState<FormValues>({
        email: '',
        firstName: '',
        lastName: '',
        setPassword: false,
        password: '',
        confirmPassword: ''
    });

    useNav();

    useEffect(() => {
        fetch();
    }, [session.token]);

    // Set default form values once loaded
    useEffect(() => {
        if (profile) {
            setFormValues({
                ...formValues,
                email: profile.email,
                firstName: profile.firstName,
                lastName: profile.lastName
            });
        }
    }, [profile])

    useEffect(() => {
        // Once profile is updated, redirect back
        if (updatedProfile) {
            dispatch(appWindowAddNotification('Profile updated.', 'success'));
            history.push(userProfileUrl());
        }
    }, [updatedProfile]);

    useProgressEffects(isFetching || isUpdating, fetchError);

    const handleOnEnter = () => {
        handleSubmit();
    };

    const handleOnUpdate = (field: keyof FormValues, value: string | boolean) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    const handleCancel = () => {
        history.push(userProfileUrl());
    };

    const handleSubmit = () => {
        var newErrors = [];

        // Validate local fields before submitting
        if (formValues.setPassword) {
            if (formValues.password.length === 0) {
                newErrors.push({
                    field: 'password',
                    message: 'Value cannot be blank.'
                });
            } else if (formValues.password !== formValues.confirmPassword) {
                newErrors.push({
                    field: 'password',
                    message: 'Passwords do not match.'
                });

                newErrors.push({
                    field: 'confirmPassword',
                    message: 'Passwords do not match.'
                });
            }
        }

        if (newErrors.length === 0) {
            update(formValues);
        }

        setFieldErrors(newErrors);
    };

    const allFieldErrors = fieldErrors.concat(updateServerFieldErrors);

    return (
        <div className={classes.root}>
            {!isFetching && profile &&
                <>
                    <PageHeader text="Update Profile" />

                    <>
                        <Paper className={classes.paper}>
                            <UserProfileForm
                                formValues={formValues}
                                validationMessage={updateError}
                                fieldValidationMessages={allFieldErrors}
                                isSubmitting={isUpdating}
                                onEnter={handleOnEnter}
                                onUpdate={handleOnUpdate}
                            />
                        </Paper>

                        <Grid container justifyContent="flex-end">
                            <Button
                                className={classes.button}
                                color="inherit"
                                variant="contained"
                                disabled={isUpdating}
                                onClick={handleCancel}>Cancel</Button>

                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                disabled={isUpdating}
                                onClick={handleSubmit}>Update</Button>
                        </Grid>
                    </>
                </>
            }
        </div>
    );
};

export default UserProfileUpdatePage;