import { applyMiddleware, compose, createStore, Store as ReduxStore } from 'redux';
import { createBrowserHistory } from 'history'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import { createRootReducer } from './rootReducer';
import { AuthState, INITIAL_STATE as AUTH_INITIAL_STATE } from './features/auth/store';
import * as authActions from './features/auth/actions';
import { AppWindowState, INITIAL_STATE as APP_WINDOW_INITIAL_STATE } from './features/app-window/store';
import * as appWindowActions from './features/app-window/actions';
import { Actions as ApiActions } from './api';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose,
        reduxStore?: ReduxStore<State, AllActions>,
        reduxPersistor?: Persistor
    }
}

// Application uses History API
export const history = createBrowserHistory();

// The root redux state
export interface State {
    appWindow: AppWindowState
    auth: AuthState
}

const initialState: State = {
    appWindow: APP_WINDOW_INITIAL_STATE,
    auth: AUTH_INITIAL_STATE
};

// All actions that exist in the system
export type AllActions =
    | appWindowActions.Actions
    | authActions.Actions
    | ApiActions;

// Creates the root store and adds middleware
function configureStore(): [ReduxStore<State, AllActions>, Persistor] {
    // Add support for Redux devtools extension
    const composeEnhancer: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    let store = null;
    let persistor = null;

    // A reference to the store is kept in the window object
    // This is used for HMR to persist the store between reloads
    if (window.reduxStore && window.reduxPersistor) {
        store = window.reduxStore;
        persistor = window.reduxPersistor;
    } else {
        // We want to persist the authentication portion of the Redux store in localStorage.
        // That way a user can refresh the page or come back later and not have to login again.
        const persistConfig = {
            key: 'root',
            storage,
            whitelist: ['auth']
        };

        const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

        store = createStore(
            persistedReducer,
            initialState,
            composeEnhancer(
                applyMiddleware(
                    thunk
                ),
            ),
        );

        persistor = persistStore(store);

        window.reduxStore = store;
        window.reduxPersistor = persistor;
    }

    return [store, persistor];
}

const configuredStore = configureStore();

export const Store: ReduxStore<State, AllActions> = configuredStore[0];
export const StorePersistor: Persistor = configuredStore[1];