import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { Routing } from '../api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    routing: Routing
}

// Routing Summary Box
const RoutingSummary = (props: Props) => {
    const classes = useStyles();
    const { routing } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                {routing.forwardedNumber !== null &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Call Forwarding Number</Typography>
                        <Typography>
                            {routing.forwardedNumber}
                        </Typography>
                    </Grid>
                }

                {routing.customUser !== null &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Custom URI User Value</Typography>
                        <Typography>
                            {routing.customUser}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>
    );
}

export default RoutingSummary;