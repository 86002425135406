import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useFetchDubberAccounts, useFetchDubberGroups } from '../hooks';
import AccountSearchBar from './AccountSearchBar';
import AccountTable from './AccountTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    page: number;
    search: string;
    limit: number;
}

const compareForm = (a: Form, b: Form) => a.page == b.page && a.limit == b.limit && a.search == b.search;

const formFromQuery = (query: ParsedQuery<string>): Form => {
    const pageQuery = parseInt(String(query['page'] || 1));
    const limitQuery = parseInt(String(query['limit'] || DEFAULT_PAGE_SIZE));
    const searchQuery = String(query['search'] || '');

    return {
        page: pageQuery,
        search: searchQuery,
        limit: limitQuery
    }
};

const handleSubmitSearch = (limit: number, search: string) => {
    var query: Record<string, string> = {
        limit: String(limit),
        search,
        page: "1"
    };

    const params = new URLSearchParams(query);

    history.push('?' + params.toString());
}

// Page for listing all accounts in Dubber
const AccountIndexPage = (props: Props) => {
    const classes = useStyles();
    const [form, setForm] = useState<Form>({
        page: 1,
        search: '',
        limit: DEFAULT_PAGE_SIZE
    })

    const [fetchGroups, isFetchingGroups, groups, groupErrorMessage] = useFetchDubberGroups();
    const [fetch, isFetching, accounts, errorMessage] = useFetchDubberAccounts();

    // Parse query string
    const params = parse(location.search);

    // Refresh list if current form settings don't match the query string
    var queryForm = formFromQuery(params);

    if (!compareForm(form, queryForm)) {
        setForm(queryForm);
    }

    // Update navigation
    useNav('dubber', 'accounts');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingGroups,
        errorMessage || groupErrorMessage
    );

    // Trigger group fetch on page load
    useEffect(() => {
        fetchGroups();
    }, []);

    // Once groups are fetched, pull accounts
    // Right now we assume there's only one group
    useEffect(() => {
        if (groups && groups.length === 1) {
            fetch(groups[0].id);
        }
    }, [groups]);

    const groupId = useMemo(() => {
        if (groups && groups.length === 1) {
            return groups[0].id;
        }

        return null;
    }, [groups]);

    // Pagination accounts based on filter form
    const pagedAccounts = useMemo(() => {
        if (accounts === null) {
            return null;
        }

        const filteredAccounts = accounts.filter(a => {
            const search = form.search.toLowerCase().trim();

            if (search !== '') {
                return a.name.toLowerCase().indexOf(search) > -1;
            }

            return true;
        });

        return {
            accounts: filteredAccounts.slice((form.page - 1) * form.limit, form.page * form.limit),
            totalItems: filteredAccounts.length,
            pageSize: form.limit,
            page: form.page
        };
    }, [accounts, form]);

    return (
        <div className={classes.root}>
            <PageHeader text="Dubber" subtext="Accounts" />

            <Paper>
                <AccountSearchBar
                    limit={form.limit}
                    search={form.search}
                    onSubmitSearch={handleSubmitSearch}
                />

                {accounts !== null
                    && accounts.length === 0
                    && <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">No accounts found.</Typography>
                    </div>
                }

                {pagedAccounts !== null
                    && pagedAccounts.totalItems > 0
                    && groupId != null
                    && <>
                        <AccountTable groupId={groupId} items={pagedAccounts.accounts} />
                        <Pagination
                            totalItems={pagedAccounts.totalItems}
                            pageSize={pagedAccounts.pageSize}
                            page={pagedAccounts.page}
                            disabled={isFetching}
                            additionalParams={{
                                search: form.search,
                                limit: form.limit
                            }}
                            onChange={() => null} />
                    </>
                }
            </Paper>
        </div>
    );
};

export default AccountIndexPage;