import * as React from 'react';
import { useNav } from '../app-window/hooks';
import { makeStyles } from '@mui/styles';
import { MAIN_WIDTH } from '../../constants';
import PageHeader from '../../components/PageHeader';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    }
}));

/**
 * Dashboard screen
 * TODO!
 * @constructor
 */
const DashboardPage = () => {
    useNav('dashboard');

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PageHeader text="Dashboard"/>

            <Typography variant="body1">Welcome to the Konica Minolta Unified Communications administration system.</Typography>
        </div>
    );
};

export default DashboardPage;