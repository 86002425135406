import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { bandwidthLocationViewUrl, bandwidthNumberViewUrl } from '../urls';
import { formatUsNumber } from '../numbers/helpers';

interface Props {
    accountId: string;
    subAccountId: string;
    numbers: ListItem[]
}

export interface ListItem {
    number: string;
    locationId: string;
    locationName: string;
}

/**
 * Table for displaying all numbers under a sub-account
 * @param props
 */
const NumbersTable = (props: Props) => {
    const { accountId, subAccountId, numbers } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Location</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {numbers.map(n => {
                    const NumberLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n.number)} {...props} />);
                    const LocationLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthLocationViewUrl(accountId, subAccountId, n.locationId)} {...props} />);

                    return (
                        <TableRow key={n.number}>
                            <TableCell component="th" scope="row">
                                <Link component={NumberLinkComponent}>{formatUsNumber(n.number)}</Link>
                            </TableCell>

                            <TableCell>
                                <Link component={LocationLinkComponent}>{n.locationName}</Link>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumbersTable;