import { FormControl, FormHelperText, Grid, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { Language, LANGUAGES } from '../api';
import { languageLabel } from '../helpers';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export interface FormValues {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    language: Language;
}

interface Props {
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: string) => void;
    onEnter: () => void;
}

export const formComplete = (organization: OrganizationDetails, form: FormValues): boolean => true;

const UserUpdateForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, onEnter } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onEnter();
        }
    };

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('firstName')}>
                        <InputLabel htmlFor="firstName">First Name</InputLabel>
                        <Input
                            autoFocus
                            disabled={isSubmitting}
                            id="firstName"
                            value={formValues.firstName}
                            onChange={evt => onUpdate('firstName', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="firstName" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('lastName')}>
                        <InputLabel htmlFor="lastName">Last Name</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="lastName"
                            value={formValues.lastName}
                            onChange={evt => onUpdate('lastName', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />
                        <ValidationMessage field="lastName" />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('mobileNumber')}>
                        <InputLabel htmlFor="mobileNumber">Mobile Number</InputLabel>
                        <Input
                            disabled={isSubmitting}
                            id="mobileNumber"
                            value={formValues.mobileNumber}
                            onChange={evt => onUpdate('mobileNumber', evt.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text" />

                        {isValid('mobileNumber') && <FormHelperText>Number must be in format 1XXXXXXXXXX</FormHelperText>}

                        <ValidationMessage field="mobileNumber" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('language')}>
                        <InputLabel htmlFor="language">Language</InputLabel>
                        <Select
                            id="language"
                            margin="none"
                            native={true}
                            disabled={isSubmitting}
                            value={formValues.language}
                            input={<Input name="language" id="language" />}
                            onChange={evt => onUpdate('language', evt.target.value)}
                            onKeyDown={handleKeyDown}>
                            {LANGUAGES.map(l => <option value={l} key={l}>{languageLabel(l)}</option>)}
                        </Select>

                        <ValidationMessage field="language" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default UserUpdateForm;