import { Button, FormControl, Grid, LinearProgress, Link, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import history from '../../../history';
import theme from '../../../theme';
import { loginUrl } from '../../auth/urls';
import { BulkUpload, createBulkUpload, fetchBulkUpload } from '../api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

// Hook for handling the form submission
const useFormHandler = (organizationId: number, assignmentId: number, onSuccess: (upload: BulkUpload) => void) => {
    const api = useApi();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationFieldMessages, setValidationFieldMessages] = useState<FieldError[]>([]);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    let didCancel = false;

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, [organizationId, assignmentId]);

    // Handle submit
    const handleSubmit = useCallback(async (contents: string) => {
        setIsSubmitting(true);

        try {
            const createdUpload = await createBulkUpload(api, organizationId, assignmentId, contents);

            if (!didCancel) {
                onSuccess(createdUpload);
            }
        } catch(e) {
            if (!didCancel) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, we'll either display error messages under the fields
                    // or display a generic error message if no field errors are listed
                    setValidationMessage(e.fields && (e.fields.length > 0) ? null : e.message);
                    setValidationFieldMessages(e.fields && (e.fields.length > 0) ? e.fields : []);
                } else {
                    // For generic errors, display a generic error message
                    setFailureMessage('Unable to upload.');
                    setValidationMessage(null);
                    setValidationFieldMessages([]);
                }

                setIsSubmitting(false);
            }
        }
    }, [organizationId, assignmentId, didCancel, onSuccess]);

    return {
        isSubmitting,
        validationMessage,
        validationFieldMessages,
        failureMessage,
        onSubmit: handleSubmit
    };
};

interface UploadFormProps {
    organizationId: number;
    assignmentId: number;
    onComplete: (upload: BulkUpload) => void;
    onDownloadPasswords: (uploadId: number) => void;
    onShowErrors: (uploadId: number) => void;
    onShowZtpWarnings: (uploadId: number) => void;
}

type Status = 'idle' | 'uploading' | 'processing' | 'error' | 'success';

export const UploadForm = (props: UploadFormProps) => {
    const classes = useStyles();

    const [error, setError] = useState<string | null>(null);
    const [uploadErrorCount, setUploadErrorCount] = useState(0);
    const [uploadZtpErrorCount, setUploadZtpErrorCount] = useState(0);

    const [status, setStatus] = useState<Status>('idle');
    const [filename, setFilename] = useState<string | null>(null);
    const [currentUpload, setCurrentUpload] = useState<BulkUpload | null>(null);

    const api = useApi();

    const fileInput = useRef<HTMLInputElement | null>(null);

    const {
        validationMessage,
        validationFieldMessages,
        failureMessage,
        onSubmit
    } = useFormHandler(props.organizationId, props.assignmentId, upload => {
        setStatus('processing');
        setCurrentUpload(upload);
    });

    // Clear current state if organization changes
    useEffect(() => {
        setStatus('idle');
        setFilename(null);
        setCurrentUpload(null);
    }, [props.assignmentId]);

    // If any error occurred during the upload, show a generic error
    useEffect(() => {
        if (validationMessage !== null || validationFieldMessages.length > 0 || failureMessage !== null) {
            setStatus('error');
            setError('File was rejected by the server.');
        }
    }, [validationMessage, validationFieldMessages, failureMessage]);

    // If currentUpload is set, then a file was uploaded and we're waiting for it to be processed.
    // Set up a timer to check the status periodically.
    useEffect(() => {
        if (currentUpload !== null) {
            let fetching = false;

            switch (currentUpload.status) {
                case 'Pending':
                case 'Working':
                    // If upload is still running, schedule an update
                    const desc = setInterval(() => {
                        (async () => {
                            if (!fetching) {
                                fetching = true;
                                const updatedUpload = await fetchBulkUpload(api, props.organizationId, props.assignmentId, currentUpload.id);
                                setCurrentUpload(updatedUpload);
                                fetching = false;
                            }
                        })();
                    }, 3000);

                    return () => {
                        clearInterval(desc);
                    };
                case 'Error':
                    //setError(currentUpload.errorMessage);
                    setError(null);
                    setUploadErrorCount(currentUpload.errorMessage === null ? 0 : currentUpload.errorMessage.split("\n").length);
                    setStatus('error');
                    props.onComplete(currentUpload);
                    break;
                case 'Success':
                    setStatus('success');
                    setError(null);
                    setUploadZtpErrorCount(currentUpload.ztpErrorMessages === null ? 0 : currentUpload.ztpErrorMessages.split("\n").length);
                    props.onComplete(currentUpload);
                    break;
            }
        }
    }, [currentUpload]);

    // Clicking "Select File" triggers the file input field to request a file
    const handleSelectFileClick = () => {
        if (fileInput !== null && fileInput.current !== null) {
            fileInput.current.click();
        }
    };

    // Once a file is selected, the contents are read and submitted to the server
    const handleFileSelection = (target: HTMLInputElement) => {
        if (target !== null && target.files !== null && target.files.length == 1) {
            setCurrentUpload(null);

            const fileReader = new FileReader();

            fileReader.onerror = (e) => {
                setStatus('error');
                setError('Unable to read file');
                setFilename(null);
            };

            // Once FileReader is done reading the file, upload it to the server
            fileReader.onload = (e) => {
                if (e.target !== null && e.target.result !== null) {
                    setFilename(target && target.files ? target.files[0].name : null);
                    setStatus('uploading');

                    // Upload file
                    onSubmit(e.target.result.toString().split(',')[1]);
                }
            };

            // Reads selected file as a Base64-encoded string
            fileReader.readAsDataURL(target.files[0]);
        }
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth required>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        disabled={status === 'uploading' || status === 'processing'}
                        onClick={handleSelectFileClick}>Select File</Button>

                    <input
                        ref={fileInput}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        id="upload-field"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={evt => handleFileSelection(evt.target)}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={9}>
                {status == 'uploading' &&
                    <>
                        <Typography>Uploading {filename}</Typography>
                        <LinearProgress variant="indeterminate" color="primary"/>
                    </>}

                {status == 'processing' &&
                <>
                    <Typography>Processing {filename}</Typography>
                    <LinearProgress variant="determinate" color="primary" value={currentUpload && currentUpload.pctComplete ? currentUpload.pctComplete : 0}/>
                </>}

                {status == 'success' && currentUpload !== null &&
                    <>
                    <Typography color="default">
                        {uploadZtpErrorCount > 0
                            ? <>Upload sucessful, with <Link href="#" onClick={() => props.onShowZtpWarnings(currentUpload.id)} color="secondary">{uploadZtpErrorCount} ZTP Warning{uploadZtpErrorCount != 1 ? 's' : ''}</Link></>
                            : <>Upload sucessful</>
                        }
                        </Typography>

                        <Typography component="p"><Link
                        href="#"
                            onClick={() => props.onDownloadPasswords(currentUpload.id)}
                            color="primary">Download User Logins</Link></Typography>
                    </>}

                {status == 'error' &&
                    <Typography color="error">
                    {uploadErrorCount === 0 && error}

                    {uploadErrorCount > 0 && currentUpload &&
                        <>Upload failed
                        <Typography component="p"><Link
                            href="#"
                            onClick={() => props.onShowErrors(currentUpload.id)}
                            color="secondary">View {uploadErrorCount} Error{uploadErrorCount != 1 ? 's' : ''}</Link></Typography></>}
                    </Typography>
                }
            </Grid>
        </Grid>
    );
};