import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchDubberAccount } from '../accounts/hooks';
import { useCreateDubberUser } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberAccountViewUrl, dubberUserViewUrl } from '../urls';
import UserCreateForm, { FormValues } from './UserCreateForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const UserCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];

    const [createUser, isCreatingUser, createdUser, createUserError, createUserFieldErrors] = useCreateDubberUser();
    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();

    const [formValues, setFormValues] = useState<FormValues>({
        firstName: '',
        lastName: '',
        email: '',
        role: 'StandardUser',
        mobileNumber: '',
        language: 'en-US'
    });

    useNav('dubber', 'accounts');

    useEffect(() => {
        fetchAccount(groupId, accountId);
    }, [groupId, accountId]);

    useProgressEffects(isFetchingAccount, fetchAccountError);

    const handleUpdate = (field: keyof FormValues, value: string) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Press cancel goes back to the account view
    const handleCancel = () => {
        history.push(dubberAccountViewUrl(groupId, accountId));
    };

    const handleSubmit = () => {
        createUser(groupId, accountId, {
            role: formValues.role,
            email: formValues.email || null,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            mobileNumber: formValues.mobileNumber || null,
            language: formValues.language
        });
    };

    // Redirect to user view once updated
    useEffect(() => {
        if (createdUser) {
            dispatch(appWindowAddNotification('User created.', 'success'));
            history.push(dubberUserViewUrl(groupId, accountId, createdUser.id));
        }
    }, [createdUser]);

    return (
        <div className={classes.root}>
            {!isFetchingAccount && account &&
                <>
                    <PageHeader text="Create User" subtext={account.name} />

                    <Paper className={classes.paper}>
                        <UserCreateForm
                            formValues={formValues}
                            isSubmitting={isCreatingUser}
                            onUpdate={handleUpdate}
                            onEnter={() => handleSubmit()}
                            errorMessage={createUserError}
                            fieldErrorMessages={createUserFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isCreatingUser}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isCreatingUser}
                            onClick={handleSubmit}>Create</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(UserCreatePage, DubberPolicies.CanCreateAndDeleteUsers);