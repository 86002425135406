import { ApiCaller } from '../../api';

export const ACCOUNTS_SIP_TRUNKING = ['5008762', '5008853'];
export const ACCOUNTS_HOSTED_PBX = ['5004382'];

export const generateActiveDidsReport = async (api: ApiCaller, year: number, month: number, accountIds: string[]): Promise<string> => {
    const response = await api<{url: string}>(`reporting/did-usage`, 'POST', {
        year,
        month,
        accountIds
    });

    return response.url;
};

export const generateHpbxUsageReport = async (api: ApiCaller, year: number, month: number, groupId: string): Promise<string> => {
    const response = await api<{url: string}>(`reporting/hpbx-usage`, 'POST', {
        year,
        month,
        groupId
    });

    return response.url;
};