import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Organization } from './api';
import { directRoutingAssignmentViewUrl } from '../urls';
import moment = require('moment');
import { Customer } from '../api';

interface Props {
    items: Organization[];
    iPilotCustomers: Customer[];
}

const OrganizationTable = (props: Props) => {
    const { items, iPilotCustomers } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell>iPilot Account</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assign Date</TableCell>
                    <TableCell>Cancel Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(org => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={directRoutingAssignmentViewUrl(org.id, org.assignmentId)} {...props} />);
                    const customer = iPilotCustomers.find(c => c.id == String(org.iPilotAccountId));
                    const customerName = customer === undefined ? 'Unknown' : customer.name;

                    return (
                        <TableRow key={org.assignmentId}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{org.name}</Link>
                            </TableCell>
                            <TableCell>
                                {customerName}
                            </TableCell>
                            <TableCell>{org.isActive ? "Active" : "Cancelled"}</TableCell>
                            <TableCell>{moment(org.startedAt).format('MM/DD/YYYY')}</TableCell>
                            <TableCell>{org.cancelledAt !== null ? moment(org.cancelledAt).format('MM/DD/YYYY') : ''}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default OrganizationTable;