import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    }
}));

interface Props {
    sapId: string | null;
    address: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;

    reseller: {
        id: number;
        name: string;
    } | null;
}

/**
 * Displays summary details about an organization
 * @param props
 * @constructor
 */
const SummaryBox = (props: Props) => {
    const classes = useStyles();
    const { sapId, address, address2, city, state, zip, reseller } = props;

    const showAddress =
        (address !== null && address.length > 0)
        || (address2 !== null && address2.length > 0)
        || (city !== null && city.length > 0)
        || (state !== null && state.length > 0)
        || (zip !== null && zip.length > 0);

    const Address = () => {
        const showComma = (city !== null && city.length > 0)
            && ((state !== null && state.length > 0) || (zip !== null && zip.length > 0));

        return (
            <>
                {address !== null && address.length > 0 && <>{address}<br /></>}
                {address2 !== null && address2.length > 0 && <>{address2}<br /></>}
                {city}{showComma && <>,</>} {state} {zip}
            </>
        );
    };

    const visible = showAddress || reseller !== null || sapId;

    return (
        <>
            {visible &&
                <Paper className={classes.root}>
                    <Grid container spacing={32}>
                        {sapId && <Grid item>
                            <Typography variant="caption" color="textSecondary">SAP ID</Typography>
                            <Typography>{sapId}</Typography>
                        </Grid>}

                        {showAddress &&
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">Address</Typography>
                                <Typography>
                                    <Address />
                                </Typography>
                            </Grid>
                        }

                        {reseller !== null &&
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">Reseller</Typography>
                                <Typography>
                                    <Link component={p => <RouterLink
                                        to={`/organizations/${reseller.id}`} {...p as any} />}>{reseller.name}</Link>
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            }
        </>
    );
};

export default SummaryBox;