import { Platform } from './api';

export function platformDisplay(platform: Platform) {
    switch (platform) {
        case 'Cisco':
            return 'Cisco BroadCloud';

        case 'Averistar':
            return 'AveriStar';

        default:
            return platform;
    }
}