import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useFetchBandwidthAccounts } from '../hooks';
import AccountForm from './AccountForm';
import { useFetchBandwidthSubAccounts } from './hooks';
import SubAccountSearchBar from './SubAccountSearchBar';
import SubAccountTable from './SubAccountTable';
const queryString = require('query-string');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    accountId: string;
    search: string;
    page: number;
    limit: number;
}

const compareForm = (a: Form, b: Form) =>
    a.accountId == b.accountId
    && a.search == b.search
    && a.page == b.page
    && a.limit == b.limit;

const formFromQuery = (query: ParsedQuery<string>): Form => {
    const searchQuery = String(query['search'] || '');
    const accountIdQuery = String(query['accountId'] || '');

    const pageQuery = parseInt(String(query['page'] || 1));
    const limitQuery = parseInt(String(query['limit'] || DEFAULT_PAGE_SIZE));

    return {
        accountId: accountIdQuery,
        search: searchQuery,
        page: pageQuery,
        limit: limitQuery
    }
};

// Page for listing all sub-accounts within a Bandwidth account
const SubAccountIndexPage = (props: Props) => {
    const classes = useStyles();

    const [form, setForm] = useState<Form>({
        accountId: '',
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    });

    const [fetchAccounts, isFetchingAccounts, accounts, accountsErrorMessage] = useFetchBandwidthAccounts();
    const [fetchSubAccounts, isFetchingSubAccounts, subaccounts, subAccountsErrorMessage] = useFetchBandwidthSubAccounts();

    const [selectedSubAccountId, setSelectedSubAccountId] = useState<string | null>(null);

    // Parse query string
    const params = parse(location.search);

    // Refresh list if current form settings don't match the query string
    var queryForm = formFromQuery(params);

    if (!compareForm(form, queryForm)) {
        setForm(queryForm);
    }

    // Update navigation
    useNav('bandwidth', 'sub-accounts');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingAccounts || isFetchingSubAccounts,
        accountsErrorMessage || subAccountsErrorMessage
    );

    // Fetch parent accounts on load
    useEffect(() => {
        fetchAccounts();
    }, []);

    // Fetch sub-accounts when account is changed
    useEffect(() => {
        if (selectedSubAccountId != form.accountId) {
            if (form.accountId) {
                fetchSubAccounts(form.accountId);
            }

            setSelectedSubAccountId(form.accountId);
        }
    }, [form]);

    const handleAccountUpdate = (accountId: string) => {
        // Reset form except for the account field
        history.push('?' + queryString.stringify({
            accountId,
            search: '',
            page: 1,
            limit: queryForm.limit,
        }));
    };

    // Order the sub-accounts by name then filter
    const filtredSubAccounts = useMemo(() => {
        const { search } = form;

        return (subaccounts || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(sa => {
                return (sa.description || '').toLowerCase().includes(search.toLowerCase())
                    || sa.name.toLowerCase().includes(search.toLowerCase())
                    || sa.id.includes(search);
            })
    }, [accounts, subaccounts, form]);

    // Pagination filtered sub accounts
    const pagedSubAccounts = useMemo(() => {
        const { page, limit } = form;

        return (filtredSubAccounts || [])
            .slice((page - 1) * limit, page * limit);
    }, [accounts, subaccounts, form]);

    // Response to changes int the search form
    const handleSubmitSearch = (search: string, limit: number) => {
        history.push('?' + queryString.stringify({
            accountId: queryForm.accountId,
            search,
            page: 1,
            limit,
        }));
    }

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth" subtext="Sub-Accounts" />

            {!isFetchingAccounts && accounts !== null &&
                <Paper className={classes.paper}>
                    <AccountForm
                        accounts={accounts}
                        selectedAccountId={form.accountId}
                        onSelect={(accountId: string) => handleAccountUpdate(accountId) } />
                </Paper>
            }

            <Paper>
                {form.accountId && <>
                    {subaccounts !== null
                        && subaccounts.length === 0
                        && <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">No sub-accounts found.</Typography>
                        </div>
                    }

                    {subaccounts !== null
                        && subaccounts.length > 0
                        && <>
                            <SubAccountSearchBar
                                search={form.search}
                                limit={form.limit}
                                onSubmitSearch={handleSubmitSearch}
                            />

                            <SubAccountTable accountId={form.accountId} items={pagedSubAccounts} />

                            <Pagination
                                totalItems={filtredSubAccounts.length}
                                pageSize={form.limit}
                                page={form.page}
                                disabled={isFetchingSubAccounts}
                                additionalParams={{
                                    accountId: form.accountId,
                                    limit: form.limit
                                }}
                                onChange={() => null} />
                        </>
                    }
                </>}
            </Paper>
        </div>
    );
};

export default SubAccountIndexPage;