import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { productLabel } from '../helpers';
import { dubberDubPointViewUrl } from '../urls';
import { DubPoint } from './api';

interface Props {
    groupId: string;
    accountId: string;
    dubPoints: DubPoint[];
    size?: 'medium' | 'small';
}

export default function DubPointTable(props: Props) {
    const { groupId, accountId, dubPoints, size } = props;

    return (
        <Table size={size}>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Group ID</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(dubPoints || []).map(dp => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberDubPointViewUrl(groupId, accountId, dp.id)} {...props} />);

                    return <TableRow key={dp.id}>
                        <TableCell component="th" scope="row">
                            <Link component={LinkComponent}>{dp.id}</Link>
                        </TableCell>
                        <TableCell>{dp.externalGroup}</TableCell>
                        <TableCell>{dp.externalIdentifier}</TableCell>
                        <TableCell>{productLabel(dp.product)}</TableCell>
                        <TableCell>{dp.status}</TableCell>
                    </TableRow>;
                }
                )}
            </TableBody>
        </Table>
    );
}