import * as React from 'react';
import ServicesBar from './ServicesBar';
import { Typography } from '@mui/material';
import ServiceGrid from './ServiceGrid';
import { useFetchAssignedServices } from '../../hooks';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../../../../theme';

interface Props {
    organizationId: number;
    showAddButton: boolean;
}

const useStyles = makeStyles(() => ({
    section: {
        marginBottom: theme.spacing(3)
    }
}));

const ServicesTabContent = (props: Props) => {
    const classes = useStyles();
    const { organizationId, showAddButton } = props;
    const [isFetching, services, error] = useFetchAssignedServices(organizationId);

    const [activeServices, inactiveServices] = useMemo(() => {
        if (services == null) {
            return [null, null];
        }

        return [
            services.filter(s => s.isActive),
            services.filter(s => !s.isActive)
        ];
    }, [services]);

    return (
        <>
            <div className={classes.section}>
                <ServicesBar
                    organizationId={organizationId}
                    heading="Active Services"
                    showAddButton={showAddButton}
                />

                {activeServices != null && activeServices.length == 0
                    && <Typography>Organization has no active services assigned to it.</Typography>
                }

                {activeServices != null && activeServices.length > 0
                    && <ServiceGrid organizationId={organizationId} assignedServices={activeServices} />
                }
            </div>

            {inactiveServices != null && inactiveServices.length > 0 &&
                <div className={classes.section}>
                    <ServicesBar
                        organizationId={organizationId}
                        heading="Cancelled Services"
                        showAddButton={false}
                    />

                    <ServiceGrid organizationId={organizationId} assignedServices={inactiveServices} />
                </div>
            }
        </>
    );
};

export default ServicesTabContent;