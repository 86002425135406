import {
    Actions,
    APP_WINDOW_ADD_NOTIFICATION,
    APP_WINDOW_HIDE_PROGRESS,
    APP_WINDOW_REMOVE_NOTIFICATION,
    APP_WINDOW_SET_ACTIVE_NAV,
    APP_WINDOW_SHOW_PROGRESS
} from './actions';
import { AppWindowState, INITIAL_STATE } from './store';

/**
 * Reducer for App Window state
 * @param state
 * @param action
 */
export default function reducer(state: AppWindowState = INITIAL_STATE, action: Actions): AppWindowState {
    switch (action.type) {
        case APP_WINDOW_SHOW_PROGRESS:
            state = {
                ...state,
                showProgressBar: true
            };
            break;

        case APP_WINDOW_HIDE_PROGRESS:
            state = {
                ...state,
                showProgressBar: false
            };
            break;

        case APP_WINDOW_ADD_NOTIFICATION:
            state = {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.payload.key,
                        message: action.payload.message,
                        type: action.payload.type
                    }
                ]
            };
            break;
        case APP_WINDOW_REMOVE_NOTIFICATION:
            state = {
                ...state,
                notifications:
                    state.notifications.filter(notification => notification.key !== action.payload.key)
            };
            break;

        case APP_WINDOW_SET_ACTIVE_NAV:
            state = {
                ...state,
                activeNav: action.payload.nav,
                activeSubNav: action.payload.subNav,
            };
            break;
    }

    return state;
}