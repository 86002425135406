import { useEffect, useState } from 'react';
import { BadRequestError, PagedResponse, ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { loginUrl } from '../../auth/urls';
import { Platform } from '../api';
import { addNumbers, fetchNumberCounts, fetchNumbers, Number, NumberCount, removeNumbers } from './api';

type FetchHook<T> = [
    boolean,
    T | null,
    string | null
];

type FetchHookWithCall<C, T> = [
    C,
    boolean,
    T | null,
    string | null
];

/**
 * Hook for retrieving Hosted PBX number counts
 */
export const useFetchNumberCounts = (platform: Platform): FetchHook<NumberCount[]> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [counts, setCounts] = useState<NumberCount[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchNumberCounts(api, platform);
                if (!didCancel) {
                    setCounts(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError('Unable to retrieve Hosted PBX numbers.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [platform]);

    return [
        isFetching,
        counts,
        error
    ];
};

/**
 * Hook for retrieving Hosted PBX numbers for a group
 */

type FetchNumbersCall = (platform: Platform, groupId: string, digits?: string, isAssigned?: boolean, page?: number, limit?: number) => void;

export const useFetchNumbers = (): FetchHookWithCall<FetchNumbersCall, PagedResponse<Number>> => {
    const api = useApi();

    // Request parameters
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);
    const [groupId, setGroupId] = useState<string| undefined>(undefined);
    const [digits, setDigits] = useState<string | undefined>(undefined);
    const [isAssigned, setIsAssigned] = useState<boolean | undefined>(undefined);
    const [page, setPage] = useState<number | undefined>(undefined);
    const [limit, setLimit] = useState<number | undefined>(undefined);

    // Stores the date of the last fetch request. Allows for re-fetching numbers using the same criteria as the previous fetch.
    const [requestDate, setRequestDate] = useState<Date>(new Date());

    // Response
    const [isFetching, setIsFetching] = useState(false);
    const [numbers, setNumbers] = useState<PagedResponse<Number> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchNumbersCall = (platform: Platform, groupId: string, digits?: string, isAssigned?: boolean, page?: number, limit?: number) => {
        setPlatform(platform);
        setGroupId(groupId);
        setDigits(digits);
        setIsAssigned(isAssigned);
        setPage(page);
        setLimit(limit);

        setRequestDate(new Date());
    };

    useEffect(() => {
        let didCancel = false;

        (async () => {
            // Only fetch if groupId is specified
            if (groupId && platform) {
                setIsFetching(true);

                try {
                    const response = await fetchNumbers(api, platform, groupId, digits, isAssigned, page, limit);
                    if (!didCancel) {
                        setNumbers(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setError('Unable to retrieve Hosted PBX numbers.');
                        }
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [platform, groupId, digits, isAssigned, page, limit, requestDate]);

    return [
        fetch,
        isFetching,
        numbers,
        error
    ];
};

/*
 * Hook for adding numbers to a group
 */

type AddNumbersSubmit = (platform: Platform, groupId: string, numbers: string[]) => void;
type AddNumbersReset = () => void;

export const useAddNumbers = (onComplete: () => void): [AddNumbersSubmit, boolean, string | null, string | null, AddNumbersReset] => {
    const api = useApi();

    // Request parameters
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);
    const [groupId, setGroupId] = useState<string | undefined>(undefined);
    const [numbers, setNumbers] = useState<string[] | undefined>(undefined);
    const [requestDate, setRequestDate] = useState<Date>(new Date());

    // Response parameters
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    // Expose a submit method to trigger the post
    const submit = (platform: Platform, groupId: string, numbers: string[]) => {
        setPlatform(platform);
        setGroupId(groupId);
        setNumbers(numbers);

        setRequestDate(new Date());
    };

    const reset = () => {
        setPlatform(undefined);
        setGroupId(undefined);
        setNumbers(undefined);
        setIsSubmitting(false);
        setValidationMessage(null);
        setError(null);
    }

    useEffect(() => {
        let didCancel = false;

        (async () => {
            if (platform && groupId && numbers && numbers.length > 0) {
                setIsSubmitting(true);

                try {
                    await addNumbers(api, platform, groupId, numbers);

                    if (!didCancel) {
                        setIsSubmitting(false);

                        onComplete();
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // Validation messages may be either in a "message" property or under a "fields" property 
                            if (e.fields && e.fields.length > 0) {
                                setValidationMessage(e.fields[0].message);
                            } else {
                                setValidationMessage(e.message);
                            }
                            setIsSubmitting(false);
                        } else {
                            setIsSubmitting(false);

                            if (e instanceof Error) {
                                setError(e.message);
                            }
                        }
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [platform, groupId, numbers, requestDate]);

    return [
        submit,
        isSubmitting,
        validationMessage,
        error,
        reset
    ];
};

/*
 * Hook for removing numbers from a group
 */

type RemoveNumbersSubmit = (platform: Platform, groupId: string, numbers: string[]) => void;
type RemoveNumbersReset = () => void;

export const useRemoveNumbers = (onComplete: () => void): [RemoveNumbersSubmit, boolean, string | null, string | null, RemoveNumbersReset] => {
    const api = useApi();

    // Request parameters
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);
    const [groupId, setGroupId] = useState<string | undefined>(undefined);
    const [numbers, setNumbers] = useState<string[] | undefined>(undefined);
    const [requestDate, setRequestDate] = useState<Date>(new Date());

    // Response parameters
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    // Expose a submit method to trigger the delete
    const submit = (platform: Platform, groupId: string, numbers: string[]) => {
        setPlatform(platform);
        setGroupId(groupId);
        setNumbers(numbers);
        setValidationMessage(null);
        setError(null);
        setRequestDate(new Date());
    };

    const reset = () => {
        setPlatform(undefined);
        setGroupId(undefined);
        setNumbers(undefined);
        setIsSubmitting(false);
        setValidationMessage(null);
        setError(null);
    }

    useEffect(() => {
        let didCancel = false;

        (async () => {
            if (platform && groupId && numbers && numbers.length > 0) {
                setIsSubmitting(true);
                
                try {
                    await removeNumbers(api, platform, groupId, numbers);

                    if (!didCancel) {
                        setIsSubmitting(false);

                        onComplete();
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else if (e instanceof BadRequestError) {
                            // Validation messages may be either in a "message" property or under a "fields" property 
                            if (e.fields && e.fields.length > 0) {
                                setValidationMessage(e.fields[0].message);
                            } else {
                                setValidationMessage(e.message);
                            }
                            setIsSubmitting(false);
                        } else {
                            setIsSubmitting(false);

                            if (e instanceof Error) {
                                setError(e.message);
                            }
                        }
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [platform, groupId, numbers, requestDate]);

    return [
        submit,
        isSubmitting,
        validationMessage,
        error,
        reset
    ];
};