import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { Account } from '../api';
import { useFetchDubberDubPoints, useFetchDubberUsers } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberDubPointCreateUrl, dubberDubPointViewUrl, dubberUserViewUrl } from '../urls';
import moment = require('moment');
import { productLabel } from '../helpers';
import SearchBar, { DubPointStatusOptions } from './SearchBar';

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    headingContainer: {
        display: 'flex'
    },
    h4: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));

interface Props {
    groupId: string;
    account: Account;
    onLoadStateChange: (loading: boolean) => void;
}

const DubPointIndexTabContents = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { onLoadStateChange, account, groupId } = props;
    const session = useSession();
    const queryString = parse(location.search);
    const page = queryString['page'] === undefined ? 1 : parseInt(String(queryString['page']));
    const pageSize = queryString['limit'] === undefined ? DEFAULT_PAGE_SIZE : parseInt(String(queryString['limit']));
    const search = queryString['search'] === undefined ? '' : String(queryString['search']);
    const status = queryString['status'] === undefined ? 'Active' : String(queryString['status']) as DubPointStatusOptions;

    const [fetch, isFetching, dubPoints, fetchError] = useFetchDubberDubPoints();
    const [fetchUsers, isFetchingUsers, users, fetchUsersError] = useFetchDubberUsers();

    useEffect(() => {
        fetch(groupId, account.id);
        fetchUsers(groupId, account.id);
    }, [account])

    // Trigger progress bar on parent container when loading
    useEffect(() => {
        onLoadStateChange(isFetching || isFetchingUsers);
    }, [isFetching, isFetchingUsers]);

    // Display error notification if fetch fails
    useEffect(() => {
        if (fetchError !== null) {
            dispatch(appWindowAddNotification(fetchError, 'error'));
        }

        if (fetchUsersError !== null) {
            dispatch(appWindowAddNotification(fetchUsersError, 'error'));
        }
    }, [fetchError, fetchUsersError]);

    const handleSubmitSearch = (search: string, status: DubPointStatusOptions, limit: number) => {
        var query: Record<string, string> = {
            limit: String(limit),
            search,
            status,
            page: "1"
        };

        const params = new URLSearchParams(query);

        history.push('?' + params.toString());
    };

    const filteredDubPoints = (dubPoints || []).filter(d => {
        const searchLowered = search.toLowerCase().trim();

        if (status !== 'all' && d.status !== status) {
            return false;
        }

        const user = (users || []).find(u => u.id === d.user) || null;

        const userName = d.user
            ? ((user !== null) ? (user.firstName + " " + user.lastName) : d.user).toLowerCase()
            : '';

        if (searchLowered !== '') {
            return d.id.toLowerCase().indexOf(searchLowered) > -1
                || (d.user || '').toLowerCase().indexOf(searchLowered) > -1
                || userName.toLowerCase().indexOf(searchLowered) > -1
                || (d.externalGroup || '').toLowerCase().indexOf(searchLowered) > -1
                || (d.externalIdentifier || '').toLowerCase().indexOf(searchLowered) > -1;
        }

        return true;
    });

    const dubPointsPage = useMemo(() => {
        if (!dubPoints) {
            return null;
        }

        return filteredDubPoints.slice((page - 1) * pageSize, page * pageSize);
    }, [page, pageSize, filteredDubPoints]);

    return (
        <>
            {dubPoints !== null && users !== null &&
                <>
                    <SearchBar
                        search={search}
                        limit={pageSize}
                        status={status}
                        onSubmitSearch={handleSubmitSearch}
                        showAddButton={authorizedFor(DubberPolicies.CanManageDubPoints, session.roles)}
                        onAddNew={() => history.push(dubberDubPointCreateUrl(groupId, account.id))}
                    />

                    {dubPoints.length === 0 &&
                        <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">
                                No dub points found.
                            </Typography>
                        </div>}

                    {dubPoints.length > 0 &&
                        <>
                            {filteredDubPoints.length === 0
                                ? <div className={classes.noResultsContainer}>
                                    <Typography color="textSecondary" align="center">
                                        No dub points match search.
                                    </Typography>
                                </div>
                                : <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell>Group ID</TableCell>
                                                <TableCell>User ID</TableCell>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(dubPointsPage || []).map(dp => {
                                                const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberDubPointViewUrl(groupId, account.id, dp.id)} {...props} />);
                                                const UserLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberUserViewUrl(groupId, account.id, dp.user)} {...props} />);

                                                const user = users.find(u => u.id === dp.user) || null;

                                                const userName = dp.user
                                                    ? ((user !== null) ? (user.firstName + " " + user.lastName) : dp.user)
                                                    : 'None';

                                                return <TableRow key={dp.id}>
                                                    <TableCell component="th" scope="row">
                                                        <Link component={LinkComponent}>{dp.id}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {user
                                                            ? <Link component={UserLinkComponent}>{userName}</Link>
                                                            : userName
                                                        }
                                                    </TableCell>
                                                    <TableCell>{dp.externalGroup}</TableCell>
                                                    <TableCell>{dp.externalIdentifier}</TableCell>
                                                    <TableCell>{productLabel(dp.product)}</TableCell>
                                                    <TableCell>{dp.status}</TableCell>
                                                </TableRow>;
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                    <Pagination
                                        totalItems={filteredDubPoints.length}
                                        pageSize={pageSize}
                                        page={page}
                                        disabled={isFetching}
                                        additionalParams={{
                                            search,
                                            status,
                                            limit: pageSize
                                        }}
                                        onChange={() => null} />
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default DubPointIndexTabContents;