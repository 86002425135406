import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import { Fax2MailPolicies } from '../policies';
import { fax2mailAssignmentViewUrl } from '../urls';
import AssignmentForm, { FormValues } from './AssignmentForm';
import { useFetchFax2MailAssignment, useUpdateFax2MailAssignment } from './hooks';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * Fax2Mail assignment view page
 * @param props
 * @constructor
 */
const AssignmentUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);
    const [fetch, isFetchingAssignment, assignment, fetchAssignmentError] = useFetchFax2MailAssignment();
    const [update, isUpdating, isComplete, updateError, updateFieldErrors] = useUpdateFax2MailAssignment();

    const [formValues, setFormValues] = useState<FormValues>({});

    useNav('fax2mail', 'organizations');

    useProgressEffects(
        isFetchingAssignment || isFetchingOrganization || isUpdating,
        fetchAssignmentError || fetchOrganizationError
    );

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [assignmentId]);

    const handleCancel = () => {
        history.push(fax2mailAssignmentViewUrl(organizationId, assignmentId))
    };

    const handleSubmit = () => {
        update(organizationId, assignmentId, {});
    };

    useEffect(() => {
        if (isComplete) {
            dispatch(appWindowAddNotification('Service updated', 'success'));

            history.push(fax2mailAssignmentViewUrl(organizationId, assignmentId))
        }
    }, [isComplete]);

    return (
        <div className={classes.root}>
            <PageHeader text="Fax2Mail"
                subtext={organization !== null ? organization.name : undefined}>
            </PageHeader>

            {organization !== null
                && assignment !== null
                && <>
                    <Paper className={classes.paper}>
                        <AssignmentForm
                            organization={organization}
                            assignmentId={assignment.id}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                        fieldErrorMessages={updateFieldErrors} />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(AssignmentUpdatePage, Fax2MailPolicies.CanManageAssignments);