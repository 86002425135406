import { Search as SearchIcon } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AppBar, Button, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Toolbar } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import theme from '../../../theme';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    block: {
        display: 'block',
    }
}));

interface Props {
    billingPeriod: string;
    limit?: number;
    onSubmitSearch: (billingPeriod: string, limit?: number) => void;
}

/**
 * Displays the top portion of the Statements table, which includes a search bar
 */
export default function SearchBar(props: Props) {
    const classes = useStyles();
    const { onSubmitSearch, billingPeriod, limit } = props;

    // The value of the billingPeriod & limit fields are tracked internally but defaults to what's in the query string
    const [billingPeriodValue, setBillingPeriodValue] = useState(billingPeriod || '');
    const [limitValue, setLimitValue] = useState(limit || 20);

    const submit = () => onSubmitSearch(billingPeriodValue, limitValue);

    function handleKeypress(evt: React.KeyboardEvent) {
        if (evt.key === 'Enter') {
            submit();
        }
    }

    return (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <SearchIcon className={classes.block} color="inherit" />
                    </Grid>

                    <Grid item xs>
                        <MobileDatePicker
                            label="Billing Period"
                            views={["year", "month"]}
                            value={moment(billingPeriodValue).utc()}
                            InputProps={{
                                disableUnderline: true
                            }}
                            renderInput={props => <TextField {...props} variant="standard" onKeyPress={handleKeypress} />}
                            onChange={evt => {
                                setBillingPeriodValue(evt ? evt.format('YYYY-MM-01') : '')
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="per-page">Per Page</InputLabel>
                            <Select
                                value={limitValue}
                                displayEmpty={true}
                                disableUnderline
                                input={<Input name="per-page" id="per-page" />}
                                onKeyPress={handleKeypress}
                                onChange={evt => setLimitValue(parseInt(String(evt.target.value)))}>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" onClick={submit}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}