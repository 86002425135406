import { ApiCaller } from '../../../../api';
import { AddressBase } from '../../api';
import { OrderBase, OrderHistory, OrderNote } from '../api';

export interface E911Order extends OrderBase {
    type: 'E911';
    lastModifiedDate: string;
    action: 'Update' | 'Remove';
    callerName: string | null;
    numbers: string[];
    address: E911Address | null;
    errors: E911Error[];
    history: OrderHistory[];
    notes: OrderNote[];
}

export interface E911Address extends AddressBase {
    county: string;
    latitude: string | null;
    longitude: string | null;
}

export interface E911Error {
    code: string;
    description: string;
    numbers: string[];
}

export interface E911OrderForm {
    numbers: string[];
    callerName: string;
    address: E911AddressForm;
}

export interface E911AddressForm extends AddressBase {
}

/**
 * GET /bandwidth/{accountId}/orders/e911s
 * 
 * Get all E911 Orders for an account.
 * A number can be specified to get the orders that include that number
 * 
 * @param api
 * @param accountId
 * @param number
 */
export const fetchBandwidthE911Orders = async (api: ApiCaller, accountId: string, number?: string): Promise<E911Order[]> => {
    return await api<E911Order[]>(`bandwidth/${accountId}/orders/e911s`, 'GET', {
        number
    });
};

/**
 * GET /bandwidth/{accountId}/orders/e911s/{orderId}
 * 
 * Get a specific E911 Order
 * 
 * @param api
 * @param accountId
 * @param orderId
 */
export const fetchBandwidthE911Order = async (api: ApiCaller, accountId: string, orderId: string): Promise<E911Order> => {
    return await api<E911Order>(`bandwidth/${accountId}/orders/e911s/${orderId}`, 'GET');
};

/**
 * POST /bandwidth/{accountId}/orders/e911s
 * 
 * Create a new E911 Order
 * 
 * @param api
 * @param accountId
 * @param form
 */
export const createBandwidthE911Order = async (api: ApiCaller, accountId: string, form: E911OrderForm): Promise<E911Order> => {
    return await api<E911Order>(`bandwidth/${accountId}/orders/e911s`, 'POST', {
        ...form
    });
};
