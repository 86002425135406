import { TablePagination } from '@mui/material';
import { stringify } from 'query-string';
import * as React from 'react';
import history from '../history';

interface Props {
    totalItems: number;
    pageSize: number,
    page: number;
    disabled: boolean;
    onChange: (page: number) => void;
    setParams?: boolean;
    additionalParams?: { [key: string]: string | number | undefined }
}

export default function Pagination(props: Props) {
    const { totalItems, pageSize, page, onChange, disabled, additionalParams } = props;

    const setParams = props.setParams ?? true;

    const handleButton = (page: number) => {
        var params = {
            page
        };

        if (setParams) {
            params = {
                page,
                ...additionalParams
            }
        }

        history.push(`?${stringify(params)}`);

        onChange(page);
    };

    
    return (
        <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            //backIconButtonProps={backButtonProps}
            //nextIconButtonProps={nextButtonProps}
            //onPageChange={(event, page) => onChange(page + 1)}
            onPageChange={(event, page) => handleButton(page + 1)}
        />
    );
}