import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organization/{organizationId}/services/sip-trunking
 *
 * Get all SIP Trunking assignments for an organization
 */

export interface Assignment {
    id: number;
    startedAt: string;
    isActive: boolean;
    cancelledAt: string | null;
    bandwidthAccount: string;
    bandwidthSubAccount: string;
}

export const fetchSipTrunkingAssignments = async (api: ApiCaller, organizationId: number): Promise<Assignment[]> => {
    return await api<Assignment[]>(`organizations/${organizationId}/services/sip-trunking`, 'GET');
};

/*
 * GET /api/organization/{organizationId}/services/sip-trunking/{assignmentId}
 *
 * Get SIP Trunking assignment
 */


export const fetchSipTrunkingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/sip-trunking/${assignmentId}`, 'GET');
};

export interface CreateAssignment {
    useExisting: boolean;
    bandwidthAccount: string;
    bandwidthSubAccount?: string;
    accountName?: string;
    houseNumber?: string;
    streetName?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
};

/*
 * POST /api/organization/{organizationId}/services/sip-trunking
 *
 * Assign SIP Trunking service to an organization
 */

export const createSipTrunkingAssignment = async (api: ApiCaller, organizationId: number, form: CreateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/sip-trunking/`, 'POST', {
        ...form
    });
};

export interface UpdateAssignment {
    bandwidthAccount: string;
    bandwidthSubAccount: string;
};

/*
 * PUT /api/organization/{organizationId}/services/sip-trunking/{assignmentId}
 *
 * Update SIP Trunking service assignment
 */

export const updateSipTrunkingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/sip-trunking/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * DELETE /api/organization/{organizationId}/services/sip-trunking/{assignmentId}
 *
 * Cancel a SIP Trunking service assignment
 */

export const cancelSipTrunkingAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/sip-trunking/${assignmentId}`, 'DELETE');
};
