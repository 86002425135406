import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import history from '../../../../history';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { CreateBandwidthGeneralTnOptionOrderForm } from '../../api';
import { useFetchBandwidthNumber } from '../../hooks';
import { useCreateBandwidthGeneralTnOptionOrder } from '../../orders/hooks';
import { bandwidthNumberViewUrl } from '../../urls';
import { formatUsNumber } from '../helpers';
import GeneralForm, { FormData } from './GeneralForm';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useDispatch } from 'react-redux';
import { withPolicyRestriction } from '../../../auth/policies';
import { BandwidthPolicies } from '../../policies';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for updating general settings on a number
const GeneralUpdatePage = (props: Props) => {
    const number = String(props.match.params['number']);
    const dispatch = useDispatch();

    const classes = useStyles();
    const [fetch, isFetching, numberDetails, fetchErrorMessage] = useFetchBandwidthNumber();
    const [formValues, setFormValues] = useState<FormData>({
        failoverUri: '',
        callingNameDisplay: 'systemDefault',
        nnid: ''
    });

    const [originalFormValues, setOriginalFormValues] = useState<FormData>(formValues);

    const [update, isUpdating, completedOrder, updateError, updateFieldErrors] = useCreateBandwidthGeneralTnOptionOrder();

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        fetchErrorMessage
    );

    // Fetch number details on load
    useEffect(() => {
        fetch(number);
    }, [number]);

    // Update form when number was loaded
    useEffect(() => {
        if (numberDetails) {
            const callingNameDisplayed = numberDetails?.tnOptions?.callingNameDisplayed || null;

            const filledFormValues: FormData = {
                failoverUri: numberDetails?.tnOptions?.failoverUri || '',
                callingNameDisplay: callingNameDisplayed === null ? 'systemDefault' : (callingNameDisplayed ? 'on' : 'off'),
                nnid: String(numberDetails?.tnOptions?.nnid || '')
            };

            setFormValues(filledFormValues);
            setOriginalFormValues(filledFormValues);
        }
    }, [numberDetails]);

    // Handle field updates
    const handleFieldChange = (fieldName: keyof FormData, value: string) => {
        let newValues = Object.assign({}, formValues);

        switch (fieldName) {
            case 'callingNameDisplay':
                if (value === 'systemDefault' || value === 'on' || value === 'off') {
                    newValues[fieldName] = value;
                }
                break;
            case 'failoverUri':
                newValues[fieldName] = value;
                break;
            case 'nnid':
                // Only allow numeric values in nnid
                newValues[fieldName] = value.replace(/[^0-9]/, '');
                break;
        }

        setFormValues(newValues);
    };

    // Only allow the Update button to be active if the form has changed
    const formChanged = useMemo(() => {
        return formValues.callingNameDisplay !== originalFormValues.callingNameDisplay
            || formValues.failoverUri !== originalFormValues.failoverUri
            || formValues.nnid !== originalFormValues.nnid
    }, [formValues]);

    // Cancel button returns to number details
    const handleCancel = useCallback(() => {
        history.push(bandwidthNumberViewUrl(number));
    }, [number]);

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Handle submit form
    const handleSubmit = useCallback(() => {
        if (numberDetails) {
            // We only set the values that have changed.
            // This is the behavior of TN Orders, NULL signifies no change
            let submission: CreateBandwidthGeneralTnOptionOrderForm = {
                numbers: [number],
                failoverUri: null,
                callingNameDisplay: null,
                nnid: null
            };

            if (formValues.failoverUri != originalFormValues.failoverUri) {
                submission.failoverUri = formValues.failoverUri.length === 0
                    ? 'systemDefault'
                    : formValues.failoverUri;
            }

            if (formValues.callingNameDisplay != originalFormValues.callingNameDisplay) {
                submission.callingNameDisplay = formValues.callingNameDisplay;
            }

            if (formValues.nnid != originalFormValues.nnid) {
                submission.nnid = formValues.nnid.length === 0
                    ? 'systemDefault'
                    : formValues.nnid;
            }

            update(numberDetails.accountId, submission);
        }
    }, [formValues]);

    // Once an order is created, display a nice message and redirect back to the number details
    useEffect(() => {
        if (completedOrder) {
            dispatch(appWindowAddNotification(`Order ${completedOrder.id} created`, 'success'));
            history.push(bandwidthNumberViewUrl(number));
        }
    }, [completedOrder]);

   
    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Numbers" subtext={formatUsNumber(number)} />

            {numberDetails !== null
                && <>
                    <Paper className={classes.paper}>
                        <GeneralForm
                            formValues={formValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                            fieldErrorMessages={updateFieldErrors}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                        disabled={isUpdating || !formChanged}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(GeneralUpdatePage, BandwidthPolicies.CanManageNumberTnOptions);