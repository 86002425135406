import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import { bandwidthSubAccountViewUrl } from '../../bandwidth/urls';
import { dubberAccountViewUrl } from '../../dubber/urls';
import { Assignment } from './api';
import moment = require('moment');
import { webexIdToUuid } from '../helpers';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    assignment: Assignment;
}

/**
 * Displays a box showing basic information about a Webex assignment
 * @param props
 * @constructor
 */
const AssignmentSummary = (props: Props) => {
    const classes = useStyles();
    const { assignment } = props;

    const BandwidthLinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(assignment.bandwidthAccount, assignment.bandwidthSubAccount)} {...props} />);
    const DubberLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(assignment.dubberGroup, assignment.dubberAccount)} {...props} />)

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Organization UUID</Typography>
                    <Typography>
                        {webexIdToUuid(assignment.webexOrgId)}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Bandwidth Sub-Account</Typography>
                    <Typography>
                        <Link component={BandwidthLinkComponent}>{assignment.bandwidthSubAccount}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Dubber Account</Typography>
                    <Typography>
                        <Link component={DubberLinkComponent}>{assignment.dubberAccount}</Link>
                    </Typography>
                </Grid>


                <Grid item>
                    <Typography variant="caption" color="textSecondary">Service Assigned</Typography>
                    <Typography>
                        {moment(assignment.startedAt).format('MM/DD/YYYY')}
                    </Typography>
                </Grid>

                {!assignment.isActive &&
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">Service Cancelled</Typography>
                        <Typography>
                            {moment(assignment.cancelledAt).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Paper>);
};

export default AssignmentSummary;