import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../theme';
import { RoleLabels } from '../administrators/api';
import { UserProfile } from './api';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    }
}));

interface Props {
    profile: UserProfile;
}

/**
 * Displays MFA details about the current user
 * @param props
 * @constructor
 */
const MfaSummary = (props: Props) => {
    const classes = useStyles();
    const { profile } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Authenticator App</Typography>
                    <Typography>{profile.authenticatorConfigured ? 'Configured' : 'Not Configured'}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MfaSummary;