import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssignmentEntry, RedSkyReportDevice, RedSkyReportLocation } from './api';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, ListSubheader, MenuItem, Paper, Select, SelectProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { webexIdToUuid } from '../helpers';
import { useAssignRedSkyMacs } from './hooks';
import AssignmentDialog from './AssignmentDialog';
import { Search } from '@mui/icons-material';

interface Props {
    assignmentId: number;
    devices: RedSkyReportDevice[];
    webexLocations: RedSkyReportLocation[];
    redSkyLocations: RedSkyReportLocation[];
    onWorking: (working: boolean) => void;
    onError: (errorMsg: string) => void;
    refresh: () => void;
}

const useStyles = makeStyles(() => ({
    paper: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subheading: {
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    buttonBar: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'right'
    },
    menuItem: {
        display: 'block'
    }
}));

interface WebexLocationsWithDevices {
    location: RedSkyReportLocation;
    devices: RedSkyReportDevice[];
}

interface Change {
    macAddress: string;
    redSkyLocationId: string;
}

interface AssignmentSubmission {
    ts: Date;
    changes: AssignmentEntry[]
}

export default function DevicesTab(props: Props) {
    const classes = useStyles();
    const { assignmentId, devices, webexLocations, redSkyLocations, onWorking, onError, refresh } = props;
    const [changes, setChanges] = useState<Change[]>([]);
    const [assign, isAssigning, assignmentResults, assignmentError, assignmentFieldErrors] = useAssignRedSkyMacs();
    const [onlyShowUnassigned, setOnlyShowUnassigned] = useState(false);

    const [assignmentSubmission, setAssignmentSubmission] = useState<AssignmentSubmission | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        onWorking(isAssigning);
    }, [isAssigning]);

    useEffect(() => {
        if (assignmentError) {
            onError(assignmentError);
        }
    }, [assignmentError]);

    const unassignedDevices = useMemo(() => {
        return devices.filter(d => d.emergencyLocation === null);
    }, [devices]);

    const displayedDevices = onlyShowUnassigned ? unassignedDevices : devices;

    // Group devices by location
    const webexLocationsWithDevices: WebexLocationsWithDevices[] = useMemo(() => {
        return webexLocations.map(l => {
            return {
                location: l,
                devices: displayedDevices.filter(d => webexIdToUuid(d.location.id) == webexIdToUuid(l.id)).sort((a, b) => a.mac.toLowerCase().localeCompare(b.mac.toLowerCase()))
            }
        })
            .filter(l => l.devices.length > 0)
            .sort((a, b) => a.location.name.toLowerCase().localeCompare(b.location.name.toLowerCase()))
    }, [displayedDevices, webexLocations]);

    const getValue = useCallback((macAddress: string) => {
        // Get default value
        const defaultVal = devices.find(d => d.mac == macAddress)?.emergencyLocation?.id;
        const changedVal = changes.find(c => c.macAddress == macAddress)?.redSkyLocationId;

        return (changedVal || defaultVal) || '';
    }, [devices, changes]);

    const setValue = useCallback((macAddress: string, redSkyLocationId: string) => {
        var copy = [...changes].filter(c => c.macAddress != macAddress);

        setChanges([
            ...copy,
            {
                macAddress,
                redSkyLocationId
            }
        ]);
    }, [changes]);

    const valueChanged = useCallback((macAddress: string) => {
        const defaultVal = devices.find(d => d.mac == macAddress)?.emergencyLocation?.id;
        const changedVal = changes.find(c => c.macAddress == macAddress)?.redSkyLocationId;

        return changedVal !== undefined && (defaultVal !== changedVal);
    }, [devices, changes]);

    const handleAssignmentButton = useCallback(() => {
        if (changes.length > 0) {
            setAssignmentSubmission({
                ts: new Date(),
                changes
            })
        }
    }, [assignmentId, changes]);

    // Refresh on close
    const handleDialogClose = () => {
        setDialogOpen(false);
        refresh();
    };

    useEffect(() => {
        if (assignmentSubmission) {
            assign(assignmentId, { changes });
            setDialogOpen(true);
        }
    }, [assignmentSubmission]);

    return (
        <>
            {devices.length > 0 && <>
                <Grid container>
                    <Grid item xs={9}>
                        <FormControlLabel
                            control={<Checkbox />}
                            value={onlyShowUnassigned}
                            onChange={() => setOnlyShowUnassigned(!onlyShowUnassigned)}
                            disabled={unassignedDevices.length === 0}
                            label={`Only show devices missing an emergency location (${unassignedDevices.length})`} />
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.buttonBar}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={changes.length === 0}
                                onClick={() => handleAssignmentButton()}>Apply Changes{changes.length > 0 && <> ({changes.length})</>}</Button>
                        </div>
                    </Grid>
                </Grid>

                {webexLocationsWithDevices.map(l => {
                    return (
                        <Paper key={l.location.id} className={classes.paper}>
                            <div className={classes.subheading}>
                                <Typography variant="h4">{l.location.name}</Typography>

                                <Typography variant="body2">
                                    {l.location.address1}<br />
                                    {l.location.address2 && <>{l.location.address2}<br /></>}
                                    {l.location.city}, {l.location.state} {l.location.postalCode}
                                </Typography>
                            </div>

                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="25%">MAC Address</TableCell>
                                        <TableCell width="25%">Device Type</TableCell>
                                        <TableCell width="25%">Owner</TableCell>
                                        <TableCell width="25%">Emergency Location</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {l.devices.map(d => {
                                        return (
                                            <TableRow key={d.mac} selected={valueChanged(d.mac)}>
                                                <TableCell>{d.mac}</TableCell>
                                                <TableCell>{d.type}</TableCell>
                                                <TableCell>{d.owner}</TableCell>
                                                <TableCell>
                                                    <FormControl variant="standard" size="small" sx={{ minWidth: 120 }}>
                                                        <LocationSelect
                                                            macAddress={d.mac}
                                                            locations={redSkyLocations}
                                                            value={getValue(d.mac)}
                                                            onChange={e => setValue(d.mac, e.target.value)}
                                                            renderValue={(id: string) => {
                                                                let location;

                                                                if (id) {
                                                                    location = redSkyLocations.find(l => l.id == id);
                                                                }

                                                                if (location !== undefined) {
                                                                    return <div>{location.name}</div>;
                                                                } else {
                                                                    return <div />;
                                                                }
                                                            }}/>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    );
                })
                }
            </>}

            <AssignmentDialog isOpen={dialogOpen} results={assignmentResults} onClose={handleDialogClose} />
        </>
    );
}

interface LocationSelectProps extends SelectProps<string> {
    macAddress: string;
    locations: RedSkyReportLocation[];
}

const LocationSelect = (props: LocationSelectProps) => {
    const { macAddress, locations } = props;

    const classes = useStyles();
    const [search, setSearch] = useState('');

    const filteredLocations = useMemo(() => {
        if ((search || '').length === 0) {
            return locations;
        }

        const s = search.toLocaleLowerCase();

        return locations.filter(l => {
            return l.id.toLocaleLowerCase().indexOf(s) > -1
                || l.address1.toLocaleLowerCase().indexOf(s) > -1
                || (l.address2 || '').toLocaleLowerCase().indexOf(s) > -1
                || l.name.toLocaleLowerCase().indexOf(s) > -1
                || l.city.toLocaleLowerCase().indexOf(s) > -1
                || l.state.toLocaleLowerCase().indexOf(s) > -1
                || l.postalCode.toLocaleLowerCase().indexOf(s) > -1;
        });
    }, [search, locations]);

    return <Select
        MenuProps={{ autoFocus: false }}
        onClose={() => setSearch('')}
        {...props}>
        <ListSubheader>
            <TextField
                size="small"
                autoFocus
                placeholder="Search locations"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                        e.stopPropagation();
                    }
                }}
            />
        </ListSubheader>

        {filteredLocations.map(l => {
            return (
                <MenuItem dense divider className={classes.menuItem} key={macAddress + ':' + l.id} value={l.id}>
                    <Typography component="div">{l.name}</Typography>
                    <Typography component="div" variant="body2">
                        {l.address1}<br />
                        {l.address2 && <>{l.address2}<br /></>}
                        {l.city}, {l.state} {l.postalCode}</Typography>
                </MenuItem>

            );
        })}
    </Select>;
};