import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment = require('moment');
import * as React from 'react';
import { OrderHistory } from '../api';
import { OrderNote } from './api';

interface Props {
    history: OrderHistory[];
    notes: OrderNote[];
}

/**
 * Table for displaying a list of status event changes & notes for an order
 * @param props
 */
const HistoryNotesTable = (props: Props) => {
    const { history, notes } = props;

    const combined = [...history, ...notes.map(n => ({
        date: n.date,
        author: n.userId,
        status: '',
        note: n.description
    }))];

    // Sort by date ASC
    combined.sort((a, b) => {
        return moment(b.date).diff(moment(a.date));
    });

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Note</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {combined.map(entry => {
                    const date = moment(entry.date).format('MM/DD/YYYY hh:mm A');

                    return (
                        <TableRow key={entry.date + entry.author}>
                            <TableCell>{date}</TableCell>
                            <TableCell>{entry.author}</TableCell>
                            <TableCell>{entry.status}</TableCell>
                            <TableCell>{entry.note}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default HistoryNotesTable;
