export const SERVICE_DIRECT_ROUTING = 'DirectRouting';
export const SERVICE_FAX2MAIL = 'Fax2Mail';
export const SERVICE_HOSTED_PBX = 'HostedPBX';
export const SERVICE_SIP_TRUNKING = 'SIPTrunking';
export const SERVICE_WEBEX = 'Webex';

export type Service =
    | typeof SERVICE_DIRECT_ROUTING
    | typeof SERVICE_FAX2MAIL
    | typeof SERVICE_HOSTED_PBX
    | typeof SERVICE_SIP_TRUNKING
    | typeof SERVICE_WEBEX;

export const serviceName = (service: Service) => {
    switch (service) {
        case SERVICE_DIRECT_ROUTING: return 'Direct Routing for Teams';
        case SERVICE_FAX2MAIL: return 'Fax2Mail';
        case SERVICE_HOSTED_PBX: return 'Hosted PBX';
        case SERVICE_SIP_TRUNKING: return 'SIP Trunking';
        case SERVICE_WEBEX: return 'Cloud PBX w/ Webex';
    }
};