import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useValidationHelpers } from '../../form-helpers';
import history from '../../history';
import theme from '../../theme';
import { dashboardUrl } from '../dashboard/urls';
import { authLoginAction } from './actions';
import { useCreateSession } from './hooks';
import { loginMfaUrl } from './urls';

const useStyles = makeStyles({
    formControl: {
        marginTop: theme.spacing(1),
        maginBottom: theme.spacing(1)
    }
});

const LoginPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [createSession, isCreatingSession, session, sessionError, sessionFieldErrors] = useCreateSession();

    // Handle form submission
    const handleSubmit = () => {
        createSession(email, password);
    };

    // Trigger submit if enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    const { isValid, ValidationMessage } = useValidationHelpers(sessionFieldErrors);

    const textColor = sessionError !== null ? 'error' : 'inherit';
    const text = sessionError !== null ? sessionError : 'Administration System';

    useEffect(() => {
        if (session) {
            dispatch(authLoginAction(session.token, session.expiresAt, session.status, session.roles ?? [], session.administratorId));

            // If fully authenticated, redirect to dashboard
            // Else redirect to MFA selection
            if (session.status === 'Authenticated') {
                history.push(dashboardUrl());
            } else {
                history.push(loginMfaUrl());
            }
        }
    }, [session]);

    return (
        <Dialog open={true} aria-labelledby="login">
            <DialogTitle id="login">Konica Minolta Unified Communications</DialogTitle>

            <DialogContent>
                <DialogContentText color={textColor}>{text}</DialogContentText>
                <FormControl className={classes.formControl} fullWidth required error={!isValid('email')}>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input
                        autoFocus
                        disabled={isCreatingSession}
                        onChange={evt => setEmail(evt.target.value)}
                        onKeyDown={handleKeyDown}
                        id="email"
                        type="email" />
                    <ValidationMessage field="email" />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth required error={!isValid('password')}>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        disabled={isCreatingSession}
                        onChange={evt => setPassword(evt.target.value)}
                        onKeyDown={handleKeyDown}
                        id="password"
                        type="password" />
                    <ValidationMessage field="password" />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} color="primary" disabled={isCreatingSession}>
                    Login
                </Button>
            </DialogActions>

            {isCreatingSession && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

export default LoginPage;