import { Box, LinearProgress, Paper, Tab, Tabs as UiTabs } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useState } from 'react';
import history from '../../../history';
import theme from '../../../theme';
import { useSession } from '../../auth/hooks';
import { Account } from '../api';
import DubPointIndexTabContents from '../dub-points/DubPointIndexTabContents';
import TeamIndexTabContents from '../teams/TeamIndexTabContents';
import { dubberDubPointIndexUrl, dubberTeamIndexUrl, dubberUserIndexUrl } from '../urls';
import UserIndexTabContents from '../users/UserIndexTabContents';

interface Props {
    groupId: string;
    account: Account;
    activeTab: Tab;
}

const useStyles = makeStyles(() => ({
    root: {
    },
    tabPaperContents: {
        padding: 0//,
        //paddingTop: theme.spacing(2),
        //paddingBottom: theme.spacing(2)
    }
}));

export type Tab = 'users' | 'teams' | 'dub-points';

const AccountViewTabs = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const { activeTab, groupId, account } = props;
    const [showLoadingBar, setShowLoadingBar] = useState(false);

    const handleTabChange = (_: React.ChangeEvent<{}>, value: Tab) => {
        switch (value) {
            case 'users':
                history.push(dubberUserIndexUrl(groupId, account.id));
                break;
            case 'teams':
                history.push(dubberTeamIndexUrl(groupId, account.id));
                break;
            case 'dub-points':
                history.push(dubberDubPointIndexUrl(groupId, account.id));
                break;
        }
    };

    const handleLoadStateChange = (loading: boolean) => {
        setShowLoadingBar(loading);
    }

    return (
        <Paper className={classes.root}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <UiTabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="users" label="Users" />
                    <Tab value="teams" label="Teams" />
                    <Tab value="dub-points" label="Dub Points" />
                </UiTabs>
            </Box>

            <div className={classes.tabPaperContents}>
                {activeTab === 'users' &&
                    <UserIndexTabContents
                        groupId={groupId}
                        account={account}
                        onLoadStateChange={handleLoadStateChange} />
                }

                {activeTab === 'teams' &&
                    <TeamIndexTabContents
                        groupId={groupId}
                        account={account}
                        onLoadStateChange={handleLoadStateChange} />
                }

                {activeTab === 'dub-points' &&
                    <DubPointIndexTabContents
                        groupId={groupId}
                        account={account}
                        onLoadStateChange={handleLoadStateChange} />
                }
            </div>

            {showLoadingBar && <LinearProgress />}
        </Paper>
    );
};

export default AccountViewTabs;