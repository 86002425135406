import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatUsNumber } from '../../numbers/helpers';
import { bandwidthNumberViewUrl } from '../../urls';
import { E911Error } from './api';

interface Props {
    isComplete: boolean;
    numbers: string[];
    errors: E911Error[];
}

/**
 * @param props
 */
const NumberTable = (props: Props) => {
    const { errors, isComplete, numbers } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {numbers.map(n => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthNumberViewUrl(n)} {...props} />);
                    const description = errors.find(e => e.numbers.indexOf(n) !== undefined)?.description || null;

                    return (
                        <TableRow key={n}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{formatUsNumber(n)}</Link>
                            </TableCell>
                            <TableCell>
                                {description && "Failed"}
                                {description === null && (isComplete ? 'Complete' : "Processing")}
                            </TableCell>
                            <TableCell>{description}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default NumberTable;
