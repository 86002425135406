import { LinearProgress, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { ErrorModal } from '../../hosted-pbx/bulk-uploads/ErrorModal';
import { WebexOrganizationForm } from '../components/WebexOrganizationForm';
import { useFetchAllWebexOrganizations } from '../hooks';
import { WebexPolicies } from '../policies';
import { useFetchBulkUploads, useFetchBulkUploadUserSheet } from './hooks';
import { UploadForm } from './UploadForm';
import { UploadTable } from './UploadTable';

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    ftpWarning: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

/**
 * Bulk Tool Upload index page
 */
const BulkUploadIndexPage = (props: Props) => {
    const classes = useStyles();

    const [fetchOrganizations, isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllWebexOrganizations();
    const [fetchUserSheet, isFetchingUserSheet, userSheetUrl, fetchUserSheetError] = useFetchBulkUploadUserSheet();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);


    // Modal for showing upload errors
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState('Error Messages');
    const [errorModalErrors, setErrorModalErrors] = useState<string[]>([]);

    const {
        fetch,
        isFetching: isFetchingUploads,
        uploads,
        error: fetchUploadsError,
    } = useFetchBulkUploads();

    useNav('webex', 'bulk-uploads');

    useProgressEffects(
        isFetchingOrganizations || isFetchingUserSheet,
        fetchOrganizationsError || fetchUploadsError || fetchUserSheetError
    );

    // When an upload is complete (successful or not), the Recent Uploads table is refreshed
    const refreshRecentUploads = () => {
        if (selectedOrganizationId != null && selectedAssignmentId != null) {
            fetch(selectedOrganizationId, selectedAssignmentId, 'uploadedAt', 'desc', 1);
        }
    };

    // Fetch orgs on load
    useEffect(() => {
        fetchOrganizations();
    }, [])

    useEffect(() => {
        // Refresh recent uploads when the organization is changed
        refreshRecentUploads();
    }, [selectedOrganizationId, selectedAssignmentId]);

    // Force download of user sheet once fetch finishes
    useEffect(() => {
        if (userSheetUrl) {
            window.open(userSheetUrl);
        }
    }, [userSheetUrl]);

    const showErrors = (errors: string[]) => {
        setErrorModalTitle('Error Messages');
        setErrorModalErrors(errors);
        setErrorModalOpen(true);
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Cloud PBX w/ Webex" subtext="Bulk Upload Tool" />

            {!isFetchingOrganizations && organizations !== null &&
                <Paper className={classes.paper}>
                    <WebexOrganizationForm
                        organizations={organizations}
                        selectedOrganizationId={selectedOrganizationId}
                        selectedAssignmentId={selectedAssignmentId}
                        onSelect={(orgId, asId) => {
                            setSelectedOrganizationId(orgId);
                            setSelectedAssignmentId(asId);
                        }
                        } />
                </Paper>
            }

            {selectedOrganizationId !== null
                && selectedAssignmentId !== null
                && <>
                    <Typography variant="h3" className={classes.subHeading}>Upload</Typography>

                    <Paper className={classes.paper}>
                        <Typography variant="body1"><Link href="/documents/cpbx-bulk-upload-1.0.0.xlsx" target="_blank">Latest template (1.0.0)</Link></Typography>

                        <UploadForm
                            organizationId={selectedOrganizationId}
                            assignmentId={selectedAssignmentId}
                            onComplete={() => refreshRecentUploads()}
                            showErrors={errors => showErrors(errors)}
                            downloadUserSheet={uploadId => fetchUserSheet(selectedOrganizationId, selectedAssignmentId, uploadId)}
                        />
                    </Paper>

                    <Typography variant="h3" className={classes.subHeading}>Recent Uploads</Typography>

                    {!isFetchingUploads && uploads !== null
                        ? (
                            uploads.length > 0
                                ?
                                <Paper className={classes.paper}>
                                    <UploadTable
                                        uploads={uploads}
                                        showErrors={errors => showErrors(errors)}
                                        downloadUserSheet={uploadId => fetchUserSheet(selectedOrganizationId, selectedAssignmentId, uploadId)}
                                    />
                                </Paper>
                                : <Typography>No recent uploads for this organization.</Typography>
                        )
                        : <LinearProgress variant="indeterminate" color="primary" />}
                </>
            }

            <ErrorModal title={errorModalTitle} open={errorModalOpen} errors={errorModalErrors} onClose={() => setErrorModalOpen(false)} />
        </div >
    );
};

export default withPolicyRestriction(BulkUploadIndexPage, WebexPolicies.CanUseBulkUpload);