import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { bandwidthSubAccountViewUrl } from '../urls';
import { SubAccountIndex } from './api';

interface Props {
    accountId: string;
    items: SubAccountIndex[]
}

const SubAccountTable = (props: Props) => {
    const { accountId, items } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Sub-Account</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell># of Locations</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(acc => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={bandwidthSubAccountViewUrl(accountId, acc.id)} {...props} />);

                    return (
                        <TableRow key={acc.id}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{acc.name}</Link>
                            </TableCell>
                            <TableCell>{acc.description}</TableCell>
                            <TableCell>{acc.locations}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default SubAccountTable;