export const ORGANIZATION_INDEX_URL = '/organizations';
export const ORGANIZATION_CREATE_URL = '/organizations/create';
export const ORGANIZATION_VIEW_URL = '/organizations/:id';
export const ORGANIZATION_UPDATE_URL = '/organizations/:id/update';
export const ORGANIZATION_ASSIGN_SERVICE_URL = '/organizations/:id/assign-service';

export const organizationIndexUrl = () => ORGANIZATION_INDEX_URL;

export const organizationCreateUrl = () => ORGANIZATION_CREATE_URL;

export const organizationViewUrl = (id: string | number) =>
    ORGANIZATION_VIEW_URL.replace(':id', String(id));

export const organizationUpdateUrl = (id: string | number) =>
    ORGANIZATION_UPDATE_URL.replace(':id', String(id));

export const organizationAssignServiceUrl = (id: string | number) =>
    ORGANIZATION_ASSIGN_SERVICE_URL.replace(':id', String(id));