import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import { useFetchWebexBandwidthSubAccounts } from '../bandwidth-subaccounts/hooks';
import { useFetchWebexDubberAccounts } from '../dubber-accounts/hooks';
import { useFetchWebexCustomers } from '../hooks';
import { WebexPolicies } from '../policies';
import { webexAssignmentViewUrl } from '../urls';
import { UpdateAssignment } from './api';
import { useFetchWebexAssignment, useUpdateWebexAssignment } from './hooks';
import UpdateAssignmentForm from './UpdateAssignmentForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * Webex assignment view page
 * @param props
 * @constructor
 */
const AssignmentUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);

    const [fetchAssignment, isFetchingAssignment, assignment, fetchAssignmentError] = useFetchWebexAssignment();

    const [fetchWebexCustomers, isFetchingWebexCustomers, webexCustomers, webexCustomersError] = useFetchWebexCustomers();
    const [fetchBandwidthSubAccounts, isFetchingBandwidthSubAccounts, bandwidthSubAccounts, bandwidthSubAccountsError] = useFetchWebexBandwidthSubAccounts();
    const [fetchDubberAccounts, isFetchingDubberAccounts, dubberAccounts, dubberAccountsError] = useFetchWebexDubberAccounts();

    const [update, isUpdating, isComplete, updateError, updateFieldErrors] = useUpdateWebexAssignment();

    const [formValues, setFormValues] = useState<UpdateAssignment>({
        webexCustomerId: '',
        bandwidthSubAccount: '',
        dubberAccount: ''
    });

    useNav('webex', 'organizations');

    useProgressEffects(
        isFetchingAssignment || isFetchingOrganization || isFetchingWebexCustomers || isFetchingBandwidthSubAccounts || isFetchingDubberAccounts || isUpdating,
        fetchAssignmentError || fetchOrganizationError || webexCustomersError || bandwidthSubAccountsError || dubberAccountsError
    );

    useEffect(() => {
        fetchAssignment(organizationId, assignmentId);
        fetchWebexCustomers();
        fetchBandwidthSubAccounts();
        fetchDubberAccounts();
    }, [assignmentId]);

    // Pre-fill form with assignment details when it loads
    useEffect(() => {
        if (assignment !== null) {
            setFormValues({
                webexCustomerId: assignment.webexCustomerId,
                bandwidthSubAccount: assignment.bandwidthSubAccount,
                dubberAccount: assignment.dubberAccount
            });
        }
    }, [assignment]);

    const handleCancel = () => {
        history.push(webexAssignmentViewUrl(organizationId, assignmentId))
    };

    const handleSubmit = () => {
        update(organizationId, assignmentId, {
            webexCustomerId: formValues.webexCustomerId,
            bandwidthSubAccount: formValues.bandwidthSubAccount,
            dubberAccount: formValues.dubberAccount
        });
    };

    useEffect(() => {
        if (isComplete) {
            dispatch(appWindowAddNotification('Service updated', 'success'));

            history.push(webexAssignmentViewUrl(organizationId, assignmentId))
        }
    }, [isComplete]);

    return (
        <div className={classes.root}>
            <PageHeader text="Cloud PBX w/ Webex"
                subtext={organization !== null ? organization.name : undefined}>
            </PageHeader>

            {!isFetchingOrganization
                && !isFetchingAssignment
                && !isFetchingWebexCustomers
                && !isFetchingBandwidthSubAccounts
                && !isFetchingDubberAccounts
                && organization !== null
                && assignment !== null
                && webexCustomers !== null
                && bandwidthSubAccounts !== null
                && dubberAccounts !== null
                && <>
                    <Paper className={classes.paper}>
                        <UpdateAssignmentForm
                            organization={organization}
                            webexCustomerOptions={webexCustomers}
                            bandwidthSubAccountOptions={bandwidthSubAccounts}
                            dubberAccountOptions={dubberAccounts}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                            fieldErrorMessages={updateFieldErrors} />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(AssignmentUpdatePage, WebexPolicies.CanManageAssignments);