import { List, ListItem, ListItemIcon, ListItemText, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';

interface Props {
    open: boolean;
    title: string;
    errors: string[];
    onClose: () => void;
}


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(() => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(100),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        maxHeight: 500,
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
}));

export const ErrorModal = (props: Props) => {
    const { open, onClose, errors, title } = props;
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose}>
            <div style={getModalStyle()} className={classes.paper}>
                <Typography variant="h6" id="modal-title">
                    {title}
                </Typography>

                <List>
                    {errors.map((error, idx) => (
                        <ListItem key={idx}>
                            <ListItemIcon>
                                <ErrorOutline />
                            </ListItemIcon>
                            <ListItemText key={idx} primary={error} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </Modal>
    );
};
