import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, FormControl, Input, InputLabel, LinearProgress } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useConfirmMfaEmail, useRequestMfaEmail } from './hooks';
import theme from '../../theme';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { authMfaCompleteAction } from './actions';
import { dashboardUrl } from '../dashboard/urls';
import history from '../../history';
import { useValidationHelpers } from '../../form-helpers';

const useStyles = makeStyles({
    formControl: {
        marginTop: theme.spacing(1),
        maginBottom: theme.spacing(1)
    }
});

interface EmailMethodProps {
    onCancel: () => void;
}

const EmailMethod = (props: EmailMethodProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [request, isRequesting, isRequested, requestError] = useRequestMfaEmail();
    const [confirm, isConfirming, isConfirmed, confirmError, confirmErrorFields] = useConfirmMfaEmail();
    const { isValid, ValidationMessage } = useValidationHelpers(confirmErrorFields);

    const { onCancel } = props;
    const [code, setCode] = useState('');

    useEffect(() => {
        request();
    }, []);

    const handleSubmit = () => {
        confirm(code);
    };

    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (isConfirmed) {
            // Upon confirmation mark the session as confirmed and redirect to dashboard
            dispatch(authMfaCompleteAction());
            history.push(dashboardUrl());
        }
    }, [isConfirmed]);

    const textColor = requestError !== null || confirmError !== null ? 'error' : 'inherit';
    const text = requestError !== null
        ? requestError
        : (confirmError !== null) ? confirmError : 'A code has been sent to your email address. Enter it below.';

    return <>
        <DialogContent>
            {isRequesting && <Box sx={{ textAlign: 'center' }}><CircularProgress color="primary" /></Box>}

            {!isRequesting && isRequested &&
                <>
                    <DialogContentText color={textColor}>{text}</DialogContentText>

                    <FormControl className={classes.formControl} fullWidth required>
                        <InputLabel htmlFor="code">Code</InputLabel>
                        <Input
                            autoFocus
                            disabled={false}
                            autoComplete="off"
                            onChange={evt => setCode(evt.target.value)}
                            onKeyDown={handleKeyDown}
                            error={!isValid('code')}
                            id="code"
                            type="code" />
                        <ValidationMessage field="code" />
                    </FormControl>
                </>
            }
        </DialogContent>

        {!isRequesting && isRequested &&
            <DialogActions>
                <Button onClick={() => onCancel()} color="secondary" disabled={isConfirming}>
                    Cancel
                </Button>

                <Button onClick={() => handleSubmit()} color="primary" disabled={isConfirming}>
                    Submit
                </Button>
            </DialogActions>
        }

        {isConfirming && <LinearProgress variant="indeterminate" color="primary" />}
    </>
};

export default EmailMethod;