import { Grid, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';
import { Account, Team, User } from '../api';
import { dubberAccountViewUrl, dubberUserViewUrl } from '../urls';
import { TeamMember } from './api';

const useStyles = makeStyles(() => ({
    paper: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    groupId: string;
    account: Account;
    team: Team;
    teamMember: TeamMember;
}

const TeamMemberSummary = (props: Props) => {
    const classes = useStyles();
    const { groupId, account, team, teamMember } = props;

    const AccountLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberAccountViewUrl(groupId, account.id)} {...props} />);
    const UserLinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberUserViewUrl(groupId, account.id, teamMember.user)} {...props} />);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Account</Typography>
                    <Typography>
                        <Link component={AccountLinkComponent}>{account.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Team</Typography>
                    <Typography>
                        <Link component={AccountLinkComponent}>{team.name}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">User</Typography>
                    <Typography>
                        <Link component={UserLinkComponent}>{teamMember.firstName} {teamMember.lastName}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Listener</Typography>
                    <Typography>
                        {teamMember.listener ? 'Yes' : 'No'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>);
};

export default TeamMemberSummary;