import { Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldError } from '../../../api';
import { CreateAssignment } from '../../webex/assignment/api';
import CreateAssignmentForm, { formComplete } from '../../webex/assignment/CreateAssignmentForm';
import { OrganizationDetails } from '../api';

interface Props {
    organization: OrganizationDetails;
    onReady: (ready: boolean, values: { [key: string]: string | boolean | number | number[] | undefined }) => void;
    submitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
}

/**
 * Step 2 of the service assignment wizard when Webex service is selected.
 *
 * @param props
 * @constructor
 */
const WebexStep = (props: Props) => {
    const { organization, onReady, submitting, errorMessage, fieldErrorMessages } = props;

    const [formValues, setFormValues] = useState<CreateAssignment>({
        useExisting: true
    });

    // If all values are filled out, then the Finish button becomes active.
    // Pressing the Finish button executes the function we pass to OnCanFinish
    useEffect(() => {
        if (formComplete(organization, formValues)) {
            onReady(true, {
                ...formValues
            });
        } else {
            onReady(false, {});
        }
    }, [formValues]);

    return (
        <>
            <Typography variant="h4">Cloud PBX w/ Webex</Typography>

            <CreateAssignmentForm
                organization={organization}
                formValues={formValues}
                isSubmitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages}
                setFormValues={setFormValues} />

        </>
    );
};

export default WebexStep;