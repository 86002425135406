import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldError } from '../../../api';
import { makeStyles } from "@mui/styles";
import { OrganizationDetails } from '../api';
import { formComplete, FormValues } from '../../fax2mail/assignment/AssignmentForm';
import AssignmentForm from '../../fax2mail/assignment/AssignmentForm';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    onReady: (ready: boolean, values: { [key: string]: string | boolean | number | number[] | undefined }) => void;
    submitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
}

/**
 * Step 2 of the service assignment wizard when Fax2Mail service is selected.
 *
 * @param props
 * @constructor
 */
const Fax2MailStep = (props: Props) => {
    const { organization, onReady, submitting, errorMessage, fieldErrorMessages } = props;
    const classes = useStyles();

    const [formValues, setFormValues] = useState<FormValues>({
    });

    // If all values are filled out, then the Finish button becomes active.
    // Pressing the Finish button executes the function we pass to OnCanFinish
    useEffect(() => {
        if (formComplete(organization, formValues)) {
            onReady(true, {
            });
        } else {
            onReady(false, {});
        }


    }, [formValues]);

    return (
        <>
            <Typography variant="h4">Fax2Mail</Typography>

            <AssignmentForm
                organization={organization}
                formValues={formValues}
                isSubmitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages}
                setFormValues={setFormValues} />

        </>
    );
};

export default Fax2MailStep;