export * from './accounts/api';
export * from './locations/api';
export * from './numbers/api';
export * from './port-check/api';
export * from './orders/api';
export * from './sub-accounts/api';

export type Direction = 'N' | 'E' | 'S' | 'W' | 'NE' | 'SE' | 'NW' | 'SW';

export const DIRECTIONS: Direction[] = [
    'N', 'E', 'S', 'W', 'NE', 'SE', 'NW', 'SW'
];

export type AddressType = 'Service' | 'Billing';

// Basic address fields
export interface AddressBase {
    //addressType: AddressType;
    housePrefix: string | null;
    houseNumber: string;
    houseSuffix: string | null;
    preDirectional: Direction | null;
    streetName: string;
    streetSuffix: string | null;
    postDirectional: Direction | null;
    addressLine2: string | null;
    city: string;
    stateCode: string;
    zip: string;
    plusFour: string | null;
}