import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { productLabel } from '../helpers';
import { dubberDubPointViewUrl } from '../urls';
import { TeamMemberDubPoint } from './api';

interface Props {
    groupId: string;
    accountId: string;
    dubPoints: TeamMemberDubPoint[];
}

export default function DubPointTable(props: Props) {
    const { groupId, accountId, dubPoints } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Dub Point ID</TableCell>
                    <TableCell>Group ID</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Product</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dubPoints.map(dp => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberDubPointViewUrl(groupId, accountId, dp.dubPoint)} {...props} />);

                    return (
                        <TableRow key={dp.dubPoint}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{dp.dubPoint}</Link>
                            </TableCell>
                            <TableCell>{dp.details.externalGroup}</TableCell>
                            <TableCell>{dp.details.externalIdentifier}</TableCell>
                            <TableCell>{productLabel(dp.details.product)}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}