import { BILLING_ASSOCIATE, PROJECT_COORDINATOR, PROVISIONING, SERVICE_MANAGER, SUPER_USER } from '../administrators/api';

export enum SipTrunkingPolicies {
    CanManageAssignments = 'CanManageSipTrunkingAssignments',
    CanCancelAssignments = 'CanCancelSipTrunkingAssignments'
}

export const SipTrunkingPolicyRoles = {
    [SipTrunkingPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [SipTrunkingPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING]
};