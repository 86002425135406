import { FormControl, Input, InputLabel, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import theme from '../../../theme';
import { Account } from '../accounts/api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    accounts: Account[];
    selectedAccountId: string | null;
    onSelect: (accountId: string) => void;
}

export const AccountForm = (props: Props) => {
    const classes = useStyles();
    const { accounts, selectedAccountId, onSelect } = props;

    return (
        <FormControl className={classes.formControl} fullWidth required>
            <InputLabel htmlFor="selectedAccountId">Account</InputLabel>
            <Select
                id="selectedAccountId"
                native
                margin="none"
                value={selectedAccountId || ''}
                input={<Input name="selectedAccountId" id="selectedAccountId" />}
                onChange={evt =>  onSelect(evt.target.value || '')}
                onKeyDown={() => false}>
                <option value="" />
                {accounts.map(acc => <option key={acc.id} value={acc.id}>{acc.name} ({acc.id})</option>)}
            </Select>
        </FormControl>
    );
};

export default AccountForm;