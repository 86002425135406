import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { PortInOrder } from './api';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    order: PortInOrder;
}

// Port In Order Activation Information Box
const PortInActivationInformation = (props: Props) => {
    const classes = useStyles();
    const { order } = props;

    const foc = order.actualFocDate
        ? moment(order.actualFocDate).format('MM/DD/YYYY hh:mm A')
        : "Not Yet Assigned";

    const requestedDate = moment(order.requestedFocDate).format('MM/DD/YYYY hh:mm A')

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Firm Order Commitment (FOC)</Typography>
                    <Typography>
                        {foc}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Requested Activation Date</Typography>
                    <Typography>
                        {requestedDate}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Customer Activation</Typography>
                    <Typography>
                        {order.triggered ? "Yes" : "No"}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Immediately Requested</Typography>
                    <Typography>
                        {order.immediately ? "Yes" : "No"}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PortInActivationInformation;