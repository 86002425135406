import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useFetchBandwidthLocation, useFetchBandwidthSubAccount } from '../../hooks';
import HistoryNotesTable from '../HistoryNotesTable';
import { useFetchBandwidthNewNumberOrder } from './hooks';
import NewNumberSummary from './NewNumberSummary';
import NumberTable from './NumberTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginBottom: theme.spacing(2)
    },
    section: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
}));

interface Props extends RouteComponentProps<any> {

}

// Page for viewing a New Number order
const NewNumberViewPage = (props: Props) => {
    const classes = useStyles();

    const accountId = props.match.params['accountId'] || null;
    const orderId = props.match.params['orderId'] || null;

    const [fetchOrder, isFetchingOrder, order, fetchError] = useFetchBandwidthNewNumberOrder();
    const [fetchSubAccount, isFetchingSubAccount, subAccount, fetchSubAccountError] = useFetchBandwidthSubAccount();
    const [fetchLocation, isFetchingLocation, location, fetchLocationError] = useFetchBandwidthLocation();

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingOrder || isFetchingSubAccount || isFetchingLocation,
        fetchError || fetchSubAccountError || fetchLocationError
    );

    // Fetch accounts on load
    useEffect(() => {
        fetchOrder(accountId, orderId);
    }, [accountId, orderId]);

    // Fetch subaccount & location when order is loaded
    useEffect(() => {
        if (order) {
            fetchSubAccount(accountId, order.subAccountId);
            fetchLocation(accountId, order.subAccountId, order.locationId);
        }
    }, [order]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Order" subtext={orderId} />

            {!isFetchingOrder && order && !isFetchingSubAccount && subAccount && !isFetchingLocation && location &&
                <>
                    <div className={classes.summary}>
                        <NewNumberSummary
                            accountId={accountId}
                            order={order}
                            subAccount={subAccount}
                            location={location} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">Numbers</Typography>
                        <Typography variant="body1">{order.completedQuantity} complete, {order.failedQuantity} failed</Typography>

                        {(order.completedNumbers.length > 0 || order.failedNumbers.length > 0) &&
                            <Paper className={classes.paper}>
                                <NumberTable
                                    completedNumbers={order.completedNumbers}
                                    errors={order.errors}
                                    failedNumbers={order.failedNumbers} />
                            </Paper>
                        }
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">History & Notes</Typography>
                        <Paper className={classes.paper}>
                            <HistoryNotesTable
                                history={order.history}
                                notes={order.notes}
                            />
                        </Paper>
                    </div>
                </>

            }
        </div>
    );
};

export default NewNumberViewPage;