import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';
import { Lidb } from '../api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    lidb: Lidb;
}

// Outbound Caller ID Summary Box
const OutboundCallerIdSummary = (props: Props) => {
    const classes = useStyles();
    const { lidb } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Service Type</Typography>
                    <Typography>
                        {lidb.serviceType}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Display Name</Typography>
                    <Typography>
                        {lidb.display ? 'Allowed' : 'Blocked'}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" color="textSecondary">Name</Typography>
                    <Typography>
                        {lidb.name}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default OutboundCallerIdSummary;