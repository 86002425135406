import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    passcode: string;
}

// Port Out Passcode Summary Box
const PasscodeSummary = (props: Props) => {
    const classes = useStyles();
    const { passcode } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">Passcode</Typography>
                    <Typography>
                        {passcode}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PasscodeSummary;