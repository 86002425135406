import * as React from 'react';
import { FieldError } from './api';
import { FormHelperText } from '@mui/material';
import { useCallback } from 'react';

export const useValidationHelpers = (fieldErrors: FieldError[]) => {
    const isValid = useCallback((fieldName: string) =>
        fieldErrors.find(e => e.field == fieldName) === undefined, [fieldErrors]);

    const fieldMessages = useCallback((fieldName: string) => fieldErrors
        .filter(e => e.field == fieldName)
        .map(e => e.message), [fieldErrors]);

    const ValidationMessage = useCallback((props: { field: string }) => <>
        {!isValid(props.field) &&
        <FormHelperText error>
            {fieldMessages(props.field)}
        </FormHelperText>
        }
    </>, [fieldErrors]);

    return {
        isValid,
        ValidationMessage
    }
};