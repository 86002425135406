import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';
import { Location } from './api';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1)
    }
}));

interface Props {
    location: Location;
}

// Location Origination Summary Box
const OriginationSummary = (props: Props) => {
    const classes = useStyles();
    const { location } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Distribution Method</Typography>
                    <Typography>
                        {location.originationDistribution}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Hosts</Typography>
                    {location.originationHosts.length === 0 ? <Typography>No Hosts</Typography> : location.originationHosts.map(h => <div key={h}>{h}</div>)}
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Calling Name Displayed</Typography>
                    <Typography>
                        {location.callingNameDisplayed ? 'Yes' : 'No'}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">Enforced for All Numbers</Typography>
                    <Typography>
                        {location.callingNameEnforced ? 'Yes' : 'No'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default OriginationSummary;