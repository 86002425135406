import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { fetchOrganizations } from '../api';
import { OrganizationPolicies } from '../policies';
import { organizationCreateUrl } from '../urls';
import OrganizationTable, { OrganizationTableItem } from './OrganizationTable';
import SearchBar from './SearchBar';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    block: {
        display: 'block',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }
}));


interface Props extends RouteComponentProps<any> {
}

interface Form {
    search: string | undefined;
    page: number | undefined;
    limit: number | undefined;
}

interface Page {
    current: number;
    size: number;
    total: number;
}

/**
 * Hook for retrieving organization list from server
 */

type UseOrganizationListFetchResult = [
    boolean,
    Array<OrganizationTableItem> | null,
    Page | null,
    string | null
];

const useOrganizationListFetch = (search: string, pageNumber: number = 1, limit: number | undefined): UseOrganizationListFetchResult => {
    const api = useApi();

    // Indicates that we're currently fetching items from the server
    const [fetching, setFetching] = useState(false);

    // The list of organizations retrieved
    const [items, setItems] = useState<Array<OrganizationTableItem> | null>(null);

    // Details about the page returned by the server
    const [page, setPage] = useState<Page | null>(null);

    // Error message returned by the server
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setFetching(true);

            try {
                const response = await fetchOrganizations(api, search, pageNumber, limit);
                if (!didCancel) {
                    setItems(response.items.map(i => ({
                        ...i,
                        sapId: i.sapId || ''

                    })));

                    setPage({
                        current: response.page,
                        size: response.pageSize,
                        total: response.totalItems,
                    });

                    setFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setFetching(false);
                        setError('Unable to retrieve organizations');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [search, pageNumber, limit]);

    return [
        fetching,
        items,
        page,
        error
    ];
};


/**
 * Organization list page
 * @param props
 */

const OrganizationIndexPage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const session = useSession();

    // Parse query string portion of URL. The search and page are used to retrieve the organization list
    const params = parse(location.search);
    const searchQuery = params['search'] !== undefined ? String(params['search']) : undefined;
    const pageQuery = params['page'] !== undefined ? parseInt(String(params['page'])) : undefined;
    const limitQuery = params['limit'] !== undefined ? parseInt(String(params['limit'])) : undefined;

    const [form, setForm] = useState<Form>({
        // Default query state to what's passed in the URL query string
        search: searchQuery,
        page: pageQuery,
        limit: limitQuery
    });

    // A change in params causes a change in state
    if ((searchQuery !== form.search) || (pageQuery !== form.page) || (limitQuery !== form.limit)) {
        setForm({
            search: searchQuery,
            page: pageQuery,
            limit: limitQuery
        });
    }

    useNav('organizations');

    const [isFetching, items, page, error] = useOrganizationListFetch(form.search || '', form.page, form.limit);

    useProgressEffects(
        isFetching,
        error
    );

    // Submitting the search will update the query string and force a fetch
    function handleSubmitSearch(value: string, limit: number) {
        history.push(`?search=${value}&limit=${limit}&page=1`);
    }

    return (
        <>
            <div className={classes.root}>
                <PageHeader text="All Organizations">
                    {authorizedFor(OrganizationPolicies.CanManage, session.roles) &&
                        <Button
                            className={classes.headerButton}
                            variant="text"
                            color="primary"
                            component={React.forwardRef((props, ref) => <Link to={organizationCreateUrl()} {...props}/>)}>
                            Add New
                        </Button>
                    }
                </PageHeader>
                <Paper>
                    <SearchBar
                        search={form.search}
                        limit={form.limit}
                        onSubmitSearch={handleSubmitSearch}/>

                    {items !== null && items.length > 0 &&
                    <>
                        <OrganizationTable items={items}/>

                        {page !== null &&
                            <Pagination
                                totalItems={page.total}
                                pageSize={page.size}
                                page={page.current}
                                disabled={isFetching}
                                additionalParams={{
                                    search: form.search,
                                    limit: form.limit
                                }}
                                onChange={() => null}/>
                        }
                    </>
                    }

                    {items !== null && items.length === 0 &&
                        <div className={classes.noResultsContainer}>
                            <Typography color="textSecondary" align="center">
                                No organizations found.
                            </Typography>
                        </div>
                    }

                </Paper>
            </div>
        </>
    );
};

export default OrganizationIndexPage;