import { ApiCaller } from '../../../api';
import { DubPoint } from '../dub-points/api';

export interface TeamMemberIndex {
    user: string;
    firstName: string | null;
    lastName: string | null;
    listener: boolean;
    dateCreated: string;
    dateUpdated: string;
}

export interface TeamMember extends TeamMemberIndex {
    dubPoints: TeamMemberDubPoint[];
}

export interface TeamMemberCreateUpdate {
    listener: boolean;
    dubPointIds: string[];
}

export interface TeamMemberDubPoint {
    dubPoint: string;
    dateCreated: string;
    dateUpdated: string;
    details: DubPoint;
}


/*
 * GET /api/dubber/:groupId/accounts/:accountId/teams/{teamId}/members
 *
 * Get listing of members in a team
 */

export const fetchDubberTeamMembers = async (api: ApiCaller, groupId: string, accountId: string, teamId: string): Promise<TeamMemberIndex[]> => {
    return await api<TeamMemberIndex[]>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}/members`, 'GET');
};


/*
 * GET /api/dubber/:groupId/accounts/:accountId/teams/{teamId}/members/{userId}
 *
 * Get details about team member
 */

export const fetchDubberTeamMember = async (api: ApiCaller, groupId: string, accountId: string, teamId: string, userId: string): Promise<TeamMember> => {
    return await api<TeamMember>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}/members/${userId}`, 'GET');
};

/*
 * PUT /api/dubber/:groupId/accounts/:accountId/teams/{teamId}/members/{userId}
 *
 * Create/Update team member
 */

export const updateDubberTeamMember = async (api: ApiCaller, groupId: string, accountId: string, teamId: string, userId: string, update: TeamMemberCreateUpdate): Promise<TeamMember> => {
    return await api<TeamMember>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}/members/${userId}`, 'PUT', {
        ...update
    });
};

/*
 * DELETE /api/dubber/:groupId/accounts/:accountId/teams/{teamId}/members/{userId}
 *
 * Unassign a user from a team
 */

export const deleteDubberTeamMember = async (api: ApiCaller, groupId: string, accountId: string, teamId: string, userId: string): Promise<{}> => {
    return await api<{}>(`dubber/${groupId}/accounts/${accountId}/teams/${teamId}/members/${userId}`, 'DELETE');
};