import { ResponseError, useApi } from '../../api';
import { useEffect, useState } from 'react';
import { fetchUser, User } from './api';
import history from '../../history';
import { loginUrl } from '../auth/urls';

// Hook for retrieving a user's details
export const useFetchUserDetails = (organizationId: number, userId: number): [boolean, User | null, string | null] => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            setIsFetching(true);

            try {
                const response = await fetchUser(api, organizationId, userId);
                if (!didCancel) {
                    setUser(response);
                    setIsFetching(false);
                }
            } catch (e) {
                if (!didCancel) {
                    // If the API returns a 401 error, then our session is not valid
                    // and we must take the user back to the login screen
                    if ((e instanceof ResponseError) && (e.code === 401)) {
                        history.push(loginUrl());
                    } else {
                        setIsFetching(false);
                        setError('Unable to retrieve user details.');
                    }
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [organizationId]);

    return [
        isFetching,
        user,
        error
    ];
};