import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { Organization } from '../api';
import { useCreateFax2MailStatement, useFetchAllFax2MailOrganizations, useFetchAllFax2MailStatements, useFetchFax2MailSeats } from '../hooks';
import { Fax2MailPolicies } from '../policies';
import { fax2mailStatementIndexUrl } from '../urls';
import PeriodAndOrganizationSelection from './PeriodAndOrganizationSelection';
import StatementForm, { PageValues, SeatError } from './StatementForm';
import StatementRevisionNotice from './StatementRevisionNotice';
import moment = require('moment');

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * Page for creating new statements
 * @param props
 */
const StatementCreatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useNav('fax2mail', 'statements');

    const params = parse(location.search);

    const [fetchOrganizations, isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllFax2MailOrganizations();
    const [fetchStatements, isFetchingStatements, existingStatements, fetchStatementsError] = useFetchAllFax2MailStatements();
    const [fetchSeats, isFetchingSeats, seats, fetchSeatsError] = useFetchFax2MailSeats();
    const [submit, isSubmitting, isComplete, submitError, submitFieldErrors] = useCreateFax2MailStatement();

    const [billingYear, setBillingYear] = useState<number>();
    const [billingMonth, setBillingMonth] = useState<number>();
    const [organization, setOrganization] = useState<Organization | null>(null);

    const [seatPageValues, setSeatPageValues] = useState<PageValues[]>([]);

    // Fetch all organizations on load
    useEffect(() => {
        fetchOrganizations();
    }, []);

    useProgressEffects(
        isFetchingOrganizations || isFetchingSeats || isFetchingStatements || isSubmitting,
        fetchOrganizationsError || fetchSeatsError || fetchStatementsError || submitError);

    const handleSelectOrganization = (billingYear: number, billingMonth: number, assignmentId: number | null) => {
        const org = (organizations || []).find(o => o.assignmentId === assignmentId);

        setOrganization(org || null);
        setBillingYear(billingYear);
        setBillingMonth(billingMonth);
    };

    // Load seats and previous statements when assignment changes
    useEffect(() => {
        if (organization !== null) {
            fetchSeats(organization.id, organization.assignmentId);
            fetchStatements(organization.id, organization.assignmentId);
        }
    }, [organization]);

    // Reset form values when seats load
    useEffect(() => {
        if (seats !== null) {
            setSeatPageValues(seats.map(s => ({
                seatId: s.id,
                pagesIn: '0',
                pagesOut: '0'
            })));
        } else {
            setSeatPageValues([]);
        }
    }, [seats]);

    const previousStatementsForPeriod = useMemo(() => {
        if (existingStatements && organization) {
            return existingStatements.filter(s => {
                return s.billingYear === billingYear && s.billingMonth === billingMonth;
            })
        } else {
            ;
            return [];
        }
    }, [existingStatements, organization, billingYear, billingMonth]);

    // Turn indexed error messages for seats into a new type that includes the seat ID
    const seatErrors = useMemo(() => {
        let seatErrors: SeatError[] = [];

        if (seats) {
            submitFieldErrors.forEach(e => {
                if (e.field.substring(0, 6) === 'seats[') {
                    // The index will be contained inside brackets
                    const match = e.field.match(/\[(\d+)\]/);
                    if (match !== null) {
                        const idx = parseInt(match[1]);
                        const seatId = seats[idx].id;

                        if (seatId) {
                            // Trim off first part to just leave the field name
                            var field = (e.field.split('.')[1]);

                            seatErrors.push({
                                seatId,
                                field,
                                message: e.message
                            });
                        }
                    }
                }
            });
        }

        return seatErrors;
    }, [submitFieldErrors]);

    const handleChange = (seatId: number, field: 'pagesIn' | 'pagesOut', value: string) => {
        var adjustedPageValues = seatPageValues.map(v => {
            if (v.seatId === seatId) {
                return {
                    seatId: seatId,
                    pagesIn: field === 'pagesIn' ? value : v.pagesIn,
                    pagesOut: field === 'pagesOut' ? value : v.pagesOut,
                }
            } else {
                return v;
            }
        });

        setSeatPageValues(adjustedPageValues);
    };

    const handleSubmit = () => {
        if (billingYear && billingMonth && organization) {
            submit({
                billingYear,
                billingMonth,
                organizationId: organization.id,
                assignmentId: organization.assignmentId,
                seats: seatPageValues.map(v => ({
                    seatId: v.seatId,
                    pagesIn: parseInt(v.pagesIn),
                    pagesOut: parseInt(v.pagesOut)
                }))
            });
        }
    };

    const handleCancel = () => {
        history.push(fax2mailStatementIndexUrl());
    };

    // Go back to statement listing and show message when complete
    useEffect(() => {
        if (isComplete) {
            dispatch(appWindowAddNotification('Statement created', 'success'));

            history.push(fax2mailStatementIndexUrl());
        }
    }, [isComplete]);

    return (
        <div className={classes.root}>
            <PageHeader text="Fax2Mail" subtext="Create Statement" />

            {!isFetchingOrganizations
                && (organizations !== null)
                &&
                <>
                    <Paper className={classes.paper}>
                        <PeriodAndOrganizationSelection
                            isSubmitting={isSubmitting}
                            organizations={organizations}
                            onSelect={handleSelectOrganization} />
                    </Paper>

                    {!isFetchingSeats && (seats !== null) && billingYear && billingMonth && organization &&
                        <>
                            {previousStatementsForPeriod.length > 0 && <StatementRevisionNotice billingYear={billingYear} billingMonth={billingMonth} />}

                            <StatementForm
                                seats={seats}
                                isSubmitting={isSubmitting}
                                onChange={handleChange}
                                seatPageValues={seatPageValues}
                                seatErrors={seatErrors}
                            />

                            <Grid container justifyContent="flex-end">
                                <Button
                                    className={classes.button}
                                    color="inherit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    onClick={handleCancel}>Cancel</Button>


                                <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}>Save</Button>
                            </Grid>
                        </>
                    }


                </>
            }
        </div>
    );
};


export default withPolicyRestriction(StatementCreatePage, Fax2MailPolicies.CanManageStatements);