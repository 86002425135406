import { Box, Paper, Tab, Tabs as UiTabs, Tooltip, Typography } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarTodayTwoTone';
import { makeStyles } from '@mui/styles';
import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useFetchBandwidthAccounts, useFetchBandwidthE911Orders, useFetchBandwidthLidbOrders, useFetchBandwidthMoveNumberOrders, useFetchBandwidthNewNumberOrders, useFetchBandwidthPortInOrders, useFetchBandwidthTnOptionOrders } from '../hooks';
import { bandwidthOrderE911sIndexUrl, bandwidthOrderE911ViewUrl, bandwidthOrderIndexUrl, bandwidthOrderLidbsIndexUrl, bandwidthOrderLidbViewUrl, bandwidthOrderMoveNumberViewUrl, bandwidthOrderMovesIndexUrl, bandwidthOrderNewNumbersIndexUrl, bandwidthOrderNewNumberViewUrl, bandwidthOrderPortInsIndexUrl, bandwidthOrderPortInViewUrl, bandwidthOrderTnOptionsIndexUrl, bandwidthOrderTnOptionViewUrl } from '../urls';
import { orderStatusDisplay } from './helpers';
import { OrderIndexTabContents } from './OrderIndexTabContents';
import SearchForm from './SearchForm';
const queryString = require('query-string');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tabPaperContents: {
        padding: theme.spacing(0)
    }
}));

type Tabs = 'new-numbers'
    | 'port-ins'
    | 'e911s'
    | 'tn-options'
    | 'moves'
    | 'lidbs';

interface Props extends RouteComponentProps<any> {
    activeTab?: Tabs;
}

// Page for listing orders under an account
const OrderIndexPage = (props: Props) => {
    const classes = useStyles();

    const accountId = props.match.params['accountId'] || null;

    const qs = parse(location.search);
    const page = qs['page'] !== undefined ? parseInt(String(qs['page'])) : 1;

    const { activeTab } = props;

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [fetchAccounts, isFetchingAccounts, accounts, accountsErrorMessage] = useFetchBandwidthAccounts();

    const [fetchNewNumberOrders, isFetchingNewNumberOrders, newNumberOrders, newNumberOrdersMessage] = useFetchBandwidthNewNumberOrders();
    const [fetchPortInOrders, isFetchingPortInOrders, portInOrders, portInOrdersMessage] = useFetchBandwidthPortInOrders();

    const [fetchE911Orders, isFetchingE911Orders, e911Orders, e911OrdersMessage] = useFetchBandwidthE911Orders();
    const [fetchTnOptionsOrders, isFetchingTnOptionsOrders, tnOptionsOrders, tnOptionsOrdersMessage] = useFetchBandwidthTnOptionOrders();
    const [fetchLidbOrders, isFetchingLidbOrders, lidbOrders, lidbOrdersMessage] = useFetchBandwidthLidbOrders();
    const [fetchMoveOrders, isFetchingMoveOrders, moveOrders, moveOrdersMessage] = useFetchBandwidthMoveNumberOrders();

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingAccounts,
        accountsErrorMessage || errorMessage
    );

    // Fetch accounts on load
    useEffect(() => {
        fetchAccounts();
    }, []);

    const handleSelected = (newAccountId: string | null) => {
        if (newAccountId === '') {
            newAccountId = null;
        }

        if (newAccountId === null) {
            history.push(bandwidthOrderIndexUrl());
        } else if (accountId !== newAccountId) {
            history.push(bandwidthOrderNewNumbersIndexUrl(newAccountId));
        }
    };

    // Fetch all orders when accountId is selected
    useEffect(() => {
        if (accountId) {
            fetchNewNumberOrders(accountId);
            fetchPortInOrders(accountId);
            fetchTnOptionsOrders(accountId);
            fetchE911Orders(accountId);
            fetchMoveOrders(accountId);
            fetchLidbOrders(accountId);
        }
    }, [accountId]);

    const handleTabChange = (_: React.ChangeEvent<{}>, value: Tabs) => {
        if (accountId) {
            switch (value) {
                case 'new-numbers':
                    history.push(bandwidthOrderNewNumbersIndexUrl(accountId));
                    break;
                case 'port-ins':
                    history.push(bandwidthOrderPortInsIndexUrl(accountId));
                    break;
                case 'e911s':
                    history.push(bandwidthOrderE911sIndexUrl(accountId));
                    break;
                case 'tn-options':
                    history.push(bandwidthOrderTnOptionsIndexUrl(accountId));
                    break;
                case 'moves':
                    history.push(bandwidthOrderMovesIndexUrl(accountId));
                    break;
                case 'lidbs':
                    history.push(bandwidthOrderLidbsIndexUrl(accountId));
                    break;
            }
        }
    };

    const newNumberOrderListItems = useMemo(() => {
        if (newNumberOrders) {
            return newNumberOrders.map(o => {
                return {
                    ...o,
                    number: `${o.numberCount} Number${o.numberCount != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [newNumberOrders]);

    const portInOrderListItems = useMemo(() => {
        if (portInOrders && portInOrders) {
            return portInOrders.map(o => {
                return {
                    ...o,
                    focDate: o.actualFocDate || '',
                    number: `${o.numberCount} Number${o.numberCount != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [portInOrders]);

    const e911OrderListItems = useMemo(() => {
        if (e911Orders) {
            return e911Orders.map(o => {
                return {
                    ...o,
                    numberList: o.numbers,
                    number: o.numbers.length === 1 ? o.numbers[0] : `${o.numbers.length} Number${o.numbers.length != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [e911Orders]);

    const tnOptionsOrderListItems = useMemo(() => {
        if (tnOptionsOrders) {
            return tnOptionsOrders.map(o => {
                var numbers = o.groups.map(g => g.numbers).reduce((acc, curVal) => acc.concat(curVal));

                return {
                    ...o,
                    numberList: numbers,
                    number: numbers.length === 1 ? numbers[0] : `${numbers.length} Number${numbers.length != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [tnOptionsOrders]);

    const lidbOrderListItems = useMemo(() => {
        if (lidbOrders) {
            return lidbOrders.map(o => {
                return {
                    ...o,
                    number: `${o.numberCount} Number${o.numberCount != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [lidbOrders]);

    const moveOrderListItems = useMemo(() => {
        if (moveOrders) {
            return moveOrders.map(o => {
                return {
                    ...o,
                    number: `${o.numberCount} Number${o.numberCount != 1 ? 's' : ''}`
                };
            });
        } else {
            return null;
        }
    }, [moveOrders]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth" subtext="Orders" />

            {!isFetchingAccounts && accounts &&
                <>
                    <Paper className={classes.paper}>
                        <SearchForm
                            disabled={false}
                            accounts={accounts}
                            onSelected={handleSelected}
                        />
                    </Paper>

                    {accountId &&
                        <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <UiTabs value={activeTab} onChange={handleTabChange}>
                                    <Tab value="new-numbers" label={tabLabel('New Numbers', isFetchingNewNumberOrders, (newNumberOrders || []).length)} />
                                    <Tab value="port-ins" label={tabLabel('Port-Ins', isFetchingPortInOrders, (portInOrders || []).length)} />
                                    <Tab value="e911s" label={tabLabel('E911s', isFetchingE911Orders, (e911Orders || []).length)} />
                                    <Tab value="tn-options" label={tabLabel('TN Options', isFetchingTnOptionsOrders, (tnOptionsOrders || []).length)} />
                                    <Tab value="moves" label={tabLabel('Moves', isFetchingMoveOrders, (moveOrders || []).length)} />
                                    <Tab value="lidbs" label={tabLabel('Calling Names', isFetchingLidbOrders, (lidbOrders || []).length)} />
                                </UiTabs>
                            </Box>

                            <Paper className={classes.tabPaperContents}>
                                {activeTab === 'new-numbers' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderNewNumberViewUrl}
                                        loading={isFetchingNewNumberOrders}
                                        error={newNumberOrdersMessage}
                                        orders={newNumberOrderListItems}
                                        statusOptions={['Complete', 'Partial', 'Failed', 'Backordered']}
                                    />
                                }

                                {activeTab === 'port-ins' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderPortInViewUrl}
                                        loading={isFetchingPortInOrders}
                                        error={portInOrdersMessage}
                                        orders={portInOrderListItems}
                                        showFocDate={true}
                                        statusOptions={['Draft', 'PendingDocuments', 'Submitted', 'Exception', 'RequestedSupp', 'Foc', 'RequestedCancel', 'Cancelled', 'Complete']}
                                    />
                                }

                                {activeTab === 'e911s' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderE911ViewUrl}
                                        loading={isFetchingE911Orders}
                                        error={e911OrdersMessage}
                                        orders={e911OrderListItems}
                                        statusOptions={['Received', 'Processing', 'Complete', 'Partial', 'Failed', 'AdjustedComplete', 'AdjustedPartial']}
                                    />
                                }

                                {activeTab === 'tn-options' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderTnOptionViewUrl}
                                        loading={isFetchingTnOptionsOrders}
                                        error={tnOptionsOrdersMessage}
                                        orders={tnOptionsOrderListItems}
                                        statusOptions={['Received', 'Processing', 'Complete', 'Partial', 'Failed']}
                                    />
                                }

                                {activeTab === 'moves' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderMoveNumberViewUrl}
                                        loading={isFetchingMoveOrders}
                                        error={moveOrdersMessage}
                                        orders={moveOrderListItems}
                                        statusOptions={['Received', 'Processing', 'Complete', 'Partial', 'Failed']}
                                    />
                                }

                                {activeTab === 'lidbs' &&
                                    <OrderIndexTabContents
                                        url={bandwidthOrderLidbViewUrl}
                                        loading={isFetchingLidbOrders}
                                        error={lidbOrdersMessage}
                                        orders={lidbOrderListItems}
                                        statusOptions={['Received', 'Processing', 'Complete', 'Partial', 'Failed']}
                                    />
                                }


                            </Paper>
                        </>
                    }
                </>
            }
        </div >
    );
};

const tabLabel = (name: string, isLoading: boolean, count: number | null | undefined) => {
    if (isLoading) {
        return `${name} (...)`;
    } else {
        if (count !== null && count !== undefined) {
            return `${name} (${count})`;
        }
    }

    return name;
};

export default OrderIndexPage;