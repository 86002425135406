import { Paper, Typography } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import theme from '../../../theme';

interface Props {
    billingYear: number;
    billingMonth: number;
}

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: amber[900],
        marginTop: theme.spacing(2)
    },
    text: {
        color: grey[50]
    }
}));

const StatementRevisionNotice = (props: Props) => {
    const classes = useStyles();
    const { billingMonth, billingYear } = props;

    return (
        <Paper className={classes.root}>
            <Typography variant="h4" className={classes.text}>Statement Revision</Typography>
            <Typography className={classes.text}>Statement(s) already exist for billing period {billingMonth}/{billingYear}. Saving a statement will create a new revision.</Typography>
        </Paper>
    );
};

export default StatementRevisionNotice;