import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useSession } from '../../../auth/hooks';
import { authorizedFor } from '../../../auth/policies';
import { OrderBase } from '../../api';
import { useFetchBandwidthAccounts, useFetchBandwidthE911Orders, useFetchBandwidthLidbOrders, useFetchBandwidthMoveNumberOrders, useFetchBandwidthNumber, useFetchBandwidthPortInOrders, useFetchBandwidthTnOptionOrders } from '../../hooks';
import { BandwidthPolicies } from '../../policies';
import { bandwidthNumberMoveUrl, bandwidthNumberUpdateE911Url, bandwidthNumberUpdateGeneralUrl, bandwidthNumberUpdateLidbUrl, bandwidthNumberUpdatePasscodeUrl, bandwidthNumberUpdateRoutingUrl } from '../../urls';
import { formatUsNumber } from '../helpers';
import E911Summary from './E911Summary';
import NumberSummary from './NumberSummary';
import OrdersTable from './OrdersTable';
import OutboundCallerIdSummary from './OutboundCallerIdSummary';
import PasscodeSummary from './PasscodeSummary';
import RoutingSummary from './RoutingSummary';
import TnOptionsSummary from './TnOptionsSummary';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    subheading: {
        display: 'flex'
    },
    updateButton: {
        marginLeft: theme.spacing(1)
    },
    noPadding: {
        padding: theme.spacing(0)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for viewing details about a number
const NumberViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const [fetch, isFetching, numberDetails, fetchErrorMessage, fetchErrorCode] = useFetchBandwidthNumber();

    const [fetchAccounts, isFetchingAccounts, accounts, fetchAccountsErrorMessage] = useFetchBandwidthAccounts();

    const [fetchE911Orders, isFetchingE911Orders, e911Orders, fetchE911OrdersError] = useFetchBandwidthE911Orders();
    const [fetchLidbOrders, isFetchingLidbOrders, lidbOrders, fetchLidbOrdersError] = useFetchBandwidthLidbOrders();
    const [fetchMoveOrders, isFetchingMoveOrders, moveOrders, fetchMoveOrdersError] = useFetchBandwidthMoveNumberOrders();
    const [fetchPortInOrders, isFetchingPortInOrders, portInOrders, fetchPortInOrdersError] = useFetchBandwidthPortInOrders();
    const [fetchTnOptionOrders, isFetchingTnOptionOrders, tnOptionOrders, fetchTnOptionOrdersError] = useFetchBandwidthTnOptionOrders();

    // Don't display an error toast if number doesn't exist
    const fetchErrorToast = fetchErrorCode !== 404 ? fetchErrorMessage : null;

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching || isFetchingPortInOrders || isFetchingE911Orders || isFetchingLidbOrders || isFetchingMoveOrders || isFetchingTnOptionOrders || isFetchingAccounts,
        fetchErrorToast || fetchPortInOrdersError || fetchE911OrdersError || fetchLidbOrdersError || fetchMoveOrdersError || fetchTnOptionOrdersError || fetchAccountsErrorMessage
    );

    const number = String(props.match.params['number']);

    // Fetch number on load
    useEffect(() => {
        fetch(number);
        fetchAccounts();
    }, [number]);

    // Fetch orders when number is loaded
    useEffect(() => {
        if (numberDetails) {
            fetchE911Orders(numberDetails.accountId, number);
            fetchLidbOrders(numberDetails.accountId, number);
            fetchMoveOrders(numberDetails.accountId, number);
            fetchPortInOrders(numberDetails.accountId, undefined, number);
            fetchTnOptionOrders(numberDetails.accountId, number);
        }
    }, [numberDetails]);

    const routingSet = useMemo(() => {
        return numberDetails && (numberDetails.routing.customUser !== null || numberDetails.routing.forwardedNumber !== null);
    }, [numberDetails]);

    const tnOptionsSet = useMemo(() => {
        return numberDetails && (
            numberDetails.tnOptions.callingNameDisplayed !== null
            || numberDetails.tnOptions.failoverUri !== null
            || numberDetails.tnOptions.nnid !== null
        );
    }, [numberDetails]);

    const passcodeSet = useMemo(() => numberDetails?.portOutPasscode && numberDetails?.portOutPasscode?.passcode ? true : false, [numberDetails]);

    const orders = useMemo(() => {
        if (!e911Orders || !lidbOrders || !moveOrders || !portInOrders || !tnOptionOrders) {
            return null;
        }

        let o: OrderBase[] = [];

        o = o.concat.apply([], [e911Orders, lidbOrders, moveOrders, portInOrders, tnOptionOrders]);

        return o.sort((a, b) => {
            return -moment(a.createDate).diff(moment(b.createDate));
        });
    }, [e911Orders, lidbOrders, moveOrders, portInOrders, tnOptionOrders]);

    // Details about account number is in
    const account = useMemo(() => {
        if (accounts && numberDetails) {
            const account = accounts.find(a => a.id === numberDetails.accountId);

            if (account) {
                return account;
            }
        }

        return null;
    }, [numberDetails, accounts]);

    const allowRoutingUpdate = account?.service === 'SipTrunking';

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Numbers" subtext={formatUsNumber(number)}>
                {!isFetching && numberDetails && orders && authorizedFor(BandwidthPolicies.CanMoveNumbers, session.roles) && <UpdateButton url={bandwidthNumberMoveUrl(number)} text="Move Number" />}
            </PageHeader>

            {fetchErrorCode === 404 && <Typography>Number no longer exists.</Typography>}

            {!isFetching && numberDetails && orders &&
                <>
                    <div className={classes.summary}>
                        <NumberSummary number={numberDetails} />
                    </div>

                    {/* E911 */}

                    {(account?.features || []).indexOf('E911') > -1 &&
                        <>
                            <div className={classes.subheading}>
                                <Typography variant="h3">E911</Typography>
                                {authorizedFor(BandwidthPolicies.CanManageNumberE911, session.roles) && numberDetails?.e911?.status === 'Success'
                                    && <UpdateButton url={bandwidthNumberUpdateE911Url(number)} />}
                            </div>


                            <div className={classes.summary}>
                                {numberDetails.e911 === null && <Typography variant="body2">No E911 information set.</Typography>}
                                {numberDetails.e911 !== null && numberDetails.e911.status === 'Success' && <E911Summary e911={numberDetails.e911} />}
                                {numberDetails.e911 !== null && numberDetails.e911.status !== 'Success' && <Typography variant="body2" color="secondary">An update is currently in progress and modifications cannot be made.</Typography>}
                            </div>
                        </>
                    }

                    {/* LIDB */}

                    <div className={classes.subheading}>
                        <Typography variant="h3">Outbound Calling Name</Typography>
                        {authorizedFor(BandwidthPolicies.CanManageNumberLidb, session.roles) && numberDetails?.lidb?.status !== 'Pending' && <UpdateButton url={bandwidthNumberUpdateLidbUrl(number)} />}
                    </div>


                    <div className={classes.summary}>
                        {numberDetails.lidb === null && <Typography variant="body2">No Outbound Calling Name information set.</Typography>}
                        {numberDetails.lidb !== null && numberDetails.lidb.status === 'Success' && <OutboundCallerIdSummary lidb={numberDetails.lidb} />}
                        {numberDetails.lidb !== null && numberDetails.lidb.status !== 'Success' && <Typography variant="body2" color="secondary">An update is currently in progress and modifications cannot be made.</Typography>}
                    </div>

                    {/* Routing */}

                    <div className={classes.subheading}>
                        <Typography variant="h3">Routing</Typography>
                        {/* Do not allow updating on HPBX account */}
                        {authorizedFor(BandwidthPolicies.CanManageNumberRouting, session.roles) && allowRoutingUpdate && <UpdateButton url={bandwidthNumberUpdateRoutingUrl(number)} />}
                    </div>


                    <div className={classes.summary}>
                        {routingSet && <RoutingSummary routing={numberDetails.routing} />}
                        {!routingSet && <Typography variant="body2">No Routing information set.</Typography>}
                    </div>

                    {/* General */}

                    <div className={classes.subheading}>
                        <Typography variant="h3">TN Options</Typography>
                        {authorizedFor(BandwidthPolicies.CanManageNumberTnOptions, session.roles) && <UpdateButton url={bandwidthNumberUpdateGeneralUrl(number)} />}
                    </div>


                    <div className={classes.summary}>
                        {tnOptionsSet && <TnOptionsSummary tnOptions={numberDetails.tnOptions} />}
                        {!tnOptionsSet && <Typography variant="body2">No Option information set.</Typography>}
                    </div>

                    {/* Port-Out Passcode */}
                    {authorizedFor(BandwidthPolicies.CanViewAndManageNumberPin, session.roles) &&
                        <>
                            <div className={classes.subheading}>
                                <Typography variant="h3">Port-Out Passcode</Typography>
                                <UpdateButton url={bandwidthNumberUpdatePasscodeUrl(number)} />
                            </div>


                            <div className={classes.summary}>
                                {passcodeSet && <PasscodeSummary passcode={numberDetails?.portOutPasscode?.passcode || ''} />}
                                {!passcodeSet && <Typography variant="body2">No Passcode information set.</Typography>}
                            </div>
                        </>
                    }

                    {/* maybe don't need DLDA
                     <>
                        <Typography variant="h3">Directory Listing</Typography>
                        <div className={classes.summary}>
                            TODO
                        </div>
                    </>*/}

                    {orders.length > 0 &&
                        <>
                            <div className={classes.subheading}>
                                <Typography variant="h3">Orders</Typography>
                            </div>

                            <div className={classes.summary}>
                                <Paper className={classes.noPadding}>
                                    <OrdersTable accountId={numberDetails.accountId} orders={orders} />
                                </Paper>
                            </div>
                        </>
                    }

                </>
            }
        </div>
    );
};

const UpdateButton = (props: { url: string, text?: string }) => {
    const session = useSession();
    const classes = useStyles();
    const { url, text } = props;

    return (
        <Button
            className={classes.updateButton}
            variant="text"
            color="primary"
            component={React.forwardRef((props, ref) => <Link to={url} {...props} />)}>
            {text ?? "Update"}
        </Button>
    );
};

export default NumberViewPage;