import { ApiCaller } from '../../api';
import { AdministratorRole } from '../administrators/api';

export type SessionStatus = 'PendingMfa' | 'Authenticated';

interface CreatedSession {
    token: string;
    expiresAt: string;
    status: SessionStatus;
    roles: AdministratorRole[];
    administratorId: number;
}

// Create new session using username and password
export const createSession = async (api: ApiCaller, email: string, password: string): Promise<CreatedSession> => {
    return await api<CreatedSession>('sessions', 'POST', {
        email,
        password
    });
};

export type MfaMethods = 'Email' | 'Authenticator';

export interface MfaOptions {
    complete: boolean;
    methods: MfaMethods[];
}

// Get MFA options for current session
export const fetchMfaOptions = async (api: ApiCaller): Promise<MfaOptions> => {
    return await api<MfaOptions>('multi-factor-auth', 'GET');
};

// Request email passcode for MFA
export const requestMfaEmail = async (api: ApiCaller): Promise<{}> => {
    return await api<{}>('multi-factor-auth/email-request', 'POST');
};

// Confirm email passcode for MFA
export const confirmMfaEmail = async (api: ApiCaller, passcode: string): Promise<{}> => {
    return await api<{}>('multi-factor-auth/email-confirm', 'POST', {
        passcode
    });
};

// Confirm Authenticator passcode for MFA
export const mfaAuthenticator = async (api: ApiCaller, passcode: string): Promise<{}> => {
    return await api<{}>('multi-factor-auth/authenticator', 'POST', {
        passcode
    });
};