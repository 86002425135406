import { FormControl, FormControlLabel, FormHelperText, FormLabel, Input, InputLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    serviceType: 'Business' | 'Residential';
    display: 'Public' | 'Private';
    name: string;
}

const OutboundCallerIdForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, onFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <FormControl className={classes.formControl} required fullWidth error={!isValid('serviceType')}>
                <FormLabel htmlFor="serviceType">Service Type</FormLabel>
                <RadioGroup
                    row
                    value={formValues.serviceType}
                    onChange={evt => onFieldChange('serviceType', evt.target.value)}
                    name="serviceType">
                    <FormControlLabel value={'Residential'} control={<Radio />} label="Residential" />
                    <FormControlLabel value={'Business'} control={<Radio />} label="Business" />
                </RadioGroup>

                <ValidationMessage field="useType" />
            </FormControl>

            <FormControl className={classes.formControl} required fullWidth error={!isValid('display')}>
                <FormLabel htmlFor="display">Display Name</FormLabel>
                <RadioGroup
                    row
                    value={formValues.display}
                    onChange={evt => onFieldChange('display', evt.target.value)}
                    name="visibility">
                    <FormControlLabel value={'Public'} control={<Radio />} label="Allowed" />
                    <FormControlLabel value={'Private'} control={<Radio />} label="Blocked" />
                </RadioGroup>

                <ValidationMessage field="display" />
            </FormControl>

            <FormControl className={classes.formControl} required fullWidth error={!isValid('name')}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                    value={formValues.name || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('name', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="name"
                    type="text" />

                {isValid('name') && <FormHelperText>Cannot exceed 15 characters in length</FormHelperText>}

                <ValidationMessage field="name" />
            </FormControl>
        </>
    );
};

export default OutboundCallerIdForm;