import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Organization } from './api';
import moment = require('moment');
import { webexAssignmentViewUrl } from '../urls';

interface Props {
    items: Organization[]
}

const OrganizationTable = (props: Props) => {
    const { items } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assign Date</TableCell>
                    <TableCell>Cancel Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(org => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={webexAssignmentViewUrl(org.id, org.assignmentId)} {...props} />);

                    return (
                        <TableRow key={org.assignmentId}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{org.name}</Link>
                            </TableCell>
                            <TableCell>{org.isActive ? "Active" : "Cancelled"}</TableCell>
                            <TableCell>{moment(org.startedAt).format('MM/DD/YYYY')}</TableCell>
                            <TableCell>{org.cancelledAt !== null ? moment(org.cancelledAt).format('MM/DD/YYYY') : ''}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default OrganizationTable;