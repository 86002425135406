import { Button, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { useProgressEffects } from '../../app-window/hooks';
import { Location } from '../locations/api';
import { useFetchBandwidthLocations } from '../locations/hooks';
import LocationsTable from './LocationsTable';
import { Account } from '../accounts/api';
import { authorizedFor } from '../../auth/policies';
import { BandwidthPolicies } from '../policies';
import { useSession } from '../../auth/hooks';
import { Link } from 'react-router-dom';
import { bandwidthLocationCreateUrl } from '../urls';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    addButton: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

interface Props {
    account: Account;
    subAccountId: string;
    onLoaded: (locations: Location[]) => void;
}

const LocationsPane = (props: Props) => {
    const session = useSession();
    const classes = useStyles();

    const { account, subAccountId, onLoaded } = props;
    const [fetch, isFetching, locations, fetchErrorMessage] = useFetchBandwidthLocations();
    const [page, setPage] = useState(1);

    useProgressEffects(
        false,
        fetchErrorMessage
    );

    useEffect(() => {
        fetch(account.id, subAccountId);
    }, [account, subAccountId]);

    useEffect(() => {
        if (!isFetching && locations) {
            onLoaded(locations);
        }
    }, [isFetching, locations]);

    // Order locations by name
    const sortedLocations = useMemo(() => (locations || []).sort((a, b) => a.name.localeCompare(b.name)), [locations]);

    // Pagination locations
    const pagedLocations = useMemo(() => {
        return (sortedLocations || [])
            .slice((page - 1) * DEFAULT_PAGE_SIZE, page * DEFAULT_PAGE_SIZE);
    }, [sortedLocations, page]);

    return (
        <>
            {account.service !== 'WebexWholesale' && authorizedFor(BandwidthPolicies.CanManageLocations, session.roles) &&
                <Button
                    className={classes.addButton}
                    variant="text"
                    color="primary"
                    component={React.forwardRef((props, ref) => <Link to={bandwidthLocationCreateUrl(account.id, subAccountId)} {...props} />)}>
                    Add New
                </Button>
            }

            {!isFetching && locations && <>
                {locations.length === 0 &&
                    <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">
                            No locations found.
                        </Typography>
                    </div>
                }

                {locations.length > 0 &&
                    <>
                        <LocationsTable account={account} subAccountId={subAccountId} locations={pagedLocations} />

                        <Pagination
                            totalItems={locations.length}
                            pageSize={DEFAULT_PAGE_SIZE}
                            page={page}
                            disabled={isFetching}
                            setParams={false}
                            onChange={page => setPage(page)} />
                    </>
                }
            </>}

            {isFetching && <LinearProgress />}
        </>
    );
};

export default LocationsPane;