import { LinearProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { HostedPbxOrganizationForm } from '../components/HostedPbxOrganizationForm';
import { useFetchAllHpbxOrganizations } from '../hooks';
import { HostedPbxPolicies } from '../policies';
import AddNumbersDialog from './AddNumbersDialog';
import { useFetchNumbers, useRemoveNumbers } from './hooks';
import NumberTable from './NumberTable';
import SearchBar from './SearchBar';
import SubHeading from './SubHeading';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

interface SearchFields {
    digits?: string;
    limit: number;
    isAssigned?: boolean;
}

// Number management index page
const NumberIndexPage = () => {
    const classes = useStyles();

    // Update navigation
    useNav('hosted-pbx', 'numbers');

    // Dispatch used for displaying messages
    const dispatch = useDispatch();

    // Fetch all active Hosted PBX Organizations
    const [isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllHpbxOrganizations('active');

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);

    // Search fields
    const [searchFields, setSearchFields] = useState<SearchFields>({
        limit: DEFAULT_PAGE_SIZE
    });

    // Current page selected to be displayed
    const [pageNumber, setPageNumber] = useState<number>(1);

    // Use hook to fetch numbers
    const [fetchNumbers, isFetchingNumbers, numbersPage, fetchNumbersError] = useFetchNumbers();

    // Use hook for removing numbers
    const [submitRemoval, isSubmittingRemoval, removalValidationMessage, removalFatalMessage] = useRemoveNumbers(() => {
        dispatch(appWindowAddNotification('Numbers removed.', 'success'));

        reloadNumbers();

        setIsRemoveNumbersDialogOpen(false);
        setSelectedNumbers([]);
    });

    const [removalDate, setRemovalDate] = useState(new Date());

    // Control if dialogs are open
    const [isAddNumbersDialogOpen, setIsAddNumbersDialogOpen] = useState(false);
    const [isRemoveNumbersDialogOpen, setIsRemoveNumbersDialogOpen] = useState(false);

    // Holds the list of numbers selected for removal
    const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);

    useProgressEffects(isFetchingOrganizations, fetchOrganizationsError || fetchNumbersError);

    const [selectedPlatform, selectedGroup] = useMemo(() => {
        if (!organizations) {
            return [null, null];
        }

        const assignment = organizations.find(o => o.assignmentId == selectedAssignmentId);

        if (!assignment) {
            return [null, null];
        }

        return [
            assignment.platform,
            assignment.broadworksGroup
        ];
    }, [organizations, selectedOrganizationId, selectedAssignmentId]);

    // Fetch numbers when group is changed
    useEffect(() => {
        if (selectedPlatform !== null && selectedGroup !== null) {
            fetchNumbers(
                selectedPlatform,
                selectedGroup,
                searchFields.digits,
                searchFields.isAssigned,
                pageNumber,
                searchFields.limit
            );
        }
    }, [selectedGroup, searchFields, pageNumber]);

    // Reset selected numbers when group changes
    useEffect(() => {
        setSelectedNumbers([]);
    }, [selectedGroup]);

    // Reloads the numbers for the current group and resets page
    // Used after numbers are added or removed
    const reloadNumbers = () => {
        setSearchFields({ ...searchFields });
        setPageNumber(1);
    };

    // Handle Search button
    const searchSubmit = (digits: string, isAssigned: boolean | undefined, limit: number) => {
        setSearchFields({
            digits,
            isAssigned,
            limit
        });
    };

    const handleNumberToggle = (number: string) => {
        if (selectedNumbers.indexOf(number) > -1) {
            // Remove from list
            setSelectedNumbers(selectedNumbers.filter(n => n !== number));
        } else {
            // Add to list
            setSelectedNumbers([...selectedNumbers, number]);
        }
    };

    // Handle cancel button pressed on add numbers dialog
    const handleAddDialogCancel = () => {
        setIsAddNumbersDialogOpen(false);
    };

    // After adding new numbers, display a success message and refresh number listing
    const handleAddDialogComplete = () => {
        dispatch(appWindowAddNotification('Numbers added.', 'success'));

        // Reload numbers table
        reloadNumbers();

        // Close add numbers dialog
        setIsAddNumbersDialogOpen(false);
    };

    // Handle cancel button on removal dialog
    const handleRemoveDialogCancel = () => {
        setIsRemoveNumbersDialogOpen(false)
    }

    // Handle confirm button on removal dialog.
    const handleRemoveDialogConfirm = () => {
        if (selectedPlatform && selectedGroup && selectedNumbers.length > 0) {
            setRemovalDate(new Date());

            submitRemoval(selectedPlatform, selectedGroup, selectedNumbers);
        }
    };

    const removalMessage = useMemo(() => {
        return (selectedNumbers.length > 1)
            ? `Are you sure you want to remove these ${selectedNumbers.length} numbers?`
            : `Are you sure you want to remove this number?`;
    }, [selectedNumbers])

    // Handle error messages from removal dialog 
    useEffect(() => {
        if (removalValidationMessage !== null) {
            dispatch(appWindowAddNotification(removalValidationMessage, 'error'));
        }

        if (removalFatalMessage !== null) {
            dispatch(appWindowAddNotification(removalFatalMessage, 'error'));
        }

    }, [removalValidationMessage, removalFatalMessage, removalDate]);

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX" subtext="Numbers" />

            {!isFetchingOrganizations && organizations !== null &&
                <Paper className={classes.paper}>
                    <HostedPbxOrganizationForm
                        organizations={organizations}
                        selectedOrganizationId={selectedOrganizationId}
                        selectedAssignmentId={selectedAssignmentId}
                        onSelect={(orgId, asId) => {
                            setSelectedOrganizationId(orgId);
                            setSelectedAssignmentId(asId);
                        }
                    } />
                </Paper>
            }

            {selectedPlatform !== null && selectedGroup !== null &&
                <>
                    <SubHeading
                        groupId={selectedGroup}
                        selectedNumbers={selectedNumbers}
                        onAddNumbers={() => setIsAddNumbersDialogOpen(true)}
                        onRemoveNumbers={() => setIsRemoveNumbersDialogOpen(true)} />

                    <Paper className={classes.paper}>
                        {numbersPage !== null &&
                            <>
                                <SearchBar onSubmitSearch={searchSubmit} />
                                <NumberTable
                                    items={numbersPage.items}
                                    selectedNumbers={selectedNumbers}
                                    onToggle={handleNumberToggle}
                                />

                                {numbersPage !== null &&
                                    <Pagination
                                        totalItems={numbersPage.totalItems}
                                        pageSize={numbersPage.pageSize}
                                        page={numbersPage.page}
                                        disabled={isFetchingNumbers}
                                        setParams={false}
                                        onChange={p => setPageNumber(p)} />
                                }
                            </>
                        }

                        {isFetchingNumbers && <LinearProgress variant="indeterminate" color="primary" />}
                    </Paper>

                <AddNumbersDialog
                        platform={selectedPlatform}
                        groupId={selectedGroup}
                        isOpen={isAddNumbersDialogOpen}
                        onCancel={handleAddDialogCancel}
                        onComplete={handleAddDialogComplete}
                    />
                </>
            }

            <ConfirmationDialog
                isOpen={isRemoveNumbersDialogOpen}
                title="Remove Numbers"
                message={removalMessage}
                secondary={selectedNumbers.sort().join(", ")}
                showWorking={isSubmittingRemoval}
                onCancel={handleRemoveDialogCancel}
                onConfirm={handleRemoveDialogConfirm}
            />
        </div>
    );
};


export default withPolicyRestriction(NumberIndexPage, HostedPbxPolicies.CanManageNumbers);