import { Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { DubPoint } from '../api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    dubPointOption: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    dubPointSubText: {
        fontSize: '0.9rem'
    }
}));

export interface FormValues {
    listener: boolean;
    dubPointIds: string[];
}

interface Props {
    dubPointOptions: DubPoint[];
    formValues: FormValues;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onUpdate: (field: keyof FormValues, value: boolean | string[]) => void;
}

const UpdateTeamMemberForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, errorMessage, fieldErrorMessages, onUpdate, dubPointOptions } = props;

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <Grid container direction="row" spacing={2}>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="listener"
                                checked={formValues.listener}
                                disabled={isSubmitting}
                                onChange={evt => onUpdate('listener', evt.target.checked)}
                                value="1"
                                color="primary"
                            />
                        }
                        label="User can listen to team recordings" />

                    <ValidationMessage field="listener" />
                </Grid>


                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth error={!isValid('dubPointIds')}>
                        <FormLabel>Dub Points</FormLabel>
                        {dubPointOptions.length === 0
                            ? <Typography>User has no Dub Points assigned.</Typography>
                            : <FormGroup>
                                {dubPointOptions.map(dp => {
                                    return (
                                        <FormControlLabel
                                            key={dp.id}
                                            control={
                                                <Checkbox
                                                    name="roles"
                                                    color="primary"
                                                    checked={formValues.dubPointIds.indexOf(dp.id) > -1}
                                                    disabled={isSubmitting}
                                                    onChange={evt => {
                                                        if (evt.target.checked) {
                                                            onUpdate('dubPointIds', [
                                                                ...formValues.dubPointIds,
                                                                dp.id
                                                            ]);
                                                        } else {
                                                            onUpdate('dubPointIds', formValues.dubPointIds.filter(v => v !== dp.id));
                                                        }
                                                    }}
                                                    value={dp.id} />
                                            }
                                            label={
                                                <div className={classes.dubPointOption}>
                                                    <Typography variant="body1">{dp.id}</Typography>
                                                    <Typography className={classes.dubPointSubText}>{dp.externalIdentifier}</Typography>

                                                </div>
                                            } />
                                    );
                                })}
                            </FormGroup>
                        }
                        <ValidationMessage field="dubPointIds" />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default UpdateTeamMemberForm;