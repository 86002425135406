import { FormControl, Input, InputLabel, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../../api';
import { useValidationHelpers } from '../../../../form-helpers';
import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: FormData;
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    onFieldChange: (fieldName: keyof FormData, value: string) => void;
    onFieldKeyDown: (evt: React.KeyboardEvent) => void;
}

export interface FormData {
    forwardedNumber: string;
    customUser: string;
}

const RoutingForm = (props: Props) => {
    const classes = useStyles();
    const { formValues, isSubmitting, onFieldChange, onFieldKeyDown, errorMessage, fieldErrorMessages } = props;
    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    return (
        <>
            {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}

            <FormControl className={classes.formControl} fullWidth error={!isValid('forwardedNumber')}>
                <InputLabel htmlFor="forwardedNumber">Forwarded Number</InputLabel>
                <Input
                    value={formValues.forwardedNumber || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('forwardedNumber', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="forwardedNumber"
                    type="text" />

                <ValidationMessage field="forwardedNumber" />
            </FormControl>

            {/*
            <FormControl className={classes.formControl} fullWidth error={!isValid('customUser')}>
                <InputLabel htmlFor="customUser">Custom User</InputLabel>
                <Input
                    value={formValues.customUser || ''}
                    disabled={isSubmitting}
                    onChange={evt => onFieldChange('customUser', evt.target.value)}
                    onKeyDown={onFieldKeyDown}
                    id="customUser"
                    type="text" />

                <ValidationMessage field="customUser" />
            </FormControl>
            */}
        </>
    );
};

export default RoutingForm;