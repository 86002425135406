import { useEffect, useState } from "react";
import { FieldError, ResponseError, useApi } from "../../../api";
import history from '../../../history';
import { loginUrl } from "../../auth/urls";
import { Account, fetchBandwidthAccount, fetchBandwidthAccounts } from './api';

type TriggeredFetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/**
 * Hook for fetching Bandwidth accounts
 */
export const useFetchBandwidthAccounts = (): TriggeredFetchHookResponse<() => void, Account[]> => {
    interface Request {
        ts: number;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [accounts, setAccounts] = useState<Account[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = () => {
        setRequest({
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setAccounts(null);

                try {
                    const accounts = await fetchBandwidthAccounts(api);

                    if (!didCancel) {
                        setIsFetching(false);
                        setAccounts(accounts);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setAccounts(null);
                            setError('Unable to fetch accounts.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        accounts,
        error
    ];
};

/**
 * Hook for fetching Bandwidth account
 */
export const useFetchBandwidthAccount = (): TriggeredFetchHookResponse<(accountId: string) => void, Account> => {
    interface Request {
        ts: number;
        accountId: string;
    }

    const api = useApi();
    const [request, setRequest] = useState<Request | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [account, setAccount] = useState<Account | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (accountId: string) => {
        setRequest({
            accountId,
            ts: Date.now()
        });
    };

    useEffect(() => {
        if (request !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setAccount(null);

                try {
                    const response = await fetchBandwidthAccount(api, request.accountId);

                    if (!didCancel) {
                        setIsFetching(false);
                        setAccount(response);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setAccount(null);
                            setError('Unable to fetch account.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [request]);

    return [
        fetch,
        isFetching,
        account,
        error
    ];
};