import { Block, Check } from '@mui/icons-material';
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon, ListItemText, Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { Assignment, CancelCheck } from '../api';
import { useCancelCheckDirectRoutingAssignment, useCancelDirectRoutingAssignment } from './hooks';

interface Props {
    isOpen: boolean;
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    onCancel: () => void;
    onComplete: () => void;
}

/**
 * Displays a dialog box that allows a manager to cancel Direct Routing services for an organization
 *
 * @param props
 * @constructor
 */
const CancelDialog = (props: Props) => {
    const {
        isOpen,
        organizationId,
        organizationName,
        assignmentId,
        onCancel,
        onComplete
    } = props;

    const [cancelCheck, isChecking, checkResults, checkErrors] = useCancelCheckDirectRoutingAssignment();
    const [cancel, isCancelling, isCancelled, cancelErrors] = useCancelDirectRoutingAssignment();

    // On open, check if the ipilot account can be removed
    useEffect(() => {
        if (isOpen) {
            cancelCheck(organizationId, assignmentId);
        }
    }, [assignmentId, isOpen])

    const handleConfirmButton = () => {
        cancel(organizationId, assignmentId);
    };

    const handleCancelButton = () => {
        onCancel();
    };

    // Listen for cancel completion, then trigger parent handler to close the dialog
    useEffect(() => {
        if (isCancelled) {
            onComplete();
        }
    }, [isCancelled]);

    const error = checkErrors || cancelErrors;

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={onCancel}
            TransitionProps={{
                onExited: onCancel
            }}
            aria-labelledby="cancellation-dialog-title">
            <DialogTitle id="cancellation-dialog-title">Cancel Service</DialogTitle>
            <DialogContent>
                <Typography>
                    You are about to cancel Direct Routing service for <strong>{organizationName}</strong>. This will remove the iPilot Customer for this organization and cannot be undone.</Typography>

                {error && <Typography variant="body1" color="error">{error}</Typography>}

                {!isChecking && checkResults &&
                    <List>
                        <IPilotListItem isChecking={isChecking}  checkResults={checkResults} />
                    </List>
                }
            </DialogContent>

            {!isChecking && checkResults &&
                <DialogActions>
                    <Button onClick={handleCancelButton} color="primary" disabled={isCancelling}>Close</Button>
                    <Button onClick={handleConfirmButton} color="secondary" autoFocus disabled={isCancelling || !checkResults.success}>
                        Confirm Removal
                    </Button>
                </DialogActions>
            }

            {(isChecking || isCancelling) && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

// Provides a generic list item to be used to display what items get affected during a service cancellation

type listItemStatus = 'pending' | 'success' | 'failure';

interface ServiceListItemProps {
    serviceName: string;
    text: React.ReactNode;
    status: listItemStatus
}

const ActionListItem = (props: ServiceListItemProps) => {
    return (
        <ListItem>
            {props.status == "pending" && <ListItemIcon><CircularProgress size={24} /></ListItemIcon>}
            {props.status == "success" && <ListItemIcon><Check color="primary" /></ListItemIcon>}
            {props.status == "failure" && <ListItemIcon><Block color="error" /></ListItemIcon>}
            <ListItemText primary={props.serviceName} secondary={props.text} />
        </ListItem>
    );
};

// List item showing how the IPilot customer details and if it can be removed.

interface IPilotListItemProps {
    isChecking: boolean;
    checkResults: CancelCheck;
}

const IPilotListItem = (props: IPilotListItemProps) => {
    const { isChecking, checkResults } = props;

    let status: listItemStatus = 'pending';

    if (!isChecking && checkResults) {
        status = checkResults.success ? 'success' : 'failure';
    }

    return (
        <ActionListItem serviceName="IPilot Customer" status={status} text={
            <>
                {!isChecking
                    ? <>
                        {!checkResults.accountExists
                            ? <>Customer has already been deleted.</>
                            : (!checkResults.success
                                ? <><strong>{checkResults.accountName}</strong> cannot be removed because it still has <strong>{checkResults.numberCount}</strong> numbers in service.</>
                                : <><strong>{checkResults.accountName}</strong> will be removed.</>
                            )
                        }
                    </>
                    : <>Retrieving information...</>
                }
            </>
        } />
    );
};

export default CancelDialog;