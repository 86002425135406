import { ApiCaller } from '../../api';

export * from './assignment/api';
export * from './organizations/api';

/*
 * GET /api/sip-trunking/bandwidth-accounts
 *
 * Gets all accounts in Bandwidth used for SIP Trunking
 */

export interface Account {
    id: string;
    name: string;
}

export const fetchSipTrunkingBandwidthAccounts = async (api: ApiCaller): Promise<Account[]> => {
    return await api<Account[]>(`sip-trunking/bandwidth-accounts`, 'GET');
};

/*
 * GET /api/sip-trunking/bandwidth-subaccounts
 *
 * Gets all sub accounts in Bandwidth for the SIP Trunking account
 */

export interface SubAccount {
    id: string;
    accountId: string;
    name: string;
    description: string | null;
}

export const fetchSipTrunkingBandwidthSubAccounts = async (api: ApiCaller): Promise<SubAccount[]> => {
    return await api<SubAccount[]>(`sip-trunking/bandwidth-subaccounts`, 'GET');
};

/*
 * GET /api/sip-trunking/bandwidth-subaccounts/{peerId}
 *
 * Get details about a Bandwidth peer, including total locations and numbers
 */

export interface SubAccountDetails {
    id: string;
    name: string;
    description: string | null;
    numberCount: number;
    locationCount: number;
}

export const fetchSipTrunkingBandwidthSubAccount = async (api: ApiCaller, accountId: string, peerId: string): Promise<SubAccountDetails> => {
    return await api<SubAccountDetails>(`sip-trunking/bandwidth-subaccounts/${accountId}/${peerId}`, 'GET');
};

