import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from "../../../api";
import theme from '../../../theme';
import { Seat } from '../api';
import StatementFormGenericLocation from './StatementFormGenericLocation';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    isSubmitting: boolean;
    onChange: (seatId: number, field: 'pagesIn' | 'pagesOut', value: string) => void;
    seats: Seat[];
    seatPageValues: PageValues[];
    seatErrors: SeatError[];
}

export interface PageValues {
    seatId: number;
    pagesIn: string;
    pagesOut: string;
}

export interface SeatError extends FieldError {
    seatId: number;
}

/**
 * Form for entering page in and page out counts for a statement
 * @param props
 */
export const StatementForm = (props: Props) => {
    const classes = useStyles();
    const { isSubmitting, onChange, seats, seatErrors, seatPageValues } = props;

    return (
        <div className={classes.root}>
            <StatementFormGenericLocation
                isSubmitting={isSubmitting}
                onChange={onChange}
                seats={seats}
                seatPageValues={seatPageValues}
                seatErrors={seatErrors}
            />
        </div>
    );
};

export default StatementForm;