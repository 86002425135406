import { Block, Check } from '@mui/icons-material';
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon, ListItemText, Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { SubAccountDetails } from '../api';
import { useCancelSipTrunkingAssignment, useFetchSipTrunkingBandwidthSubAccount } from '../hooks';

interface Props {
    isOpen: boolean;
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    bandwidthSubAccountId: string;
    bandwidthAccountId: string;
    onCancel: () => void;
    onComplete: () => void;
}

/**
 * Displays a dialog box that allows a manager to cancel SIP Trunking services for an organization
 *
 * @param props
 * @constructor
 */
const CancelDialog = (props: Props) => {
    const {
        isOpen,
        organizationId,
        organizationName,
        assignmentId,
        bandwidthAccountId,
        bandwidthSubAccountId,
        onCancel,
        onComplete
    } = props;

    const { fetch: fetchSubAccount, isFetching: isFetchingSubAccount, account, error: fetchSubAccountError, errorCode: fetchSubAccountErrorCode } = useFetchSipTrunkingBandwidthSubAccount();
    const { cancel, isCancelling, isCancelled, validationMessage, failureMessage } = useCancelSipTrunkingAssignment();

    const handleConfirm = () => {
        cancel(organizationId, assignmentId);
    };

    const subAccountRemoved = fetchSubAccountErrorCode === 404;
    const qualifiedForDeletion = subAccountRemoved || account?.numberCount === 0;

    useEffect(() => {
        if (isOpen) {
            fetchSubAccount(bandwidthAccountId, bandwidthSubAccountId);
        }
    }, [isOpen, assignmentId, organizationId]);

    // Listen for cancel completion, then trigger parent handler to close the dialog
    useEffect(() => {
        if (isCancelled) {
            onComplete();
        }
    }, [isCancelled]);

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={onCancel}
            TransitionProps={{
                onExited: onCancel
            }}
            aria-labelledby="cancellation-dialog-title">
            <DialogTitle id="cancellation-dialog-title">Cancel Service</DialogTitle>
            <DialogContent>

                <Typography>
                    You are about to cancel SIP Trunking service for <strong>{organizationName}</strong>. This will remove the Bandwidth Sub-Account for this organization and cannot be undone.</Typography>

                {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

                {failureMessage && <Typography variant="body1" color="error">{failureMessage}</Typography>}

                <List>
                    <BandwidthListItem isFetching={isFetchingSubAccount} isRemoved={subAccountRemoved} subAccount={account} />
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel} color="primary" disabled={isCancelling}>Close</Button>
                <Button onClick={handleConfirm} color="secondary" autoFocus disabled={isCancelling || !qualifiedForDeletion}>
                    Confirm Removal
                </Button>
            </DialogActions>

            {isCancelling && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

// Provides a generic list item to be used to display what items get affected during a service cancellation

type listItemStatus = 'pending' | 'success' | 'failure';

interface ServiceListItemProps {
    serviceName: string;
    text: React.ReactNode;
    status: listItemStatus
}

const ActionListItem = (props: ServiceListItemProps) => {
    return (
        <ListItem>
            {props.status == "pending" && <ListItemIcon><CircularProgress size={24} /></ListItemIcon>}
            {props.status == "success" && <ListItemIcon><Check color="primary" /></ListItemIcon>}
            {props.status == "failure" && <ListItemIcon><Block color="error" /></ListItemIcon>}
            <ListItemText primary={props.serviceName} secondary={props.text} />
        </ListItem>
    );
};

// List item showing how the Bandwidth sub-account details and if it can be removed.

interface BandwidthListItemProps {
    isFetching: boolean;
    isRemoved: boolean;
    subAccount: SubAccountDetails | null;
}

const BandwidthListItem = (props: BandwidthListItemProps) => {
    const { isFetching, isRemoved, subAccount } = props;

    let status: listItemStatus = 'pending';

    if (!isFetching) {
        if (isRemoved) {
            status = 'success';
        } else if (subAccount) {
            status = subAccount.numberCount > 0 ? 'failure' : 'success';
        }
    }

    return (
        <ActionListItem serviceName="Bandwidth Sub-Account" status={status} text={
            <>
                {!isFetching
                    ? <>
                        {isRemoved
                            ? <>Sub-Account has already been deleted.</>
                            : subAccount && <>
                                {subAccount.numberCount > 0
                                    ? <><strong>{subAccount.id} - {subAccount.name}</strong> ({subAccount.locationCount} locations) cannot be removed because it still has <strong>{subAccount.numberCount}</strong> numbers in service.</>
                                    : <><strong>{subAccount.id} - {subAccount.name}</strong> ({subAccount.locationCount} locations) will be removed.</>
                                }
                            </>
                        }
                    </>
                    : <>Retrieving information...</>
                }
            </>
        } />
    );
};

export default CancelDialog;