/*
 * GET /api/hosted-pbx/9line/{assignmentId}
 *
 * Get report of current state of 9Line setup for a customer
 */

import { ApiCaller } from "../../../api";

export interface NineLineDevice {
    name: string;
    mac: string;
    type: string;
    owner: NineLineDeviceUser | null;
    inSync: boolean;
    users: NineLineDeviceUser[];
    emergencyLocation: NineLineLocation | null;
    ownerEmergencyLocation: NineLineLocation | null;
    recommendedEmergencyLocation: NineLineLocation | null;
}

export interface NineLineDeviceUser {
    userId: string;
    linePortId: string;
    firstName: string;
    lastName: string;
    extension: string | null;
    department: string | null;
    isPrimary: boolean;
    location: NineLineLocation | null;
}

export interface NineLineLocation {
    id: string;
    name: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    postalCode: string;
}

export interface NineLineReport {
    lastSync: string;
    devices: NineLineDevice[];
    departments: string[];
    emergencyLocations: NineLineLocation[];
}

export const fetchNineLineReport = async (api: ApiCaller, assignmentId: number): Promise<NineLineReport> => {
    return await api<NineLineReport>(`hosted-pbx/9line/${assignmentId}`);
};

export interface AssignmentEntry {
    deviceName: string;
    locationId: string;
}

export interface AssignmentForm {
    changes: AssignmentEntry[];
}

export interface AssignmentResults {
    assigned: string[];
    failures: {
        deviceName: string;
        message: string;
    }[];
}

export const assignNineLineLocations = async (api: ApiCaller, assignmentId: number, form: AssignmentForm): Promise<AssignmentResults> => {
    return await api<AssignmentResults>(`hosted-pbx/9line/${assignmentId}`, 'POST', {
        ...form
    });
};