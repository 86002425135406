import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import theme from '../../../../theme';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { useFetchBandwidthAccount, useFetchBandwidthAccounts, useFetchBandwidthLocation, useFetchBandwidthSubAccount } from '../../hooks';
import HistoryNotesTable from '../HistoryNotesTable';
import { useFetchBandwidthMoveNumberOrder } from './hooks';
import MoveNumberSummary from './MoveNumberSummary';
import NumberTable from './NumberTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    summary: {
        marginBottom: theme.spacing(2)
    },
    section: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
}));

interface Props extends RouteComponentProps<any> {

}

// Page for viewing a Move Number order
const MoveNumberViewPage = (props: Props) => {
    const classes = useStyles();

    const accountId = props.match.params['accountId'] || null;
    const orderId = props.match.params['orderId'] || null;

    const [fetchOrder, isFetchingOrder, order, fetchError] = useFetchBandwidthMoveNumberOrder();
    const [fetchAccounts, isFetchingAccounts, accounts, fetchAccountsError] = useFetchBandwidthAccounts();

    const [fetchSubAccount, isFetchingSubAccount, subAccount, fetchSubAccountError] = useFetchBandwidthSubAccount();
    const [fetchLocation, isFetchingLocation, location, fetchLocationError] = useFetchBandwidthLocation();

    const hasSourceAccount = order?.sourceAccountId ? true : false;

    // Update navigation
    useNav('bandwidth', 'orders');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingOrder || isFetchingSubAccount || isFetchingLocation || (hasSourceAccount && isFetchingAccounts),
        fetchError || fetchSubAccountError || fetchLocationError || fetchAccountsError
    );

    // Fetch accounts on load
    useEffect(() => {
        fetchOrder(accountId, orderId);
    }, [accountId, orderId]);

    // Fetch subaccount & location when order is loaded
    useEffect(() => {
        if (order) {
            fetchAccounts();
            fetchSubAccount(accountId, order.subAccountId);
            fetchLocation(accountId, order.subAccountId, order.locationId);
        }
    }, [order]);

    const sourceAccountLoaded = useMemo(() => {
        if (!order) {
            return false;
        }

        if (!order.sourceAccountId) {
            return true;
        }

        return !isFetchingAccounts && accounts;
    }, [accounts, isFetchingAccounts, order]);

    const sourceAccount = useMemo(() => {
        if (!order || !accounts) {
            return null;
        }

        if (order.sourceAccountId) {
            return accounts.find(a => a.id == order.sourceAccountId)?.name || order.sourceAccountId;
        } else {
            return null;
        }
    }, [accounts, order]);

    const numberItems = useMemo(() => {
        let items = [];

        if (order) {
            for (var i = 0; i < order.numbers.length; i++) {
                const number = order.numbers[i];
                const error = order.errors.find(e => e.numbers.indexOf(number) > -1)?.description || null;

                items.push({
                    number: number,
                    status: error ? 'Failed' : (order.status.indexOf('Complete') > -1 || order.status.indexOf('Partial') > -1 ? 'Complete' : 'Processing'),
                    notes: error
                });
            }
        }

        return items;
    }, [order]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Order" subtext={orderId} />

            {!isFetchingOrder && order && !isFetchingSubAccount && subAccount && !isFetchingLocation && location && sourceAccountLoaded &&
                <>
                    <div className={classes.summary}>
                        <MoveNumberSummary
                            accountId={accountId}
                            order={order}
                            sourceAccount={sourceAccount}
                            subAccount={subAccount}
                            location={location} />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">Numbers</Typography>
                        <Typography variant="body1">{order.numbers.length} total</Typography>

                        <Paper className={classes.paper}>
                            <NumberTable items={numberItems} />
                        </Paper>
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h3">History & Notes</Typography>
                        <Paper className={classes.paper}>
                            <HistoryNotesTable
                                history={order.history}
                                notes={order.notes}
                            />
                        </Paper>
                    </div>
                </>

            }
        </div>
    );
};

export default MoveNumberViewPage;