import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { useFetchDubberDubPoints, useFetchDubberTeam, useFetchDubberTeamMember, useFetchDubberTeamMembers, useUpdateDubberTeamMember } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberTeamMemberViewUrl } from '../urls';
import UpdateTeamMemberForm, { FormValues } from './UpdateTeamMemberForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const TeamMemberUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const teamId = props.match.params['teamId'];
    const userId = props.match.params['userId'];

    const [fetchTeam, isFetchingTeam, team, fetchTeamError] = useFetchDubberTeam();
    const [fetchDubPoints, isFetchingDubPoints, dubPoints, fetchDubPointsError] = useFetchDubberDubPoints();
    const [fetchTeamMember, isFetchingTeamMember, teamMember, fetchTeamMemberError] = useFetchDubberTeamMember();

    const [update, isUpdating, updatedMember, updateMemberError, updateMemberFieldErrors] = useUpdateDubberTeamMember();

    const [formValues, setFormValues] = useState<FormValues>({
        listener: false,
        dubPointIds: []
    });

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingTeam || isFetchingTeamMember || isFetchingDubPoints,
        fetchTeamError || fetchTeamMemberError || fetchDubPointsError
    );

    // Fetch member details on load
    useEffect(() => {
        fetchTeam(groupId, accountId, teamId);
        fetchDubPoints(groupId, accountId);
        fetchTeamMember(groupId, accountId, teamId, userId);
    }, [accountId, teamId, userId]);

    // Update default form values after loading
    useEffect(() => {
        if (teamMember && dubPoints) {
            setFormValues({
                listener: teamMember.listener,
                dubPointIds: teamMember.dubPoints.map(d => d.dubPoint)
            });
        }
    }, [teamMember, dubPoints]);

    const dubPointOptions = useMemo(() => {
        if (dubPoints) {
            return dubPoints.filter(d => d.user === userId);
        } else {
            return [];
        }
    }, [dubPoints]);

    const handleUpdate = (field: keyof FormValues, value: boolean | string[]) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    // Press cancel goes back to the team member view
    const handleCancel = () => {
        history.push(dubberTeamMemberViewUrl(groupId, accountId, teamId, userId));
    };

    const handleSubmit = () => {
        update(groupId, accountId, teamId, userId, {
            listener: formValues.listener,
            dubPointIds: formValues.dubPointIds
        });
    };

    // Redirect back to view once updated
    useEffect(() => {
        if (updatedMember) {
            dispatch(appWindowAddNotification('Team Member updated.', 'success'));
            history.push(dubberTeamMemberViewUrl(groupId, accountId, teamId, userId));
        }
    }, [updatedMember]);

    return (
        <div className={classes.root}>
            {team !== null && dubPoints !== null && teamMember
                && <>
                    <PageHeader text="Update Team Member" subtext={`${teamMember.firstName} ${teamMember.lastName}`} />

                    <Paper className={classes.paper}>
                        <UpdateTeamMemberForm
                            dubPointOptions={dubPointOptions}
                            formValues={formValues}
                            isSubmitting={isUpdating}
                            onUpdate={handleUpdate}
                            errorMessage={updateMemberError}
                            fieldErrorMessages={updateMemberFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(TeamMemberUpdatePage, DubberPolicies.CanManageTeams);