import { Block, Check } from '@mui/icons-material';
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon, ListItemText, Typography
} from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BadRequestError, ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { appWindowAddNotification } from '../../app-window/actions';
import { loginUrl } from '../../auth/urls';
import { cancelHostedPbxAssignment, DubberAccountDetails, fetchHostedPbxBandwidthSubAccount, fetchHostedPbxDubberAccount, fetchHostedPbxGroup, Group, SubAccount } from '../api';
import { hpbxAssignmentViewUrl } from '../urls';

interface FormHandlerResult {
    isSubmitting: boolean;
    validationMessage: string | null;
    failureMessage: string | null;
    onSubmit: () => void;
}

/**
 * Hook for handling the submission of the cancellation dialog
 * @param organizationId
 * @param assignmentId
 * @param onSuccess
 */
const useFormHandler = (organizationId: number, assignmentId: number, onSuccess: () => void): FormHandlerResult => {
    const api = useApi();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    let didCancel = false;

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, [organizationId, assignmentId]);

    // Handle submit
    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);

        try {
            await cancelHostedPbxAssignment(api, organizationId, assignmentId);

            if (!didCancel) {
                onSuccess();
            }
        } catch (e) {
            if (!didCancel) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, we'll either display error messages under the fields
                    // or display a generic error message if no field errors are listed
                    setValidationMessage(e.message);
                } else if ((e instanceof ResponseError) && (e.code === 500)) {
                    setFailureMessage(e.message);
                    setValidationMessage(null);
                } else {
                    // For generic errors, display a generic error message
                    setFailureMessage('Unable to cancel service.');
                    setValidationMessage(null);
                }

                setIsSubmitting(false);
            }
        }
    }, [organizationId, didCancel, onSuccess]);

    return {
        isSubmitting,
        validationMessage,
        failureMessage,
        onSubmit: handleSubmit
    };
};


interface Props {
    isOpen: boolean;
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    broadworksGroupId: string,
    bandwidthSubAccountId: string;
    dubberGroupId: string;
    dubberAccountId: string | null;
    onCancel: () => void;
}

/**
 * Displays a dialog box that allows a manager to cancel Hosted PBX services for an organization
 *
 * @param props
 * @constructor
 */
const CancelDialog = (props: Props) => {
    const api = useApi();
    const dispatch = useDispatch();

    const {
        isOpen,
        organizationId,
        organizationName,
        assignmentId,
        broadworksGroupId,
        bandwidthSubAccountId,
        dubberGroupId,
        dubberAccountId,
        onCancel
    } = props;

    const [bandwidthAccount, setBandwidthAccount] = useState<SubAccount | undefined | null>(undefined);
    const [broadworksGroup, setBroadworksGroup] = useState<Group | undefined | null>(undefined);
    const [dubberAccount, setDubberAccount] = useState<DubberAccountDetails | undefined | null>(undefined);

    const [qualifiesForDeletion, setQualifiedForDeletion] = useState(false);

    const {
        isSubmitting,
        validationMessage,
        failureMessage,
        onSubmit
    } = useFormHandler(organizationId, assignmentId, () => {
        dispatch(appWindowAddNotification('Service cancelled.', 'success'));

        // Hack for refreshing the current route
        history.push({ pathname: "/refresh" });
        history.replace(hpbxAssignmentViewUrl(organizationId, assignmentId));
    });


    const handleCancelButton = () => {
        onCancel();
    };

    const handleConfirm = () => {
        if (!isSubmitting) {
            onSubmit();
        }
    };

    // When the dialog fully closes, reset state 
    const handleExited = () => {
        setBandwidthAccount(undefined);
        setBroadworksGroup(undefined);
        setDubberAccount(undefined);

        setQualifiedForDeletion(false);
    };

    useEffect(() => {
        let didCancel = false;

        (async () => {
            if (isOpen) {
                var groupResponse
                var subaccountResponse;
                var dubberResponse;

                // Retrieve group name w/ seat count
                try {
                    groupResponse = await fetchHostedPbxGroup(api, 'Averistar', broadworksGroupId);
                } catch (e) {
                    // Setting group to null means the group was already deleted
                    if (e instanceof ResponseError && e.code === 404) {
                        groupResponse = null;
                    }
                }

                // Retrieve bandwidth account name and number count
                try {
                    subaccountResponse = await fetchHostedPbxBandwidthSubAccount(api, bandwidthSubAccountId);
                } catch (e) {
                    // Setting account to null means the account was already deleted
                    if (e instanceof ResponseError && e.code === 404) {
                        subaccountResponse = null;
                    }
                }

                // Retrieve Dubber account information if applicable
                if (dubberAccountId) {
                    try {
                        dubberResponse = await fetchHostedPbxDubberAccount(api, dubberAccountId);
                    } catch (e) {
                        // Setting account to null means the account was already deleted
                        if (e instanceof ResponseError && e.code === 404) {
                            dubberResponse = null;
                        }
                    }
                } else {
                    dubberResponse = null;
                }

                if (!didCancel) {
                    setBroadworksGroup(groupResponse);
                    setBandwidthAccount(subaccountResponse);
                    setDubberAccount(dubberResponse);

                    // For the cancel dialog to allow submission, bandwidth cannot have any inservice numbers and dubber account can't have any users
                    setQualifiedForDeletion(
                        (subaccountResponse == null || subaccountResponse.numberCount === 0)
                        && (dubberResponse == null || dubberResponse.userCount === 0));
                }
            }
        })();

        return () => {
            didCancel = true;
        }
    }, [isOpen, assignmentId, organizationId]);

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={handleCancelButton}
            TransitionProps={{
                onExited: handleExited
            }}
            aria-labelledby="cancellation-dialog-title">
            <DialogTitle id="cancellation-dialog-title">Cancel Service</DialogTitle>
            <DialogContent>

                <Typography>
                    You are about to cancel Hosted PBX service for <strong>{organizationName}</strong>.
                    This will remove the {dubberAccountId ? 'BroadWorks Group, Bandwidth Sub-Account, and Dubber Account' : 'BroadWorks Group and Bandwidth Sub-Account'} for this organization and cannot be undone.</Typography>

                {validationMessage && <Typography variant="body1" color="error">{validationMessage}</Typography>}

                {failureMessage && <Typography variant="body1" color="error">{failureMessage}</Typography>}

                <List>
                    <BroadWorksListItem group={broadworksGroup} />
                    <BandwidthListItem subaccount={bandwidthAccount} />
                    {dubberAccountId && <DubberListItem account={dubberAccount}/> }
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCancelButton} color="primary" disabled={isSubmitting}>Close</Button>
                <Button onClick={handleConfirm} color="secondary" autoFocus disabled={isSubmitting || !qualifiesForDeletion}>
                    Confirm Removal
                </Button>
            </DialogActions>

            {isSubmitting && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

// Provides a generic list item to be used to display what items get affected during a service cancellation

type listItemStatus = 'pending' | 'success' | 'failure';

interface ServiceListItemProps {
    serviceName: string;
    text: React.ReactNode;
    status: listItemStatus
}

const ActionListItem = (props: ServiceListItemProps) => {
    return (
        <ListItem>
            {props.status == "pending" && <ListItemIcon><CircularProgress size={24} /></ListItemIcon>}
            {props.status == "success" && <ListItemIcon><Check color="primary" /></ListItemIcon>}
            {props.status == "failure" && <ListItemIcon><Block color="error" /></ListItemIcon>}
            <ListItemText primary={props.serviceName} secondary={props.text} />
        </ListItem>
    );
};

// List item showing how the Bandwidth sub-account details and if it can be removed.

interface BandwidthListItemProps {
    subaccount: SubAccount | undefined | null;
}

const BandwidthListItem = (props: BandwidthListItemProps) => {
    const { subaccount } = props;

    let status: listItemStatus = 'pending';

    if (subaccount !== undefined) {
        // Bandwidth account shouldn't be deletable if it has active numbers
        if (subaccount == null) {
            status = 'success';
        } else {
            status = subaccount.numberCount > 0 ? 'failure' : 'success';
        }
    }

    return (
        <ActionListItem serviceName="Bandwidth Sub-Account" status={status} text={
            <>
                {subaccount !== undefined
                    ? <>
                        {subaccount === null
                            ? <>Sub-Account has already been deleted.</>
                            : <>
                                {subaccount.numberCount > 0
                                    ? <><strong>{subaccount.id} - {subaccount.name}</strong> ({subaccount.locationCount} locations) cannot be removed because it still has <strong>{subaccount.numberCount}</strong> numbers in service.</>
                                    : <><strong>{subaccount.id} - {subaccount.name}</strong> ({subaccount.locationCount} locations) will be removed.</>
                                }
                            </>
                        }
                    </>
                    : <>Retrieving information...</>
                }
            </>
        } />
    );
};

// List item showing the BroadWorks Group details and if it can be removed.

interface BroadWorksListItemProps {
    group: Group | undefined | null;
}

const BroadWorksListItem = (props: BroadWorksListItemProps) => {
    const { group } = props;

    // Nothing should prevent a group from being deleted
    const status = group === undefined
        ? 'pending'
        : 'success';

    return (
        <ActionListItem serviceName="BroadWorks Group" status={status} text={
            <>
                {group !== undefined
                    ? <>
                        {group === null
                            ? <> Group has already been deleted.</>
                            : <><strong>{group.id} - {group.name}</strong> ({group.userCount} seats, {group.departmentCount} departments) will be removed.</>
                        }
                    </>
                    : <>Retrieving information...</>
                }
            </>
        } />
    );
};

// List item showing the Dubber Account details and if it can be removed.

interface DubberListItemProps {
    account: DubberAccountDetails | undefined | null;
}

const DubberListItem = (props: DubberListItemProps) => {
    const { account } = props;
    let status: listItemStatus = 'pending';

    // Dubber account blocks deletion if it still has users
    if (account !== undefined) {
        if (account == null) {
            status = 'success';
        } else {
            status = account.userCount > 0 ? 'failure' : 'success';
        }
    }

    return (
        <ActionListItem serviceName="Dubber Account" status={status} text={
            <>
                {account !== undefined
                    ? <>
                        {account === null
                            ? <> Account has already been deleted.</>
                            : <>
                                {account.userCount > 0 
                                    ? <><strong>{account.id} - {account.name}</strong> still has <strong>{account.userCount}</strong> users. Delete all users before proceeding.</>
                                    : <><strong>{account.id} - {account.name}</strong> is ready.</>
                                }
                            </>
                        }
                    </>
                    : <>Retrieving information...</>
                }
            </>
        } />
    );
};

export default CancelDialog;