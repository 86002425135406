import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { dubberTeamMemberViewUrl } from '../urls';
import { TeamMemberIndex } from './api';

interface Props {
    groupId: string;
    accountId: string;
    teamId: string;
    members: TeamMemberIndex[];
}

export default function TeamMemberTable(props: Props) {
    const { groupId, accountId, teamId, members } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Listener</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {members.map(member => {
                    const LinkComponent = React.forwardRef((props, ref) => <RouterLink to={dubberTeamMemberViewUrl(groupId, accountId, teamId, member.user)} {...props} />);
                    const name = member.firstName ? `${member.firstName} ${member.lastName}` : member.user;

                    return (
                        <TableRow key={member.user}>
                            <TableCell component="th" scope="row">
                                <Link component={LinkComponent}>{name}</Link>
                            </TableCell>
                            <TableCell>{member.listener ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}