import { useEffect, useState } from 'react';
import { PagedResponse, ResponseError, useApi } from '../../../api';
import history from '../../../history';
import { loginUrl } from '../../auth/urls';
import { Platform } from '../api';
import { EligibleDevice, enableZeroTouch, fetchPolyZeroTouch, fetchYealinkZeroTouch, fetchZeroTouchEligibleDevices, searchPolyZeroTouch, searchYealinkZeroTouch, ZeroTouchView } from './api';

type FetchHookResponse<R, S> = [
    R,
    boolean,
    S | null,
    string | null
];

/**
 * Hook for retrieving ZT status for Poly device
 */

export const useFetchPolyZeroTouch = (): FetchHookResponse<(mac: string) => void, ZeroTouchView | false> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [device, setDevice] = useState<ZeroTouchView | false | null>(null);
    const [mac, setMac] = useState<string | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (mac: string) => {
        setMac(mac);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (mac !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDevice(null);
                setError(null);

                try {
                    const response = await fetchPolyZeroTouch(api, mac);

                    if (!didCancel) {
                        setDevice(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        // 404 error indicates that the device is not setup for ZT
                        } else if ((e instanceof ResponseError) && (e.code === 404)) {
                            setIsFetching(false);
                            setDevice(false);
                            setError(null);
                        } else {
                            setIsFetching(false);
                            setDevice(null);
                            setError('Unable to retrieve ZT device.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [mac, requestTs]);

    return [
        fetch,
        isFetching,
        device,
        error
    ];
};

/**
 * Hook for retrieving ZT status for multiple Poly devices
 */

export const useSearchPolyZeroTouch = (): FetchHookResponse<(macs: string[]) => void, ZeroTouchView[] | false> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [devices, setDevices] = useState<ZeroTouchView[] | null>(null);
    const [macs, setMacs] = useState<string[] | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (macs: string[]) => {
        setMacs(macs);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (macs !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDevices(null);
                setError(null);

                try {
                    const response = await searchPolyZeroTouch(api, macs);

                    if (!didCancel) {
                        setDevices(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDevices(null);
                            setError('Unable to retrieve ZT devices.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [macs, requestTs]);

    return [
        fetch,
        isFetching,
        devices,
        error
    ];
};

/**
 * Hook for retrieving ZT status for Yealink device
 */

export const useFetchYealinkZeroTouch = (): FetchHookResponse<(mac: string) => void, ZeroTouchView | false> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [device, setDevice] = useState<ZeroTouchView | false | null>(null);
    const [mac, setMac] = useState<string | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (mac: string) => {
        setMac(mac);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (mac !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDevice(null);
                setError(null);

                try {
                    const response = await fetchYealinkZeroTouch(api, mac);

                    if (!didCancel) {
                        setDevice(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                            // 404 error indicates that the device is not setup for ZT
                        } else if ((e instanceof ResponseError) && (e.code === 404)) {
                            setIsFetching(false);
                            setDevice(false);
                            setError(null);
                        } else {
                            setIsFetching(false);
                            setDevice(null);
                            setError('Unable to retrieve ZT device.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [mac, requestTs]);

    return [
        fetch,
        isFetching,
        device,
        error
    ];
};

/**
 * Hook for retrieving ZT status for multiple Yealink devices
 */

export const useSearchYealinkZeroTouch = (): FetchHookResponse<(macs: string[]) => void, ZeroTouchView[] | false> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [devices, setDevices] = useState<ZeroTouchView[] | null>(null);
    const [macs, setMacs] = useState<string[] | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch = (macs: string[]) => {
        setMacs(macs);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (macs !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);
                setDevices(null);
                setError(null);

                try {
                    const response = await searchYealinkZeroTouch(api, macs);

                    if (!didCancel) {
                        setDevices(response);
                        setIsFetching(false);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                        } else {
                            setIsFetching(false);
                            setDevices(null);
                            setError('Unable to retrieve ZT devices.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [macs, requestTs]);

    return [
        fetch,
        isFetching,
        devices,
        error
    ];
};

/**
 * Hook for retrieving devices in a group that are eligible for ZTP
 */

type FetchEligibleDevicesCall = (platform: Platform, groupId: string) => void;

export const useFetchZeroTouchEligibleDevices = (): FetchHookResponse<FetchEligibleDevicesCall, EligibleDevice[]> => {
    const api = useApi();
    const [isFetching, setIsFetching] = useState(false);
    const [platform, setPlatform] = useState<Platform | null>(null);
    const [groupId, setGroupId] = useState<string | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);

    const [search, setSearch] = useState<string | undefined>(undefined);

    const [devices, setDevices] = useState<EligibleDevice[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetch: FetchEligibleDevicesCall = (platform: Platform, groupId: string) => {
        setPlatform(platform);
        setGroupId(groupId);
        setSearch(search);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (platform !== null && groupId !== null) {
            let didCancel = false;

            (async () => {
                setIsFetching(true);

                try {
                    const response = await fetchZeroTouchEligibleDevices(api, platform, groupId);

                    if (!didCancel) {
                        setDevices(response);
                        setIsFetching(false);
                        setError(null);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                            // 404 error indicates that the device is not setup for ZT
                        } else {
                            setIsFetching(false);
                            setDevices(null);
                            setError('Unable to retrieve eligible devices.');
                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [platform, groupId, requestTs]);

    return [
        fetch,
        isFetching,
        devices,
        error
    ];
};

/*
 * Hook for enabling ZTP on a BroadWorks device
 */

export const useEnableZeroTouch = (): [(platform: Platform, groupId: string, deviceName: string) => void, boolean, boolean, string | null] => {
    const api = useApi();
    const [isWorking, setIsWorking] = useState(false);
    const [platform, setPlatform] = useState<Platform | null>(null);
    const [groupId, setGroupId] = useState<string | null>(null);
    const [deviceName, setDeviceName] = useState<string | null>(null);
    const [requestTs, setRequestTs] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const enable = (platform: Platform, groupId: string, deviceName: string) => {
        setPlatform(platform);
        setGroupId(groupId);
        setDeviceName(deviceName);
        setRequestTs(Date.now);
    };

    useEffect(() => {
        if (platform !== null && groupId !== null && deviceName !== null) {
            let didCancel = false;

            (async () => {
                setIsWorking(true);
                setIsSuccess(false);
                setError(null);

                try {
                    const response = await enableZeroTouch(api, platform, groupId, deviceName);

                    if (!didCancel) {
                        setIsWorking(false);
                        setIsSuccess(true);
                    }
                } catch (e) {
                    if (!didCancel) {
                        // If the API returns a 401 error, then our session is not valid
                        // and we must take the user back to the login screen
                        if ((e instanceof ResponseError) && (e.code === 401)) {
                            history.push(loginUrl());
                            // 404 error indicates that the device is not setup for ZT
                        } else {
                            setIsWorking(false);
                            setIsSuccess(false);

                            if (e instanceof ResponseError) {
                                setError(e.message)
                            } else {
                                setError('Unable to enable ZTP for device');
                            }

                        }
                    }
                }
            })();

            return () => {
                didCancel = true;
            }
        }
    }, [platform, groupId, deviceName, requestTs]);

    return [
        enable,
        isWorking,
        isSuccess,
        error
    ];
};