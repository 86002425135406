import { FormControl, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { Customer } from '../api';
import { UpdateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    iPilotCustomerOptions: Customer[];
    formValues: UpdateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: UpdateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: UpdateAssignment): boolean => {
    return form.iPilotAccountId !== null;
};

/**
 * Direct Routing update assignment form
 * @param props
 * @constructor
 */
const UpdateAssignmentForm = (props: Props) => {
    const { organization, iPilotCustomerOptions, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const displayError = errorMessage;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            <FormControl className={classes.formControl} fullWidth required
                error={!isValid('iPilotAccountId')}>
                <InputLabel htmlFor="iPilotAccountId">iPilot Account</InputLabel>

                <Select
                    id="iPilotAccountId"
                    native={true}
                    margin="none"
                    disabled={isSubmitting}
                    value={formValues.iPilotAccountId || ''}
                    input={<Input name="bandwidthAccount" id="iPilotAccountId" />}
                    onChange={evt => setFormValues({
                        iPilotAccountId: evt.target.value ? parseInt(String(evt.target.value)) : null
                    })}>
                    <option key="" value="" />

                    {iPilotCustomerOptions.map(c => {
                        return <option key={c.id} value={c.id}>{c.name}</option>;
                    })}
                </Select>

                <ValidationMessage field="iPilotAccountId" />
            </FormControl>
        </>
    );
};

export default UpdateAssignmentForm;