import { Button, Grid, Paper, Typography } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../../constants';
import history from '../../../../history';
import theme from '../../../../theme';
import { appWindowAddNotification } from '../../../app-window/actions';
import { useNav, useProgressEffects } from '../../../app-window/hooks';
import { withPolicyRestriction } from '../../../auth/policies';
import { CreateBandwidthPortOutPasscodeTnOptionOrderForm } from '../../api';
import { useFetchBandwidthNumber } from '../../hooks';
import { useCreateBandwidthPortOutPasscodeTnOptionOrder } from '../../orders/hooks';
import { BandwidthPolicies } from '../../policies';
import { bandwidthNumberViewUrl } from '../../urls';
import { formatUsNumber } from '../helpers';
import PasscodeForm, { FormData } from './PasscodeForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for updating port-out passcode for a number
const PasscodeUpdatePage = (props: Props) => {
    const number = String(props.match.params['number']);
    const dispatch = useDispatch();

    const classes = useStyles();
    const [fetch, isFetching, numberDetails, fetchErrorMessage] = useFetchBandwidthNumber();

    const [formValues, setFormValues] = useState<FormData>({
        passcode: ''
    });

    const [originalFormValues, setOriginalFormValues] = useState<FormData>(formValues);

    const [update, isUpdating, completedOrder, updateError, updateFieldErrors] = useCreateBandwidthPortOutPasscodeTnOptionOrder();

    // Update navigation
    useNav('bandwidth', 'numbers');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        fetchErrorMessage
    );

    // Fetch number details on load
    useEffect(() => {
        fetch(number);
    }, [number]);

    // Update form when number was loaded
    useEffect(() => {
        if (numberDetails) {
            const baseFormValues: FormData = {
                passcode: numberDetails?.portOutPasscode?.passcode || ''
            };

            setFormValues(baseFormValues);
            setOriginalFormValues(baseFormValues);
        }
    }, [numberDetails]);

    // Handle field updates
    const handleFieldChange = (fieldName: keyof FormData, value: string) => {
        let newValues = Object.assign({}, formValues);

        newValues[fieldName] = value;

        setFormValues(newValues);
    };

    // Only allow the Update button to be active if the form has changed
    const formChanged = useMemo(() => {
        return formValues.passcode !== originalFormValues.passcode
    }, [formValues]);

    // Cancel button returns to number details
    const handleCancel = useCallback(() => {
        history.push(bandwidthNumberViewUrl(number));
    }, [number]);

    // Submit form is enter is pressed
    const handleKeyDown = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    // Handle submit form
    const handleSubmit = useCallback(() => {
        if (numberDetails) {
            // We only set the values that have changed.
            let submission: CreateBandwidthPortOutPasscodeTnOptionOrderForm = {
                numbers: [number],
                passcode: null
            };

            if (formValues.passcode != originalFormValues.passcode) {
                submission.passcode = formValues.passcode;
            }

            update(numberDetails.accountId, submission);
        }
    }, [formValues]);

    // Once an order is created, display a nice message and redirect back to the number details
    useEffect(() => {
        if (completedOrder) {
            dispatch(appWindowAddNotification(`Order ${completedOrder.id} created`, 'success'));
            history.push(bandwidthNumberViewUrl(number));
        }
    }, [completedOrder]);

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth Numbers" subtext={formatUsNumber(number)} />

            {numberDetails !== null
                && <>
                    <Paper className={classes.paper}>
                        <PasscodeForm
                            formValues={formValues}
                            isSubmitting={isUpdating}
                            errorMessage={updateError}
                            fieldErrorMessages={updateFieldErrors}
                            onFieldChange={handleFieldChange}
                            onFieldKeyDown={handleKeyDown}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdating}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdating || !formChanged}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(PasscodeUpdatePage, BandwidthPolicies.CanViewAndManageNumberPin);