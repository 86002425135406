import { PROVISIONING, SERVICE_MANAGER, SUPER_USER, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER } from '../administrators/api';

export enum DubberPolicies {
    CanCreateAndDeleteUsers = 'DubberCanCreateAndDeleteUsers',
    CanUpdateUsers = 'DubberCanUpdateUsers',
    CanManageTeams = 'DubberCanManageTeams',
    CanManageDubPoints = 'DubberCanManageDubPoints'
}

export const DubberPolicyRoles = {
    [DubberPolicies.CanCreateAndDeleteUsers]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [DubberPolicies.CanUpdateUsers]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [DubberPolicies.CanManageTeams]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER],
    [DubberPolicies.CanManageDubPoints]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
};