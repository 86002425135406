import { ApiCaller } from '../../../api';
import { AddressBase, LocationAddress } from '../api';
import { AddressType } from '../api';

export interface NumberPage {
    total: number;
    next: string | null;
    numbers: NumberBrief[];
}

export type NumberStatus = 'Inservice' | 'Available' | 'Aging' | 'PortInPendingFoc' | 'Reserved';

export interface NumberBrief {
    number: string;
    accountId: string;
    status: NumberStatus;
}

/**
 * GET /bandwidth/numbers
 * 
 * Fetch Bandwidth numbers
 * 
 * @param api
 * @param accountId
 * @param search
 * @param page
 * @param limit
 */
export const fetchBandwidthNumbers = async (api: ApiCaller, accountIds?: string, search?: string, page: string = "1", limit: number = 2500): Promise<NumberPage> => {
    return await api<NumberPage>(`bandwidth/numbers`, 'GET', {
        accountIds,
        search,
        page,
        limit
    });
};

export type Feature = 'E911' | 'LIDB';
export type FeatureStatus = 'Pending' | 'Success';

export interface Number extends NumberBrief {
    orderId: string;
    orderType: 'E911' | 'Lidb' | 'MoveNumber' | 'NewNumber' | 'PortIn' | 'TnOption' | 'ImportToAccount';
    orderCreateDate: string;
    lastModifiedDate: string;
    inServiceDate: string;
    city: string;
    state: string;
    lata: string;
    tier: number;
    rateCenter: string;
    subAccountId: string;
    subAccountName: string;
    locationId: string;
    locationName: string;
    locationDefault: boolean;
    features: Feature[];
    e911: E911 | null;
    lidb: Lidb | null;
    tnOptions: TnOptions;
    routing: Routing;
    portOutPasscode: PortOutPasscode;
}

export interface E911 {
    status: FeatureStatus;
    isAdjusted: boolean | null;
    name: string | null;
    address: E911ddress | null;
}

export interface Lidb {
    status: FeatureStatus;
    serviceType: 'Business' | 'Residential' | null;
    display: boolean | null;
    name: string | null; 
}

export interface TnOptions {
    callingNameDisplayed: boolean | null;
    failoverUri: string | null;
    nnid: number | null;
}

export interface Routing {
    forwardedNumber: string | null;
    customUser: string | null;
}

export interface PortOutPasscode {
    passcode: string | null;
}

export interface E911ddress extends AddressBase {
    county: string;
    latitude: string | null;
    longitude: string | null;
}

/**
 * GET /bandwidth/numbers/{number}
 * 
 * Get Bandwidth number details
 * 
 * @param api
 * @param number
 */
export const fetchBandwidthNumber = async (api: ApiCaller, number: string): Promise<Number> => {
    return await api<Number>(`bandwidth/numbers/${number}`, 'GET');
};