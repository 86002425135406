import { Button, FormControl, Grid, Input, InputLabel, Paper, Select, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { MobileDatePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldError, ResponseError, useApi } from '../../api';
import PageHeader from '../../components/PageHeader';
import { MAIN_WIDTH } from '../../constants';
import { useValidationHelpers } from '../../form-helpers';
import history from '../../history';
import theme from '../../theme';
import { useNav, useProgressEffects } from '../app-window/hooks';
import { withPolicyRestriction } from '../auth/policies';
import { loginUrl } from '../auth/urls';
import { useFetchHostedPbxGroups } from '../hosted-pbx/hooks';
import { generateHpbxUsageReport } from './api';
import { ReportingPolicies } from './policies';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface FormValues {
    billingPeriod: string;
    groupId: string;
}

interface FormHandler {
    isGenerating: boolean;
    submit: (formValues: FormValues) => void;
    errorMessage: string | null;
}

// Form handler to send reporting generation request to the REST API
const useFormHandler = (callback: (url: string) => void): FormHandler => {
    const api = useApi();

    const [isGenerating, setIsGenerating] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const submit = (formValues: FormValues) => {
        (async () => {
            setIsGenerating(true);

            try {
                const billingPeriod = moment(formValues.billingPeriod).utc();
                const url = await generateHpbxUsageReport(api, billingPeriod.year(), billingPeriod.month() + 1, formValues.groupId);

                setIsGenerating(false);

                callback(url);
            } catch (e) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else {
                    setErrorMessage('Unable to generate report.');
                }

                setIsGenerating(false);
            }
        })();
    };

    return {
        isGenerating,
        submit,
        errorMessage
    };
};

// Hosted PBX report
const HostedPbxUsagePage = () => {
    const classes = useStyles();

    //const [isFetchingGroups, groups, fetchGroupsError] = useFetchHostedPbxGroups('name', true);
    const [fetchGroups, isFetchingGroups, groups, fetchGroupsError] = useFetchHostedPbxGroups();

    const [formValues, setFormValues] = useState<FormValues>({
        billingPeriod: moment.utc().format('YYYY-MM'),
        groupId: ''
    });

    const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);

    const { isGenerating, submit, errorMessage } = useFormHandler(url => {
        window.open(url);
    });

    useNav('reporting', 'hpbx-usage');

    useEffect(() => {
        fetchGroups('Averistar', 'name', true);
    }, []);

    useProgressEffects(isFetchingGroups || isGenerating, fetchGroupsError || errorMessage);

    const handleSubmit = () => {
        let errors: FieldError[] = [];

        if (formValues.billingPeriod.length === 0) {
            errors.push({
                field: 'billingPeriod',
                message: 'Field is required'
            })
        }

        if (formValues.groupId.length === 0) {
            errors.push({
                field: 'groupId',
                message: 'Field is required'
            })
        }

        setFieldErrors(errors);

        if (errors.length === 0) {
            submit(formValues);
        }
    };

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrors);

    return (
        <div className={classes.root}>
            <PageHeader text="Reports" subtext="Hosted PBX Usage"/>

            {!isFetchingGroups && groups !== null &&
            <>
                <Paper className={classes.paper}>
                    <FormControl className={classes.formControl} fullWidth required error={!isValid('billingPeriod')}>
                        <MobileDatePicker
                            //required={true}
                            label="Billing Period"
                            views={["year", "month"]}
                            value={moment(formValues.billingPeriod).utc()}
                            disabled={isGenerating}
                            onChange={evt => {
                                setFormValues({...formValues, billingPeriod: evt ? evt.format('YYYY-MM-01') : ''});
                            }}
                            renderInput={props => <TextField {...props} variant="standard" />}
                        />
                        <ValidationMessage field="billingPeriod"/>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth required error={!isValid('groupId')}>
                        <InputLabel htmlFor="groupId">Group</InputLabel>
                        <Select
                            id="type"
                            margin="none"
                            native
                            disabled={isGenerating}
                            value={formValues.groupId}
                            input={<Input name="groupId" id="groupId"/>}
                            onChange={evt => {
                                setFormValues({...formValues, groupId: evt.target.value})
                            }}
                            onKeyDown={() => false}>
                            <option/>
                            {groups.map(grp => <option key={grp.id} value={grp.id}>{grp.name} ({grp.id})</option>)}
                        </Select>
                        <ValidationMessage field="groupId"/>
                    </FormControl>
                </Paper>

                <Grid container justifyContent="flex-end">
                    <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={isGenerating}
                    onClick={handleSubmit}>Generate</Button>
                </Grid>
            </>
            }
        </div>
    );
};

export default withPolicyRestriction(HostedPbxUsagePage, ReportingPolicies.CanViewHpbxUsageReport);